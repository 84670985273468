<nav sidebar id="sidebar" role="navigation" class="sidebar"></nav>
<nav navbar (toggleSidebarEvent)="toggleSidebarListener($event)" (toggleChatEvent)="toggleChatListener()"
     class="page-controls navbar navbar-dashboard"></nav>
<div class="content-wrap">
  <main id="content" class="content view-animate fade-up" role="main">
    <div class="banner" *ngIf="(banner$ | async)?.enabled">
      <p>{{(banner$ | async)?.message}}</p>
    </div>

    <router-outlet></router-outlet>

  </main>
</div>
