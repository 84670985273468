import {map, filter} from 'rxjs/operators';
import {Component, OnInit} from '@angular/core';
import {ITableHeader} from '../../../../shared/table/TableComponent';
import {Observable} from 'rxjs';
import {AllocationReport} from '../../../../../services/api.client';
import * as moment from 'moment';
import {formatCurrency} from '../../../../../utilities/Util';
import {SHORT_DATE_FORMAT} from '../../../../../constants';
import {User} from '../../../../../services/api.client';
import {select, Store} from '@ngrx/store';
import * as fromRoot from '../../../../../store';
import {selectReportDataAllocationReport} from '../../../../../store/selectors/report.selectors';
import {selectSecurityStateDataCurrentUser} from '../../../../../store/selectors/security.selectors';

@Component({
  templateUrl: './AllocationAndBalancesComponent.html',
})
export class AllocationAndBalancesComponent implements OnInit {
  tableHeaders: ITableHeader[] = [];
  rows: any;

  // subscription
  currentUser: User;
  currentUser$: Observable<User>;
  report$: Observable<AllocationReport[]>;

  constructor(private _store: Store<fromRoot.AppState>) {
      this.currentUser$ = this._store.pipe(select(selectSecurityStateDataCurrentUser));
      this.report$ = this._store.pipe(select(selectReportDataAllocationReport));
  }
  ngOnInit(): void {
      this.currentUser$.subscribe(user => this.currentUser = user);

      if (this.currentUser.hasPrivate) {
      this.tableHeaders = [
        {text: 'Agency Name', },
        {text: 'Allocation Note', },
        {text: 'Fund Type', },
        {text: 'Admin %', },
        {text: 'Allocated', },
        {text: 'Spent', },
        {text: 'Current Balance', },
        {text: 'Entered', },
        {text: 'Ledger #', },
        {text: 'Private', },
      ];

      this.rows = this.report$.pipe(
        filter(report => !!report),
        map((report) => report.map(r => [
          r.agencyName,
          r.note,
          r.fundType,
          r.adminPercent / 100,
          formatCurrency(r.allocated),
          formatCurrency(r.spent),
          formatCurrency(r.remainingBalance),
          moment(r.enteredDate).format(SHORT_DATE_FORMAT),
          r.ledgerNumber,
          r.isPrivate
        ])), );

    } else {
      this.tableHeaders = [
        {text: 'Agency Name', },
        {text: 'Allocation Note', },
        {text: 'Fund Type', },
        {text: 'Admin %', },
        {text: 'Allocated', },
        {text: 'Spent', },
        {text: 'Current Balance', },
        {text: 'Entered', },
        {text: 'Ledger #', },
      ];

      this.rows = this.report$.pipe(
        filter(report => !!report),
        map((report) => report.map(r => [
          r.agencyName,
          r.note,
          r.fundType,
          r.adminPercent / 100,
          formatCurrency(r.allocated),
          formatCurrency(r.spent),
          formatCurrency(r.remainingBalance),
          moment(r.enteredDate).format(SHORT_DATE_FORMAT),
          r.ledgerNumber
        ])), );
    }
  }
}
