import {map, filter} from 'rxjs/operators';
import {Component, OnInit, Input} from '@angular/core';
import {ITableHeader} from '../../../shared/table/TableComponent';
import {PledgeType} from '../../../../services/api.client';
import {Company} from '../../../../services/api.client';
import {Observable, Subject} from 'rxjs';
import {ShiftPledgeReportSummary} from '../../../../services/api.client';
import {formatPercent, formatCurrency} from '../../../../utilities/Util';
import {User} from '../../../../services/api.client';
import {select, Store} from '@ngrx/store';
import * as fromRoot from '../../../../store';
import {selectSecurityStateDataCurrentUser} from '../../../../store/selectors/security.selectors';

@Component({
  selector: 'pledgeReportSummaryTable',
  templateUrl: './ShiftPledgeReportSummaryTable.html',
})

export class ShiftPledgeReportSummaryTable implements OnInit {
  tableHeaders: ITableHeader[] = [];
  reports$ = new Subject<ShiftPledgeReportSummary[]>();
  rows: any[][];


  @Input('isManual')
  isManual: boolean;

  @Input('rows')
  set _(rows: ShiftPledgeReportSummary[]) {
    this.reports$.next(rows);
  }

  // subscription
  currentUser: User;
  currentUser$: Observable<User>;

  constructor(private _store: Store<fromRoot.AppState>) {
      this.currentUser$ = this._store.pipe(select(selectSecurityStateDataCurrentUser));
  }

  ngOnInit(): void {
      this.currentUser$.subscribe(user => this.currentUser = user);
      if (this.isManual) {
      if (this.currentUser.hasPrivate) {
        this.tableHeaders = [
          {text: 'Select to Pay', },
          {text: 'Pledge Type', sortKey: 'PledgeType', ascending: true},
          {text: 'Company', sortKey: 'Company'},
          {text: 'Pledged Count', sortKey: 'PledgedCount'},
          {text: 'Pledged Amount', sortKey: 'PledgedAmount'},
          {text: 'Pledged Type Total%1', sortKey: 'PledgedTypeTotal'},
          {text: 'Ledger #', },
          {text: 'Private', },
        ];

        this.reports$.pipe(
          filter(reports => !!reports),
          map((reports) => reports.map((report) => [
            report.pledgeType,
            report.company,
            report.pledgedCount,
            formatCurrency(report.pledgedAmount, '0,0.00'),
            formatPercent(report.pledgeTypeTotal),
            report.ledgerNumber,
            report.isPrivate
          ])), ).subscribe((rows) => this.rows = rows);

      } else {
        this.tableHeaders = [
          {text: 'Select to Pay', },
          {text: 'Pledge Type', sortKey: 'PledgeType', ascending: true},
          {text: 'Company', sortKey: 'Company'},
          {text: 'Pledged Count', sortKey: 'PledgedCount'},
          {text: 'Pledged Amount', sortKey: 'PledgedAmount'},
          {text: 'Pledged Type Total%1', sortKey: 'PledgedTypeTotal'},
          {text: 'Ledger #', },
        ];

        this.reports$.pipe(
          filter(reports => !!reports),
          map((reports) => reports.map((report) => [
            report.pledgeType,
            report.company,
            report.pledgedCount,
            formatCurrency(report.pledgedAmount, '0,0.00'),
            formatPercent(report.pledgeTypeTotal),
            report.ledgerNumber
          ])), ).subscribe((rows) => this.rows = rows);
      }
    } else {
      if (this.currentUser.hasPrivate) {
        this.tableHeaders = [
          {text: 'Pledge Type', sortKey: 'PledgeType', ascending: true},
          {text: 'Company', sortKey: 'Company'},
          {text: 'Pledged Count', sortKey: 'PledgedCount'},
          {text: 'Pledged Amount', sortKey: 'PledgedAmount'},
          {text: 'Pledged Type Total%1', sortKey: 'PledgedTypeTotal'},
          {text: 'Ledger #', },
          {text: 'Private', },
        ];

        this.reports$.pipe(
          filter(reports => !!reports),
          map((reports) => reports.map((report) => [
            report.pledgeType,
            report.company,
            report.pledgedCount,
            formatCurrency(report.pledgedAmount, '0,0.00'),
            formatPercent(report.pledgeTypeTotal),
            report.ledgerNumber,
            report.isPrivate
          ])), ).subscribe((rows) => this.rows = rows);

      } else {
        this.tableHeaders = [
          {text: 'Pledge Type', sortKey: 'PledgeType', ascending: true},
          {text: 'Company', sortKey: 'Company'},
          {text: 'Pledged Count', sortKey: 'PledgedCount'},
          {text: 'Pledged Amount', sortKey: 'PledgedAmount'},
          {text: 'Pledged Type Total%1', sortKey: 'PledgedTypeTotal'},
          {text: 'Ledger #', },
        ];

        this.reports$.pipe(
          filter(reports => !!reports),
          map((reports) => reports.map((report) => [
            report.pledgeType,
            report.company,
            report.pledgedCount,
            formatCurrency(report.pledgedAmount, '0,0.00'),
            formatPercent(report.pledgeTypeTotal),
            report.ledgerNumber
          ])), ).subscribe((rows) => this.rows = rows);
      }
    }
  }
}
