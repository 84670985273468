import {ICompanyData} from '../ICompanyData';
import {createReducer, on} from '@ngrx/store';
import {
  clearCurrentSelectedCompany,
  companiesDataIsLoaded,
  companiesDataIsLoading, companiesDataSoftDelete,
  setCurrentSelectedCompany
} from '../actions/company.actions';

export const initialState: ICompanyData = {
  isCompaniesLoading: false,
  companies: null,
  currentSelectedCompany: null
};

export const companyReducer = createReducer(
 initialState,
 on(setCurrentSelectedCompany, (state, { payload }) => ({
   ...state,
       currentSelectedCompany: payload
 })),
    on(companiesDataIsLoaded, (state, { payload }) => ({
      ...state,
      isCompaniesLoading: false,
      companies: payload
    })),
    on(companiesDataIsLoading, (state) => ({
      ...state,
      isCompaniesLoading: true
    })),
    on(clearCurrentSelectedCompany, (state, { payload }) => ({
      ...state,
      currentSelectedCompany: payload
    })),
    on(companiesDataSoftDelete, (state) => ({
      ...state,
      companies: null
    }))
);
