<h1 class="text-center m-b-2">Keeping Current History</h1>
<table class="table table-striped table-hover">
  <thead>
  <tr>
    <th>Client Name</th>
    <th>Account #</th>
    <th>Creation Date</th>
    <th>Edited Date</th>
    <th>Status</th>
    <th>Program</th>
  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let application of applications" class="clickable" [routerLink]="getKcApplicationUrl(application)">
    <td>{{ application.client.lastName }}, {{ application.client.firstName }}</td>
    <td>{{ application.accountNumber }}</td>
    <td>{{ application.enteredDate | moment: 'L' }}</td>
    <td>{{ application.modifiedDate | moment: 'L' }}</td>
    <td>{{ system.getKcStatusNameById(application.keepingCurrentStatusId) }}</td>
    <td>{{ application.keepingCurrentProgram.programName }}</td>
  </tr>
  </tbody>
</table>