<div class="row">
    <div class="offset-md-1 col-md-10">
        <div class="form-group row">
            <label class="col-md-2">Start Date:</label>
            <datePicker class="col-md-4" [control]="startDate" [autoClose]="true"></datePicker>
            <label class="col-md-2">End Date:</label>
            <datePicker class="col-md-4" [control]="endDate" [autoClose]="true"></datePicker>
        </div>
        <div *ngIf="showPledgeStatus" class="form-group row">
            <label class="col-md-2">Pledge Status:</label>
            <div class="col-md-10">
                <select pledgeStatus [formControl]="pledgeStatus" class="form-control" name="pledgeStatus">
                    <option value="">All Pledge Statuses</option>
                </select>
            </div>
        </div>
        <div *ngIf="showCompany" class="form-group row">
            <label class="col-md-2">Company</label>
            <div class="col-md-10">
                <input #company type="text" class="form-control" (click)="companyListModal.openModal()"
                       value="All Companies">
                <company-box *ngIf="showCompany"
                             (onCompanySelected)="companySelected($event); company.value = ($event?.noCompany ? 'All Companies' : $event?.company?.name) || 'All Companies'"></company-box>
            </div>
        </div>
        <div class="form-group row">
            <label class="col-md-2">Agency</label>
            <div class="col-md-10">
                <input #agency [formControl]="agencyInput" type="text" class="form-control"
                       (click)="agencyListModal.open()" value='All Agencies'>
                <agency-box (onAgencySelected)="onAgencyChange($event)"></agency-box>
            </div>
        </div>
        <div *ngIf="showZeroBalance" class="form-group row">
            <div class="col-xs-12">
                <label class="hideAllZeroLabel"><input type="checkbox" [formControl]="hideZeroBalances"> Hide all the $0
                    Balance Allocations</label>
            </div>
        </div>
        <div class="form-group row">
            <label class="col-md-2">Select Fund(s)</label>
            <pledgeTypeCheckbox [filterByRole]="true" [control]="pledgeTypeIds" [requireOne]="true"
                                showSelectAll="true"
                                [preselectAll]="true"></pledgeTypeCheckbox>
        </div>
        <ng-content></ng-content>
    </div>
</div>
