<div class="container" *ngIf="!loading">
    <div class="form-container">
        <div class="select-container">
            <ul class="select-preview" *ngIf="isNotEmpty((batchId$ | async))">
                <li><i class="fa fa-list"></i> Total Pledges: {{(selectedBatch$ | async)?.pledgeCount}}</li>
                <li *ngIf="(selectedBatch$ | async)?.paidCount > 0"><i class="fa fa-money"></i> Paid Pledges: {{(selectedBatch$ | async)?.paidCount}}</li>
                <li><i class="fa fa-trash"></i> Deleted Pledges: {{(selectedBatch$ | async)?.deletedPledgeCount}}</li>
            </ul>
            <ul class="select-preview" *ngIf="!isNotEmpty((batchId$ | async))">
                <li><i class="fa fa-list"></i> Total Pledges: --</li>
                <li *ngIf="(selectedBatch$ | async)?.paidCount > 0"><i class="fa fa-money"></i> Paid Pledges: --</li>
                <li><i class="fa fa-trash"></i> Deleted Pledges: --</li>
            </ul>
            <select class="form-control col-md-12 custom-select m-0"
                    type="text" id="batch-select" (change)="onBatchSelectionChange($event.target.value)">
                <option value="">Select a Batch</option>
                <option *ngFor="let batch of batches$ | async " [value]="batch.batchId">
                    ({{batch.enteredDate.toDate() | date:'shortDate'}}) - Batch {{batch.batchId.toString().slice(0, 6)}} {{getBatchPaidStatus(batch)}}
                </option>
            </select>
        </div>
        <div class="action-container">
            <div class="show-paid-toggle">
                <label class="switch m-l-05 m-r-05">
                    <input type="checkbox" [(ngModel)]="shouldShowPaidPledges" (change)="onShowPaidPledgesChange()">
                    <span class="slider round"></span>
                </label>
                Show Paid Batches
            </div>
            <button [disabled]="!(selectedBatch$ | async)" class="btn btn-primary pull-left action-button" role="button" (click)="downloadCsv()">Export Batch (CSV)
            </button>
            <button [disabled]="!(selectedBatch$ | async)" class="btn pull-left btn-red action-button mt-2" role="button" (click)="deleteBatch()">
                <i class="fa fa-trash me-2"></i>Delete Batch
            </button>
        </div>
    </div>
    <app-view-batch *ngIf="isNotEmpty((batchId$ | async))" (onPledgesPaid)="updatePaidCount($event)" [batchId]="batchId$"></app-view-batch>
</div>

