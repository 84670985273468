import {Component} from '@angular/core';
import {Observable} from 'rxjs';
import {FormBuilder, FormControl} from '@angular/forms';
import {UserService} from '../../../services/UserService';
import {Validators} from '../../../utilities/Validators';
import {User} from '../../../services/api.client';
import {NotificationService} from '../../../services/NotificationService';
import {share} from 'rxjs/operators';
import {select, Store} from '@ngrx/store';
import * as fromRoot from '../../../store';
import {selectUserDataSelectedUser} from '../../../store/selectors/user.selectors';

@Component({
    selector: '[changePassword]',
    templateUrl: './ChangePasswordComponent.html',
    styleUrls: ['./ChangePasswordComponent.scss'],
})

export class ChangePasswordComponent {
    public form = this.fb.group({
        currentPassword: ['', Validators.required],
        newPassword: ['', Validators.compose([Validators.required, Validators.passwordComplexity])],
        confirmPassword: ['', Validators.required],
    }, {validators: Validators.matchPassword('newPassword', 'confirmPassword')});

    selectedUser: User;
    selectedUser$: Observable<User>;

    public currentPassword = this.form.get('currentPassword') as FormControl;
    public newPassword = this.form.get('newPassword') as FormControl;
    public confirmPassword = this.form.get('confirmPassword') as FormControl;

    errorMessage: string;

    constructor(
        private fb: FormBuilder,
        private _notificationService: NotificationService,
        private _userService: UserService,
        private _store: Store<fromRoot.AppState>
    ) {
        this.selectedUser$ = this._store.pipe(select(selectUserDataSelectedUser));
    }

    isValid(): boolean {
        if (this.form.valid) {
            return true;
        } else {
            this.form.markAllAsTouched();
            return false;
        }
    }

    submit(callback?): Observable<any> {
        const {currentPassword, newPassword} = this.form.value;
        const response = this._userService.changePassword(this.selectedUser.id, currentPassword, newPassword)
            .pipe(
                share()
            );
        response.subscribe({
            next: () => {
                // this._notificationService.showSuccess()
            },
            error: (error) => {
                this.errorMessage = error;
            }
        });
        return response;
    }

    get showMatchPasswordError(): boolean {
        return this.form.hasError('matchPassword')
            && this.newPassword.valid
            && this.newPassword.touched
            && this.confirmPassword.valid
            && this.confirmPassword.touched;
    }
}
