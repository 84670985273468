import {EMPTY as observableEmpty, Observable} from 'rxjs';

import {filter, takeUntil, tap} from 'rxjs/operators';
import {Component, Output, EventEmitter, ViewChild, forwardRef, OnInit, AfterViewInit} from '@angular/core';
import {Router, Params, ActivatedRoute} from '@angular/router';
import {UntypedFormBuilder, Validators, FormControl} from '@angular/forms';
import {MonthDays} from '../../../enums/MonthDays';
import {Months} from '../../../enums/Months';
import {Agency, LookUp, PledgeType} from '../../../services/api.client';
import {PledgeTypeService} from '../../../services/PledgeTypeService';
import {NotificationService} from '../../../services/NotificationService';
import {extractEnumNames} from '../../../utilities/Util';
import {PromptComponent} from '../../shared/dialogs/PromptComponent';
import {SHORT_DATE_FORMAT} from '../../../constants';
import * as moment from 'moment';
import {AutoDisableParent} from '../../../directives/AutoDisableDirective';
import {select, Store} from '@ngrx/store';
import * as fromRoot from '../../../store';
import {selectPledgeTypeDataCurrentSelectedPledgeType} from '../../../store/selectors/pledgeType.selectors';
import {selectSecurityDefaultManagementAgency, selectSecurityStateDataSystemDataRegion} from '../../../store/selectors/security.selectors';
import {AgencyListModalComponent} from '../agency/AgencyListModalComponent';
import {Disposable} from '../../../utilities/Disposable';

@Component({
    templateUrl: './PledgeTypeComponent.html',
    styleUrls: ['./PledgeTypeComponent.scss'],
    providers: [{provide: AutoDisableParent, useExisting: forwardRef(() => PledgeTypeComponent)}],

})

export class PledgeTypeComponent extends Disposable implements OnInit, AfterViewInit {
    public form = this.fb.group({
        name: new FormControl(null, Validators.required),
        regionValue: new FormControl(null, Validators.required),
        managementAgencyId: new FormControl(null),
        fiscalStartMonth: new FormControl(null, Validators.required),
        fiscalStartDay: new FormControl(null, Validators.required),
        isSsnRequired: new FormControl(false, Validators.required),
        minSinglePledgeAmt: new FormControl(null, Validators.required),
        maxSinglePledgeAmt: new FormControl(null, Validators.required),
        maxYearlyPledgeAmt: new FormControl(null, Validators.required),
        crisisPledgeThreshold: new FormControl(null, Validators.required),
        ledgerNumber: new FormControl('', Validators.compose([Validators.required, Validators.maxLength(24)])),
        isActive: new FormControl(true, Validators.required),
        isKeepingCurrent: new FormControl(false, Validators.required),
        isEmergencyNatGasRepair: new FormControl(false, Validators.required),
        isPovertyLevelRequired: new FormControl(false, Validators.required),
        isEfs: new FormControl(false),
        isUhons: new FormControl(false),
        isUwda: new FormControl(false),
        efsStartDate: new FormControl(moment().format(SHORT_DATE_FORMAT)),
        efsEndDate: new FormControl(moment().format(SHORT_DATE_FORMAT)),
        isPrivate: new FormControl(false),
    });

    constructor(private fb: UntypedFormBuilder,
                private _notificationService: NotificationService,
                private _pledgeTypeService: PledgeTypeService,
                private _router: Router,
                private _route: ActivatedRoute,
                private _store: Store<fromRoot.AppState>) {
        super();
        this.currentSelectedPledgeType$ = this._store.pipe(select(selectPledgeTypeDataCurrentSelectedPledgeType));
        this.allRegions$ = this._store.pipe(select(selectSecurityStateDataSystemDataRegion));
        this.defaultManagementAgency$ = this._store.pipe(select(selectSecurityDefaultManagementAgency));
    }

    months = extractEnumNames(Months);
    days = extractEnumNames(MonthDays);
    params: any;
    showManagementAgency = false;
    selectManagementAgencyControl = new FormControl(false);
    currentSelectedManagementAgencyId: string;

    currentSelectedPledgeType: PledgeType;
    currentSelectedPledgeType$: Observable<PledgeType>;
    allRegions: LookUp[] = [];
    allRegions$: Observable<LookUp[]>;
    defaultManagementAgency: Agency;
    defaultManagementAgency$: Observable<Agency>;

    get name(): FormControl {
        return this.form.get('name') as FormControl;
    }

    get regionValue(): FormControl {
        return this.form.get('regionValue') as FormControl;
    }

    get managementAgencyId(): FormControl {
        return this.form.get('managementAgencyId') as FormControl;
    }

    get fiscalStartMonth(): FormControl {
        return this.form.get('fiscalStartMonth') as FormControl;
    }

    get fiscalStartDay(): FormControl {
        return this.form.get('fiscalStartDay') as FormControl;
    }

    get isSsnRequired(): FormControl {
        return this.form.get('isSsnRequired') as FormControl;
    }

    get minSinglePledgeAmt(): FormControl {
        return this.form.get('minSinglePledgeAmt') as FormControl;
    }

    get maxSinglePledgeAmt(): FormControl {
        return this.form.get('maxSinglePledgeAmt') as FormControl;
    }

    get maxYearlyPledgeAmt(): FormControl {
        return this.form.get('maxYearlyPledgeAmt') as FormControl;
    }

    get crisisPledgeThreshold(): FormControl {
        return this.form.get('crisisPledgeThreshold') as FormControl;
    }

    get ledgerNumber(): FormControl {
        return this.form.get('ledgerNumber') as FormControl;
    }

    get isActive(): FormControl {
        return this.form.get('isActive') as FormControl;
    }

    get isKeepingCurrent(): FormControl {
        return this.form.get('isKeepingCurrent') as FormControl;
    }

    get isEmergencyNatGasRepair(): FormControl {
        return this.form.get('isEmergencyNatGasRepair') as FormControl;
    }

    get isPovertyLevelRequired(): FormControl {
        return this.form.get('isPovertyLevelRequired') as FormControl;
    }

    get isEfs(): FormControl {
        return this.form.get('isEfs') as FormControl;
    }

    get isUhons(): FormControl {
        return this.form.get('isUhons') as FormControl;
    }

    get efsStartDate(): FormControl {
        return this.form.get('efsStartDate') as FormControl;
    }

    get efsEndDate(): FormControl {
        return this.form.get('efsEndDate') as FormControl;
    }

    get isUwda(): FormControl {
        return this.form.get('isUwda') as FormControl;
    }

    get isPrivate(): FormControl {
        return this.form.get('isPrivate') as FormControl;
    }

    // children - agency modal
    @ViewChild(PromptComponent)
    promptModal: PromptComponent;

    @ViewChild(AgencyListModalComponent)
    managementAgencyListModal: AgencyListModalComponent;

    @Output('onShowPromptModal') _showPrompt = new EventEmitter();

    ngOnInit(): void {
        this.currentSelectedPledgeType$.subscribe(currentSelectedPledgeType => this.currentSelectedPledgeType = currentSelectedPledgeType);
        this.allRegions$.subscribe(allRegions => this.allRegions = allRegions);
        this._route.params.subscribe((params: Params) => {
            this.params = params;
            const id = params.id;
            if (id === 'add') {
                this._pledgeTypeService.setCurrentSelectedPledgeType(new PledgeType());
            } else if (!this.currentSelectedPledgeType || (this.currentSelectedPledgeType && this.currentSelectedPledgeType.id !== id)) {
                this._pledgeTypeService.getPledgeTypeById(id);
            }
        });

        this.initForm();

        this.currentSelectedPledgeType$.pipe(
            filter(pledgeType => !!pledgeType))
            .subscribe(pledgeType => {
                if (pledgeType?.managementAgencyId && pledgeType?.managementAgencyName) {
                    this.managementAgencyId.setValue(pledgeType.managementAgencyName);
                    this.selectManagementAgencyControl.setValue(true);
                    this.currentSelectedManagementAgencyId = pledgeType.managementAgencyId;
                    this.showManagementAgency = true;
                }

                this.form.patchValue({
                    name: pledgeType?.name,
                    regionValue: pledgeType?.regionValue,
                    managementAgencyId: pledgeType.managementAgencyName,
                    fiscalStartMonth: pledgeType?.fiscalStartMonth,
                    fiscalStartDay: pledgeType?.fiscalStartDay,
                    isSsnRequired: pledgeType?.isSsnRequired ?? false,
                    minSinglePledgeAmt: pledgeType?.minSinglePledgeAmt,
                    maxSinglePledgeAmt: pledgeType?.minSinglePledgeAmt,
                    maxYearlyPledgeAmt: pledgeType?.maxYearlyPledgeAmt,
                    crisisPledgeThreshold: pledgeType?.crisisPledgeThreshold,
                    ledgerNumber: pledgeType?.ledgerNumber,
                    isActive: pledgeType?.isActive ?? true,
                    isKeepingCurrent: pledgeType?.isKeepingCurrent ?? false,
                    isEmergencyNatGasRepair: pledgeType?.isEmergencyNatGasRepair ?? false,
                    isPovertyLevelRequired: pledgeType?.isPovertyLevelRequired ?? false,
                    isEfs: pledgeType?.isEfs ?? false,
                    isUhons: pledgeType?.isUhons ?? false,
                    isUwda: pledgeType?.isUwda ?? false,
                    efsStartDate: pledgeType?.efsStartDate,
                    efsEndDate: pledgeType?.efsEndDate,
                    isPrivate: pledgeType?.isPrivate ?? false
                });
            });

        this.selectManagementAgencyControl.valueChanges
            .pipe(
                takeUntil(this.$destroyed),
            ).subscribe((checkedValue: boolean) => {
            this.showManagementAgency = checkedValue;
        });

        this.defaultManagementAgency$
            .pipe(takeUntil(this.$destroyed))
            .subscribe(defaultManagementAgency => this.defaultManagementAgency = defaultManagementAgency);

    }

    initForm(): void {
        this.form = this.fb.group({
            name: new FormControl(null, Validators.required),
            regionValue: new FormControl(null, Validators.required),
            managementAgencyId: new FormControl(null),
            fiscalStartMonth: new FormControl(null, Validators.required),
            fiscalStartDay: new FormControl(null, Validators.required),
            isSsnRequired: new FormControl(false, Validators.required),
            minSinglePledgeAmt: new FormControl(null, Validators.required),
            maxSinglePledgeAmt: new FormControl(null, Validators.required),
            maxYearlyPledgeAmt: new FormControl(null, Validators.required),
            crisisPledgeThreshold: new FormControl(null, Validators.required),
            ledgerNumber: new FormControl('', Validators.compose([Validators.required, Validators.maxLength(24)])),
            isActive: new FormControl(true, Validators.required),
            isKeepingCurrent: new FormControl(false, Validators.required),
            isEmergencyNatGasRepair: new FormControl(false, Validators.required),
            isPovertyLevelRequired: new FormControl(false, Validators.required),
            isEfs: new FormControl(false),
            isUhons: new FormControl(false),
            isUwda: new FormControl(false),
            efsStartDate: new FormControl(moment().format(SHORT_DATE_FORMAT)),
            efsEndDate: new FormControl(moment().format(SHORT_DATE_FORMAT)),
            isPrivate: new FormControl(false),
        });

    }

    onManagementAgencyChange(managementAgency: Agency): void {
        if (managementAgency) {
            this.currentSelectedManagementAgencyId = managementAgency?.id;
            this.managementAgencyId.setValue(managementAgency.agencyName);
        } else {
            this.currentSelectedManagementAgencyId = null;
            this.managementAgencyId.setValue('All Agencies');
        }

        this.managementAgencyId.markAsDirty();
    }

    // submit
    submit(): Observable<PledgeType> {
        const pledgeType = this.form.value;
        this.form.markAllAsTouched();
        if (!this.isRequiredDataPopulated() || !this.form.valid) {
            this.form.markAllAsTouched();
            this._notificationService.showError('The form is not valid');
            return observableEmpty;
        }

        pledgeType.managementAgencyId = this.currentSelectedManagementAgencyId
            ? this.currentSelectedManagementAgencyId
            : this.setManagementAgencyIdToDefault();

        if (this.currentSelectedPledgeType && this.currentSelectedPledgeType.id) {
            pledgeType.id = this.currentSelectedPledgeType.id;
            return this._pledgeTypeService.updatePledgeType(pledgeType)
                .pipe(
                    tap(() => this._router.navigate(['/app/pledgetypes']))
                );
        } else {
            return this._pledgeTypeService.addPledgeType(pledgeType)
                .pipe(
                    tap(() => this._router.navigate(['/app/pledgetypes']))
                );
        }
    }

    isRequiredDataPopulated(): boolean {
        if (!this.showManagementAgency) {
            this.resetManagementAgencyError();
            return true;
        }

        if (!this.managementAgencyId?.value) {
            this.setManagementAgencyError();
            return false;
        }

        this.resetManagementAgencyError();
        return true;
    }

    setManagementAgencyError(): void {
        this.managementAgencyId.setErrors({
            isRequired: true
        });
    }

    resetManagementAgencyError(): void {
        if (this.managementAgencyId.hasError('isRequired')) {
            delete this.managementAgencyId.errors.isRequired;
        }

        this.managementAgencyId.updateValueAndValidity();
        this.managementAgencyId.markAsUntouched();
    }

    showPromptModal(): boolean {
        this.promptModal.open().subscribe(okClicked => {
            if (okClicked) {
                this._pledgeTypeService.deletePledgeType(this.currentSelectedPledgeType.id);
                this._router.navigate(['/app/pledgetypes', this.params]).then();
            } else {
                return false;
            }
        });

        return false;
    }

    private setManagementAgencyIdToDefault(): string {
        return this.defaultManagementAgency?.id;
    }

    ngAfterViewInit(): void {
    }
}
