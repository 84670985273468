<div class="row" *ngIf="application">
  <div class="col-md-6">
    <legend><strong>Monthly Bill Credit</strong> Summary</legend>
    <div class="form-group row"><label class="col-sm-8 control-label">Monthly Budget-Billing $ Amount:</label>
      <div class="col-sm-4">{{ application.budgetBillAmount | parseNumber | currency:'USD':'symbol':'1.2-2' }}</div>
    </div>
    <div class="form-group row"><label class="col-sm-8 control-label">Monthly Keeping Current $ Credit:</label>
      <div class="col-sm-4">- {{ application.programMonthlyCredit | parseNumber | currency:'USD':'symbol':'1.2-2' }}<br>
        ========
      </div>
    </div>
    <div class="form-group row"><label class="col-sm-8 control-label">Monthly Customer Payment $ Due:</label>
      <div class="col-sm-4">={{ application.customerMonthlyPayment | parseNumber | currency:'USD':'symbol':'1.2-2' }}</div>
    </div>
  </div>
  <div class="col-md-6">
    <legend><strong>Overall Bill Credit</strong> Details</legend>
    <div class="form-group row"><label class="col-sm-8 control-label">Keeping Current Total Credit $ Paid:</label>
      <div class="col-sm-4">{{ application.totalProgramCreditsPaid | parseNumber | currency:'USD':'symbol':'1.2-2' }}</div>
    </div>
    <div class="form-group row"><label class="col-sm-8 control-label">Keeping Current Remaining Credits:</label>
      <div class="col-sm-4">{{ application.numProgramCreditsRemaining }}</div>
    </div>
    <div class="form-group row"><label class="col-sm-8 control-label">Keeping Current Start Date:</label>
      <div class="col-sm-4">{{ application.confirmationCreatedDate | moment: 'L' }}</div>
    </div>
    <div class="form-group row"><label class="col-sm-8 control-label">Keeping Current Expiration:</label>
      <div class="col-sm-4">{{ application.programCreditExpiration | moment: 'L' }}</div>
    </div>
  </div>
</div>
<div class="row">
  <div class="col-md-6">
    <legend><strong>Overall Arrears Credit</strong> Summary</legend>
    <div class="form-group row"><label class="col-sm-8 control-label">Original Arrears $ Owed:</label>
      <div class="col-sm-4">{{ application.totalAmountDue | parseNumber | currency:'USD':'symbol':'1.2-2' }}</div>
    </div>
    <div class="form-group row"><label class="col-sm-8 control-label">Total Arrears Credit $ Paid:</label>
      <div class="col-sm-4">- {{ application.totalArrearsCreditsPaid | parseNumber | currency:'USD':'symbol':'1.2-2' }}<br>
        ========
      </div>
    </div>
    <div class="form-group row"><label class="col-sm-8 control-label">Remaining Arrears $ Owed:</label>
      <div class="col-sm-4">{{ application.arrearsAmountRemaining | parseNumber | currency:'USD':'symbol':'1.2-2' }}</div>
    </div>
  </div>
  <div class="col-md-6">
    <legend><strong>Monthly Arrears Credit </strong> Details</legend>
    <div class="form-group row"><label class="col-sm-8 control-label">Arrears Monthly $ Credit Paid:</label>
      <div class="col-sm-4">{{ application.arrearsMonthlyCredit | parseNumber | currency:'USD':'symbol':'1.2-2' }}</div>
    </div>
    <div class="form-group row"><label class="col-sm-8 control-label">Arrears Remaining Credits:</label>
      <div class="col-sm-4">{{ application.numArrearsCreditsRemaining }}</div>
    </div>
    <div class="form-group row"><label class="col-sm-8 control-label">Arrears Credit Start Date:</label>
      <div class="col-sm-4">{{ application.confirmationCreatedDate | moment: 'L'}}</div>
    </div>
    <div class="form-group row"><label class="col-sm-8 control-label">Arrears Credit Expiration:</label>
      <div class="col-sm-4">{{ application.arrearsCreditExpiration | moment: 'L'}}</div>
    </div>
  </div>
  <div class="col-xs-12">
    <importantCustomerInformation></importantCustomerInformation>
  </div>
</div>


