import {FormGroup, ValidationErrors, ValidatorFn} from '@angular/forms';

export function emailOrPhoneRequiredValidator(): ValidatorFn {
    return (group: FormGroup): ValidationErrors | null => {
        const phoneControl = group.get('phone');
        const emailControl = group.get('email');
        const contactPreference = group.get('contactPreference');

        if (contactPreference && contactPreference.value === 'phone' && (!phoneControl.value || phoneControl.value === '')) {
            phoneControl.setErrors({ required: true });
            emailControl.setErrors(null); // Clear errors on the other control
            return { required: true };
        }

        if (contactPreference && contactPreference.value === 'email' && (!emailControl.value || emailControl.value === '')) {
            emailControl.setErrors({ required: true });
            phoneControl.setErrors(null); // Clear errors on the other control
            return { required: true };
        }

        // Reset errors if no validation issues are found
        phoneControl.setErrors(null);
        emailControl.setErrors(null);

        return null;
    };
}

