import {share} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {
  Agency, CompanyCategory,
  EmailParameter,
  GenericApiResponse,
  LoginParameters,
  LoginResult,
  ResetPasswordParameters,
  SecurityClient, SystemClient,
  SystemData
} from './api.client';
import {LocalStorageService} from './LocalStorageService';
import {
  select,
  Store
} from '@ngrx/store';
import * as fromRoot from '../store';
import {
  loginFailure,
  loginInProgress,
  loginSuccess,
  logoutAction, setDefaultManagementAgency,
  setVerifiedClientId,
  systemDataUpdatedAction
} from '../store/actions/security.actions';
import {selectSecurityStateDataSystemData} from '../store/selectors/security.selectors';
import {setCompanyCategories} from '../store/actions/companyCategory.actions';
import {CompanyCategoryService} from './companyCategory.service';

// Store
// API
// Services
// Models
@Injectable()
export class SecurityService {

  redirectUrl = '';

  constructor(private _localStorageService: LocalStorageService,
              private _securityApiService: SecurityClient,
              private _systemApiService: SystemClient,
              private _companyCategoryService: CompanyCategoryService,
              private _store: Store<fromRoot.AppState>) {
  }

  checkLocalLogin(): boolean {
    try {
      const result = this._localStorageService.getObject('loginResult') as LoginResult;
      if (result?.currentUser) {
        this.loginSuccessAction(result);
        return true;
      }
      return false;
    } catch (ex) {
      this.clearLocalLogin();
      return false;
    }
  }

  login(email: string, password: string, rememberMe: boolean = false): Observable<LoginResult> {
    if (!email) {
      throw new Error('Email not specified');
    }

    if (!password) {
      throw new Error('Password not specified');
    }
    this.loginInProgressAction();
    const parameters = new LoginParameters({email, password});
    const request = this._securityApiService.login(parameters).pipe(share());
    request.subscribe({ next: (result) => {
      if (rememberMe) {
        this.saveLocalLogin(result);
      }
      this.loginSuccessAction(result);
    }, error: (err) => {
        if (err.isSwaggerException) {
            try {
                this.loginFailureAction(JSON.parse(err.response).message);
            } catch (e) {
            }
        } else {
            this.loginFailureAction(err);
        }
    }});
    return request;
  }

  setVerifyClientId(id: string): void {
    // save to localstorage
    const result = this._localStorageService.getObject('loginResult') as LoginResult;
    result.verifiedClientId = id;
    this.saveLocalLogin(result);
    this._store.dispatch(setVerifiedClientId({ payload: id }));
    // this._store.dispatch({type: .SET_VERIFIED_CLIENT_ID, payload: id});

  }

  sendPasswordResetEmail(email: string): Observable<GenericApiResponse | null> {
    return this._securityApiService.sendResetPasswordRequest(new EmailParameter({email}));
  }

  resetPassword(profileID: string, token: string, newPassword: string): Observable<GenericApiResponse | null> {
    return this._securityApiService.resetPassword(new ResetPasswordParameters({token, newPassword}));
  }

  loadSystemData(): Observable<SystemData> {
    const request = this._systemApiService.getSystemData().pipe(share());
    request.subscribe({ next: (data) => {
      this.updateSystemDataAction(data);
    }, error: () => {

    }});
    return request;
  }

  logout(): void {
    this.logoutAction();
    this.clearLocalLogin();
  }

  getSystemData(): SystemData {
    const data = this._store.pipe(select(selectSecurityStateDataSystemData));
    let systemData: SystemData = null;
    data.subscribe((next) => {
       systemData = next;
    });

    return systemData;
  }

  private saveLocalLogin(data: LoginResult): void {
    this._localStorageService.setObject('loginResult', data);
  }

  private clearLocalLogin(): void {
    this._localStorageService.remove('loginResult');
  }

  // ---------------- Security Actions ------------------
  private loginSuccessAction(result: LoginResult): void {
    this._store.dispatch(loginSuccess({ payload: result }));
    this.updateSystemDataAction(result.systemData);
    this.updateDefaultManagementAgency(result.defaultManagementAgency);
    this.updateCompanyCategories(result.systemData.categories);
  }

  private loginFailureAction(message: string): void {
    this._store.dispatch(loginFailure({ payload: message }));
  }

  private logoutAction(): void {
    this._store.dispatch(logoutAction());
  }

  private loginInProgressAction(): void {
    this._store.dispatch(loginInProgress());
  }

  private updateSystemDataAction(systemData: SystemData): void {
    this._store.dispatch(systemDataUpdatedAction({ payload: systemData }));
  }

  private updateDefaultManagementAgency(managementAgency: Agency): void {
    this._store.dispatch(setDefaultManagementAgency({ payload: managementAgency }));
  }

  private updateCompanyCategories(companyCategories: CompanyCategory[]): void {
    this._store.dispatch(setCompanyCategories({payload: companyCategories}));
  }
}

