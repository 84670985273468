import {Component, inject, Input, OnInit, ViewChild} from '@angular/core';
import {Disposable} from '../../../../../../../utilities/Disposable';
import {ITableHeader, TableComponent} from '../../../../../../shared/table/TableComponent';
import {AssistanceApplicationStateAuditService} from '../../../../../../../services/assistanceApplicationStateAudit.service';
import {
    PagedListOfAssistanceApplicationStateAuditListViewModel
} from '../../../../../../../services/api.client';
import {takeUntil} from 'rxjs/operators';

@Component({
    selector: 'app-audit-state-list',
    templateUrl: './audit-state-list.component.html',
    styleUrls: ['./audit-state-list.component.scss']
})
export class AuditStateListComponent extends Disposable implements OnInit {

    @Input() assistanceApplicationId;
    @ViewChild(TableComponent) table: TableComponent;

    private _assistanceApplicationStateAuditService = inject(AssistanceApplicationStateAuditService);

    pagedAudits: PagedListOfAssistanceApplicationStateAuditListViewModel;

    tableHeaders: ITableHeader[] = [
        {text: 'Audit Date', sortKey: 'AuditDate', ascending: false},
        {text: 'Auditor', sortKey: 'AuditorId'},
        {text: 'Agency'},
        {text: 'Old Status', sortKey: 'OldState'},
        {text: 'New Status', sortKey: 'NewState'},
        {text: 'Notes', sortKey: 'Note'},
    ];

    currentSortName = 'AuditDate';
    currentSorting = true;

    // pagination
    limit = 10;
    currentPage = 1;
    maxSize = 1;
    totalCount: number;

    ngOnInit(): void {
        this.filterAudits();
    }

    sortAudits(options: ITableHeader): void {
        this.currentSortName = options.sortKey;
        this.currentSorting = !options.ascending;
        this.filterAudits();
    }

    setLimit(limit: number): void {// # of rows per page
        this.limit = limit;
        this.filterAudits();
    }

    setPage(page: number): void {
        setTimeout(() => {
            this.currentPage = page; // set current page
            this.filterAudits();
        });
    }

    filterAudits(): void {
        this._assistanceApplicationStateAuditService.retrievePagedAssistanceApplicationAudits(
            this.assistanceApplicationId,
            this.currentSortName,
            this.currentSorting,
            this.limit,
            (this.currentPage - 1) * this.limit
        ).pipe(takeUntil(this.$destroyed))
            .subscribe(pagedAudits => {
                this.pagedAudits = pagedAudits;
                this.totalCount = pagedAudits?.totalCount;
            });
    }
}
