<div class="row">
  <div class="offset-md-1 col-md-10">
    <div *ngIf="showPledgeTypes" class="form-group row">
      <label class="col-md-2">EFS Phase:</label>
      <div class="col-md-10">
        <select efsPledgeTypes [formControl]="pledgeTypeId" class="form-control" name="pledgeType">
          <option value="">-- All EFS Phases --</option>
        </select>
      </div>
    </div>
    <div *ngIf="showAgencies" class="form-group row">
      <label class="col-md-2">Agency</label>
      <div class="col-md-10">
        <input #agency [formControl]="agencyInput" type="text" class="form-control" (click)="agencyListModal.open()"
               value='All Agencies'>
        <agency-box (onAgencySelected)="onAgencyChange($event)"></agency-box>
      </div>
    </div>
    <ng-content></ng-content>
  </div>
</div>
