import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {ClientService} from '../../../services/ClientService';
import {FormControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {ModalComponent} from 'src/app/utilities/old-modal/components/modal';
import {Observable, Observer} from 'rxjs';
import {Validators} from '../../../utilities/Validators';

@Component({
  selector: 'client-ssn-reveal',
  templateUrl: './ClientSsnRevealComponent.html',
  styleUrls: ['./ClientSsnRevealComponent.scss']
})

export class ClientSsnRevealComponent implements OnInit {
  @ViewChild(ModalComponent)
  modal: ModalComponent;

  @Input()
  clientId: string;

  get userPassword(): FormControl {
    return this.form.get('userPassword') as FormControl;
  }

  form = this.formBuilder.group({
    userPassword: new FormControl('', Validators.required)
  });

  fullSsn: string;
  private observer: Observer<boolean>;

  constructor(private clientService: ClientService, private formBuilder: UntypedFormBuilder) {
  }

  ngOnInit(): void {
    this.initForm();
  }

  initForm(): void {
  }

  open(): Observable<boolean> {
    this.modal.open();
    this.fullSsn = null;
    this.userPassword.setValue(null);
    return new Observable(obs => {
      this.observer = obs;
    });
  }

  revealSsn(): void {
    this.clientService.exposeClientSsn(this.clientId, this.userPassword.value)
      .subscribe((result) => {
        this.fullSsn = result.result;
      });
  }

  cancelClicked(): void {
    this.modal.close();
    if (this.observer) {
      this.observer.next(false);
      this.observer.complete();
    }
  }

  submit(): void {
    this.revealSsn();
  }

  ngOnDestroy(): void {
    this.fullSsn = null;
  }
}
