<div class="row">
  <div class="col-sm-12 col-md-12 col-lg-10 offset-lg-1">
    <h4>Shift Pledges Pending Payment to Paid</h4>
    <p>
      The report below shows a summary of the pledges in the system which were paid on the Paid Date entered in the
      textbox below.
      In order to shift new or additional pledges to Paid, first enter the 'Paid Date' that you want applied to the
      pledges;
      and select which pledges you want Paid (i.e. 'All Companies' or one specific company, etc.).
      After you have selected the date and the pledges, then click 'Pay Pledges' to shift the pledges from Pending
      Payment to Paid.
      The summary report below will automatically update to include all the new pledges that were set to Paid.
    </p>
  </div>
  <div class="col-sm-12 col-md-12 col-lg-10 offset-lg-1">
    <form paymentProcessingForm #form
          [showEntryDate]="true"
          [showDisplayPledgeStatus]="true"
          [showShiftPledgeStatus]="true"
          [showDateRange]="true"
          [showPledgeTypes]="true"
          [showCompany]="true" novalidate>
      <span entryDateLabel>Payment date of Pledges being shifted from Pending Payment to Paid:</span>
      <div footer class="text-right">
        <button class="btn btn-primary" (click)="form.submitPendingToPaid()">Show Results</button>
        <button class="btn btn-primary" [disabled]="!reports" (click)="form.submitPayPledge()">Pay Pledges</button>
      </div>
    </form>
  </div>
  <div class="col-xs-12 m-b-1 m-t-1">
    <pledgeReportSummaryTable [rows]="reports$ | async"></pledgeReportSummaryTable>
  </div>
</div>
