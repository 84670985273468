import {filter} from 'rxjs/operators';
import {Component, OnInit} from '@angular/core';
import {PledgeType} from '../../../services/api.client';
import {PledgeTypeService} from '../../../services/PledgeTypeService';
import {Observable} from 'rxjs';
import {select, Store} from '@ngrx/store';
import * as fromRoot from '../../../store';
import {selectPledgeTypeDataAllPledgeTypes} from '../../../store/selectors/pledgeType.selectors';

@Component({
  selector: '[pledgeTypes]',
  templateUrl: './PledgeTypeDropdown.html'
})
export class PledgeTypeDropdown implements OnInit {
  pledgeTypes: PledgeType[];
  pledgeTypes$: Observable<PledgeType[]>;

  constructor(private _pledgeTypeService: PledgeTypeService, private _store: Store<fromRoot.AppState>) {
    this.pledgeTypes$ = this._store.pipe(select(selectPledgeTypeDataAllPledgeTypes));
  }

  ngOnInit(): void {
    this.pledgeTypes$.subscribe(pledgeTypes => this.pledgeTypes = pledgeTypes);
    this.pledgeTypes$.pipe(
      filter(pledges => !pledges))
      .subscribe(() => {
        this._pledgeTypeService.getAllPledgeTypes();
      });
  }
}
