import {HttpClient} from '@angular/common/http';
import {share} from 'rxjs/operators';
import {Injectable, Injector, OnInit} from '@angular/core';
import {getMessageFromException} from '../utilities/Util';
import {
    Client,
    ClientClient,
    ClientSearchTypes,
    MergeClientParameters,
    PagedListOfClient,
    SimpleBooleanResult, SimpleStringResult
} from './api.client';
import {NotificationService} from './NotificationService';
import {Observable} from 'rxjs';
import {select, Store} from '@ngrx/store';
import * as fromRoot from '../store';
import {selectClientDataCurrentClient} from '../store/selectors/client.selectors';
import {selectSecuritySecurityDataVerifiedClientIed} from '../store/selectors/security.selectors';
import {clientsDataIsLoading, clientsDataLoaded, setCurrentClient} from '../store/actions/client.actions';
// api
// models
// store

@Injectable()
export class ClientService {

    verifiedClientId: string;
    verifiedClientId$: Observable<string>;

    currentClient: Client;
    currentClient$: Observable<Client>;

    get isClientVerified(): boolean {
        // service gets loaded in guard so there's a change of client not existing
        return this.currentClient && this.currentClient.id === this.verifiedClientId;
    }

    constructor(private _http: HttpClient,
                private _clientApiService: ClientClient,
                private _notificationService: NotificationService,
                private injector: Injector,
                private _store: Store<fromRoot.AppState>) {
        this.currentClient$ = this._store.pipe(select(selectClientDataCurrentClient));
        this.verifiedClientId$ = this._store.pipe(select(selectSecuritySecurityDataVerifiedClientIed));
        this.currentClient$.subscribe(currentClient => this.currentClient = currentClient);
        this.verifiedClientId$.subscribe(verifiedClientId => this.verifiedClientId = verifiedClientId);
    }

    getClients(searchType: ClientSearchTypes,
               searchValue: string,
               agencyId: string,
               sortName: string,
               descending: boolean,
               limit: number,
               offset: number,
               secondarySortName?: string): Observable<PagedListOfClient> {
        this.startLoadingAction();
        const request = this._clientApiService.searchClients(
            searchType,
            searchValue,
            agencyId,
            sortName,
            secondarySortName,
            descending,
            limit || 10,
            offset || 0
        ).pipe(share());
        request.subscribe({
            next: (data) => {
                this.setClientsAction(data);
            }, error: (err) => {
                this._notificationService.showError(getMessageFromException(err));
            }, complete: () => {

            }
        });
        return request;
    }

    getClientById(id: string): Observable<Client | null> {
        const request = this._clientApiService.retrieveClient(id).pipe(share());
        request.subscribe((result) => {
            this.setCurrentClientAction(result);
        });
        return request;
    }

    clientUniqueSsnCheck(ssn: string, id: string): Observable<SimpleBooleanResult> {
        return this._clientApiService.clientUniqueSsnCheck(ssn, id).pipe(share());
    }


    setCurrentClient(currentClient: Client): void {
        this.setCurrentClientAction(currentClient);
    }

    addClient(client: Client): Observable<Client> {
        const request = this._clientApiService.addClient(client).pipe(share());
        this._notificationService.showSuccess('Attempting to save new Client.');
        request.subscribe({
            next: (result) => {
                this.setCurrentClientAction(result);
                this._notificationService.showSuccess('Client Successfully Created!');
            }, error: (err) => {
                this._notificationService.showError(getMessageFromException(err));
            }, complete: () => {

            }
        });
        return request;
    }

    updateClient(client: Client): Observable<Client> {
        const request = this._clientApiService.updateClient(client).pipe(share());
        this._notificationService.showSuccess('Attempting to update Client.');
        request.subscribe({
            next: (result) => {
                this.setCurrentClientAction(result);
                this._notificationService.showSuccess('Client Successfully Updated!');
            }, error: (err) => {
                this._notificationService.showError(getMessageFromException(err));
            }, complete: () => {

            }
        });
        return request;
    }

    mergeClient(srcClientId, destClientId): Observable<Client> {
        const mergeClientParameters = new MergeClientParameters({
            srcClientId,
            destClientId
        });
        return this._clientApiService.mergeClients(mergeClientParameters);
    }

    exposeClientSsn(clientId: string, password: string): Observable<SimpleStringResult> {
        const request = this._clientApiService.exposeSsn(clientId, password).pipe(share());
        request.subscribe({
            next: () => {

            }, error: () => {
                this._notificationService.showError('There was an error getting the SSN.');
            }
        });
        return request;
    }

    // ---------------- Security Actions ------------------
    private startLoadingAction(): void {
        this._store.dispatch(clientsDataIsLoading());
    }

    private setClientsAction(clients: PagedListOfClient): void {
        this._store.dispatch(clientsDataLoaded({ payload: clients }));
    }

    setCurrentClientAction(currentClient: Client): void {
        this._store.dispatch(setCurrentClient({ payload: currentClient}));
    }
}
