import {createAction, props} from '@ngrx/store';
import {
    Company,
    EfnCase,
    KcApplication,
    KcApplicationResult,
    KcNoteResult,
    KcProgramLevel,
    PagedListOfKcApplication
} from '../../services/api.client';

export const setCurrentClientsApplications  = createAction(
    '[KC Application] SET_CURRENT_CLIENTS_APPLICATIONS',
    props<{ payload: KcApplication[] }>());

export const setCurrentSelectedKcApplications  = createAction(
    '[KC Application] SET_CURRENT_SELECTED_KCAPPLICATION',
    props<{ payload: KcApplication }>());

export const setCurrentSelectedKcApplicationResult  = createAction(
    '[KC Application] SET_CURRENT_SELECTED_KCAPPLICATIONRESULT',
    props<{ payload: KcApplicationResult }>());

export const setKcApplications  = createAction(
    '[[KC Application] KCAPPLICATIONS_DATA_ISLOADED',
    props<{ payload: PagedListOfKcApplication }>());

export const clearCurrentSelectedKcApplicationResult  = createAction(
    '[KC Application] CLEAR_CURRENT_SELECTED_KCAPPLICATIONRESULT\'',
    props<{ payload: KcApplicationResult }>());

export const setCurrentKcNotes  = createAction(
    '[KC Application] SET_CURRENT_KCNOTES',
    props<{ payload: KcNoteResult[] }>());

export const setKcProgramDetail  = createAction(
    '[KC Application] SET_KC_PROGRAM_DETAIL',
    props<{ payload: KcProgramLevel }>());

export const setKcAmerenCompany  = createAction(
    '[KC Application] SET_AMEREN_MISSOURI_COMPANY',
    props<{ payload: Company }>());

export const setKcPledge  = createAction(
    '[KC Application] SET_KC_PLEDGE',
    props<{ payload: EfnCase }>());
