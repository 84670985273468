<div class="container">
    <form [formGroup]="form">
        <div class="row m-l-05 mt-5">
            <label class="col-md-3"><strong>Audit Status:</strong></label>
            <div class="col-md-8">
                <select class="form-control required-label"
                        [formControl]="auditState"
                        formControlName="auditState"
                        (change)="onSelectedAuditState($event)">
                    <option *ngFor="let state of auditStates"
                            [value]="state.id">
                        {{ state.text }}
                    </option>
                </select>
            </div>
        </div>

        <div class="row m-l-05 mt-5" [class.has-danger]="!auditNote?.valid && auditNote?.touched">
            <label class="col-md-3"><strong>Note:</strong></label>
            <div class="col-md-8">
                <textarea [formControl]="auditNote"
                      (change)="onChangeAuditNote()"
                      formControlName="auditNote"
                      name="auditNote"
                      cols="30"
                      rows="5"
                      class="form-control required-label"></textarea>
                <div *ngIf="auditNote?.hasError('required') && auditNote?.touched" class="alert alert-danger m-t-05">
                    Comment is required
                </div>
            </div>
        </div>

        <button type="submit" class="btn btn-secondary m-2 pull-right" (click)="onClickCancelAuditState()">Cancel
        </button>
        <button type="button" class="btn btn-primary m-2 px-1 pull-right" (click)="onClickConfirmAuditState()">Confirm
        </button>
    </form>
</div>
