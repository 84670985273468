import {createAction, props} from '@ngrx/store';
import {PagedListOfLookUp, PagedListOfPledge, Pledge, PledgeTypeBalance} from '../../services/api.client';

export const setCurrentSelectedPledge  = createAction(
    '[Pledge] SET_CURRENT_SELECTEDPLEDGE',
    props<{ payload: Pledge }>());

export const pledgesDataIsLoaded  = createAction(
    '[Pledge] PLEDGESDATA_ISLOADED',
    props<{ payload: PagedListOfPledge }>());

export const pledgesDataIsLoading  = createAction(
    '[Pledge] PLEDGESDATA_ISLOADING');

export const clearCurrentSelectedPledge  = createAction(
    '[Pledge] CLEAR_CURRENT_SELECTEDPLEDGE',
    props<{ payload: PagedListOfLookUp }>());

export const setCurrentClientPledgeList  = createAction(
    '[Pledge] SET_CURRENTCLIENT_PLEDGELIST',
    props<{ payload: Pledge[] }>());

export const setPledgeTypeBalance  = createAction(
    '[Pledge] SET_PLEDGETYPE_BALANCED',
    props<{ payload: PledgeTypeBalance }>());

export const setUwdaPledges  = createAction(
    '[Pledge] SET_UWDA_PLEDGES',
    props<{ payload: PagedListOfPledge }>());

export const setManualPendingPledges  = createAction(
    '[Pledge] SET_MANUAL_PENDING_PLEDGES',
    props<{ payload: Pledge[] }>());

