import {Component, OnInit, ViewChild, Output, EventEmitter} from '@angular/core';
import {Router} from '@angular/router';
import {Client, ClientSearchTypes, PagedListOfClient, SystemData, User} from '../../../services/api.client';
import {ClientService} from '../../../services/ClientService';
import {ITableHeader, TableComponent} from '../../shared/table/TableComponent';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {Masking} from '../../../utilities/Masking';
import {Observable} from 'rxjs';
import {select, Store} from '@ngrx/store';
import * as fromRoot from '../../../store';
import {
  selectClientDataClients,
  selectClientDataClientsItems,
  selectClientDataClientsTotalCount
} from '../../../store/selectors/client.selectors';
import {
  selectSecurityStateDataCurrentUser,
  selectSecurityStateDataCurrentUserUserType,
  selectSecurityStateDataSystemData
} from '../../../store/selectors/security.selectors';
// shared
// store
// models
// services
// utilities
// directives
// modals

@Component({
  selector: 'clientList',
  templateUrl: './ClientListComponent.html',
  styleUrls: ['./ClientListComponent.scss'],
})

export class ClientListComponent implements OnInit {

  constructor(private _clientService: ClientService,
              private _router: Router,
              private _store: Store<fromRoot.AppState>) {
    this.currentUser$ = this._store.pipe(select(selectSecurityStateDataCurrentUser));
    this.systemData$ = this._store.pipe(select(selectSecurityStateDataSystemData));
    this.loggedInUserType$ = this._store.pipe(select(selectSecurityStateDataCurrentUserUserType));
    this.clients$ = this._store.pipe(select(selectClientDataClientsItems));
    this.pagedList$ = this._store.pipe(select(selectClientDataClients));
    this.totalCount$ = this._store.pipe(select(selectClientDataClientsTotalCount));
  }
  searchTypePlaceholder = 'Search by SSN';
  ssnMask = Masking.ssnMask;
  form = new UntypedFormGroup({
    searchType: new UntypedFormControl('Ssn')
  });

  // subscriptions
  systemData: SystemData;
  systemData$: Observable<SystemData>;

  loggedInUserType: string;
  loggedInUserType$: Observable<string>;

  @ViewChild(TableComponent)
  table: TableComponent;

  // SSN/string search check box
  searchValue = '';

  // subscription
  currentUser: User;
  currentUser$: Observable<User>;
  clients: Client[];
  clients$: Observable<Client[]>;
  pagedList: PagedListOfClient;
  pagedList$: Observable<PagedListOfClient>;

  // set Current Selected Client
  @Output()
  selectedClient = new EventEmitter<Client>();

  // sorting
  currentSortName = 'LastName';
  currentSorting = false;

  tableHeaders: ITableHeader[] = [
    {text: 'First Name', sortKey: 'FirstName'},
    {text: 'Last Name', sortKey: 'LastName', ascending: true},
    {text: 'Phone', sortKey: 'Phone', class: 'hidden-sm-down'},
    {text: 'SSN', sortKey: 'Last4Ssn', class: 'hidden-sm-down'},
    {text: 'Client Address', sortKey: 'Address1', class: 'hidden-sm-down'},
    {text: 'Zip Code', sortKey: 'ZipCode', class: 'hidden-sm-down'},
    {text: 'Employment', sortKey: 'EmploymentStatus', class: 'hidden-sm-down'},
  ];

  // pagination
  limit = 10;
  currentPage = 1;
  maxSize = 1;
  totalCount: number;
  totalCount$: Observable<number>;

  ngAfterViewInit(): void {
  }

  ngOnInit(): void {
    this.currentUser$.subscribe(user => this.currentUser = user);
    this.systemData$.subscribe(systemData => this.systemData = systemData);
    this.loggedInUserType$.subscribe(loggedInUserType => this.loggedInUserType = loggedInUserType);
    this.clients$.subscribe(clients => this.clients = clients);
    this.pagedList$.subscribe(pagedList => this.pagedList = pagedList);
    this.totalCount$.subscribe(totalCount => this.totalCount = totalCount);
    this.searchTypePlaceholder = 'Search by SSN';
    let previous = 'Ssn';
    this.form.controls.searchType.valueChanges.subscribe(searchType => {
      if (searchType === 'Ssn') {
        this.searchValue = '';
        this.searchTypePlaceholder = 'Search by SSN';
      } else {
        if (previous === 'Ssn') {
          this.searchValue = '';
        }
        this.searchTypePlaceholder = 'Search by Name, Keyword, or Last 4 SSN';
      }
      previous = searchType;
    });
  }

  selectClient(client: Client): void {
    this.selectedClient.emit(client);

    // this._clientService.setCurrentClient(client);
    // this._router.navigate(['/app/client', client.id]);
  }

  sortClients(options: ITableHeader): void {
    this.currentSortName = options.sortKey;
    this.currentSorting = !options.ascending;
    this.filterClients();
  }

  setLimit(limit: number): void {// # of rows per page
    this.limit = limit;
    this.filterClients();
  }

  setPage(page: number): void {
    setTimeout(() => {
      this.currentPage = page; // set current page
      this.filterClients();
    });
  }

  // get clients
  filterClients(): void {
    const searchType = this.form.controls.searchType.value as ClientSearchTypes;

    this.table.request = this._clientService.getClients(
      searchType,
      this.searchValue,
      this.currentUser.agency && this.currentUser.agency.id || '',
      this.currentSortName,
      this.currentSorting,
      this.limit,
      (this.currentPage - 1) * this.limit,
      null
    );
  }

  // search value
  setSearchValue(searchValue): void {
    this.searchValue = searchValue;
  }
}
