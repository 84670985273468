import {Component, EventEmitter, Output, ViewChild} from '@angular/core';
import {AbstractControl, FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {Validators} from '../../../../utilities/Validators';
import {ModalComponent} from '../../../../utilities/old-modal/components/modal';
import {CompanySelectedEvent} from '../../../shared/events/company-selected-event';
import {Observable, Observer} from 'rxjs';
import {Company} from '../../../../services/api.client';

@Component({
  selector: 'app-keeping-current-amount-owed-calculator',
  templateUrl: './keeping-current-amount-owed-calculator.component.html',
  styleUrls: ['./keeping-current-amount-owed-calculator.component.scss']
})
export class KeepingCurrentAmountOwedCalculatorComponent {
  @Output() onCalculateEmitter = new EventEmitter<number>();
  form = this.fb.group({
    accountBalance: ['0.00', Validators.required],
    totalAmountDeferred: ['0.00', Validators.required],
    unbilledPaymentAgreement: ['0.00', Validators.required],
  });

  @ViewChild(ModalComponent)
  modal: ModalComponent;

  private observer: Observer<any>;

  constructor(private fb: FormBuilder) {
  }

  openModal(): void {
    this.modal?.open();
  }
  getTotalAmountOwed(): number {
    const total = (+this.totalAmountDeferredControl?.value ?? 0) + (+this.accountBalanceControl?.value ?? 0) + (+this.unbilledPaymentAgreementControl?.value ?? 0);
    return total;
  }

  get accountBalanceControl(): FormControl {
    return this.form.get('accountBalance') as FormControl;
  }

  get totalAmountDeferredControl(): FormControl {
    return this.form.get('totalAmountDeferred') as FormControl;
  }

  get unbilledPaymentAgreementControl(): FormControl {
    return this.form.get('unbilledPaymentAgreement') as FormControl;
  }

  onCalculate(): void {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }
    const total = this.getTotalAmountOwed();
    this.onCalculateEmitter.emit(total);
    this.modal.close();
  }

  onCloseModal(): void {
    this.onCalculateEmitter.emit(null);
    this.modal.close();
  }
}
