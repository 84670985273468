<h1 class="page-title">Manage Pledge</h1>
<section class="widget">
    <div class="loading" *ngIf="loading | async">
        <h2>Loading...</h2>
    </div>
    <div *ngIf="!(loading | async)" class="widget-body">
        <div class="mt">
            <form class="form-horizontal" [formGroup]="form" novalidate>
                <div class="row pull-right m-r-05">
                    <a *ngIf="assistanceApplication"
                       type="submit"
                       class=""
                       [routerLink]="['../../client', clientId?.value, 'assistance-applications', assistanceApplication?.id]">
                        View Case
                    </a>
                </div>
                <div class="row form-group">

                    <label class="col-md-3"><strong>Pledge Details</strong></label>
                    <div class="col-md-8">
                        <div class="row">
                            <p *ngIf="currentPledgeTypeBalance && pledgeTypeId.value != 'Choose an Agency'">
                                {{ agencyName.value }}'s available allocation for {{
                                    getPledgeTypeById(pledgeTypeId.value)?.name
                                }} = {{
                                    currentPledgeTypeBalance?.balance |
                                        parseNumber | currency:'USD':'symbol':'1.2-2'
                                }}.</p>


                        </div>

                        <fieldLayout>
                            <label class="required-label">Client Name</label>
                            <input class="form-control" type="text" [value]="getClientName()"
                                   disabled="disabled">
                        </fieldLayout>

                        <fieldLayout *ngIf="missingClientIncome" class="text-danger">
                            Client is missing income information. Please update the client's individual monthly
                            income
                            before creating a pledge.
                        </fieldLayout>

                        <fieldLayout [class.has-danger]="!agencyId?.valid && agencyId?.touched">
                            <label class="required-label">Agency</label>
                            <input class="form-control"
                                   type="text"
                                   [formControl]="agencyName"
                                   placeholder="Select an Agency"
                                   (click)="showAgencyModal()"
                                   (keydown)="showAgencyModal()"/>
                        </fieldLayout>

                        <fieldLayout [class.has-danger]="!pledgeTypeId.valid && pledgeTypeId.touched">
                            <label class="required-label">Fund Type</label>
                            <select [formControl]="pledgeTypeId" class="form-control custom-select" type="text"
                                    formControlName="pledgeTypeId">
                                <option [value]="null">Choose a Fund Type</option>
                                <option *ngFor="let item of filterPledgeTypes$ | async" [value]="item.id">
                                    {{ item.name }}
                                </option>
                            </select>
                        </fieldLayout>

                        <fieldLayout class="row" *ngIf="is211?.value">
                            <span class="col-md-3"></span>
                            <div class="custom-control-container">
                                <label class="custom-control custom-radio">
                                    <input type="radio" class="custom-control-input" [value]="false"
                                           [formControl]="usesOneTimeCompany"
                                           [checked]="!usesOneTimeCompany.value">
                                    <span class="custom-control-indicator"></span>
                                    <span class="custom-control-description">Company</span>
                                </label>
                                <label class="custom-control custom-radio">
                                    <input type="radio" class="custom-control-input" [value]="true"
                                           [formControl]="usesOneTimeCompany">
                                    <span class="custom-control-indicator"></span>
                                    <span class="custom-control-description">One-Time Payee</span>
                                </label>
                            </div>
                        </fieldLayout>

                        <fieldLayout class="row"
                                     *ngIf="selectedCompany?.urlDisplay && selectedCompany.urlLink && !usesOneTimeCompany.value">
                            <div class="col-xs-11 p-r-0">
                                <a [href]="selectedCompany.urlLink | url" target="_blank"
                                   class="btn btn-primary pull-right">{{ selectedCompany.urlDisplay }}</a>
                            </div>
                        </fieldLayout>

                        <fieldLayout [class.has-danger]="!companyId.valid && companyId.touched"
                                     *ngIf="!usesOneTimeCompany.value">
                            <label class="required-label">Company</label>
                            <input class="form-control"
                                   type="text"
                                   [disabled]="!hasSelectedPledgeType()"
                                   [formControl]="companyName"
                                   placeholder="Select a Company"
                                   (click)="showCompanyModal()"
                                   (keydown)="showCompanyModal()"/>
                        </fieldLayout>

                        <span *ngIf="companyId.touched && form.hasError('mutuallyExclusive')"
                              class="m-t-05 col-md-3"></span>
                        <fieldLayout *ngIf=" form.hasError('mutuallyExclusive')"
                                     class="form-control-feedback">
                            {{ form.getError('mutuallyExclusive') }}
                        </fieldLayout>

                        <fieldLayout *ngIf="is211?.value && usesOneTimeCompany.value"
                                     [class.has-danger]="!onetimeCompanyRecipient.valid && onetimeCompanyRecipient.touched">
                            <label class="required-label">Payee Recipient</label>
                            <input type="text" class="form-control" [formControl]="onetimeCompanyRecipient">
                        </fieldLayout>

                        <fieldLayout *ngIf="is211?.value && usesOneTimeCompany.value"
                                     [class.has-danger]="!onetimeCompanyAddress1.valid && onetimeCompanyAddress1.touched">
                            <label class="required-label">Payee Address</label>
                            <input type="text" class="form-control" [formControl]="onetimeCompanyAddress1">
                        </fieldLayout>

                        <fieldLayout *ngIf="is211?.value && usesOneTimeCompany.value"
                                     [class.has-danger]="!onetimeCompanyAddress2.valid && onetimeCompanyAddress2.touched">
                            <label>Payee Address2</label>
                            <input type="text" class="form-control" [formControl]="onetimeCompanyAddress2">
                        </fieldLayout>

                        <fieldLayout *ngIf="is211?.value && usesOneTimeCompany.value"
                                     [class.has-danger]="!onetimeCompanyCity.valid && onetimeCompanyCity.touched">
                            <label class="required-label">Payee City</label>
                            <input type="text" class="form-control" [formControl]="onetimeCompanyCity">
                        </fieldLayout>

                        <fieldLayout *ngIf="is211?.value && usesOneTimeCompany.value"
                                     [class.has-danger]="!onetimeCompanyState.valid && onetimeCompanyState.touched">
                            <label class="required-label">Payee State</label>
                            <select type="text" class="form-control" [formControl]="onetimeCompanyState">
                                <option [value]="null">Choose a State</option>
                                <option *ngFor="let state of states" [value]="state.key">{{ state.lookupValue }}
                                </option>
                            </select>
                        </fieldLayout>

                        <fieldLayout *ngIf="is211?.value && usesOneTimeCompany.value"
                                     [class.has-danger]="!onetimeCompanyZip.valid && onetimeCompanyZip.touched">
                            <label class="required-label">Payee Zipcode</label>
                            <input type="text" class="form-control" [formControl]="onetimeCompanyZip"
                                   [imask]="zipCodeMask">
                        </fieldLayout>

                        <fieldLayout *ngIf="is211?.value">
                            <label>Special Request</label>
                            <input type="text" class="form-control" [formControl]="checkMemo">
                        </fieldLayout>

                        <fieldLayout *ngIf="is211?.value"
                                     [class.has-danger]="!assistanceType.valid && assistanceType.touched">
                            <label>Assistance Type</label>
                            <div class="form-control-feedback"
                                 *ngIf="assistanceTypeErrorMessage && assistanceType.touched">{{ assistanceTypeErrorMessage }}
                            </div>
                            <select name="assistanceType" class="form-control" [formControl]="assistanceType">
                                <option [value]="null">Choose an Assistance Type</option>
                                <option *ngFor="let type of assistanceTypes" [value]="type.value">{{
                                        type.key |
                                            splitCamelCase
                                    }}
                                </option>
                            </select>
                        </fieldLayout>

                        <fieldLayout *ngIf="is211?.value"
                                     [class.has-danger]="!leverageAmount.valid && leverageAmount.touched">
                            <label class="required-label">Leverage Amount</label>
                            <!--<div class="text-danger" *ngIf="leverageAmount.hasError('requiredWhen')">-->
                            <!--{{leverageAmount.getError('requiredWhen')}}-->
                            <!--</div>-->
                            <input type="text" class="form-control" [formControl]="leverageAmount">
                            <!--                     [imask]="currencyMask">-->
                        </fieldLayout>

                        <fieldLayout
                                [class.has-danger]="accountNumberErrorMessage ||
                                (!clientCompanyAccountNumber.valid && clientCompanyAccountNumber.touched)">
                            <label class="required-label">Account Number</label>
                            <div class="form-control-feedback"
                                 *ngIf="accountNumberErrorMessage">{{ accountNumberErrorMessage }}
                            </div>
                            <input *ngIf="clientCompanyAccountNumber.disabled" readonly="readonly"
                                   [value]="clientCompanyAccountNumber.value" type="text" class="form-control">
                            <input *ngIf="!clientCompanyAccountNumber.disabled"
                                   [formControl]="clientCompanyAccountNumber" class="form-control" type="text"
                                   formControlName="clientCompanyAccountNumber"
                                   (blur)="validateAccountNumber(); isDirty = true;"
                                   (keyup)="isDirty && validateAccountNumber(); true;">
                        </fieldLayout>

                        <fieldLayout [class.has-danger]="amountPledgedErrorMessage && pledgedAmount.touched">
                            <label class="required-label">Amount Pledged</label>
                            <div class="form-control-feedback"
                                 *ngIf="amountPledgedErrorMessage && pledgedAmount.touched">{{
                                    amountPledgedErrorMessage
                                }}
                            </div>
                            <!--[imask]="currencyMask" TODO: James come back and fix-->
                            <input [formControl]="pledgedAmount" class="form-control" type="number"
                                   formControlName="pledgedAmount"
                                   (blur)="validateAmountPledged(); isDirty = true;"
                                   (keyup)="isDirty && validateAmountPledged(); true;">
                        </fieldLayout>

                        <fieldLayout [style.z-index]="5"
                                     [class.has-danger]="!pledgedDate.valid && pledgedDate.touched">
                            <label class="required-label">Date Pledged</label>
                            <datePicker [control]="pledgedDate"
                                        [controlFormat]="'L'"
                                        [autoClose]="true"
                                        (dateChange)="dateChanged()"
                                        [style.z-index]="5"
                                        class="datePicker-padding"></datePicker>
                            <span *ngIf="pledgedDate.touched && pledgedDate.hasError('dateRange')"
                                  class="m-t-05 col-md-3"></span>
                            <div *ngIf="pledgedDate.touched && pledgedDate.hasError('dateRange')"
                                 class="form-control-feedback">
                                {{ pledgedDate.getError('dateRange') }}
                            </div>
                            <span *ngIf="pledgedDate.touched && pledgedDate.hasError('isDateValid')"
                                  class="m-t-05 col-md-3"></span>
                            <div *ngIf="pledgedDate.touched && pledgedDate.hasError('isDateValid')"
                                 class="form-control-feedback">
                                {{ pledgedDate.getError('isDateValid') }}
                            </div>
                        </fieldLayout>

                        <fieldLayout [style.z-index]="5"
                                     [class.has-danger]="!paidDate.valid && paidDate.touched">
                            <label>Date Paid</label>
                            <datePicker [control]="paidDate"
                                        [controlFormat]="'L'"
                                        [autoClose]="true"
                                        (dateChange)="paidDateChanged()"
                                        [style.z-index]="1"
                                        class="datePicker-padding"></datePicker>
                            <span *ngIf="paidDate.touched && paidDate.hasError('isDateValid')"
                                  class="m-t-05 col-md-3"></span>
                            <div *ngIf="paidDate.touched && paidDate.hasError('isDateValid')"
                                 class="form-control-feedback">
                                {{ paidDate.getError('isDateValid') }}
                            </div>
                        </fieldLayout>

                        <fieldLayout [class.has-danger]="!status.valid && status.touched">
                            <label>Status</label>
                            <select [formControl]="status" class="form-control custom-select" type="text"
                                    formControlName="status">
                                <option *ngFor="let item of pledgeStatuses | exclude:excludedPledgeStatuses:'value'"
                                        [value]="item.key">{{ item.key | splitCamelCase }}
                                </option>
                            </select>

                        </fieldLayout>

                        <fieldLayout *ngIf="isCrisisPledge.enabled" class="row form-field-spacing"
                                     [class.has-danger]="!isCrisisPledge.valid && isCrisisPledge.touched">
                            <span class="m-t-05 col-md-3"></span>
                            <label class="custom-control custom-checkbox">
                                <input type="checkbox" [formControl]="isCrisisPledge"
                                       class="custom-control-input form-control">
                                <span class="custom-control-indicator"></span>
                                <span class="custom-control-description">I confirm the client is in a crisis situation and in need of emergency assistance.</span>
                            </label>
                        </fieldLayout>
                    </div>
                </div>

                <div class="row">
                    <div class="col-xs">
                    </div>
                    <button [disabled]="!form.dirty || submitDisabled == true || missingClientIncome"
                            type="submit"
                            class="btn btn-primary"
                            (click)="savePledge()">Save Pledge
                    </button>

                    <div class="col-xs"
                         *ngIf="currentPledge?.id && canBeDeleted()"></div>
                    <button
                            class="btn btn-inverse"
                            type="button"
                            *ngIf="canBeDeleted()"
                            (click)="showDeletePrompt()">Delete Pledge
                    </button>
                    <div class="col-xs">

                    </div>
                </div>

                <div class="row confirm-btn-group">
                    <div class="col-xs"></div>

                    <button
                            class="btn btn-primary"
                            type="button"
                            *ngIf="currentPledge?.id"
                            [disabled]="form.dirty"
                            (click)="reprintConfirmation()">Reprint Confirmation
                    </button>

                    <div class="col-xs"
                         *ngIf="is211?.value && currentPledge?.id && status.value == pledgeStatusTypes.Confirmed"></div>

                    <button class="btn btn-primary"
                            type="submit"
                            *ngIf="is211?.value && currentPledge?.id && status.value == pledgeStatusTypes.Confirmed"
                            [disabled]="form.dirty || submitDisabled == true"
                            (click)="prompt(true)">Print Manual Check Request
                    </button>

                    <div class="col-xs"></div>


                </div>
            </form>

            <agency-box (onAgencySelected)="onAgencySelected($event)"></agency-box>
            <company-box (onCompanySelected)="onCompanySelected($event)"></company-box>
            <pledge-confirmation #confirmPrompt class="pledge-confirmation"
                                 [headerText]="isExpedite ? 'Manual Check Request' : 'Pledge Confirmation'">
                <div body>
                    <table class="table table-bordered" style="table-layout: fixed">
                        <thead>
                        <tr>
                            <td colspan="2" style="font-weight: bold; text-align: center">Client Info</td>
                        </tr>
                        </thead>
                        <tbody>

                        <tr>
                            <td class="required-label">... for the client:</td>
                            <td class="required-label">{{ getClientName() }}</td>
                        </tr>
                        <tr>
                            <td>... who resides at:</td>
                            <td>{{ currentClient?.address1 }} {{ currentClient?.address2 }} {{ currentClient?.city }}
                                ,
                                {{ currentClient?.state }} {{ currentClient?.zipCode }}
                            </td>
                        </tr>
                        <tr>
                            <td>... county:</td>
                            <td>{{ currentClient?.county }}</td>
                        </tr>
                        <tr>
                            <td>... union info:</td>
                            <td>{{ currentClient?.unionInfo }}</td>
                        </tr>
                        </tbody>
                    </table>
                    <table class="table table-bordered" style="table-layout: fixed">
                        <thead>
                        <tr>
                            <td colspan="2" style="font-weight: bold; text-align: center">Pledge Info</td>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td class="required-label">... Pledge Amount:</td>
                            <td class="required-label">${{ pledgedAmount.value }}</td>
                        </tr>
                        <tr>
                            <td class="required-label">... utility account #:</td>
                            <td class="required-label">{{ clientCompanyAccountNumber.value }}</td>
                        </tr>
                        <tr>
                            <td class="required-label">... ledger #:</td>
                            <td class="required-label">{{ getPledgeTypeById(pledgeTypeId.value)?.ledgerNumber }}</td>
                        </tr>
                        <ng-container *ngIf="is211?.value">
                            <tr *ngIf="leverageAmount.value">
                                <td>... Leverage Amount</td>
                                <td>${{ leverageAmount.value }}</td>
                            </tr>
                            <tr *ngIf="assistanceType?.value">
                                <td>... Assistance Type</td>
                                <td>{{ pledgeAssistances[assistanceType?.value]?.value | splitCamelCase }}</td>
                            </tr>
                        </ng-container>
                        <tr>
                            <td>... Fund Type:</td>
                            <td>{{ getPledgeTypeById(pledgeTypeId.value)?.name }}</td>
                        </tr>
                        </tbody>
                    </table>
                    <table class="table table-bordered" style="table-layout: fixed">
                        <thead>
                        <tr>
                            <td colspan="2" style="font-weight: bold; text-align: center">Payment Info</td>
                        </tr>
                        </thead>
                        <tbody>
                        <tr *ngIf="companyId.value">
                            <td class="required-label">... will be made to:</td>
                            <td class="required-label">{{ selectedCompanyName }}</td>
                        </tr>
                        <tr>
                            <td>... Pledge Date:</td>
                            <td>{{ pledgedDate.value | moment: 'L' }}</td>
                        </tr>
                        <tr *ngIf="modifiedDate.value">
                            <td>... Modified Date:</td>
                            <td>{{ modifiedDate.value | moment: 'L' }}</td>
                        </tr>
                        <tr *ngIf="enteredDate.value">
                            <td>... Entered Date:</td>
                            <td>{{ enteredDate.value }}</td>
                        </tr>
                        <ng-container *ngIf="!companyId.value">
                            <tr>
                                <td class="required-label">... Payee Recipient</td>
                                <td class="required-label">{{ onetimeCompanyRecipient.value }}</td>
                            </tr>
                            <tr>
                                <td class="required-label">... Payee Address</td>
                                <td class="required-label">{{ onetimeCompanyAddress1.value }} {{
                                        onetimeCompanyAddress2.value
                                    }}
                                    {{ onetimeCompanyCity.value }}, {{ onetimeCompanyState.value }} {{
                                        onetimeCompanyZip.value
                                    }}
                                </td>
                            </tr>
                        </ng-container>
                        <ng-container *ngIf="is211?.value">
                            <tr *ngIf="checkMemo.value">
                                <td class="required-label">... Special Request</td>
                                <td class="required-label">{{ checkMemo.value }}</td>
                            </tr>
                        </ng-container>
                        </tbody>
                    </table>
                    <table *ngIf="isExpedite" style="table-layout: fixed" class="table table-bordered">
                        <tr>
                            <td>A. Requested By ({{ currentPledge.enteredDate | moment: 'L' }}):</td>
                            <td>{{ currentPledge.enteredBy }}</td>
                        </tr>
                        <tr>
                            <td>B. Authorized By & Step C.:</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>C. Approved Manual Pay On-line (Y/N):</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>D. Finance Shifted to Pd. On-line By:</td>
                            <td></td>
                        </tr>
                    </table>
                </div>
            </pledge-confirmation>
            <prompt-dialog #deletePrompt
                           [titleText]="'Delete Pledge?'"
                           [promptText]="'Are you sure you want to delete this Pledge?'">
            </prompt-dialog>
        </div>
    </div>
</section>
