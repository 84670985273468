<modal #modal>
  <modal-header>
    <div class="row">
      <div class="col-md-12">
        <h4 class="modal-title">
          Request Client's SSN
        </h4>
      </div>
    </div>
  </modal-header>
  <modal-body>
    <label class="col-xs-12 required-label col-form-label" for="userPassword">Enter Password</label>
    <div class="form-control-container col-xs-12">
      <input [formControl]="userPassword" id="userPassword" type="password" class="form-control" placeholder="Password">
    </div>
    <div class="row">
      <div class="col-xs-12">
        <h4 style="text-align: center;">{{fullSsn}}</h4>
      </div>
    </div>
  </modal-body>
  <modal-footer>
    <button type="button" class="btn btn-default" (click)="cancelClicked()">Cancel</button>
    <button type="button" class="btn btn-primary" (click)="revealSsn()">Request SSN</button>
  </modal-footer>
</modal>
