import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {Observable} from 'rxjs';
import {Router, ActivatedRoute} from '@angular/router';
import {PagedListOfAgency, SystemData, User, UserType} from '../../../services/api.client';
import {splitCamelCase} from '../../../utilities/Util';
import {ITableHeader, TableComponent} from '../../shared/table/TableComponent';
import {Agency} from '../../../services/api.client';
import {PledgeType} from '../../../services/api.client';

import {AgencyService} from '../../../services/AgencyService';
import {PledgeTypeService} from '../../../services/PledgeTypeService';
import {DropdownValue} from '../../../utilities/DropdownComponent';
import {AgencyStatusType} from '../../../services/api.client';
import {delay} from 'rxjs/operators';
import {select, Store} from '@ngrx/store';
import * as fromRoot from '../../../store';
import {
  selectAgencyDataAgencies,
  selectAgencyDataAgenciesItems,
  selectAgencyDataAgenciesTotalCount
} from '../../../store/selectors/agency.selectors';
import {selectPledgeTypeDataPledgeTypesItems} from '../../../store/selectors/pledgeType.selectors';
import {selectSecurityStateDataCurrentUser, selectSecurityStateDataSystemData} from '../../../store/selectors/security.selectors';
// shared
// store
// models
// services
// utilities

@Component({
  templateUrl: './AgencyListComponent.html',
  styleUrls: ['./AgencyListComponent.scss'],
})

export class AgencyListComponent implements OnInit, AfterViewInit {

  constructor(private _agencyService: AgencyService,
              private _pledgeTypeService: PledgeTypeService,
              private _route: ActivatedRoute,
              private _router: Router,
              private _store: Store<fromRoot.AppState>) {
    this.currentUser$ = this._store.pipe(select(selectSecurityStateDataCurrentUser));
    this.systemData$ = this._store.pipe(select(selectSecurityStateDataSystemData));
    this.pagedList$ = this._store.pipe(select(selectAgencyDataAgencies));
    this.agencies$ = this._store.pipe(select(selectAgencyDataAgenciesItems));
    this.pledgeTypes$ = this._store.pipe(select(selectPledgeTypeDataPledgeTypesItems));
    this.totalCount$ = this._store.pipe(select(selectAgencyDataAgenciesTotalCount));
  }

  get queryParams(): any {
    return this.params;
  }
  isLoading: boolean;
  pledgeTypeId = '';
  searchValue = '';
  tableHeaders: ITableHeader[];
  params: any;

  @ViewChild(TableComponent)
  table: TableComponent;

  // subscription
  systemData: SystemData;
  systemData$: Observable<SystemData>;
  currentUser: User;
  currentUser$: Observable<User>;
  pagedList: PagedListOfAgency;
  pagedList$: Observable<PagedListOfAgency>;
  agencies: Agency[];
  agencies$: Observable<Agency[]>;
  pledgeTypes: PledgeType[];
  pledgeTypes$: Observable<PledgeType[]>;

  // sorting
  currentSortName = 'AgencyName';
  currentSorting = false;


  // PAGINATION
  limit = 10;
  currentPage = 1;
  maxSize = 1;
  totalCount: number;
  totalCount$: Observable<number>;

  // Dropdowns
  pledgeTypeDropdown: DropdownValue[];

  ngAfterViewInit(): void {
    this._route.params
      .pipe(delay(0)) // fixes expression has changed after it was checked.
      .subscribe(params => {
      this.searchValue = params.searchValue || '';
      this.pledgeTypeId = params.pledgeTypeId || '';
      this.limit = +params.limit || 10;
      this.currentPage = +params.currentPage || 1;
      this.params = {
        searchValue: this.searchValue,
        pledgeTypeId: this.pledgeTypeId,
        limit: this.limit,
        includeDisable: true,
        sortName: this.currentSortName,
        descending: this.currentSorting,
        currentPage: this.currentPage,
      };
      this.table.request = this._agencyService.getAgencies(
        this.searchValue,
        this.pledgeTypeId,
        true,
        this.limit,
        this.currentSortName,
        this.currentSorting,
        (this.currentPage - 1) * this.limit
      );
    });
  }

  ngOnInit(): void {
    this.currentUser$.subscribe(user => this.currentUser = user);
    this.systemData$.subscribe(systemData => this.systemData = systemData);
    this.pagedList$.subscribe(pagedList => this.pagedList = pagedList);
    this.agencies$.subscribe(agencies => this.agencies = agencies);
    this.pledgeTypes$.subscribe(pledgeTypes => this.pledgeTypes = pledgeTypes);
    this.totalCount$.subscribe(totalCount => this.totalCount = totalCount);
    this.tableHeaders = [
      {text: 'Agency Name', sortKey: 'AgencyName', ascending: true},
    ];
    // add additional columns for admin user
    if (this.currentUser.userType === UserType.SystemAdmin) {
      this.tableHeaders.push({text: 'Status', sortKey: 'Status'});
    }
    this.loadPledgeTypes();
  }

  // Current Selected Agency
  setCurrentSelectedAgency(agency: Agency): void {
    this._agencyService.setCurrentSelectedAgency(agency);
  }

  sortAgencies(options: ITableHeader): void {
    this.currentSortName = options.sortKey;
    this.currentSorting = !options.ascending;
    this.filterAgencies();
  }

  // pagination methods
  setLimit(limit: number): void {// # of rows per page
    this.limit = limit;
    this.filterAgencies();
  }

  setPage(page: number): void {
    setTimeout(() => {
      this.currentPage = page; // set current page
      this.filterAgencies();
    });
  }

  // set selected Pledge Type ID
  selectedPledgeType(pledgeType): void {
    this.pledgeTypeId = pledgeType;
  }

  // dropdown - pledge types
  loadPledgeTypes(): void {// get Pledge Types
    this._pledgeTypeService.getPledgeTypes(
      '',
      '',
      false,
      100,
      (this.currentPage - 1) * this.limit
    );
    this.pledgeTypes$.subscribe(() => {
      // set Pledge Types drop-down
      if (this.pledgeTypes) {
        this.pledgeTypeDropdown = [];
        for (const pledgeType of this.pledgeTypes) {
          this.pledgeTypeDropdown.push(new DropdownValue(pledgeType.id, pledgeType.name));
        }
      }
    });
  }

  filterAgencies(): void {// get agencies
    this._router.navigate(['/app/agencies', {
      searchValue: this.searchValue,
      pledgeTypeId: this.pledgeTypeId,
      includeDisabled: true,
      limit: this.limit,
      sortName: this.currentSortName,
      descending: this.currentSorting,
      currentPage: this.currentPage,
    }]).then();
  }

  // search value
  setSearchValue(searchValue): void {
    this.searchValue = searchValue;
  }

    // format Agency Status
    formatAgencyStatus(status: AgencyStatusType): string {
      if (!status || !status.toString().trim()) {
          return 'missing status';
      }

      return splitCamelCase(status.toString() || 'missing status', true);
    }
}
