import {inject, Injectable} from '@angular/core';
import {FundTypeClient, FundTypeSelection} from './api.client';
import {Observable} from 'rxjs';

@Injectable()
export class FundTypeService {
    private _fundTypeClient = inject(FundTypeClient);

    getFundPledgeTypes(): Observable<FundTypeSelection[]> {
        return this._fundTypeClient.getFundAdministrativeAgencyFundTypes();
    }
}
