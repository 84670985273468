/***********************************
 *
 * WARNING:  This file is generated.
 * PLEASE do not attempt to edit it
 *
 ***********************************/


export enum PledgeAssistanceTypeType {
  Electric = 0,
  Gas = 1,
  RentEviction = 2,
  RentDeposit = 3,
  Mortgage = 4,
  EmergencyHotel = 5,
  Prescription = 6,
  Water = 7,
  Other = 8
}


