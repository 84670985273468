<div class="container">
    <h1 class="page-title">County Overview</h1>
    <!--section-->
    <section class="widget">
        <div class="widget-body">
            <div class="mt">
                <!--form-->
                <form class="form-horizontal" [formGroup]="form" novalidate>
                    <!--County-->
                    <div class="row form-group">
                        <div class="col-md-12 p-l-3 p-r-3">
                            <!--Name-->
                            <div class="row form-group">
                                <span class="m-t-05 required-label col-md-3">Name</span>
                                <input [formControl]="countyNameControl"
                                       name="countyNameControl"
                                       class="form-control col-md-9"
                                       type="text"
                                       placeholder="County Name">
                            </div>
                            <!--Active Internal-->
                            <div class="row form-group"
                                 [class.has-danger]="!isActiveInternalControl?.valid && isActiveInternalControl?.touched">
                                <span class="m-t-05 required-label col-md-3">Enable Internal Application</span>
                                <input type="checkbox"
                                       [formControl]="isActiveInternalControl"
                                       name="isActiveInternalControl"
                                       class="custom-checkbox"
                                />
                            </div>
                            <!--Active External-->
                            <div class="row form-group"
                                 [class.has-danger]="!isActiveExternalControl?.valid && isActiveExternalControl?.touched">
                                <span class="m-t-05 required-label col-md-3">Enable Public Application</span>
                                <input type="checkbox"
                                       [formControl]="isActiveExternalControl"
                                       name="isActiveExternalControl"
                                       class="custom-checkbox"
                                />
                            </div>
                            <div *ngIf="isActiveExternalValue" class="form-group"
                                 [class.has-danger]="!selectedFundAdministrativePledgeTypeControl?.valid && selectedFundAdministrativePledgeTypeControl?.touched">
                                <div class="row">
                                    <span class="m-t-05 required-label col-md-3">Fund Organization</span>
                                    <select class="drop-down-line form-control col-md-9"
                                            [formControl]="selectedFundAdministrativePledgeTypeControl">
                                        <option value="">-- Fund Organization --</option>
                                        <option *ngFor="let fundAdministrativePledgeType of fundAdministrativePledgeTypes"
                                                [value]="fundAdministrativePledgeType.id">
                                            {{ fundAdministrativePledgeType.name }}
                                        </option>
                                    </select>
                                </div>
                                <div class="row " *ngIf="selectedFundAdministrativePledgeTypeControl?.hasError('requiredWhen') && selectedFundAdministrativePledgeTypeControl?.touched">
                                    <span class="alert alert-danger m-t-05 offset-md-3" style="width: 100%">
                                        Fund Organization is required when enabling public application
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!--submit-->
                    <div class="row form-group">
                        <div class="col-md-12">
                            <div class="col-md-2">
                            </div>
                            <div class="col-md-9 btn-form pull-right">
                                <cancel-button class="p-l-1 pull-right" [route]="'/app/counties'"></cancel-button>

                                <!--save-->
                                <button [disabled]="!form.dirty"
                                        type="button"
                                        class="btn btn-primary pull-right"
                                        (click)="onSave()"
                                >Save County
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </section>
</div>
