import {AssistanceApplicationStateAuditType} from '../../../services/api.client';
import {Option} from './option';

export const ASSISTANCE_APPLICATION_STATE_AUDIT_TYPE_OPTIONS: Option<AssistanceApplicationStateAuditType>[] = [
    {
        label: 'Pass',
        value: AssistanceApplicationStateAuditType.Pass,
    },
    {
        label: 'Fail',
        value: AssistanceApplicationStateAuditType.Fail,
    },
    {
        label: 'Pass With Correction',
        value: AssistanceApplicationStateAuditType.PassWithCorrection,
    },
    {
        label: 'Not Yet Audited',
        value: AssistanceApplicationStateAuditType.NotYetAudited,
    }
];
