<div class="row">
    <div class="col-xs-12">
        <div *ngIf="showManualHeader" class="form-group row" [style.z-index]="3">
            <div class="col-xs-12"></div>
            <label class="col-md-6 manualDateLabel">
                <ng-content select="[entryDateLabel]"></ng-content>
            </label>
            <datePicker class="col-md-4" [control]="entryDate" [autoClose]="true"></datePicker>
            <button class="btn btn-primary col-md-2" [disabled]="!entryDate" (click)="form.submitPayPledge()">Pay
                Pledges
            </button>
        </div>
        <div *ngIf="showEntryDate" class="form-group row" [style.z-index]="3">
            <label class="col-md-8">
                <ng-content select="[entryDateLabel]"></ng-content>
            </label>
            <datePicker class="col-md-4" [control]="entryDate" [autoClose]="true"></datePicker>
        </div>
        <div *ngIf="showEndDate" class="form-group row" [style.z-index]="3">
            <label class="col-md-8">
                <ng-content select="[entryDateLabel]"></ng-content>
            </label>
            <datePicker class="col-md-4" [control]="endDate" [autoClose]="true"></datePicker>
        </div>
        <div *ngIf="showDateRange" class="form-group row">
            <label class="col-md-2">Start Date:</label>
            <datePicker class="col-md-4" [control]="startDate" [autoClose]="true" [style.z-index]="2"></datePicker>
            <label class="col-md-2">End Date:</label>
            <datePicker class="col-md-4" [control]="endDate" [autoClose]="true" [style.z-index]="1"></datePicker>
        </div>
        <div *ngIf="showDisplayPledgeStatus" class="form-group row">
            <label class="col-md-3">Displayed Pledge Status(es):</label>
            <div class="col-md-9">
                <select pledgeStatus [formControl]="displayPledgeStatus" class="form-control"></select>
            </div>
        </div>
        <div *ngIf="showShiftPledgeStatus" class="form-group row">
            <label class="col-md-3">Shift Pledge Status(es):</label>
            <div class="col-md-9">
                <select pledgeStatus [formControl]="shiftPledgeStatus" class="form-control"></select>
            </div>
        </div>
        <div *ngIf="showPledgeTypes" class="form-group row">
            <label class="col-md-3">Fund Type:</label>
            <div class="col-md-9">
                <select pledgeTypes [formControl]="pledgeTypeId" class="form-control" name="pledgeType">
                    <option value="null">All Fund Types</option>
                </select>
            </div>
        </div>
        <div *ngIf="showMultiChoicePledgeTypes" class="form-group row">
            <label class="col-md-3">Fund Type:</label>
            <div class="col-md-9">
                <pledgeTypeCheckbox [showSelectAll]="true"
                                    [filterByRole]="filterPledgeTypesByRole"
                        [control]="pledgeTypeIds" [requireOne]="true"
                                    [preselectAll]="true"></pledgeTypeCheckbox>
            </div>
        </div>
        <div *ngIf="showCompany" class="form-group row">
            <label class="col-md-3">Company:</label>
            <div class="col-md-9">
                <input #company type="text" class="form-control" (click)="companyListModal.openModal()"
                       value="All Companies">
                <company-box
                        (onCompanySelected)="companySelected($event); company.value = ($event?.noCompany ? 'All Companies' : $event?.company?.name) || 'All Companies'"></company-box>
            </div>
        </div>
        <ng-content select="[footer]"></ng-content>
    </div>

    <prompt-dialog
            [titleText]="'Update Pledges?'"
            [promptText]="'Are you sure you want to update the following pledges to a ' + shiftPledgeStatusDisplay?.toLowerCase() + ' status?'">
    </prompt-dialog>
</div>
