import {Injectable} from '@angular/core';

declare var Messenger: any;

@Injectable()
export class NotificationService {
  constructor() {
    Messenger({
      // Let's put the messenger in the top left corner
      extraClasses: 'messenger-fixed messenger-on-right messenger-on-bottom'
    });
  }

  showSuccess(message: string, timeout: number = 5): void {
    Messenger().post({
      message,
      type: 'success',
      hideAfter: timeout
    });
  }

  showError(message: string, timeout: number = 5): void {
    Messenger().post({
      message,
      type: 'error',
      hideAfter: timeout
    });
  }


}
