<modal #modal>
  <modal-header>
    <div class="row">
      <div class="col-md-12">
        <h4 class="modal-title m-b-05">Agencies</h4>
        <form (submit)="filterAgencies()" novalidate>
          <input type="text" placeholder="Search By Name" class="form-control pull-left" [formControl]="searchBox">
          <button type="submit" class="btn btn-primary pull-left m-l-05">Search</button>
        </form>
      </div>
    </div>
  </modal-header>
  <modal-body>
    <table-dynamic id="datatable-table"
                   class="table table-striped table-hover"
                   [currentPage]="currentPage"
                   [totalCount]="totalCount"
                   [headers]="headers"
                   (pageChanged)="setPage($event)"
                   (limitChange)="setLimit($event)">
      <tr *ngFor="let agency of agencies" [ngClass]="{selected: agency == selectedRow}" (click)="setClickedRow(agency)">
        <td>{{agency.agencyName}}</td>
      </tr>
    </table-dynamic>
  </modal-body>
  <modal-footer>
    <button type="button" [disabled]="!selectedRow" class="btn btn-primary" (click)="okClicked(selectedRow)">Ok</button>
    <button type="button" class="btn btn-default" data-dismiss="modal" (click)="cancelClicked()">Cancel</button>
    <button type="button" class="btn btn-danger pull-left" data-dismiss="modal" (click)="resetClicked()">No Agency
    </button>
  </modal-footer>
</modal>
