import {tap, filter, map} from 'rxjs/operators';
import {Component, Self, Input, OnInit, OnDestroy, Output, EventEmitter} from '@angular/core';
import {Subject, Subscription} from 'rxjs';

import * as moment from 'moment';
import Moment = moment.Moment;
import {Calendar} from './Calendar';
import {UntypedFormControl} from '@angular/forms';
import {DateColumn} from './DateColumn';

@Component({
  selector: 'datePicker',
  templateUrl: './DatePickerComponent.html',
  styleUrls: ['./DatePickerComponent.scss'],
  providers: [
    Calendar
  ]
})
export class DatePickerComponent implements OnInit, OnDestroy {

  hidden = true;

  @Input('defaultValue')
  defaultValue = '';

  @Input('placeholder')
  placeholder = '';

  _formControl: UntypedFormControl;
  _formControlSub: Subscription;

  isManualEdit = false;

  @Input('control')
  set formControl(formControl: UntypedFormControl) {
    if (this._formControlSub) {
      this._formControlSub.unsubscribe();
    }
    this._formControl = formControl;
    if (this._formControl) {
      this._formControlSub = this._formControl.valueChanges.pipe(
        filter(() => !this.isManualEdit),
        // ignore invalid values
        filter((date) => !!date),
        map((date) => moment.isMoment(date) ? date : moment(date)),
        // ignore same date
        filter((date) => !this.isSameDate(date)), )
        .subscribe((date: Moment) => this.selectedDateColumn = date);
      if (this._formControl.value) {
        this.selectedDateColumn = this._formControl.value;
      }
    }
  }

  get formControl(): any {
    return this._formControl;
  }


  @Input('controlFormat')
  formControlFormat: string; // moment string format, example 'L'

  @Input('autoClose')
  autoClose: boolean;

  @Output('dateChange')
  dateChange = new EventEmitter<Moment>();

  _minDate: Moment;
  @Input('minDate')
  set minDate(date: any) {
    this._minDate = (date) ? moment(date).startOf('day') : null;
    if (this._minDate && this._selectedDateColumn && this._selectedDateColumn.isBefore(this._minDate)) {
      this.selectedDateColumn = this._minDate;
    }
  }

  _maxDate: Moment;
  @Input('maxDate')
  set maxDate(date: any) {
    this._maxDate = (date) ? moment(date).startOf('day') : null;
    if (this._maxDate && this._selectedDateColumn && this._selectedDateColumn.isAfter(this._maxDate)) {
      this.selectedDateColumn = this._maxDate;
    }
  }

  _initialDate: Moment;
  @Input('initialDate')
  set initialDate(date: any) {
    this._initialDate = (date) ? moment(date) : null;
  }

  dateClicks = new Subject<{ event: MouseEvent, date: DateColumn }>();
  _selectedDateColumn: DateColumn = null;

  set selectedDateColumn(date: any) {
    date = this.parseToDateColumn(date);
    // ignore same column
    if (this._selectedDateColumn === date) {
      return;
    }
    // make previous column inactive
    if (this._selectedDateColumn) {
      this._selectedDateColumn.active = false;
    }

    this._selectedDateColumn = date;
    let mDate: Moment = null;
    if (this._selectedDateColumn) {
      if (!this.calendar.isSameMonth(this._selectedDateColumn)) {
        this.calendar.setMonth(this._selectedDateColumn);
      }
      this._selectedDateColumn.active = true;
      mDate = this._selectedDateColumn.toMoment();
      mDate.toString = () => mDate.format(this.formControlFormat || 'L');
    }

    if (this.formControl) {
      const fDate: Moment = this.formControl.value;
      // if formControl has a moment object, update it without changing its reference
      // otherwise add new moment date
      if (moment.isMoment(fDate)) {
        if (!this._isSameDate(mDate, fDate)) {
          fDate.year(mDate?.year()).month(mDate?.month()).date(mDate?.date());
          // force notify since we didn't change reference
          this.formControl.updateValueAndValidity({onlySelf: false, emitEvent: true});
        }
      } else if (fDate !== mDate) {
        this.formControl.setValue(mDate, {onlySelf: false, emitEvent: true});
      }
    }
    this.dateChange.emit(mDate);
    if (this.autoClose) {
      this.hide();
    }
  }

  parseToDateColumn(date: any): DateColumn {
    if (date instanceof DateColumn) {
      return date;
    } else if (moment(date).isValid()) {
      return this.calendar.getDateColumn(moment(date));
    } else {
      return null;
    }
  }

  get formattedDate(): string {
    if (!this._selectedDateColumn) {
      return this.defaultValue;
    }
    return this._selectedDateColumn.format(this.formControlFormat || 'L');
  }

  get isMinMonth(): boolean {
    return this._minDate && this.calendar.firstDayOfMonth.isSameOrBefore(this._minDate);
  }

  get isMaxMonth(): boolean {
    return this._maxDate && this.calendar.lastDayOfMonth.isSameOrAfter(this._maxDate);
  }

  constructor(@Self() public calendar: Calendar) {
  }

  ngOnInit(): void {
    if (this._initialDate) {
      this.selectedDateColumn = this._initialDate;
    }
    this.dateClicks.pipe(
      map(event => event.date),
      // ignore dates before min date
      filter(column => !this._minDate || (this._minDate && column.isSameOrAfter(this._minDate))),
      // ignore dates after max date
      filter(column => !this._maxDate || (this._maxDate && column.isSameOrBefore(this._maxDate))),
      // ignore disabled columns
      filter(column => column.disable === false),
      // ignore dates not in current month
      filter(column => this.calendar.isSameMonth(column)),
      tap(() => this.hide()), )
      .subscribe((column) => this.selectedDateColumn = column);
  }

  isSameDate(date: Moment): boolean {
    if (!this._selectedDateColumn || !date) {
      return false;
    }
    const mDate = this._selectedDateColumn.toMoment();
    return this._isSameDate(mDate, date);
  }

  _isSameDate(date1: Moment, date2: Moment): boolean {
    return date1.isSame(date2, 'year') && date1.isSame(date2, 'month') && date1.isSame(date2, 'day');
  }

  show(): void {
    this.hidden = false;
  }

  hide(): void {
    this.hidden = true;
  }

  getDateCSS(column: DateColumn): string {
    let classes = this.calendar.getDateCSS(column);
    if (this._minDate && column.isBefore(this._minDate)) {
      classes += ' before_min_date';
    }
    if (this._maxDate && column.isAfter(this._maxDate)) {
      classes += ' after_max_date';
    }

    if (column === this._selectedDateColumn) {
      classes += ' active';
    }
    return classes;
  }

  ngOnDestroy(): void {
    this.calendar.dispose();
    this.dateClicks.unsubscribe();
    this._formControlSub?.unsubscribe();
  }
}
