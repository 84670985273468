import {Component, OnInit, Input, HostBinding, EventEmitter, Output, forwardRef, ViewChild} from '@angular/core';
import {EfnCase} from '../../../../../../../services/api.client';
import {UntypedFormControl} from '@angular/forms';
import {EfnService} from '../../../../../../../services/EfnService';
import {NotificationService} from '../../../../../../../services/NotificationService';
import {Observable} from 'rxjs';
import {AutoDisableParent} from '../../../../../../../directives/AutoDisableDirective';
import {Agency} from '../../../../../../../services/api.client';
import {AgencyListModalComponent} from '../../../../../agency/AgencyListModalComponent';
import {AgencyEfnCase} from '../../../../../../../services/api.client';
import {select, Store} from '@ngrx/store';
import * as fromRoot from '../../../../../../../store';
import {selectEfnDataAgencyEfnCases} from '../../../../../../../store/selectors/agency.selectors';

@Component({
  selector: 'efnInvitesTab',
  templateUrl: './EfnInvitesTabComponent.html',
  styleUrls: ['./EfnInvitesTabComponent.scss'],
  providers: [{provide: AutoDisableParent, useExisting: forwardRef(() => EfnInvitesTabComponent)}],
})
export class EfnInvitesTabComponent implements OnInit {

  constructor(
      private efnService: EfnService,
      private notificationService: NotificationService,
      private _store: Store<fromRoot.AppState>) {
    this.invitedAgencies$ = this._store.pipe(select(selectEfnDataAgencyEfnCases));
  }

  @HostBinding('class.row') styles = true;

  @Input()
  efnCase: EfnCase;

  invitedAgencies: AgencyEfnCase[];
  invitedAgencies$: Observable<AgencyEfnCase[]>;

  filteredInvitedAgencies: AgencyEfnCase[];

  newAgency: UntypedFormControl = new UntypedFormControl();
  selectedAgencyId: string;

  @Output()
  submittedInvites = new EventEmitter<void>();

  // MODAL
  @ViewChild(AgencyListModalComponent)
  agencyListModal: AgencyListModalComponent;

  @Output('onShowAgencyModal') _showAgencies = new EventEmitter();
  private agencyList: AgencyListModalComponent;

  // selected agency
  agency: Agency;
  selectedAgencyName = '-- Select Agency --';

  ngOnInit(): void {
    this.invitedAgencies$
      .subscribe((invites) => {
        this.invitedAgencies = invites;
        this.filteredInvitedAgencies = invites.filter((a) => a.agencyId !== this.efnCase.createdByAgencyId);
      });
  }

  refreshAgencyList(): void {
    this.efnService.getAgencyEfnCasesByEfnCaseId(this.efnCase.id);
  }

  // show Agency Modal
  showAgencyModal(): boolean {
    this.agencyListModal.open();
    return false;
  }

  onAgencySelected(selectedAgency): void {
    if (selectedAgency) {
      selectedAgency.agencyName ? this.selectedAgencyName = selectedAgency.agencyName : this.selectedAgencyName = '';
      this.selectedAgencyId = selectedAgency.id;
      this.sendInvite();
    } else {
      this.selectedAgencyName = '';
      this.selectedAgencyId = '';
    }
  }

  removeInvitedAgency(agencyEfnCaseId: string): void {
    this.efnService.removeAgencyEfnCase(agencyEfnCaseId)
      .subscribe(() => {
        this.refreshAgencyList();
      });
  }

  sendInvite(): void {
    this.efnService.inviteAgencies(this.selectedAgencyId, this.efnCase.id)
      .subscribe(() => {
        this.notificationService.showSuccess('Successfully sent invites.');
        this.refreshAgencyList();
        this.submittedInvites.emit();
      });
  }
}
