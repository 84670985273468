<modal #modal id="section-to-print">
  <modal-header [show-close]="false">
    <h3 class="modal-title"><b>{{ titleText }}</b></h3>
    <ng-content select="[title]"></ng-content>
  </modal-header>
  <modal-body>
    <div *ngIf="promptText">{{ promptText }}</div>
    <h3 title style="color: black; text-align: center" class="m-b-2">EFS Confirmation</h3>
    <ng-content select="[body]"></ng-content>
  </modal-body>
  <modal-footer [show-default-buttons]="false">
    <button class="btn btn-primary" (click)="printClicked()">{{ printButtonText }}</button>
    <button class="btn btn-default" (click)="cancelClicked()">{{ cancelButtonText }}</button>
  </modal-footer>
</modal>