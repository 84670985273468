<div class="row">
  <h2 class="offset-md-1 col-md-10">Pledge by Zip Code Summary Report</h2>
</div>
<div class="row">
  <div class="col-xs-12">
    <form pledgeReportForm #form [showDisplayPledgeStatus]="true" [showCompany]="true" novalidate>
      <button type="submit" class="btn btn-primary" (click)="form.submitPledgeDemographicByZipcodeReport()">Submit
      </button>
      <button class="btn btn-primary" (click)="form.downloadPledgeDemographicByZipcodeReportClick()">Export to Excel</button>
    </form>
    <h1 *ngIf="rows | async">Pledge by Zip Code Summary Report</h1>
    <reportTable [headers]="tableHeaders" [rows]="rows | async"></reportTable>
  </div>
</div>
