import {createFeatureSelector, createSelector} from '@ngrx/store';
import {ICountySpendingReportData} from '../ICountySpendingReportData';


const countySpendingReportFeatureSelector = createFeatureSelector<ICountySpendingReportData>("countySpendingReport");

export const selectCountySpendingReportIsLoading = createSelector(
    countySpendingReportFeatureSelector,
    (state) => state.isLoading
);

export const selectCountySpendingReportData = createSelector(
    countySpendingReportFeatureSelector,
    (state) => state.data
);

export const selectCountySpendingReportTotalCount = createSelector(
    countySpendingReportFeatureSelector,
    (state) => state.data?.totalCount
);
