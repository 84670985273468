import {createAction, props} from '@ngrx/store';
import {Client, PagedListOfClient} from '../../services/api.client';

export const setCurrentClient = createAction(
    '[Client] SET_CURRENT_CLIENT',
    props<{ payload: Client }>());
export const clientsDataIsLoading = createAction('[Client] CLIENTSDATA_ISLOADING');
export const clientsDataLoaded = createAction(
    '[Client] CLIENTSDATA_LOADED',
    props<{ payload: PagedListOfClient }>());
export const clientsDataClear = createAction('[Client] CLIENTSDATA_CLEAR');
