<div class="row">
    <div class="col-xs-12">

    </div>
    <div *ngIf="userHasAgreed" class="col-xs-12">
        <p>You last accepted the user’s agreement on {{loggedInUser?.uwAgreementDate | moment: 'MM/DD/YYYY'}}. Click on
            Support/Info
            tab to see that agreement.</p>
    </div>
    <div *ngIf="!userHasAgreed" class="col-xs-12">
        <p>By clicking “I accept” below agency user acknowledges they have read in full the ‘<a
                href="http://www.stl.unitedway.org/wp-content/uploads/2017/05/Direct-Asst-Portal-ConfidentialityAgreement-5-17.pdf"
                target="_blank">Agency Partner Data Sharing;
            Client Confidentiality; and Release of Client Information Agreement</a>’. And further they certify that they
            understand it and will abide by terms of the agreement.
            Each year all users will need to re-certify the above. The agreement is always available to review or print
            from
            the site’s support tab.</p>
        <p>After accepting you will need to log into the system again.</p>
        <button class="btn-success btn btn-large" (click)="saveAgreementDate()">I Accept</button>
    </div>
</div>
