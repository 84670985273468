import {Component, Input, OnInit} from '@angular/core';
import {EfnService} from '../../../../../../../services/EfnService';
import {EfnCase, UserType} from '../../../../../../../services/api.client';
import {AgencyEfnCase} from '../../../../../../../services/api.client';
import {Router} from '@angular/router';
import {EfnCaseStatusType} from '../../../../../../../services/api.client';
import {User} from '../../../../../../../services/api.client';
import {Observable} from 'rxjs';
import {select, Store} from '@ngrx/store';
import * as fromRoot from '../../../../../../../store';
import {selectSecurityStateDataCurrentUser} from '../../../../../../../store/selectors/security.selectors';

@Component({
  selector: 'efnAgenciesTab',
  templateUrl: './EfnAgenciesTabComponent.html',
  styleUrls: ['./EfnAgenciesTabComponent.scss']
})
export class EfnAgenciesTabComponent implements OnInit {

  currentUser: User;
  currentUser$: Observable<User>;

  _efnCase: EfnCase;
  @Input()
  set efnCase(efnCase: EfnCase) {
    this._efnCase = efnCase;
    if (this._efnCase) {
      this.agencies = null;
      this.efnService.getAgencyEfnCasesByEfnCaseId(this._efnCase.id)
        .subscribe((agencies: AgencyEfnCase[]) => this.agencies = agencies);
    }
  }

  get efnCase(): EfnCase {
    return this._efnCase;
  }

  agencies: AgencyEfnCase[] = null;

  constructor(
      private efnService: EfnService,
      private router: Router,
      private _store: Store<fromRoot.AppState>) {
    this.currentUser$ = this._store.pipe(select(selectSecurityStateDataCurrentUser));
  }

  ngOnInit(): void {
    this.currentUser$.subscribe(user => this.currentUser = user);
  }

  goToPledge(pledgeId: string): void {
    const route = '/app/pledge/' + pledgeId;
    this.router.navigate([route]).then();
  }

  isFunded(): boolean {
    return this.efnCase.status === EfnCaseStatusType.Funded;
  }

  canAccessPledge(agencyEfnCase: AgencyEfnCase): boolean {
    return (agencyEfnCase.agencyId === this.currentUser.agencyId) ||
        this.currentUser.userType === UserType.SystemAdmin;
  }

  createPledge(agencyEfnCase: AgencyEfnCase): void {
    // compile params
    const params = {
      agencyEfnCaseId: agencyEfnCase.id,
      clientId: this.efnCase.clientId,
      clientCompanyAccountNumber: this.efnCase.accountNumber,
      leverageAmount: this.efnCase.totalNeed - agencyEfnCase.originalCommitAmount,
      pledgedAmount: agencyEfnCase.originalCommitAmount,
      companyId: this.efnCase.companyId,
      companyName: this.efnCase.companyName,
      onetimeCompanyRecipient: this.efnCase.paymentTo,
      onetimeCompanyAddress1: this.efnCase.payeeAddress,
      onetimeCompanyAddress2: this.efnCase.payeeAddress2,
      onetimeCompanyCity: this.efnCase.payeeCity,
      onetimeCompanyState: this.efnCase.payeeState,
      onetimeCompanyZip: this.efnCase.payeeZip
    };
    // send to pledge Component
    this.router.navigate(['/app/client', this.efnCase.clientId, 'pledge', 'add'], {queryParams: params}).then(); //
  }
}
