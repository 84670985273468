import {Component, Input, OnInit} from '@angular/core';
import {FormControl} from '@angular/forms';
import {
    INTERNAL_ASSISTANCE_APPLICATION_STATUS_OPTIONS,
    PUBLIC_ASSISTANCE_APPLICATION_STATUS_OPTIONS
} from '../../../../../../shared/constants/options/assistance-application-status-options';
import {Option} from '../../../../../../shared/constants/options/option';

@Component({
    selector: 'app-assistance-application-status',
    template: `
        <select [formControl]="control" class="form-control">
            <option *ngFor="let option of options" [value]="option.value">{{ option.label }}</option>
        </select>
    `
})
export class AssistanceApplicationStatusComponent implements OnInit {
    @Input() control: FormControl<number> | undefined;
    @Input() isPublic: boolean | undefined = false;

    public options: Option<string>[] = [];

    ngOnInit(): void {
        if (this.control === undefined) {
            throw new Error('control is required for app-assistance-application-status');
        }

        this.options = this.isPublic ? PUBLIC_ASSISTANCE_APPLICATION_STATUS_OPTIONS : INTERNAL_ASSISTANCE_APPLICATION_STATUS_OPTIONS;
    }
}
