import {combineLatestWith, from as observableFrom, Observable} from 'rxjs';

import {map, mergeMap, take, catchError} from 'rxjs/operators';
import {Injectable, Injector} from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import {Client, KcApplicationResult} from '../services/api.client';
import {getRouteParam} from '../utilities/Util';
import {KcApplicationService} from '../services/KcApplicationService';
import {select, Store} from '@ngrx/store';
import * as fromRoot from '../store';
import {selectClientDataCurrentClient} from '../store/selectors/client.selectors';
import {selectKcAppDataCurrentSelectedKcApplication} from '../store/selectors/kcApplication.selectors';

@Injectable()
export class ResolveClientKcApplication  {

  client$: Observable<Client>;

  application$: Observable<KcApplicationResult>;


  constructor(private injector: Injector, private _store: Store<fromRoot.AppState>) {
      this.client$ = this._store.pipe(select(selectClientDataCurrentClient));
      this.application$ = this._store.pipe(select(selectKcAppDataCurrentSelectedKcApplication));
  }


  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot)
      : Observable<any> | Promise<any> | any {
    return this.application$.pipe(
      combineLatestWith(this.client$.modelExists()),
      take(1),
      mergeMap(([application, client]) => {
        const id = getRouteParam(route, 'applicationId');
        if (!application || application.id !== id) {
          return this.injector.get(KcApplicationService).getKcApplicationById(id)
              .pipe(
                catchError(() => this.injector.get(Router).navigate(['/app/client', client.id]))
              );

        }
        return observableFrom([application]);
      }), map((application) => !!application), );

  }
}
