import {Injectable} from '@angular/core';
import {ActivityAuditClient, PagedListOfActivityAuditViewModel} from './api.client';
import {Observable} from 'rxjs';
import {share} from 'rxjs/operators';
import {Store} from '@ngrx/store';
import * as fromRoot from '../store';
import {setActivityAuditIsLoading, setActivityAuditLoaded} from '../store/actions/activityAudit.actions';
import {NotificationService} from './NotificationService';

@Injectable()
export class ActivityAuditService {
    constructor(
        private _activityAuditClient: ActivityAuditClient,
        private _store: Store<fromRoot.AppState>,
        private _notificationService: NotificationService) {
    }

    searchActivityAudits(
        id: string,
        sortName: string,
        descending: boolean,
        limit: number,
        offset: number
    ): Observable<PagedListOfActivityAuditViewModel> {
        this.setIsLoadingAction();
        const request = this._activityAuditClient.searchAssistanceApplicationActivityAudits(
            id,
            sortName,
            null,
            descending,
            limit || 10,
            offset || 0
        ).pipe(share());

        request.subscribe({
            next: (data) => {
                this.setPagedActivityAuditDataAction(data);
            },
            error: () => {
                this._notificationService.showError('Error loading Activity Audit Data. Please try again.');
            }
        });

        return request;
    }

    private setIsLoadingAction(): void {
        this._store.dispatch(setActivityAuditIsLoading());
    }

    setPagedActivityAuditDataAction(pagedActivityAuditData: PagedListOfActivityAuditViewModel): void {
        this._store.dispatch(setActivityAuditLoaded({payload: pagedActivityAuditData}));
    }
}
