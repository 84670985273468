import {Component, OnInit, ViewChild, Input, Output, EventEmitter, AfterViewInit, Injector} from '@angular/core';
import {FormBuilder, FormControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {CompanyListModalComponent} from '../company/CompanyListModalComponent';
import {PaymentProcessingService} from '../../../services/PaymentProcessingService';
import * as moment from 'moment';
import {
    PagedListOfCompanyMailingReport,
    PagedListOfProcessingReport, PledgeReportParams,
    PledgeStatusType,
    ShiftPledgeReportSummary
} from '../../../services/api.client';
import {PromptComponent} from '../../shared/dialogs/PromptComponent';
import {Pledge} from '../../../services/api.client';
import {delay} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {CompanySelectedEvent} from '../../shared/events/company-selected-event';
import {NotificationService} from '../../../services/NotificationService';


(window as any).m = moment;

@Component({
    selector: '[paymentProcessingForm]',
    templateUrl: './PaymentProcessingFormComponent.html',
    styleUrls: ['./PaymentProcessingFormComponent.scss'],
})

export class PaymentProcessingFormComponent implements OnInit, AfterViewInit {

    public form = this.fb.group({
        entryDate: [moment()
            .startOf('year')
            .format('MM/DD/YYYY')
        ],
        startDate: [moment()
            .startOf('year')
            .format('MM/DD/YYYY')
        ],
        endDate: [moment()
            .endOf('day')
            .format('MM/DD/YYYY')
        ],
        displayPledgeStatus: new FormControl<PledgeStatusType>(PledgeStatusType.Confirmed),
        shiftPledgeStatus: [null],
        pledgeTypeId: [null],
        pledgeTypeIds: [null],
        companyId: [''],
    });

    public entryDate = this.form.get('entryDate') as FormControl;
    public startDate = this.form.get('startDate') as FormControl;
    public endDate = this.form.get('endDate') as FormControl;
    public displayPledgeStatus = this.form.get('displayPledgeStatus') as FormControl;
    public shiftPledgeStatus = this.form.get('shiftPledgeStatus') as FormControl;
    public pledgeTypeId = this.form.get('pledgeTypeId') as FormControl;
    public pledgeTypeIds = this.form.get('pledgeTypeIds') as FormControl;
    public companyId = this.form.get('companyId') as FormControl;

    constructor(private fb: FormBuilder, private _reportService: PaymentProcessingService, private injector: Injector) {

    }

    shiftPledgeStatusDisplay: string;
    pledges: Pledge[] = new Array<Pledge>();
    @Output() pledgesChanged: EventEmitter<any> = new EventEmitter();

    @Input('isManual') isManual: boolean;
    @Input('showManualHeader') showManualHeader: boolean;
    @Input('showEntryDate') showEntryDate: boolean;
    @Input('showEndDate') showEndDate: boolean;
    @Input('showDateRange') showDateRange: boolean;
    @Input('showDisplayPledgeStatus') showDisplayPledgeStatus: boolean;
    @Input('showShiftPledgeStatus') showShiftPledgeStatus: boolean;
    @Input('showPledgeTypes') showPledgeTypes: boolean;
    @Input() showMultiChoicePledgeTypes: boolean;
    @Input() filterPledgeTypesByRole: boolean;
    @Input('showCompany') showCompany: boolean;

    @ViewChild(CompanyListModalComponent) companyListModal: CompanyListModalComponent;
    @ViewChild(PromptComponent) promptModal: PromptComponent;
    @Output('onShowPromptModal') _showPrompt = new EventEmitter();

    ngOnInit(): void {
        this.form.get('shiftPledgeStatus')?.valueChanges
            .pipe(delay(0)) // fixes expression has changed after it was checked.
            .subscribe(v => {
                this.shiftPledgeStatusDisplay = PledgeStatusType[v];
            });
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            this.form.get('displayPledgeStatus').reset(PledgeStatusType.Pending);
        });
    }

    submit(): any {
        return void 0;
    }

    submitPendingToPaid(): Observable<ShiftPledgeReportSummary[]> {
        const formValue = this.form.value;

        this.isManual = true;
        formValue.shiftPledgeStatus = PledgeStatusType.Paid;
        formValue.displayPledgeStatus = PledgeStatusType.Pending;

        const pledgeReportPrams = PledgeReportParams.fromJS(formValue);

        return this._reportService.buildShiftPledgeReport(pledgeReportPrams);
    }

    // existing infrastructure
    // submitManualPendingToPaid() {
    //     var form = this.form.value;
    //
    //     form.shiftPledgeStatus = PledgeStatusType.Paid;
    //     form.displayPledgeStatus = PledgeStatusType.Pending;
    //     return this._reportService.buildShiftPledgeReport(this.form.value);
    // }

    // new - for manual
    // getManualPendingToPaidPledges() {
    //     var form = this.form.value;
    //
    //     form.shiftPledgeStatus = PledgeStatusType.Paid;
    //     form.displayPledgeStatus = PledgeStatusType.Pending;
    //     let request = this._reportService.getManualPendingToPaidPledges(this.form.value);
    //     request.subscribe((data) => {
    //         this.pledges = data;
    //     }, (error) => {
    //     });
    //     this.pledgesChanged.emit(this.pledges);
    //     return request;
    // }

    submitConfirmedToPending(): Observable<ShiftPledgeReportSummary[]> {
        const formValue = this.form.value;
        formValue.shiftPledgeStatus = PledgeStatusType.Pending;
        formValue.displayPledgeStatus = PledgeStatusType.Confirmed;

        const pledgeReportParams = PledgeReportParams.fromJS(formValue);

        return this._reportService.buildShiftPledgeReport(pledgeReportParams);
    }

    submitPendingPledgesSummary(): Observable<ShiftPledgeReportSummary[]> {
        const formValue = this.form.value;
        const pledgeReportParams = PledgeReportParams.fromJS(formValue);

        return this._reportService.buildShiftPledgeReport(pledgeReportParams);
    }

    submitPayPledge(): boolean {
        // TODO: use right endpoint

        const formValue = this.form.value;
        formValue.shiftPledgeStatus = PledgeStatusType.Paid;
        formValue.displayPledgeStatus = PledgeStatusType.Pending;

        const pledgeReportParams = PledgeReportParams.fromJS(formValue);

        this.promptModal.open().subscribe(okClicked => {
            if (okClicked) {
                return this._reportService.changeUpdatePledgeStatus(pledgeReportParams);
            } else {
                return false;
            }
        });

        return false;
    }

    submitPendingPledgesDetail(offset?: number, limit?: number): Observable<PagedListOfProcessingReport> {
        const model = this.momentize(this.form.value);
        if (!model.companyId) {
            model.companyId = null;
        }
        if (!this.pledgeTypeIds.value.length) {
            this.injector.get(NotificationService).showError('Please select at least one fund.');
            return;
        }
        Object.assign(model, {offset, limit});
        return this._reportService.buildProcessingDetailReport(model);
    }

    downloadPendingPledgesDetail(): void {
        if (!this.pledgeTypeIds.value.length) {
            this.injector.get(NotificationService).showError('Please select at least one fund.');
            return;
        }
        const formValue = this.form.value;
        const pledgeReportParams = PledgeReportParams.fromJS(formValue);
        return this._reportService.buildProcessingDetailReportUrl(pledgeReportParams);
    }

    submitCompanyMailingLabel(offset?: number, limit?: number): Observable<PagedListOfCompanyMailingReport> {
        const model = this.momentize(this.form.value);
        Object.assign(model, {offset, limit});
        return this._reportService.buildCompanyMailingLabels(model);
    }

    downloadCompanyMailingLabel(): void { // TODO
        return this._reportService.buildCompanyMailingLabelsUrl();
    }

    submitLockPledges(): boolean {

        const formValue = this.form.value;
        formValue.shiftPledgeStatus = PledgeStatusType.Pending;
        formValue.displayPledgeStatus = PledgeStatusType.Confirmed;

        const pledgeReportParams = PledgeReportParams.fromJS(formValue);

        this.promptModal.open().subscribe(okClicked => {
            if (okClicked) {
                return this._reportService.changeUpdatePledgeStatus(pledgeReportParams);
            } else {
                return false;
            }
        });

        return false;
    }

    // ---------
    private momentize(model: any): any {
        if (model.startDate) {
            model.startDate = moment(model.startDate).startOf('day');
        }
        if (model.endDate) {
            model.endDate = moment(model.endDate).endOf('day');
        }
        if (model.entryDate) {
            model.entryDate = moment(model.entryDate).startOf('day');
        }
        return model;
    }

    public companySelected(event: CompanySelectedEvent): void {
        if (event.noCompany) {
            this.companyId.setValue('');
        }
        this.companyId.setValue(event?.company && event.company?.id);
    }
}
