<div class="row">
  <div class="col-sm-12 col-md-12 col-lg-10 offset-lg-1">
    <h4>Shift Confirmed Pledges to Pending Payment</h4>
    <p>The report below shows a summary of the selected pledges in the system which are currently Pending Payment.
      In order to shift new or additional pledges to Pending Payment, first enter the maximum date for which you want
      to Shift pledges; and select which pledges you want to Shift (i.e. 'All Companies' or one specific company, etc.).
      After you have selected the date and the pledges, then click 'Lock Pledges' to shift the pledges from Confirmed to
      Pending Payment. The summary report below will automatically update to include all the new pledges that were set
      to
      Pending Payment.</p>
  </div>
  <div class="col-sm-12 col-md-12 col-lg-10 offset-lg-1">
    <form paymentProcessingForm #form
          [showEntryDate]="false"
          [showEndDate]="true"
          [showDisplayPledgeStatus]="true"
          [showShiftPledgeStatus]="true"
          [showPledgeTypes]="true"
          [showCompany]="true" novalidate>
      <span entryDateLabel>Maximum entry date of Pledges being shifted to Pending Payment:</span>
      <div footer class="text-right">
        <button class="btn btn-primary" (click)="form.submitConfirmedToPending()">Show Results</button>
        <button class="btn btn-primary" [disabled]="!(reports$ | async)" (click)="form.submitLockPledges()">Update Status</button>
      </div>
    </form>
  </div>
  <div class="col-xs-12 m-b-1">
    <pledgeReportSummaryTable [rows]="reports$ | async"></pledgeReportSummaryTable>
  </div>
  <prompt-dialog
    [titleText]="'Update Pledges?'"
    [promptText]="'Are you sure you want to update the following pledges to a pending status?'">
  </prompt-dialog>
</div>
