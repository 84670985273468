import {Component, ViewChild, EventEmitter, Output, AfterViewInit} from '@angular/core';
import {Observable, Observer} from 'rxjs';
import {ModalComponent} from 'src/app/utilities/old-modal/components/modal';
import {ChangePasswordComponent} from './ChangePasswordComponent';

@Component({
    selector: 'changePasswordModal',
    templateUrl: './ChangePasswordModalComponent.html',
    styleUrls: ['./ChangePasswordModalComponent.scss'],
})

export class ChangePasswordModalComponent implements AfterViewInit {

    @ViewChild(ModalComponent)
    modal: ModalComponent;

    @Output('okClicked')
    onOkClicked: EventEmitter<any> = new EventEmitter<any>();

    @Output('cancelClicked')
    onCancelClicked: EventEmitter<any> = new EventEmitter<any>();

    @ViewChild(ChangePasswordComponent)
    formComponent: ChangePasswordComponent;

    private observer: Observer<boolean>;

    ngAfterViewInit(): void {
        this.modal.onClose.subscribe(() => this.formComponent.form.reset());
    }

    open(): Observable<boolean> {
        this.modal.open();
        return new Observable(obs => {
            this.observer = obs;
        });
    }

    okClicked(): void {
        if (!this.formComponent.isValid()) {
            return;
        }
        this.formComponent.submit()
            .subscribe(() => {
                this.modal.close();
                this.onOkClicked.emit(true);
                if (this.observer) {
                    this.observer.next(true);
                    this.observer.complete();
                }
            });
    }

    cancelClicked(): void {
        this.onCancelClicked.emit(null);
        this.modal.close();
        if (this.observer) {
            this.observer.next(false);
            this.observer.complete();
        }
    }
}
