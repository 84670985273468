import {Inject, Injectable, Optional} from '@angular/core';
import {ApiBaseUrl} from './api.client';
import {Observable} from 'rxjs';
import {select, Store} from '@ngrx/store';
import * as fromRoot from '../store';
import {selectSecurityStateDataSecurityToken} from '../store/selectors/security.selectors';

@Injectable({providedIn: 'root'})
export class FileDownloadService {

    token: string;
    token$: Observable<string>;

    constructor(
        private _store: Store<fromRoot.AppState>,
        @Optional() @Inject(ApiBaseUrl) private baseUrl?: string) {
        this.token$ = this._store.pipe(select(selectSecurityStateDataSecurityToken));
        this.token$.subscribe(token => this.token = token);
    }

    buildUrl(endpoint: string, data: {[k: string]: any} = {}): string {
        let url = `${this.baseUrl || ''}${endpoint}`;

        if (!!data){
            url += `?${this.getQueryParams(data)}`;
        }

        return url;
    }

    private getQueryParams(data: {[k: string]: any}): string {
        data.securityToken = encodeURIComponent(this.token);
        if (data.startDate) {
            data.startDate = data.startDate.format();
        }
        if (data.endDate) {
            data.endDate = data.endDate.format();
        }
        return Object.keys(data)
            .filter((key) => {
                if (Array.isArray(data[key])) {
                    return data[key].length > 0;
                }
                return !!data[key];
            })
            .map((key) => `${key}=${data[key]}`)
            .join('&');
    }
}
