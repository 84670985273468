import {Component} from '@angular/core';

@Component({
  selector: 'footer',
  templateUrl: './FooterComponent.html',
  styleUrls: ['./FooterComponent.scss'],
})
export class FooterComponent {

}
