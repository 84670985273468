import {Component, Input, ViewChild} from '@angular/core';
import {EfnCase} from '../../../../../../services/api.client';
import {EfnCaseHelper} from '../EfnHelper';
import {EfnAgenciesTabComponent} from './agencies/EfnAgenciesTabComponent';

@Component({
  selector: 'efnTabs',
  templateUrl: './EfnTabsComponent.html',
  styleUrls: ['./EfnTabsComponent.scss']
})
export class EfnTabsComponent {

  @Input()
  efnCase: EfnCase;

  @ViewChild(EfnAgenciesTabComponent)
  agencyTab: EfnAgenciesTabComponent;

  tab: any = 'agencies';

  constructor(public helper: EfnCaseHelper) {
  }

  reload(): void {
    this.agencyTab.efnCase = this.efnCase;
  }
}
