import {AfterViewInit, Component, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {ITableHeader, TableComponent} from '../../shared/table/TableComponent';
import {Agency, PagedListOfEfnCase} from '../../../services/api.client';
import {AgencyListModalComponent} from '../agency/AgencyListModalComponent';
import {UserType} from '../../../services/api.client';
import {User} from '../../../services/api.client';
import {EfnService} from '../../../services/EfnService';
import {EfnCaseStatusType} from '../../../services/api.client';

import {EfnCase} from '../../../services/api.client';
import {extractEnumNames} from '../../../utilities/Util';
import {UntypedFormControl} from '@angular/forms';
import {Observable} from 'rxjs';
import {select, Store} from '@ngrx/store';
import * as fromRoot from '../../../store';
import {selectEfnDataEfnCasesPaged, selectEfnDataEfnCasesPagedTotalCount} from '../../../store/selectors/efn.selectors';
import {selectSecurityStateDataCurrentUser} from '../../../store/selectors/security.selectors';

@Component({
  templateUrl: './EfnListComponent.html',
  styleUrls: ['./EfnListComponent.scss']
})

export class EfnListComponent implements OnInit, AfterViewInit {

  constructor(
      private efnService: EfnService,
      private _store: Store<fromRoot.AppState>) {
    this.currentUser$ = this._store.pipe(select(selectSecurityStateDataCurrentUser));
    this.efnCaseList$ = this._store.pipe(select(selectEfnDataEfnCasesPaged));
    this.totalCount$ = this._store.pipe(select(selectEfnDataEfnCasesPagedTotalCount));
  }
  statuses = extractEnumNames(EfnCaseStatusType);
  selectedAgencyId: string;
  searchValue: string;
  caseStatus = new UntypedFormControl('');

  @ViewChild(TableComponent)
  table: TableComponent;

  currentUser: User;
  currentUser$: Observable<User>;

  efnCaseList: PagedListOfEfnCase;
  efnCaseList$: Observable<PagedListOfEfnCase>;

  // sorting
  currentSortName = 'EnteredDate';
  currentSorting = true;

  tableHeaders: ITableHeader[] = [
    {text: 'Last Name', sortKey: 'Client|LastName'},
    {text: 'First Name', sortKey: 'Client|FirstName'},
    {text: 'SSN', sortKey: 'Client|Last4Ssn'},
    {text: 'Created', sortKey: 'EnteredDate', class: 'hidden-sm-down', ascending: false},
    {text: 'Deadline', sortKey: 'Deadline', class: 'hidden-sm-down', ascending: false},
    {text: 'Funded', sortKey: 'EnteredDate', class: 'hidden-sm-down', ascending: false},
    {text: 'Status', sortKey: 'Status'},
    {text: 'Agency', sortKey: 'CreatedByAgency|AgencyName'},
    {text: 'Committed', sortKey: 'CurrentTotal'},
    {text: 'Total Need', sortKey: 'TotalNeed'},
  ];

  // pagination
  limit = 10;
  currentPage = 1;
  maxSize = 1;
  totalCount: number;
  totalCount$: Observable<number>;

  // MODAL
  @ViewChild(AgencyListModalComponent)
  agencyListModal: AgencyListModalComponent;
  @Output('onShowAgencyModal') _showAgencies = new EventEmitter();
  private agencyList: AgencyListModalComponent;

  // selected agency
  agency: Agency;
  selectedAgencyName = '-- Select Agency --';

  ngOnInit(): void {
    this.currentUser$.subscribe(user => this.currentUser = user);
    this.efnCaseList$.subscribe(efnCaseList => this.efnCaseList = efnCaseList);
    this.totalCount$.subscribe(totalCount => this.totalCount = totalCount);
    if (this.currentUser.userType !== UserType.SystemAdmin &&
        this.currentUser.userType !== UserType.FundAdmin) {// NOT SYS ADMIN OR FUND ADMIN
      this.selectedAgencyId = this.currentUser.agencyId;
      this.selectedAgencyName = this.currentUser.agency.agencyName;
    }
    // this.searchEfnCases();
  }

  // Current Selected Pledge
  setCurrentSelectedEfnCase(efnCase: EfnCase): void {
    this.efnService.setSelectedEfnCase(efnCase);
  }

  setSearchValue(value: string): void {
    this.searchValue = value;
  }

  sortCases(options: ITableHeader): void {
    this.currentSortName = options.sortKey;
    this.currentSorting = !options.ascending;
    this.searchEfnCases();
  }

  setLimit(limit: number): void {// # of rows per page
    this.limit = limit;
    this.searchEfnCases();
  }

  setPage(page: number): void {
    setTimeout(() => {
      this.currentPage = page; // set current page
      this.searchEfnCases();
    });
  }

  searchEfnCases(): void {
    this.table.request = this.efnService.getEfnCasesPaged(
      this.searchValue,
      this.selectedAgencyId,
      this.caseStatus.value,
      this.currentSortName,
      this.limit,
        (this.currentPage - 1) * this.limit,
      this.currentSorting
    );
  }

  // show Agency Modal
  showAgencyModal(): boolean {
    this.agencyListModal.open();
    return false;
  }

  onAgencySelected(selectedAgency): void {
    if (selectedAgency) {
      selectedAgency.agencyName ? this.selectedAgencyName = selectedAgency.agencyName : this.selectedAgencyName = '';
      this.selectedAgencyId = selectedAgency.id;
    } else {
      this.selectedAgencyName = '';
      this.selectedAgencyId = '';
    }
  }

  isDisabledAgency(): boolean {
    return this.currentUser.userType !== UserType.SystemAdmin &&
        this.currentUser.userType !== UserType.FundAdmin;
  }

  formatStatus(status): string {
    if (!this.statuses) {
      return '';
    }
    let statusText = {key: '', value: -1};
    if (typeof status === 'number') {
      statusText = this.statuses.find(type => type.value === status);
    }
    else if (typeof status === 'string') {
        statusText = this.statuses.find(type => type.key.toLowerCase() === status.toLowerCase());
 }
    return statusText.key || '';
  }

  ngAfterViewInit(): void {
    this.searchEfnCases();
  }
}
