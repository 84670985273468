<div class="row">
  <h2 class="offset-md-1 col-md-10">Pledge by Counties Summary Report</h2>
</div>
<div class="row">
  <div class="col-xs-12">
    <form pledgeReportForm #form [showDisplayPledgeStatus]="true" [showCompany]="true" novalidate>
      <button type="submit" class="btn btn-primary" (click)="form.submitPledgeDemographicByCountyReport()">Submit
      </button>
      <button class="btn btn-primary" (click)="form.downloadPledgeDemographicByCountyReportClick()">Export to Excel</button>
    </form>
    <h1 *ngIf="rows | async">Pledge by Counties Summary Report</h1>
  </div>
  <div class="col-xs-12 col-md-7">
    <reportTable [headers]="tableHeaders" [rows]="rows | async"></reportTable>
  </div>
</div>
