import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ModalComponent} from './components/modal';
import {ModalHeaderComponent} from './components/modal-header';
import {ModalBodyComponent} from './components/modal-body';
import {ModalFooterComponent} from './components/modal-footer';


@NgModule({
    declarations: [
        ModalComponent,
        ModalHeaderComponent,
        ModalBodyComponent,
        ModalFooterComponent,
    ],
    imports: [
        CommonModule,
    ],
    exports: [
        ModalComponent,
        ModalHeaderComponent,
        ModalBodyComponent,
        ModalFooterComponent,
    ]
})
export class OldModalModule {
}
