import {Observable} from 'rxjs';
import {share} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import * as moment from 'moment';
import {
    ApprovedEnrollment,
    ApprovedEnrollmentHistory,
    BudgetBillingReport,
    CompletionReport,
    EnrollmentReport,
    KeepingCurrentReportClient
} from './api.client';
import {MissedPaymentReport} from './api.client';
import {ConfirmOrphanReport} from './api.client';
import Moment = moment.Moment;
import {Store} from '@ngrx/store';
import * as fromRoot from '../store';
import {
    retrieveKcConfirmOrphanReportFailed,
    retrieveKcConfirmOrphanReportSuccess,
    retrieveKcEnrollmentReportFailed,
    retrieveKcEnrollmentReportSuccess, retrieveKcMissedPaymentReportFailed,
    retrieveKcMissedPaymentReportSuccess
} from '../store/actions/report.actions';

@Injectable()
export class KcReportService {

    constructor(private _store: Store<fromRoot.AppState>, private _kcReportAPI: KeepingCurrentReportClient) {
    }

    buildConfirmOrphanReport(startDate: moment.Moment, endDate: moment.Moment, agencyId: string): Observable<ConfirmOrphanReport[]> {
        const request = this._kcReportAPI.buildConfirmOrphanReport(startDate, endDate, agencyId).pipe(share());
        request.subscribe({
            next: (data) => {
                this.retrieveConfirmOrphanReportSuccessAction(data);
            }, error: (error) => {
                this.retrieveConfirmOrphanReportFailedAction(error);
            }
        });
        return request;
    }

    buildMissedPaymentReport(startDate: moment.Moment, endDate: moment.Moment, agencyId: string): Observable<MissedPaymentReport[]> {
        const request = this._kcReportAPI.buildMissedPaymentReport(startDate, endDate, agencyId).pipe(share());
        request.subscribe({
            next: (data) => {
                this.retrieveMissedPaymentReportSuccessAction(data);
            }, error: (error) => {
                this.retrieveMissedPaymentReportFailedAction(error);
            }
        });
        return request;
    }

    buildEnrollmentReport(startDate: moment.Moment, endDate: moment.Moment, agencyId: string, statusIds: string)
        : Observable<EnrollmentReport[]> {
        const request = this._kcReportAPI.buildEnrollmentReport(startDate, endDate, agencyId, statusIds).pipe(share());
        request.subscribe({
            next:
                (data) => {
                    this.retrieveEnrollmentReportSuccessAction(data);
                }, error: (error) => {
                this.retrieveEnrollmentReportFailedAction(error);
            }
        });
        return request;
    }

    buildApprovedEnrollmentReport(exportDate: Moment): Observable<ApprovedEnrollment[]> {
        if (typeof exportDate === 'string') {
            exportDate = moment(exportDate);
        }
        return this._kcReportAPI.buildApprovedEnrollmentsReport(exportDate);

    }

    buildBudgetBillingReport(monthYear: string, agencyId: string): Observable<BudgetBillingReport[]> {
        return this._kcReportAPI.buildBudgetBillingReport(monthYear, agencyId);
    }

    buildCompletionReport(startMonthYear: string, endMonthYear: string, agencyId: string): Observable<CompletionReport[]> {
        return this._kcReportAPI.buildCompletionReport(startMonthYear, endMonthYear, agencyId);
    }

    setDownloadTimeStampForApprovalRecordedApplications(): Observable<void> {
        return this._kcReportAPI.setDownloadTimeStampForApprovalRecordedApplications().pipe(share());
    }

    getApprovedEnrollmentHistory(): Observable<ApprovedEnrollmentHistory[]> {
        return this._kcReportAPI.getApprovedEnrollmentHistory();
    }


    // ---------------------security actions------------------
    // Enrollment
    private retrieveEnrollmentReportSuccessAction(data: EnrollmentReport[]): void {
        this._store.dispatch(retrieveKcEnrollmentReportSuccess({ payload: data}));
    }

    private retrieveEnrollmentReportFailedAction(error: EnrollmentReport[]): void {
        this._store.dispatch(retrieveKcEnrollmentReportFailed({ payload: error}));
    }

    // MissedPayments
    private retrieveMissedPaymentReportSuccessAction(data: MissedPaymentReport[]): void {
        this._store.dispatch(retrieveKcMissedPaymentReportSuccess({ payload: data}));
    }

    private retrieveMissedPaymentReportFailedAction(error: MissedPaymentReport[]): void {
        this._store.dispatch(retrieveKcMissedPaymentReportFailed({ payload: error}));
    }

    // ConfirmOrphans
    private retrieveConfirmOrphanReportSuccessAction(data: ConfirmOrphanReport[]): void {
        this._store.dispatch(retrieveKcConfirmOrphanReportSuccess({ payload: data}));
    }

    private retrieveConfirmOrphanReportFailedAction(error: ConfirmOrphanReport[]): void {
        this._store.dispatch(retrieveKcConfirmOrphanReportFailed({ payload: error}));
    }
}
