import {AfterViewInit, Component, inject, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, UntypedFormGroup} from '@angular/forms';
import {CountySpendingReportService} from '../../../../services/countySpendingReport.service';
import {
    CountySelection,
    CountySpendingReportParameter,
    FundTypeSelection, PagedListOfCountySpendingReportPreview
} from '../../../../services/api.client';
import {FundTypeService} from '../../../../services/fundType.service';
import {combineLatest, Observable} from 'rxjs';
import {ITableHeader, TableComponent} from '../../../shared/table/TableComponent';
import {FileDownloadService} from '../../../../services/FileDownloadService';
import {CountySpendingUrls} from '../../../../api/CountySpendingUrls';
import {Store} from '@ngrx/store';
import * as fromRoot from '../../../../store';
import {
    selectCountySpendingReportData,
    selectCountySpendingReportTotalCount
} from '../../../../store/selectors/countySpendingReport.selectors';
import {takeUntil} from 'rxjs/operators';
import {Disposable} from '../../../../utilities/Disposable';

@Component({
    selector: 'app-county-spending',
    templateUrl: './county-spending.component.html',
    styleUrls: ['./county-spending.component.scss']
})
export class CountySpendingComponent extends Disposable implements OnInit {

    @ViewChild(TableComponent) table: TableComponent;

    private _formBuilder = inject(FormBuilder);
    private _countySpendingReportService = inject(CountySpendingReportService);
    private _fundTypeService = inject(FundTypeService);
    private _fileDownloadService = inject(FileDownloadService);

    constructor(private _store: Store<fromRoot.AppState>) {
        super();
        this.countySpending$ = this._store.select(selectCountySpendingReportData);
        this.totalCount$ = this._store.select(selectCountySpendingReportTotalCount);
    }

    form: FormGroup;
    counties: CountySelection[] = [];
    fundTypes: FundTypeSelection[] = [];

    currentSortName = 'CountyName';
    currentSorting = false;

    limit = 10;
    currentPage = 1;
    maxSize = 1;
    totalCount: number;
    totalCount$: Observable<number>;

    private defaultAllFundTypes = 'allFundTypes';
    private defaultAllCounties = 'allCounties';

    countySpending$: Observable<PagedListOfCountySpendingReportPreview>;

    tableHeaders: ITableHeader[] = [
        {text: 'County Name', sortKey: 'CountyName'},
        {text: 'Total Pledges In Progress', sortKey: 'PledgesInProgressTotal'},
        {text: 'Total Pledges Paid', sortKey: 'PledgesPaidTotal'},
        {text: 'Total Pledge Dollars', sortKey: 'PledgeDollarsTotal' },
        {text: 'Unprocessed Public Application Fund Requests', sortKey: 'PledgesUnprocessedTotal' },
        {text: 'Total Applications', sortKey: 'PledgesApplicationsTotal' }
    ];

    get fundType(): FormControl {
        return this.form?.get('fundTypeId') as FormControl;
    }

    get countyName(): FormControl {
        return this.form?.get('countyName') as FormControl;
    }

    ngOnInit(): void {
        this.initForm();
        this._countySpendingReportService.setIsLoadedAction(null);

        this.totalCount$
            .pipe(takeUntil(this.$destroyed))
            .subscribe(totalCount => this.totalCount = totalCount);

        combineLatest([
            this._countySpendingReportService.getCounties(),
            this._fundTypeService.getFundPledgeTypes()
        ])
            .pipe(takeUntil(this.$destroyed))
            .subscribe(
            ([counties, fundTypes]) => {
                this.counties = counties;
                this.fundTypes = fundTypes;
            });
    }

    sortCounties(options: ITableHeader): void {
        this.currentSortName = options.sortKey;
        this.currentSorting = !options.ascending;
        this.filterCounties();
    }

    setLimit(limit: number): void {// # of rows per page
        this.limit = limit;
        this.filterCounties();
    }

    setPage(page: number): void {
        setTimeout(() => {
            this.currentPage = page; // set current page
            this.filterCounties();
        });
    }

    filterCounties(): void {
        const parameter = this.buildReportParameters();
        this.table.request = this._countySpendingReportService.previewCountySpendingReport(
            parameter,
            this.currentSortName,
            this.currentSorting,
            this.limit,
            (this.currentPage - 1) * this.limit
        );
    }

    private buildReportParameters(): CountySpendingReportParameter {
        return CountySpendingReportParameter.fromJS({
            fundTypeId: this.fundType?.value === this.defaultAllFundTypes ? null : this.fundType?.value,
            isAllFundAdministrativeAgency: this.fundType?.value === this.defaultAllFundTypes,
            countyName: this.countyName?.value === this.defaultAllCounties ? null : this.countyName?.value,
            isAllCounties: this.countyName?.value === this.defaultAllCounties
        });
    }
    downloadReport(): string {
        const parameter = this.buildReportParameters();

        return this._fileDownloadService.buildUrl(CountySpendingUrls.DOWNLOAD_COUNTY_SPENDING_REPORT, parameter);
    }

    private initForm(): void {
        this.form = this._formBuilder.group({
            fundTypeId: new FormControl<string>(this.defaultAllFundTypes),
            countyName: new FormControl<string>(this.defaultAllCounties)
        });
    }
}
