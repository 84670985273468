<h1 class="page-title">Browse Allocations</h1>
<section class="widget">
    <div class="widget-body">
        <div class="mt">
            <form [formGroup]="form" novalidate>
                <div class="row">
                    <div class="col-md-3 col-sm-3">
                        <label class="allocation-label">Search by:</label>
                        <input class="search-text-input form-control"
                               type="text"
                               placeholder="Search by Agency or Company"
                               [formControl]="searchValue" />
                    </div>
                    <div class="col-md-4 col-sm-4">
                        <label class="allocation-label">Fund Type:</label>
                        <dropdown class="drop-down-inline"
                                  [promptValue]="'Fund Types'"
                                  [values]="pledgeTypeDropdown"
                                  (select)="selectedPledgeType($event)"></dropdown>
                    </div>
                    <div class="col-md-5 col-sm-2 align-checkbox">
                        <div class="custom-control-container">
                            <label class="custom-control custom-checkbox">
                                <input class="custom-control-input"
                                       [formControl]="showZeroBalances"
                                       type="checkbox">
                                <span class="custom-control-indicator"></span>
                                <span class="custom-control-description">Show $0 Balances</span>
                            </label>
                        </div>

                    </div>
                </div>
                <div class="row">
                    <div class="col-md-2 col-sm-2 inline-button">
                        <button class="btn btn-primary" type="submit" (click)="refreshAllocations()">Search</button>
                    </div>
                    <div class="col-md col-sm-4 inline-button">
                        <button class="btn btn-outline-primary pull-right" (click)="showUpload()">Upload</button>
                        <button class="btn btn-primary pull-right m-x-1" (click)="navigateToAllocationAndBalance()"
                                type="button">
                            New Allocation
                        </button>
                    </div>

                </div>
            </form>
            <table-dynamic id="datatable-table"
                           class="table table-striped table-hover"
                           [headers]="tableHeaders"
                           [currentPage]="currentPage"
                           [totalCount]="totalCount$ | async"
                           (headerClick)="sortAllocations($event)"
                           (pageChanged)="setPage($event)"
                           (limitChange)="setLimit($event)">
                <tr *ngFor="let allocation of allocations">
                    <td>
                        <a [routerLink]="['../allocation', allocation.id, queryParams]"
                           (click)="setCurrentSelectedAllocation(allocation)">
                            <span class="fw-semi-bold">{{allocation.agencyName}}</span>
                        </a>
                    </td>
                    <td class="hidden-sm-down">{{allocation.pledgeTypeName}}</td>
                    <td>{{allocation.remainingBalance | parseNumber | currency:'USD':'symbol':'1.2-2'}}</td>
                    <td>{{allocation.amount | parseNumber | currency:'USD':'symbol':'1.2-2'}}</td>
                    <td class="hidden-sm-down">{{allocation.reason ? allocation.reason.substring(0, 14) + "..." : ""}}</td>
                    <td class="hidden-sm-down">{{ formatStatus(allocation.status) }}</td>
                </tr>
            </table-dynamic>
        </div>
    </div>
      <prompt-dialog #pd [titleText]="'Upload Allocations'" [showTitle]="true">
        <div class="row" body>
          <div *ngIf="!pd?.modal?.hiding" class="col-xs-12">
            <app-upload class="text-xs-center" [labelText]="'Upload Allocation Spreadsheet'"
                        [fileInput]="fileInput"></app-upload>
            <div *ngIf="uploadStatus" class="text-success text-xs-center">{{uploadStatus}}</div>
            <div *ngIf="uploadResults" class="text-danger text-xs-center">There were some issues with the file.</div>
            <div *ngIf="uploadResults" class="text-danger text-xs-left">
              <ul>
                <li *ngFor="let item of uploadResults">
                  Line# {{item.lineNumber}}: {{item.errorMessages.join(', ')}}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </prompt-dialog>
</section>
