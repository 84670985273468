<div class="container">
  <main id="content" role="main">
    <div class="widget-login-container">
      <h5 class="widget-login-logo animated fadeInUp">
        United Way Assistance Platform
      </h5>
      <section class="widget widget-login animated fadeInUp">
        <header>
          <img alt="United Way" class="logo-img" src="/assets/images/logo-mobile.svg">
        </header>
        <div class="widget-body">
          <div class="mt-lg" [hidden]="!(isLoggingIn$ | async)">
            Logging in...
          </div>
          <form class="login-form" [hidden]="isLoggingIn$ | async" novalidate>
            <div class="form-group" [class.has-danger]="errorMessage$ | async">
              <div class="form-control-feedback">{{ errorMessage$ | async }}</div>
              <input [(ngModel)]="email"
                     [class.form-control-danger]="errorMessage$ | async"
                     type="text" name="email" required class="form-control" id="email" placeholder="Email">
            </div>
            <div class="form-group" [class.has-danger]="errorMessage$ | async">
              <input [(ngModel)]="password"
                     [class.form-control-danger]="errorMessage$ | async"
                     class="form-control" name="password" id="pswd" type="password" placeholder="Password">
            </div>
            <div class="btn-toolbar pull-right">
              <button class="btn btn-inverse btn-sm" (click)="login()"
                      [disabled]="(isLoggingIn$ | async) || (!email || !password)">Login
              </button>
            </div>
            <div class="abc-checkbox widget-login-info pull-right">
              <input #remembermecb type="checkbox" name="rme" id="rme" checked="checked"
                     (change)="rememberMe = remembermecb.checked">
              <label for="rme">Keep me signed in </label>
            </div>
            <a class="forgot-password mr-n-lg pull-left" routerLink="/forgotpassword">Password Help?</a>
            <div class="clearfix"></div>
          </form>
        </div>
      </section>
    </div>
  </main>
  <footer class="page-footer">
    2016 United Way of Greater St. Louis, LLC
  </footer>
</div>
