import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {Observable, of} from 'rxjs';
import {mergeMap, take} from 'rxjs/operators';
import {Client, KcApplicationClient, KcProgram} from '../services/api.client';
import {select, Store} from '@ngrx/store';
import * as fromRoot from '../store';
import {selectClientDataCurrentClient} from '../store/selectors/client.selectors';

@Injectable()
export class ResolveKcPrograms  {

    client: Client;
    client$: Observable<Client>;

    constructor(private _kcAppClient: KcApplicationClient, private _store: Store<fromRoot.AppState>) {
        this.client$ = this._store.pipe(select(selectClientDataCurrentClient));
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<KcProgram[]> | Promise<KcProgram[]> | KcProgram[] {
        return this.client$.modelExists().pipe(
            take(1),
            mergeMap((client) => {
                if (client.federalPovertyLevelDisplay !== null && client.federalPovertyLevelDisplay !== undefined) {
                    return this._kcAppClient.retrieveKcPrograms(client.federalPovertyLevelDisplay);
                }
                return of([]);
            })
        );
    }
}
