import {Observable, Operator, Subscriber, Subscription} from 'rxjs';
import {asyncScheduler} from 'rxjs';
import {AsyncScheduler} from 'rxjs/internal/scheduler/AsyncScheduler';

// http://stackoverflow.com/questions/37146081/rxjs-throttle-behavior-get-first-value-immediately?rq=1
export type AuditTimeImmediateSignature<T> = (duration: number, scheduler?: AsyncScheduler) => Observable<T>;

declare module 'rxjs/internal/Observable' {
  interface Observable<T> {
    auditTimeImmediate: AuditTimeImmediateSignature<T>;
  }
}

/**
 * like auditTime, but triggers first value immediately, if throttle is not active
 *
 * @see {@link auditTime}
 */
export function auditTimeImmediate<T>(duration: number, scheduler: AsyncScheduler = asyncScheduler): Observable<T> {
  return this.lift(new AuditTimeImmediateOperator(duration, scheduler));
}

class AuditTimeImmediateOperator<T> implements Operator<T, T> {
  constructor(private duration: number,
              private scheduler: AsyncScheduler) {
  }

  call(subscriber: Subscriber<T>, source: any): any {
    return source._subscribe(new AuditTimeImmediateSubscriber(subscriber, this.duration, this.scheduler));
  }
}

class AuditTimeImmediateSubscriber<T> extends Subscriber<T> {

  private value: T;
  private hasValue = false;
  private throttled: Subscription;

  constructor(destination: Subscriber<T>,
              private duration: number,
              private scheduler: AsyncScheduler) {
    super(destination);
  }

  protected _next(value: T): void {
    this.value = value;
    this.hasValue = true;
    if (!this.throttled) {
      this.clearThrottle();
    }
  }

  clearThrottle(): void {
    const {value, hasValue, throttled} = this;
    if (throttled) {
      this.remove(throttled);
      this.throttled = null;
      throttled.unsubscribe();
    }
    if (hasValue) {
      this.value = null;
      this.hasValue = false;
      this.destination.next(value);
      this.add(this.throttled = this.scheduler.schedule(dispatchNext, this.duration, this));
    }
  }
}

function dispatchNext<T>(subscriber: AuditTimeImmediateSubscriber<T>): void {
  subscriber.clearThrottle();
}


Observable.prototype.auditTimeImmediate = auditTimeImmediate;
