import {Component, EventEmitter, Input, Output} from '@angular/core';
import {AssistanceApplicationStateAuditType, PagedListOfAuditReportTableRowViewModel} from '../../../../../services/api.client';
import {Router} from '@angular/router';
import {ITableHeader} from '../../../../shared/table/TableComponent';

@Component({
  selector: 'app-audit-progress-report-table',
  templateUrl: './audit-progress-report-table.component.html',
  styleUrls: ['./audit-progress-report-table.component.scss']
})
export class AuditProgressReportTableComponent {
  @Input() pagedData: PagedListOfAuditReportTableRowViewModel | undefined;
  @Output() pageChange = new EventEmitter<number>();
  @Output() limitChange = new EventEmitter<number>();


  constructor(private router: Router) {
  }

  public headers: ITableHeader[] = [
    {text: 'Fund', class: 'hidden-sm-down'},
    {text: 'Counties', class: 'hidden-sm-down'},
    {text: 'Pass', class: 'hidden-sm-down'},
    {text: 'Pass With Correction', class: 'hidden-sm-down'},
    {text: 'Fail', class: 'hidden-sm-down'},
    {text: 'Not Yet Audited', class: 'hidden-sm-down'},
    {text: '% Audited', class: 'hidden-sm-down'},
  ];

  public onPageChange(pageIndex: any): void {
    this.pageChange.emit(pageIndex);
  }

  public onLimitChange(limit: number): void {
    this.limitChange.emit(limit);
  }
}
