import {share, distinctUntilChanged, scan, map} from 'rxjs/operators';
import {Observable, ReplaySubject} from 'rxjs';
import {Injectable} from '@angular/core';

/**
 * Global request stream.
 */
const request$ = new ReplaySubject<{ request: XMLHttpRequest, done: boolean }>();

/**
 * Wrap XMLHttpRequest.send to create an application wide request stream.
 */
(function(send, request) {
  XMLHttpRequest.prototype.send = function(data) {
    request.next({request: this, done: false});
    const listener = this.addEventListener('readystatechange', (ev: ProgressEvent) => {
      if (this.readyState === 4) {
        request.next({request: this, done: true});
        this.removeEventListener('readystatechange', listener);
      }
    });
    send.call(this, data);
  };
}(XMLHttpRequest.prototype.send, request$));

/**
 *  Loading stream
 *
 *  Emits true if there are any requests pending, false otherwise.
 */
const loading$: Observable<boolean> = request$.pipe(
  map((xhr) => !xhr.done),
  scan((pendingCount: number, isNewRequest: boolean) => (isNewRequest) ? ++pendingCount : --pendingCount, 0),
  map((pending) => pending > 0),
  distinctUntilChanged(),
  share()
);

// start tracking when file is loaded
loading$.subscribe();

@Injectable({
  providedIn: 'root'
})
export class RequestService {
  get stream(): ReplaySubject<{ request: XMLHttpRequest, done: boolean }> {
    return request$;
  }

  get loading(): Observable<boolean> {
    return loading$;
  }
}
