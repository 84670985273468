import {Option} from './option';
import {AssistanceApplicationDenialReason} from '../../enums/assistance-application-denial-reason';

export const ASSISTANCE_APPLICATION_DENIAL_REASON_OPTIONS: Option<AssistanceApplicationDenialReason>[] = [
    {
        label: 'Over Income',
        value: AssistanceApplicationDenialReason.OverIncome,
    },
    {
        label: 'Duplicate Household Submission',
        value: AssistanceApplicationDenialReason.DuplicateHouseholdSubmission,
    },
    {
        label: 'Unresponsive',
        value: AssistanceApplicationDenialReason.Unresponsive,
    },
    {
        label: 'Duplicate Individual Submission',
        value: AssistanceApplicationDenialReason.DuplicateIndividualSubmission,
    },
    {
        label: 'Program Closed',
        value: AssistanceApplicationDenialReason.ProgramClosed,
    },
    {
        label: 'Cancelled',
        value: AssistanceApplicationDenialReason.Cancelled,
    },
    {
        label: 'Other',
        value: AssistanceApplicationDenialReason.Other,
    }
];
