import {Component, OnInit} from '@angular/core';
import {GenericApiResponse} from '../../../services/api.client';
import {SecurityService} from '../../../services/SecurityService';
import {Router} from '@angular/router';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {Validators} from '../../../utilities/Validators';

@Component({
    templateUrl: './ForgotPasswordComponent.html',
    styleUrls: ['./ForgotPasswordComponent.scss'],
})
export class ForgotPasswordComponent implements OnInit {

    errMessage = '';
    successMessage: string;
    form: UntypedFormGroup;
    email: UntypedFormControl;

    constructor(private _securityService: SecurityService, private router: Router,
                private _formBuilder: UntypedFormBuilder) {
    }

    ngOnInit(): void {
        this.form = this._formBuilder.group({
            email: ['', Validators.compose([Validators.required, Validators.email])]
        });
        this.email = (this.form.controls.email as UntypedFormControl);
    }

    sendLink(): void {
        if (!this.form.valid) {
            this.email.markAsTouched();
            return;
        }
        this._securityService.sendPasswordResetEmail(this.email.value)
            .subscribe({
                next: (response: GenericApiResponse) => {
                    this.successMessage = response.message;
                    this.router.navigate(['/login']).then();
                },
                error: (err) => {
                    if (err.isSwaggerException) {
                        try {
                            this.errMessage = JSON.parse(err.response).message;
                        } catch (e) {
                        }
                    } else {
                        this.errMessage = err;
                    }
                }
            });
    }
}
