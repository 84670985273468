// For vendors for example jQuery, Lodash, angular2-jwt just import them here unless you plan on
// chunking vendors files for async loading. You would need to import the async loaded vendors
// at the entry point of the async loaded file. Also see custom-typings.d.ts as you also need to
// run `typings install x` where `x` is your module

// (window as any).ENV = 'development';
import {fromEvent, Observable} from 'rxjs';
// it has to be from rxjs/Observable inorder
// to make the modal library compatible
(Observable as any).fromEvent = fromEvent;

import 'jquery';
import 'zone.js';
import 'jquery-touchswipe/jquery.touchSwipe';
import 'jquery-slimscroll/jquery.slimscroll';
import 'rxjs';
import './utilities/rxjs/operators/log';
import './utilities/rxjs/operators/map';
import './utilities/rxjs/operators/auditTimeImmediate';
import './utilities/rxjs/operators/empty';
import './utilities/rxjs/operators/exists';
import './utilities/rxjs/operators/modelEmpty';
import './utilities/rxjs/operators/modelExists';
import './services/RequestService';
import 'tether';
import 'bootstrap';
import 'widgster';
import 'lodash';
import 'select2';
import 'messenger/build/js/messenger.js';
import 'pusher-js';
import '@angular/platform-browser';
import '@angular/platform-browser-dynamic';
import '@angular/core';
import '@angular/common';
import '@angular/forms';
import '@angular/router';
import 'moment';
// custom rxjs extensions
// load request service here so we can track requests made by third party libraries
// Angular 2

// AngularClass
// import '@angularclass/hmr';

// RxJS
declare var ENV: any;

/*
(window as any).ENV = ENV;
if ('production' === ENV) {
  // Production
} else {
  // Development
}
*/

// import "@angular/platform-browser";
// import "@angular/platform-browser-dynamic";
// import "@angular/core";
// import "@angular/common";
// import "@angular/http";
// import "@angular/router";

// angular

// algolia
