import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {FormControl, FormGroup, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {ICustomFile} from 'file-input-accessor';
import {from, Observable} from 'rxjs';
import {delay, filter, last, mergeMap} from 'rxjs/operators';

import {AllocationService} from '../../../services/AllocationService';
import {
    Allocation,
    AllocationImportClient,
    AllocationValidationResult, PagedListOfAllocationListItem,
    PledgeType,
    StatusType,
    SystemData,
    User, UserType,
} from '../../../services/api.client';
import {PledgeTypeService} from '../../../services/PledgeTypeService';
import {DropdownValue} from '../../../utilities/DropdownComponent';
import {extractEnumNames} from '../../../utilities/Util';
import {PromptComponent} from '../../shared/dialogs/PromptComponent';
import {ITableHeader, TableComponent} from '../../shared/table/TableComponent';
import {select, Store} from '@ngrx/store';
import * as fromRoot from '../../../store';
import {
    selectAllocationDataAllocations,
    selectAllocationDataAllocationsItems,
    selectAllocationDataAllocationsTotalCount
} from '../../../store/selectors/allocation.selectors';
import {selectPledgeTypeDataPledgeTypesItems} from '../../../store/selectors/pledgeType.selectors';
import {selectSecurityStateDataCurrentUser} from '../../../store/selectors/security.selectors';
import {Disposable} from '../../../utilities/Disposable';

// shared
// store
// models
// services
// utilities


@Component({
    templateUrl: './AllocationListComponent.html',
    styleUrls: ['./AllocationListComponent.scss'],
})

export class AllocationListComponent extends Disposable implements OnInit, AfterViewInit {

    constructor(private _allocationService: AllocationService,
                private _pledgeTypeService: PledgeTypeService,
                private _allocationApi: AllocationImportClient,
                private _route: ActivatedRoute,
                private _router: Router,
                private _formBuilder: UntypedFormBuilder,
                private _store: Store<fromRoot.AppState>) {
        super();
        this.currentUser$ = this._store.pipe(select(selectSecurityStateDataCurrentUser));
        this.pagedList$ = this._store.pipe(select(selectAllocationDataAllocations));
        this.allocations$ = this._store.pipe(select(selectAllocationDataAllocationsItems));
        this.pledgeTypes$ = this._store.pipe(select(selectPledgeTypeDataPledgeTypesItems));
        this.totalCount$ = this._store.pipe(select(selectAllocationDataAllocationsTotalCount));
    }

    get queryParams(): any {
        return this.params;
    }

    isLoading: boolean;
    systemData: SystemData;
    statuses = extractEnumNames(StatusType);
    pledgeTypeId = '';

    get searchValue(): FormControl {
        return this.form.get('searchValue') as FormControl;
    }

    get showZeroBalances(): FormControl {
        return this.form.get('showZeroBalances') as FormControl;
    }

    params: any;

    currentUser$: Observable<User>;
    pagedList$: Observable<PagedListOfAllocationListItem>;
    allocations$: Observable<Allocation[]>;
    pledgeTypes$: Observable<PledgeType[]>;
    totalCount$: Observable<number>;

    @ViewChild(TableComponent)
    table: TableComponent;

    // Subscriptions
    currentUser: User;
    pagedList: PagedListOfAllocationListItem;
    allocations: Allocation[];
    pledgeTypes: PledgeType[];

    // sorting
    currentSortName = 'Agency|AgencyName';
    currentSorting = false;

    tableHeaders: ITableHeader[] = [
        {text: 'Agency Name', sortKey: 'Agency|AgencyName', ascending: true},
        {text: 'Fund Name', sortKey: 'PledgeType|Name', class: 'hidden-sm-down'},
        {text: 'Balance', sortKey: 'RemainingBalance'},
        {text: 'Original Amount', sortKey: 'Amount'},
        {text: 'Reason', sortKey: 'Reason', class: 'hidden-sm-down'},
        {text: 'Status', sortKey: 'Status', class: 'hidden-sm-down'},
    ];

    // pagination
    limit = 10;
    currentPage = 1;
    maxSize = 1;
    form: FormGroup = this._formBuilder.group({
        searchValue: new FormControl(''),
        showZeroBalances: new FormControl(false)
    });

    // Dropdowns
    pledgeTypeDropdown: DropdownValue[];

    // upload prompt
    @ViewChild(PromptComponent)
    prompt: PromptComponent;

    uploadResults: AllocationValidationResult[];
    uploadStatus: string;
    fileInput = new UntypedFormControl();

    ngAfterViewInit(): void {
        this._route.params
            .pipe(delay(0)) // fixes expression has changed after it was checked.
            .subscribe(params => {
                const searchValue = params.searchValue || '';
                if (searchValue) {
                    this.searchValue.setValue(searchValue);
                }


                this.pledgeTypeId = params.pledgeTypeId || '';
                this.limit = params.limit || 10;
                this.currentPage = params.currentPage || 1;
                this.params = {
                    searchValue: this.searchValue.value || '',
                    pledgeTypeId: this.pledgeTypeId,
                    currentSortName: this.currentSortName,
                    excludeZeroBalances: this.showZeroBalances,
                    limit: this.limit,
                    descending: this.currentSorting,
                    currentPage: this.currentPage,
                };
                this.table.request = this._allocationService.getAllocationsPaged(
                    this.searchValue.value,
                    this.pledgeTypeId,
                    this.showZeroBalances.value,
                    this.currentSortName,
                    this.limit,
                    (this.currentPage - 1) * this.limit,
                    this.currentSorting
                );
            });
    }

    // On Init
    ngOnInit(): void {
        this._pledgeTypeService.getPledgeTypes('', '', false, 100, 0);
        this.allocations$.subscribe(allocations => this.allocations = allocations);
        this.pledgeTypes$.pipe(
            filter(pledgeTypes => !!pledgeTypes))
            .subscribe((pledgeTypes) => {
                this.pledgeTypeDropdown = [];
                this.currentUser$.subscribe((user) => {
                    this.currentUser = user;
                });
                if (this.currentUser && this.currentUser.userType === UserType.SystemAdmin) {
                    for (const pledgeType of pledgeTypes) {
                        this.pledgeTypeDropdown.push(new DropdownValue(pledgeType.id, pledgeType.name));
                    }
                } else {
                    for (const pledgeType of pledgeTypes) {
                        if (this.currentUser.agency.pledgeTypeIds.some(x => x === pledgeType.id)) {
                            this.pledgeTypeDropdown.push(new DropdownValue(pledgeType.id, pledgeType.name));
                        }
                    }
                }
            });


        this.fileInput.valueChanges.pipe(
            filter((files) => files && !!files.length),
            mergeMap((files: ICustomFile[]) => {
                return from(files).pipe(
                    mergeMap((file: ICustomFile) => {
                        this.onBeforeUpload(file);
                        return this._allocationApi.uploadAllocations('v1', {
                            data: file,
                            fileName: file.name
                        });
                    }),
                    last()
                );
            })
        ).subscribe((data: AllocationValidationResult[]) => {
            this.prompt.okButtonDisabled = false;
            this.prompt.cancelButtonDisabled = false;

            if (data.length === 0) {
                this.uploadStatus = 'Upload complete';
                this.refreshAllocations();
                return;
            }
            this.uploadStatus = null;
            this.uploadResults = data;
        });
    }

    // set Current Selected Allocation
    setCurrentSelectedAllocation(currentSelectedAllocation: Allocation): void {
        this._allocationService.setCurrentSelectedAllocation(currentSelectedAllocation);
        this._router.navigate(['/app/allocation', currentSelectedAllocation.id]).then();
    }

    sortAllocations(options: ITableHeader): void {
        this.currentSortName = options.sortKey;
        this.currentSorting = !options.ascending;
        this.refreshAllocations();
    }

    setLimit(limit: number): void {// # of rows per page
        this.limit = limit;
        this.refreshAllocations();
    }

    setPage(page: number): void {
        setTimeout(() => {
            this.currentPage = page; // set current page
            this.refreshAllocations();
        });
    }

    // set selected Pledge Type ID
    selectedPledgeType(pledgeType): void {
        this.pledgeTypeId = pledgeType;
    }

    refreshAllocations(): void {
        this._router.navigate(['/app/allocations', {
            searchValue: this.searchValue.value.trim(),
            pledgeTypeId: this.pledgeTypeId,
            currentSortName: this.currentSortName,
            excludeZeroBalance: this.showZeroBalances,
            limit: this.limit,
            descending: this.currentSorting,
            currentPage: this.currentPage,
        }]).then();
    }

    formatStatus(status): string {
        if (!this.statuses) {
            return '';
        }
        const allStatus = this.statuses.find(type => type.value === status);
        return allStatus.key || '';
    }

    showUpload(): void {
        this.uploadStatus = '';
        this.uploadResults = null;
        this.prompt.open();
    }

    onBeforeUpload(uploadingFile: ICustomFile): void {
        this.uploadStatus = 'Uploading and validating file: ' + uploadingFile.name;

        this.prompt.okButtonDisabled = true;
        this.prompt.cancelButtonDisabled = true;
    }

    navigateToAllocationAndBalance(): void {
        this._router.navigate(['/app/allocation/add']).then();
    }
}
