import {Component, EventEmitter, Input, Output} from '@angular/core';
import {
    ActivityAuditState,
    AssistanceApplicationStateAuditType,
    PagedListOfAuditReportTableRowViewModel
} from '../../../../services/api.client';
import {ITableHeader} from '../../../shared/table/TableComponent';
import {Router} from '@angular/router';

@Component({
    selector: 'app-audit-report-table',
    templateUrl: './audit-report-table.component.html',
    styleUrls: ['./audit-report-table.component.scss', '../../../../../css/bootstrap5/grid.css']
})
export class AuditReportTableComponent {
    @Input() pagedData: PagedListOfAuditReportTableRowViewModel | undefined;
    @Output() pageChange = new EventEmitter<number>();
    @Output() limitChange = new EventEmitter<number>();


    constructor(private router: Router) {
    }

    public headers: ITableHeader[] = [
        {text: 'Entered Date', class: 'hidden-sm-down'},
        {text: 'Client', class: 'hidden-sm-down'},
        {text: 'Audit State', class: 'hidden-sm-down'},
        {text: 'Comments', class: 'hidden-sm-down'},
    ];

    public onPageChange(pageIndex: any): void {
        this.pageChange.emit(pageIndex);
    }

    public onLimitChange(limit: number): void {
        this.limitChange.emit(limit);
    }

    public onRowClick(clientId: string, assistanceApplicationId: string): void {
        this.router.navigate(['app', 'client', clientId, 'assistance-applications', assistanceApplicationId]);
    }

    public getAuditStateLabel(auditState: string): string {
        switch (auditState) {
            case AssistanceApplicationStateAuditType[AssistanceApplicationStateAuditType.Pass]:
                return 'Pass';
            case AssistanceApplicationStateAuditType[AssistanceApplicationStateAuditType.Fail]:
                return 'Fail';
            case AssistanceApplicationStateAuditType[AssistanceApplicationStateAuditType.PassWithCorrection]:
                return 'Pass With Correction';
            case AssistanceApplicationStateAuditType[AssistanceApplicationStateAuditType.NotYetAudited]:
                return 'Not Yet Audited';
            default:
                return '';
        }
    }
}
