import {createFeatureSelector, createSelector} from '@ngrx/store';
import {IAgencyData} from '../IAgencyData';

const agencyDataFeatureSelector = createFeatureSelector<IAgencyData>('agency');

export const selectAgencyDataAgencies = createSelector(
    agencyDataFeatureSelector,
    (state) => state.agencies
);

export const selectAgencyDataAgenciesItems = createSelector(
    agencyDataFeatureSelector,
    (state) => state.agencies?.items
);

export const selectAgencyDataAgenciesTotalCount = createSelector(
    agencyDataFeatureSelector,
    (state) => state.agencies?.totalCount
);

export const selectAgencyDataCurrentSelectedAgency = createSelector(
    agencyDataFeatureSelector,
    (state) => state.currentSelectedAgency
);

export const selectEfnDataAgencyEfnCases = createSelector(
    agencyDataFeatureSelector,
    (state) => state.agencyEfnCases
);
