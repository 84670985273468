{{ uploadStatus }}
<app-upload class="text-xs-center"
            [labelText]="'Upload File'"
            [fileInput]="fileInput"></app-upload>
<div style="position: relative; min-height: 200px; margin-top: 1rem;">
  <h3 *ngIf="files === null" class="text-center ab-center">
    Fetching files...
  </h3>
  <h3 *ngIf="files?.length === 0" class="text-center ab-center">
    No files listed.
  </h3>
  <table *ngIf="files?.length" class="table table-striped table-hover">
    <thead>
    <tr>
      <td>Filename</td>
      <td>Download</td>
      <td>Uploaded By</td>
      <td>Entered Date</td>
      <td></td>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let f of files">
      <td>{{ f.fileName }}</td>
      <td><a target="_blank" [href]="apiUrl + f.fileUrl + '&' + system.getQueryParameters()">Download</a></td>
      <td>{{ f.enteredBy }}</td>
      <td>{{ f.enteredDate | moment:'LLL' }}</td>
      <td>
        <button class="btn btn-danger" type="button" (click)="deleteFile(f.id)" title="Delete"><span
            class="fa fa-close"></span><span class="sr-only">Delete</span></button>
      </td>
    </tr>
    </tbody>
  </table>
  <prompt-dialog
      [titleText]="'Delete File?'"
      [promptText]="'Are you sure you want to delete this file?'">
  </prompt-dialog>
</div>



