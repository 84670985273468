import {map, mergeMap, take} from 'rxjs/operators';
import {Injectable, Injector} from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {Client} from '../services/api.client';
import {KcApplicationService} from '../services/KcApplicationService';
import {Observable} from 'rxjs';
import {KcApplication} from '../services/api.client';
import {getRouteParam} from '../utilities/Util';
import {select, Store} from '@ngrx/store';
import * as fromRoot from '../store';
import {selectClientDataCurrentClient} from '../store/selectors/client.selectors';

@Injectable()
export class CanCreateKcApplicationGuard  {

  client$: Observable<Client>;

  constructor(private kcApplicationService: KcApplicationService,
              private injector: Injector,
              private _store: Store<fromRoot.AppState>) {
      this.client$ = this._store.pipe(select(selectClientDataCurrentClient));
  }

  canActivate(route: ActivatedRouteSnapshot,
              state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.client$
      .modelExists().pipe(
        take(1),
        mergeMap((client) =>
          this.kcApplicationService.getIncompleteOrApprovedApplicationByClientId(client.id)
              .pipe(map((application: KcApplication) => {
                  if (!application || !application.keepingCurrentStatus || application.keepingCurrentStatus.isNewStatus) {
                      return true;
                  }
                  const id = getRouteParam(route, 'id');
                  this.injector.get(Router).navigate(['/app/client', id, 'application', application.id]).then();
                  return true;
              }))
        ));


  }
}
