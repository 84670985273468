import {Component, ViewChild, Input} from '@angular/core';
import {ModalComponent} from 'src/app/utilities/old-modal/components/modal';
import {Observable, Observer} from 'rxjs';
import 'rxjs';

@Component({
  selector: 'clientEfs-confirmation',
  templateUrl: './ClientEfsConfirmationComponent.html',
  styleUrls: ['./ClientEfsConfirmationComponent.scss']
})

export class ClientEfsConfirmationComponent {
  @ViewChild(ModalComponent)
  modal: ModalComponent;

  @Input() isReprint = false;
  @Input() titleText = '';
  @Input() promptText = '';
  @Input() printButtonText = 'Print';
  @Input() cancelButtonText = 'Cancel Print';

  private observer: Observer<boolean>;

  open(): Observable<boolean> {
    this.modal.open();

    return new Observable(obs => {
      this.observer = obs;
    });
  }

  close(): void {
    this.cancelClicked();
  }

  printClicked(): void {
    window.print();

    this.modal.close();
    if (this.observer) {
      this.observer.next(false);
      this.observer.complete();
    }
  }

  cancelClicked(): void {
    this.modal.close();
    if (this.observer) {
      this.observer.next(false);
      this.observer.complete();
    }
  }
}
