import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {Disposable} from '../../../../../../../utilities/Disposable';
import {ITableHeader, TableComponent} from '../../../../../../shared/table/TableComponent';
import {Observable} from 'rxjs';
import {PagedListOfActivityAuditViewModel} from '../../../../../../../services/api.client';
import {ActivityAuditService} from '../../../../../../../services/activityAudit.service';
import {Store} from '@ngrx/store';
import * as fromRoot from '../../../../../../../store';
import {
    selectActivityAuditIsLoading,
    selectActivityAuditPagedActivityAudit,
    selectActivityAuditTotalCount
} from '../../../../../../../store/selectors/activityAudit.selectors';
import {takeUntil} from 'rxjs/operators';

@Component({
    selector: 'app-assistance-application-activity-audit',
    templateUrl: './assistance-application-activity-audit.component.html',
    styleUrls: ['./assistance-application-activity-audit.component.scss']
})
export class AssistanceApplicationActivityAuditComponent extends Disposable implements OnInit {

    constructor(private _activityAuditService: ActivityAuditService,
                private _store: Store<fromRoot.AppState>) {
        super();
        this.pagedListOfActivityAudit$ = this._store.select(selectActivityAuditPagedActivityAudit);
        this.totalCount$ = this._store.select(selectActivityAuditTotalCount);
        this.isLoading$ = this._store.select(selectActivityAuditIsLoading);
    }

    @Input() caseId: string;

    @ViewChild(TableComponent)
    table: TableComponent;

    pagedListOfActivityAudit: PagedListOfActivityAuditViewModel;
    pagedListOfActivityAudit$: Observable<PagedListOfActivityAuditViewModel>;

    totalCount: number;
    totalCount$: Observable<number>;

    isLoading: boolean;
    isLoading$: Observable<boolean>;

    tableHeaders: ITableHeader[] = [
        {text: 'Date/Time', sortKey: 'Date', class: 'hidden-sm-down', ascending: false },
        {text: 'User', sortKey: 'CreatedBy', class: 'hidden-sm-down' },
        {text: 'Organization', sortKey: 'Organization', class: 'hidden-sm-down'},
        {text: 'Section', sortKey: 'EntitySetName', class: 'hidden-sm-down'},
        {text: 'Field', sortKey: 'PropertyName', class: 'hidden-sm-down'},
        {text: 'Old Value', class: 'hidden-sm-down'},
        {text: 'New Value', class: 'hidden-sm-down'},
        {text: 'Action', sortKey: 'Action', class: 'hidden-sm-down'},
    ];

    // sorting
    currentSortName = 'Date';
    currentSorting = true;

    // pagination
    limit = 10;
    currentPage = 1;
    maxSize = 1;

    ngOnInit(): void {
        this.pagedListOfActivityAudit$
            .pipe(takeUntil(this.$destroyed))
            .subscribe(pagedListOfActivityAudit => this.pagedListOfActivityAudit = pagedListOfActivityAudit);

        this.totalCount$
            .pipe(takeUntil(this.$destroyed))
            .subscribe(totalCount => this.totalCount = totalCount);

        this.isLoading$
            .pipe(takeUntil(this.$destroyed))
            .subscribe(isLoading => this.isLoading = isLoading);

        this._activityAuditService.searchActivityAudits(
            this.caseId,
            this.currentSortName,
            this.currentSorting,
            this.limit,
            (this.currentPage - 1) * this.limit);
    }

    sortActivity(options: ITableHeader): void {
        this.currentSortName = options.sortKey;
        this.currentSorting = !options.ascending;
        this.filterActivity();
    }

    setLimit(limit: number): void {// # of rows per page
        this.limit = limit;
        this.filterActivity();
    }

    setPage(page: number): void {
        setTimeout(() => {
            this.currentPage = page; // set current page
            this.filterActivity();
        });
    }

    filterActivity(): void {
        this.pagedListOfActivityAudit$ = this._activityAuditService.searchActivityAudits(
            this.caseId,
            this.currentSortName,
            this.currentSorting,
            this.limit,
            (this.currentPage - 1) * this.limit);

        this.table.request = this.pagedListOfActivityAudit$;
    }

    public getProcessTypeLabel(processType: string): string {
        const generalUpdateProcessType = 'Assistance Applications';
        if (processType === generalUpdateProcessType) {
            return 'General';
        }

        // if not general, remove the pre appended assistance application from the more specific section
        const filteredValue = processType.replace('Assistance Application', '').trim();
        return filteredValue;
    }
}
