import {Component, OnInit} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {PledgeBulkPaymentBatchPreview, PledgeBulkPaymentReportClient} from '../../../../../services/api.client';
import {NotificationService} from '../../../../../services/NotificationService';
import {Disposable} from '../../../../../utilities/Disposable';
import swal from 'sweetalert2';

@Component({
    selector: 'app-pledge-bulk-payment-select-batch',
    templateUrl: './pledge-bulk-payment-select-batch.component.html',
    styleUrls: ['./pledge-bulk-payment-select-batch.component.scss']
})
export class PledgeBulkPaymentSelectBatchComponent extends Disposable implements OnInit {

    public loading = true;
    public batchId$ = new BehaviorSubject<string>('');
    public selectedBatch$ = new BehaviorSubject<PledgeBulkPaymentBatchPreview>(null);
    public batches$ = new BehaviorSubject<PledgeBulkPaymentBatchPreview[]>([]);
    public shouldShowPaidPledges = false;

    constructor(private pledgeReportClient: PledgeBulkPaymentReportClient,
                private _notificationService: NotificationService) {
        super();
    }

    ngOnInit(): void {
        this.loading = true;
        this.loadBatchPreviews();
    }

    private loadBatchPreviews(): void {
        this.pledgeReportClient.getBulkPaymentBatchPreviews(this.shouldShowPaidPledges).subscribe(batches => {
            this.batches$.next(batches);
            this.loading = false;
        }, error => {
            this._notificationService.showError('Error loading batches');
            this.loading = false;
        });
    }

    public onBatchSelectionChange(batchId: string): void {
        this.batchId$.next(batchId);
        this.selectedBatch$.next(this.batches$.value.find(b => b.batchId === batchId));
    }

    public onShowPaidPledgesChange(): void {
        this.selectedBatch$.next(null);
        this.batchId$.next('');
        this.loadBatchPreviews();
    }

    public isNotEmpty(value: string): boolean {
        return !(value == null || (value.trim().length === 0));
    }

    public downloadCsv(): void {
        this.pledgeReportClient.downloadBulkPaymentBatchCsv(this.batchId$.value).subscribe(response => {
            const today = new Date();
            const todayFormatted = new Intl.DateTimeFormat("en-US", {
                year: "numeric",
                month: "2-digit",
                day: "2-digit"
            }).format(today);
            const blob = response.data;
            const filename = response.fileName || `Bulk_Payment_Batch_${todayFormatted}.csv`;
            const a = document.createElement('a');
            const objectUrl = URL.createObjectURL(blob);
            a.href = objectUrl;
            a.download = filename;
            a.click();
            URL.revokeObjectURL(objectUrl);
        }, error => {
            this._notificationService.showError('Error downloading CSV');
        });
    }

    public async deleteBatch(): Promise<void> {
        let overridePaidStatus = false;
        if (this.selectedBatch$.value?.paidCount > 0) {
            const result = await swal.fire({
                heightAuto: false,
                icon: 'warning',
                showConfirmButton: true,
                showDenyButton: true,
                title: 'Contains Paid Pledges',
                text: 'This batch contains paid pledges. Are you sure you want to delete it and set the status of the pledges back to \'Confirmed\'?',
            });
            if (!result.isConfirmed) {
                return;
            }
            overridePaidStatus = true;
        } else {
            const result = await swal.fire({
                heightAuto: false,
                icon: 'question',
                showConfirmButton: true,
                showDenyButton: true,
                title: 'Delete Batch',
                text: 'Are you sure you want to delete this batch?',
            });
            if (!result.isConfirmed) {
                return;
            }
        }
        this.pledgeReportClient.deleteBatch(this.batchId$.value, overridePaidStatus).subscribe({
            next: () => {
                this._notificationService.showSuccess('Batch deleted successfully');
                this.selectedBatch$.next(null);
                this.batchId$.next(null);
                this.loadBatchPreviews();
            },
            error: () => {
                this._notificationService.showError('Error deleting batch');
            }
        });
    }

    private extractFilename(url: string): string {
        const lastSlashIndex = url.lastIndexOf('/');
        const lastDotIndex = url.lastIndexOf('.');
        if (lastDotIndex > lastSlashIndex) {
            return url.substring(lastSlashIndex + 1);
        }
        return '';
    }

    public getBatchPaidStatus(batch: PledgeBulkPaymentBatchPreview): string {
        if (batch.paidCount === 0)
        {
            return '';
        }
        return (batch.paidCount + batch.deletedPledgeCount) === batch.pledgeCount ? '(Paid)' : '(Partially Paid)';
    }

    public updatePaidCount(paidCount: number): void {
        if (!paidCount) {
            return;
        }
        const currentBatch = this.selectedBatch$.value;
        currentBatch.paidCount += paidCount;
        this.selectedBatch$.next(currentBatch);
    }
}
