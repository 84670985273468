import {from as observableFrom, Observable} from 'rxjs';

import {take, map, mergeMap} from 'rxjs/operators';
import {Injectable, Injector} from '@angular/core';
import {ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {getRouteParam} from '../utilities/Util';
import {UserService} from '../services/UserService';
import {User} from '../services/api.client';
import {UserType} from '../services/api.client';
import {NotificationService} from '../services/NotificationService';
import {select, Store} from '@ngrx/store';
import * as fromRoot from '../store';
import {selectSecurityStateDataCurrentUser} from '../store/selectors/security.selectors';

@Injectable()
export class CanEditUserGuard {

    currentUser$: Observable<User>;

    constructor(private injector: Injector, private _store: Store<fromRoot.AppState>) {
        this.currentUser$ = this._store.pipe(select(selectSecurityStateDataCurrentUser));
    }

    canActivate(route: ActivatedRouteSnapshot,
                state: RouterStateSnapshot): Observable<boolean> {
        return this.currentUser$.pipe(
            take(1),
            mergeMap((currentUser) => {
                const id = getRouteParam(route, 'id');
                if (id === 'add') {
                    return observableFrom([currentUser.userType === UserType.SystemAdmin ||
                    currentUser.userType === UserType.AgencyAdmin ||
                    currentUser.userType === UserType.RegionAdmin
                    ]);
                }
                // system admin or self edit
                if (currentUser.userType === UserType.SystemAdmin || currentUser.id === id) {
                    return observableFrom([true]);
                }
                // users, viewers and fundAdmins can't edit other users
                if (currentUser.userType === UserType.User ||
                    currentUser.userType === UserType.Viewer ||
                    currentUser.userType === UserType.FundAdmin) {
                    this.injector.get(NotificationService).showError('You do not have permission to edit this user.');
                    return observableFrom([false]);
                }
                const request: Observable<User> = this.injector.get(UserService).getUserById(id);
                return request.pipe(map((user: User) => {
                    if (
                        // AgencyAdmin may only edit Users and Viewers
                        (user.userType !== UserType.User && user.userType !== UserType.Viewer) ||
                        // agencyAdmin can only edit users within their agency
                        user.agencyId !== currentUser.agencyId
                    ) {
                        this.injector.get(NotificationService).showError('You do not have permission to edit this user.');
                        return false;
                    }
                    return true;
                }));
            }),);

    }
}
