import {EMPTY as observableEmpty, Observable, combineLatest} from 'rxjs';
import {distinctUntilChanged, filter, takeUntil, tap} from 'rxjs/operators';
import {Component, Output, EventEmitter, ViewChild, forwardRef, OnInit, AfterViewInit} from '@angular/core';
import {Router, Params, ActivatedRoute} from '@angular/router';
import {UntypedFormBuilder, FormControl} from '@angular/forms';
import {CompanyPayeeType, Company, CompanyCategory} from '../../../services/api.client';
import {LookUp} from '../../../services/api.client';
import {StatusType} from '../../../services/api.client';
import {CompanyService} from '../../../services/CompanyService';
import {extractEnumNames} from '../../../utilities/Util';
import {Masking} from '../../../utilities/Masking';
import {PromptComponent} from '../../shared/dialogs/PromptComponent';
import {PledgeType} from '../../../services/api.client';
import {PledgeTypeService} from '../../../services/PledgeTypeService';
import {Validators} from '../../../utilities/Validators';
import {AutoDisableParent} from '../../../directives/AutoDisableDirective';
import {select, Store} from '@ngrx/store';
import * as fromRoot from '../../../store';
import {selectCompanyDataCurrentSelectedCompany} from '../../../store/selectors/company.selectors';
import {selectPledgeTypeDataAllPledgeTypes} from '../../../store/selectors/pledgeType.selectors';
import {
    selectSecurityStateDataSystemDataRegion,
    selectSecurityStateDataSystemDataStates
} from '../../../store/selectors/security.selectors';
import {IPayeeType} from '../../../shared/interfaces/IPayeeType';
import {ICompanyCategory} from '../../../shared/interfaces/ICompanyCategory';
import {selectCompanyCategories} from '../../../store/selectors/companyCategory.selectors';
import {Disposable} from '../../../utilities/Disposable';

@Component({
    templateUrl: './CompanyComponent.html',
    styleUrls: ['./CompanyComponent.scss'],
    providers: [{provide: AutoDisableParent, useExisting: forwardRef(() => CompanyComponent)}],
})

export class CompanyComponent extends Disposable implements OnInit, AfterViewInit {

    get name(): FormControl {
        return this.form.get('name') as FormControl;
    }

    get isUwdaCompany(): FormControl {
        return this.form.get('isUwdaCompany') as FormControl;
    }

    get regionValue(): FormControl {
        return this.form.get('regionValue') as FormControl;
    }

    get status(): FormControl {
        return this.form.get('status') as FormControl;
    }

    get address1(): FormControl {
        return this.form.get('address1') as FormControl;
    }

    get address2(): FormControl {
        return this.form.get('address2') as FormControl;
    }

    get city(): FormControl {
        return this.form.get('city') as FormControl;
    }

    get state(): FormControl {
        return this.form.get('state') as FormControl;
    }

    get zipCode(): FormControl {
        return this.form.get('zipCode') as FormControl;
    }

    get urlLink(): FormControl {
        return this.form.get('urlLink') as FormControl;
    }

    get urlDisplay(): FormControl {
        return this.form.get('urlDisplay') as FormControl;
    }

    get sortOrder(): FormControl {
        return this.form.get('sortOrder') as FormControl;
    }

    get contactName1(): FormControl {
        return this.form.get('contactName1') as FormControl;
    }

    get contactPhone1(): FormControl {
        return this.form.get('contactPhone1') as FormControl;
    }

    get contactName2(): FormControl {
        return this.form.get('contactName2') as FormControl;
    }

    get contactPhone2(): FormControl {
        return this.form.get('contactPhone2') as FormControl;
    }

    get contactName3(): FormControl {
        return this.form.get('contactName3') as FormControl;
    }

    get contactPhone3(): FormControl {
        return this.form.get('contactPhone3') as FormControl;
    }

    get isAccountValidationEnforced(): FormControl {
        return this.form.get('isAccountValidationEnforced') as FormControl;
    }

    get isAccountValid(): FormControl {
        return this.form.get('isAccountValid') as FormControl;
    }

    get isAccountValidSpecialChars(): FormControl {
        return this.form.get('isAccountValidSpecialChars') as FormControl;
    }

    get accountValidationMinLen(): FormControl {
        return this.form.get('accountValidationMinLen') as FormControl;
    }

    get accountValidationMaxLen(): FormControl {
        return this.form.get('accountValidationMaxLen') as FormControl;
    }

    get accountValidationErrorMsg(): FormControl {
        return this.form.get('accountValidationErrorMsg') as FormControl;
    }

    get isAccountValidNumerical(): FormControl {
        return this.form.get('isAccountValidNumerical') as FormControl;
    }

    get isAccountValidAlphabetical(): FormControl {
        return this.form.get('isAccountValidAlphabetical') as FormControl;
    }

    get pledgeTypeIds(): FormControl {
        return this.form.get('pledgeTypeIds') as FormControl;
    }

    get payeeType(): FormControl {
        return this.form.get('payeeType') as FormControl;
    }

    get category(): FormControl {
        return this.form.get('category') as FormControl;
    }

    constructor(private _formBuilder: UntypedFormBuilder,
                private _companyService: CompanyService,
                private _pledgeTypeService: PledgeTypeService,
                private _router: Router,
                private _route: ActivatedRoute,
                private _store: Store<fromRoot.AppState>) {
        super();
        this.allRegions$ = this._store.pipe(select(selectSecurityStateDataSystemDataRegion));
        this.states$ = this._store.pipe(select(selectSecurityStateDataSystemDataStates));
        this.currentCompany$ = this._store.pipe(select(selectCompanyDataCurrentSelectedCompany));
        this.allPledgeTypes$ = this._store.pipe(select(selectPledgeTypeDataAllPledgeTypes));
        this.companyCategories$ = this._store.select(selectCompanyCategories);
    }

    statuses = extractEnumNames(StatusType);
    params: any;

    allRegions: LookUp[] = [];
    allRegions$: Observable<LookUp[]>;
    states: LookUp[];
    states$: Observable<LookUp[]>;
    currentCompany: Company;
    currentCompany$: Observable<Company>;
    allPledgeTypes: PledgeType[];
    allPledgeTypes$: Observable<PledgeType[]>;
    filteredPledgeTypes: PledgeType[];
    payeeTypesSelect: IPayeeType[];
    categorySelect: ICompanyCategory[];
    companyCategories: CompanyCategory[];
    companyCategories$: Observable<CompanyCategory[]>;

    phoneMask = Masking.phoneMask;

    form = this._formBuilder.group({
        isUwdaCompany: new FormControl(false),
        name: new FormControl(null, Validators.required),
        regionValue: new FormControl(null, Validators.required),
        pledgeTypeIds: new FormControl([]),
        address1: new FormControl(null, Validators.required),
        address2: new FormControl(null),
        city: new FormControl(null, Validators.required),
        state: new FormControl(null, Validators.required),
        zipCode: new FormControl(null, Validators.required),
        allocateAllowed: new FormControl(false),
        sortOrder: new FormControl(null),
        status: new FormControl(null),
        contactName1: new FormControl(null),
        contactPhone1: new FormControl(null),
        contactName2: new FormControl(null),
        contactPhone2: new FormControl(null),
        contactName3: new FormControl(null),
        contactPhone3: new FormControl(null),
        payeeType: new FormControl(null, Validators.required),
        category: new FormControl(null, Validators.required),
        urlLink: new FormControl(null),
        urlDisplay: new FormControl(null),
        isAccountValidationEnforced: new FormControl(false),
        isAccountValidAlphabetical: new FormControl(false),
        isAccountValidNumerical: new FormControl(false),
        isAccountValidSpecialChars: new FormControl(false),
        accountValidationMinLen: new FormControl(null),
        accountValidationMaxLen: new FormControl(null),
        accountValidationErrorMsg: new FormControl(null),

        // frontend
        isAccountValid: new FormControl('')
    });

    // children - agency modal
    @ViewChild(PromptComponent)
    promptModal: PromptComponent;
    @Output('onShowPromptModal') _showPrompt = new EventEmitter();

    ngOnInit(): void {
        this.setCompanyPayeeTypesSelect();
        this.allRegions$
            .pipe(takeUntil(this.$destroyed))
            .subscribe(allRegions => this.allRegions = allRegions);
        this.states$
            .pipe(takeUntil(this.$destroyed))
            .subscribe(states => this.states = states);
        this.currentCompany$
            .pipe(takeUntil(this.$destroyed))
            .subscribe(currentCompany => this.currentCompany = currentCompany);
        this.allPledgeTypes$
            .pipe(takeUntil(this.$destroyed))
            .subscribe(pledgeTypes => this.allPledgeTypes = pledgeTypes);
        this._pledgeTypeService.getAllPledgeTypes()
            .pipe(takeUntil(this.$destroyed))
            .subscribe((pTs: PledgeType[]) => {
                this.filteredPledgeTypes = pTs.filter(x => x.isActive === true);
            });
        this.companyCategories$
            .pipe(takeUntil(this.$destroyed))
            .subscribe(companyCategories => {
                this.companyCategories = companyCategories;
                this.populateCategorySelect();
            });

        this._route.params
            .pipe(takeUntil(this.$destroyed))
            .subscribe((params: Params) => {
                this.params = params;
                const id = params.id;
                if (id === 'add') { // add
                    this._companyService.setCurrentSelectedCompany(new Company());
                } else {// edit
                    this._companyService.getCompanyById(id);
                }
            });

        this.initForm();

        this.currentCompany$.pipe(
            takeUntil(this.$destroyed),
            filter(company => !!company))
            .subscribe(company => {
                this.form.patchValue(company);
                this.pledgeTypeIds.setValue(company.pledgeTypeIds, {onlySelf: false, emitEvent: true, emitModelToViewChange: true});
            });
    }

    initForm(): void {
        combineLatest([
            this.isAccountValidNumerical.valueChanges,
            this.isAccountValidAlphabetical.valueChanges,
        ])
            .pipe(takeUntil(this.$destroyed))
            .subscribe(([isAccountValidNumerical, isAccountValidAlphabetical]) => {

                if (isAccountValidNumerical === false && isAccountValidAlphabetical === true) {
                    this.isAccountValid.setValue('alphabetical');
                } else if (isAccountValidNumerical === true && isAccountValidAlphabetical === false) {
                    this.isAccountValid.setValue('numeric');
                } else if (isAccountValidNumerical && isAccountValidAlphabetical) {
                    this.isAccountValid.setValue('alphabeticalAndNumeric');
                } else {
                    this.isAccountValid.setValue('');
                }
            });

        this.isAccountValid
            .valueChanges.pipe(
            takeUntil(this.$destroyed),
            distinctUntilChanged())
            .subscribe((value) => {
                switch (value) {
                    case 'numeric':
                        this.isAccountValidNumerical.setValue(true);
                        this.isAccountValidAlphabetical.setValue(false);
                        break;
                    case 'alphabetical':
                        this.isAccountValidNumerical.setValue(false);
                        this.isAccountValidAlphabetical.setValue(true);
                        break;
                    case 'alphabeticalAndNumeric':
                        this.isAccountValidNumerical.setValue(true);
                        this.isAccountValidAlphabetical.setValue(true);
                        break;
                }
            });
    }

    // checkBoxDirty = false;
    // selectedPledgeTypes: string[] = [];

    // //check-boxes events
    // pledgeTypesChange(pledgeType, checked: boolean) {
    //     this.checkBoxDirty = true;
    //     this.isValidSave();
    //     if (checked) {
    //         this.selectedPledgeTypes.push(pledgeType.id);
    //         this.pledgeTypeIds.setValue(this.selectedPledgeTypes);
    //     } else {
    //         this.selectedPledgeTypes.splice(this.selectedPledgeTypes.findIndex(id => id == pledgeType.id), 1);
    //         this.pledgeTypeIds.setValue(this.selectedPledgeTypes);
    //     }
    // }
    //
    // //populate check-boxes
    // isChecked(pledgeType) {
    //     if (this.currentCompany) {
    //         let types = this.currentCompany.pledgeTypeIds;
    //         if (types) {
    //             if (types.indexOf(pledgeType.id) > -1) {
    //                 return true;
    //             } else {
    //                 return false;
    //             }
    //         }
    //     }
    // }


    // disable save button if no changes
    isValidSave(): boolean {
        return !this.form.dirty;
    }

    isEdit(): boolean {
        return !!(this.currentCompany && this.currentCompany.id);
    }

    submit(): Observable<Company> {
        const company = this.form.value;
        this.form.markAllAsTouched();
        if (!this.form.valid) {
            return observableEmpty;
        } else if (this.isEdit()) {
            company.id = this.currentCompany.id;
            const updatedCompany = Company.fromJS({
                id: company?.id,
                isUwdaCompany: company?.isUwdaCompany || false,
                name: company?.name,
                isIndividual: company?.isIndividual || false,
                address1: company?.address1,
                address2: company?.address2,
                city: company?.city,
                state: company?.state,
                zipCode: company?.zipCode,
                allocateAllowed: company?.allocateAllowed || false,
                sortOrder: company?.sortOrder || null,
                status: company?.status || null,
                contactName1: company?.contactName1,
                contactPhone1: company?.contactPhone1,
                contactName2: company?.contactName2,
                contactPhone2: company?.contactPhone2,
                contactName3: company?.contactName3,
                contactPhone3: company?.contactPhone3,
                payeeType: company?.payeeType,
                category: company?.category,
                isAccountValidationEnforced: company?.isAccountValidationEnforced || false,
                isAccountValidAlphabetical: company?.isAccountValidAlphabetical || false,
                isAccountValidNumerical: company?.isAccountValidNumerical || false,
                isAccountValidSpecialChars: company?.isAccountValidSpecialChars || false,
                accountValidationMinLen: company?.accountValidationMinLen || null,
                accountValidationMaxLen: company?.accountValidationMaxLen || null,
                accountValidationErrorMsg: company?.accountValidationErrorMsg,
                urlLink: company?.urlLink,
                urlDisplay: company?.urlDisplay,
                lastUsedDate: company?.lastUsedDate || null,
                regionValue: company?.regionValue || null,
                pledgeTypeIds: company?.pledgeTypeIds || null
            });
            return this._companyService.updateCompany(updatedCompany)
                .pipe(
                    tap(() => this._router.navigate(['/app/companies']))
                );
        } else {
            return this._companyService.addCompany(company)
                .pipe(
                    tap(() => this._router.navigate(['/app/companies']))
                );
        }
    }

    // modal
    showPromptModal(): boolean {
        this.promptModal.open()
            .pipe(takeUntil(this.$destroyed))
            .subscribe(okClicked => {
                if (okClicked) {
                    this._companyService.deleteCompany(this.currentCompany.id);
                    this._router.navigate(['/app/companies', this.params]).then();
                } else {
                    return false;
                }
            });

        return false;
    }

    private setCompanyPayeeTypesSelect(): void {
        this.payeeTypesSelect = ([{
            key: this.getCompanyPayeeTypeKeyByValue(CompanyPayeeType.Company),
            value: 'Company'
        }, {
            key: this.getCompanyPayeeTypeKeyByValue(CompanyPayeeType.Individual),
            value: 'Individual'
        }] as IPayeeType[]);
    }

    private getCompanyPayeeTypeKeyByValue(value: number): string | undefined {
        return Object.keys(CompanyPayeeType)
            .find(
                (key) => CompanyPayeeType[key as keyof typeof CompanyPayeeType] === value
            ) as string | undefined;
    }

    private populateCategorySelect(): void {
        this.categorySelect = this.companyCategories.map(x => ({
            key: x.category.toString(),
            value: x.categoryLabel
        } as ICompanyCategory));
    }

    ngOnDestroy(): void {
        this._companyService.setCurrentSelectedCompanyAction(null);
    }

    ngAfterViewInit(): void {
    }

}
