import {Component, OnInit, ViewChild} from '@angular/core';
import {PagedListOfPledgeType, PledgeType, SystemData} from '../../../services/api.client';
import {ITableHeader, TableComponent} from '../../shared/table/TableComponent';
import {PledgeTypeService} from '../../../services/PledgeTypeService';
import {select, Store} from '@ngrx/store';
import * as fromRoot from '../../../store';
import {Observable} from 'rxjs';
import {
  selectPledgeTypeDataPledgeTypes,
  selectPledgeTypeDataPledgeTypesItems,
  selectPledgeTypeDataPledgeTypesTotalCount
} from '../../../store/selectors/pledgeType.selectors';

// shared
// store
// models
// services
// utilities
// directives
// modals

@Component({
  templateUrl: './PledgeTypeListComponent.html',
  styleUrls: ['./PledgeTypeListComponent.scss'],
})

export class PledgeTypeListComponent implements OnInit {

  constructor(private _pledgeTypeService: PledgeTypeService, private _store: Store<fromRoot.AppState>) {
    this.pagedList$ = this._store.pipe(select(selectPledgeTypeDataPledgeTypes));
    this.pledgeTypes$ = this._store.pipe(select(selectPledgeTypeDataPledgeTypesItems));
    this.totalCount$ = this._store.pipe(select(selectPledgeTypeDataPledgeTypesTotalCount));
  }

  isLoading: boolean;
  systemData: SystemData;
  searchValue = '';

  // subscriptions
  pagedList: PagedListOfPledgeType;
  pagedList$: Observable<PagedListOfPledgeType>;
  pledgeTypes: PledgeType[];
  pledgeTypes$: Observable<PledgeType[]>;

  // table sort
  currentSortName = 'Name';
  currentSorting = false;

  tableHeaders: ITableHeader[] = [
    {text: 'Fund Type Name', sortKey: 'Name', ascending: true},
    {text: 'Region', sortKey: 'Name'},
    {text: 'Fiscal Start'},
    {text: 'Require SSN', class: 'hidden-sm-down'},
    {text: 'Min Single', sortKey: 'MinSinglePledgeAmt', class: 'hidden-sm-down'},
    {text: 'Max Single', sortKey: 'MaxSinglePledgeAmt', class: 'hidden-sm-down'},
    {text: 'Max Yearly', sortKey: 'MaxYearlyPledgeAmt', class: 'hidden-sm-down'},
    {text: 'Crisis Amount', sortKey: 'CrisisPledgeThreshold', class: 'hidden-sm-down'},
    {text: 'Status', sortKey: 'Status', class: 'hidden-sm-down'},
    {text: 'Is EFS', sortKey: 'IsEfs', class: 'hidden-sm-down'},
    {text: 'Is Private', sortKey: 'IsPrivate', class: 'hidden-sm-down'},
    {text: 'Ledger #', sortKey: 'LedgerNumber', class: 'hidden-sm-down'},
  ];

  // pagination
  limit = 10;
  currentPage = 1;
  // maxSize: number = 1;
  totalCount: number;
  totalCount$: Observable<number>;

  @ViewChild(TableComponent)
  table: TableComponent;

  ngOnInit(): void {
    this.pledgeTypes$.subscribe(pledgeTypes => this.pledgeTypes = pledgeTypes);
    this.pagedList$.subscribe(pagedList => this.pagedList = pagedList);
    this.totalCount$.subscribe(totalCount => this.totalCount = totalCount);
  }

  // set Current Selected Pledge Type
  setCurrentSelectedPledgeType(pledgeType: PledgeType): void {
    this._pledgeTypeService.setCurrentSelectedPledgeType(pledgeType);
    // this._router.navigate(['/app/pledgeType', pledgeType.id, {pledgeTypeTab: 'pledgeType'}]);
  }

  // search value
  setSearchValue(searchValue): void {
    this.searchValue = searchValue;
  }

  // sorting
  sortPledgeTypes(options: ITableHeader): void {
    this.currentSortName = options.sortKey;
    this.currentSorting = !options.ascending;
    this.filterPledgeTypes();
  }
  setLimit(limit: number): void {// # of rows per page
    this.limit = limit;
    this.filterPledgeTypes();
  }

  setPage(page: number): void {
    setTimeout(() => {
      this.currentPage = page; // set current page
      this.filterPledgeTypes();
    });
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.filterPledgeTypes();
    });

  }

  // load date from the server
  filterPledgeTypes(): void {// get Pledge Types
    this.table.request = this._pledgeTypeService.getPledgeTypes(
      this.searchValue,
      this.currentSortName,
      this.currentSorting,
      this.limit,
      (this.currentPage - 1) * this.limit);
  }
}
