<h1 class="page-title">Browse EF Network Cases</h1>
<section class="widget">
    <div class="widget-body">
        <div class="mt">
            <form novalidate>
                <div class="row">
                    <div class="col-md col-xs-12">
                        <ul class="help-text-align">
                            <li><span>To enter new EFN case, go to ‘Clients’ Menu on the left.</span></li>
                            <li><span>To select a EFN case, click on the 'Total Need' column.</span></li>
                        </ul>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md col-xs-12">
                        <!--Search String #-->
                        <label class="header-label">Search:</label>
                        <input class="search-text-input form-control"
                               type="text"
                               placeholder="Search by client name or key word"
                               [ngModel]="searchValue"
                               [ngModelOptions]="{standalone: true}"
                               (ngModelChange)="setSearchValue($event)"/>
                    </div>
                    <div class="col-md col-xs-12">
                        <!--Agency modal filter-->
                        <label class="header-label">Agency:</label>
                        <input class="agency-modal-input form-control"
                               type="text"
                               placeholder="select agency to filter"
                               (click)="showAgencyModal()"
                               (keydown)="showAgencyModal()"
                               value="{{selectedAgencyName}}"
                               [disabled]="isDisabledAgency()"/>
                    </div>
                    <div class="col-md col-xs-12 dropdown-menu-top">
                        <!--Status filter-->
                        <label class="header-label">Case Status:</label>
                        <select name="" id="" class="custom-select form-control" [formControl]="caseStatus">
                            <option value="">-- Filter By Status --</option>
                            <option *ngFor="let status of statuses" [value]="status.value">{{status.key}}</option>
                        </select>
                    </div>
                </div>
                <div class="row">
                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <button class="btn btn-primary pull-left" role="button" (click)="searchEfnCases()">Search
                        </button>
                    </div>
                </div>
            </form>
            <table-dynamic id="datatable-table" class="table table-striped table-hover"
                           [headers]="tableHeaders" [currentPage]="currentPage" [totalCount]="totalCount"
                           (headerClick)="sortCases($event)" (pageChanged)="setPage($event)"
                           (limitChange)="setLimit($event)">
                <tr *ngFor="let case of efnCaseList?.items">
                    <td><a [routerLink]="['../client', case.clientId]"><strong>{{
                        case.clientLastName}}</strong></a></td>
                    <td class="hidden-sm-down">{{case.clientFirstName}}</td>
                    <td class="hidden-sm-down">***-**-{{case.clientLast4Ssn}}</td>
                    <td class="hidden-sm-down">{{case.enteredDate | moment: 'L'}}</td>
                    <td class="hidden-sm-down">{{case.deadline | moment: 'L'}}</td>
                    <td class="hidden-sm-down">{{case.fundedDate | moment: 'L'}}</td>
                    <td>{{formatStatus(case.status)}}</td>
                    <td class="hidden-sm-down">{{case.createdByAgencyName}}</td>
                    <td>{{case.currentTotal | currency:'USD':'symbol':'1.2-2'}}</td>
                    <td><a [routerLink]="['../client', case.clientId, 'efn_cases', case.id]"
                           (click)="setCurrentSelectedEfnCase(case)"><strong>{{case.totalNeed
                        | currency:'USD':'symbol':'1.2-2'}}</strong></a></td>
                </tr>
            </table-dynamic>
        </div>
    </div>
    <agency-box (onAgencySelected)="onAgencySelected($event)"></agency-box>
</section>
