import {take, filter, map} from 'rxjs/operators';
import {Component, Input, OnInit} from '@angular/core';
import {FundTypeClient, PledgeType} from '../../../services/api.client';
import {PledgeTypeService} from '../../../services/PledgeTypeService';
import {Observable} from 'rxjs';
import {UntypedFormControl} from '@angular/forms';
import {select, Store} from '@ngrx/store';
import * as fromRoot from '../../../store';
import {selectPledgeTypeDataAllPledgeTypes} from '../../../store/selectors/pledgeType.selectors';

@Component({
    selector: 'pledgeTypeCheckbox',
    templateUrl: './PledgeTypeCheckbox.html'
})
export class PledgeTypeCheckbox implements OnInit {
    pledgeTypes: PledgeType[];
    pledgeTypes$: Observable<PledgeType[]>;
    filteredPledgeTypes$: Observable<PledgeType[]>;

    @Input()
    control: UntypedFormControl;
    @Input()
    showDisabled: boolean;
    @Input()
    requireOne: boolean;
    @Input()
    preselectAll: boolean;
    @Input()
    showSelectAll: boolean;
    @Input()
    filterByRole: boolean;

    public allSelected: boolean;
    public togglingSelectAll = false;

    constructor(private _pledgeTypeService: PledgeTypeService, private _fundTypeClient: FundTypeClient, private _store: Store<fromRoot.AppState>) {
        this.pledgeTypes$ = this._store.pipe(select(selectPledgeTypeDataAllPledgeTypes));
    }

    onClick(id: string): boolean {
        if (this.requireOne) {
            const values = this.control.value;
            // make sure at least one checkbox is checked
            if (values && values.length === 1 && values[0] === id) {
                return false;
            }
        }
    }

    ngOnInit(): void {
        this.allSelected = this.preselectAll ?? false;
        if (this.filterByRole) {
            this.pledgeTypes$ = this._fundTypeClient.getRoleFundTypes().pipe();
        }
        this.pledgeTypes$.subscribe(pledgeTypes => {
            this.pledgeTypes = pledgeTypes;
        });
        // fetch pledge types only if not yet loaded
        this.pledgeTypes$.pipe(
            filter(pledges => !pledges))
            .subscribe(() => {
                this._pledgeTypeService.getAllPledgeTypes();
            });

        this.filteredPledgeTypes$ = (this.showDisabled) ?
            this.pledgeTypes$.exists() :
            this.pledgeTypes$.exists().pipe(map((p) => p.filter(f => f.isActive)));

    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            if (this.preselectAll) {
                this.filteredPledgeTypes$.pipe(
                    take(1))
                    .subscribe((data) => this.control.setValue(data.map(d => d.id)));
            }

        });
    }

    public toggleSelectAll(): void {
        this.togglingSelectAll = true;
        if (this.allSelected) {
            this.allSelected = false;
            this.control.setValue([]);
            this.togglingSelectAll = false;
        } else {
            this.allSelected = true;
            this.filteredPledgeTypes$.pipe(
                take(1))
                .subscribe((data) => {
                    this.control.setValue(data.map(d => d.id));
                    this.togglingSelectAll = false;
                });
        }
    }

}
