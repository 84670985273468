<div class="container mt-4">
    <div class="row">
        <div class="col-12">
            See the checklist below for the documents you will need to upload to complete your application:
        </div>
    </div>
    <div class="row p-t-1">
        <div class="col-12">
            <button class="btn" (click)="showDocumentInformation = !showDocumentInformation">
                What documents do I need
                <i *ngIf="showDocumentInformation" class="toggle fa fa-angle-down"></i>
                <i *ngIf="!showDocumentInformation" class="toggle fa fa-angle-right"></i>
            </button>
        </div>
    </div>
    <div class="document-information-wrapper m-t-2" *ngIf="showDocumentInformation">
        <div class="row w-100">
            <div class="col-12">
                <!-- Photo ID -->
                <div class="mb-2">
                    <i data-toggle="tooltip" data-container="body" data-placement="top"
                       title="Please add this required document" *ngIf="!photoIdStatus"
                       class="fa fa-exclamation-circle me-2 missing-color"></i>
                    <i *ngIf="photoIdStatus" class="fa fa-check-circle-o me-2 check-color"></i>
                    <span>Photo ID</span>
                </div>
                <!-- Employment Status -->
                <div class="mb-2">
                    <i *ngIf="!employmentStatus" class="fa fa-exclamation-circle me-2 missing-color"></i>
                    <i *ngIf="employmentStatus" class="fa fa-check-circle-o me-2 check-color"></i>
                    <span>At least one from the following list:</span>
                </div>
                <ul class="mb-3">
                    <li>Utility bill</li>
                    <li>Personal property tax statement</li>
                    <li>Tax return transcript</li>
                    <li>Bank statement</li>
                    <li>Letter from government or court</li>
                    <li>Statement of government benefits
                        <ul>
                            <li>SSI/SSDI award letter</li>
                            <li>Unemployment award</li>
                            <li>WIC or SNAP award letters or benefit statements</li>
                        </ul>
                    </li>

                </ul>
                <!-- Income Status -->
                <div class="mb-2">
                    <i *ngIf="!incomeStatus" class="fa fa-exclamation-circle me-2 missing-color"></i>
                    <i *ngIf="incomeStatus" class="fa fa-check-circle-o me-2 check-color"></i>
                    <span>At least one of the following for ALL adults</span>
                </div>
                <ul class="mb-2">
                    <li>2 check stubs that are less than 90 days old</li>
                    <li>Pension letter</li>
                    <li>Government benefit statement letters
                        <ul>
                            <li>SSI/SSDI award letter</li>
                            <li>Unemployment award</li>
                            <li>WIC or SNAP award letters or benefit statements</li>
                        </ul>
                    </li>
                    <li>
                        If self-employed:
                    </li>
                    <ul>
                        <li>Most recent tax returns</li>
                        <li>Profit and loss statement</li>
                    </ul>

                </ul>

                <div class="mb-1"
                     *ngIf="baseForm.errors?.incomeDocumentRequiredForSelfEmployed">
                    <div class="text-danger">
                        <i class="fa fa-exclamation-circle me-2"></i>
                        <span>Please provide tax returns and/or profit and loss statements for each self-employed adult.</span>
                    </div>
                </div>

                <div class="mb-1">
                    <div class="text-danger"
                         *ngIf="baseForm.errors?.incomeDocumentRequiredForNonSelfEmployed">
                        <i class="fa fa-exclamation-circle me-2"></i>
                        <span>Please provide sufficient income documents for all non-self-employed adults.</span>
                    </div>
                </div>

                <ng-container *ngIf="requestingUtilityAssistance">
                    <div class="mb-2">
                        <i *ngIf="!utilityBillStatus" class="fa fa-exclamation-circle me-2 missing-color"></i>
                        <i *ngIf="utilityBillStatus" class="fa fa-check-circle-o me-2 check-color"></i>
                        <span>{{utilityBillLabel}} or Shut-off Notice</span>
                    </div>
                </ng-container>

                <ng-container *ngIf="requestingChildcareAssistance">
                    <div class="mb-2">
                        <i *ngIf="!childcareBillStatus" class="fa fa-exclamation-circle me-2 missing-color"></i>
                        <i *ngIf="childcareBillStatus" class="fa fa-check-circle-o me-2 check-color"></i>
                        <span>Childcare Bill or Statement</span>
                    </div>
                </ng-container>

                <ng-container *ngIf="requestingCarRepairAssistance">
                    <div class="mb-2">
                        <i *ngIf="!carRepairEstimateStatus" class="fa fa-exclamation-circle me-2 missing-color"></i>
                        <i *ngIf="carRepairEstimateStatus" class="fa fa-check-circle-o me-2 check-color"></i>
                        <span>Car Repair Bill Estimate</span>
                    </div>
                    <ul class="mb-2">
                        <li>Bill or estimate must be in the name of the applicant (including year, make/model of the repair vehicle)
                            <ul>
                                <li>Repair service(s) must be outlined</li>
                                <li>Repair company’s name and address must be on the bill or estimate</li>
                                <li>Repair must cost will result in the car being operable</li>
                            </ul>
                        </li>
                    </ul>
                </ng-container>

                <ng-container *ngIf="requestingRentAssistance">
                    <div class="mb-2">
                        <i *ngIf="!leaseAgreementStatus" class="fa fa-exclamation-circle me-2 missing-color"></i>
                        <i *ngIf="leaseAgreementStatus" class="fa fa-check-circle-o me-2 check-color"></i>
                        <span>Lease Agreement</span>
                    </div>

                    <div class="mb-2">
                        <i *ngIf="delinquentRentNoticeStatus" class="fa fa-check-circle-o me-2 check-color"></i>
                        <i *ngIf="!delinquentRentNoticeStatus" class="fa fa-exclamation-circle me-2 missing-color"></i>
                        <span>Delinquent Rent Notice</span>
                    </div>
                </ng-container>

                <ng-container *ngIf="requestingEvictionAssistance">
                    <div class="mb-2">
                        <i *ngIf="!evictionStatus" class="fa fa-exclamation-circle me-2 missing-color"></i>
                        <i *ngIf="evictionStatus" class="fa fa-check-circle-o me-2 check-color"></i>
                        <span>Eviction Notification</span>
                    </div>
                    <ul class="mb-2">
                        <li>Attach court notice or screenshot of court record</li>
                    </ul>
                </ng-container>

                <ng-container *ngIf="requestingCarRepairAssistance">
                    <div class="mb-2">
                        <i *ngIf="!proofOfIncome" class="fa fa-exclamation-circle me-2 missing-color"></i>
                        <i *ngIf="proofOfIncome" class="fa fa-check-circle-o me-2 check-color"></i>
                        <span>Proof of Employment</span>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>

    <div class="row p-y-1">
        <div class="col-12">
            <div class="fw-semi-bold">
                Please upload the documents below to complete your application:
            </div>
        </div>
    </div>

    <div class="row p-b-1">
        <div class="col-12">
            <button class="btn" (click)="showUploadedDocuments = !showUploadedDocuments">
                Add/Edit Documents
                <i *ngIf="showUploadedDocuments" class="toggle fa fa-angle-down"></i>
                <i *ngIf="!showUploadedDocuments" class="toggle fa fa-angle-right"></i>
            </button>
        </div>
    </div>
    <div class="document-upload-wrapper" *ngIf="showUploadedDocuments">
    <!-- Add Document Button -->
        <div class="row">
            <div class="col-12">
                <button type="button" class="btn btn-primary" (click)="addDocumentFormGroup()">
                    <i class="fa fa-plus me-2"></i>Add Document
                </button>
            </div>
        </div>

        <!-- Document Upload Rows -->
        <div class="row mt-3 mb-3 file-row" *ngFor="let formGroup of formArray.controls; index as i"
             [formGroup]="getFileInputFormGroup(i)">
            <div class="col-xs-12">
                <div *ngIf="getFileInputFormGroup(i).valid; else invalid" class="text-success">
                    <i class="fa fa-check fa-2x mt-2"></i>
                </div>
                <ng-template #invalid>
                    <div class="text-danger">
                        <i class="fa fa-times-circle fa-2x mt-2"></i>
                    </div>
                </ng-template>

                <!-- Document type row -->
                <div *ngIf="!formGroup.controls?.id?.value" class="row my-2">
                    <div class="col-xs-12">
                        <select formControlName="fileType" class="form-control doc-select w-100">
                            <option [value]="null" selected disabled>Select a document type...</option>
                            <option [disabled]="formGroup.controls?.id?.value" *ngFor="let x of docOptions" [value]="x.value">{{x.label}}</option>
                        </select>
                        <div *ngIf="!formGroup.get('fileType').value" class="text-danger mt-1">
                            Please select a document type.
                        </div>
                    </div>
                </div>

                <div *ngIf="formGroup.controls?.id?.value" class="row my-2">
                    <div class="p-l-1 file-type">{{getFileType(formGroup.controls?.fileType?.value)}}</div>
                </div>

                <!-- File input row -->
                <div class="row my-2">
                    <div class="col-xs-12">
                        <ng-container *ngIf="!formGroup.get('file').value">
                            <label class="custom-file w-100">
                                <input (change)="onFileChange($event, formGroup)" type="file" id="file-upload" accept=".jpg, .jpeg, .png, .gif, .bmp, .heic, .pdf, .doc, .docx, .txt, .xlsx"
                                       class="custom-file-input">
                                <span class="custom-file-control"></span>
                            </label>
                            <div class="text-danger mt-1">
                                Please upload a document.
                            </div>
                        </ng-container>
                        <div *ngIf="formGroup.get('file').value" class="mt-1 ml-2 file-name">
                            {{ formGroup.get('file').value.name }}
                            <span><a target="_blank" [href]="apiUrl + formGroup.get('file').value.fileUrl + '&' + system.getQueryParameters()"><i class="fa fa-download download-icon" style="padding-left: 5px;"></i></a></span>
                        </div>
                    </div>
                </div>

                <!-- Delete button row -->
                <div class="row mb-2 mt-4">
                    <div class="col-xs-12 delete-file-button-container">
                        <button type="button" class="btn btn-danger" (click)="removeDoc(i)">
                            <i class="fa fa-trash me-2"></i>Delete
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
