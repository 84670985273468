import {from as observableFrom, Observable} from 'rxjs';

import {take, mergeMap} from 'rxjs/operators';
import {Injectable, Injector, OnInit} from '@angular/core';
import {Router, ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {SecurityService} from '../services/SecurityService';
import {User} from '../services/api.client';
import {SystemService} from '../services/SystemService';
import {select, Store} from '@ngrx/store';
import * as fromRoot from '../store';
import {selectSecurityStateDataCurrentUser} from '../store/selectors/security.selectors';

@Injectable()
export class AuthGuard {

    user$: Observable<User>;
    user: User;

    constructor(
        private inject: Injector,
        private systemService: SystemService,
        private _store: Store<fromRoot.AppState>) {
        this.user$ = this._store.pipe(select(selectSecurityStateDataCurrentUser));
        this.user$.subscribe(user => this.user = user);
    }

    canActivate(route: ActivatedRouteSnapshot,
                state: RouterStateSnapshot): Observable<boolean> {
        const service: SecurityService = this.inject.get(SecurityService);
        const router: Router = this.inject.get(Router);
        return this.user$.pipe(
            take(1)).pipe(
            mergeMap((user) => {
                if (user || service.checkLocalLogin()) {

                    return observableFrom([true]);
                } else {
                    service.redirectUrl = state.url;
                    router.navigate(['/login']).then();
                    return observableFrom([false]);
                }
            }));
    }

}
