<div class="row force-full-width">
    <div class="col-xs-12">
        <div class="clearfix">
            <ul class="nav nav-tabs pull-xs-left" role="tablist">
                <li class="nav-item"><a class="nav-link" (click)="tab = 'files'; false;"
                                        [class.active]="tab === 'files'">Files</a></li>
                <li class="nav-item"><a class="nav-link" (click)="tab = 'comments'; false;" [class.active]="tab === 'comments'">Comments</a>
                </li>
            </ul>
        </div>
        <div class="tab-content mb-lg overflow-visible">
            <div role="tabpanel" class="tab-pane active in" [ngSwitch]="tab">
                <app-assistance-application-files *ngIf="tab === 'files'" [receivedForms]="assistanceApplication?.files"></app-assistance-application-files>
                <app-assistance-application-comments *ngIf="tab === 'comments'" [assistanceApplication]="assistanceApplication"></app-assistance-application-comments>
            </div>
        </div>
    </div>
</div>
