import * as moment from 'moment';
import Moment = moment.Moment;
import Duration = moment.Duration;
import {unitOfTime} from 'moment';


type UnitOfTime = ('year' | 'years' | 'y' |
  'quarter' | 'quarters' | 'Q' |
  'month' | 'months' | 'M' |
  'week' | 'weeks' | 'w' |
  'day' | 'days' | 'd' |
  'hour' | 'hours' | 'h' |
  'minute' | 'minutes' | 'm' |
  'second' | 'seconds' | 's' |
  'millisecond' | 'milliseconds' | 'ms');

export class DateColumn {
  active = false;
  disable = false;

  events: Event[];
  private _date: Moment;

  constructor(date: Moment) {
    this._date = date.clone();
  }

  add(n: number, p: UnitOfTime): Duration;
  add(n: number): Duration;
  add(d: Duration): Duration;
  add(...args: any[]) {
    const date = this._date.clone();
    return date.add.apply(date, args);
  }

  subtract(n: number, p: UnitOfTime): Duration;
  subtract(n: number): Duration;
  subtract(d: Duration): Duration;
  subtract(...args: any[]) {
    const date = this._date.clone();
    return date.subtract.apply(date, args);
  }

  isAfter(): boolean;
  isAfter(b: Moment | string | number | Date | number[], granularity?: string): boolean;
  isAfter(...args: any[]) {
    return this._date.isAfter.apply(this._date, args);
  }

  isBefore(): boolean;
  isBefore(b: Moment | string | number | Date | number[], granularity?: unitOfTime.StartOf): boolean;
  isBefore(...args: any[]) {
    return this._date.isBefore.apply(this._date, args);
  }

  isSameOrBefore(b: Moment, granularity?: unitOfTime.StartOf): boolean {
    return this._date.isSameOrBefore(b, granularity);
  }

  isSameOrAfter(b: Moment, granularity?: unitOfTime.StartOf): boolean {
    return this._date.isSameOrAfter(b, granularity);
  }


  clone(): DateColumn {
    return new DateColumn(this._date);
  }

  date(d: number): Moment;
  date(): number;
  date(...args: any[]) {
    return this._date.date.apply(this._date, args);
  }


  isSame(b: Moment | string | number | Date | number[], granularity?: string): boolean;
  isSame(...args: any[]): boolean {
    return this._date.isSame.apply(this._date, args);
  }

  format(format: string): string;
  format(): string;
  format(...args: any[]) {
    return this._date.format.apply(this._date, args);
  }

  toMoment() {
    return this._date.clone();
  }
}
