<ng-container *ngIf="pagedData">
    <table-dynamic
            (limitChange)="onLimitChange($event)"
            (pageChanged)="onPageChange($event)"
            [currentPage]="pagedData.pageIndex"
            [headers]="headers"
            [totalCount]="pagedData.totalCount"
            class="table table-striped"
            id="datatable-table">
        <tr class="cursor-pointer" *ngFor="let row of pagedData.items">
            <td>{{row.fundName}}</td>
            <td [title]="row.counties">{{row.counties[0]}}<span class="hover-more" *ngIf="row.counties.length > 1">...</span></td>
            <td>{{row.passCount}}</td>
            <td>{{row.passWithCorrectionsCount}}</td>
            <td>{{row.failCount}}</td>
            <td>{{row.notYetAuditedCount}}</td>
            <td>{{row.percentAudited | number : '1.0-2'}}%</td>
        </tr>
    </table-dynamic>
</ng-container>
<ng-container *ngIf="!pagedData || pagedData.items.length === 0">
    <div class="container-fluid">
        <div class="row">
            <div class="col">
                <div class="alert alert-danger w-100 text-center" role="alert">
                    <strong>No data found</strong>
                </div>
            </div>
        </div>

    </div>
</ng-container>
