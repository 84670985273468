<div class="row">
  <h2 class="offset-md-1 col-md-10">Pledge Demographic Summary Report</h2>
</div>
<div class="row">
  <div class="col-xs-12">
    <form pledgeReportForm #form [showDisplayPledgeStatus]="true" [showCompany]="true" novalidate>
      <button type="submit" class="btn btn-primary" (click)="form.submitPledgeDemographicSummaryReport()">Submit
      </button>
      <button class="btn btn-primary" (click)="form.downloadPledgeDemographicSummaryReportClick()">Export to Excel</button>
    </form>
    <div class="row">
      <div class="col-xs-12">
        <h2 *ngIf="companySummaryRows">Company Summary Report</h2>
      </div>
      <div class="col-xs-12">
        <reportTable [headers]="companySummaryHeaders" [rows]="companySummaryRows"></reportTable>
      </div>
    </div>
    <div class="row">
      <div class="col-xs-12">
        <h2 *ngIf="clientDemographicsRows || employmentStatusRows || ageGroupHeadersRows">Client Demographic
          Reports</h2>
      </div>
      <div class="col-md-6">
        <reportTable [headers]="clientDemographicsHeaders" [rows]="clientDemographicsRows"></reportTable>

      </div>
      <div class="col-md-6">
        <reportTable [headers]="employmentStatusHeaders" [rows]="employmentStatusRows"></reportTable>
      </div>
      <div class="col-md-6">
        <reportTable [headers]="ageGroupHeadersHeaders" [rows]="ageGroupHeadersRows"></reportTable>
      </div>
    </div>
    <div class="row">
      <div class="col-xs-12">
        <h2 *ngIf="householdDemographicsRows || monthlyHouseholdRows || householdeSizeRows">Household Demographic
          Reports</h2>
      </div>
      <div class="col-md-6">
        <reportTable [headers]="householdDemographicsHeaders" [rows]="householdDemographicsRows"></reportTable>
        <small *ngIf="householdDemographicsRows" class="note">Note: These household demographics include the
          corresponding client demographic numbers from above.
        </small>
      </div>
      <div class="col-md-6">
        <reportTable [headers]="monthlyHouseholdHeaders" [rows]="monthlyHouseholdRows"></reportTable>
      </div>
      <div class="col-md-6">
        <reportTable [headers]="householdeSizeHeaders" [rows]="householdeSizeRows"></reportTable>
      </div>
    </div>
  </div>
</div>
