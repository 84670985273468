import {AfterViewInit, Component, ViewChild} from '@angular/core';
import {Observable} from 'rxjs';
import {PledgeStatusType} from '../../../../services/api.client';
import {PaymentProcessingFormComponent} from '../PaymentProcessingFormComponent';
import {ShiftPledgeReportSummary} from '../../../../services/api.client';
import {select, Store} from '@ngrx/store';
import * as fromRoot from '../../../../store';
import {
    selectReportDataPledgeDemographicSummaryReport,
    selectReportDataShiftPledgeReportSummary
} from '../../../../store/selectors/report.selectors';

@Component({
    selector: 'pledgesSummary',
    templateUrl: './PendingPledgesSummaryComponent.html',
    styleUrls: ['./PendingPledgesSummaryComponent.scss']
})

export class PendingPledgesSummaryComponent implements AfterViewInit {
    reports$: Observable<ShiftPledgeReportSummary[]>;
    reports: ShiftPledgeReportSummary[];

    @ViewChild(PaymentProcessingFormComponent)
    form: PaymentProcessingFormComponent;

    constructor(private _store: Store<fromRoot.AppState>) {
        this.reports$ = this._store.pipe(select(selectReportDataShiftPledgeReportSummary));
    }

    ngAfterViewInit(): void {
        setTimeout(() => this.form.form.get('displayPledgeStatus')?.reset(PledgeStatusType.Pending, {disabled: false}), 0);
    }
}
