<div class="row">
  <h2 class="offset-md-1 col-md-10">Confirmation Orphans Report</h2>
</div>
<div class="row">
  <div class="col-xs-12">
    <form keepingCurrentReportForm #form
          [showDateRange]="true"
          [showAgencies]="true" novalidate>
      <button type="submit" class="btn btn-primary" (click)="form.submitConfirmationOrphansReport()">Submit</button>
      <a class="btn btn-primary" [href]="form.downloadConfirmationOrphansReport()">Export to Excel</a>
    </form>
    <h1 *ngIf="rows | async">Confirmation Orphans Report</h1>
    <reportTable [rows]="rows | async" [headers]="tableHeaders"></reportTable>
  </div>
</div>
