import {IEfnData} from '../IEfnData';
import {createReducer, on} from '@ngrx/store';
import {
  setClientsEfnCases,
  setEfnCasesPaged,
  setFundedEfnCases,
  setOpenEfnCases,
  setSelectedEfnCase
} from '../actions/efn.actions';

export const initialState: IEfnData = {
  selectedEfnCase: null,
  clientsEfnCases: null,
  efnCasesPaged: null,
  openEfnCases: null,
  fundedEfnCases: null
};

export const efnReducer = createReducer(
    initialState,
    on(setSelectedEfnCase, (state, { payload }) => ({
      ...state,
      selectedEfnCase: payload
    })),
    on(setClientsEfnCases, (state, { payload }) => ({
      ...state,
      clientsEfnCases: payload
    })),
    on(setEfnCasesPaged, (state, { payload }) => ({
      ...state,
      efnCasesPaged: payload
    })),
    on(setFundedEfnCases, (state, { payload }) => ({
      ...state,
      fundedEfnCases: payload
    })),
    on(setOpenEfnCases, (state, { payload }) => ({
      ...state,
      openEfnCases: payload
    }))
);
