import { Component } from '@angular/core';

@Component({
  selector: 'app-pledge-bulk-payment',
  templateUrl: './pledge-bulk-payment.component.html',
  styleUrls: ['./pledge-bulk-payment.component.scss']
})
export class PledgeBulkPaymentComponent {

}
