<h1 class="m-t-1 m-b-1">Client Information</h1>
<form clientForm #form class="col-xs-12 col-md-11 step1">
  <div footer class="row">
    <div class="col-xs-12 offset-md-5 col-md-7 p-l-0" [class.has-danger]="confirm.touched && !confirm.valid">
      <div [visible]="confirm.touched && confirm.hasError('mustBeChecked')" [keepFlow]="true"
           class="form-control-feedback">Please confirm everything on this page is correct.
      </div>
    </div>
    <div class="col-xs-12 offset-md-5 col-md-7 p-l-0">
      <label class="custom-control custom-checkbox">
        <input type="checkbox" [formControl]="confirm" class="custom-control-input form-control">
        <span class="custom-control-indicator"></span>
        <span class="custom-control-description">I confirm all information on this page is correct</span>
      </label>
    </div>
    <div class="col-xs-12 offset-md-5 col-md-7 p-l-0">
      <button type="submit" (click)="submit()" class="btn btn-primary m-t-1">Next</button>
    </div>
  </div>
</form>
