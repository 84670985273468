import {createFeatureSelector, createSelector} from '@ngrx/store';
import {IKcApplicationData} from '../IKcApplicationData';

const kcDataFeatureSelector = createFeatureSelector<IKcApplicationData>('kcApp');

export const selectKcAppDataProgramDetail = createSelector(
    kcDataFeatureSelector,
    (state) => state.programDetail
);

export const selectKcAppDataKcApps = createSelector(
    kcDataFeatureSelector,
    (state) => state.kcApplications
);

export const selectKcAppDataKcAppsTotalCount = createSelector(
    kcDataFeatureSelector,
    (state) => state.kcApplications?.totalCount
);

export const selectKcAppDataCurrentSelectedKcApplication = createSelector(
    kcDataFeatureSelector,
    (state) => state.currentSelectedKcApplication
);

export const selectKcAppDataKcAmerenCompany = createSelector(
    kcDataFeatureSelector,
    (state) => state.kcAmerenCompany
);

export const selectKcAppDataKcAppResult = createSelector(
    kcDataFeatureSelector,
    (state) => state.currentKcApplicationResult
);
