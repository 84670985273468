import {HttpClient} from '@angular/common/http';
import {share} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {getMessageFromException} from '../utilities/Util';
import {MassAssignPledgeTypeParams, PagedListOfPledgeType, PledgeType, PledgeTypeClient} from './api.client';
import {NotificationService} from './NotificationService';
import {Store} from '@ngrx/store';
import * as fromRoot from '../store';
import {Observable} from 'rxjs';
import {
    pledgeTypesDataIsLoading,
    pledgeTypesDataLoaded, pledgeTypesDataSoftDelete,
    setAllPledgeTypes,
    setCurrentPledgeType
} from '../store/actions/pledgeType.actions';
import {setApplicationCountyFundAdministrativeFundTypes} from '../store/actions/applicationCounty.actions';

// api
// models
// store

@Injectable()
export class PledgeTypeService {

    constructor(private _http: HttpClient,
                private _pledgeTypeApiService: PledgeTypeClient,
                private _notificationService: NotificationService,
                private _store: Store<fromRoot.AppState>) {
    }

    // get Pledge Types
    getPledgeTypes(searchValue: string,
                   sortName: string,
                   descending: boolean,
                   limit: number,
                   offset: number): Observable<PagedListOfPledgeType> {
        this.startLoadingAction(); // start Loading Action
        const request = this._pledgeTypeApiService.searchPledgeTypes(
            searchValue,
            limit || 10,
            offset || 0,
            sortName || 'Name',
            null,
            descending
        ).pipe(share());
        request.subscribe({
            next: (data) => {
                this.setPledgeTypesAction(data);
            }, error: (err) => {
                this._notificationService.showError(getMessageFromException(err));
            }, complete: () => {

            }
        });
        return request;
    }

    addPledgeType(pledgeType: PledgeType): Observable<PledgeType> {
        const request = this._pledgeTypeApiService.addPledgeType(pledgeType).pipe(share());
        this._notificationService.showSuccess('Attempting to save new Fund Type.');
        request.subscribe({
            next: (result) => {
                this.setCurrentSelectedPledgeType(result);
                this._notificationService.showSuccess('Fund Type Successfully Created!');
            }, error: (err) => {
                this._notificationService.showError(getMessageFromException(err));
            }, complete: () => {

            }
        });

        return request;
    }

    updatePledgeType(pledgeType: PledgeType): Observable<PledgeType> {
        const request = this._pledgeTypeApiService.updatePledgeType(pledgeType).pipe(share());
        this._notificationService.showSuccess('Attempting to update Fund Type.');
        request.subscribe({
            next: (result) => {
                this.setCurrentSelectedPledgeType(result);
                this._notificationService.showSuccess('Fund Type Succesfully Updated!');
            }, error: () => {
                this._notificationService.showError('There was a problem updating this Fund Type.');
            }, complete: () => {

            }
        });
        return request;
    }

    deletePledgeType(id: string): Observable<boolean> {
        const request = this._pledgeTypeApiService.deletePledgeType(id).pipe(share());
        this._notificationService.showSuccess('Attempting to delete Fund Type.');
        request.subscribe({
            next: () => {
                this.softDeletePledgeTypeAction();
                this._notificationService.showSuccess('Fund Type Successfully Deleted!');
            }, error: (err) => {
                this._notificationService.showError(getMessageFromException(err));
            }, complete: () => {

            }
        });

        return request;
    }

    getAllPledgeTypes(): Observable<PledgeType[]> {
        const request = this._pledgeTypeApiService.retrievePledgeTypes().pipe(share());
        request.subscribe({
            next: (result) => {
                this.setAllPledgeTypes(result);
            }, error: () => {
                this._notificationService.showError('There was an problem getting the Fund Types');
            }, complete: () => {

            }
        });
        return request;
    }

    retrieveFundAdministrativePledgeTypes(): Observable<PledgeType[]> {
        const request = this._pledgeTypeApiService.retrieveFundAdministrativeAgencyPledgeTypes().pipe(share());
        request.subscribe({
            next: (result) => {
                this.setFundAdministrativePledgeTypesAction(result);
            }, error: () => {
                this._notificationService.showError('There was an problem getting the Fund Administrative Fund Types');
            }
        });
        return request;
    }

    massAssignPledgeType(newPledgeTypeId: string, sourcePledgeTypeId: string): Observable<boolean> {
        const requestBody = MassAssignPledgeTypeParams.fromJS({
            newPledgeType: newPledgeTypeId,
            sourcePledgeType: sourcePledgeTypeId
        });

        const request = this._pledgeTypeApiService.massAssignPledgeType(requestBody).pipe(share());
        this._notificationService.showSuccess('Attempting to mass assign Fund Type.');
        request.subscribe({
            next: () => {
                this._notificationService.showSuccess('Fund Type mass assigned!');
            }, error: () => {
                this._notificationService.showError('There was a problem mass assigning the Fund Type.');
            }, complete: () => {

            }
        });
        return request;
    }


    setCurrentSelectedPledgeType(currentSelectedPledgeType: PledgeType): void {
        this.setCurrentSelectedPledgeTypeAction(currentSelectedPledgeType);
    }

    // getCurrentSelectedPledgeType(): PledgeType {// TODO: not used yet; on browser refresh
    //   return this.getCurrentSelectedPledgeTypeAction();
    // }

    getPledgeTypeById(id: string): void {
        this._pledgeTypeApiService.retrievePledgeType(id).subscribe((result) => {
            this.setCurrentSelectedPledgeTypeAction(result);
        });
    }

    setAllPledgeTypes(pledgeTypes: PledgeType[]): void {
        this.setAllPledgeTypesAction(pledgeTypes);
    }

    // start, set clear
    private startLoadingAction(): void {
        this._store.dispatch(pledgeTypesDataIsLoading());
    }

    setPledgeTypesAction(pledgeTypes: PagedListOfPledgeType): void {
        this._store.dispatch(pledgeTypesDataLoaded({payload: pledgeTypes}));
    }

    private setCurrentSelectedPledgeTypeAction(currentPledgeType: PledgeType): void {
        this._store.dispatch(setCurrentPledgeType({payload: currentPledgeType}));
    }

    setAllPledgeTypesAction(pledgeTypes: PledgeType[]): void {
        this._store.dispatch(setAllPledgeTypes({payload: pledgeTypes}));
    }

    private softDeletePledgeTypeAction(): void {
        this._store.dispatch(pledgeTypesDataSoftDelete());
    }

    private setFundAdministrativePledgeTypesAction(pledgeTypes: PledgeType[]): void {
        this._store.dispatch(setApplicationCountyFundAdministrativeFundTypes({ payload: pledgeTypes }));
    }
}
