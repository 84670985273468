import {Component} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';

@Component({
  templateUrl: './ClientCreateComponent.html',
  styleUrls: ['./ClientCreateComponent.scss']
})

export class ClientCreateComponent {


  constructor(private _route: ActivatedRoute,
              private _router: Router) {
  }
}
