<h1 class="page-title">Bulk Payment Processing</h1>
<div class="row">
    <div class="col-xs-12">
        <div class="clearfix">
            <ul class="nav nav-tabs pull-xs-left" role="tablist">
                <li class="nav-item"><a class="nav-link" [routerLink]="['./create-batch']" routerLinkActive="active">Create Batch</a></li>
                <li class="nav-item"><a class="nav-link" [routerLink]="['./select-batch']" routerLinkActive="active">Select Batch</a></li>
            </ul>
        </div>
        <div class="tab-content mb-lg overflow-visible">
            <div role="tabpanel" class="tab-pane active in clearfix">
                <router-outlet></router-outlet>
            </div>
        </div>
    </div>
</div>
