import {createAction, props} from '@ngrx/store';
import {PagedListOfPledgeType, Pledge, PledgeType} from '../../services/api.client';

export const pledgeTypesDataIsLoading  = createAction(
    '[Pledge Type] PLEDGE_TYPES_DATA_ISLOADING');

export const pledgeTypesDataLoaded  = createAction(
    '[Pledge Type] PLEDGE_TYPES_DATA_LOADED',
    props<{ payload: PagedListOfPledgeType }>());

export const setCurrentPledgeType  = createAction(
    '[Pledge Type] SET_CURRENT_PLEDGE_TYPE',
    props<{ payload: PledgeType }>());

export const pledgeTypesDataClear  = createAction(
    '[Pledge Type] PLEDGE_TYPES_DATA_CLEAR',
    props<{ payload: Pledge }>());

export const setAllPledgeTypes  = createAction(
    '[Pledge Type] SET_ALL_PLEDGETYPES',
    props<{ payload: PledgeType[] }>());

export const pledgeTypesDataSoftDelete  = createAction(
    '[Pledge Type] PLEDGE_TYPES_DATA_SOFT_DELETE');

export const setKcPledge  = createAction(
    '[[Pledge Type] SET_KC_PLEDGE_TYPE',
    props<{ payload: Pledge }>());
