<h1 class="page-title">Browse UWDA Pledges</h1>
<section class="widget">
  <div class="widget-body">
    <div class="mt">
      <form novalidate>
        <div class="row">
          <div class="col-xs-4 uwda-select">
            <label class="header-label">Fund Type:</label>
            <select class="drop-down-inline form-control" [formControl]="selectedPledgeTypeId">
              <option value="">-- Fund Type --</option>
              <option *ngFor="let item of uwdaPledgeTypes" [value]="item.id">{{item.name}}</option>
            </select>
          </div>
          <div class="col-xs-4 uwda-button">
            <button class="btn btn-primary pull-left" role="button" (click)="getConfirmedUwdaPledges()">
              Search
            </button>
          </div>
          <div class="col-xs-4 uwda-button">
            <button class="btn btn-success pull-right" (click)="approvePledges()">
              Approve Selected
            </button>
          </div>
        </div>
      </form>
      <table-dynamic id="datatable-table"
                     class="table table-striped table-hover"
                     [headers]="tableHeaders"
                     [currentPage]="currentPage"
                     [totalCount]="totalCount"
                     (headerClick)="sortPledges($event)"
                     (pageChanged)="setPage($event)"
                     (limitChange)="setLimit($event)">
        <tr *ngFor="let pledge of pagedPledges?.items">
          <td><a [routerLink]="['../client', pledge.clientId]"
                 (click)="setCurrentSelectedClient(pledge.clientId)"><strong>{{ pledge.clientLastName
            }}</strong></a></td>
          <td><a [routerLink]="['../client', pledge.clientId]"
                 (click)="setCurrentSelectedClient(pledge.clientId)"><strong>{{ pledge.clientFirstName
            }}</strong></a></td>
          <td class="hidden-sm-down" *ngIf="pledge.clientMissingSsn">***-**-{{pledge.clientLast4Ssn}}</td>
          <td class="hidden-sm-down" *ngIf="!pledge.clientMissingSsn">Missing SSN</td>
          <td class="hidden-sm-down">{{ pledge.pledgedDate | moment: 'L' }}</td>
          <td class="hidden-sm-down">{{ _pledgeService.formatPledgeStatus(pledge.status) }}</td>
          <td><a [routerLink]="['../pledge', pledge.id]" (click)="setCurrentSelectedPledge(pledge)"><strong>{{
            pledge.pledgedAmount | parseNumber | currency:'USD':'symbol':'1.2-2' }}</strong></a></td>
          <td class="hidden-sm-down">{{ formatFundType(pledge.pledgeTypeId) }}</td>
          <td class="hidden-sm-down center">
            <input checkboxArray
                   [ngValue]="pledge.id"
                   [formControl]="autoOkPledgeIds"
                   type="checkbox"
                   class="form-check-input"/>
          </td>
          <td class="hidden-sm-down center">
            <input checkboxArray
                   [ngValue]="pledge.id"
                   [formControl]="manualOkPledgeIds"
                   type="checkbox"
                   class="form-check-input"/>
          </td>
        </tr>
      </table-dynamic>
    </div>
  </div>
</section>
<!--<pre>{{ autoOkPledgeIds.value | json }}</pre>-->
<!--<pre>{{ manualOkPledgeIds.value | json }}</pre>-->
