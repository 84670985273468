<div class="container-fluid align-items-center" [formGroup]="form">
    <div class="row">
        <!-- Agency Filter -->
        <div class="col-md-3 col-lg-3 mb-4">
            <label for="agency" class="form-label"><i class="fa fa-building">&nbsp;</i>Agency</label>
            <div class="input-chip-container"
            >
                <input class="form-control"
                       id="agency"
                       placeholder="Select an Agency"
                       (click)="showAgencyModal()"
                       (keydown)="showAgencyModal()"
                       (keyup.enter)="onAgencySelected($event.target.value)"
                       [disabled]="!userHasAccessToAllAgencies"
                       [hidden]="!userHasAccessToAllAgencies"
                />
                <div class="chips">
                    <div *ngFor="let agencyName of selectedAgencyNames; index as i" class="chip">
                        {{ agencyName }}
                        <button [hidden]="!userHasAccessToAllAgencies" type="button" class="close-button" (click)="removeAgency(i)"><i
                                class="fa fa-times me-2"></i></button>
                    </div>
                </div>
            </div>
        </div>

        <div *ngIf="selectedAgencyNames.length > 0" class="col-md-3 col-lg-3 mb-4">
            <label for="case-workers" class="form-label"><i class="fa fa-user">&nbsp;</i>Case Worker</label>
            <div class="input-chip-container">
                <select id="case-workers" class="form-control custom-select"
                        (change)="onCaseWorkerChange($event.target.value)">
                    <option *ngIf="selectedCaseWorkerNames.length === 0" value="">All Case Workers</option>
                    <option *ngIf="selectedCaseWorkerNames.length > 0" value="">Select Case Worker</option>
                    <option *ngFor="let user of agencyUsers" value="{{user.id}}">
                        {{ user.firstName + ' ' + user.lastName }}
                    </option>
                </select>
                <div class="chips">
                    <div *ngFor="let name of selectedCaseWorkerNames; index as i" class="chip">
                        {{ name }}
                        <button type="button" class="close-button" (click)="removeCaseWorker(i)"><i
                                class="fa fa-times me-2"></i></button>
                    </div>
                </div>
            </div>
        </div>


        <!-- Fund Type Filter -->
        <div class="col-md-3 col-lg-3 mb-4">
            <label for="regions-select" class="form-label"><i class="fa fa-money">&nbsp;</i>Fund Type</label>
            <select id="regions-select" formControlName="fundPledgeTypeId" class="form-control custom-select">
                <option value="">Select Fund Type</option>
                <option value="">All Fund Types</option>
                <option *ngFor="let fundPledgeType of fundPledgeTypes"
                        [value]="fundPledgeType.id">{{ fundPledgeType.name }}
                </option>
            </select>
        </div>

        <!-- Submission Type Filter -->
        <div class="col-md-3 col-lg-3 mb-4">
            <label for="submissionType-select" class="form-label"><i class="fa fa-upload">&nbsp;</i>Submission
                Type</label>
            <select id="submissionType-select" formControlName="isPublic" class="form-control custom-select">
                <option [ngValue]="null">Select Submission Type</option>
                <option [ngValue]="null">Both</option>
                <option [value]="true">Public</option>
                <option [value]="false">Agency</option>
            </select>
        </div>

        <!-- Audit Status Filter -->
        <div class="col-md-3 col-lg-3 mb-4">
            <label for="auditStatus-select" class="form-label">
                <i class="fa fa-check-circle">&nbsp;</i>
                Audit State
            </label>
            <select id="auditStatus-select" formControlName="auditStatus" class="form-control custom-select">
                <option value="">Select Audit State</option>
                <option *ngFor="let option of ASSISTANCE_APPLICATION_STATE_AUDIT_TYPE_OPTIONS"
                        [value]="option.value">{{ option.label }}
                </option>
            </select>
        </div>
    </div>

    <div class="row">
        <!-- Date Filters -->
        <div class="col-md-6 col-lg-6 mb-4">
            <div class="row gx-3">
                <div class="col-md-6 mb-2">
                    <label class="form-label"><i class="fa fa-calendar-check-o">&nbsp;</i>Start Entered Date</label>
                    <input [imask]="Masking.dateMask" class="form-control" formControlName="enteredDateStart"
                           placeholder="MM/DD/YYYY"/>
                </div>
                <div class="col-md-6">
                    <label class="form-label"><i class="fa fa-calendar-times-o">&nbsp;</i>End Entered Date</label>
                    <input [imask]="Masking.dateMask" class="form-control" formControlName="enteredDateEnd"
                           placeholder="MM/DD/YYYY"/>
                </div>
            </div>
        </div>

        <!-- Requested Amount Filters -->
        <div class="col-md-6 col-lg-6 mb-4">
            <div class="row gx-3">
                <div class="col-md-6 mb-2">
                    <label class="form-label"><i class="fa fa-dollar">&nbsp;</i>Min Requested</label>
                    <input [imask]="Masking.wholeCurrencyMask" [unmask]="true" class="form-control"
                           formControlName="totalRequestedMin"
                           placeholder="Min Total Requested"/>
                </div>
                <div class="col-md-6">
                    <label class="form-label"><i class="fa fa-dollar">&nbsp;</i>Max Requested</label>
                    <input [imask]="Masking.wholeCurrencyMask" [unmask]="true" class="form-control"
                           formControlName="totalRequestedMax"
                           placeholder="Max Total Requested"/>
                </div>
            </div>
        </div>
    </div>
</div>
<agency-box (onAgencySelected)="onAgencySelected($event)"></agency-box>
