import {Component, Input, OnInit} from '@angular/core';
import {LookUp} from '../../../services/api.client';
import {Observable} from 'rxjs';
import {UntypedFormControl} from '@angular/forms';
import {select, Store} from '@ngrx/store';
import * as fromRoot from '../../../store';
import {selectSecurityStateDataSystemDataZones} from '../../../store/selectors/security.selectors';

@Component({
  selector: 'efnZoneCheckbox',
  templateUrl: './EfnZoneCheckbox.html'
})
export class EfnZoneCheckbox implements OnInit {
  efnZones: LookUp[];
  efnZones$: Observable<LookUp[]>;

  @Input()
  control: UntypedFormControl;

  constructor(private _store: Store<fromRoot.AppState>) {
    this.efnZones$ = this._store.pipe(select(selectSecurityStateDataSystemDataZones));
  }

  ngOnInit(): void {
    this.efnZones$.subscribe(efnZones => this.efnZones = efnZones);
  }
}
