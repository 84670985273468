<div class="row">
  <div class="col-xs">
    <div class="logo-container dollar-more">
      <img alt="Dollar More" class="logo-img" src="/assets/images/$MoreUE.png">
    </div>
  </div>
  <div class="col-xs">
    <div class="logo-container">
      <img alt="United Way" class="logo-img" src="/assets/images/logo-mobile.svg">
    </div>
  </div>
  <div class="col-xs">
    <div class="logo-container dollar-help">
      <img alt="Dollar Help" class="logo-img" src="/assets/images/DollarHelp-logo.png">
    </div>
  </div>
</div>

<section class="widget" *ngIf="currentUser.userType !== userTypes.FundAdmin">
  <div class="widget-body">
    <div class="mt">
      <div class="row">
        <div class="col-xs-12">
          <h5>Fund Type Balances</h5>
          <table-dynamic #fundBalanceTable id="pledgeTypeBalance-table" class="table table-striped"
                         [headers]="[{text: 'Fund Type'}, {text: 'Balance'}]"
                         [showPagination]="false">
            <tr *ngFor="let p of pledgeTypeBalances">
              <td>{{ p.pledgeType.name }}</td>
              <td>{{ p.balance | parseNumber | currency:'USD':'symbol':'1.2-2'  }}</td>
            </tr>
          </table-dynamic>
        </div>
      </div>

      <div class="row" *ngIf="currentUser.hasEfn">
        <div class="col-xs-6" *ngIf="openCases">
          <h5>Open EFN Cases</h5>
          <table-dynamic #openEfnCasesTable id="openEfnCases-table" class="table table-striped"
                         [headers]="[{text: 'Client'}, {text: 'Summary'}, {text: 'Total Need'}, {text: 'Current Committed'}, {text: 'Deadline'}]"
                         [showPagination]="false">
            <tr *ngFor="let c of openCases">
              <td><a [routerLink]="['../client', c.clientId]" (click)="fetchClientById(c.clientId)">
                <strong>{{c.clientLastName}}, {{c.clientFirstName}}</strong></a></td>
              <td><a [routerLink]="['../client', c.clientId, 'efn_cases', c.id]" (click)="fetchEfnCase(c.id)">
                <strong>{{c.summary}}</strong></a></td>
              <td>{{c.totalNeed | parseNumber | currency:'USD':'symbol':'1.2-2' }}</td>
              <td>{{c.currentTotal | parseNumber | currency:'USD':'symbol':'1.2-2' }}</td>
              <td>{{c.deadline | moment: 'L'}}</td>
            </tr>
          </table-dynamic>
        </div>

        <div class="col-xs-6" *ngIf="fundedCases">
          <h5>Recently Funded EFN Cases</h5>
          <table-dynamic #fundedEfnCasesTable id="fundedEfnCases-table" class="table table-striped"
                         [headers]="[{text: 'Client'}, {text: 'Summary'}, {text: 'Total Need'}, {text: 'Current Committed'}, {text: 'Deadline'}]"
                         [showPagination]="false">
            <tr *ngFor="let c of fundedCases">
              <td><a [routerLink]="['../client', c.clientId]" (click)="fetchClientById(c.clientId)">
                <strong>{{c.clientLastName}}, {{c.clientFirstName}}</strong></a></td>
              <td><a [routerLink]="['../client', c.clientId, 'efn_cases', c.id]" (click)="fetchEfnCase(c.id)">
                <strong>{{c.summary}}</strong></a></td>
              <td>{{c.totalNeed | parseNumber | currency:'USD':'symbol':'1.2-2' }}</td>
              <td>{{c.currentTotal | parseNumber | currency:'USD':'symbol':'1.2-2' }}</td>
              <td>{{c.deadline | moment: 'L'}}</td>
            </tr>
          </table-dynamic>
        </div>
      </div>
    </div>
  </div>
</section>
