import {map} from 'rxjs/operators';
import {Component, OnInit} from '@angular/core';
import {Client} from '../../../../services/api.client';
import {KcApplicationService} from '../../../../services/KcApplicationService';
import {NotificationService} from '../../../../services/NotificationService';
import {Router, ActivatedRoute, Params} from '@angular/router';
import {SystemService} from '../../../../services/SystemService';
import {KcApplication} from '../../../../services/api.client';
import {ITableHeader} from '../../../shared/table/TableComponent';
import {Observable} from 'rxjs';
import {select, Store} from '@ngrx/store';
import * as fromRoot from '../../../../store';
import {selectClientDataCurrentClient} from '../../../../store/selectors/client.selectors';

@Component({
  selector: 'clientApplications',
  templateUrl: './ClientApplicationsComponent.html'
})

export class ClientApplicationsComponent implements OnInit {
  client: Client;
  client$: Observable<Client>;

  tableHeaders: ITableHeader[] = [
    {text: 'Last Name', sortKey: 'LastName'},
    {text: 'First Name', sortKey: 'FirstName'},
    {text: 'Account #', sortKey: 'AccountNumber', class: 'hidden-sm-down'},
    {text: 'Creation Date', sortKey: 'EnteredDate', class: 'hidden-sm-down'},
    {text: 'Edited Date', sortKey: 'ModifiedDate', class: 'hidden-sm-down', ascending: true},
    {text: 'Status', sortKey: 'StatusName'},
  ];
  applications: KcApplication[];

  constructor(private kcApplicationService: KcApplicationService,
              private notificationService: NotificationService,
              private router: Router,
              private route: ActivatedRoute,
              public system: SystemService,
              private _store: Store<fromRoot.AppState>) {
    this.client$ = this._store.pipe(select(selectClientDataCurrentClient));
  }

  ngOnInit(): void {
    this.client$.subscribe(currentClient => this.client = currentClient);
    this.route.data.pipe(
      map((params: Params) => params.applications as KcApplication[]))
      .subscribe((applications) => this.applications = applications);
  }

  getKcApplicationUrl(application: KcApplication): string[] {
    if (application.keepingCurrentStatus.isNewStatus) {
      return ['../application/client_enrollment'];
    } else {
      return ['../application', application.id];
    }
  }

  ngAfterViewInit(): void {

  }

}
