import {map, filter} from 'rxjs/operators';
import {Component, OnInit} from '@angular/core';
import {ITableHeader} from '../../../../shared/table/TableComponent';
import {Observable} from 'rxjs';
import {PledgeCountyReport} from '../../../../../services/api.client';
import {select, Store} from '@ngrx/store';
import * as fromRoot from '../../../../../store';
import {selectReportDataPledgeDemographicCountryReport} from '../../../../../store/selectors/report.selectors';

@Component({
    templateUrl: './PledgeByCountyComponent.html',
})
export class PledgeByCountyComponent implements OnInit {

    tableHeaders: ITableHeader[] = [
        {text: 'State'},
        {text: 'County'},
        {text: 'Pledge Count'},
        {text: 'Pledge Amount'},
    ];

    report$: Observable<PledgeCountyReport[]> = null;

    constructor(private _store: Store<fromRoot.AppState>) {
        this.report$ = this._store.pipe(select(selectReportDataPledgeDemographicCountryReport));
    }

    rows: Observable<(string | number)[][]>;

    ngOnInit(): void {
        this.rows = this.report$.pipe(
            filter(report => !!report),
            map((report) => report.map((county) => [
                county.state,
                county.county,
                county.pledgeCount,
                '$' + county.pledgeTotal.toFixed(2)])),);
    }
}
