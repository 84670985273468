<div class="container">
  <main id="content" role="main">
    <section class="widget animated fadeInUp">
      <div class="row">
        <div class="col-xs-12">
          <h3>Forgot Password</h3>
        </div>
      </div>
      <form *ngIf="!successMessage" [formGroup]="form" novalidate>
        <div class="row">
          <div class="col-xs-12 text-xs-center p-y-1">
            <span>Allow ten minutes to receive Password Reset e-mail after clicking ‘Reset Link’ button</span>
          </div>
        </div>
        <div class="row">
          <div class="col-xs-12">
            <div class="form-group" [class.has-danger]="!email.valid && email.touched">
              <div class="form-control-feedback" [hidden]="!errMessage"><i>{{ errMessage }}</i></div>
              <div *ngIf="!email.valid && email.touched" class="form-control-feedback">Invalid Email</div>
              <input [formControl]="email"
                     [class.form-control-danger]="!email.valid && email.touched"
                     type="text" name="email" required class="form-control" id="email" placeholder="Your Email">
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-xs-12">
            <div class="btn-toolbar text-xs-right">
              <button class="btn btn-primary btn-inverse btn-sm" type="button" [routerLink]="['/login']">Back</button>
              <button class="btn btn-secondary btn-sm" type="submit"
                      (click)="sendLink(); false;"
                      [disabled]="!email">Send Password Reset Link
              </button>
            </div>
          </div>
        </div>
      </form>
      <div *ngIf="successMessage" class="row">
        <div class="col-xs-12">
          <h6>{{ successMessage }}</h6>
          <button class="btn btn-inverse btn-sm pull-right" type="button" routerLink="/login">Back</button>
        </div>
      </div>

    </section>
  </main>
</div>
