import {AssistanceApplicationFileType} from '../enums/assistance-application-file-type';

export const AssistanceApplicationFileTypeOptions = [
    {
        label: 'Photo ID',
        value: AssistanceApplicationFileType.PhotoId
    },
    {
        label: 'SSN Proof',
        value: AssistanceApplicationFileType.SsnProof
    },
    {
        label: 'Utility Bill',
        value: AssistanceApplicationFileType.UtilityBill
    },
    {
        label: 'Utility Shut-off Notice',
        value: AssistanceApplicationFileType.UtilityShutoffNotice
    },
    {
        label: 'Personal Property Tax Statement',
        value: AssistanceApplicationFileType.TaxStatement
    },
    {
        label: 'Bank Statement',
        value: AssistanceApplicationFileType.BankStatement
    },
    {
        label: 'Government or Court Record',
        value: AssistanceApplicationFileType.GovtOrCourtRecord
    },
    {
        label: 'Government Benefits Statement',
        value: AssistanceApplicationFileType.GovtBenefitsStatement
    },
    {
        label: 'SSI/SSDI Award Letter',
        value: AssistanceApplicationFileType.SsiSsdiAwardLetter
    },
    {
        label: 'Unemployment Award',
        value: AssistanceApplicationFileType.UnemploymentAward
    },
    {
        label: 'WIC or SNAP Award Letters/Documentation',
        value: AssistanceApplicationFileType.WicSnapAwardLetter
    },
    {
        label: 'Check Stub(s)',
        value: AssistanceApplicationFileType.CheckStub
    },
    {
        label: 'Pension Letter',
        value: AssistanceApplicationFileType.PensionLetter
    },
    {
        label: 'Tax Returns',
        value: AssistanceApplicationFileType.TaxReturns
    },
    {
        label: 'W-9',
        value: AssistanceApplicationFileType.W9
    },
    {
        label: 'Profit/Loss Statement',
        value: AssistanceApplicationFileType.ProfitLossStatement
    },
    {
        label: 'Childcare Bill',
        value: AssistanceApplicationFileType.ChildcareBill
    },
    {
        label: 'Auto Repair Bill',
        value: AssistanceApplicationFileType.AutoRepairBill
    },
    {
        label: 'Lease Agreement',
        value: AssistanceApplicationFileType.LeaseAgreement
    },
    {
        label: 'Delinquent Rent Notice',
        value: AssistanceApplicationFileType.DelinquentRentNotice
    },
    {
        label: 'Eviction Court Notice or Record',
        value: AssistanceApplicationFileType.EvictionCourtOrder
    },
    {
        label: 'Other',
        value: AssistanceApplicationFileType.Other
    },
    {
        label: 'For Caseworker Use Only',
        value: AssistanceApplicationFileType.ForCaseworkerUseOnly
    }
];
