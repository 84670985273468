import {Component, OnInit, ViewChild, EventEmitter, Output} from '@angular/core';
import {Observable, Observer} from 'rxjs';
import {Agency, PagedListOfAgency} from '../../../services/api.client';

import {AgencyService} from '../../../services/AgencyService';
import {ModalComponent} from 'src/app/utilities/old-modal/components/modal';
import {UntypedFormControl} from '@angular/forms';
import {TableComponent} from '../../shared/table/TableComponent';
import {select, Store} from '@ngrx/store';
import * as fromRoot from '../../../store';
import {
  selectAgencyDataAgencies,
  selectAgencyDataAgenciesItems,
  selectAgencyDataAgenciesTotalCount
} from '../../../store/selectors/agency.selectors';

@Component({
  selector: 'agency-box',
  templateUrl: './AgencyListModalComponent.html',
  styleUrls: ['./AgencyListModalComponent.scss'],
})

export class AgencyListModalComponent implements OnInit {

  constructor(private _agencyService: AgencyService, private _store: Store<fromRoot.AppState>) {
    this.pagedList$ = this._store.pipe(select(selectAgencyDataAgencies));
    this.agencies$ = this._store.pipe(select(selectAgencyDataAgenciesItems));
    this.totalCount$ = this._store.pipe(select(selectAgencyDataAgenciesTotalCount));
  }

  searchBox = new UntypedFormControl('');
  headers = [{text: 'Agency Name', }];

  @ViewChild(ModalComponent)
  modal: ModalComponent;

  selectedRow: Agency;
  searchValue = '';

  @Output()
  onAgencySelected = new EventEmitter<Agency>();

  private observer: Observer<any>;

  pagedList: PagedListOfAgency;
  pagedList$: Observable<PagedListOfAgency>;
  agencies: Agency[];
  agencies$: Observable<Agency[]>;

  // PAGINATION
  limit = 10;
  currentPage = 1;
  maxSize = 1;
  totalCount: number;
  totalCount$: Observable<number>;

  @ViewChild(TableComponent)
  table: TableComponent;

  ngOnInit(): void {
    this.pagedList$.subscribe(pagedList => this.pagedList = pagedList);
    this.agencies$.subscribe(agencies => this.agencies = agencies);
    this.totalCount$.subscribe(totalCount => this.totalCount = totalCount);
  }

  setLimit(limit: number): void {// # of rows per page
    this.limit = limit;
    this.filterAgencies();
  }

  setPage(page: number): void {
    this.currentPage = page;
    this.filterAgencies();
  }

  open(): Observable<boolean> {
    this.modal.open();
    this.filterAgencies();
    return new Observable(obs => {
      this.observer = obs;
    });
  }

  filterAgencies(): void {// get agencies
    this.table.request = this._agencyService.getAgencies(
      this.searchBox.value,
      '',
      false,
      this.limit,
      'AgencyName',
      false,
      (this.currentPage - 1) * this.limit
    );
  }

  setClickedRow(agency): void {
    if (this.selectedRow === agency) {
      this.selectedRow = null;
    } else {
      this.selectedRow = agency;
    }
  }

  okClicked(agency): void {
    this._emitCloseEvent(agency);
  }

  cancelClicked(): void {
    this.modal.close();
    // this._emitCloseEvent(null);
  }

  resetClicked(): void {
    this.selectedRow = void 0;
    this._emitCloseEvent(this.selectedRow);
  }

  private _emitCloseEvent(event: any): void {
    this.onAgencySelected.emit(event);
    this.modal.close();
    if (this.observer) {
      this.observer.next(event);
      this.observer.complete();
    }
  }

}
