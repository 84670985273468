import {share} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {
    PagedListOfCompanyMailingReport,
    PagedListOfProcessingReport,
    PaymentProcessingClient,
    PledgeClient,
    PledgeReportParams, ShiftPledgeReportSummary, SimpleBooleanResult
} from './api.client';
import {NotificationService} from './NotificationService';
import {saveAs} from 'file-saver';
import {Store} from '@ngrx/store';
import * as fromRoot from '../store';
import {
    buildCompanyMailingLabelsFailed,
    buildCompanyMailingLabelsSuccess,
    buildProcessingDetailReportFailed,
    buildProcessingDetailReportSuccess,
    buildShiftPledgeReportFailed, buildShiftPledgeReportReportReportFailed,
    buildShiftPledgeReportReportReportSuccess,
    buildShiftPledgeReportSuccess
} from '../store/actions/report.actions';

@Injectable()
export class PaymentProcessingService {

    constructor(private _reportApiService: PaymentProcessingClient,
                private _pledgeApiService: PledgeClient,
                private _store: Store<fromRoot.AppState>,
                private _notificationService: NotificationService) {
    }

    buildCompanyMailingLabels({
                                  displayPledgeStatus, shiftPledgeStatus, startDate,
                                  endDate, pledgeTypeId, pledgeTypeIds, companyId,
                                  agencyId, entryDate
                              }: PledgeReportParams): Observable<PagedListOfCompanyMailingReport> {
        const request = this._reportApiService.buildCompanyMailingLabels(
            displayPledgeStatus,
            shiftPledgeStatus,
            startDate,
            endDate,
            pledgeTypeId,
            pledgeTypeIds,
            companyId,
            agencyId,
            entryDate
        ).pipe(share());
        request.subscribe({
            next: (data) => {
                this.buildCompanyMailingLabelsSuccessAction(data);
            }, error: (error) => {
                this.buildCompanyMailingLabelsFailedAction(error);
            }
        });
        return request;
    }

    buildCompanyMailingLabelsUrl(): void {
        // return this._reportApiService.buildUrl('downloadCompanyMailingLabels',
        //   new URLSearchParams(this.getQueryParameters(reportParams)));
    }

    buildProcessingDetailReport(reportParams: PledgeReportParams): Observable<PagedListOfProcessingReport> {
        const request = this._reportApiService.buildProcessingSummaryReport(reportParams).pipe(share());
        request.subscribe({
            next: (data) => {
                this.buildProcessingDetailReportSuccessAction(data);
            }, error: (error) => {
                this.buildProcessingDetailReportFailedAction(error);
            }
        });
        return request;
    }

    buildProcessingDetailReportUrl(reportParams: PledgeReportParams): void {
        this._reportApiService.downloadProcessingSummaryReport(reportParams)
            .subscribe(result => {
                const currentDate = new Date();
                const fileName = `Pledge Report-${currentDate.getFullYear() + '-' + (currentDate.getMonth() + 1) +
                '-' + currentDate.getDate()}.csv`;
                // There is a problem where the filename wasn't being parsed by nswag.
                saveAs(result.data, fileName);
            });
    }

    buildShiftPledgeReport({
                               displayPledgeStatus,
                               shiftPledgeStatus,
                               startDate,
                               endDate,
                               pledgeTypeId,
                               pledgeTypeIds,
                               companyId,
                               agencyId,
                               entryDate
                           }: PledgeReportParams): Observable<ShiftPledgeReportSummary[]> {
        const request = this._reportApiService.buildShiftPledgeReport(
            displayPledgeStatus,
            shiftPledgeStatus,
            startDate,
            endDate,
            pledgeTypeId,
            pledgeTypeIds,
            companyId,
            agencyId,
            entryDate
        ).pipe(share());
        request.subscribe({
            next: (data) => {
                this.buildShiftPledgeReportSuccessAction(data);
            }, error: (error) => {
                this.buildShiftPledgeReportFailedAction(error);
            }
        });
        return request;

    }

    // getManualPendingToPaidPledges(reportParams: string[]): Observable<Pledge[]> {
    //     let request = this._pledgeApiService.getManualPendingPledges(reportParams).share();
    //     request.subscribe((data) => {
    //         let pendingPledges = data;
    //         return pendingPledges;
    //         // this.buildShiftPledgeReportSuccessAction(data);
    //     }, (error) => {
    //         // this.buildShiftPledgeReportFailedAction(error);
    //     });
    //     return request;
    // }

    changeUpdatePledgeStatus(reportParams: PledgeReportParams): Observable<SimpleBooleanResult> {
        if (reportParams.companyId === '') {
            reportParams.companyId = null;
        }
        const request = this._reportApiService.changePledgeStatus(reportParams).pipe(share());
        this._notificationService.showSuccess('Attempting to bulk update Pledge Status.');
        request.subscribe({
            next: () => {
                this._notificationService.showSuccess('Pledge Status bulk update completed!');
                this.changePledgeStatusSuccessAction();
            }, error: (error) => {
                this._notificationService.showSuccess('Pledge Status bulk update completed!');
                this.changePledgeStatusFailedAction(error);
            }
        });
        return request;

    }

    private buildCompanyMailingLabelsSuccessAction(data: PagedListOfCompanyMailingReport): void {
        this._store.dispatch(buildCompanyMailingLabelsSuccess({payload: data}));
    }

    private buildCompanyMailingLabelsFailedAction(error: any): void {
        this._store.dispatch(buildCompanyMailingLabelsFailed({payload: error}));
    }

    private buildProcessingDetailReportSuccessAction(data: PagedListOfProcessingReport): void {
        this._store.dispatch(buildProcessingDetailReportSuccess({payload: data}));
    }

    private buildProcessingDetailReportFailedAction(error: any): void {
        this._store.dispatch(buildProcessingDetailReportFailed({payload: error}));
    }

    private buildShiftPledgeReportSuccessAction(data: ShiftPledgeReportSummary[]): void {
        this._store.dispatch(buildShiftPledgeReportSuccess({payload: data}));
    }

    private buildShiftPledgeReportFailedAction(error: any): void {
        this._store.dispatch(buildShiftPledgeReportFailed({payload: error}));
    }

    private changePledgeStatusSuccessAction(): void {
        this._store.dispatch(buildShiftPledgeReportReportReportSuccess());
    }

    private changePledgeStatusFailedAction(error: any): void {
        this._store.dispatch(buildShiftPledgeReportReportReportFailed({payload: error}));
    }

    // private getQueryParameters(data: any) {
    //   data.securityToken = encodeURIComponent(this._reportApiService.securityToken);
    //   if (data.startDate && data.startDate.format) {
    //     data.startDate = data.startDate.format();
    //   }
    //   if (data.endDate && data.endDate.format) {
    //     data.endDate = data.endDate.format();
    //   }
    //   if (data.entryDate && data.entryDate.format) {
    //     data.entryDate = data.entryDate.format();
    //   }
    //
    //   return Object.keys(data)
    //     .filter((key) => !!data[key])
    //     .map((key) => {
    //       if (data[key] instanceof Array) {
    //         return data[key]
    //           .map((value) => `${key}=${value}`)
    //           .join('&');
    //       }
    //       return `${key}=${data[key]}`;
    //     })
    //     .join('&');
    // }
}
