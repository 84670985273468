import {takeUntil, tap} from 'rxjs/operators';
import {Component, AfterViewInit, ViewChild, forwardRef, OnInit} from '@angular/core';
import {Client, EfnCase, EfnCaseStatusType, User} from '../../../../../../services/api.client';
import {EfnCaseFormComponent} from '../form/EfnCaseFormComponent';
import {ActivatedRoute, Router} from '@angular/router';
import {Observable} from 'rxjs';
import {EfnCaseHelper} from '../EfnHelper';
import {AutoDisableParent} from '../../../../../../directives/AutoDisableDirective';
import * as moment from 'moment';
import {select, Store} from '@ngrx/store';
import * as fromRoot from '../../../../../../store';
import {selectClientDataCurrentClient} from '../../../../../../store/selectors/client.selectors';
import {selectSecurityStateDataCurrentUser} from '../../../../../../store/selectors/security.selectors';
import {Disposable} from '../../../../../../utilities/Disposable';
import {EfnService} from '../../../../../../services/EfnService';
import {PromptComponent} from '../../../../../shared/dialogs/PromptComponent';

@Component({
  templateUrl: './CreateEfnCaseComponent.html',
  styleUrls: ['./CreateEfnCaseComponent.scss'],
  providers: [EfnCaseHelper, {provide: AutoDisableParent, useExisting: forwardRef(() => CreateEfnCaseComponent)}],

})

export class CreateEfnCaseComponent extends Disposable implements OnInit, AfterViewInit {

  client: Client;
  client$: Observable<Client>;

  user: User;
  user$: Observable<User>;

  @ViewChild(EfnCaseFormComponent)
  efnCaseForm: EfnCaseFormComponent;

  @ViewChild(PromptComponent)
  clientInactiveModal: PromptComponent;

  clientCountyInactiveMessage: string;

  constructor(
      private router: Router,
      private activatedRoute: ActivatedRoute,
      private _store: Store<fromRoot.AppState>,
      private _efnService: EfnService
      ) {
    super();
    this.user$ = this._store.pipe(select(selectSecurityStateDataCurrentUser));
    this.client$ = this._store.pipe(select(selectClientDataCurrentClient));
  }

  ngOnInit(): void {
    this.user$
        .pipe(takeUntil(this.$destroyed))
        .subscribe(user => this.user = user);
    this.client$
        .pipe(takeUntil(this.$destroyed))
        .subscribe(client => this.client = client);
    this._efnService.checkIsCaseCountyActive(this.client?.county)
        .pipe(takeUntil(this.$destroyed))
        .subscribe((isActive: boolean) => {
          if (!isActive) {
            this.efnCaseForm.form.disable();
            this.clientCountyInactiveMessage = `The Client County ${this.client?.county} is inactive and is not currently accepting new cases.`;
            this.clientInactiveModal.open().subscribe(() => this.router.navigate([`app/client/${this.client.id}/efn_cases`]));
          }
        });
  }

  ngAfterViewInit(): void {

    setTimeout(() => {
      this.efnCaseForm.populateClient(this.client);
      this.efnCaseForm.populateUser(this.user);
      this.efnCaseForm.deadline.setValue(moment().endOf('day').add(7, 'day'));
      this.efnCaseForm.status.setValue(EfnCaseStatusType.Open);
      this.efnCaseForm.status.disable();

      this.efnCaseForm.usesOneTimeCompany
        .valueChanges
        .pipe(takeUntil(this.$destroyed))
        .subscribe(isOneTimeCompany => {

          if (isOneTimeCompany) {
            // reset all company fields
          } else {
            // reset one time payee fields
          }

        });
    });

  }

  submit(): Observable<EfnCase> {
    return this.efnCaseForm.createCase().pipe(
      tap((efn) => this.router.navigate(['../', efn.id], {relativeTo: this.activatedRoute})));
  }
}
