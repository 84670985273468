import {Injectable, Injector} from '@angular/core';
import {
    AssistanceApplication,
    AssistanceApplicationClient,
    DraftApplication,
    DraftApplicationModel,
    LookUp,
    LookUpCategoryType,
    PagedListOfAssistanceApplicationListPreview,
    PublicClient,
    PublicInternalFilter
} from '../../../services/api.client';
import {BehaviorSubject, Observable} from 'rxjs';
import {FormArray, FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {Validators} from '../../../utilities/Validators';
import {emailOrPhoneRequiredValidator} from './assistance-application/eligibility-screening-form/validators';
import * as moment from 'moment';


import {Race} from '../../../shared/enums/race';
import {Gender} from '../../../shared/enums/gender';
import {EmploymentStatus} from '../../../shared/enums/employment-status';
import {
    adultApplicationValidator,
    minorValidation,
    publicAssistanceApplicationRequiredDocumentsUploadedValidator, ssnValidator,
    stateValidator, stateValidatorForValidStates,
    yearRangeValidator, zipCodeValidator
} from './validators';
import {AssistanceApplicationFileType} from '../../../shared/enums/assistance-application-file-type';
import {ActivatedRoute, Router} from '@angular/router';
import {NotificationService} from '../../../services/NotificationService';
import swal from 'sweetalert2';
import {distinctUntilChanged, share, takeUntil} from 'rxjs/operators';
import {Store} from '@ngrx/store';
import * as fromRoot from '../../../store';
import {AssistanceApplicationStatus} from '../../../shared/enums/assistance-application-status';
import {setAssistanceApplicationsPaged} from '../../../store/actions/assistanceApplication.actions';
import {Disposable} from '../../../utilities/Disposable';
import {IncomeValidatorService} from '../../../shared/utils/income-validator.service';

@Injectable({
    providedIn: 'root'
})
export class AssistanceApplicationService extends Disposable {
    public isValidUrlSubject = new BehaviorSubject<boolean>(true);
    public isValidUrl$ = this.isValidUrlSubject.asObservable();
    public raceOptions: LookUp[] = [];
    public employmentStatusOptions: LookUp[] = [];
    public genderOptions: LookUp[] = [];
    public assistanceOptions: LookUp[] = [];
    public unionInfoOptions: LookUp[] = [];
    public currentDraftId: string;
    private includedRaces = [Race.AmericanIndian, Race.EuropeanAmerican, Race.AsianAmerican, Race.SouthAsian,
        Race.Jewish, Race.MiddleEastern, Race.PacificIslander, Race.Hispanic, Race.AfricanAmerican, Race.Biracial, Race.Other];
    private includedGenders = [Gender.Male, Gender.Female, Gender.NotDisclose, Gender.NonBinary, Gender.SelfDescribe];
    private includedEmploymentStatuses = [EmploymentStatus.PartTimeEmployment, EmploymentStatus.FullTimeEmployment,
        EmploymentStatus.Retired, EmploymentStatus.SelfEmployed, EmploymentStatus.UnemployedWithBenefits,
        EmploymentStatus.UnemployedNoBenefits];
    private currentPledgeType: string;
    private isSavingDraftSubject = new BehaviorSubject<boolean>(false);
    public isSavingDraft$ = this.isSavingDraftSubject.asObservable();

    public form = this.fb.group({
        eligibilityScreening: this.fb.group({
                state: new FormControl<string>('', {validators: [Validators.required, stateValidator()]}),
                county: new FormControl<string>('', Validators.required),
                childrenCount: new FormControl<number | null>(null, [Validators.required]),
                adultCount: new FormControl<number | null>(null, [Validators.required]),
                householdIncome: new FormControl<number>(0, {
                    validators: [Validators.required],
                }),
                contactPreference: new FormControl<string>('', Validators.required),
                email: new FormControl<string>('', [Validators.email]),
                phone: new FormControl<string>('', [
                    Validators.minLength(9, [/-/g, /_/g, /\(/g, /\)/g])
                ])
            },
            {
                validators: [emailOrPhoneRequiredValidator(),  this.incomeValidator.eligibilityScreeningIncomeValidator()]
            }),
        standardQuestions: this.fb.group({
            applicantInformation: this.fb.group({
                id: [null],
                emailAddress: ['', Validators.email],
                firstName: ['', Validators.required],
                lastName: ['', Validators.required],
                phone: ['', [Validators.minLength(9, [/-/g, /_/g, /\(/g, /\)/g])]],
                birthYear: new FormControl(
                    null,
                    Validators.compose([Validators.required,
                        adultApplicationValidator(),
                        yearRangeValidator(moment().subtract(150, 'years'), moment())])),
                address: ['', Validators.required],
                zipCode: ['', {validators: [Validators.required, zipCodeValidator()]}],
                ssn: ['', [Validators.required, ssnValidator()]],
                employmentStatus: ['', Validators.required],
                race: [null],
                otherRace: [''],
                gender: [null],
                totalMonthlyIncome: new FormControl<number>(0, [Validators.required]),
                totalHouseholdMonthlyIncome: new FormControl<number | null>(null, {validators: [Validators.required]}),
                alternativePhone: ['', [Validators.minLength(9, [/-/g, /_/g, /\(/g, /\)/g])]],
                county: ['', Validators.required],
                state: new FormControl<string>('', {validators: [Validators.required, stateValidatorForValidStates()]}),
                unionInfo: new FormControl('Not Applicable', Validators.required),
                isElderly: new FormControl(false),
                isDisabled: new FormControl(false),
                hasChildrenUnderFive: new FormControl(false),
                isVeteran: new FormControl(false),
                isHomeless: new FormControl(false),
                membersAreElderly: new FormControl(false),
                membersAreDisabled: new FormControl(false),
                membersHaveChildrenUnderFive: new FormControl(false),
            }),
            householdInformation: this.fb.group({
                adults: this.fb.array([]),
                minors: this.fb.array([]),
            }),
            assistanceRequestDescription: this.fb.group({
                rent: [false],
                evictionNotification: [false],
                electric: [false],
                gasHeat: [false],
                water: [false],
                sewer: [false],
                childcareExpenses: [false],
                transportationRideShare: [false],
                carRepair: [false],
            }),
            circumstances: [''],
        }),
        files: this.fb.array([])
    }, {
        validators: [publicAssistanceApplicationRequiredDocumentsUploadedValidator()]
    });

    get adults(): FormArray {
        return this.standardQuestions.get('householdInformation').get('adults') as FormArray;
    }

    get minors(): FormArray {
        return this.standardQuestions.get('householdInformation').get('minors') as FormArray;
    }

    get eligibilityScreeningFormGroup(): FormGroup {
        return this.form.get('eligibilityScreening') as FormGroup;
    }

    get standardQuestions(): FormGroup {
        return this.form.get('standardQuestions') as FormGroup;
    }

    get filesFormArray(): FormArray {
        return this.form.get('files') as FormArray;
    }

    get eligibilityState(): FormControl {
        return this.eligibilityScreeningFormGroup?.get('state') as FormControl;
    }

    get eligibilityCounty(): FormControl {
        return this.eligibilityScreeningFormGroup?.get('county') as FormControl;
    }

    get eligibilityHouseholdIncome(): FormControl {
        return this.eligibilityScreeningFormGroup?.get('householdIncome') as FormControl;
    }

    get eligibilityAdultCount(): FormControl {
        return this.eligibilityScreeningFormGroup?.get('adultCount') as FormControl;
    }

    get eligibilityChildrenCount(): FormControl {
        return this.eligibilityScreeningFormGroup?.get('childrenCount') as FormControl;
    }

    get standardQuestionsState(): FormControl {
        return this.standardQuestions?.get('applicantInformation')?.get('state') as FormControl;
    }

    get standardQuestionsCounty(): FormControl {
        return this.standardQuestions?.get('applicantInformation')?.get('county') as FormControl;
    }

    get standardQuestionsTotalHouseholdMonthlyIncome(): FormControl {
        return this.standardQuestions?.get('applicantInformation')?.get('totalHouseholdMonthlyIncome') as FormControl;
    }

    public get requestingUtilityAssistance(): boolean {
        const assistanceRequestDescription = this.standardQuestions.get('assistanceRequestDescription') as FormGroup;
        return assistanceRequestDescription.get('electric').value ||
            assistanceRequestDescription.get('gasHeat').value ||
            assistanceRequestDescription.get('water').value ||
            assistanceRequestDescription.get('sewer').value;
    }

    public get requestingChildcareAssistance(): boolean {
        const assistanceRequestDescription = this.standardQuestions.get('assistanceRequestDescription') as FormGroup;
        return assistanceRequestDescription.get('childcareExpenses').value;
    }

    public get requestingCarRepairAssistance(): boolean {
        const assistanceRequestDescription = this.standardQuestions.get('assistanceRequestDescription') as FormGroup;
        return assistanceRequestDescription.get('carRepair').value;
    }

    public get requestingRentAssistance(): boolean {
        const assistanceRequestDescription = this.standardQuestions.get('assistanceRequestDescription') as FormGroup;
        return assistanceRequestDescription.get('rent').value;
    }

    public get requestingEvictionAssistance(): boolean {
        const assistanceRequestDescription = this.standardQuestions.get('assistanceRequestDescription') as FormGroup;
        return assistanceRequestDescription.get('evictionNotification').value;
    }

    constructor(
        private fb: FormBuilder,
        private _assistanceApplicationClient: AssistanceApplicationClient,
        private _notificationService: NotificationService,
        private publicClient: PublicClient,
        private _store: Store<fromRoot.AppState>,
        private router: Router,
        private route: ActivatedRoute,
        private injector: Injector,
        private incomeValidator: IncomeValidatorService
    ) {
        super();
        this.handleFormUpdates();
    }

    public setIsValidUrl(pledgeType: string): void {
        this.publicClient.isValidUrl(pledgeType).subscribe(next => {
            this.isValidUrlSubject.next(next);
            if (next) {
                this.currentPledgeType = pledgeType;
            }
        }, err => {
            this.isValidUrlSubject.next(false);
        });
    }

    public checkIsCountyActive(county: string): Observable<boolean> {
        return this.publicClient.isApplicationCountyActive(county);
    }

    public addFileFormGroup(fileType?: AssistanceApplicationFileType | undefined): void {
        const formGroup = this.fb.group({
            fileType: [fileType, Validators.required],
            file: [null, Validators.required]
        });
        const formArray = this.form.get('files') as FormArray;
        formArray.push(formGroup);
    }

    public setDropdownOptions(): void {
        this.publicClient.retrieveLookUps(LookUpCategoryType.EmploymentStatus).subscribe(x => {
            const includedEmploymentStatusesStrings = this.includedEmploymentStatuses.map(o => o.toString());
            this.employmentStatusOptions = x.items.filter(item => includedEmploymentStatusesStrings.includes(item.key));
        });

        this.publicClient.retrieveLookUps(LookUpCategoryType.Race).subscribe(x => {
            const includedRacesString = this.includedRaces.map(o => o.toString());
            this.raceOptions = x.items.filter(item => includedRacesString.includes(item.key));
        });

        this.publicClient.retrieveLookUps(LookUpCategoryType.Gender).subscribe(x => {
            const includedGendersString = this.includedGenders.map(o => o.toString());
            this.genderOptions = x.items.filter(item => includedGendersString.includes(item.key));
        });

        this.publicClient.retrieveLookUps(LookUpCategoryType.AdministrativeAgencyFundAssistance).subscribe(x => {
            this.assistanceOptions = x.items;
        });

        this.publicClient.retrieveLookUps(LookUpCategoryType.UnionInfo).subscribe(x => {
            this.unionInfoOptions = x.items.sort((a, b) => {
                return a.sortOrder < b.sortOrder ? -1 : 1;
            });
        });


    }

    checkIsCaseCountyActive(county: string): Observable<boolean> {
        return this._assistanceApplicationClient.isCaseCountyActive(county);
    }

    getClientAssistanceApplications(clientId: string): Observable<AssistanceApplication[]> {
        return this._assistanceApplicationClient.getClientAssistanceApplications(clientId);
    }

    public loadDraft(draft: DraftApplication): void {
        this.currentDraftId = draft.id;
        const adults = [...draft.application.standardQuestions.householdInformation.adults];
        const minors = [...draft.application.standardQuestions.householdInformation.minors];
        draft.application.standardQuestions.householdInformation.adults = [];
        draft.application.standardQuestions.householdInformation.minors = [];
        this.form.patchValue(draft.application);

        minors.forEach(x => {
            this.minors.push(
                this.fb.group({
                    firstName: [x.firstName, Validators.required],
                    lastName: [x.lastName, Validators.required],
                    birthYear: new FormControl(
                        x.birthYear,
                        Validators.compose([Validators.required,
                            minorValidation(),
                            yearRangeValidator(moment().subtract(150, 'years'), moment())])),
                    ssn: ['', [Validators.required, ssnValidator()]]
                })
            );
        });
        adults.forEach(x => {
            this.adults.push(this.fb.group({
                firstName: [x.firstName, Validators.required],
                lastName: [x.lastName, Validators.required],
                birthYear: new FormControl(
                    x.birthYear,
                    Validators.compose([Validators.required,
                        yearRangeValidator(moment().subtract(150, 'years'), moment())])),
                ssn: ['', [Validators.required, ssnValidator()]],
                employmentStatus: [x.employmentStatus, Validators.required],
                totalMonthlyIncome: [x.totalMonthlyIncome, Validators.required]
            }));
        });

        this.router.navigate(['apply', this.currentPledgeType, 'application'], {queryParams: {id: draft.id}}).then();
    }

    public startNewApplication(): void {
        this.currentDraftId = null;
        this.router.navigate(['apply', this.currentPledgeType, 'introduction']).then();
    }

    public saveDraftApplication(): void {
        this.isSavingDraftSubject.next(true);
        const request = DraftApplicationModel.fromJS(this.form.getRawValue());
        request.eligibilityScreening.householdIncome =
            this.isNumeric(request.eligibilityScreening.householdIncome)
                ? request.eligibilityScreening.householdIncome
                : 0;
        request.standardQuestions.applicantInformation.totalHouseholdMonthlyIncome =
            this.isNumeric(request.standardQuestions.applicantInformation.totalHouseholdMonthlyIncome)
                ? request.standardQuestions.applicantInformation.totalHouseholdMonthlyIncome
                : 0;
        request.standardQuestions.applicantInformation.totalMonthlyIncome =
            this.isNumeric(request.standardQuestions.applicantInformation.totalMonthlyIncome)
                ? request.standardQuestions.applicantInformation.totalMonthlyIncome
                : 0;
        request.standardQuestions.householdInformation.adults.forEach(x => {
            x.totalMonthlyIncome = this.isNumeric(x.totalMonthlyIncome)
                ? x.totalMonthlyIncome
                : 0;
        });
        const assistanceRequestDescription = this.form.get('standardQuestions').get('assistanceRequestDescription') as FormGroup;
        Object.keys(assistanceRequestDescription.controls).forEach(key => {
            request.standardQuestions.assistanceRequestDescription[key] = assistanceRequestDescription.get(key).value ?? false;
        });
        this.publicClient.saveDraft(request, this.currentDraftId, this.currentPledgeType).subscribe({
            next: response => {
                if (!this.currentDraftId) {
                    this.currentDraftId = response;
                    const params = {id: response};
                    this.router.navigate([], {
                        relativeTo: this.route,
                        queryParams: params,
                        queryParamsHandling: 'merge',
                    }).then();
                    swal.fire({
                        heightAuto: false,
                        icon: 'success',
                        title: 'Saved',
                        text: 'Your application has been saved. You may continue filling it out now or return at a later time. You were sent a confirmation message with a link and temporary password you can use to access your application.',
                    }).then();
                }
                this.injector.get(NotificationService).showSuccess('Your application was saved successfully.');
            },
            error: err => {
                console.error(err);
                this.injector.get(NotificationService).showError('An error occurred while trying to save your application. Please try again, or contact support if the issue persists.');
            }
        }).add(() => this.isSavingDraftSubject.next(false));
    }

    private isNumeric(str: any): boolean {
        if (!str || str.toString().trim() === '') {
            return false;
        }
        return !isNaN(+str);
    }

    getAssistanceApplicationsPaged(searchValue: string,
                                   agencyId: string,
                                   managingAgencyId: string,
                                   status: AssistanceApplicationStatus,
                                   publicInternal: PublicInternalFilter,
                                   sortName = '',
                                   limit = 100,
                                   offset = 0,
                                   descending = false): Observable<PagedListOfAssistanceApplicationListPreview> {
        const request = this._assistanceApplicationClient.getAssistanceApplicationsPaged(
            searchValue,
            agencyId,
            managingAgencyId,
            status,
            publicInternal,
            limit,
            offset,
            sortName,
            null,
            descending).pipe(share());
        request.subscribe({
            next: (result) => {
                this.setAssistanceApplicationsPaged(result);
            }, error: () => {
                this._notificationService.showError('There was a problem retrieving the Assistance Applications');
            }
        });
        return request;
    }

    private setAssistanceApplicationsPaged(apps: PagedListOfAssistanceApplicationListPreview): void {
        this._store.dispatch(setAssistanceApplicationsPaged({payload: apps}));
    }

    private handleFormUpdates(): void {
        this.handleEligibilityFormUpdates();
        this.handleStandardQuestionFormUpdates();
    }

    private handleEligibilityFormUpdates(): void {
        this.handleEligibilityStateUpdates();
        this.handleEligibilityCountyUpdates();
        this.handleEligibilityHouseholdIncomeUpdates();
    }

    private handleStandardQuestionFormUpdates(): void {
        this.handleStandardQuestionsStateUpdates();
        this.handleStandardQuestionsCountyUpdates();
        this.handleStandardQuestionsTotalHouseholdMonthlyIncomeUpdates();
        this.handleStandardQuestionsAdultsUpdates();
        this.handleStandardQuestionsMinorsUpdates();
    }

    private handleEligibilityStateUpdates(): void {
        this.eligibilityState.valueChanges
            .pipe(
                distinctUntilChanged(),
                takeUntil(this.$destroyed)
            )
            .subscribe((state: string) => {
                if (state !== 'Other') {
                    this.standardQuestionsState.setValue(state);
                }
            });
    }

    private handleEligibilityCountyUpdates(): void {
        this.eligibilityCounty.valueChanges
            .pipe(
                distinctUntilChanged(),
                takeUntil(this.$destroyed)
            )
            .subscribe((county: string) => {
                this.standardQuestionsCounty.setValue(county);
            });
    }

    private handleStandardQuestionsStateUpdates(): void {
        this.standardQuestionsState.valueChanges
            .pipe(
                distinctUntilChanged(),
                takeUntil(this.$destroyed)
            )
            .subscribe((state: string) => {
                if (state === 'MO' || state === 'IL') {
                    this.eligibilityState.setValue(state);
                } else {
                    this.eligibilityState.setValue('Other');
                }
            });
    }

    private handleStandardQuestionsCountyUpdates(): void {
        this.standardQuestionsCounty.valueChanges
            .pipe(
                distinctUntilChanged(),
                takeUntil(this.$destroyed)
            )
            .subscribe((county: string) => {
                this.eligibilityCounty.setValue(county);
            });
    }

    private handleEligibilityHouseholdIncomeUpdates(): void {
        this.eligibilityHouseholdIncome.valueChanges
            .pipe(
                distinctUntilChanged(),
                takeUntil(this.$destroyed)
            )
            .subscribe((amount: string) => {
                this.standardQuestionsTotalHouseholdMonthlyIncome.setValue(amount);
            });
    }

    private handleStandardQuestionsTotalHouseholdMonthlyIncomeUpdates(): void {
        this.standardQuestionsTotalHouseholdMonthlyIncome.valueChanges
            .pipe(
                distinctUntilChanged(),
                takeUntil(this.$destroyed)
            )
            .subscribe((amount: string) => {
                this.eligibilityHouseholdIncome.setValue(amount);
            });
    }

    private handleStandardQuestionsAdultsUpdates(): void {
        this.adults.valueChanges
            .pipe(
                distinctUntilChanged(),
                takeUntil(this.$destroyed)
            )
            .subscribe((adultsFormArray: FormArray) => {
                this.eligibilityAdultCount.setValue(adultsFormArray?.length);
            });
    }

    private handleStandardQuestionsMinorsUpdates(): void {
        this.minors.valueChanges
            .pipe(
                distinctUntilChanged(),
                takeUntil(this.$destroyed)
            )
            .subscribe((minorsFormArray: FormArray) => {
                this.eligibilityChildrenCount.setValue(minorsFormArray?.length);
            });
    }
}
