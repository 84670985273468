import {EMPTY as observableEmpty, from as observableFrom, Observable} from 'rxjs';

import {take, filter, mergeMap, delay, map, tap, takeUntil} from 'rxjs/operators';
import {Component, ViewChild, Output, EventEmitter, forwardRef, OnInit} from '@angular/core';
import {Router, Params, ActivatedRoute} from '@angular/router';
import {Validators, FormControl, FormBuilder, AbstractControl} from '@angular/forms';
import {
    Agency,
    Allocation,
    Company,
    PagedListOfPledgeType,
    PledgeType,
    StatusType
} from '../../../services/api.client';
import {extractEnumNames} from '../../../utilities/Util';
import {AllocationService} from '../../../services/AllocationService';
import {PledgeTypeService} from '../../../services/PledgeTypeService';
import {AgencyListModalComponent} from '../agency/AgencyListModalComponent';
import {CompanyListModalComponent} from '../company/CompanyListModalComponent';
import {PromptComponent} from '../../shared/dialogs/PromptComponent';
import {AutoDisableParent} from '../../../directives/AutoDisableDirective';
import {select, Store} from '@ngrx/store';
import * as fromRoot from '../../../store';
import {selectAllocationDataCurrentSelectedAllocation} from '../../../store/selectors/allocation.selectors';
import {selectPledgeTypeDataAllPledgeTypes} from '../../../store/selectors/pledgeType.selectors';
import {Disposable} from '../../../utilities/Disposable';
import {CompanySelectedEvent} from '../../shared/events/company-selected-event';

@Component({
    templateUrl: './AllocationComponent.html',
    styleUrls: ['./AllocationComponent.scss'],
    providers: [{provide: AutoDisableParent, useExisting: forwardRef(() => AllocationComponent)}],
})

export class AllocationComponent extends Disposable implements OnInit {
    public form = this.fb.group({
        reason: new FormControl(null),
        amount: new FormControl(null, Validators.required),
        allocatedAmount: new FormControl(null),
        adminPercent: new FormControl(null),
        pledgeTypeId: new FormControl(null, Validators.required),
        agencyId: new FormControl(null, Validators.required),
        agencyName: new FormControl(null, Validators.required),
        companyId: new FormControl(null),
        companyName: new FormControl(null),
        status: new FormControl(StatusType.Enabled)
    });

    constructor(
        private fb: FormBuilder,
        private _allocationService: AllocationService,
        private _router: Router,
        private _route: ActivatedRoute,
        private _pledgeTypeService: PledgeTypeService,
        private _store: Store<fromRoot.AppState>
    ) {
        super();
        this.currentAllocation$ = this._store.pipe(select(selectAllocationDataCurrentSelectedAllocation));
        this.allPledgeTypes$ = this._store.pipe(select(selectPledgeTypeDataAllPledgeTypes));
    }

    statuses = extractEnumNames(StatusType);
    activePledgeTypes: PledgeType[] = [];
    selectedAgencyName = 'Choose an Agency';
    selectedCompanyName = 'Choose a Company';
    params: any;
    originalAmountAllocated: any = 0;
    amountAllocatedErrorMessage = '';

    currentAllocation: Allocation;
    currentAllocation$: Observable<Allocation>;

    allPledgeTypes$: Observable<PledgeType[]>;


    // -------- agency modal ------------
    @ViewChild(AgencyListModalComponent)
    agencyListModal: AgencyListModalComponent;
    @Output('onShowAgencyModal') _showAgencies = new EventEmitter();

    // -------- company modal ------------
    @ViewChild(CompanyListModalComponent)
    companyListModal: CompanyListModalComponent;
    @Output('onShowCompanyModal') _showCompany = new EventEmitter();
    s;
    // -------- Prompt modal ------------
    @ViewChild(PromptComponent)
    promptModal: PromptComponent;
    @Output('onShowPromptModal') _showPrompt = new EventEmitter();

    public reason = this.form.get('reason') as FormControl;
    public amount = this.form.get('amount') as FormControl;
    public allocatedAmount = this.form.get('allocatedAmount') as FormControl;
    public adminPercent = this.form.get('adminPercent') as FormControl;
    public pledgeTypeId = this.form.get('pledgeTypeId') as FormControl;
    public agencyId = this.form.get('agencyId') as FormControl;
    public agencyName = this.form.get('agencyName') as FormControl;
    public companyId = this.form.get('companyId') as FormControl;
    public companyName = this.form.get('companyName') as FormControl;
    public status = this.form.get('status') as FormControl;

    // prompt modal
    showPromptModal(): boolean {
        this.promptModal.open().subscribe(okClicked => {
            if (okClicked) {
                this._allocationService.deleteAllocation(this.currentAllocation.id);
                this._router.navigate(['/app/allocations', this.params]).then();
            } else {
                return false;
            }
        });

        return false;
    }

    ngOnInit(): void {
        this._route.params
            .pipe(delay(0))
            .subscribe((params: Params) => this.params = params);
        this.allPledgeTypes$.pipe(
            take(1),
            mergeMap((pledgeTypes) => {
                if (pledgeTypes) {
                    return observableFrom([pledgeTypes]);
                } else {
                    return this._pledgeTypeService.getPledgeTypes('', '', false, 100, 0)
                        .pipe(map((result: PagedListOfPledgeType) => result.items));
                }
            })
        ).subscribe((pledgeTypes) => {
            for (const pledgeType of pledgeTypes) {
                if (pledgeType.status === StatusType[StatusType.Enabled]) {
                    this.activePledgeTypes.push(pledgeType);
                }
            }

            this._route.params.subscribe((params: Params) => {
                const id = params.id;
                if (id === 'add') { // edit
                    this._allocationService.setCurrentSelectedAllocation(new Allocation());
                } else {
                    this._allocationService.getAllocationById(id).pipe(
                        takeUntil(this.$destroyed),
                        filter(allocation => !!allocation),
                        take(1)
                    )
                        .subscribe(allocation => {
                            this.currentAllocation = allocation || new Allocation();
                            this.selectedAgencyName = allocation.agencyName ? allocation.agencyName : 'Choose an Agency';
                            this.selectedCompanyName = allocation.companyName ? allocation.companyName : 'Choose a Company';
                            this.originalAmountAllocated = allocation.amount;
                            this.pledgeTypeId.setValue(allocation.pledgeTypeId);
                            const activePledgeType = this.activePledgeTypes?.find(x => x.name === allocation.pledgeTypeName);

                            this.form.patchValue({
                                reason: this.currentAllocation?.reason,
                                amount: this.currentAllocation?.amount ? this.currentAllocation.amount : 0,
                                allocatedAmount: this.currentAllocation?.allocatedAmount,
                                adminPercent: this.currentAllocation?.adminPercent,
                                pledgeTypeId: activePledgeType?.id,
                                agencyId: this.currentAllocation?.agencyId,
                                agencyName: this.currentAllocation?.agencyName,
                                companyId: this.currentAllocation?.companyId,
                                companyName: this.currentAllocation?.companyName,
                                status: this.currentAllocation?.status
                            });
                        });
                }
            });
        });
    }

    allocationLoad(): void {
        this._route.params.subscribe((params: Params) => {
            const id = params.id;
            if (id === 'add') { // add
                this._allocationService.setCurrentSelectedAllocation(new Allocation());
                // edit
            } else if (id) {
                this._allocationService.getAllocationById(id);
            }
        });
    }

    // -------agency modal-----------------
    showAgencyModal(): boolean {
        this.agencyListModal.open();
        return false;
    }

    onAgencySelected(selectedAgency: Agency): void {
        if (selectedAgency) {
            selectedAgency.agencyName ? this.selectedAgencyName = selectedAgency.agencyName : this.selectedAgencyName = '';
            this.agencyId.setValue(selectedAgency.id);
            this.agencyName.setValue(selectedAgency.agencyName);
        } else {
            this.selectedAgencyName = '';
            this.agencyId.setValue('');
            this.agencyName.setValue('');
        }
        this.agencyName.markAsDirty();
        this.agencyId.markAsDirty();
    }

    showCompanyModal(): boolean {
        this.companyListModal.openModal();
        return false;
    }

    onCompanySelected(selectedCompany: CompanySelectedEvent): void {
        if (selectedCompany.company) {
            this.selectedCompanyName = selectedCompany.company.name;
            this.companyId.setValue(selectedCompany.company.id);
            this.companyName.setValue(selectedCompany.company.name);
        } else {
            this.selectedCompanyName = '';
            this.companyId.setValue('');
            this.companyName.setValue('');
        }
        this.companyName.markAsDirty();
        this.companyId.markAsDirty();
    }

    validateOriginalAmountAllocated(): boolean {
        if (!this.pledgeTypeId || !this.currentAllocation || !this.originalAmountAllocated) {
            return true;
        }

        const amount = +this.amount.value;
        const allocatedAmt = this.allocatedAmount.value;

        if (amount < allocatedAmt) {
            this.amountAllocatedErrorMessage = 'Allocated amount cannot be less than $' + allocatedAmt + '.<br>\
                This allocation already has confirmed pledge(s) that require at least $' + allocatedAmt + ' allocated.';
            return false;
        } else {
            this.amountAllocatedErrorMessage = '';
            return true;
        }
    }

    isValidSave(): boolean {
        return !this.form.dirty;
    }

    isEdit(): boolean {
        return !!(this.currentAllocation && this.currentAllocation.id);
    }

    submit(): Observable<Allocation> {
        const formData = this.form.getRawValue();
        const allocation = Allocation.fromJS({
            agencyId: formData.agencyId,
            companyId: formData.companyId || null,
            pledgeTypeId: formData.pledgeTypeId || null,
            agencyName: formData.agencyName || null,
            companyName: formData.companyName || null,
            pledgeTypeName: null,
            reason: formData.reason || null,
            amount: formData.amount,
            adminPercent: formData.adminPercent || null,
            status: formData.status,
            remainingBalance: 0,
            allocatedAmount: formData.allocatedAmount || null
        });
        if (!this.form.valid) {
            this.form.markAllAsTouched();
            return observableEmpty;
        }
        if (this.isEdit()) {
            allocation.id = this.currentAllocation.id;
            allocation.allocatedAmount = 0;
            return this._allocationService.updateAllocation(allocation)
                .pipe(
                    tap(() => this._router.navigate(['/app/allocations']))
                );
        } else {
            return this._allocationService.addAllocation(allocation)
                .pipe(
                    tap(() => this._router.navigate(['/app/allocations']))
                );
        }
    }

    ngOnDestroy(): void {
        this._allocationService.setCurrentSelectedAllocationAction(null);
    }
}
