import {Component} from '@angular/core';

@Component({
  templateUrl: './PaymentProcessingComponent.html',
  styleUrls: ['./PaymentProcessingComponent.scss']
})

export class PaymentProcessingComponent {

  constructor() {

  }
}
