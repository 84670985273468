import {Pipe, PipeTransform} from '@angular/core';

/**
 * Created by djacobsmeyer on 1/4/17.
 */
@Pipe({name: 'url'})
export class UrlPipe implements PipeTransform {
  transform(value: string, format): string {
    if (!value) {
      return '';
    }
    if (value.indexOf('http://') === 0 || value.indexOf('https://') === 0) {
      return value;
    } else {
      return 'http://' + value;
    }
  }
}
