<div class="row">
  <h2 class="offset-md-1 col-md-10">Allocations and Balances Report</h2>
</div>
<form pledgeReportForm #form
      [showDisplayPledgeStatus]="false"
      [showZeroBalance]="true" novalidate>
  <button type="submit" class="btn btn-primary" (click)="form.submitAllocationAndBalanceReport()">Submit</button>
  <button class="btn btn-primary" (click)="form.downloadAllocationAndBalanceReportClick()">Export to Excel</button>
</form>
<reportTable [headers]="tableHeaders" [rows]="rows | async"></reportTable>
