import {Component} from '@angular/core';

@Component({
  selector: '[support]',
  templateUrl: './SupportComponent.html',
  styleUrls: ['./SupportComponent.scss']
})

export class SupportComponent {
}
