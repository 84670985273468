import {Component, OnInit} from '@angular/core';
import {
  AssistanceApplicationClient,
  PagedListOfAuditProgressReportTableRowViewModel,
  PagedListOfAuditReportTableRowViewModel
} from '../../../../services/api.client';
import {AuditReportUrls} from '../../../../api/AuditReportUrls';
import {FileDownloadService} from '../../../../services/FileDownloadService';
import * as moment from 'moment/moment';
import {NotificationService} from '../../../../services/NotificationService';

@Component({
  selector: 'app-audit-progress-report',
  templateUrl: './audit-progress-report.component.html',
  styleUrls: ['./audit-progress-report.component.scss']
})
export class AuditProgressReportComponent implements OnInit {
  private pageIndex = 1;
  private limit = 10;
  private offset = 0;

  public pagedData: PagedListOfAuditProgressReportTableRowViewModel | undefined;
  public fetchingData = false;

  constructor(private assistanceApplicationClient: AssistanceApplicationClient,
              private fileDownloadService: FileDownloadService,
              private _notificationService: NotificationService) {
  }

  ngOnInit(): void {
    this.fetchData();
  }
  private fetchData(): void {
    this.fetchingData = true;
    this.assistanceApplicationClient.getAuditProgressReportTableRows(
        this.limit,
        this.offset
    ).subscribe((data) => {
      this.pagedData = data;
      this.fetchingData = false;
    }, err => {
      this.fetchingData = false;
      this._notificationService.showError('A problem occurred while retrieving data. Please try again.');
    });
  }
  public onExportClick(): void {
    const url = this.fileDownloadService.buildUrl(AuditReportUrls.DOWNLOAD_AUDIT_PROGRESS_REPORT);
    window.open(url, '_blank');
  }

  public onPageChange(pageIndex: number): void {
    this.pageIndex = pageIndex;
    this.offset = (this.pageIndex - 1) * this.limit;
    this.fetchData();
  }

  public onLimitChange(limit: number): void {
    this.limit = limit;
    this.offset = (this.pageIndex - 1) * this.limit;
    this.fetchData();
  }
}
