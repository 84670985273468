import {createAction, props} from '@ngrx/store';
import {Efs} from '../../services/api.client';

export const setCurrentSelecteddEfs  = createAction(
    '[Efs] SET_CURRENT_SELECTEDEFS',
    props<{ payload: Efs }>());

export const efsDataIsLoaded = createAction(
    '[Efs] EFSSDATA_ISLOADED',
    props<{ payload: Efs }>());

export const efsDataIsLoading = createAction(
    '[Efs] EFSSDATA_ISLOADING');

export const clearCurrentSelectedEfs = createAction(
    '[Efs] CLEAR_CURRENT_SELECTEDEFS',
    props<{ payload: Efs }>());

export const setCurrentClientEfsList = createAction(
    '[Efs] SET_CURRENTCLIENT_EFSLIST',
    props<{ payload: Efs[] }>());
