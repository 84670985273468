<div class="container" *ngIf="currentClient">

    <div class="row flex-md-items-between">
        <label class="col-xs"><strong>Application History For:</strong>
            {{currentClient.prefix}} {{currentClient.firstName}} {{currentClient.middleInitial}} {{currentClient.lastName}} {{currentClient.suffix}}
        </label>
        <div *ngIf="hasAccess()" class="col-xs-3">
            <a [routerLink]="['./add']" class="btn btn-primary mb-xs new-button" [class.disabled]="!isClientValid()">New
                Application</a><br>
            <span *ngIf="invalidClientMessage && !isClientValid()" class="text-danger">{{ invalidClientMessage }}</span>
        </div>
    </div>
    <div class="row">
        <table class="table table-striped table-hover">
            <thead>
            <th class="text-xs-center" (click)="sortTable('fundType.name')">
                <div class="header-container">
                    <span>Fund</span>
                    <div class="chevron-container">
                <span *ngIf="sortField === 'fundType.name'; else notSortField">
                    <i *ngIf="sortDirection === 'asc'" class="fa fa-chevron-up"></i>
                    <i *ngIf="sortDirection === 'desc'" class="fa fa-chevron-down"></i>
                </span>
                        <ng-template #notSortField>
                            <i class="fa fa-chevron-up"></i>
                            <i class="fa fa-chevron-down"></i>
                        </ng-template>
                    </div>
                </div>
            </th>
            <th class="text-xs-center">
                <div class="header-container">
                    <span>Summary</span>
                </div>
            </th>
            <th class="text-xs-center" (click)="sortTable('totalNeed')">
                <div class="header-container">
                    <span>Total Need</span>
                    <div class="chevron-container">
                <span *ngIf="sortField === 'totalNeed'; else notSortField">
                    <i *ngIf="sortDirection === 'asc'" class="fa fa-chevron-up"></i>
                    <i *ngIf="sortDirection === 'desc'" class="fa fa-chevron-down"></i>
                </span>
                        <ng-template #notSortField>
                            <i class="fa fa-chevron-up"></i>
                            <i class="fa fa-chevron-down"></i>
                        </ng-template>
                    </div>
                </div>
            </th>
            <th class="text-xs-center" (click)="sortTable('assignedAgency.agencyName')">
                <div class="header-container">
                    <span>Agency</span>
                    <div class="chevron-container">
                <span *ngIf="sortField === 'assignedAgency.agencyName'; else notSortField">
                    <i *ngIf="sortDirection === 'asc'" class="fa fa-chevron-up"></i>
                    <i *ngIf="sortDirection === 'desc'" class="fa fa-chevron-down"></i>
                </span>
                        <ng-template #notSortField>
                            <i class="fa fa-chevron-up"></i>
                            <i class="fa fa-chevron-down"></i>
                        </ng-template>
                    </div>
                </div>
            </th>
            <th class="text-xs-center" (click)="sortTable('createdByUser')">
                <div class="header-container">
                    <span>Case Manager</span>
                    <div class="chevron-container">
                <span *ngIf="sortField === 'createdByUser'; else notSortField">
                    <i *ngIf="sortDirection === 'asc'" class="fa fa-chevron-up"></i>
                    <i *ngIf="sortDirection === 'desc'" class="fa fa-chevron-down"></i>
                </span>
                        <ng-template #notSortField>
                            <i class="fa fa-chevron-up"></i>
                            <i class="fa fa-chevron-down"></i>
                        </ng-template>
                    </div>
                </div>
            </th>
            <th class="text-xs-center" (click)="sortTable('status')">
                <div class="header-container">
                    <span>Case Status</span>
                    <div class="chevron-container">
                <span *ngIf="sortField === 'status'; else notSortField">
                    <i *ngIf="sortDirection === 'asc'" class="fa fa-chevron-up"></i>
                    <i *ngIf="sortDirection === 'desc'" class="fa fa-chevron-down"></i>
                </span>
                        <ng-template #notSortField>
                            <i class="fa fa-chevron-up"></i>
                            <i class="fa fa-chevron-down"></i>
                        </ng-template>
                    </div>
                </div>
            </th>
            <th class="text-xs-center" (click)="sortTable('enteredDate')">
                <div class="header-container">
                    <span>Entry Date</span>
                    <div class="chevron-container">
                <span *ngIf="sortField === 'enteredDate'; else notSortField">
                    <i *ngIf="sortDirection === 'asc'" class="fa fa-chevron-up"></i>
                    <i *ngIf="sortDirection === 'desc'" class="fa fa-chevron-down"></i>
                </span>
                        <ng-template #notSortField>
                            <i class="fa fa-chevron-up"></i>
                            <i class="fa fa-chevron-down"></i>
                        </ng-template>
                    </div>
                </div>
            </th>
            <th class="text-xs-center">
                <div class="header-container">
                    <span>Case ID</span>
                </div>
            </th>
            </thead>
            <tbody>
            <tr *ngFor="let application of clientAssistanceApplications" (click)="navToDetail(application.id)"
                class="cursor-pointer">
                <td class="text-xs-center">{{application.fundType.name}}</td>
                <td class="text-xs-center">{{application.summary}}</td>
                <td class="text-xs-center">{{application.totalNeed | parseNumber | currency:'USD':'symbol':'1.2-2'}}</td>
                <td class="text-xs-center">{{application.assignedAgency.agencyName}}</td>
                <td class="text-xs-center">{{application.createdByUser ? application.createdByUser.firstName + ' ' + application.createdByUser.lastName : 'None'}}</td>
                <td class="text-xs-center">{{getCaseStatus(application.status)}}</td>
                <td class="text-xs-center">{{application.enteredDate | moment: 'L'}}</td>
                <td class="text-xs-center">{{application.id}}</td>
            </tr>
            </tbody>
        </table>
    </div>

</div>
