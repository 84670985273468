import {Input, Output, Component, EventEmitter} from '@angular/core';

export class DropdownValue {
  id: string;
  text: string;

  constructor(id: string, text: string) {
    this.id = id;
    this.text = text;
  }

  public static ConvertToDropdownValues(data: { [key: string]: string }): DropdownValue[] {
    var _list = [];

    for (var _prop in data) {
      _list.push(new DropdownValue(_prop, data[_prop]));
    }
    ;

    return _list;
  }
}

@Component({
  selector: 'dropdown',
  template: `<select class="form-control" (change)="selectItem($event)">
                    <option [value]="i.id" [selected]="isSelected(i.id)" *ngFor="let i of _data">{{i.text}}</option>
                </select>
  `
})
export class DropdownComponent {
  @Input()
  set values(input: DropdownValue[]) {
    this._data = input;
    this.addPromptValue();
  }

  _data: DropdownValue[];

  @Input()
  set initialValue(id: string) {
    this._selectedId = id;
  }

  @Input()
  set promptValue(value: string) {
    this._promptValue = value;
    this.addPromptValue();
  }

  private _promptValue: string;

  private _selectedId: string;

  @Output()
  select: EventEmitter<string> = new EventEmitter<string>();

  constructor() {
  }

  private addPromptValue() {
    if (this._promptValue) {
      if (this._data && this._data.length) {
        if (this._data[0].id == '') {
          return;
        }
        var _prompt = new DropdownValue('', '-- ' + this._promptValue + ' --');
        this._data.unshift(_prompt);
      }
    }
  }

  ngOnInit(): void {

  }

  isSelected(id) {
    return this._selectedId == id ? 'selected' : '';
  }

  selectItem(value) {
    this._selectedId = value.target.value;
    this.select.emit(value.target.value);
  }
}
