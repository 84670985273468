import {map} from 'rxjs/operators';
import {Component, OnInit, ViewChild} from '@angular/core';
import {ITableHeader} from '../../../../shared/table/TableComponent';
import {EfsReportFormComponent} from '../form/EfsReportFormComponent';
import {Observable} from 'rxjs';
import {EfsReport} from '../../../../../services/api.client';
import * as moment from 'moment';
import {DATE_TIME_FORMAT} from '../../../../../constants';
import {select, Store} from '@ngrx/store';
import * as fromRoot from '../../../../../store';
import {selectReportDataEfsReport} from '../../../../../store/selectors/report.selectors';

@Component({
    templateUrl: './EfsReportComponent.html',
})
export class EfsReportComponent implements OnInit {

    tableHeaders: ITableHeader[] = [
        {text: 'Entry Date'},
        {text: 'First Name'},
        {text: 'Last Name'},
        {text: 'SSN'},
        {text: 'Cycle'},
        {text: 'Assistance Type'},
        {text: 'Agency'},
        {text: 'Zip Code'},
    ];

    @ViewChild('form')
    form: EfsReportFormComponent;

    report$: Observable<EfsReport[]> = null;
    rows: Observable<(string | number)[][]>;

    constructor(private _store: Store<fromRoot.AppState>) {
        this.report$ = this._store.pipe(select(selectReportDataEfsReport));
    }

    ngOnInit(): void {
        this.rows = this.report$
            .exists().pipe(
                map((report) => report.map(r => [
                    moment(r.enteredDate).format(DATE_TIME_FORMAT),
                    r.clientFirstName,
                    r.clientLastName,
                    '***-**-' + r.clientLast4Ssn || 'no SSN',
                    r.pledgeTypeName,
                    r.assistanceType,
                    r.agencyName,
                    r.zipCode
                ])));
    }
}
