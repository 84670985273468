import {Component, ContentChild, HostBinding, ElementRef} from '@angular/core';
import {FormControlDirective} from '@angular/forms';


@Component({
    selector: 'assistanceApplicationGroupLayout',
    host: {class: 'row'},
    template: `
  <div class="col-xs-2">
    <ng-content></ng-content>
  </div>
  <div class="col-xs-10">
    <ng-content select="assistanceApplicationFieldLayout"></ng-content>
  </div>
`,
    styles: [`:host {margin-bottom: 1rem;}`]
})
export class AssistanceApplicationFormLayoutComponent {
}

@Component({
    selector: 'assistanceApplicationFieldLayout',
    host: {class: 'row'},
    template: `
  <div class="col-xs-3">
    <ng-content select="label"></ng-content>
  </div>
  <div class="col-xs-9">
    <ng-content></ng-content>
  </div>
`,
    styles: [`:host {margin-bottom: 1rem;} :host ::ng-deep datePicker { width: 100%; }`]
})
export class AssistanceApplicationFormFieldLayoutComponent {
    @ContentChild(FormControlDirective)
    formControlDirective !: ElementRef;

    get formControl(): any  {
        return this.formControlDirective && this.formControlDirective?.nativeElement?.control
            ? this.formControlDirective.nativeElement.control
            : null;
    }

    @HostBinding('class.has-error')
    get hasError(): boolean {
        return this.formControl ? this.formControl.touched && !this.formControl.valid : false;
    }

}
