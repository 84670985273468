/**
 * Created by djacobsmeyer on 5/8/17.
 */

import {Component, OnInit} from '@angular/core';
import {UserService} from '../../../../services/UserService';
import * as moment from 'moment';
import {SecurityService} from '../../../../services/SecurityService';
import {User} from '../../../../services/api.client';
import {Observable} from 'rxjs';
import {SystemService} from '../../../../services/SystemService';
import {NotificationService} from '../../../../services/NotificationService';
import {Router} from '@angular/router';
import {select, Store} from '@ngrx/store';
import * as fromRoot from '../../../../store';
import {selectSecurityStateDataCurrentUser} from '../../../../store/selectors/security.selectors';

@Component({
    selector: 'support-agreement',
    templateUrl: './SupportAgreementComponent.html',
    styleUrls: ['./SupportAgreementComponent.scss']
})

export class SupportAgreementComponent implements OnInit {
    userHasAgreed: boolean;

    loggedInUser: User;
    loggedInUser$: Observable<User>;

    constructor(
        private userService: UserService,
        private systemService: SystemService,
        private securityService: SecurityService,
        private notificationService: NotificationService,
        private router: Router,
        private _store: Store<fromRoot.AppState>
    ) {
        this.loggedInUser$ = this._store.pipe(select(selectSecurityStateDataCurrentUser));
    }

    ngOnInit(): void {
        this.loggedInUser$
            .subscribe((user) => {
                this.loggedInUser = user;
                this.userHasAgreed = user.uwAgreementDate && this.systemService.loggedInUserHasAgreed();
            });
    }

    saveAgreementDate(): void {
        this.userService.setAgreementDate(moment())
            .subscribe(() => {
                this.notificationService.showSuccess('You must log back in for changes to take effect.');
                this.securityService.logout();
            });
    }
}
