<!-- main page content. the place to put widgets in. usually consists of .row > .col-lg-* > .widget.  -->
<h1 class="page-title">Browse Fund Types</h1>
<section class="widget">
  <div class="widget-body">
    <div class="mt">
      <form novalidate>
        <div class="row form-group">
          <div class="col-md col-xs-12">
            <label class="pledgeType-label">Fund Type:</label>
            <input class="search-text-input form-control"
                   type="text"
                   placeholder="Search by Name"
                   [ngModel]="searchValue"
                   [ngModelOptions]="{standalone: true}"
                   (ngModelChange)="setSearchValue($event)"/>

          </div>
        </div>
        <div class="row form-group">
          <div class="col-xl-2 col-lg-3 col-md-6 col-sm-6 col-xs-12">
            <button class="btn btn-primary" type="submit" role="button" (click)="filterPledgeTypes()">
              Search
            </button>
          </div>
          <div class="col-xl-10 col-lg-9 col-md-6 col-sm-6 col-xs-12">
            <button [routerLink]="['../pledgetype/add']" class="btn btn-primary pull-right">New
              Fund Type
            </button>
          </div>
        </div>
      </form>
      <table-dynamic id="datatable-table"
                     class="table table-striped table-hover"
                     [headers]="tableHeaders"
                     [currentPage]="currentPage"
                     [totalCount]="totalCount"
                     (headerClick)="sortPledgeTypes($event)"
                     (pageChanged)="setPage($event)"
                     (limitChange)="setLimit($event)">
        <tr *ngFor="let pledgeType of pledgeTypes">
          <td>
            <a [routerLink]="['../pledgetype', pledgeType.id]"
               (click)="setCurrentSelectedPledgeType(pledgeType); false"><span
              class="fw-semi-bold">{{pledgeType.name}}</span></a>
          </td>
          <td>{{pledgeType.regionValue}}</td>
          <td>{{ pledgeType.fiscalStartMonth }}/{{pledgeType.fiscalStartDay}}</td>
          <td class="hidden-sm-down">{{ pledgeType.isSsnRequired }}</td>
          <td class="hidden-sm-down">{{ pledgeType.minSinglePledgeAmt | parseNumber | currency:'USD':'symbol':'1.2-2' }}
          </td>
          <td class="hidden-sm-down">{{ pledgeType.maxSinglePledgeAmt | parseNumber | currency:'USD':'symbol':'1.2-2' }}
          </td>
          <td class="hidden-sm-down">{{ pledgeType.maxYearlyPledgeAmt | parseNumber | currency:'USD':'symbol':'1.2-2' }}
          </td>
          <td class="hidden-sm-down">{{ pledgeType.crisisPledgeThreshold | parseNumber | currency:'USD':'symbol':'1.2-2' }}
          </td>
          <td class="hidden-sm-down">{{ pledgeType.status }}</td>
          <td class="hidden-sm-down">{{ pledgeType.isEfs }}</td>
          <td class="hidden-sm-down">{{ pledgeType.isPrivate }}</td>
          <td class="hidden-sm-down">{{ pledgeType.ledgerNumber }}</td>
        </tr>
      </table-dynamic>
      <div>* Dollar Help & Dollar More checkboxes, make the Fund Type viewable by the corresponsing Fund Type
        administrator
      </div>
      <div>** Clean Slate checkbox will make the additional "Clean Slate" questions appear on the Pledge Entry
        page.
      </div>
    </div>
  </div>
</section>
