<div class="container">
  <main id="content" role="main">
    <section class="widget animated fadeInUp">
      <header class="row">
        <h3>Reset Password</h3>
      </header>
      <form novalidate class="row" [formGroup]="form">
        <div class="form-group col-md-12 has-danger">
          <div [visible]="!password1.valid && password1.touched"
               class="form-control-feedback">Password field is required.
          </div>
          <div [visible]="password1.valid && !password2.valid && password2.touched"
               class="form-control-feedback">Please confirm your password.
          </div>
          <div [visible]="password1.valid && password2.valid && password2.touched && form.hasError('matchPassword')"
               class="form-control-feedback">Password does not match.
          </div>
        </div>
        <div class="form-group col-md-12">
          Password must meet the following requirements: <br>
          <ul>
            <li>Minimum length: 12</li>
            <li>Password must have 3 of 4:
              <ul>
                <li>Uppercase letter (A-Z)</li>
                <li>Lowercase letter (a-z)</li>
                <li>Number (0-9)</li>
                <li>Special Character (! @ # $ % ^ & *)</li>
              </ul>
            </li>
          </ul>
        </div>
        <div class="form-group col-md-12"
             [class.has-danger]="!password1.valid && password1.touched">
          <input [formControl]="password1"
                 [class.form-control-danger]="!password1.valid && password1.touched"
                 type="password" name="password1" required class="form-control" id="password1"
                 placeholder="New Password">
          <div *ngIf="password1.touched && password1.hasError('passwordComplexity')" class="form-control-feedback">
            {{password1.getError('passwordComplexity')}}</div>
        </div>
        <div class="form-group col-md-12"
             [class.has-danger]="!password2.valid && password2.touched">
          <input [formControl]="password2"
                 [class.form-control-danger]="!password2.valid && password2.touched"
                 type="password" name="password2" required class="form-control" id="password2"
                 placeholder="Confirmed Password">
        </div>
        <div class="form-group col-md-12 text-xs-right">
          <a class="btn btn-secondary btn-sm" routerLink="/login">Login</a>
          <button class="btn btn-inverse btn-sm" type="submit" (click)="submit()"
                  [disabled]="!form.valid">Reset Password
          </button>
        </div>
      </form>
    </section>
  </main>
</div>
