import {Component, OnInit} from '@angular/core';
import {User, UserType} from '../../../../services/api.client';
import {select, Store} from '@ngrx/store';
import * as fromRoot from '../../../../store';
import {Observable} from 'rxjs';
import {selectSecurityStateDataCurrentUser} from '../../../../store/selectors/security.selectors';

@Component({
  templateUrl: './PledgesReportComponent.html',
})
export class PledgesReportComponent implements OnInit {
  userTypes = UserType;
  currentUser: User;
  currentUser$: Observable<User>;

  constructor(private _store: Store<fromRoot.AppState>) {
    this.currentUser$ = this._store.pipe(select(selectSecurityStateDataCurrentUser));
  }

  ngOnInit(): void {
    this.currentUser$.subscribe(user => this.currentUser = user);
  }
}
