import {Component, OnInit} from '@angular/core';
import {User, UserType} from '../../../services/api.client';
import {PledgeType} from '../../../services/api.client';
import {SystemService} from '../../../services/SystemService';
import {Observable} from 'rxjs';
import {select, Store} from '@ngrx/store';
import * as fromRoot from '../../../store';
import {
  selectSecuritySecurityDataCurrentUserHasEfs, selectSecurityStateDataCurrentUser, selectSecurityStateDataCurrentUserHasEfn,
  selectSecurityStateDataSecurityDataPledgeTypes,
} from '../../../store/selectors/security.selectors';
import {environment} from '../../../../environments/environment';

@Component({
  selector: 'sidebar,[sidebar]',
  templateUrl: './SidebarComponent.html',
  styleUrls: ['./SidebarComponent.scss'],
})
export class SidebarComponent implements OnInit {
  userType = UserType;
  pledgeTypes: PledgeType[] = [];
  pledgeTypes$: Observable<PledgeType[]>;
  hasKeepingCurrent: boolean;
  hasUwdaFundType: boolean;
  hasEfs: boolean;
  hasEfs$: Observable<boolean>;
  user$: Observable<User>;
  user: User;

  hasEfn = false;
  hasAssistanceApplications: boolean;
  hasEfn$: Observable<boolean>;

  constructor(public system: SystemService, private _store: Store<fromRoot.AppState>) {
    this.pledgeTypes$ = this._store.pipe(select(selectSecurityStateDataSecurityDataPledgeTypes));
    this.hasEfs$ = this._store.pipe(select(selectSecuritySecurityDataCurrentUserHasEfs));
    this.hasEfn$ = this._store.pipe(select(selectSecurityStateDataCurrentUserHasEfn));
    this.user$ = this._store.pipe(select(selectSecurityStateDataCurrentUser));
  } // we use this inject in the template for all the security checks

  public get shouldShowApplications(): boolean {
    return this.hasAssistanceApplications && !environment.appConfig?.disableApplicationsFeature;
  }

  ngOnInit(): void {
    this.pledgeTypes$.subscribe(pledgeTypes => this.pledgeTypes = pledgeTypes);
    this.hasEfs$.subscribe(hasEfs => this.hasEfs = hasEfs);
    this.hasEfn$.subscribe(hasEfn => this.hasEfn = hasEfn);
    this.user$.subscribe(user => this.user = user);
    this.hasKeepingCurrent = this.pledgeTypes.some(p => p.isKeepingCurrent);
    this.hasUwdaFundType = this.pledgeTypes.some(p => p.isUwda);
    this.hasAssistanceApplications = this.user.hasAssistanceApps;
  }

  showUwdaMenu(): boolean {
    if (this.system.isSystemAdmin) {
      return true;
    } else {
      return (this.system.isAgencyAdmin || this.system.isRegionAdmin) && this.hasUwdaFundType;
    }
  }
}
