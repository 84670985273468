import {createAction, props} from '@ngrx/store';
import {Agency, AgencyEfnCase, PagedListOfAgency} from '../../services/api.client';

export const setCurrentSelectedAgency = createAction('[Agency] SET_CURRENT_SELECTED_AGENCY', props<{ payload: Agency }>());
export const agenciesDataIsLoading = createAction('[Agency] AGENCIES_DATA_ISLOADING');
export const agenciesDataLoaded = createAction('[Agency] AGENCIES_DATA_LOADED', props<{ payload: PagedListOfAgency}>());
export const agenciesDataClear = createAction('[Agency] AGENCIES_DATA_CLEAR');
export const agenciesDataSoftDelete = createAction('[Agency] AGENCIES_DATA_SOFT_DELETE');
export const setAgencyEfnCases = createAction(
    '[Agency] SET_AGENCY_EFN_CASES',
    props<{ payload: AgencyEfnCase[] }>());
