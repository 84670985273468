<div class="row">
    <h2 class="offset-md-1 col-md-10">Budget Billing Changes Report</h2>
</div>
<div class="row">
    <div class="col-xs-12">
        <form keepingCurrentReportForm #form
              [showMonthSelection]="true"
              [showAgencies]="true" novalidate>
            <button type="submit" class="btn btn-primary" (click)="submit()">Submit</button>
            <a class="btn btn-primary" target="_blank" [href]="form?.downloadBudgetBillingReport()">Export to Excel</a>
        </form>
        <h1 *ngIf="rows">Budget Billing Changes Report</h1>
        <reportTable *ngIf="rows" [rows]="rows " [headers]="tableHeaders"></reportTable>
    </div>
</div>
