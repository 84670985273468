import {from as observableFrom, Observable} from 'rxjs';
import {map, mergeMap} from 'rxjs/operators';
import {Component, OnInit, OnDestroy} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AssistanceApplication, Client, EfnCase, KcApplicationResult, Pledge, User} from '../../../services/api.client';
import {ClientService} from '../../../services/ClientService';
import {PledgeService} from '../../../services/PledgeService';
import {SystemService} from '../../../services/SystemService';
import {EfnService} from '../../../services/EfnService';
import {select, Store} from '@ngrx/store';
import * as fromRoot from '../../../store';
import {selectClientDataCurrentClient} from '../../../store/selectors/client.selectors';
import {selectEfnDataClientEfnCases} from '../../../store/selectors/efn.selectors';
import {selectKcAppDataKcAppResult} from '../../../store/selectors/kcApplication.selectors';
import {selectPledgeDataCurrentClientPledgeList} from '../../../store/selectors/pledge.selectors';
import {selectSecurityStateDataCurrentUser} from '../../../store/selectors/security.selectors';
import {environment} from '../../../../environments/environment';

@Component({
    templateUrl: './ClientTabsComponent.html',
    styleUrls: ['./ClientTabsComponent.scss']
})

export class ClientTabsComponent implements OnInit, OnDestroy {
    currentClient: Client;
    currentClient$: Observable<Client>;

    currentUser: User;
    currentUser$: Observable<User>;

    currentClientPledgeList: Pledge[];
    currentClientPledgeList$: Observable<Pledge[]>;

    clientEfnCases: EfnCase[];
    clientEfnCases$: Observable<EfnCase[]>;

    clientAssistanceApplications: AssistanceApplication[];
    clientAssistanceApplications$: Observable<AssistanceApplication[]>;

    applicationResult: KcApplicationResult;
    applicationResult$: Observable<KcApplicationResult>;

    constructor(private _route: ActivatedRoute,
                private _router: Router,
                private _clientService: ClientService,
                private _pledgeService: PledgeService,
                public system: SystemService,
                private efnService: EfnService,
                private _store: Store<fromRoot.AppState>) {
        this.currentUser$ = this._store.pipe(select(selectSecurityStateDataCurrentUser));
        this.currentClient$ = this._store.pipe(select(selectClientDataCurrentClient));
        this.currentClientPledgeList$ = this._store.pipe(select(selectPledgeDataCurrentClientPledgeList));
        this.clientEfnCases$ = this._store.pipe(select(selectEfnDataClientEfnCases));
        this.applicationResult$ = this._store.pipe(select(selectKcAppDataKcAppResult));
    }

    public get showAssistanceApps(): boolean {
        return this.currentUser?.hasAssistanceApps && !environment.appConfig?.disableApplicationsFeature;
    }

    ngOnInit(): void {
        this.currentUser$.subscribe(user => this.currentUser = user);
        this.currentClient$.subscribe(currentClient => this.currentClient = currentClient);
        this.currentClientPledgeList$.subscribe(currentClientPledgeList => this.currentClientPledgeList = currentClientPledgeList);
        this.clientEfnCases$.subscribe(efnCases => this.clientEfnCases = efnCases);
        this.applicationResult$.subscribe(applicationResult => this.applicationResult = applicationResult);
        this.getCurrentClient().pipe(
            mergeMap((client) => this._pledgeService.getCurrenClientPledgeList(client.id) && this.efnService.getEfnCasesByClientId(client.id)))
            .subscribe();
    }

    getCurrentClient(): Observable<Client | null> {
        if (this.currentClient) {
            return observableFrom([this.currentClient]);
        }
        return this._route.params.pipe(
            map(params => params.id),
            mergeMap((id) => this._clientService.getClientById(id)));
    }

    get isClientEdit(): boolean {
        return this._router.url.indexOf('/edit') !== -1;
    }

    ngOnDestroy(): void {
        this._clientService.setCurrentClientAction(null);
    }
}
