<h1 class="page-title">Application Counties</h1>
<section class="widget">
    <div class="widget-body">
        <div class="mt">

            <form novalidate [formGroup]="form">
                <div class="row">
                    <div class="col-md-6">
                        <input class="form-control m-b-05"
                               placeholder="Search by County Name"
                               type="text"
                               [formControl]="nameControl"/>
                    </div>

                    <div class="search-container">
                        <div class="col-md-6">
                            <button class="btn btn-primary btn-search" role="button" type="submit"
                                    (click)="filterCounties()">Search
                            </button>
                        </div>
                    </div>
                </div>
            </form>

            <table-dynamic id="datatable-table"
                           class="table table-striped table-hover"
                           [headers]="tableHeaders"
                           [currentPage]="currentPage"
                           [totalCount]="totalCount"
                           (headerClick)="sortCounties($event)"
                           (pageChanged)="setPage($event)"
                           (limitChange)="setLimit($event)">
                <tr *ngFor="let county of pagedListOfApplicationCounties?.items">
                    <td class="hidden-sm-down">
                        <a [routerLink]="['../manage-counties', county.id]"
                           (click)="setCurrentSelectedApplicationCounty(county)">
                            <strong>{{county.name}}</strong>
                        </a>
                    </td>
                    <td class="hidden-sm-down">{{ showActiveText(county.isActiveInternal) }}</td>
                    <td class="hidden-sm-down">{{ showActiveText(county.isActiveExternal) }}</td>
                    <td class="hidden-sm-down">{{ county.fundAdministrativePledgeTypeName }}</td>
                </tr>
            </table-dynamic>
        </div>
    </div>
</section>
