import {Component, Input} from '@angular/core';
import {Client} from '../../../../services/api.client';

@Component({
  selector: 'clientInfo',
  templateUrl: './ClientInfoComponent.html',
  styleUrls: ['./ClientInfoComponent.scss']
})

export class ClientInfoComponent {

  @Input()
  client: Client;

  @Input()
  agencyName: string;


  constructor() {
  }
}
