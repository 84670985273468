import {share} from 'rxjs/operators';
/**
 * Created by djacobsmeyer on 2/16/17.
 */
import {Injectable} from '@angular/core';
import {NotificationService} from './NotificationService';
import {
    CommitEfnCaseParameters, DeclineEfnCaseParameters,
    EfnCase,
    EfnCaseFile,
    EfnClient,
    EfnCommentsClient,
    EfnFileClient,
    InviteAgencyParams,
    PagedListOfEfnCase
} from './api.client';
import {EfnCaseStatusType} from './api.client';

import {Observable} from 'rxjs';
import {AgencyEfnCase} from './api.client';
import {EfnCaseComment} from './api.client';
import {Store} from '@ngrx/store';
import * as fromRoot from '../store';
import {
    setClientsEfnCases,
    setEfnCasesPaged,
    setFundedEfnCases,
    setOpenEfnCases,
    setSelectedEfnCase
} from '../store/actions/efn.actions';
import {setAgencyEfnCases} from '../store/actions/agency.actions';

@Injectable()
export class EfnService {

    constructor(private _efnApiService: EfnClient,
                private _efnCommentApiService: EfnCommentsClient,
                private _notificationService: NotificationService,
                private _efnFileApiService: EfnFileClient,
                private _store: Store<fromRoot.AppState>) {
    }

    addEfnCase(newCase: EfnCase): Observable<EfnCase> {
        const request = this._efnApiService.addEfnCase(newCase).pipe(share());
        request.subscribe({
            next: (result) => {
                this.setSelectedEfnCaseAction(result);
                this._notificationService.showSuccess('EFN Case successfully added!');
            }, error: () => {
                this._notificationService.showError('There was an error creating this EFN Case.');
            }
        });
        return request;
    }

    updateEfnCase(updatedCase: EfnCase): Observable<EfnCase> {
        const request = this._efnApiService.updateEfnCase(updatedCase).pipe(share());
        request.subscribe({
            next: (result) => {
                this.setSelectedEfnCaseAction(result);
                this._notificationService.showSuccess('EFN Case successfully updated!');
            }, error: () => {
                this._notificationService.showError('There was a problem updating this EFN Case.');
            }
        });
        return request;
    }

    getEfnCasesByClientId(id: string): Observable<EfnCase[]> {
        const request = this._efnApiService.getEfnCasesByClient(id).pipe(share());
        request.subscribe({
            next: (result) => {
                this.setClientsEfnCasesAction(result);
            }, error: () => {
                this._notificationService.showError('There was an error getting the Client\'s Cases.');
            }
        });
        return request;
    }

    getEfnCaseById(id: string): Observable<EfnCase> {
        const request = this._efnApiService.getEfnCase(id).pipe(share());
        request.subscribe({
            next: (result) => {
                this.setSelectedEfnCaseAction(result);
            }, error: () => {
                this._notificationService.showError('There was an error getting the client\'s case.');
            }
        });
        return request;
    }


    getEfnCasesPaged(searchValue: string,
                     agencyId: string,
                     status: EfnCaseStatusType,
                     sortName = '',
                     limit = 100,
                     offset = 0,
                     descending = false): Observable<PagedListOfEfnCase> {
        const request = this._efnApiService.getEfnCasesPaged(
            searchValue,
            agencyId,
            status,
            limit,
            offset,
            sortName,
            null,
            descending).pipe(share());
        request.subscribe({
            next: (result) => {
                this.setEfnCasesPaged(result);
            }, error: () => {
                this._notificationService.showError('There was a problem retrieving the EFN Cases');
            }
        });
        return request;
    }

    getOpenEfnCasesByAgencyId(agencyId: string): Observable<EfnCase[]> {
        const request = this._efnApiService.getOpenEfnCasesByAgencyId(agencyId).pipe(share());
        request.subscribe({
            next: (result) => {
                this.setOpenEfnCasesAction(result);
            }, error: () => {
                this._notificationService.showError('There was an error getting the open EFN Cases.');
            }
        });
        return request;
    }

    getFundedEfnCasesByAgencyId(agencyId: string): Observable<EfnCase[]> {
        const request = this._efnApiService.getFundedEfnCasesByAgencyId(agencyId).pipe(share());
        request.subscribe({
            next: (result) => {
                this.setFundedEfnCasesAction(result);
            }, error: () => {
                this._notificationService.showError('There was an error getting the funded EFN Cases.');
            }
        });
        return request;
    }

    getAgencyEfnCasesByEfnCaseId(efnCaseId: string): Observable<AgencyEfnCase[]> {
        const request = this._efnApiService.getAgencyEfnCasesByEfnCaseId(efnCaseId).pipe(share());
        request.subscribe({
            next: (result) => {
                this.setAgencyEfnCasesAction(result);
            }, error: () => {
                this._notificationService.showError('Error getting efn agencies.');
            }
        });
        return request;
    }

    inviteAgencies(agencyId: string, efnCaseId: string): Observable<void> {
        const inviteAgencyParams = InviteAgencyParams.fromJS({
            agencyId,
            efnCaseId
        });
        const request = this._efnApiService.inviteAgency(inviteAgencyParams).pipe(share());
        request.subscribe({
            next: () => {
            }, error: (err) => {
                this._notificationService.showError(err || 'Error submitting invite.');
            }
        });
        return request;
    }

    getEfnCommentsByEfnCaseId(id: string): Observable<EfnCaseComment[]> {
        const request = this._efnCommentApiService.getEfnCommentsByEfnCaseId(id).pipe(share());
        request.subscribe({
            next: () => {
            }, error: (err) => {
                this._notificationService.showError(err || 'Error getting EFN comments.');
            }
        });
        return request;
    }

    addEfnCaseComment(newCaseComment: EfnCaseComment): Observable<void> {
        const request = this._efnCommentApiService.addEfnCaseComment(newCaseComment).pipe(share());
        request.subscribe({
            next: () => {
            }, error: (err) => {
                this._notificationService.showError(err || 'Error getting EFN comments.');
            }
        });
        return request;
    }

    removeAgencyEfnCase(agencyEfnCaseId: string): Observable<void> {
        const request = this._efnApiService.removeAgencyEfn(agencyEfnCaseId).pipe(share());
        request.subscribe({
            next: () => {

            }, error: err => {
                this._notificationService.showError(err || 'There was a problem removing this agency.');
            }
        });
        return request;
    }

    commitEfnCase(commitParameters: CommitEfnCaseParameters): Observable<AgencyEfnCase> {
        return this._efnApiService.commitEfnCase(commitParameters).pipe(share());
    }

    declineEfnCase({efnCaseId, agencyId}: { efnCaseId: string, agencyId: string }): Observable<void> {
        const requestBody = DeclineEfnCaseParameters.fromJS({
           efnCaseId,
           agencyId
        });

        return this._efnApiService.declineEfnCase(requestBody);
    }

    getFilesByEfnCaseId(efnCaseId: string): Observable<EfnCaseFile[]> {
        return this._efnFileApiService.getEfnFileByEfnCaseId(efnCaseId);
    }

    checkIsCaseCountyActive(county: string): Observable<boolean> {
        return this._efnApiService.isCaseCountyActive(county);
    }

    setSelectedEfnCase(efnCase: EfnCase): void {
        this.setSelectedEfnCaseAction(efnCase);
    }

    // --------actions----------
    private setSelectedEfnCaseAction(selectedCase: EfnCase): void {
        this._store.dispatch(setSelectedEfnCase({payload: selectedCase}));
    }

    private setClientsEfnCasesAction(clientsCases: EfnCase[]): void {
        this._store.dispatch(setClientsEfnCases({payload: clientsCases}));
    }

    private setEfnCasesPaged(cases: PagedListOfEfnCase): void {
        this._store.dispatch(setEfnCasesPaged({payload: cases}));
    }

    private setOpenEfnCasesAction(openCases: EfnCase[]): void {
        this._store.dispatch(setOpenEfnCases({payload: openCases}));
    }

    private setFundedEfnCasesAction(fundedCases: EfnCase[]): void {
        this._store.dispatch(setFundedEfnCases({payload: fundedCases}));
    }

    private setAgencyEfnCasesAction(result: AgencyEfnCase[]): void {
        this._store.dispatch(setAgencyEfnCases({payload: result}));
    }
}
