import {Component, OnInit} from '@angular/core';
import {SecurityService} from '../../../services/SecurityService';
import {Router} from '@angular/router';
import {UntypedFormBuilder, UntypedFormGroup, UntypedFormControl, FormControl} from '@angular/forms';
import {Validators} from '../../../utilities/Validators';

@Component({
    templateUrl: './ResetPasswordComponent.html',
    styleUrls: ['./ResetPasswordComponent.scss'],
})
export class ResetPasswordComponent implements OnInit {

    errMessage = '';

    form: UntypedFormGroup;

    get password1(): FormControl {
        return this.form.get('password1') as FormControl;
    }

    get password2(): FormControl {
        return this.form.get('password2') as FormControl;
    }

    get token(): FormControl {
        return this.form.get('token') as FormControl;
    }

    constructor(private _securityService: SecurityService,
                private router: Router,
                private _formBuilder: UntypedFormBuilder) {
    }

    ngOnInit(): void {
        this.initForm();
        this.router.routerState
            .root
            .queryParams
            .subscribe(({token}) => {
                if (!token) {
                    this.router.navigate(['/login']).then();
                } else {
                    this.form.controls.token.setValue(token);
                }
            });
    }


    initForm(): void {
        this.form = this._formBuilder.group({
            password1: new FormControl('', Validators.compose([Validators.required, Validators.passwordComplexity])),
            password2: new FormControl('', Validators.required),
            token: new FormControl('', Validators.required),
        }, {validators: Validators.matchPassword('password1', 'password2')});
    }

    submit(callback?): void {
        const {password1, token} = this.form.value;
        this._securityService.resetPassword('', password1, token)
            .subscribe({
                next: (result) => {
                    if (result.success) {
                        // TODO: Show confirmation prior to going back to login
                        this.router.navigate(['/login']).then();
                    }
                },
                error: (err) => {
                    if (err.isSwaggerException) {
                        try {
                            this.errMessage = JSON.parse(err.response).message;
                        } catch (e) {
                        }
                    } else {
                        this.errMessage = err;
                    }
                }
            });
    }

}
