import {AssistanceApplicationStatus} from '../../enums/assistance-application-status';
import {Option} from './option';

export const INTERNAL_ASSISTANCE_APPLICATION_STATUS_OPTIONS: Option<AssistanceApplicationStatus>[] = [
    {
        label: 'Submitted',
        value: AssistanceApplicationStatus.Submitted,
    },
    {
        label: 'Under Review',
        value: AssistanceApplicationStatus.UnderReview,
    },
    {
        label: 'Approved',
        value: AssistanceApplicationStatus.Approved,
    },
    {
        label: 'Denied',
        value: AssistanceApplicationStatus.Denied,
    },
];

export const PUBLIC_ASSISTANCE_APPLICATION_STATUS_OPTIONS: Option<AssistanceApplicationStatus>[] = [
    {
        label: 'Submitted',
        value: AssistanceApplicationStatus.Submitted,
    },
    {
        label: 'Under Review',
        value: AssistanceApplicationStatus.UnderReview,
    },
    {
        label: 'Information Needed',
        value: AssistanceApplicationStatus.InfoNeeded,
    },
    {
        label: 'Approved',
        value: AssistanceApplicationStatus.Approved,
    },
    {
        label: 'Denied',
        value: AssistanceApplicationStatus.Denied,
    },
];
