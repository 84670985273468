export const environment = {
    appInsights: {instrumentationKey: '199b4db6-200f-4122-a17d-d43b7d82e8fe'},
    sessionTimeout: -1,
    clientId: '',
    appConfig: {
        apiBaseUrl: 'https://eaplatform-api-test.azurewebsites.net',
        authorityUrl: '',
        addressLookupUrl: '',
        addressLookupKey: '',
        disableApplicationsFeature: false
    },
    features: [],
    production: false
};
