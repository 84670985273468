import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NumbersOnlyDirective} from './directives/NumbersOnlyDirective';
import {CurrencyInputComponent} from './fields/CurrencyInputComponent';
import {LoadingProgressBarComponent} from './components/loading-progress-bar.component';

@NgModule({
    declarations: [
        CurrencyInputComponent,
        NumbersOnlyDirective,
        LoadingProgressBarComponent
    ],
    exports: [
        CurrencyInputComponent,
        NumbersOnlyDirective,
        NumbersOnlyDirective,
        LoadingProgressBarComponent
    ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        FormsModule,
    ]
})
export class SharedModule {
}
