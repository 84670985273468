import {Component} from '@angular/core';

@Component({
  selector: 'importantCustomerInformation',
  template: `
<legend><strong>Important Customer</strong> Information</legend>
<ul>
  <li>For customers to receive the monthly credits that will reduce their past due amount, they MUST pay the “Amount Due” on their bill by each due date. Paying after the due date for two (2) consecutive months will automatically remove them from the Program.</li>
  <li>Customer must remain current within two billing cycles to continue on the Program. <b>Customers that default on payments for two consecutive months will be removed from the Program and not be allowed back into the Program for 12 months.</b></li>
  <li>All Keeping Current Customers will be enrolled in Ameren Missouri’s Budget Billing Program.</li>
</ul>
`,
})

export class ImportantCustomerInformationComponent {
}
