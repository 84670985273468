import {Directive, ElementRef, HostListener, Output, EventEmitter, Input, OnDestroy, OnInit} from '@angular/core';
import * as _ from 'lodash';

@Directive({
  selector: '[outOfBounds]'
})
export class OutOfBoundsDirective implements OnInit, OnDestroy {

  @Output('outOfBounds')
  outOfBounds = new EventEmitter<HTMLElement>();

  _ignoredElements: HTMLElement[] = [];

  @Input('ignore')
  set ignore(elements) {
    this._ignoredElements = _.isArray(elements) ? elements : _.isObject(elements) ? [elements] : [];
  }

  @Input('delay')
  delay: number;
  skip: boolean;

  constructor(private _element: ElementRef) {
  }

  ngOnInit(): void {
    if (this.delay) {
      this.skip = true;
      setTimeout(() => this.skip = false, this.delay);
    }
  }

  @HostListener('document:click', ['$event.target'])
  outOfBoundsClick(target: HTMLElement): any {
    if (this.skip || this.shouldIgnore(target)) {
      return void 0; // do nothing
    }
    const element = this._element.nativeElement;
    if (element !== target && !element.contains(target)) { // clicked out of bounds
      this.outOfBounds.emit(target);
    }
  }

  shouldIgnore(target: HTMLElement): boolean {
    return this._ignoredElements.some((el) => el === target || el.contains(target));
  }

  ngOnDestroy(): void {
    this._ignoredElements = null;
    this._element = null;
    this.outOfBounds.unsubscribe();
  }
}
