import {Component, Input} from '@angular/core';
import {KcApplicationResult} from '../../../services/api.client';


@Component({
  selector: 'advancedClientEnrollmentInfo',
  templateUrl: './AdvancedClientEnrollmentInfoComponent.html'
})

export class AdvancedClientEnrollmentInfoComponent {
  @Input()
  application: KcApplicationResult;
}
