import {
    AfterContentChecked,
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    OnInit,
    ViewChild
} from '@angular/core';
import {
    AssistanceApplication,
    CompanyCategoryThresholdViewModel,
    AssistanceApplicationClient,
    Client,
    User, UserType
} from '../../../../../../services/api.client';
import {forkJoin, Observable} from 'rxjs';
import {PromptComponent} from '../../../../../shared/dialogs/PromptComponent';
import {ActivatedRoute, Router} from '@angular/router';
import {select, Store} from '@ngrx/store';
import * as fromRoot from '../../../../../../store';
import {selectSecurityStateDataCurrentUser} from '../../../../../../store/selectors/security.selectors';
import {selectClientDataCurrentClient} from '../../../../../../store/selectors/client.selectors';
import {takeUntil} from 'rxjs/operators';
import * as moment from 'moment/moment';
import {AssistanceApplicationFormComponent} from '../assistance-application-form/assistance-application-form.component';
import {Disposable} from '../../../../../../utilities/Disposable';
import {CompanyCategoryService} from '../../../../../../services/companyCategory.service';
import {setCompanyCategoryThresholds} from '../../../../../../store/actions/companyCategory.actions';
import {AssistanceApplicationStatus} from '../../../../../../shared/enums/assistance-application-status';
import {FundAdministrativeAgencyName} from '../../../../../../shared/constants/FundConstants';

@Component({
    templateUrl: './create-assistance-application.component.html',
    styleUrls: ['./create-assistance-application.component.scss'],
})
export class CreateAssistanceApplicationComponent extends Disposable implements OnInit, AfterViewInit, AfterContentChecked {
    public administrativeFundName = FundAdministrativeAgencyName;
    client: Client;
    client$: Observable<Client>;

    user: User;
    user$: Observable<User>;

    @ViewChild(AssistanceApplicationFormComponent)
    assistanceApplicationForm: AssistanceApplicationFormComponent;

    @ViewChild(PromptComponent)
    clientInactiveModal: PromptComponent;

    clientCountyInactiveMessage: string;
    private categoryThresholds: CompanyCategoryThresholdViewModel[];

    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private _store: Store<fromRoot.AppState>,
        private assistanceApplicationClient: AssistanceApplicationClient,
        private cdref: ChangeDetectorRef,
        private _assistanceApplicationCategoryThresholdService: CompanyCategoryService
    ) {
        super();
        this.user$ = this._store.pipe(select(selectSecurityStateDataCurrentUser));
        this.client$ = this._store.pipe(select(selectClientDataCurrentClient));
    }

    public get formIsInvalid(): boolean {
        return this.assistanceApplicationForm?.form?.invalid;
    }

    ngOnInit(): void {
        this.client$
            .pipe(takeUntil(this.$destroyed))
            .subscribe(client => this.client = client);
        this.user$
            .pipe(takeUntil(this.$destroyed))
            .subscribe(user => {
                this.user = user;

                if (!user ||
                    !this.client ||
                    user.userType === UserType.Viewer) {
                    this.router.navigate(['app', 'client', this.client?.id, 'assistance-applications']).then();
                }
            });

        forkJoin([
            this.assistanceApplicationClient.isAssistanceApplicationCountyActive(this.client?.county),
            this._assistanceApplicationCategoryThresholdService.getCategoryThresholds()
        ])
            .pipe(takeUntil(this.$destroyed))
            .subscribe({
                next: ([isActive, categoryThresholds]) => {
                    this.categoryThresholds = categoryThresholds;
                    this._store.dispatch(setCompanyCategoryThresholds({payload: categoryThresholds}));
                    if (!isActive) {
                        this.assistanceApplicationForm.form.disable();
                        this.clientCountyInactiveMessage = `The Client County ${this.client?.county} is inactive and is not currently accepting new cases.`;
                        this.clientInactiveModal.open().subscribe(() => this.router.navigate([`app/client/${this.client.id}/efn_cases`]));
                    }
                }
            });
    }

    ngAfterViewInit(): void {

        setTimeout(() => {
            this.assistanceApplicationForm.populateClient(this.client);
            this.assistanceApplicationForm.populateUser(this.user);
            this.assistanceApplicationForm.statusControl.setValue(AssistanceApplicationStatus.UnderReview);
            this.assistanceApplicationForm.statusControl.disable();
        });

    }

    public hasIncome(income: number|undefined|null): boolean {
        return income !== null && income !== undefined && income >= 0;
    }

    async submit(): Promise<void> {
        await this.assistanceApplicationForm.createCase();
    }

    ngAfterContentChecked(): void {
        this.cdref.detectChanges();
    }
}
