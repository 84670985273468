import {ActivatedRouteSnapshot} from '@angular/router';
import * as moment from 'moment';
import {Moment} from 'moment';
import * as numeral from 'numeral';
import {SHORT_DATE_FORMAT} from '../constants';
import {UserType} from '../services/api.client';

export function getMessageFromException(err: any): string {
    let msg: any = err;
    if (err.isSwaggerException) {
        try {
            msg = JSON.parse(err.response).message;
            return msg;
        } catch (e) {
        }
    }
    return err.message || '' + err;
}

export function extractEnumNames(enums: any): { key: string, value: number }[] {
  return Object.keys(enums)
    .filter((key) => isNaN(parseInt(key, null)))
    .map((key) => ({key, value: enums[key]}));
}

export function splitCamelCase(value: string, capitalizeFirst: boolean = true): string {
  if (!value) {
    return value;
  }
  let result = value                         // "ToGetYourGEDInTimeASongAboutThe26ABCsIsOfTheEssenceButAPersonalIDCardForUser456ContainingABC26TimesIsNotAsEasyAs123"
    .replace(/([a-z])([A-Z][a-z])/g, '$1 $2')           // "To Get YourGEDIn TimeASong About The26ABCs IsOf The Essence ButAPersonalIDCard For User456ContainingABC26Times IsNot AsEasy As123"
    .replace(/([A-Z][a-z])([A-Z])/g, '$1 $2')           // "To Get YourGEDIn TimeASong About The26ABCs Is Of The Essence ButAPersonalIDCard For User456ContainingABC26Times Is Not As Easy As123"
    .replace(/([a-z])([A-Z]+[a-z])/g, '$1 $2')          // "To Get Your GEDIn Time ASong About The26ABCs Is Of The Essence But APersonal IDCard For User456ContainingABC26Times Is Not As Easy As123"
    .replace(/([A-Z]+)([A-Z][a-z][a-z])/g, '$1 $2')     // "To Get Your GEDIn Time A Song About The26ABCs Is Of The Essence But A Personal ID Card For User456ContainingABC26Times Is Not As Easy As123"
    .replace(/([a-z]+)([A-Z0-9]+)/g, '$1 $2')           // "To Get Your GEDIn Time A Song About The 26ABCs Is Of The Essence But A Personal ID Card For User 456Containing ABC26Times Is Not As Easy As 123"
    // Note: the next regex includes a special case to exclude plurals of acronyms, e.g. "ABCs"
    .replace(/([A-Z]+)([A-Z][a-rt-z][a-z]*)/g, '$1 $2') // "To Get Your GED In Time A Song About The 26ABCs Is Of The Essence But A Personal ID Card For User 456Containing ABC26Times Is Not As Easy As 123"
    .replace(/([0-9])([A-Z][a-z]+)/g, '$1 $2')          // "To Get Your GED In Time A Song About The 26ABCs Is Of The Essence But A Personal ID Card For User 456 Containing ABC26 Times Is Not As Easy As 123"
    .replace(/([A-Z]+)([0-9]+)/g, '$1 $2')              // "To Get Your GED In Time A Song About The 26ABCs Is Of The Essence But A Personal ID Card For User 456 Containing ABC 26 Times Is Not As Easy As 123"
    .replace(/([0-9]+)([A-Z]+)/g, '$1 $2')              // "To Get Your GED In Time A Song About The 26 ABCs Is Of The Essence But A Personal ID Card For User 456 Containing ABC 26 Times Is Not As Easy As 123"
    .trim();

  // capitalize the first letter
  return capitalizeFirst ? result.charAt(0).toUpperCase() + result.slice(1) : result;

}

export function formatCurrency(value: number, format: string = '$0,0.00'): string {
  return numeral(value).format(format);
}

export function toNumber(value: any) {
  let parse = parseFloat(value);
  return Number.isNaN(parse) ? 0 : parse;

}

export function formatDecimal(value: number, format: string = '0,0.00'): string {
  if (!value && value !== 0) {
    return '';
  }

  return new numeral(value).format(format);
}

export function formatPercent(value: number, digits: number = 2): string {
  if (!value && value !== 0) {
    return '';
  }
  return numeral(value).format('0.00%');
}

export function getRouteParam(route: ActivatedRouteSnapshot, key: string) {
  let id: string = null;
  while (id == null) {
    id = route.params[key] || route.queryParams[key];
    route = route.parent;
  }
  return id;
}

export function formatDate(value: Date | Moment, format: string = SHORT_DATE_FORMAT): string {
  if (!value) {
    return '';
  }

  return moment(value).format(format);
}

export function normalize(value: any) {
  return value === 'null' ? null : value;
}

export function formatPhone(phone: string) {
  if (!phone) {
    return phone;
  }

  var value = phone.toString().trim().replace(/^\+/, '');

  if (value.match(/[^0-9]/)) {
    return phone;
  }

  var country, city, number;

  switch (value.length) {
    case 10: // +1PPP####### -> C (PPP) ###-####
      country = 1;
      city = value.slice(0, 3);
      number = value.slice(3);
      break;

    case 11: // +CPPP####### -> CCC (PP) ###-####
      country = value[0];
      city = value.slice(1, 4);
      number = value.slice(4);
      break;

    case 12: // +CCCPP####### -> CCC (PP) ###-####
      country = value.slice(0, 3);
      city = value.slice(3, 5);
      number = value.slice(5);
      break;

    default:
      return phone;
  }

  if (country == 1) {
    country = '';
  }

  number = number.slice(0, 3) + '-' + number.slice(3);

  return (country + ' (' + city + ') ' + number).trim();
}

export function isBlank(obj: any): boolean {
  return obj === undefined || obj === null || obj === 'null'; // ng2 bug
}

export function toBoolean(obj: any): boolean {
  return !!obj;
}
