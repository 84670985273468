import {createAction, props} from '@ngrx/store';
import {PagedListOfUser, User} from '../../services/api.client';

export const setPagedUsers = createAction(
    '[USER] SET_PAGED_USERS',
    props<{ payload: PagedListOfUser }>());

export const setCurrentSelectedUser = createAction(
    '[USER] SET_CURRENT_SELECTEDUSER',
    props<{ payload: User }>());
