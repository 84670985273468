import {createReducer, on} from '@ngrx/store';
import {setAssistanceApplicationsPaged} from '../actions/assistanceApplication.actions';
import {IAssistanceApplicationData} from '../IAssistanceApplicationData';


export const initialState: IAssistanceApplicationData = {
    assistanceApplicationsPaged: null,
};

export const assistanceApplicationReducer = createReducer(
    initialState,
    on(setAssistanceApplicationsPaged, (state, {payload}) => ({
        ...state,
        assistanceApplicationsPaged: payload
    }))
);
