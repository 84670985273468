import {Component, Input, ChangeDetectionStrategy, EventEmitter, Output} from '@angular/core';
import {ITableHeader} from '../../../shared/table/TableComponent';

@Component({
  selector: 'reportTable',
  templateUrl: './ReportTableComponent.html',
  styleUrls: ['./ReportTableComponent.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReportTableComponent {
  @Input('headers')
  tableHeaders: ITableHeader[];
  @Input('rows')
  rows: any[][];
  @Input('isManual')
  isManual: any;

  selectedPledgeIds = [];
  @Output() selectedChange: EventEmitter<any> = new EventEmitter();

  toggle(e, item): void  {
    const index = this.selectedPledgeIds.indexOf(item);
    if (index === -1) {
      this.selectedPledgeIds.push(item);
    } else {
      this.selectedPledgeIds.splice(index, 1);
    }
    this.selectedChange.emit(this.selectedPledgeIds);
  }
}
