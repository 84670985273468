<div class="container">
    <h1 class="page-title">Allocation Overview</h1>
    <section class="widget">
        <div class="widget-body">
            <div class="mt">
                <form class="form-horizontal" [formGroup]="form" novalidate>
                    <div class="row form-group">
                        <label class="col-md-3"><strong>Allocation:</strong></label>
                        <div class="col-md-8">
                            <div class="row form-field-spacing"
                                 [class.has-danger]="!pledgeTypeId.valid && pledgeTypeId.touched">
                                <span class="m-t-05 required-label col-md-3">Fund Type</span>
                                <select [formControl]="pledgeTypeId" class="form-control col-md-8 custom-select"
                                        type="text"
                                        formControlName="pledgeTypeId">
                                    <option value="">Choose a Fund Type</option>
                                    <option *ngFor="let item of activePledgeTypes" [value]="item.id">{{item.name}}
                                    </option>
                                </select>
                            </div>
                            <div class="row form-field-spacing" [class.has-danger]="amountAllocatedErrorMessage">
                                <span class="m-t-05 required-label col-md-3">Allocation Amount ($)</span>
                                <input [formControl]="amount" numbersOnly class="form-control col-md-8" type="text"
                                       formControlName="amount"
                                       placeholder="Allocation Amount"
                                       (blur)="validateOriginalAmountAllocated()"/>
                                <span *ngIf="amountAllocatedErrorMessage" class="m-t-05 required-label col-md-3"></span>
                                <div class="form-control-feedback" id="amountAllocatedErrorMessage"
                                     *ngIf="amountAllocatedErrorMessage"
                                     [innerHTML]="amountAllocatedErrorMessage"></div>
                            </div>
                            <div class="row form-field-spacing">
                                <span class="m-t-05 col-md-3">Admin Percent  (enter as whole number ie. 10%)</span>
                                <input [formControl]="adminPercent" numbersOnly [decimal]="false"
                                       class="form-control col-md-8" type="text"
                                       formControlName="adminPercent" placeholder="Admin Percent">
                            </div>
                            <div class="row form-field-spacing">
                                <span class="m-t-05 col-md-3">From Company</span>
                                <input class="form-control col-md-8"
                                       type="text"
                                       [formControl]="companyName"
                                       placeholder="Select a Company"
                                       (click)="showCompanyModal()"
                                       (keydown)="showCompanyModal()"/>
                            </div>
                            <div class="row form-field-spacing"
                                 [class.has-danger]="!agencyId.valid && agencyId.touched">
                                <span class="m-t-05 required-label col-md-3">Allocation To Agency</span>
                                <input class="form-control col-md-8"
                                       type="text"
                                       [formControl]="agencyName"
                                       placeholder="Select an Agency"
                                       (click)="showAgencyModal()"
                                       (keydown)="showAgencyModal()"/>
                            </div>

                            <div class="row form-field-spacing">
                                <span class="m-t-05 col-md-3">Reason</span>
                                <textarea [formControl]="reason" class="form-control col-md-8"
                                          formControlName="reason"></textarea>
                            </div>
                        </div>
                    </div>
                    <!--submit-->
                    <div class="row form-group">
                        <div class="col-md-3">
                        </div>
                        <div class="col-md-8 btn-form">
                            <cancel-button class="p-l-1 pull-right" [route]="'/app/allocations'"></cancel-button>
                            <!--save-->
                            <button [disable]="isValidSave()"
                                    type="submit"
                                    [autoDisable]="submit"
                                    class="btn btn-primary">Save Allocation
                            </button>
                            <!--delete-->
                            <button type="button"
                                    class="btn btn-inverse"
                                    *ngIf="currentAllocation?.id"
                                    (click)="showPromptModal()">Delete Allocation
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <agency-box (onAgencySelected)="onAgencySelected($event)"></agency-box>
        <company-box (onCompanySelected)="onCompanySelected($event)"></company-box>
        <prompt-dialog
                [titleText]="'Delete Allocation?'"
                [promptText]="'Are you sure you want to delete allocation?'">
        </prompt-dialog>
    </section>
</div>

