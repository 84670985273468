import {Component, Input} from '@angular/core';
import {AssistanceApplicationFile} from '../../../../../../../services/api.client';
import {environment} from '../../../../../../../../environments/environment';
import {SystemService} from '../../../../../../../services/SystemService';
import {AssistanceApplicationFileTypeOptions} from '../../../../../../../shared/constants/assistance-application-file-type-options';

@Component({
  selector: 'app-assistance-application-files',
  templateUrl: './assistance-application-files.component.html',
  styleUrls: ['./assistance-application-files.component.scss']
})
export class AssistanceApplicationFilesComponent {
  @Input() receivedForms: AssistanceApplicationFile[] = [];
  apiUrl: string = environment.appConfig.apiBaseUrl;

  constructor(public system: SystemService) {
  }

  getFileType(fileType): string{
    return AssistanceApplicationFileTypeOptions.find(x => x.value === fileType).label;
  }
}
