<modal #modal [size]="size">
    <modal-header *ngIf="titleText || showTitle" [show-close]="false">
        <h3 *ngIf="titleText" class="modal-title text-xs-center"><b>{{ titleText }}</b></h3>
        <ng-content select="[title]"></ng-content>
    </modal-header>
    <modal-body>
        <div *ngIf="promptText" class="text-xs-center">{{ promptText }}</div>
        <ng-content select="[body]"></ng-content>
    </modal-body>
    <modal-footer [show-default-buttons]="false">
        <div *ngIf="!overrideFooter">
            <button class="btn btn-primary" (click)="okClicked()"
                    [disabled]="okButtonDisabled">{{ okButtonText }}</button>
            <button class="btn btn-default" (click)="cancelClicked()"
                    [disabled]="cancelButtonDisabled">{{ cancelButtonText }}</button>
            <button *ngIf="allowCustomButton" class="btn btn-danger" (click)="onCustomButton()">{{ customButtonText }}</button>
        </div>
        <ng-content select="[footer]"></ng-content>
    </modal-footer>
</modal>
