import {Component, Input, Output, EventEmitter, AfterViewInit} from '@angular/core';
import {DropdownValue} from '../../../utilities/DropdownComponent';
import {Observable, Subject} from 'rxjs';

import {delay, distinctUntilChanged, takeUntil} from 'rxjs/operators';
import {Disposable} from '../../../utilities/Disposable';

export interface ITableHeader {
    sortKey?: string;
    ascending?: boolean;
    text?: string;
    class?: string;
    isCheckbox?: boolean;
}

@Component({
    selector: 'table-dynamic',
    templateUrl: './TableComponent.html',
    styleUrls: ['./TableComponent.scss'],
})
export class TableComponent extends Disposable implements AfterViewInit {
    isChecked: boolean;

    set request(request: Observable<any>) {
        setTimeout(() => this.state = 'loading', 0);
        request
            .pipe(delay(0))
            .subscribe({
                next: (data) => {
                    const items = data.items || data;
                    if (items.length === 0) {
                        this.state = 'empty';
                    } else {
                        this.state = null;
                    }
                }, error: () => {
                    this.state = 'failed';
                }
            });
    }

    @Input()
    set headers(headers: ITableHeader[]) {
        this._headers = headers;
        this.activeHeader = headers.find(header => header.hasOwnProperty('ascending'));
    }

    get headers(): any {
        return this._headers;
    }

    // ---------------------------HEADER-------------------------------
    activeHeader: ITableHeader;
    state: string;
    _headers: ITableHeader[];

    @Output('headerClick')
    headerClick = new EventEmitter<ITableHeader>();

    @Output()
    selectAllCheckChanged = new EventEmitter<boolean>();

    @Input() allRowsSelected: Subject<boolean> = new Subject<boolean>();

    // -------------------------PAGINATION--------------------------------

    @Input('initialLimitValue')
    initialLimitValue = '10';

    limit: number = parseInt(this.initialLimitValue, null);

    // inputs
    @Input('currentPage')
    currentPage: number;
    @Input('totalCount')
    totalCount: number;
    // outputs
    @Output('limitChange')
    limitChange = new EventEmitter<number>();

    @Output('pageChanged')
    pageChanged = new EventEmitter<number>();

    rows: DropdownValue[] = [
        new DropdownValue('10', '10'),
        new DropdownValue('25', '25'),
        new DropdownValue('50', '50'),
        new DropdownValue('100', '100')
    ];

    @Input('showPagination')
    showPagination = true;

    // user agent
    maxSize = 5;

    onHeaderClick(header: ITableHeader): void {
        if (!header.sortKey) {
            return;
        }
        header.ascending = !header.ascending;
        this.activeHeader = header;
        this.headerClick.emit(this.activeHeader);
    }

    setLimit(limit: number): void {// # of rows per page
        this.limit = limit;
        this.initialLimitValue = limit.toString();
        this.limitChange.emit(limit);
    }

    setPage(page: number): void {
        this.pageChanged.emit(page);
    }

    public onSelectAllChanged(event: any): void {
        this.selectAllCheckChanged.emit(event);
    }

    ngAfterViewInit(): void {
        this.allRowsSelected.pipe(takeUntil(this.$destroyed), distinctUntilChanged()).subscribe((value) => {
            this.isChecked = value;
        });
    }
}
