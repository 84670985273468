<div class="row">
  <div class="col-xs-12">
    <legend><strong>Notes & Enrollment</strong> History</legend>
  </div>
  <div class="col-xs-12">
    <div class="form-group row" *ngIf="system.canAddNote()">
      <label for="enter-new-note" class="col-sm-5 control-label">Enter New Note:</label>
      <div class="col-sm-7">
        <input type="text" id="enter-new-note" class="form-control" [formControl]="note">
      </div>
      <div class="col-xs-12 m-t-1">
        <button type="submit" class="btn btn-primary pull-right" (click)="submitAddNote()">Add Note</button>
      </div>
    </div>
  </div>
</div>
<table class="table table-bordered" *ngIf="notes && notes.length">
  <thead>
  <tr>
    <td>Date</td>
    <td>Type</td>
    <td>Details</td>
    <td>User</td>
    <td *ngIf="system.canDeleteNote()">Delete</td>
  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let note of notes">
    <td>{{ note.noteDate | moment: 'L' }}</td>
    <td>{{ note.noteType }}</td>
    <td>{{ note.details }}</td>
    <td>{{ note.user }}</td>
    <td *ngIf="system.canDeleteNote()" (click)="submitDeleteNote(note.noteId)">
      <button class="btn btn-danger">Delete</button>
    </td>
  </tr>
  </tbody>
</table>
