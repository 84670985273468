<h1 class="page-title">Browse LookUps</h1>
<section class="widget">
    <div class="widget-body">
        <div class="mt">
            <div class="row">
                <div class="col-md-12">
                    <section class="widget bg-primary text-white">
                        <form class="form-horizontal" [formGroup]="form" novalidate>
                            <div class="widget-body clearfix">
                                <div class="row flex-nowrap">
                                    <div class="col-md-6">
                                        <div class="row form-field-spacing"
                                             [class.has-danger]="category?.valid && category?.touched">
                                            <span class="col-md-3">Category</span>
                                            <select [formControl]="category" class="form-control col-md-8 custom-select"
                                                    type="text">
                                                <option value="null">Choose a Category</option>
                                                <option *ngFor="let item of categories" [value]="item.value">
                                                    {{splitCamelCase(item.key)}}
                                                </option>
                                            </select>
                                            <div class="col-md-3">
                                            </div>
                                            <div [hidden]="!(category?.valid && category?.touched)"
                                                 class="alert alert-danger col-md-8">
                                                This field is required
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="row form-field-spacing"
                                             [class.has-danger]="!sortOrder?.valid && sortOrder?.touched">
                                            <span class="col-md-3">Sort Order</span>
                                            <input [formControl]="sortOrder" class="form-control col-md-8" type="text">
                                            <div class="col-md-3">
                                            </div>
                                            <div [hidden]="!(!sortOrder?.valid && sortOrder?.touched)"
                                                 class="alert alert-danger col-md-8">
                                                This field is required
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row flex-nowrap">
                                    <div class="col-md-6">
                                        <div class="row form-field-spacing"
                                             [class.has-danger]="!key?.valid && key?.touched">
                                            <span class="col-md-3">Key</span>
                                            <input [formControl]="key" class="form-control col-md-8" type="text">
                                            <div class="col-md-3">
                                            </div>
                                            <div [hidden]="!(!key?.valid && key?.touched)"
                                                 class="alert alert-danger col-md-8">
                                                This field is required
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="row form-field-spacing"
                                             [class.has-danger]="!lookupValue?.valid && lookupValue?.touched">
                                            <span class="col-md-3">Value</span>
                                            <input [formControl]="lookupValue" class="form-control col-md-8"
                                                   type="text">
                                            <div class="col-md-3">
                                            </div>
                                            <div [hidden]="!(!lookupValue?.valid && lookupValue?.touched)"
                                                 class="alert alert-danger col-md-8">
                                                This field is required
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row flex-nowrap">
                                    <div class="col-md-6">
                                        <div class="row form-field-spacing">
                                            <span class="col-md-3">Description</span>
                                            <textarea [formControl]="description" class="form-control col-md-8"
                                                      type="text">
                                            </textarea>
                                        </div>
                                    </div>
                                    <div class="col-md-6 flex-xs-middle">
                                        <button class="btn btn-secondary" type="button" name="button"
                                                (click)="submit()">Add Look Up Entry
                                        </button>
                                        <button class="btn btn-secondary" [disabled]="!currentLookUp" type="button"
                                                name="button" (click)="delete()">Delete Look Up Entry
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </section>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <div class="row">
                        <label class="col-xs-2">Category:</label>
                        <dropdown class="drop-down-inline"
                                  [promptValue]="'Categories'"
                                  [values]="lookUpCategoryDropdown"
                                  (select)="selectedCategory($event)"></dropdown>
                    </div>
                </div>
                <div class="col-md"></div>
            </div>
            <table-dynamic id="datatable-table"
                           class="table table-striped table-hover"
                           [headers]="tableHeaders"
                           [currentPage]="currentPage"
                           [totalCount]="totalCount"
                           (headerClick)="sortItems($event)"
                           (pageChanged)="setPage($event)"
                           (limitChange)="setLimit($event)">
                <tr *ngFor="let lookUp of pagedList?.items">
                    <td class="hidden-sm-down">{{formatCategory(lookUp.category)}}</td>
                    <td>
                        <a><span (click)="setCurrentSelectedLookUp(lookUp); false"
                                 class="fw-semi-bold">{{lookUp.key}}</span></a>
                    </td>
                    <td>{{lookUp.lookupValue}}</td>
                    <td class="hidden-sm-down">{{lookUp.description}}</td>
                    <td class="hidden-sm-down">{{lookUp.sortOrder}}</td>
                </tr>
            </table-dynamic>
        </div>
    </div>
</section>
