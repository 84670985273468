<div class="container">
    <h1 class="page-title">Application for Assistance for {{administrativeFundName}}</h1>
    <div class="alert alert-danger m-t-05" *ngIf="!hasIncome(client?.individualMonthlyIncome)">The client record must have an individual monthly income to create an application. Please edit the client record and fill out their individual monthly income before proceeding.</div>
    <section class="widget">
        <app-assistance-application-form [client]="client" [user]="user" [isNewApplication]="true" [defaultOpenDocumentUpload]="true" #f>
            <div class="alert alert-danger m-t-05" *ngIf="!hasIncome(client?.individualMonthlyIncome)">The client record must have an individual monthly income to create an application. Please edit the client record and fill out their individual monthly income before proceeding.</div>

            <button  class="btn btn-primary pull-right" (click)="submit()">Create Case</button>
        </app-assistance-application-form>
    </section>
</div>
<prompt-dialog
        [titleText]="'Client County Inactive'"
        promptText="{{clientCountyInactiveMessage}}">
</prompt-dialog>
