import {HTTP_INTERCEPTORS, HttpClient} from '@angular/common/http';
import {ErrorHandler} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {environment} from '../environments/environment';
import {ResolveKcPledgeType} from './guards/ResolveKcPledgeType';
import {ResolveKcPrograms} from './guards/ResolveKcPrograms';
import {
    ActivityAuditClient,
    AgencyClient,
    AllocationClient,
    AllocationImportClient,
    AllocationReportClient,
    AmerenFtpClient,
    ApiBaseUrl,
    ApplicationCountyClient,
    CompanyCategoryClient,
    AssistanceApplicationClient,
    AssistanceApplicationCommentClient, AssistanceApplicationFileClient,
    AssistanceApplicationStateAuditClient,
    ClientClient,
    CompanyClient,
    CountyClient,
    CountySpendingReportClient,
    DemographicReportClient,
    EfnClient,
    EfnCommentsClient,
    EfnFileClient,
    EfsAssistanceClient,
    EfsReportClient,
    FederalPovertyLevelClient,
    FundTypeClient,
    KcApplicationClient,
    KeepingCurrentReportClient,
    LookUpClient,
    MailUsersClient,
    PaymentProcessingClient, PledgeBulkPaymentReportClient,
    PledgeClient,
    PledgeReportClient,
    PledgeTypeBalanceClient,
    PledgeTypeClient,
    PublicClient,
    SecurityClient,
    SystemClient,
    UserClient,
    BannerClient
} from './services/api.client';
import {FileDownloadService} from './services/FileDownloadService';
import {AuthGuard} from './utilities/AuthGuard';
import {LoginComponent} from './components/shared/login/LoginComponent';
import {ForgotPasswordComponent} from './components/shared/forgotpassword/ForgotPasswordComponent';
import {ResetPasswordComponent} from './components/shared/resetpassword/ResetPasswordComponent';
import {ErrorComponent} from './components/shared/error/ErrorComponent';
import {ShellComponent} from './components/shared/shell/ShellComponent';
import {AboutComponent} from './components/shared/about/AboutComponent';
import {Routes} from '@angular/router';
import {AgencyComponent} from './components/energyassistance/agency/AgencyComponent';
import {AgencyListComponent} from './components/energyassistance/agency/AgencyListComponent';
import {AllocationComponent} from './components/energyassistance/allocation/AllocationComponent';
import {AllocationListComponent} from './components/energyassistance/allocation/AllocationListComponent';
import {ApplicationListComponent} from './components/energyassistance/application/ApplicationListComponent';
import {ClientApplicationsComponent} from './components/energyassistance/client/application/ClientApplicationsComponent';
import {ClientCreateApplicationComponent} from './components/energyassistance/client/application/ClientCreateApplicationComponent';
import {ClientEditApplicationComponent} from './components/energyassistance/client/application/ClientEditApplicationComponent';
import {ClientVerificationComponent} from './components/energyassistance/client/application/step1/ClientVerificationComponent';
import {ClientEnrollmentComponent} from './components/energyassistance/client/application/step2/ClientEnrollmentComponent';
import {RequestClientEnrollmentComponent} from './components/energyassistance/client/application/step3/RequestClientEnrollmentComponent';
import {ClientCreateComponent} from './components/energyassistance/client/ClientCreateComponent';
import {ClientsComponent} from './components/energyassistance/client/ClientsComponent';
import {ClientTabsComponent} from './components/energyassistance/client/ClientTabsComponent';
import {ClientAssistanceComponent} from './components/energyassistance/client/manage/ClientAssistanceComponent';
import {ClientComponent} from './components/energyassistance/client/manage/ClientComponent';
import {ClientEditComponent} from './components/energyassistance/client/manage/ClientEditComponent';
import {ClientEfnComponent} from './components/energyassistance/client/manage/ClientEfnComponent';
import {ClientEfsComponent} from './components/energyassistance/client/manage/ClientEfsComponent';
import {ClientEfsConfirmationComponent} from './components/energyassistance/client/manage/ClientEfsConfirmationComponent';
import {CreateEfnCaseComponent} from './components/energyassistance/client/manage/efncase/createcase/CreateEfnCaseComponent';
import {EditEfnCaseComponent} from './components/energyassistance/client/manage/efncase/editcase/EditEfnCaseComponent';
import {CompanyComponent} from './components/energyassistance/company/CompanyComponent';
import {CompanyListComponent} from './components/energyassistance/company/CompanyListComponent';
import {CountySpendingComponent} from './components/energyassistance/reports/county-spending/county-spending.component';
import {DashboardComponent} from './components/energyassistance/dashboard/DashboardComponent';
import {EfnListComponent} from './components/energyassistance/efn/EfnListComponent';
import {LookUpComponent} from './components/energyassistance/lookup/LookUpComponent';
import {
    CompanyMailingLabelComponent
} from './components/energyassistance/paymentprocessing/companymailinglabel/CompanyMailingLabelComponent';
import {ConfirmedToPendingComponent} from './components/energyassistance/paymentprocessing/confirmedtopending/ConfirmedToPendingComponent';
import {
    ManualPendingToPaidComponent
} from './components/energyassistance/paymentprocessing/manualpendingtopaid/ManualPendingToPaidComponent';
import {PaymentProcessingComponent} from './components/energyassistance/paymentprocessing/PaymentProcessingComponent';
import {
    PendingPledgesSummaryComponent
} from './components/energyassistance/paymentprocessing/pendingpledgessummary/PendingPledgesSummaryComponent';
import {PendingToPaidComponent} from './components/energyassistance/paymentprocessing/pendingtopaid/PendingToPaidComponent';
import {PledgeComponent} from './components/energyassistance/pledge/PledgeComponent';
import {PledgeConfirmationComponent} from './components/energyassistance/pledge/PledgeConfirmationComponent';
import {PledgeListComponent} from './components/energyassistance/pledge/PledgeListComponent';
import {PledgeTypeComponent} from './components/energyassistance/pledgetype/PledgeTypeComponent';
import {PledgeTypeListComponent} from './components/energyassistance/pledgetype/PledgeTypeListComponent';
import {PledgeByCountyComponent} from './components/energyassistance/reports/demographic/bycounty/PledgeByCountyComponent';
import {PledgeByZipCodeReportComponent} from './components/energyassistance/reports/demographic/byzipcode/PledgeByZipCodeReportComponent';
import {PledgeDetailReportComponent} from './components/energyassistance/reports/demographic/detail/PledgeDetailReportComponent';
import {PledgeDemographicComponent} from './components/energyassistance/reports/demographic/PledgeDemographicComponent';
import {PledgeSummaryReportComponent} from './components/energyassistance/reports/demographic/summary/PledgeSummaryReportComponent';
import {EfsMainReportComponent} from './components/energyassistance/reports/efs/EfsMainReportComponent';
import {EfsReportComponent} from './components/energyassistance/reports/efs/efsreport/EfsReportComponent';
import {
    ApprovedEnrollmentsReportComponent
} from './components/energyassistance/reports/keepingcurrent/approvedenrollments/ApprovedEnrollmentsReportComponent';
import {OrphansReportComponent} from './components/energyassistance/reports/keepingcurrent/confirmationorphans/OrphansReportComponent';
import {
    EnrollmentStatusReportComponent
} from './components/energyassistance/reports/keepingcurrent/enrollmentstatus/EnrollmentStatusReportComponent';
import {KeepingCurrentReportComponent} from './components/energyassistance/reports/keepingcurrent/KeepingCurrentReportComponent';
import {
    MissedPaymentsReportComponent
} from './components/energyassistance/reports/keepingcurrent/missedpayments/MissedPaymentsReportComponent';
import {AgencyDetailReportComponent} from './components/energyassistance/reports/pledges/agencypledgedetail/AgencyDetailReportComponent';
import {AgencySummaryReportComponent} from './components/energyassistance/reports/pledges/agencypledgesummary/AgencySummaryReportComponent';
import {
    AllocationAndBalancesComponent
} from './components/energyassistance/reports/pledges/allocationandbalances/AllocationAndBalancesComponent';
import {
    PendingPledgesDetailComponent
} from './components/energyassistance/reports/pledges/pendingpledgesdetail/PendingPledgesDetailComponent';
import {PledgesReportComponent} from './components/energyassistance/reports/pledges/PledgesReportComponent';
import {SupportAgreementComponent} from './components/energyassistance/support/agreements/SupportAgreementComponent';
import {SupportComponent} from './components/energyassistance/support/SupportComponent';
import {UserCreateComponent} from './components/energyassistance/user/usercreate/UserCreateComponent';
import {UserEmailComponent} from './components/energyassistance/user/UserEmailComponent';
import {UserListComponent} from './components/energyassistance/user/UserListComponent';
import {UwdaPledgeListComponent} from './components/energyassistance/uwdapledge/UwdaPledgeListComponent';
import {CanCreateKcApplicationGuard} from './guards/CanCreateKcApplicationGuard';
import {CanEditUserGuard} from './guards/CanEditUserGuard';
import {HasAgreementDate} from './guards/HasAgreementDate';
import {HasKcFeatureGuard} from './guards/HasKcFeatureGuard';
import {IsClientVerifiedGuard} from './guards/IsClientVerifiedGuard';
import {ResolveClient} from './guards/ResolveClient';
import {ResolveClientKcApplication} from './guards/ResolveClientKcApplication';
import {ResolveClientKcApplicationResult} from './guards/ResolveClientKcApplicationResult';
import {ResolveClientKcApplications} from './guards/ResolveClientKcApplications';
import {ResolveEfnCase} from './guards/ResolveEfnCase';
import {ResolvePledge} from './guards/ResolvePledge';
import {AgencyService} from './services/AgencyService';
import {AllocationService} from './services/AllocationService';
import {ClientService} from './services/ClientService';
import {CompanyService} from './services/CompanyService';
import {ConfigService} from './services/ConfigService';
import {CountyService} from './services/CountyService';
import {DemographicDemographicReportService} from './services/DemographicReportService';
import {EfnService} from './services/EfnService';
import {EfsReportService} from './services/EfsReportService';
import {EfsService} from './services/EfsService';
import {FederalPovertyLevelService} from './services/FederalPovertyLevelService';
import {KcApplicationService} from './services/KcApplicationService';
import {KcReportService} from './services/KcReportService';
import {LocalStorageService} from './services/LocalStorageService';
import {LookUpService} from './services/LookUpService';
import {MonitorService} from './services/MonitorService';
import {NotificationService} from './services/NotificationService';
import {PaymentProcessingService} from './services/PaymentProcessingService';
import {PledgeService} from './services/PledgeService';
import {PledgeTypeService} from './services/PledgeTypeService';
import {ReportService} from './services/ReportService';
import {RequestService} from './services/RequestService';
import {SecurityService} from './services/SecurityService';
import {SystemService} from './services/SystemService';
import {UserService} from './services/UserService';
import {CustomErrorHandler} from './utilities/ErrorHandler';
import {ApiTokenInterceptor} from './interceptors/apiToken.interceptor';
import {AssistanceApplicationService} from './components/public/application/assistance-application.service';
import {ApplicationCountyService} from './services/applicationCounty.service';
import {
    ApplicationCountyListComponent
} from './components/energyassistance/application-county/application-county-list/application-county-list.component';
import {
    ManageApplicationCountyComponent
} from './components/energyassistance/application-county/manage-application-county/manage-application-county.component';
import {ActivityAuditService} from './services/activityAudit.service';
import {
    CreateAssistanceApplicationComponent
} from './components/energyassistance/client/manage/assistance-application/create-assistance-application/create-assistance-application.component';
import {
    EditAssistanceApplicationComponent
} from './components/energyassistance/client/manage/assistance-application/edit-assistance-application/edit-assistance-application.component';
import {AssistanceApplicationStateAuditService} from './services/assistanceApplicationStateAudit.service';
import {ClientAssistanceApplicationComponent} from './components/energyassistance/client/manage/ClientAssistanceApplicationComponent';
import {CompanyCategoryService} from './services/companyCategory.service';
import {CurrencyPipe} from '@angular/common';
import {
    AssistanceApplicationsListComponent
} from './components/energyassistance/assistance-applications/AssistanceApplicationsListComponent';
import {CountySpendingReportService} from './services/countySpendingReport.service';
import {FundTypeService} from './services/fundType.service';
import {AuditReportComponent} from './components/energyassistance/audit-report/audit-report.component';
import {
    PledgeGenerateBulkPaymentReportComponent
} from './components/energyassistance/reports/pledge-bulk-payment/pledge-bulk-payment-report/pledge-generate-bulk-payment-report.component';
import {PledgeBulkPaymentComponent} from './components/energyassistance/reports/pledge-bulk-payment/pledge-bulk-payment.component';
import {FundAdministrativeAgencyName} from './shared/constants/FundConstants';
import {AuditProgressReportComponent} from './components/energyassistance/reports/audit-progress-report/audit-progress-report.component';
import {
    PledgeBulkPaymentSelectBatchComponent
} from './components/energyassistance/reports/pledge-bulk-payment/pledge-bulk-payment-select-batch/pledge-bulk-payment-select-batch.component';
import {
    BudgetBillingReportComponent
} from './components/energyassistance/reports/keepingcurrent/budgetbilling/BudgetBillingReportComponent';
import {CompletionReportComponent} from './components/energyassistance/reports/keepingcurrent/completion/CompletionReportComponent';

const fundAdministrativeName = FundAdministrativeAgencyName;
export const routes: Routes = [
    {path: '', redirectTo: 'login', pathMatch: 'full'},
    {path: 'index.html', redirectTo: 'login', pathMatch: 'full'},
    {path: 'login', component: LoginComponent, data: {title: 'Login'}},
    {path: 'error', component: ErrorComponent, data: {title: 'Error'}},
    {path: 'about', component: AboutComponent, data: {title: 'About'}},
    {path: 'forgotpassword', component: ForgotPasswordComponent, data: {title: 'Forgot Password'}},
    {path: 'resetpassword', component: ResetPasswordComponent, data: {title: 'Reset Password'}},
    {
        path: 'app', component: ShellComponent, canActivate: [AuthGuard, HasAgreementDate], canDeactivate: [HasAgreementDate],
        children: [
            {path: '', redirectTo: 'clients', pathMatch: 'full'},
            {path: 'dashboard', component: DashboardComponent, data: {title: 'Dashboard'}},
            {path: 'clients', component: ClientsComponent, data: {title: 'Browse Clients'}},
            {path: 'client/add', component: ClientCreateComponent, data: {title: 'Create Client'}},
            {
                path: 'client/:id',
                component: ClientTabsComponent,
                canActivate: [ResolveClient],
                canActivateChild: [IsClientVerifiedGuard],
                children: [
                    {path: '', component: ClientComponent},
                    {path: 'edit', component: ClientEditComponent},
                    {path: 'assistance', component: ClientAssistanceComponent},
                    {path: 'efs', component: ClientEfsComponent},
                    {path: 'efn_cases', component: ClientEfnComponent},
                    {path: 'assistance-applications', component: ClientAssistanceApplicationComponent},
                    {
                        path: 'assistance-applications/add',
                        component: CreateAssistanceApplicationComponent,
                        data: {title: `${fundAdministrativeName} - Create Application`}
                    },
                    {
                        path: 'assistance-applications/:assistanceApplicationId',
                        component: EditAssistanceApplicationComponent,
                        data: {title: `${fundAdministrativeName} - Edit Assistance Application`}
                    },
                    {path: 'efn_cases/add', component: CreateEfnCaseComponent, data: {title: 'EFN - Create Case'}},
                    {
                        path: 'efn_cases/:efnCaseId',
                        component: EditEfnCaseComponent,
                        resolve: {efnCase: ResolveEfnCase},
                        data: {title: 'EFN - Edit Case'}
                    },
                    {
                        path: '',
                        canActivate: [HasKcFeatureGuard],
                        resolve: {kcPledgeTypes: ResolveKcPledgeType, kcPrograms: ResolveKcPrograms},
                        children: [
                            {
                                path: 'applications',
                                component: ClientApplicationsComponent,
                                resolve: {applications: ResolveClientKcApplications}
                            },
                            {
                                path: 'application',
                                children: [
                                    // new application
                                    {
                                        path: '',
                                        component: ClientCreateApplicationComponent,
                                        canActivate: [CanCreateKcApplicationGuard],
                                        children: [
                                            {path: '', redirectTo: 'verify_client', pathMatch: 'full'},
                                            // step 1
                                            {path: 'verify_client', component: ClientVerificationComponent},
                                            // step 2
                                            {path: 'client_enrollment', component: ClientEnrollmentComponent},
                                            // step 3
                                            {path: 'request_enrollment', component: RequestClientEnrollmentComponent},
                                        ]
                                    },
                                    // existing application
                                    {
                                        path: ':applicationId',
                                        component: ClientEditApplicationComponent,
                                        canActivate: [],
                                        resolve: {application: ResolveClientKcApplicationResult, kcPrograms: ResolveKcPrograms}
                                    },
                                    {
                                        path: ':applicationId/request_enrollment',
                                        component: RequestClientEnrollmentComponent,
                                        resolve: {application: ResolveClientKcApplication}
                                    },
                                ]
                            }
                        ]
                    },
                    {path: 'pledge/add', component: PledgeComponent, data: {title: 'Manage Pledge'}},
                ],
                data: {title: 'Manage Client'}
            },
            {path: 'pledges', component: PledgeListComponent, data: {title: 'Browse Pledges'}},
            {
                path: 'pledge/:id',
                component: PledgeComponent,
                data: {title: 'Manage Pledge'},
                resolve: {pledge: ResolvePledge}
            },
            {path: 'efn_cases', component: EfnListComponent, data: {title: 'Browse EF Network Cases'}},
            {
                path: 'assistance_applications',
                component: AssistanceApplicationsListComponent,
                data: {title: 'Browse Assistance Applications'}
            },
            {path: 'uwdapledges', component: UwdaPledgeListComponent, data: {title: 'Browse Uwda Pledges'}},
            {path: 'applications', component: ApplicationListComponent, data: {title: 'Browse Applications'}},
            {path: 'agencies', component: AgencyListComponent, data: {title: 'Browse Agencies'}},
            {path: 'agency/:id', component: AgencyComponent, data: {title: 'Manage Agency'}},
            {path: 'allocations', component: AllocationListComponent, data: {title: 'Browse Allocations'}},
            {path: 'allocation/:id', component: AllocationComponent, data: {title: 'Manage Allocation'}},
            {path: 'companies', component: CompanyListComponent, data: {title: 'Browse Companies'}},
            {path: 'company/:id', component: CompanyComponent, data: {title: 'Manage Company'}},
            {path: 'pledgetypes', component: PledgeTypeListComponent, data: {title: 'Browse Pledge Types'}},
            {path: 'pledgetype/:id', component: PledgeTypeComponent, data: {title: 'Manage Pledge Type'}},
            // users
            {path: 'users', component: UserListComponent, data: {title: 'Browse Users'}},
            {path: 'users/email', component: UserEmailComponent, data: {title: 'Email Users'}},
            {
                path: 'user/:id',
                component: UserCreateComponent,
                data: {title: 'Manage User'},
                canActivate: [CanEditUserGuard]
            },
            // reports
            {
                path: 'reports/pledges', component: PledgesReportComponent,
                children: [
                    {path: '', redirectTo: 'agency_detail', pathMatch: 'full'},
                    {path: 'agency_detail', component: AgencyDetailReportComponent},
                    {path: 'agency_summary', component: AgencySummaryReportComponent},
                    {path: 'allocation_and_balances', component: AllocationAndBalancesComponent},
                    {path: 'pledges_detail', component: PendingPledgesDetailComponent},
                ]
            },
            {path: 'pledge-bulk-payment-report', component: PledgeBulkPaymentComponent,
                children: [
                    {path: '', redirectTo: 'create-batch', pathMatch: 'full'},
                    {path: 'create-batch', component: PledgeGenerateBulkPaymentReportComponent},
                    {path: 'select-batch', component: PledgeBulkPaymentSelectBatchComponent},
                ]
            },
            {
                path: 'reports/demographic', component: PledgeDemographicComponent,
                children: [
                    {path: '', redirectTo: 'pledge_detail', pathMatch: 'full'},
                    {path: 'pledge_detail', component: PledgeDetailReportComponent},
                    {path: 'pledge_summary', component: PledgeSummaryReportComponent},
                    {path: 'pledge_by_zip_code', component: PledgeByZipCodeReportComponent},
                    {path: 'pledge_by_counties', component: PledgeByCountyComponent},
                ]
            },
            {
                path: 'reports/keeping_current', component: KeepingCurrentReportComponent,
                children: [
                    {path: '', redirectTo: 'enrollment_status', pathMatch: 'full'},
                    {path: 'enrollment_status', component: EnrollmentStatusReportComponent},
                    {path: 'missed_payments', component: MissedPaymentsReportComponent},
                    {path: 'confirmation_orphans', component: OrphansReportComponent},
                    {path: 'download_approved_enrollments', component: ApprovedEnrollmentsReportComponent},
                    {path: 'budget_billing', component: BudgetBillingReportComponent},
                    {path: 'completion', component: CompletionReportComponent},
                ]
            },
            {
                path: 'reports/efs', component: EfsMainReportComponent,
                children: [
                    {path: '', redirectTo: 'efs_report', pathMatch: 'full'},
                    {path: 'efs_report', component: EfsReportComponent},
                ]
            },
            { path: 'reports/county-spending', component: CountySpendingComponent },
            { path: 'reports/audit', component: AuditReportComponent },
            { path: 'reports/audit-progress', component: AuditProgressReportComponent },
            {
                path: 'payment_processing', component: PaymentProcessingComponent, data: {title: 'Process Payments'},
                children: [
                    {path: '', redirectTo: 'confirmed_pending', pathMatch: 'full'},
                    {path: 'confirmed_pending', component: ConfirmedToPendingComponent},
                    {path: 'pending_paid', component: PendingToPaidComponent},
                    {path: 'manual_pending_paid', component: ManualPendingToPaidComponent},
                    {path: 'pledges_summary', component: PendingPledgesSummaryComponent},
                    {path: 'company_mailing', component: CompanyMailingLabelComponent},
                ]
            },
            {path: 'lookups', component: LookUpComponent, data: {title: 'Browse Look Ups'}},
            {path: 'support', component: SupportComponent, data: {title: 'Support'}},
            {
                path: 'support/agreements',
                component: SupportAgreementComponent,
                data: {title: 'Agreements'},
                canDeactivate: [HasAgreementDate]
            },
            {path: 'counties', component: ApplicationCountyListComponent, data: {title: 'Application Counties'}},
            {path: 'manage-counties/:id', component: ManageApplicationCountyComponent, data: {title: 'Manage Application Counties'}}
        ]
    },
    {
        path: 'apply',
        loadChildren: () => import('./components/public/layout/layout.module').then((module) => module.LayoutModule)
    },
    {path: '**', redirectTo: 'error'}
];

// const config: IResponsiveConfig = {
//   breakPoints: {
//     xs: {max: 543},
//     sm: {min: 544, max: 767},
//     md: {min: 768, max: 991},
//     lg: {min: 992, max: 1199},
//     xl: {min: 1200}
//   },
//   debounceTime: 100 // allow to debounce checking timer
// };

// export function getResponsiveConfig() {
//   return new ResponsiveConfig(config);
// }

export const APP_BASE_PROVIDERS = [
    RequestService,
    // {provide: ResponsiveConfig, useFactory: getResponsiveConfig},
    {provide: ApiBaseUrl, useValue: environment.appConfig.apiBaseUrl},
    HttpClient,
    AgencyClient,
    AllocationClient,
    AllocationImportClient,
    AllocationReportClient,
    AmerenFtpClient,
    ApplicationCountyClient,
    CompanyCategoryClient,
    AssistanceApplicationClient,
    AssistanceApplicationCommentClient,
    AssistanceApplicationStateAuditClient,
    BannerClient,
    ClientClient,
    CompanyClient,
    CountyClient,
    CountySpendingReportClient,
    DemographicReportClient,
    EfnClient,
    EfnCommentsClient,
    EfnFileClient,
    EfsAssistanceClient,
    EfsReportClient,
    FederalPovertyLevelClient,
    KcApplicationClient,
    KeepingCurrentReportClient,
    LookUpClient,
    MailUsersClient,
    PaymentProcessingClient,
    PledgeClient,
    PledgeReportClient,
    PledgeTypeBalanceClient,
    PledgeTypeClient,
    SecurityClient,
    SystemClient,
    UserClient,
    CanEditUserGuard,
    FileDownloadService,
    IsClientVerifiedGuard,
    SystemService,
    ResolveClientKcApplications,
    NotificationService,
    SecurityService,
    AuthGuard,
    LocalStorageService,
    ResolveClient,
    ResolveClientKcApplication,
    ConfigService,
    EfsService,
    EfnService,
    ResolvePledge,
    HasKcFeatureGuard,
    HasAgreementDate,
    ClientService,
    CountyService,
    DemographicDemographicReportService,
    ResolveClientKcApplicationResult,
    PledgeService,
    PledgeConfirmationComponent,
    ClientEfsConfirmationComponent,
    ActivityAuditClient,
    CompanyService,
    PledgeTypeService,
    UserService,
    CanCreateKcApplicationGuard,
    AgencyService,
    AllocationService,
    EfnService,
    ResolveEfnCase,
    ResolveKcPledgeType,
    ResolveKcPrograms,
    LookUpService,
    FederalPovertyLevelService,
    KcApplicationService,
    KcReportService,
    PublicClient,
    FundTypeClient,
    AssistanceApplicationService,
    EfsReportService,
    ReportService,
    PaymentProcessingService,
    Title,
    MonitorService,
    ApplicationCountyService,
    AssistanceApplicationStateAuditService,
    ActivityAuditService,
    CompanyCategoryService,
    AssistanceApplicationFileClient,
    CountySpendingComponent,
    CountySpendingReportService,
    FundTypeService,
    CurrencyPipe,
    PledgeBulkPaymentReportClient,
    {provide: ErrorHandler, useClass: CustomErrorHandler},
    {
        provide: HTTP_INTERCEPTORS,
        useClass: ApiTokenInterceptor,
        multi: true
    }
];

