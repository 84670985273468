<modal #modal>
    <div id="section-to-print">
        <modal-header [show-close]="false">
            <h3 class="modal-title"><b>{{ titleText }}</b></h3>
            <ng-content select="[title]"></ng-content>
        </modal-header>
        <modal-body>
            <div *ngIf="promptText">{{ promptText }}</div>
            <h3 title style="color: black; text-align: center" class="m-b-2">{{headerText}}</h3>
            <ng-content select="[body]"></ng-content>
        </modal-body>
        <modal-footer [show-default-buttons]="false">
            <button [disabled]="disableButtons" class="btn btn-primary" (click)="printClicked()"
                    *ngIf="isReprint">{{ printButtonText }}</button>
            <button [disabled]="disableButtons" class="btn btn-primary" (click)="confirmPrintClicked()"
                    *ngIf="!isReprint">{{ confirmPrintButtonText }}</button>
            <button [disabled]="disableButtons" class="btn btn-primary" (click)="confirmClicked()"
                    *ngIf="!isReprint">{{ confirmButtonText }}</button>
            <button [disabled]="disableButtons" class="btn btn-default" (click)="cancelClicked()"
                    *ngIf="!isReprint">{{ cancelButtonText }}</button>
        </modal-footer>
    </div>
</modal>
