import {Directive, HostListener, Input} from '@angular/core';

@Directive({
  selector: '[numbersOnly]'
})
export class NumbersOnlyDirective {

  @Input()
  decimal = true;

  @HostListener('keydown', ['$event', '$event.target.value'])
  onKeyDown(event: KeyboardEvent, value: string): void {
    if (['Tab', 'Backspace', 'ArrowLeft', 'ArrowRight'].some((key) => event.key === key)) {
      return;
    }
    if (this.decimal && event.key === '.') {
      if (value.length === 0 || value.indexOf('.') !== -1) {
        event.preventDefault();
      }
    } else if (event.key === ' ' || (Number.isNaN(+event.key) || Number.isNaN(+value))) {
      event.preventDefault();
    }
  }

  constructor() {
  }
}
