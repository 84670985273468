import {Component, OnInit} from '@angular/core';
import {KcStatus} from '../../../services/api.client';
import {Observable} from 'rxjs';
import {select, Store} from '@ngrx/store';
import * as fromRoot from '../../../store';
import {selectSecurityStateDataSystemDataKcStatuses} from '../../../store/selectors/security.selectors';

@Component({
  selector: '[keepingCurrentStatus]',
  templateUrl: './KeepingCurrentStatusesDropdown.html'
})
export class KeepingCurrentStatuses implements OnInit {
  keepingCurrentStatus: KcStatus[];
  keepingCurrentStatus$: Observable<KcStatus[]>;

  constructor(private _store: Store<fromRoot.AppState>) {
    this.keepingCurrentStatus$ = this._store.pipe(select(selectSecurityStateDataSystemDataKcStatuses));
  }

  ngOnInit(): void {
    this.keepingCurrentStatus$.subscribe(keepingCurrentStatus => this.keepingCurrentStatus = keepingCurrentStatus);
  }
}
