<clientInfo [client]="client" [agencyName]="user?.agency?.agencyName"></clientInfo>
<div class="row">
  <div class="col-xs-12">
    <form clientEnrollmentForm class="form-horizontal" [application]="incompleteNewApplication || {}" [kcPrograms]="kcPrograms"
          (onSubmit)="onSubmit($event)" (onContinue)="next()">
      <div footer style="float: left;">
        <button type="button" class="btn btn-primary" (click)="back()">Back</button>
        <!-- strong check because the value "undefined" will show buttons on page load -->
        <!--<button *ngIf="incompleteNewApplication?.id && system.loggedInUserCanContinueEnrollment(form?.kcStatus)" type="submit" class="btn btn-primary pull-right" (click)="submitContinue">Continue</button>-->
      </div>
    </form>
  </div>
</div>
