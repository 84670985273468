import {share} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {EfsReport, EfsReportClient} from './api.client';
import {Store} from '@ngrx/store';
import * as fromRoot from '../store';
import {Observable} from 'rxjs';
import {retrieveEfsReportFailed, retrieveEfsReportSuccess} from '../store/actions/report.actions';

@Injectable()
export class EfsReportService {

  constructor(
      private _store: Store<fromRoot.AppState>,
      private _efsReportAPI: EfsReportClient) {
  }

  buildEfsReport(pledgeTypeId: string, agencyId: string): Observable<EfsReport[]> {
    const request = this._efsReportAPI.buildEfsReport(pledgeTypeId, agencyId).pipe(share());
    request.subscribe({ next: (data) => {
      this.retrieveEfsReportSuccessAction(data);
    }, error: (error) => {
      this.retrieveEfsReportFailedAction(error);
    }});
    return request;
  }


  // ---------------------security actions------------------
  // Efs
  private retrieveEfsReportSuccessAction(data: EfsReport[]): void {
    this._store.dispatch(retrieveEfsReportSuccess({ payload: data}));
  }

  private retrieveEfsReportFailedAction(error: EfsReport[]): void {
    this._store.dispatch(retrieveEfsReportFailed({ payload: error}));
  }
}
