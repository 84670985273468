<form hasDanger class="row">
  <div class="col-sm">
    <input type="text" class="form-control" [formControl]="comment" placeholder="Enter Comment">
    <button type="submit" class="btn btn-primary btn-comment" [autoDisable]="addComment">Add Comment</button>
  </div>
</form>

<div style="position: relative; min-height: 200px; margin-top: 1rem;">
  <h3 *ngIf="comments === null" class="text-center ab-center">
    Fetching comments...
  </h3>
  <h3 *ngIf="comments?.length === 0" class="text-center ab-center">
    No comments listed.
  </h3>
  <table *ngIf="comments?.length" class="table table-striped table-hover">
    <thead>
    <tr>
      <td>Agency Name</td>
      <td>Full Name</td>
      <td>Comment</td>
      <td>Entered Date</td>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let c of comments">
      <td>{{ c.agencyName }}</td>
      <td>{{ c.userFullName }}</td>
      <td>{{ c.comment }}</td>
      <td>{{ c.enteredDate | moment:'LLL' }}</td>
    </tr>
    </tbody>
  </table>
</div>


