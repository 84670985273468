import {Component, forwardRef, Input, OnInit, ViewChild} from '@angular/core';
import {UntypedFormControl} from '@angular/forms';
import {ICustomFile} from 'file-input-accessor';
import {from, of} from 'rxjs';
import {filter, mergeMap, last} from 'rxjs/operators';
import {AutoDisableParent} from '../../../../../../../directives/AutoDisableDirective';
import {EfnCase, EfnCaseFile, EfnFileClient} from '../../../../../../../services/api.client';
import {EfnService} from '../../../../../../../services/EfnService';
import {SystemService} from '../../../../../../../services/SystemService';
import {Validators} from '../../../../../../../utilities/Validators';
import {PromptComponent} from '../../../../../../shared/dialogs/PromptComponent';
import {environment} from "../../../../../../../../environments/environment";

@Component({
  selector: 'efnFilesTab',
  templateUrl: './EfnFilesTabComponent.html',
  styleUrls: ['./EfnFilesTabComponent.scss'],
  providers: [{provide: AutoDisableParent, useExisting: forwardRef(() => EfnFilesTabComponent)}],

})
export class EfnFilesTabComponent implements OnInit {

  file = new UntypedFormControl(null, Validators.required);
  files: EfnCaseFile[] = null;

  _efnCase: EfnCase;
  uploadStatus: string;
  apiUrl: string = environment.appConfig.apiBaseUrl;

  fileInput = new UntypedFormControl();

    @ViewChild(PromptComponent)
    promptModal: PromptComponent;

  @Input()
  set efnCase(efnCase: EfnCase) {
    this._efnCase = efnCase;
    if (this._efnCase && !this.files) {
      this.reloadFiles();
    }
  }

  get efnCase(): EfnCase {
    return this._efnCase;
  }

  constructor(private efnService: EfnService,
              private _efnFileApi: EfnFileClient,
              public system: SystemService) {
  }

    ngOnInit(): void {
        this.fileInput.valueChanges.pipe(
            filter((files) => !files || !files.length)
        ).subscribe(() => {
            this.uploadStatus = '';
        });
        this.fileInput.valueChanges.pipe(
            filter((files) => files && !!files.length),
            mergeMap((files: ICustomFile[]) => {
                return from(files).pipe(
                    mergeMap((file) => {
                        this.onBeforeUpload(file);
                        return this._efnFileApi.uploadEfnFile(this.efnCase.id, "v1", {
                            data: file,
                            fileName: file.name
                        });
                    }),
                    last(),
                    mergeMap(() => {
                        this.files = null;
                        return this.efnService.getFilesByEfnCaseId(this._efnCase.id);
                    })
                );
            })
        ).subscribe((efnFiles: EfnCaseFile[]) => {
            this.uploadStatus = '';
            this.files = efnFiles;
        });
    }

    onBeforeUpload(uploadingFile: ICustomFile): void {
        this.uploadStatus = 'Uploading file...' + uploadingFile.name;
    }

    reloadFiles(): void {
        this.files = null;
        this.efnService.getFilesByEfnCaseId(this._efnCase.id)
            .subscribe((files: EfnCaseFile[]) => {
                this.files = files;
            });
    }

    deleteFile(fileId: string): boolean {
      this.promptModal.open().pipe(
          mergeMap((okClicked) => {
              if (okClicked) {
                  return this._efnFileApi.deleteEfnFile(fileId).pipe(
                      mergeMap(() => this._efnFileApi.getEfnFileByEfnCaseId(this._efnCase.id))
                  );
              }
              return of(this.files);
          })
      ).subscribe((files: EfnCaseFile[]) => {
          this.files = files;
      });

      return false;
    }

}

