import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {
    AssistanceApplicationClient,
    PagedListOfAuditReportTableRowViewModel
} from '../../../services/api.client';
import {AuditReportFilters, AuditReportFiltersComponent} from './audit-report-filters/audit-report-filters.component';
import * as moment from 'moment';
import {FileDownloadService} from '../../../services/FileDownloadService';
import {AuditReportUrls} from '../../../api/AuditReportUrls';

@Component({
    selector: 'app-audit-report',
    templateUrl: './audit-report.component.html',
    styleUrls: ['./audit-report.component.scss']
})
export class AuditReportComponent implements OnInit, AfterViewInit {
    @ViewChild(AuditReportFiltersComponent) filtersComponent: AuditReportFiltersComponent | undefined;

    private filters: AuditReportFilters | undefined;
    public filtersChanged = false;

    private pageIndex = 1;
    private limit = 10;
    private offset = 0;

    public pagedData: PagedListOfAuditReportTableRowViewModel | undefined;

    private initialDataFetchCompleted = false;
    public fetchingData = false;


    constructor(private assistanceApplicationClient: AssistanceApplicationClient, private fileDownloadService: FileDownloadService) {
    }

    public ngOnInit(): void {
    }

    public ngAfterViewInit(): void {
        this.filtersChanged = false;
    }

    private fetchData(successCallback: () => void = () => {}): void {
        this.fetchingData = true;
        this.assistanceApplicationClient.getAuditReportTableRows(
            this.filters.isPublic,
            this.filters.agencyIds,
            this.filters.userIds,
            this.filters.fundPledgeTypeId,
            this.filters.enteredDateStart ? moment(this.filters.enteredDateStart) : null,
            this.filters.enteredDateEnd ? moment(this.filters.enteredDateEnd) : null,
            this.filters.totalRequestedMin,
            this.filters.totalRequestedMax,
            this.filters.auditStatus,
            this.limit,
            this.offset
        ).subscribe((data) => {
            this.pagedData = data;
            this.fetchingData = false;
            successCallback();
        });
    }

    public onSearchClick(): void {
        this.fetchData(() => this.filtersChanged = false);
    }

    public onExportClick(): void {
        const filters: any = this.filters;
        const url = this.fileDownloadService.buildUrl(AuditReportUrls.DOWNLOAD_AUDIT_REPORT, filters);
        window.open(url, '_blank');
    }

    public onFiltersChange(filters: AuditReportFilters): void {
        this.filters = filters;

        if (!this.initialDataFetchCompleted) {
            this.fetchData();
            this.initialDataFetchCompleted = true;
        }

        this.filtersChanged = true;
    }

    public onPageChange(pageIndex: number): void {
        this.pageIndex = pageIndex;
        this.offset = (this.pageIndex - 1) * this.limit;
        this.fetchData();
    }

    public onLimitChange(limit: number): void {
        this.limit = limit;
        this.offset = (this.pageIndex - 1) * this.limit;
        this.fetchData();
    }

    public onResetFiltersClick(): void {
        this.filtersComponent.resetFilters();
        this.fetchData(() => this.filtersChanged = true);
    }
}
