import {createAction, props} from '@ngrx/store';
import {PagedListOfActivityAuditViewModel} from '../../services/api.client';


export const setActivityAuditIsLoading = createAction('[Activity Audit] ACTIVITY_AUDIT_IS_LOADING');

export const setActivityAuditLoaded = createAction(
    '[Activity Audit] ACTIVITY_AUDIT_IS_LOADED',
    props<{ payload: PagedListOfActivityAuditViewModel }>()
);
