import {map} from 'rxjs/operators';
import {Component, OnInit, ViewChild} from '@angular/core';
import {ITableHeader} from '../../../../shared/table/TableComponent';
import {KeepingCurrentReportFormComponent} from '../form/KeepingCurrentReportFormComponent';
import {Observable} from 'rxjs';
import {EnrollmentReport} from '../../../../../services/api.client';
import {formatCurrency, formatPercent, splitCamelCase, formatDate} from '../../../../../utilities/Util';
import {KcPaySourceType} from '../../../../../services/api.client';
import {DATE_TIME_FORMAT} from '../../../../../constants';
import {WeatherizationStatusesType} from '../../../../../services/api.client';
import {select, Store} from '@ngrx/store';
import * as fromRoot from '../../../../../store';
import {selectReportDataEnrollmentReport} from '../../../../../store/selectors/report.selectors';

@Component({
    templateUrl: './EnrollmentStatusReportComponent.html',
})
export class EnrollmentStatusReportComponent implements OnInit {

    tableHeaders: ITableHeader[] = [
        {text: 'Account Number'},
        {text: 'First Name'},
        {text: 'Last Name'},
        {text: 'SSN'},
        {text: 'Poverty Level'},
        {text: 'Elderly'},
        {text: 'Disabled'},
        {text: 'Child Under 5'},
        {text: 'Agency'},
        {text: 'AgencyCssCode'},
        {text: 'Program'},
        {text: 'Status'},
        {text: 'Original Arrears Owed'},
        {text: 'UpFront Amount Paid'},
        {text: 'UpFront Pay Source'},
        {text: 'Program Monthly Credit'},
        {text: 'Arrears Monthly Credit'},
        {text: 'Total Monthly Credit'},
        {text: 'Budget Bill Amount'},
        {text: 'Customer Monthly Payment'},
        {text: 'Grand Total Pledged'},
        {text: 'Total Overall Credits Paid'},
        {text: 'Total Program Credits Paid'},
        {text: 'Total Arrears Credits Paid'},
        {text: 'Arrears Amount Remaining'},
        {text: 'Total # Program Installments'},
        {text: 'Total # Arrears Installments'},
        {text: 'Total # Months Enrolled'},
        {text: '# Program Credits Remaining'},
        {text: '# Arrears Credits Remaining'},
        {text: 'Third Party Agreeable'},
        {text: 'Liheap Received'},
        {text: 'Liheap Requested'},
        {text: 'Weatherization Received'},
        {text: 'Weatherization Requested'},
        {text: 'Weatherization Status'},
        {text: 'Approval Confirmed'},
        {text: 'Initial Bill Date'},
        {text: 'Arrears Expiration'},
        {text: 'Program Expiration'},
        {text: 'Edit Date'},
    ];

    @ViewChild('form')
    form: KeepingCurrentReportFormComponent;

    report$: Observable<EnrollmentReport[]>;
    rows: any;

    constructor(private _store: Store<fromRoot.AppState>) {
        this.report$ = this._store.pipe(select(selectReportDataEnrollmentReport));
    }

    ngOnInit(): void {
        this.rows = this.report$
            .exists().pipe(
                map((report: EnrollmentReport[]) => report.map(r => [
                    r.accountNumber,
                    r.firstName,
                    r.lastName,
                    '***-**-' + r.ssn,
                    formatPercent(r.povertyLevelDisplay),
                    r.elderly ? 'YES' : 'NO',
                    r.disabled ? 'YES' : 'NO',
                    r.childUnder5 ? 'YES' : 'NO',
                    r.agency,
                    r.amerenCssCode,
                    r.programName,
                    r.statusName,
                    formatCurrency(r.originalArrearsOwed),
                    formatCurrency(r.upfromAmountPaid),
                    this.getPaySourcetype(r.upfrontPaySource),
                    formatCurrency(r.programCredit),
                    formatCurrency(r.arrearsCredit),
                    formatCurrency(r.totalMonthlyCredit),
                    formatCurrency(r.budgetBillAmount),
                    formatCurrency(r.customerMonthlyPayment),
                    formatCurrency(r.pledgedAmount),
                    formatCurrency(r.totalOverallCreditsPaid),
                    formatCurrency(r.totalProgramCreditsPaid),
                    formatCurrency(r.totalArrearsCreditsPaid),
                    formatCurrency(r.arrearsAmountRemaining),
                    r.totalProgramInstallments,
                    r.totalArrearsInstallments,
                    r.totalMonthsEnrolled,
                    r.programCreditsRemaining,
                    r.arrearsCreditsRemaining,
                    r.thirdPartyAgreeable ? 'YES' : 'NO',
                    r.liheapReceived ? 'YES' : 'NO',
                    r.liheapRequested ? 'YES' : 'NO',
                    r.weatherizationReceived ? 'YES' : 'NO',
                    r.weatherizationRequested ? 'YES' : 'NO',
                    this.getWeatherizationStatusesType(r.weatherizationStatus),
                    formatDate(r.approvedTimestamp, DATE_TIME_FORMAT),
                    formatDate(r.initialBillDate, DATE_TIME_FORMAT),
                    formatDate(r.arrearsCreditExpiration, DATE_TIME_FORMAT),
                    formatDate(r.programCreditExpiration, DATE_TIME_FORMAT),
                    formatDate(r.editorDate, DATE_TIME_FORMAT),
                ])));
    }

    private getPaySourcetype(paySource: number | string): string {
        if (typeof paySource === 'number') {
            return splitCamelCase(KcPaySourceType[paySource], true);
        }
        return splitCamelCase(paySource, true);
    }

    private getWeatherizationStatusesType(status: number | string): string {
        if (typeof status === 'number') {
            return splitCamelCase(WeatherizationStatusesType[status], true);
        }
        return splitCamelCase(status, true);
    }
}
