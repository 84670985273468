<h1 class="page-title">New Client</h1>
<section class="widget">
  <div class="widget-body">
    <div class="mt">
      <div class="container">
        <div class="row form-group">
          <form class="form-horizontal col-xs-12" clientForm #f novalidate>
            <div footer class="offset-md-5 col-md-7 p-l-0">
              <button type="submit" class="btn btn-primary" [autoDisable]="f.submit">Create Client</button>
              <cancel-button [route]="'/app/clients'"></cancel-button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</section>
