// shared
// store
// models
// services
// utilities
// directives
import {Component, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {Observable, Observer} from 'rxjs';
import {
  CompanyCategoryType,
  Company,
  PagedListOfCompany, CompanyCategory
} from '../../../services/api.client';

import {CompanyService} from '../../../services/CompanyService';
import {ModalComponent} from 'src/app/utilities/old-modal/components/modal';
import {AbstractControl, FormControl, UntypedFormControl} from '@angular/forms';
import {TableComponent} from '../../shared/table/TableComponent';
import {select, Store} from '@ngrx/store';
import * as fromRoot from '../../../store';
import {
  selectCompanyDataCompanies,
  selectCompanyDataCompaniesItems,
  selectCompanyDataCompaniesTotalCount
} from '../../../store/selectors/company.selectors';
import * as moment from 'moment';
import {Moment} from 'moment';
import {selectCompanyCategories} from '../../../store/selectors/companyCategory.selectors';
import {Disposable} from '../../../utilities/Disposable';
import {takeUntil} from 'rxjs/operators';
import {ICompanyCategory} from '../../../shared/interfaces/ICompanyCategory';
import {CompanySelectedEvent} from '../../shared/events/company-selected-event';

@Component({
  selector: 'company-box',
  templateUrl: './CompanyListModalComponent.html',
  styleUrls: ['./CompanyListModalComponent.scss'],
})

export class CompanyListModalComponent extends Disposable implements OnInit {

  constructor(private _companyService: CompanyService, private _store: Store<fromRoot.AppState>) {
    super();
    this.pagedList$ = this._store.pipe(select(selectCompanyDataCompanies));
    this.companies$ = this._store.pipe(select(selectCompanyDataCompaniesItems));
    this.totalCount$ = this._store.pipe(select(selectCompanyDataCompaniesTotalCount));
    this.currentDate = new Date().getDate();
    this.companyCategories$ = this._store.select(selectCompanyCategories);
  }


  searchBox = new UntypedFormControl('');
  companyCategoryControl = new FormControl(null);

  headers = [
    {text: 'Company Name'},
    {text: 'Last Used'},
    {text: 'Category'}
  ];

  @ViewChild(ModalComponent)
  modal: ModalComponent;

  selectedRow: Company;
  searchValue = '';
  region = '';
  currentDate: any;
  showNoCompany = true;

  @Output() onCompanySelected = new EventEmitter<CompanySelectedEvent>();
  payee: AbstractControl;

  private observer: Observer<any>;

  pagedList: PagedListOfCompany;
  pagedList$: Observable<PagedListOfCompany>;
  companies: Company[];
  companies$: Observable<Company[]>;
  companyCategories: CompanyCategory[];
  companyCategories$: Observable<CompanyCategory[]>;
  categoryTypesSelect: ICompanyCategory[];

  // PAGINATION
  limit = 10;
  currentPage = 1;
  maxSize = 1;
  totalCount: number;
  totalCount$: Observable<number>;

  @ViewChild(TableComponent)
  table: TableComponent;

  protected readonly Date = Date;

  ngOnInit(): void {
    this.pagedList$
        .pipe(takeUntil(this.$destroyed))
        .subscribe(pagedList => this.pagedList = pagedList);
    this.totalCount$
        .pipe(takeUntil(this.$destroyed))
        .subscribe(totalCount => this.totalCount = totalCount);
    this.companies$
        .pipe(takeUntil(this.$destroyed))
        .subscribe(companies => this.companies = companies);
    this.companyCategories$
        .pipe(takeUntil(this.$destroyed))
        .subscribe(companyCategories => {
          this.companyCategories = companyCategories;
          this.populateCategoryTypesSelect();
        });
  }

  setLimit(limit: number): void {// # of rows per page
    this.limit = limit;
    this.filterCompanies();
  }

  setPage(page: number): void {
    this.currentPage = page;
    this.filterCompanies();
  }

  openModal(region: string = null, payee: AbstractControl = null, showNoCompany: boolean = true): Observable<boolean> {
    this.modal?.open();
    this.region = region;
    this.payee = payee;
    this.showNoCompany = showNoCompany;
    this.filterCompanies();
    return new Observable(obs => {
      this.observer = obs;
    });
  }

  filterCompanies(): void {// get companies
    this.table.request = this._companyService.getCompanies(
        this.searchBox.value,
        this.region,
        this.getCategory(this.companyCategoryControl?.value),
        false,
        'LastUsedDate', // this.currentSortName,
        true, // this.currentSorting,
        this.limit,
        (this.currentPage - 1) * this.limit);
  }

  setClickedRow(company: Company): void {
    this.selectedRow = company;
  }

  okClicked(company: Company): void {
    this._emitCloseEvent(company);
  }

  cancelClicked(): void {
    this._emitCloseEvent(null);
  }

  resetClicked(): void {
    this.selectedRow = null;
    this._emitCloseEvent(this.selectedRow, true);
  }

  getLastUsedDate(lastUsedDate: Moment | null): string {
    const twelveMonthsAgo = moment().subtract(12, 'months');
    if (!lastUsedDate) {
      return 'Never';
    } else if (!lastUsedDate.isAfter(twelveMonthsAgo)) {
      return 'Over 12 Months Ago';
    } else {
      return lastUsedDate.format('MM/DD/YYYY');
    }
  }

  resetFilters(): void {
    this.companyCategoryControl?.setValue(null);
    this.searchBox?.setValue('');
    this.filterCompanies();
  }

  private _emitCloseEvent(event: any, noCompany = false): void {
    this.onCompanySelected.emit({company: event, payee: this.payee, noCompany});
    this.modal.close();
    if (this.observer) {
      this.observer.next(event);
      this.observer.complete();
    }
  }

  private populateCategoryTypesSelect(): void {
    this.categoryTypesSelect = this.companyCategories.map(x => ({
      key: x.category.toString(),
      value: x.categoryLabel
    } as ICompanyCategory));
  }

  private getCategory(category: string): CompanyCategoryType {
    return CompanyCategoryType[category];
  }
}
