import {Directive, Input, HostBinding, HostListener} from '@angular/core';
import {Observable} from 'rxjs';


export class AutoDisableParent {
}

@Directive({
  selector: '[autoDisable]',
})
export class AutoDisableDirective {

  @Input('autoDisable')
  autoDisable: () => Observable<any>;

  @Input()
  disable = false;

  // internal state will always override external state
  overrideDisabled = false;

  constructor(private parent: AutoDisableParent) {
  }

  @HostBinding('class.disabled')
  get isDisabled(): boolean {
    return this.disable || this.overrideDisabled;
  }

  @HostListener('click', ['$event'])
  onClick(): boolean {
    if (this.disable || this.overrideDisabled) {
      return false; // don't bubble up the event
    }
    this.overrideDisabled = true;
    this.autoDisable.bind(this.parent)()
      .subscribe(() => {
        this.overrideDisabled = false;
      }, () => {
        this.overrideDisabled = false;
      }, () => {
        this.overrideDisabled = false;
      });
    return false; // don't bubble up the event
  }
}
