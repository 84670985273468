import {from as observableFrom, Observable} from 'rxjs';

import {mergeMap, take} from 'rxjs/operators';
import {Injectable, Injector, OnInit} from '@angular/core';
import {ActivatedRouteSnapshot, RouterStateSnapshot, Router} from '@angular/router';
import {User} from '../services/api.client';
import {SystemService} from '../services/SystemService';
import {SupportAgreementComponent} from '../components/energyassistance/support/agreements/SupportAgreementComponent';
import {select, Store} from '@ngrx/store';
import * as fromRoot from '../store';
import {selectSecurityStateDataCurrentUser} from '../store/selectors/security.selectors';

@Injectable()
export class HasAgreementDate {

    user$: Observable<User>;
    user: User;

    constructor(
        private systemService: SystemService,
        private inject: Injector,
        private _store: Store<fromRoot.AppState>) {
        this.user$ = this._store.pipe(select(selectSecurityStateDataCurrentUser));
        this.user$.subscribe(user => this.user = user);
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        const router: Router = this.inject.get(Router);
        return this.user$.pipe(
            take(1),
            mergeMap(() => {
                if (!this.systemService.loggedInUserHasAgreed() &&
                    state.url !== '/app/support/agreements') {
                    router.navigate(['/app/support/agreements']).then();
                    return observableFrom([false]);
                } else {
                    return observableFrom([true]);
                }
            }),);
    }

    canDeactivate(component: SupportAgreementComponent,
                  route: ActivatedRouteSnapshot,
                  state: RouterStateSnapshot)
        : Observable<boolean> | Promise<boolean> | boolean {
        const router: Router = this.inject.get(Router);
        return this.user$.pipe(
            take(1),
            mergeMap(() => {
                if (!this.systemService.loggedInUserHasAgreed()) {
                    if (state.url !== '/app/support/agreements') {
                        router.navigate(['/app/support/agreements']).then();
                    }
                    return observableFrom([false]);
                } else {
                    return observableFrom([true]);
                }
            }),);
    }
}
