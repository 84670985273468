import {createAction, props} from '@ngrx/store';
import {ApplicationCounty, PagedListOfApplicationCounty, PledgeType} from '../../services/api.client';


export const setApplicationCountyIsLoading = createAction(
    '[Application County] SET_IS_LOADING'
);

export const setApplicationCountyDataLoaded = createAction(
    '[Application County] SET_PAGED_APPLICATION_COUNTIES_DATA_LOADED',
    props<{ payload: PagedListOfApplicationCounty }>()
);

export const setApplicationCountyCurrentSelectedApplicationCounty = createAction(
    '[Application County] SET_CURRENT_SELECTED_APPLICATION_COUNTY',
    props<{ payload: ApplicationCounty }>()
);

export const setApplicationCountyFundAdministrativeFundTypes = createAction(
    '[Application County] SET_FUND_ADMINISTRATIVE_FUND_TYPES',
    props<{ payload: PledgeType[] }>()
);


