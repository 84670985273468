import {IApplicationCountyData} from '../IApplicationCountyData';
import {createReducer, on} from '@ngrx/store';
import {
    setApplicationCountyCurrentSelectedApplicationCounty,
    setApplicationCountyDataLoaded, setApplicationCountyFundAdministrativeFundTypes,
    setApplicationCountyIsLoading
} from '../actions/applicationCounty.actions';


export const initialState: IApplicationCountyData = {
    isLoading: false,
    pagedApplicationCounties: null,
    currentSelectedApplicationCounty: null,
    fundAdministrativeFundTypes: []
};

export const applicationCountyReducer = createReducer(
    initialState,
    on(setApplicationCountyIsLoading, (state) => ({
        ...state,
        isLoading: true
    })),
    on(setApplicationCountyDataLoaded, (state, {payload}) => ({
        ...state,
        isLoading: false,
        pagedApplicationCounties: payload
    })),
    on(setApplicationCountyCurrentSelectedApplicationCounty, (state, {payload}) => ({
        ...state,
        currentSelectedApplicationCounty: payload
    })),
    on(setApplicationCountyFundAdministrativeFundTypes, (state, {payload}) => ({
            ...state,
            fundAdministrativeFundTypes: payload
    }))
);
