<h1 class="page-title">Browse Agencies</h1>
<section class="widget">
  <div class="widget-body">
    <form novalidate>
      <div class="row form-group">
        <div class="col-md col-sm-12">
          <label class="grid-label">Agency Name:</label>
          <input class="search-text-input form-control"
                 type="text"
                 placeholder="Search by Name"
                 [ngModel]="searchValue"
                 [ngModelOptions]="{standalone: true}"
                 (ngModelChange)="setSearchValue($event)"/>
        </div>
        <div class="col-md-4 col-sm dropdown-menu-top">
          <label class="grid-label">Fund Type:</label>
          <dropdown class="drop-down-inline"
                    [promptValue]="'All Fund Types'"
                    [values]="pledgeTypeDropdown"
                    (select)="selectedPledgeType($event)"></dropdown>
        </div>
      </div>
      <div class="row form-group">
        <div class="col-md-6">
          <button class="btn btn-primary" role="button" type="submit" (click)="filterAgencies()">Search</button>
        </div>
        <div class="col-md-6">
          <button class="btn btn-primary pull-right" [routerLink]="['../agency/add']">New Agency</button>
        </div>
      </div>
    </form>


    <table-dynamic id="datatable-table"
                   class="table table-striped table-hover"
                   [headers]="tableHeaders"
                   [currentPage]="currentPage"
                   [totalCount]="totalCount"
                   (headerClick)="sortAgencies($event)"
                   (pageChanged)="setPage($event)"
                   (limitChange)="setLimit($event)">
      <tr *ngFor="let agency of pagedList?.items">
        <td>
          <a [routerLink]="['../agency', agency.id, queryParams]"
             (click)="setCurrentSelectedAgency(agency); false"><span
            class="fw-semi-bold">{{agency.agencyName}}</span></a>
        </td>
        <td>
          <span>{{ formatAgencyStatus(agency.status) }}</span>
        </td>
      </tr>
    </table-dynamic>

  </div>
</section>
