<div class="row">
  <div class="col-sm-12 col-md-12 col-lg-10 offset-lg-1">
    <h4>Manual Shift Pledges Pending Payment to Paid</h4>
    <p>
      In order to shift one or more of the items below, currently in “Manual Pend” status, to “Paid” status, enter
      payment date in box below, select one or more items with that payment date, and click ‘Pay Pledges’ button.
    </p>
  </div>
  <div class="col-sm-12 col-md-12 col-lg-10 offset-lg-1">
    <form novalidate>
      <div class="row">
        <div class="col-xs-12">
          <div class="form-group row" [style.z-index]="3">
            <div class="col-xs-12"></div>
            <label class="col-md-6 manualDateLabel">
              <span>Payment date of Pledges being manually shifted from Pending Payment to Paid:</span>
            </label>
            <datePicker class="col-md-4" [control]="paidDate" [autoClose]="true"></datePicker>
            <button class="btn btn-primary col-md-2" [disabled]="!paidDate"
                    (click)="manualSubmitPendingToPaid()">Pay Pledges
            </button>
          </div>
          <ng-content select="[footer]"></ng-content>
        </div>
        <prompt-dialog
          [titleText]="'Update Pledges?'"
          [promptText]="'Are you sure you want to update the following pledges to a paid status?'">
        </prompt-dialog>
      </div>
      <div footer class="text-right">
      </div>
    </form>
  </div>
  <div *ngIf="pledges" [hidden]="isLoading" class="col-xs-12 m-b-1 m-t-1">
    <table-dynamic *ngIf="pledges?.length > 0 && currentUser.hasPrivate"
                   [headers]="tableHeaders"
                   [showPagination]="false">
      <tr *ngFor="let pledge of pledges">
        <td class="checkboxCenter">
          <input checkboxArray
                 [ngValue]="pledge.id"
                 [formControl]="pledgeIds"
                 type="checkbox" class="form-check-input"/>
        </td>
        <td>{{ pledge.clientLastName }}</td>
        <td>{{ pledge.clientFirstName }}</td>
        <td>{{ pledge.clientLast4Ssn }}</td>
        <td>{{ pledge.pledgedAmount }}</td>
        <td>{{ formatFundType(pledge.pledgeTypeId) }}</td>
        <td>{{ pledge.pledgedDate | moment: 'L' }}</td>
        <td>{{ _pledgeService.formatPledgeStatus(pledge.status) }}</td>
        <td>{{ pledge.pledgeType.ledgerNumber}}</td>
        <td>{{ pledge.pledgeType.isPrivate }}</td>
      </tr>
    </table-dynamic>
    <table-dynamic *ngIf="pledges?.length > 0 && !currentUser.hasPrivate"
                   [headers]="tableHeaders"
                   [showPagination]="false">
      <tr *ngFor="let pledge of pledges">
        <td class="checkboxCenter">
          <input checkboxArray
                 [ngValue]="pledge.id"
                 [formControl]="pledgeIds"
                 type="checkbox" class="form-check-input"/>
        </td>
        <td>{{ pledge.clientLastName }}</td>
        <td>{{ pledge.clientFirstName }}</td>
        <td>{{ pledge.clientLast4Ssn }}</td>
        <td>{{ pledge.pledgedAmount }}</td>
        <td>{{ formatFundType(pledge.pledgeTypeId) }}</td>
        <td>{{ pledge.pledgedDate | moment: 'L' }}</td>
        <td>{{ _pledgeService.formatPledgeStatus(pledge.status) | splitCamelCase }}</td>
        <td>{{ pledge.pledgeType.ledgerNumber}}</td>
      </tr>
    </table-dynamic>
    <table-dynamic *ngIf="pledges?.length === 0" [headers]="tableHeaders" [showPagination]="false">
      <tr>
        <td [attr.colspan]="tableHeaders.length" style="text-align: center">Empty</td>
      </tr>
    </table-dynamic>
  </div>
</div>
