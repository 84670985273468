/**
 * Created by djacobsmeyer on 1/17/17.
 */
import {Component, Input} from '@angular/core';

@Component({
  selector: 'cancel-button',
  template: '<button [class]=\'buttonClass\' [routerLink]=\'route\'>{{buttonText}}</button>'
})

export class CancelButtonComponent {
//
  @Input()
  buttonText = 'Cancel';

  @Input()
  route: string;

  @Input()
  buttonClass = 'btn btn-outline-primary';
}
