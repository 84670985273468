<form novalidate [formGroup]="form">
  <div class="row">
    <div class="col-md-6 m-b-05">
      <div class="custom-control-container m-t-05">
        <label class="custom-control custom-radio">
          <input class="custom-control-input" type="radio" value="Ssn" formControlName="searchType">
          <span class="custom-control-indicator"></span>
          <span class="custom-control-description">Search By SSN</span>
        </label>
      </div>
      <div class="custom-control-container m-t-05">
        <label class="custom-control custom-radio">
          <input class="custom-control-input" type="radio" value="SearchAllAgencies" formControlName="searchType">
          <span class="custom-control-indicator"></span>
          <span class="custom-control-description">Search All Agencies</span>
        </label>
      </div>
      <div class="custom-control-container">
        <label class="custom-control custom-radio">
          <input class="custom-control-input" type="radio" value="SearchMyAgency" formControlName="searchType">
          <span class="custom-control-indicator"></span>
          <span class="custom-control-description">Search My Agency</span>
        </label>
      </div>
    </div>
    <div class="col-md-6">
      <div class="pull-right how-to-pledge">
        <a href="#" data-toggle="modal" data-target="#pledgeHelp">How to pledge</a>
      </div>
      <div class="modal fade" id="pledgeHelp" tabindex="-1" role="dialog"
           aria-labelledby="pledgeHelpLabel" aria-hidden="true">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
              <h4 class="modal-title" id="pledgeHelpLabel">How to Pledge</h4>
            </div>
            <div class="modal-body">
              <ul>
                <li>Click on ‘Search by SSN’ search option, enter SSN with no dashes in search box and click ‘Search’
                  button.
                  If found, select the Client by clicking on the Client's name and enter pledge.
                </li>
                <li>If not found, click on ‘Search All Agencies’ search option, enter last name in search box and click
                  ‘Search’.
                  If found, select the Client by clicking on the Client's name and enter pledge.
                </li>
                <li> If not found, consider other 'Search All Agencies' search options (alternate name, address...) to
                  find client.
                </li>
                <li>
                  Only if client is not found, click on ‘New Client’ button to add client and make pledge or EFS
                  Commitment.
                </li>
                <li>
                  <i>Click on the Support menu item on the main menu at left for more help.</i>
                </li>
              </ul>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-primary" data-dismiss="modal">Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-6">
      <input *ngIf="form.controls['searchType']?.value === 'Ssn'"
             class="form-control m-b-05"
             [imask]="ssnMask"
             [attr.placeholder]="searchTypePlaceholder"
             type="text"
             [ngModel]="searchValue"
             (ngModelChange)="setSearchValue($event)"
             [ngModelOptions]="{standalone: true}"/>

      <input *ngIf="form.controls['searchType']?.value !== 'Ssn'"
             class="form-control m-b-05"
             type="text"
             [attr.placeholder]="searchTypePlaceholder"
             [ngModel]="searchValue"
             (ngModelChange)="setSearchValue($event)"
             [ngModelOptions]="{standalone: true}"/>

    </div>

  </div>

  <div class="row search-container">
    <div class="col-md-6">
      <button class="btn btn-primary btn-search" role="button" type="submit"
              (click)="filterClients()">Search
      </button>
    </div>
    <div class="col-md-6 btn-new-client-container">
      <a class="btn btn-primary pull-right" [routerLink]="['../client/add']"
         [class.hidden]="loggedInUserType == 'Viewer'">New Client</a>
    </div>
  </div>
</form>

<table-dynamic id="datatable-table"
               class="table table-striped table-hover"
               [headers]="tableHeaders"
               [currentPage]="currentPage"
               [totalCount]="totalCount"
               (headerClick)="sortClients($event)"
               (pageChanged)="setPage($event)"
               (limitChange)="setLimit($event)">
  <tr *ngFor="let client of clients" [class.active]="client.id === selectedId">
    <td>
      <a href="#" (click)="selectClient(client); selectedId = client.id;false"><span
        class="fw-semi-bold">{{client.firstName}} {{client.middleInitial}}</span></a>
    </td>
    <td>
      <a href="#" (click)="selectClient(client); selectedId = client.id; false"><span
        class="fw-semi-bold">{{client.lastName}}</span></a>
    </td>
    <td class="hidden-sm-down">{{client.phone}}</td>
    <td class="hidden-sm-down" *ngIf="!client.last4Ssn">Missing SSN</td>
    <td class="hidden-sm-down" *ngIf="client.last4Ssn">***-**-{{client.last4Ssn}}</td>
    <td class="hidden-sm-down">{{client.address1}}</td>
    <td class="hidden-sm-down">{{client.zipCode}}</td>
    <td class="hidden-sm-down">{{client.employmentStatus}}</td>
  </tr>
</table-dynamic>
