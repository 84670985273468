import {from as observableFrom, Observable, combineLatestWith} from 'rxjs';

import {map, take} from 'rxjs/operators';
import {Injectable, Injector} from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import {ClientService} from '../services/ClientService';
import {ClientComponent} from '../components/energyassistance/client/manage/ClientComponent';
import {Client} from '../services/api.client';
import {ClientEditComponent} from '../components/energyassistance/client/manage/ClientEditComponent';
import {select, Store} from '@ngrx/store';
import * as fromRoot from '../store';
import {selectClientDataCurrentClient} from '../store/selectors/client.selectors';

/**
 * Created by djacobsmeyer on 12/30/16.
 */
@Injectable()
export class IsClientVerifiedGuard  {

  constructor(private injector: Injector, private _store: Store<fromRoot.AppState>) {
      this.currentClient$ = this._store.pipe(select(selectClientDataCurrentClient));
  }

  private get router(): Router {
    return this.injector.get(Router);
  }

  private get clientService(): ClientService {
    return this.injector.get(ClientService);
  }

  currentClient$: Observable<Client>;

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return observableFrom([true]).pipe(
      combineLatestWith(this.currentClient$.modelExists()),
      take(1),
      map(([_, client]) => {
        let s = state.root;
        while (s.firstChild !== null) {
          s = s.firstChild;
        }

        if (s.component === ClientComponent || s.component === ClientEditComponent || this.clientService.isClientVerified) {
          return true;
        } else {
          this.router.navigate(['/app/client', client.id]).then();
        }

      }), );
  }
}
