import {Component, EventEmitter, inject, Input, OnInit, Output} from '@angular/core';
import {DropdownValue} from '../../../../../../utilities/DropdownComponent';
import {FormBuilder, FormControl, Validators} from '@angular/forms';
import {AddAssistanceApplicationStateAuditViewModel, AssistanceApplicationStateAuditType} from '../../../../../../services/api.client';
import {AssistanceApplicationStateAuditService} from '../../../../../../services/assistanceApplicationStateAudit.service';

@Component({
    selector: 'app-manage-audit-state',
    templateUrl: './manage-audit-state.component.html',
    styleUrls: ['./manage-audit-state.component.scss']
})
export class ManageAuditStateComponent implements OnInit {

    @Input() assistanceApplicationId;
    @Output() savedAuditState = new EventEmitter<AddAssistanceApplicationStateAuditViewModel>();
    @Output() closeModal = new EventEmitter();

    private _fb = inject(FormBuilder);
    private _assistanceApplicationStateAuditService = inject(AssistanceApplicationStateAuditService);
    private _currentAuditState: string;

    get auditState(): FormControl {
        return this.form?.get('auditState') as FormControl;
    }

    get auditNote(): FormControl {
        return this.form?.get('auditNote') as FormControl;
    }

    form = this._fb.group({
        auditState: new FormControl('', Validators.required),
        auditNote: new FormControl('', Validators.required)
    });

    private _savedAuditState: AddAssistanceApplicationStateAuditViewModel = new AddAssistanceApplicationStateAuditViewModel();

    auditStates: DropdownValue[] = [
        new DropdownValue(AssistanceApplicationStateAuditType[AssistanceApplicationStateAuditType.NotYetAudited.toString()], 'Not Yet Audited'),
        new DropdownValue(AssistanceApplicationStateAuditType[AssistanceApplicationStateAuditType.Pass.toString()], 'Pass'),
        new DropdownValue(AssistanceApplicationStateAuditType[AssistanceApplicationStateAuditType.PassWithCorrection.toString()], 'Pass With Correction'),
        new DropdownValue(AssistanceApplicationStateAuditType[AssistanceApplicationStateAuditType.Fail.toString()], 'Fail'),
    ];

    ngOnInit(): void {
        this._assistanceApplicationStateAuditService.retrieveCaseCurrentState(this.assistanceApplicationId).subscribe(result => {
            this._currentAuditState = result?.newState
                ? result?.newState?.toString()
                : result?.oldState?.toString() || AssistanceApplicationStateAuditType.NotYetAudited.toString();

            const initialAuditValue = this.auditStates.find(x => x.id === this._currentAuditState);
            this.auditState.setValue(initialAuditValue?.id);
            this._savedAuditState = AddAssistanceApplicationStateAuditViewModel.fromJS({
                assistanceApplicationId: this.assistanceApplicationId,
                newState: this.getAuditState(this._currentAuditState),
                note: ''
            });
        });

    }

    onSelectedAuditState(event): void {
        this._savedAuditState = AddAssistanceApplicationStateAuditViewModel.fromJS({
            assistanceApplicationId: this.assistanceApplicationId,
            newState: this.getAuditState(event?.target.value),
            note: this.auditNote?.value
        });
        this.savedAuditState.emit(this._savedAuditState);
    }

    onChangeAuditNote(): void {
        this._savedAuditState = AddAssistanceApplicationStateAuditViewModel.fromJS({
            assistanceApplicationId: this.assistanceApplicationId,
            newState: this._savedAuditState?.newState,
            note: this.auditNote?.value
        });
        this.savedAuditState.emit(this._savedAuditState);
    }

    onClickCancelAuditState(): void {
        this.closeModal.emit();
        this.resetForm();
    }

    onClickConfirmAuditState(): void {
        if (!this.form.valid) {
            this.form.markAllAsTouched();
            return;
        }

        this._assistanceApplicationStateAuditService.addAssistanceApplicationStateAudit(this._savedAuditState)
            .subscribe(() => {
                this.resetForm();
                this.closeModal.emit();
                window.location.reload();
            });
    }

    private getAuditState(enumStringValue: string): string {
        return AssistanceApplicationStateAuditType[enumStringValue];
    }

    private resetForm(): void {
        this.auditNote.markAsUntouched();
        this.auditNote.setValue('');
    }
}
