import {IActivityAuditData} from '../IActivityAuditData';
import {createReducer, on} from '@ngrx/store';
import {setActivityAuditIsLoading, setActivityAuditLoaded} from '../actions/activityAudit.actions';

export const initialState: IActivityAuditData = {
    isLoading: false,
    pagedListOfActivityAudit: null
};

export const activityAuditReducer = createReducer(
    initialState,
    on(setActivityAuditIsLoading, (state) => ({
        ...state,
        isLoading: true
    })),
    on(setActivityAuditLoaded, (state, {payload}) => ({
        ...state,
        pagedListOfActivityAudit: payload,
        isLoading: false
    })),
);
