import {createAction, props} from '@ngrx/store';
import {LookUp, PagedListOfLookUp} from '../../services/api.client';

export const lookupsDataLoaded  = createAction(
    '[Lookups] LOOKUPS_DATA_LOADED',
    props<{ payload: PagedListOfLookUp }>());

export const lookupsDataIsLoading  = createAction(
    '[Lookups] LOOKUPS_DATA_ISLOADING');

export const clearCurrentSelectedLookup  = createAction(
    '[Lookups] CLEAR_CURRENT_SELECTEDLOOKUP',
    props<{ payload: LookUp }>());

export const setCurrentSelectedLookup  = createAction(
    '[Lookups] LOOKUP',
    props<{ payload: LookUp }>());
