import {createFeatureSelector, createSelector} from '@ngrx/store';
import {ICompanyData} from '../ICompanyData';

const companyDataFeatureSelector = createFeatureSelector<ICompanyData>('company');

export const selectCompanyDataCurrentSelectedCompany = createSelector(
    companyDataFeatureSelector,
    (state) => state.currentSelectedCompany
);

export const selectCompanyDataCompanies = createSelector(
    companyDataFeatureSelector,
    (state) => state.companies
);

export const selectCompanyDataCompaniesItems = createSelector(
    companyDataFeatureSelector,
    (state) => state.companies?.items
);

export const selectCompanyDataCompaniesTotalCount = createSelector(
    companyDataFeatureSelector,
    (state) => state.companies?.totalCount
);
