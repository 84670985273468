import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {PledgeType, PledgeTypeClient} from '../services/api.client';

@Injectable()
export class ResolveKcPledgeType  {

    constructor(private _pledgeApi: PledgeTypeClient) {
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<PledgeType[]> | Promise<PledgeType[]> | PledgeType[] {
        return this._pledgeApi.retrievePledgeTypes()
            .pipe(map((pledgeTypes) => pledgeTypes.filter(pt => pt.isKeepingCurrent)));
    }

}
