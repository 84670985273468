import {combineLatest, Observable} from 'rxjs';
import {Component, OnInit, ViewChild} from '@angular/core';
import {PagedListOfPledge, Pledge, PledgeType, User} from '../../../services/api.client';
import {TableComponent, ITableHeader} from '../../shared/table/TableComponent';
import {PledgeService} from '../../../services/PledgeService';
import {ClientService} from '../../../services/ClientService';
import {NotificationService} from '../../../services/NotificationService';
import {UntypedFormControl} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {select, Store} from '@ngrx/store';
import * as fromRoot from '../../../store';
import {
  selectConfirmedUwdaPledgeDataPledges,
  selectPledgeDataConfirmedUwdaPledgesTotalCount,
  selectPledgeDataPledges
} from '../../../store/selectors/pledge.selectors';
import {
  selectSecurityStateDataCurrentUser,
  selectSecurityStateDataSecurityDataPledgeTypes
} from '../../../store/selectors/security.selectors';

@Component({
  templateUrl: './UwdaPledgeListComponent.html',
  styleUrls: ['./UwdaPledgeListComponent.scss']
})

export class UwdaPledgeListComponent implements OnInit {

  constructor(public _pledgeService: PledgeService,
              private _clientService: ClientService,
              private notification: NotificationService,
              private _route: ActivatedRoute,
              private _router: Router,
              private _store: Store<fromRoot.AppState>) {
    this.currentUser$ = this._store.pipe(select(selectSecurityStateDataCurrentUser));
    this.pledgeTypes$ = this._store.pipe(select(selectSecurityStateDataSecurityDataPledgeTypes));
    this.pagedPledges$ = this._store.pipe(select(selectConfirmedUwdaPledgeDataPledges));
    this.totalCount$ = this._store.pipe(select(selectPledgeDataConfirmedUwdaPledgesTotalCount));
  }

  get queryParams(): any {
    return this.params;
  }
  uwdaPledgeTypes: PledgeType[] = new Array<PledgeType>();
  params: any;

  currentUser$: Observable<User>;
  pledgeTypes: PledgeType[];
  pledgeTypes$: Observable<PledgeType[]>;
  pagedPledges: PagedListOfPledge;
  pagedPledges$: Observable<PagedListOfPledge>;

  // pagination
  limit = 10;
  currentPage = 1;
  maxSize = 1;
  totalCount: number;
  totalCount$: Observable<number>;

  // sorting
  currentSortName = 'PledgedDate';
  currentSorting = true;

  autoOkPledgeIds: UntypedFormControl = new UntypedFormControl([]);
  manualOkPledgeIds: UntypedFormControl = new UntypedFormControl([]);
  selectedPledgeTypeId = new UntypedFormControl('');

  @ViewChild(TableComponent)
  table: TableComponent;

  tableHeaders: ITableHeader[] = [
    {text: 'Last Name', sortKey: 'ClientLastName'},
    {text: 'First Name', sortKey: 'ClientFirstName'},
    {text: 'SSN.', sortKey: 'ClientLast4Ssn', class: 'hidden-sm-down'},
    {text: 'Date', sortKey: 'PledgedDate', class: 'hidden-sm-down', ascending: true},
    {text: 'Status', sortKey: 'Status', class: 'hidden-sm-down'},
    {text: 'Pledge', sortKey: 'PledgedAmount'},
    {text: 'Fund Type', sortKey: 'PledgeType|Name', class: 'hidden-sm-down'},
    {text: 'Auto Ok'},
    {text: 'Manual Ok'},
  ];

  ngOnInit(): void {
    this.pledgeTypes$.subscribe(pledgeTypes => this.pledgeTypes = pledgeTypes);
    this.pagedPledges$.subscribe(pledges => this.pagedPledges = pledges);
    this.totalCount$.subscribe(totalCount => this.totalCount = totalCount);
    combineLatest([
      this.autoOkPledgeIds.valueChanges,
      this.manualOkPledgeIds.valueChanges
    ]).subscribe(([autoOkPledgeIds, manualOkPledgeIds]: [string[], string[]]) => {
      if (!autoOkPledgeIds || !manualOkPledgeIds) {
        return;
      }
      autoOkPledgeIds.forEach(id => {
        const index = manualOkPledgeIds.indexOf(id);
        if (index > -1) {
          this.manualOkPledgeIds.setValue(manualOkPledgeIds.filter(i => i !== id));
        }
      });
    });

    if (this.pledgeTypes) {
      this.uwdaPledgeTypes = this.pledgeTypes.filter(i => i.isUwda);
    }
  }

  ngAfterViewInit(): void {

    setTimeout(() => {
      this._route.params.subscribe(params => {
        this.limit = params.limit || 10;
        this.currentPage = params.currentPage || 1;
        this.params = {
          // pledgeTypeId: this.selectedPledgeTypeId.value,
          sortName: this.currentSortName,
          limit: this.limit,
          descending: this.currentSorting,
          currentPage: this.currentPage,
        };
      });

    });
  }

  sortPledges(options: ITableHeader): void {
    this.currentSortName = options.sortKey;
    this.currentSorting = !options.ascending;
    this.getConfirmedUwdaPledges();
  }

  setLimit(limit: number): void {// # of rows per page
    this.limit = limit;
    this.getConfirmedUwdaPledges();
  }

  setPage(page: number): void {
    setTimeout(() => {
      this.currentPage = page; // set current page
      this.getConfirmedUwdaPledges();
    });
  }

  getConfirmedUwdaPledges(): void {
    this.table.request = this._pledgeService.getConfirmedUwdaPledges(
      this.selectedPledgeTypeId.value,
      this.currentSortName,
      this.currentSorting,
      this.limit,
      (this.currentPage - 1) * this.limit
    );

    this.autoOkPledgeIds.reset();
    this.manualOkPledgeIds.reset();
  }

  approvePledges(): void {
    if (!this.autoOkPledgeIds.value && !this.manualOkPledgeIds.value) {
      this.notification.showError('No Pledges are chosen');
      return;
    }

    if (this.autoOkPledgeIds.value) {
      // approve Auto Ok
      this._pledgeService.approveConfirmedUwdaPledgesAuto(this.autoOkPledgeIds.value)
        .subscribe(() => this.getConfirmedUwdaPledges());
    }

    if (this.manualOkPledgeIds.value) {
      // approve Manual Ok
      this._pledgeService.approveConfirmedUwdaPledgesManual(this.manualOkPledgeIds.value)
        .subscribe(() => this.getConfirmedUwdaPledges());
    }
  }

  // Current Selected Pledge
  setCurrentSelectedPledge(pledge: Pledge): void {
    this._pledgeService.setCurrentSelectedPledge(pledge);
  }

  // Current Selected Client
  setCurrentSelectedClient(clientId: string): void {
    this._clientService.getClientById(clientId);
  }

  formatFundType(fundTypeId): string {
    if (this.pledgeTypes) {
      for (const pledgeType of this.pledgeTypes) {
        if (pledgeType.id === fundTypeId) {
          return pledgeType.name;
        }
      }
      return 'Missing Pledge Type';
    }
  }

  ngOnDestroy(): void {
    this._pledgeService.clearConfirmedUwdaPledges();
  }
}
