/**
 * Requires momentjs.
 */
import * as moment from 'moment';
import Moment = moment.Moment;
import {Injectable} from '@angular/core';
import {DateColumn} from './DateColumn';
import {ReplaySubject, Subject} from 'rxjs';

import * as _ from 'lodash';

/**
 * Calendar
 */
@Injectable()
export class Calendar {

  // hard code locale for now
  firstDayOfWeek = (moment.localeData('en-us') as any).firstDayOfWeek();
  // weekdays are based off `moment.locale('your_locale');`
  weekdays: Array<string>;

  // first day visible on calendar (usually a date within prev month)
  startDate: Moment;
  // first date of month
  firstDayOfMonth: Moment;
  // last day visible on calendar (usually a date within next month)
  endDate: Moment;
  // last date of month
  lastDayOfMonth: Moment;

  /**
   * dates displayed on calendar (previous, current and next month)
   */
  dates: Array<DateColumn>;
  dateRows: Array<Array<DateColumn>>;
  /**
   * Same as `dates` but excludes previous and next month
   */
  currentMonthDates: Array<DateColumn>;

  cachedDates: any = {};

  /**
   * Fired when a dateColumn is added to the `dates` array.
   */
  dateColumns = new ReplaySubject<DateColumn>(7 * 6); // 7 * 6 === days displayed per month
  monthChange = new Subject<this>();


  constructor() {
    this.setMonth(moment());
    this.weekdays = this.getWeekDays();
  }

  setMonth(date: Moment): void;
  setMonth(date: DateColumn): void;
  setMonth(date: any): void {
    // normalize parameter to a moment object.
    date = (date instanceof DateColumn) ? date.toMoment() : date;

    if (!date.isValid()) {
      return;
    }

    this.firstDayOfMonth = date.clone().startOf('month');
    this.lastDayOfMonth = date.clone().endOf('month');

    this.startDate = this.getFirstDayOfWeek(this.firstDayOfMonth);
    this.endDate = this.getLastDayOfWeek(this.lastDayOfMonth);

    const totalDays = 7 * 6; // 6 rows
    const cacheKey = this.firstDayOfMonth.toString();
    this.dates = this.cachedDates[cacheKey] = this.cachedDates[cacheKey] || new Array(totalDays);

    this.currentMonthDates = [];
    date = this.startDate.clone();
    for (let i = 0; i < totalDays; i++) {
      this.dates[i] = this.dates[i] || new DateColumn(date);
      this.dateColumns.next(this.dates[i]);

      if (this.isSameMonth(date)) {
        this.currentMonthDates.push(this.dates[i]);
      }
      date.add(1, 'day');
    }
    this.dateRows = _.chunk<DateColumn>(this.dates, 7);
    this.monthChange.next(this);
  }

  ///
  getDateColumn(date: Moment): DateColumn {
    date = date.clone().startOf('day');
    let dateColumn = this.dates.find((column) => column.isSame(date));
    if (!dateColumn) {
      const month = this.firstDayOfMonth;
      this.setMonth(date);
      dateColumn = this.getDateColumn(date);
      // revert back to selected month
      this.setMonth(month);
    }
    return dateColumn;
  }

  /**
   * Returns an array of strings containing the weekdays (Mon, Tues, etc..)
   * Weekdays are based off `moment.locale('your_locale');`
   *
   * @returns {string[]}
   */
  getWeekDays(format = 'ddd'): Array<string> {
    const date = this.getFirstDayOfWeek(this.firstDayOfMonth);
    const weekdays = new Array(7);
    for (let i = 0; i < 7; i++) {
      weekdays[i] = date.format(format);
      date.add(1, 'day');
    }
    return weekdays;
  }

  getLastDayOfWeek(date: Moment): moment.Moment {
    return date.clone().weekday(this.firstDayOfWeek === 0 ? 6 : 0);
  }

  getFirstDayOfWeek(date: Moment): moment.Moment {
    return date.clone().weekday(this.firstDayOfWeek);
  }

  prevMonth(): void {
    this.setMonth(this.firstDayOfMonth.subtract(1, 'month'));
  }

  nextMonth(): void {
    this.setMonth(this.firstDayOfMonth.add(1, 'month'));
  }

  isSameMonth(date: DateColumn);
  isSameMonth(date: Moment);
  isSameMonth(date: any) {
    return date.isSame(this.firstDayOfMonth, 'month');
  }

  isPrevMonth(date: DateColumn);
  isPrevMonth(date: Moment);
  isPrevMonth(date: any) {
    return date.isBefore(this.firstDayOfMonth);
  }

  isNextMonth(date: DateColumn);
  isNextMonth(date: Moment);
  isNextMonth(date: any) {
    return date.isAfter(this.lastDayOfMonth);
  }

  getDateCSS(column: DateColumn): string {
    let classes = '';
    if (this.isNextMonth(column)) {
      classes = 'date_column next_month';
    } else if (this.isPrevMonth(column)) {
      classes = 'date_column prev_month';
    } else {
      classes = 'date_column current_month';
    }
    return classes;
  }

  dispose(): void {
    this.dateColumns.unsubscribe();
    this.monthChange.unsubscribe();
  }
}


