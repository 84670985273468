import {IAllocationData} from '../IAllocationData';
import {createReducer, on} from '@ngrx/store';
import {
  allocationsDataIsLoaded,
  allocationsDataIsLoading,
  allocationsDataSoftDelete,
  setCurrentSelectedAllocation
} from '../actions/allocation.actions';

export const initialState: IAllocationData = {
  isAllocationsLoading: false,
  allocations: null,
  currentSelectedAllocation: null,
};

export const allocationReducer = createReducer(
    initialState,
    on(setCurrentSelectedAllocation, (state, { payload }) => ({
      ...state,
      currentSelectedAllocation: payload
    })),
    on(allocationsDataIsLoaded, (state, { payload }) => ({
     ...state,
     isAllocationsLoading: false,
     allocations: payload
    })),
    on(allocationsDataIsLoading, (state) => ({
      ...state,
          isAllocationLoading: true
    })),
    on(allocationsDataSoftDelete, (state) => ({
      ...state,
      allocations: null
    })),
);
