<div class="container-fluid">
  <!-- .navbar-header contains links seen on xs & sm screens -->
  <div class="navbar-header">
    <ul class="nav navbar-nav">
      <li class="nav-item">
        <!-- whether to automatically collapse sidebar on mouseleave. If activated acts more like usual admin templates -->
        <a (click)="toggleSidebar('static')" class="nav-link hidden-md-down" href="#" data-toggle="tooltip"
           data-html="true" data-original-title="Turn<br>on/off<br>sidebar<br>collapsing" data-placement="bottom">
          <i class="fa fa-bars fa-lg"></i>
        </a>
        <!-- shown on xs & sm screen. collapses and expands navigation -->
        <a (click)="toggleSidebar('collapse')" class="hidden-lg-up nav-link" href="#" data-html="true"
           title="Show/hide<br>sidebar" data-placement="bottom">
          <span class="rounded rounded-lg bg-gray text-white hidden-md-up"><i class="fa fa-bars fa-lg"></i></span>
          <i class="fa fa-bars fa-lg hidden-sm-down"></i>
        </a>
      </li>
      <!-- refresh icon -->
      <li class="nav-item ml-sm hidden-sm-down">
        <a [style.visibility]="showLoader ? 'hidden' : 'visible'" class="nav-link" href="#"><i
          class="fa fa-refresh fa-lg"></i></a>
        <a href="#" class="nav-link loader_container" [class.hidden]="!showLoader">
          <div class="loader">
            <svg class="circular" viewBox="25 25 50 50">
              <circle class="path" cx="50" cy="50" r="20" fill="none" stroke-miterlimit="10"></circle>
            </svg>
          </div>
        </a>
      </li>
      <li class="nav-item nav-agency-name "><a class="nav-link">{{currentAgency?.agencyName}}</a></li>
    </ul>
    <ul class="nav navbar-nav pull-xs-right">
      <li class="nav-item dropdown">
        <a href class="nav-link dropdown-toggle" data-toggle="dropdown">
          <strong>{{currentUser.firstName}} {{currentUser.lastName}}</strong>&nbsp;
          <i class="fa fa-cog fa-lg"></i>
        </a>
        <ul class="dropdown-menu dropdown-menu-right">
          <li><a class="dropdown-item clickable" [routerLink]="['/app/user/', currentUser.id]"><i
            class="glyphicon glyphicon-user"></i> &nbsp; My Account</a></li>
          <li class="dropdown-divider"></li>
          <li><a class="dropdown-item" href="/login" (click)="logout()"><i class="fa fa-sign-out"></i> &nbsp; Log
            Out</a></li>
        </ul>
      </li>
    </ul>
  </div>
</div>
