import {filter, observeOn, map} from 'rxjs/operators';
import {Component, ViewChild, OnInit, AfterViewInit} from '@angular/core';
import {Client, EfsStatusType} from '../../../../services/api.client';
import {Efs} from '../../../../services/api.client';
import {Observable, asapScheduler, combineLatestWith} from 'rxjs';
import {formatDate, normalize} from '../../../../utilities/Util';
import {UntypedFormBuilder, UntypedFormGroup, UntypedFormControl, FormControl} from '@angular/forms';
import {LookUp} from '../../../../services/api.client';
import {ITableHeader} from '../../../shared/table/TableComponent';
import {StatusType} from '../../../../services/api.client';
import * as moment from 'moment';
import {EfsService} from '../../../../services/EfsService';
import {PledgeType} from '../../../../services/api.client';
import {PledgeTypeService} from '../../../../services/PledgeTypeService';
import {UserType} from '../../../../services/api.client';
import {User} from '../../../../services/api.client';
import {SHORT_DATE_FORMAT} from '../../../../constants';
import * as _ from 'lodash';
import {Validators} from '../../../../utilities/Validators';
import {ClientEfsConfirmationComponent} from './ClientEfsConfirmationComponent';
import {select, Store} from '@ngrx/store';
import * as fromRoot from '../../../../store';
import {selectClientDataCurrentClient} from '../../../../store/selectors/client.selectors';
import {selectEfsDataCurrentClientEfsList, selectEfsDataCurrentSelectedEfs} from '../../../../store/selectors/efs.selectors';
import {selectPledgeTypeDataAllPledgeTypes} from '../../../../store/selectors/pledgeType.selectors';
import {
    selectSecurityStateDataCurrentUser,
    selectSecurityStateDataSystemDataAssistanceType
} from '../../../../store/selectors/security.selectors';
import swal from 'sweetalert2';

@Component({
    selector: 'ClientEfsComponent',
    templateUrl: './ClientEfsComponent.html',
    styleUrls: ['./ClientEfsComponent.scss']
})

export class ClientEfsComponent implements OnInit, AfterViewInit {

    get id(): FormControl {
        return this.form?.get('id') as FormControl;
    }

    get clientId(): FormControl {
        return this.form?.get('clientId') as FormControl;
    }

    get agencyId(): FormControl {
        return this.form?.get('agencyId') as FormControl;
    }

    get agencyName(): FormControl {
        return this.form?.get('agencyName') as FormControl;
    }

    get assistanceType(): FormControl {
        return this.form?.get('assistanceType') as FormControl;
    }

    get pledgeTypeId(): FormControl {
        return this.form?.get('pledgeTypeId') as FormControl;
    }

    get zipCode(): FormControl {
        return this.form?.get('zipCode') as FormControl;
    }

    get description(): FormControl {
        return this.form?.get('description') as FormControl;
    }

    get confirmed(): FormControl {
        return this.form?.get('confirmed') as FormControl;
    }

    get status(): FormControl {
        return this.form?.get('status') as FormControl;
    }

    get assistanceDate(): FormControl {
        return this.form?.get('assistanceDate') as FormControl;
    }

    get enteredDate(): FormControl {
        return this.form?.get('enteredDate') as FormControl;
    }

    get modifiedDate(): FormControl {
        return this.form?.get('clientId') as FormControl;
    }

    get pledgeTypeName(): FormControl {
        return this.form?.get('pledgeTypeName') as FormControl;
    }

    constructor(private _efsService: EfsService,
                private _pledgeTypeService: PledgeTypeService,
                private _formBuilder: UntypedFormBuilder,
                private _store: Store<fromRoot.AppState>) {
        this.loggedInUser$ = this._store.pipe(select(selectSecurityStateDataCurrentUser));
        this.assistanceTypes$ = this._store.pipe(select(selectSecurityStateDataSystemDataAssistanceType));
        this.currentClient$ = this._store.pipe(select(selectClientDataCurrentClient));
        this.currentClientEfsList$ = this._store.pipe(select(selectEfsDataCurrentClientEfsList));
        this.selectedEfs$ = this._store.pipe(select(selectEfsDataCurrentSelectedEfs));
        this.allPledgeTypes$ = this._store.pipe(select(selectPledgeTypeDataAllPledgeTypes));
    }

    existingAssistancerErrorMessage = '';
    isLoading: boolean;
    userTypes = UserType;
    selectedPledgeType: PledgeType;
    efsPledgeTypes: PledgeType[] = [];
    efsFilteredList: Efs[] = [];
    selectedRow: Efs;

    @ViewChild('confirmPrompt')
    confirmPrompt: ClientEfsConfirmationComponent;

    assistanceTypes: LookUp[];
    assistanceTypes$: Observable<LookUp[]>;

    currentClient: Client;
    currentClient$: Observable<Client>;

    currentClientEfsList: Efs[];
    currentClientEfsList$: Observable<Efs[]>;

    selectedEfs: Efs;
    selectedEfs$: Observable<Efs>;

    allPledgeTypes: PledgeType[];
    allPledgeTypes$: Observable<PledgeType[]>;

    loggedInUser: User;
    loggedInUser$: Observable<User>;

    form: UntypedFormGroup;

    tableHeaders: ITableHeader[] = [
        {text: 'EFS Cycle', sortKey: 'pledgeTypeName'},
        {text: 'Assistance Type', sortKey: 'assistanceType'},
        {text: 'Date', sortKey: 'assistanceDate'},
        {text: 'Agency Name', sortKey: 'AgencyName', class: 'hidden-sm-down'}
    ];

    // sorting
    currentSortName = 'Confirmed';
    currentSorting = false;

    ngOnInit(): void {
        this._pledgeTypeService.getAllPledgeTypes();
        this._pledgeTypeService.getPledgeTypes('', 'Name', false, 100, 0);
        this.loggedInUser$.subscribe(user => this.loggedInUser = user);
        this.assistanceTypes$.subscribe(assistanceTypes => this.assistanceTypes = assistanceTypes);
        this.currentClient$.subscribe(currentClient => this.currentClient = currentClient);
        this.currentClientEfsList$.subscribe(currentClientEfsList => this.currentClientEfsList = currentClientEfsList);
        this.selectedEfs$.subscribe(selectedEfs => this.selectedEfs = selectedEfs);
        this.allPledgeTypes$.subscribe(allPledgeTypes => this.allPledgeTypes = allPledgeTypes);
        this.currentClient$.pipe(
            filter(client => !!client))
            .subscribe(client => {
                this.currentClient = client;
                this.filterEfs();
            });

        this.allPledgeTypes$.pipe(
            filter(allPledgeTypes => !!allPledgeTypes),
            map((pledgeTypes => pledgeTypes.filter(p => p.isEfs))), )
            .subscribe((allPledgeTypes) => {
                this.efsPledgeTypes = [];
                if (this.loggedInUser && this.loggedInUser.userType === UserType.SystemAdmin) {
                    for (const pledgeType of allPledgeTypes) {
                        if (moment(pledgeType.efsEndDate).isSameOrAfter(moment())) {
                            this.efsPledgeTypes.push(pledgeType);
                        }
                    }
                } else {
                    for (const pledgeType of allPledgeTypes) {
                        if (this.loggedInUser.agency) {
                            if (this.loggedInUser.agency.pledgeTypeIds
                                .some(x => x === pledgeType.id && moment(pledgeType.efsEndDate)
                                    .isSameOrAfter(moment()))) {
                                this.efsPledgeTypes.push(pledgeType);
                            }
                        }
                    }
                }

                if (this.efsPledgeTypes.length === 1) {
                    if (this.pledgeTypeId && this.pledgeTypeName) {
                        this.pledgeTypeId.setValue(this.efsPledgeTypes[0].id);
                        this.pledgeTypeName.setValue(this.efsPledgeTypes[0].name);
                    }
                }
            });

        this.currentClientEfsList$
            .exists()
            .subscribe(() => {
                this.efsFilteredList = this.selectedPledgeType
                    ? _.filter(this.currentClientEfsList, {pledgeTypeId: this.selectedPledgeType.id})
                    : this.currentClientEfsList;
            });

        this.setClientName();
        this.initForm();
        this.filterEfs();

        // disable filds based on current user
        if (this.loggedInUser) {
            if (this.loggedInUser.userType === this.userTypes.Viewer) {
                this.form.controls.pledgeTypeId.disable();
                this.form.controls.assistanceType.disable();
                this.form.controls.assistanceDate.disable();
                // .setValue(({value: null, disabled: 'disable'}, { onlySelf: true }));
            } else {
                this.form.controls.pledgeTypeId.enable();
                this.form.controls.assistanceType.enable();
                this.form.controls.assistanceDate.enable();
            }
        }
    }

    // set client name
    setClientName(): string {
        if (this.currentClient) {
            return this.currentClient.firstName + ' ' + this.currentClient.lastName;
        }
        return '';
    }

    // set client zip code
    setClientZipCode(): string {
        if (this.currentClient) {
            return this.currentClient.zipCode;
        }
        return '';
    }

    initForm(): void {
        this.form = this._formBuilder.group({
            id: new FormControl(null),
            clientId: new FormControl(this.currentClient.id, Validators.required),
            agencyId: new FormControl(this.loggedInUser.agencyId ? this.loggedInUser.agencyId : null),
            agencyName: new FormControl(null),
            assistanceType: new FormControl(null, Validators.compose([Validators.required, duplicateAssistanceTypeValidator.bind(this)])),
            pledgeTypeId: new FormControl(null, Validators.compose([Validators.required])),
            pledgeTypeName: new FormControl(null, Validators.required),
            zipCode: new FormControl(this.currentClient.zipCode, Validators.required),
            description: new FormControl(null),
            confirmed: new FormControl(EfsStatusType.Confirmed),
            status: new FormControl(StatusType.Enabled, Validators.required),
            enteredDate: new FormControl(moment().format(SHORT_DATE_FORMAT)),
            modifiedDate: new FormControl(moment().format(SHORT_DATE_FORMAT)),
            assistanceDate: new FormControl({value: moment().format(SHORT_DATE_FORMAT), disabled: false},
                Validators.compose([Validators.required, Validators.isDateValid, Validators.dateRange(moment().subtract(60, 'days'), moment())]))
        });

        this.selectedEfs$
            .modelExists()
            .subscribe((efs) => {
                const fields = Object.assign({}, efs, {});
                if (fields.assistanceDate) {
                    (fields as any).assistanceDate = moment(fields.assistanceDate);
                    (fields as any).assistanceDate.toString = () => (fields as any).assistanceDate.format('L');
                }

                if (fields.enteredDate) {
                    (fields as any).enteredDate = moment(fields.enteredDate);
                    (fields as any).enteredDate.toString = () => (fields as any).enteredDate.format('MM/DD/YYYY HH:mm a');
                }

                if (fields.modifiedDate) {
                    (fields as any).modifiedDate = moment(fields.modifiedDate);
                    (fields as any).modifiedDate.toString = () => (fields as any).modifiedDate.format('L');
                }
                this.assistanceDate.setValue(formatDate(fields.assistanceDate, 'L'));
                this.modifiedDate.setValue(formatDate(fields.modifiedDate, 'L'));
                this.enteredDate.setValue(formatDate(fields.enteredDate, 'MM/DD/YYYY HH:mm a'));
                this.pledgeTypeName.setValue(fields.pledgeTypeName);
                this.form.patchValue(fields);
            });

        this.assistanceDate.valueChanges
            .subscribe(() => {
                this.form.markAsDirty();
            });

        this.pledgeTypeId.valueChanges.pipe(
            combineLatestWith(this.allPledgeTypes$.exists()))
            .subscribe(([id, pledgeTypes]) => {
                this.pledgeTypeName.setValue(normalize(id) ? pledgeTypes.find((efs) => efs.id === id).name : null);
            });

        this.pledgeTypeId.valueChanges.pipe(
            combineLatestWith(this.selectedEfs$, this.pledgeTypeId.valueChanges),
            observeOn(asapScheduler))
            .subscribe(() => this.assistanceType.updateValueAndValidity());

        function duplicateAssistanceTypeValidator(): any {
            if (!this.assistanceType) {
                return;
            }
            const assistanceType = this.assistanceType.value;
            const pledgeTypeId = this.pledgeTypeId.value;
            if (assistanceType) {
                const efs: Efs = _.find<Efs>(this.currentClientEfsList, {
                    assistanceType: assistanceType as any,
                    pledgeTypeId: pledgeTypeId as any
                } as any);
                if (this.selectedEfs) {// edit EFS
                    if (efs && efs.id !== this.selectedEfs.id) {
                        return {duplicateAssistanceType: '"' + assistanceType + '"' + ' assistance type already exists for the selected  EFS cycle'};
                    } else {
                        return null;
                    }
                } else {// add new EFS
                    if (efs) {
                        return {duplicateAssistanceType: '"' + assistanceType + '"' + ' assistance type already exists for the selected  EFS cycle'};
                    } else {
                        return null;
                    }
                }
            }
        }
    }

    sortItems(options: ITableHeader): void {
        this.currentSortName = options.sortKey;
        this.currentSorting = !options.ascending;
        this.filterEfs();
    }

    // set Current Selected Assistance
    setClickedRow(selectedEfs: Efs): void {
        if (this.selectedRow === selectedEfs) {
            this.selectedRow = null;
            this._efsService.setCurrentSelectedEfs(null);
            this.resetScreenValues();
        } else {
            this.selectedRow = selectedEfs;
            this._efsService.setCurrentSelectedEfs(selectedEfs);
        }
    }

    setMarkAsTouched(): void {
        this.form.markAsTouched();
    }

    filterByEfsCycle(pledgeTypeId): void {
        this.selectedPledgeType = (_.find(this.allPledgeTypes, {id: pledgeTypeId}) as any);
        this.efsFilteredList = pledgeTypeId !== ''
            ? _.filter(this.currentClientEfsList, {pledgeTypeId}) as any
            : this.currentClientEfsList;
        this.resetScreenValues();
    }

    filterEfs(): void {
        this._efsService.getCurrentClientEfsList(this.currentClient.id, '');
        if (this.selectedPledgeType) {
            this.filterByEfsCycle(this.selectedPledgeType.id);
        }
    }

    isSystemAdmin(): boolean {
        if (!this.loggedInUser) {
            return false;
        }
        return this.loggedInUser.userType === UserType.SystemAdmin;
    }


    showConfirmPrompt(): void {
        this.confirmPrompt.isReprint = !this.form.dirty && this.selectedEfs && !!this.selectedEfs.id;

        this.confirmPrompt
            .open().pipe(
            filter(confirmed => confirmed))
            .subscribe(() => this.submit());
    }

    isClientValid(): boolean {
        if (this.currentClient) {
            return !!this.currentClient.county;
        }
    }

    // format Agency Name
    formatAgencyName(agencyId: string): string {
        if (this.currentClientEfsList) {
            for (const efs of this.currentClientEfsList) {
                if (efs.agencyId === agencyId) {
                    return efs.agencyName;
                }
            }
        }
        return 'missing agency';
    }

    submit(): void {
        const efs = this.form.value;

        if (!this.form.valid) {
            this.form.markAsTouched();
            return;
        } else {
            if (this.selectedEfs) {
                this._efsService.updateEfs(efs)
                    .subscribe((data) => {
                        this.pledgeTypeName.setValue(efs.pledgeTypeName);
                        this.filterEfs();
                        this.selectedRow = data;
                        this.showConfirmPrompt();
                        this.form.markAsUntouched();
                    });
            } else {
                const addEfs = Efs.fromJS({
                    clientId: efs.clientId,
                    agencyId: efs.agencyId,
                    agencyName: efs.agencyName,
                    assistanceType: efs.assistanceType,
                    pledgeTypeId: efs.pledgeTypeId,
                    pledgeTypeName: efs.pledgeTypeName,
                    zipCode: efs.zipCode,
                    description: efs.description,
                    confirmed: efs.confirmed,
                    status: efs.status,
                    enteredDate: efs.enteredDate,
                    modifiedDate: efs.modifiedDate,
                    assistanceDate: efs.assistanceDate
                });
                this._efsService.addEfs(addEfs)
                    .subscribe((data) => {
                        this.pledgeTypeName.setValue(efs.pledgeTypeName);
                        this.filterEfs();
                        this.selectedRow = data;
                        this.showConfirmPrompt();
                        this.form.markAsUntouched();
                    });
            }
        }
    }

    delete(): void {
        swal.fire({
            title: 'Delete EFS',
            text: 'Are you sure you want to delete this EFS entry?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            confirmButtonColor: '#d33',
            cancelButtonText: 'No'
        }).then((result) => {
            if (result.isConfirmed) {
                if (this.selectedEfs) {
                    this._efsService.deleteEfs(this.selectedEfs.id)
                        .subscribe(() => {
                            this._efsService.setCurrentSelectedEfs(null);
                            this.filterEfs();
                            this.resetScreenValues();
                        });
                }
            }
        });

    }

    Cancel(): void {
        this.resetScreenValues();
    }

    resetScreenValues(): void {
        this._efsService.setCurrentSelectedEfs(null);
        this.selectedRow = void 0;
        if (this.efsPledgeTypes.length !== 1) {
            this.pledgeTypeId.setValue(null);
        }
        this.assistanceType.setValue(null);
        this.id.setValue(null);
        this.assistanceDate.setValue(moment().format(SHORT_DATE_FORMAT));
        this.form.markAsUntouched();
    }

    ngOnDestroy(): void {
        this._efsService.setCurrentSelectedEfs(null);
        this._efsService.setCurrentSelectedEfsAction(null);
    }

    ngAfterViewInit(): void {
    }
}
