import {createFeatureSelector, createSelector} from '@ngrx/store';
import {IApplicationCountyData} from '../IApplicationCountyData';


const applicationCountyFeatureSelector = createFeatureSelector<IApplicationCountyData>("applicationCounty");

export const selectApplicationCountyPagedApplicationCounties = createSelector(
    applicationCountyFeatureSelector,
    (state) => state.pagedApplicationCounties
);

export const selectApplicationCountyPagedApplicationTotalCount = createSelector(
    applicationCountyFeatureSelector,
    (state) => state.pagedApplicationCounties?.totalCount
);

export const selectApplicationCountyCurrentSelectedApplicationCounty = createSelector(
    applicationCountyFeatureSelector,
    (state) => state.currentSelectedApplicationCounty
);

export const selectApplicationCountyFundAdministrativeAgencyFundTypes = createSelector(
    applicationCountyFeatureSelector,
    (state) => state.fundAdministrativeFundTypes
);
