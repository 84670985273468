import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {FormControl, UntypedFormBuilder} from '@angular/forms';
import {Observable} from 'rxjs';
import {EfsReportUrls} from '../../../../../api/EfsReportUrls';
import {FileDownloadService} from '../../../../../services/FileDownloadService';
import {AgencyListModalComponent} from '../../../agency/AgencyListModalComponent';
import {Agency, EfsReport, User, UserType} from '../../../../../services/api.client';
import {EfsReportService} from '../../../../../services/EfsReportService';
import {select, Store} from '@ngrx/store';
import * as fromRoot from '../../../../../store';
import {selectSecurityStateDataCurrentUser, selectSecurityStateDataSecurityToken} from '../../../../../store/selectors/security.selectors';

@Component({
    selector: '[efsReportForm]',
    templateUrl: './EfsReportFormComponent.html',
    styleUrls: ['./EfsReportFormComponent.scss'],
})

export class EfsReportFormComponent implements OnInit {
    public form = this.fb.group({
        pledgeTypeId: new FormControl(''),
        agencyInput: ['All Agencies'],
        agencyId: [''],
    });

    get pledgeTypeId(): FormControl {
        return this.form.get('pledgeTypeId') as FormControl;
    }
    get agencyId(): FormControl {
        return this.form.get('agencyId') as FormControl;
    }
    get agencyInput(): FormControl {
        return this.form.get('agencyInput') as FormControl;
    }

    @Input('showDateRange')
    showDateRange: boolean;

    @Input('showPledgeTypes')
    showPledgeTypes: boolean;

    @Input('showAgencies')
    showAgencies: boolean;

    @ViewChild(AgencyListModalComponent)
    agencyListModal: AgencyListModalComponent;

    private securityToken: string;
    private securityToken$: Observable<string>;

    currentUser: User;
    currentUser$: Observable<User>;

    constructor(
        private fb: UntypedFormBuilder,
        private _fileDownloadService: FileDownloadService,
        private _efsReportService: EfsReportService,
        private _store: Store<fromRoot.AppState>
    ) {
        this.currentUser$ = this._store.pipe(select(selectSecurityStateDataCurrentUser));
        this.securityToken$ = this._store.pipe(select(selectSecurityStateDataSecurityToken));
    }

    ngOnInit(): void {
        this.currentUser$.subscribe(user => {
            this.currentUser = user;
            if ((this.currentUser.userType !== UserType.SystemAdmin)
                && (this.currentUser.userType !== UserType.FundAdmin)) {
                this.agencyId.setValue(this.currentUser.agencyId);
                this.agencyInput.setValue(this.currentUser.agency.agencyName);
                this.agencyInput.disable();
            }
        });
        this.securityToken$.subscribe(securityToken => this.securityToken = securityToken);
    }

    onAgencyChange(agency: Agency): void {
        if (agency) {
            this.agencyId.setValue(agency.id);
            this.agencyInput.setValue(agency.agencyName);
        } else {
            this.agencyInput.setValue('All Agencies');
            this.agencyId.setValue(null);
        }

    }

    submit(): Observable<any> | void {
        return void 0;
    }

    submitEfsReport(): Observable<EfsReport[]> {
        return this._efsReportService.buildEfsReport(this.pledgeTypeId.value, this.agencyId.value);
    }

    downloadEfsReport(): string {
        const model = {
            pledgeTypeId: this.pledgeTypeId.value,
            agencyId: this.agencyId.value,
            securityToken: this.securityToken
        };
        return this._fileDownloadService.buildUrl(EfsReportUrls.DOWNLOAD_EFS_REPORT, Object.assign({}, model));
    }
}
