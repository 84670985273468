import {createFeatureSelector, createSelector} from '@ngrx/store';
import {IPledgeData} from '../IPledgeData';

const pledgeDataFeatureSelector = createFeatureSelector<IPledgeData>('pledge');

export const selectConfirmedUwdaPledgeDataPledges = createSelector(
    pledgeDataFeatureSelector,
    (state) => state.confirmedUwdaPledges
);

export const selectPledgeDataPledges = createSelector(
    pledgeDataFeatureSelector,
    (state) => state.pledges
);

export const selectPledgeDataConfirmedUwdaPledgesTotalCount = createSelector(
    pledgeDataFeatureSelector,
    (state) => state.confirmedUwdaPledges?.totalCount
);

export const selectPledgeDataIsPledgesLoading = createSelector(
    pledgeDataFeatureSelector,
    (state) => state.isPledgesLoading
);

export const selectPledgeDataManualPendingPledges = createSelector(
    pledgeDataFeatureSelector,
    (state) => state.manualPendingPledges
);

export const selectPledgeDataCurrentClientPledgeList = createSelector(
    pledgeDataFeatureSelector,
    (state) => state.currentClientPledgeList
);

export const selectPledgeDataCurrentSelectedPledge = createSelector(
    pledgeDataFeatureSelector,
    (state) => state.currentSelectedPledge
);

export const selectPledgeDataCurrentPledgeTypeBalance = createSelector(
    pledgeDataFeatureSelector,
    (state) => state.currentPledgeTypeBalance
);

export const selectPledgeDataPledgesTotalCount = createSelector(
    pledgeDataFeatureSelector,
    (state) => state.pledges?.totalCount
);
