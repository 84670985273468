import {Component, Input, ChangeDetectionStrategy, Output, EventEmitter} from '@angular/core';
import {PagedList} from '../../../../models/PagedList';
import {ITableHeader} from '../../../shared/table/TableComponent';


@Component({
  selector: 'pagedReportTable',
  templateUrl: './PagedReportTableComponent.html',
  styleUrls: ['./PagedReportTableComponent.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PagedReportTableComponent {
  @Output('tableUpdate')
  tableUpdate = new EventEmitter<PagedList<any>>();
  @Input('headers')
  tableHeaders: ITableHeader[];
  @Input('rows')
  rows: any[][];
  @Input('pagedList')
  pagedList: PagedList<any>;

  @Output()
  headerClick = new EventEmitter<string>();

  // hack - don't emit page change after a limit change
  ignorePageChange = false;

  onPageChanged(page: number): void {
    if (this.ignorePageChange) {
      this.ignorePageChange = false;
      return;
    }
    const offset = (page - 1) * this.pagedList.limit;
    if (page === offset) {
      return;
    }
    const pl = Object.assign({}, this.pagedList, {offset});
    this.tableUpdate.emit(pl);
  }

  onLimitChanged(limit: number): void {
    if (limit === this.pagedList.limit) {
      return;
    }
    if (this.pagedList.offset !== 0) {
      this.ignorePageChange = true;
    }
    // set offset back to zero on a page change,
    const pl = Object.assign({}, this.pagedList, {limit, offset: 0});
    this.tableUpdate.emit(pl);
  }
}
