import {createAction, props} from '@ngrx/store';
import {FederalPovertyLevel} from '../../services/api.client';

export const federalPovertyLevelsDataIsLoading  = createAction(
    '[Federal Poverty] FEDERALPOVERTYLEVELS_DATA_ISLOADING');

export const setCurrentSelectedFederalPovertyLevel  = createAction(
    '[Federal Poverty] SET_CURRENT_SELECTED_FEDERALPOVERTYLEVEL',
    props<{ payload: FederalPovertyLevel }>());

export const federalPovertyLevelsDataIsLoaded  = createAction(
    '[Federal Poverty] FEDERALPOVERTYLEVELS_DATA_ISLOADED',
    props<{ payload: FederalPovertyLevel }>());

export const currentClientFederalPovertyLevelsData  = createAction(
    '[Federal Poverty] CURRENT_CLIENT_FEDERALPOVERTYLEVELS_DATA',
    props<{ payload: number }>());

export const clearCurrentSelectedFederalPovertyLevel  = createAction(
    '[Federal Poverty] CLEAR_CURRENT_SELECTED_FEDERALPOVERTYLEVEL',
    props<{ payload: FederalPovertyLevel }>());
