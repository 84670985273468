import {observeOn, tap} from 'rxjs/operators';
import {Component, OnInit, AfterViewInit, ViewChild, forwardRef, OnDestroy} from '@angular/core';
import {Client, EfnCase, User} from '../../../../../../services/api.client';
import {EfnCaseFormComponent} from '../form/EfnCaseFormComponent';
import {EfnCaseHelper} from '../EfnHelper';
import {Observable, asapScheduler} from 'rxjs';
import {AutoDisableParent} from '../../../../../../directives/AutoDisableDirective';
import {select, Store} from '@ngrx/store';
import * as fromRoot from '../../../../../../store';
import {selectClientDataCurrentClient} from '../../../../../../store/selectors/client.selectors';
import {selectEfnDataSelectedEfnCase} from '../../../../../../store/selectors/efn.selectors';

@Component({
  selector: 'efnCase',
  templateUrl: './EditEfnCaseComponent.html',
  styleUrls: ['./EditEfnCaseComponent.scss'],
  providers: [EfnCaseHelper, {provide: AutoDisableParent, useExisting: forwardRef(() => EditEfnCaseComponent)}],
})

export class EditEfnCaseComponent implements OnInit, AfterViewInit {

  client: Client;
  client$: Observable<Client>;

  @ViewChild(EfnCaseFormComponent)
  efnCaseForm: EfnCaseFormComponent;

  efnCase: EfnCase;
  efnCase$: Observable<EfnCase>;

  user: User;
  user$: Observable<User>;

  constructor(public helper: EfnCaseHelper, private _store: Store<fromRoot.AppState>) {
    this.client$ = this._store.pipe(select(selectClientDataCurrentClient));
    this.efnCase$ = this._store.pipe(select(selectEfnDataSelectedEfnCase));
  }


  ngOnInit(): void {
    this.client$.subscribe(client => this.client = client);
    this.efnCase$.subscribe(efnCase => this.efnCase = efnCase);
  }

  setupChild(): void {
    if (!this.helper.canEdit()) {
      this.efnCaseForm.form.disable();
    }
    this.efnCaseForm.form.patchValue({ ...this.efnCase, status: this.efnCase.status.toString().toLowerCase() });
    this.efnCaseForm.deadline.setValue(this.efnCase.deadline.format('MM/DD/YYYY'));

    if (this.efnCase.company) {
      this.efnCaseForm.companyName.setValue(this.efnCase.company.name);
      this.efnCaseForm.selectedCompany$.next(this.efnCase.company);
    }

    if (this.detectOneTimeCompany()) {
      this.efnCaseForm.usesOneTimeCompany.setValue(true);
    } else {
      this.efnCaseForm.usesOneTimeCompany.setValue(false);
    }
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.efnCase$
          .modelExists().pipe(
          observeOn(asapScheduler))
          .subscribe(() => this.setupChild());

    });


  }

  detectOneTimeCompany(): boolean {
    return this.efnCaseForm.paymentTo.value ||
        this.efnCaseForm.payeeAddress.value ||
        this.efnCaseForm.payeeAddress2.value ||
        this.efnCaseForm.payeeCity.value ||
        this.efnCaseForm.payeeState.value ||
        this.efnCaseForm.payeeZip.value;
  }

  submit(): Observable<EfnCase | null> {
    return this.efnCaseForm
        .updateCase(this.efnCase)
        .pipe(tap((efnCase) => {
          this.efnCase = efnCase;
        }));
  }

  printCase(): void {
    window.print();
  }
}
