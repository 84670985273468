export const formControlErrorMapper = [
    {
        formControlName: 'fundPledgeTypeId',
        humanizedError: '* Fund type is required.'
    },
    {
        formControlName: 'totalNeed',
        humanizedError: '* Total amount needed is required.'
    },
    {
        formControlName: 'companies',
        humanizedError: '* One or more companies (or payees) has an invalid value.'
    },
    {
        formControlName: 'adults',
        humanizedError: '* One or more adults has an invalid value.'
    },
    {
        formControlName: 'minors',
        humanizedError: '* One or more minors has an invalid value.'
    },
    {
        formControlName: 'assistanceApplicationAreasOfNeed',
        humanizedError: '* You must check at least one type of assistance.'
    },
    {
        formControlName: 'files',
        humanizedError: '* Missing file(s). Expand "What documents do I need" for more information.'
    },
    {
        formControlName: 'otherDenialReason',
        humanizedError: '* Denial reason explanation is required.'
    }
];

export const fileErrorNames = [
    'photoIdRequired',
    'utilityOrEquivalentRequired',
    'incomeDocumentRequiredForSelfEmployed',
    'incomeDocumentRequiredForNonSelfEmployed',
    'childcareBillRequired',
    'autoRepairBillRequired',
    'utilityBillOrShutoffNoticeRequired',
    'evictionCourtOrderRequired',
    'leaseAgreementRequired',
    'delinquentRentNoticeRequired',
];

