<ng-template [ngIf]="rows">
  <table-dynamic *ngIf="rows.length"
                 [headers]="tableHeaders"
                 [showPagination]="true"
                 [initialLimitValue]="100"
                 [currentPage]="pagedList.pageIndex"
                 [totalCount]="pagedList.totalCount"
                 (headerClick)="headerClick.emit($event)"
                 (pageChanged)="onPageChanged($event)"
                 (limitChange)="onLimitChanged($event)">
    <tr *ngFor="let columns of rows">
      <td *ngFor="let data of columns">{{ data }}</td>
    </tr>
  </table-dynamic>
  <table-dynamic *ngIf="rows.length === 0" [headers]="tableHeaders" [showPagination]="false">
    <tr>
      <td [attr.colspan]="tableHeaders.length" style="text-align: center">Empty</td>
    </tr>
  </table-dynamic>
</ng-template>
