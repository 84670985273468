import {Component, OnInit, ElementRef, AfterViewInit} from '@angular/core';
import {Router, NavigationEnd} from '@angular/router';
import {Banner, LoginResult} from '../../../services/api.client';
import {ConfigService} from '../../../services/ConfigService';
import {BannerClient} from '../../../services/api.client';
import {BehaviorSubject} from 'rxjs';

declare var jQuery: any;

@Component({
  selector: 'shell',
  host: {
    '[class.nav-static]': 'config.state["nav-static"]',
    '[class.chat-sidebar-opened]': 'chatOpened',
    '[class.app]': 'true',
    id: 'app'
  },
  templateUrl: './ShellComponent.html',
  styleUrls: ['./ShellComponent.scss'],
})
export class ShellComponent implements OnInit, AfterViewInit {

  config: any;
  configFn: any;
  $sidebar: any;
  el: ElementRef;
  chatOpened: boolean;
  router: Router;

  loginResult: LoginResult;

  banner = new BehaviorSubject<Banner>(null);
  banner$ = this.banner.asObservable();

  constructor(config: ConfigService,
              el: ElementRef,
              router: Router,
              private bannerClient: BannerClient) {
    this.el = el;
    this.config = config.getConfig();
    this.configFn = config;
    this.chatOpened = false;
    this.router = router;


    jQuery.fn.onPositionChanged = function(trigger, millis): any {
      if (millis == null) {
        millis = 100;
      }
      const o = jQuery(this[0]); // our jquery object
      if (o.length < 1) {
        return o;
      }

      let lastPos = null;
      let lastOff = null;
      setInterval(() => {
        if (o == null || o.length < 1) {
          return o;
        } // abort if element is non existend eny more
        if (lastPos == null) {
          lastPos = o.position();
        }
        if (lastOff == null) {
          lastOff = o.offset();
        }
        const newPos = o.position();
        const newOff = o.offset();
        if (lastPos.top !== newPos.top || lastPos.left !== newPos.left) {
          jQuery(this).trigger('onPositionChanged', {lastPos, newPos});
          if (typeof (trigger) === 'function') {
            trigger(lastPos, newPos);
          }
          lastPos = o.position();
        }
        if (lastOff.top !== newOff.top || lastOff.left !== newOff.left) {
          jQuery(this).trigger('onOffsetChanged', {lastOff, newOff});
          if (typeof (trigger) === 'function') {
            trigger(lastOff, newOff);
          }
          lastOff = o.offset();
        }
      }, millis);

      return o;
    };
  }

  ngOnInit(): void {
    this.bannerClient.banner().subscribe(b => {
      this.banner.next(b);
    })

    jQuery(document).on('click', 'a[href="#"]', (e) => e.preventDefault());
    if (localStorage.getItem('nav-static') === 'true') {
      this.config.state['nav-static'] = true;
    }

    const $el = jQuery(this.el.nativeElement);
    this.$sidebar = $el.find('[sidebar]');

    this.$sidebar.on('mouseenter', this._sidebarMouseEnter.bind(this));
    this.$sidebar.on('mouseleave', this._sidebarMouseLeave.bind(this));

    this.checkNavigationState();

    this.$sidebar.on('click', () => {
      if (jQuery('#app').is('.nav-collapsed')) {
        this.expandNavigation();
      }
    });

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.collapseNavIfSmallScreen();
        window.scrollTo(0, 0);
      }
    });

    if ('ontouchstart' in window) {
      this.enableSwipeCollapsing();
    }

    this.$sidebar.find('.collapse').on('show.bs.collapse', function(e): void {
      // execute only if we're actually the .collapse element initiated event
      // return for bubbled events
      if (e.target !== e.currentTarget) {
        return;
      }

      const $triggerLink = jQuery(this).prev('[data-toggle=collapse]');
      jQuery($triggerLink.data('parent'))
        .find('.collapse.in').not(jQuery(this)).collapse('hide');
    })
    /* adding additional classes to navigation link li-parent
     for several purposes. see navigation styles */
      .on('show.bs.collapse', function(e): void {
        // execute only if we're actually the .collapse element initiated event
        // return for bubbled events
        if (e.target !== e.currentTarget) {
          return;
        }

        jQuery(this).closest('li').addClass('open');
      }).on('hide.bs.collapse', function(e): void {
      // execute only if we're actually the .collapse element initiated event
      // return for bubbled events
      if (e.target !== e.currentTarget) {
        return;
      }

      jQuery(this).closest('li').removeClass('open');
    });
  }

  ngAfterViewInit(): void {
    this.initSidebarScroll();
    jQuery(window).on('sn:resize', this.initSidebarScroll.bind(this));
  }

  initSidebarScroll(): void {
    const $sidebarContent = this.$sidebar.find('.js-sidebar-content');
    if (this.$sidebar.find('.slimScrollDiv').length !== 0) {
      $sidebarContent.slimscroll({
        destroy: true
      });
    }
    $sidebarContent.slimscroll({
      height: window.innerHeight,
      size: '4px'
    });
  }

  toggleSidebarListener(state): void {
    if (state === 'static') {
      this.toggleNavigationState();
    } else {
      this.toggleNavigationCollapseState();
    }
    localStorage.setItem('nav-static', this.config.state['nav-static']);
  }

  toggleChatListener(): void {
    jQuery(this.el.nativeElement).find('.chat-notification-sing').remove();
    this.chatOpened = !this.chatOpened;
  }

  toggleNavigationState(): void {
    this.config.state['nav-static'] = !this.config.state['nav-static'];
  }

  expandNavigation(): void {
    // this method only makes sense for non-static navigation state
    if (this.isNavigationStatic()
      && (this.configFn.isScreen('lg') || this.configFn.isScreen('xl'))) {
      return;
    }

    jQuery('#app').removeClass('nav-collapsed');
    this.$sidebar.find('.active .active').closest('.collapse').collapse('show')
      .siblings('[data-toggle=collapse]').removeClass('collapsed');
  }

  collapseNavigation(): void {
    // this method only makes sense for non-static navigation state
    if (this.isNavigationStatic()
      && (this.configFn.isScreen('lg') || this.configFn.isScreen('xl'))) {
      return;
    }

    jQuery('#app').addClass('nav-collapsed');
    this.$sidebar.find('.collapse.in').collapse('hide')
      .siblings('[data-toggle=collapse]').addClass('collapsed');
  }

  /**
   * Check and set navigation collapse according to screen size and navigation state
   */
  checkNavigationState(): void {
    if (this.isNavigationStatic()) {
      if (this.configFn.isScreen('sm')
        || this.configFn.isScreen('xs') || this.configFn.isScreen('md')) {
        this.collapseNavigation();
      }
    } else {
      if (this.configFn.isScreen('lg') || this.configFn.isScreen('xl')) {
        setTimeout(() => {
          this.collapseNavigation();
        }, this.config.settings.navCollapseTimeout);
      } else {
        this.collapseNavigation();
      }
    }
  }

  isNavigationStatic(): boolean {
    return this.config.state['nav-static'] === true;
  }

  toggleNavigationCollapseState(): void {
    if (jQuery('#app').is('.nav-collapsed')) {
      this.expandNavigation();
    } else {
      this.collapseNavigation();
    }
  }

  _sidebarMouseEnter(): void {
    if (this.configFn.isScreen('lg') || this.configFn.isScreen('xl')) {
      this.expandNavigation();
    }
  }

  _sidebarMouseLeave(): void {
    if (this.configFn.isScreen('lg') || this.configFn.isScreen('xl')) {
      this.collapseNavigation();
    }
  }

  enableSwipeCollapsing(): void {
    jQuery('.content-wrap').swipe({
      threshold: this.configFn.isScreen('xs') ? 100 : 200
    });
  }

  collapseNavIfSmallScreen(): void {
    if (this.configFn.isScreen('xs')
      || this.configFn.isScreen('sm') || this.configFn.isScreen('md')) {
      this.collapseNavigation();
    }
  }

}
