<clientInfo [hidden]="!(isLoaded$ | async)" [client]="client" [agencyName]="application?.agencyName"></clientInfo>
<div class="row w-100 justify-content-center">
  <a href="#" class="nav-link loader_container mb-3 mx-auto" *ngIf="!(isLoaded$ | async)">
    <div class="loader">
      <svg class="circular" viewBox="25 25 50 50">
        <circle class="path" cx="50" cy="50" r="20" fill="none" stroke-miterlimit="10"></circle>
      </svg>
    </div>
  </a>
</div>

<div class="row" [hidden]="!(isLoaded$ | async)">
  <div class="col-xs-12">
    <form clientEnrollmentForm class="form-horizontal" [application]="application" (onSubmit)="onSubmit($event)"
          (onContinue)="onContinue()"></form>
    <clientNoteTable [application]="application"></clientNoteTable>
  </div>
</div>
