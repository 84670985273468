import {createFeatureSelector, createSelector} from '@ngrx/store';
import {IPledgeTypeData} from '../IPledgeTypeData';

const pledgeTypeDataFeatureSelector = createFeatureSelector<IPledgeTypeData>("pledgeType");

export const selectPledgeTypeDataPledgeTypes = createSelector(
    pledgeTypeDataFeatureSelector,
    (state) => state.pledgeTypes
);

export const selectPledgeTypeDataPledgeTypesItems = createSelector(
    pledgeTypeDataFeatureSelector,
    (state) => state.pledgeTypes?.items
);

export const selectPledgeTypeDataPledgeTypesTotalCount = createSelector(
    pledgeTypeDataFeatureSelector,
    (state) => state.pledgeTypes?.totalCount
);

export const selectPledgeTypeDataAllPledgeTypes = createSelector(
    pledgeTypeDataFeatureSelector,
    (state) => state.allPledgeTypes
);

export const selectPledgeTypeDataCurrentSelectedPledgeType = createSelector(
    pledgeTypeDataFeatureSelector,
    (state) => state.currentSelectedPledgeType
);
