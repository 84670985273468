import {ICompanyCategoryData} from '../ICompanyCategoryData';
import {createReducer, on} from '@ngrx/store';
import {
    setCompanyCategories,
    setCompanyCategoryThresholds,
    setCurrentPayeeCompanyCategoryAmounts
} from '../actions/companyCategory.actions';

export const initialState: ICompanyCategoryData = {
    categoryThresholds: [],
    currentPayeeCompanyCategoryAmounts: [],
    companyCategories: []
};

export const companyCategoryReducer = createReducer(
    initialState,
    on(setCompanyCategoryThresholds, (state, { payload }) => ({
       ...state,
       categoryThresholds: payload
    })),
    on(setCurrentPayeeCompanyCategoryAmounts, (state, { payload }) => ({
        ...state,
        currentPayeeCompanyCategoryAmounts: payload
    })),
    on(setCompanyCategories, (state, { payload }) => ({
        ...state,
        companyCategories: payload
    }))
);
