<div class="row" *ngIf="client">
  <div class="col-md-12">
    <!--<h1>Customer Information <a class="btn btn-primary pull-right" [routerLink]="['../edit']">Edit Client Information</a></h1>-->
    <!--<h1 class="m-b-0">Customer Information</h1>-->
    <h1 class="text-center">Register Assistance</h1>
    <legend><strong>Client</strong> Information</legend>
    <!--<h1 class="text-center">Customer Information</h1>-->
    <!--<hr class="m-t-0">-->

  </div>
  <div class="col-sm-6">
    <div class="row">
      <label class="col-md-6">Agency:</label>
      <div class="col-md-6">{{ agencyName || 'No Agency' }}</div>

      <label class="col-md-6">Client Name:</label>
      <div class="col-md-6">{{ client.firstName }} {{ client.lastName }}</div>

      <label class="col-md-6">Client Address:</label>
      <div class="col-md-6">
        {{ client.address1 }} {{ client.address2 }} {{ client.city }}, {{ client.state }} {{ client.zipCode }}
      </div>
    </div>
  </div>
  <div class="col-sm-6">
    <div class="row">
      <label class="col-md-4">Client SSN:</label>
      <div class="col-md-8">***-**-{{ client.last4Ssn }}</div>

      <label class="col-md-4">Poverty Level:</label>
      <div class="col-md-8">{{ client.federalPovertyLevelDisplay | percent}}</div>

      <label class="col-md-4">Demographics:</label>
      <div class="col-md-8">
        <label *ngIf="client.isElderly"><input disabled type="checkbox" [checked]="client.isElderly"> Is elderly (60 or
          older)</label><br>
        <label *ngIf="client.isDisabled"><input disabled type="checkbox" [checked]="client.isDisabled"> Is disabled or
          chronically ill</label><br>
        <label *ngIf="client.hasChildrenUnderFive"><input disabled type="checkbox"
                                                          [checked]="client.hasChildrenUnderFive"> Has children under 5
          years old</label><br>
        <label *ngIf="client.isVeteran"><input disabled type="checkbox" [checked]="client.isVeteran"> Is veteran</label><br>
        <label *ngIf="client.isHomeless"><input disabled type="checkbox" [checked]="client.isHomeless"> Is
          homeless</label>
      </div>
    </div>
  </div>
</div>
