import {createFeatureSelector, createSelector} from '@ngrx/store';
import {IClientData} from '../IClientData';

const clientDataFeatureSelector = createFeatureSelector<IClientData>('client');

export const selectClientDataCurrentClient = createSelector(
    clientDataFeatureSelector,
    (state) => state.currentClient
);

export const selectClientDataClientsItems = createSelector(
    clientDataFeatureSelector,
    (state) => state.clients?.items
);

export const selectClientDataClients = createSelector(
    clientDataFeatureSelector,
    (state) => state.clients
);

export const selectClientDataClientsTotalCount = createSelector(
    clientDataFeatureSelector,
    (state) => state.clients?.totalCount
);
