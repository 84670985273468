<ng-template [ngIf]="rows">
  <table-dynamic *ngIf="rows.length" [headers]="tableHeaders" [showPagination]="false">
    <tr *ngFor="let columns of rows">
      <td *ngIf="isManual" class="checkboxCenter">
        <input (change)="toggle($event, columns)"
               type="checkbox"
               class="form-check-input"/>
      </td>
      <td *ngFor="let data of columns">{{ data }}</td>
    </tr>
  </table-dynamic>
  <table-dynamic *ngIf="rows.length === 0" [headers]="tableHeaders" [showPagination]="false">
    <tr>
      <td [attr.colspan]="tableHeaders.length" style="text-align: center">Empty</td>
    </tr>
  </table-dynamic>
</ng-template>
