import {UntypedFormControl, Validators as NgValidators, UntypedFormGroup, ValidatorFn, ValidationErrors, FormGroup} from '@angular/forms';
import {Observable} from 'rxjs';
import * as moment from 'moment';
import {Company} from '../services/api.client';


export class Validators {

    static compose = NgValidators.compose;

    static mustBeValue(mustBeValue): any {
        return (control: UntypedFormControl): { [key: string]: boolean } => control.value !== mustBeValue ? {mustBeValue} : null;
    }

    static required(control: UntypedFormControl): { [key: string]: boolean } {
        const value = control.value;
        let isEmpty: boolean;
        if (typeof value === 'string') {
            isEmpty = value.trim() === '';
        } else if (Array.isArray(value)) {
            isEmpty = value.length === 0;
        } else {
            isEmpty = value === null || value === undefined;
        }

        return isEmpty ? {required: true} : null;
    }

    static email(control: UntypedFormControl): { email: string } {
        const EMAIL_REGEXP = /^[a-z0-9!#$%&'*+\/=?^_`{|}~.-]+@[a-z0-9]([a-z0-9-]*[a-z0-9])?(\.[a-z0-9]([a-z0-9-]*[a-z0-9])?)*$/i;
        if (!control.value) {
            return null;
        }
        if (control.value.length <= 5 || !EMAIL_REGEXP.test(control.value)) {
            return {email: 'invalid email'};
        }
        return null;
    }

    static yearRange(startDate: moment.Moment, endDate: moment.Moment): any {
        return (control: UntypedFormControl) => {
            if (!control.value) {
                return null;
            }
            const value = control.value + '';
            if (value.length !== 4) {
                return {yearRange: 'Please enter valid birth year using four digit format (0-9).'};
            }

            const year = moment(value, 'YYYY');

            if (!year.isBetween(startDate, endDate, 'years', '[]')) {
                return {yearRange: 'The date must be between ' +
                        moment(startDate).format('YYYY') + ' and ' +
                        moment(endDate).format('YYYY') + '.'};
            }
            return null;
        };

    }


    static isDateValid(control: UntypedFormControl): { isDateValid: string } {
        if (!control.value) {
            return null;
        }

        if (!moment(control.value).isValid()) {
            return {isDateValid: 'This is not a valid date.'};
        }
    }

    // target = the formControl which is checked to validate the control
    static requiredWhen(targetControlName: string, targetControlValue: any, allowedValue: any = null): any {

        return (control: UntypedFormControl) => {
            const form = control.root as UntypedFormGroup;
            if (!form || !form.controls || !form.controls[targetControlName]) {
                return null;
            }

            const control2 = form.controls[targetControlName];
            if (control2.value === targetControlValue) {
                if (allowedValue == null) {
                    if (!control.value) {
                        return {requiredWhen: 'This field is required.'};
                    }
                } else {
                    if (!control.value && control.value !== allowedValue) {
                        return {requiredWhen: 'This field is required.'};
                    }
                }
            }
            return null;
        };
    }

    static dateRange(startDate: moment.Moment, endDate: moment.Moment, onlyIfDirty: boolean = false): any {

        return (control: UntypedFormControl) => {
            if (!control.dirty && onlyIfDirty === true) {
                return null;
            }
            if (!control.value) {
                return null;
            }

            if (!moment(control.value).isBetween(startDate, endDate, 'days', '[]')) {
                return {dateRange: 'The selected date must be between ' + moment(startDate).format('L') + ' and ' + moment(endDate).format('L') + '.'};
            }
        };
    }

    static matchFormControl(controlName1: string, controlName2: string): any {
        return (form: UntypedFormGroup): { [key: string]: any } => {
            const control1 = form.controls[controlName1];
            const control2 = form.controls[controlName2];
            if (control1.value !== control2.value) {
                return {matchFormControl: {value: control2.value, mustMatch: control1.value}};
            }
            return null;
        };
    }

    static matchPassword(passwordKey: string, confirmedPasswordKey: string): any {
        const validatorFn = Validators.matchFormControl(passwordKey, confirmedPasswordKey) as any;
        return (form: UntypedFormGroup): { [key: string]: any } => {
            const invalid = validatorFn(form);
            if (invalid) {
                return {matchPassword: invalid.matchFormControl};
            }
            return null;
        };
    }

    static mutuallyExclusive(controlName1: string, controlName2: string, controlName1Display: string, controlName2Display: string): any {
        return (form: UntypedFormGroup): { [key: string]: any } => {
            const control1 = form.controls[controlName1];
            const control2 = form.controls[controlName2];
            if (!control1 || !control2) {
                return null;
            }
            if (control1.value && control2.value) {
                return {mutuallyExclusive: 'You cannot have values for both ' + controlName1Display + ' & ' + controlName2Display + '.'};
            }
            return null;
        };
    }

    static minLength(minLength: number, filters: RegExp[]): any {
        return (control: UntypedFormControl): { [key: string]: any } => {
            const value = control.value || '';
            if (!value) {
                return null;
            }

            const ignoredKeys = filters
                .map((filter) => value.match(filter))
                .filter((filter) => !!filter)
                .reduce((prev, acc) => acc.length + prev, 0);

            if (value.length - ignoredKeys < minLength) {
                return {minLength};
            }
            return null;
        };
    }

    static mustBeChecked(control: UntypedFormControl): { [key: string]: string } {
        if (!control.value) {
            return {mustBeChecked: 'Must be checked'};
        } else {
            return null;
        }
    }

    static atLeastOneChecked(): ValidatorFn {
        return (formGroup: FormGroup): ValidationErrors | null => {
            let isAtLeastOneChecked = false;

            Object.keys(formGroup.controls).forEach(controlName => {
                const control = formGroup.get(controlName);

                if (control && control.value === true) {
                    isAtLeastOneChecked = true;
                }
            });

            return isAtLeastOneChecked ? null : { atLeastOneChecked: true };
        };
    }

    static companyAccountNumber(company$: Observable<Company>): any {
        let company: Company = null;
        company$.subscribe({
            next: (c) => {
                company = c;
            },
            error: () => {
            },
            complete: () => {
                company = null;
            }
        });
        return (control: UntypedFormControl): { [key: string]: any } => {

            if (!company || !company.isAccountValidationEnforced) {
                return null;
            }
            const accountNumber = control.value || '';
            let regex = '';
            if (company.isAccountValidAlphabetical) {
                regex += 'a-zA-Z';
            }
            if (company.isAccountValidNumerical) {
                regex += '0-9';
            }
            if (company.isAccountValidSpecialChars) {
                regex += '\-\(\)';
            }
            const min = company.accountValidationMinLen || 0;
            const max = company.accountValidationMaxLen || 100;
            if (!(new RegExp(`^[${regex}]{${min},${max}}$`)).test(accountNumber)) {
                if ((company.accountValidationMaxLen !== null &&
                        company.accountValidationMinLen !== null &&
                        company.accountValidationMaxLen === company.accountValidationMinLen) &&
                    accountNumber.length !== company.accountValidationMinLen) {
                    return {companyAccountNumber: `Account number must be ${company.accountValidationMinLen} characters.`};
                }
                if (company.accountValidationMinLen && accountNumber.length < company.accountValidationMinLen) {
                    return {companyAccountNumber: `Account number must be at least ${company.accountValidationMinLen} characters.`};
                }
                if (company.accountValidationMaxLen && accountNumber.length > company.accountValidationMaxLen) {
                    return {companyAccountNumber: `Account number cannot be greater than ${company.accountValidationMaxLen} characters.`};
                }
                let allowedCharacters = '';
                if (company.isAccountValidAlphabetical && company.isAccountValidNumerical) {
                    allowedCharacters = 'alphanumeric';
                } else if (company.isAccountValidAlphabetical) {
                    allowedCharacters = 'alphabetic';
                } else if (company.isAccountValidNumerical) {
                    allowedCharacters = 'numerical';
                }
                return {companyAccountNumber: `Account number may only contain ${allowedCharacters} characters`};
            }
            return null;
        };
    }

    static passwordComplexity(control: UntypedFormControl): { passwordComplexity: string } {
        if (!control.value) {
            return null;
        }
        const regex = /(?=^.{12,255}$)((?=.*\d)(?=.*[A-Z])(?=.*[a-z])|(?=.*\d)(?=.*[^A-Za-z0-9])(?=.*[a-z])|(?=.*[^A-Za-z0-9])(?=.*[A-Z])(?=.*[a-z])|(?=.*\d)(?=.*[A-Z])(?=.*[^A-Za-z0-9]))^.*/;
        if (!regex.test(control.value)) {
            return {passwordComplexity: 'Invalid Password'};
        }
        return null;
    }

    static minAmount(amount: number): any {
        return (control: UntypedFormControl) => {
            if (!control.value) {
                return null;
            }
            if (control.value < amount) {
                return {minAmount: 'Must enter value of at least $' + amount};
            }
            return null;
        };

    }

    static maxLength(length: number): any {
        return (control: UntypedFormControl) => {
            if (!control.value) {
                return null;
            }
            if (control.value.length > length) {
                return {maxLength: 'This field can only contain ' + length + ' characters.'};
            }
            return null;
        };
    }
}
