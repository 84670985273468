<modal #modal>
  <modal-header>
    <div class="row">
      <div class="col-md-12">
        <h4 class="modal-title">Change Password</h4>
      </div>
    </div>
  </modal-header>
  <modal-body>
    <form #form changePassword class="row" novalidate></form>
  </modal-body>
  <modal-footer>
    <button type="button" class="btn btn-default" (click)="cancelClicked()">Cancel</button>
    <button type="button" class="btn btn-primary" (click)="okClicked()">Change Password</button>
  </modal-footer>
</modal>
