<!-- TODO: fix -->
<!--<userAgentInfo (info)="thisUserAgent($event)"></userAgentInfo>-->

<table *ngIf="!state" class="table table-striped table-hover table-margin" style="width: 100%;">
    <thead>
    <tr>
        <th *ngFor="let header of headers" [class]="header.class || ''">
            <a (click)="onHeaderClick(header)" *ngIf="!header.isCheckbox">
                {{ header.text }}
                <span *ngIf="header.sortKey" class="glyphicon"
                      [visible]="header == activeHeader"
                      [keepFlow]="true"
                      [class.glyphicon-triangle-top]="header.ascending"
                      [class.glyphicon-triangle-bottom]="!header.ascending"></span>
            </a>
            <input type="checkbox" *ngIf="header.isCheckbox" [(ngModel)]="isChecked" (ngModelChange)="onSelectAllChanged($event)" />
        </th>
    </tr>
    </thead>
    <tbody>
    <ng-content></ng-content>
    </tbody>
</table>

<table *ngIf="state" class="table table-striped table-hover table-margin" style="width: 100%;">
    <thead>
    <tr>
        <th *ngFor="let header of headers" [class]="header.class || ''">
            <a (click)="onHeaderClick(header)">
                {{ header.text }}
                <span *ngIf="header.sortKey" class="glyphicon"
                      [visible]="header == activeHeader"
                      [keepFlow]="true"
                      [class.glyphicon-triangle-top]="header.ascending"
                      [class.glyphicon-triangle-bottom]="!header.ascending"></span>
            </a>
        </th>
    </tr>
    </thead>
    <tbody>
    <tr *ngIf="state === 'loading'">
        <td [attr.colspan]="headers.length" style="text-align: center">Loading data...</td>
    </tr>
    <tr *ngIf="state === 'empty'">
        <td [attr.colspan]="headers.length" style="text-align: center">Empty</td>
    </tr>
    <tr *ngIf="state === 'failed'">
        <td [attr.colspan]="headers.length" style="text-align: center">Error loading data</td>
    </tr>
    </tbody>
</table>

<div class="row" *ngIf="showPagination">
    <div class="col-md-12">
        <pagination *ngIf="maxSize >= 1"
                    [boundaryLinks]="true"
                    [totalItems]="totalCount"
                    [itemsPerPage]="limit"
                    [maxSize]="maxSize"
                    [ngModel]="currentPage"
                    (pageChanged)="setPage($event.page)"
                    class="pagination-sm"
                    previousText="&lsaquo;"
                    nextText="&rsaquo;"
                    firstText="&laquo;"
                    lastText="&raquo;"></pagination>
        <dropdown class="drop-down-inline"
                  [promptValue]="'rows'"
                  [initialValue]="initialLimitValue"
                  [values]="rows"
                  (select)="setLimit($event)"></dropdown>
    </div>
</div>
