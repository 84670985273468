import {mergeMap, take} from 'rxjs/operators';
import {Injectable, Injector} from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {Client, KcApplicationResult} from '../services/api.client';
import {combineLatestWith, Observable} from 'rxjs';
import {KcApplicationService} from '../services/KcApplicationService';
import {select, Store} from '@ngrx/store';
import * as fromRoot from '../store';
import {selectClientDataCurrentClient} from '../store/selectors/client.selectors';
import {selectKcAppDataCurrentSelectedKcApplication} from '../store/selectors/kcApplication.selectors';

@Injectable()
export class ResolveClientKcApplications  {

  client$: Observable<Client>;
  application$: Observable<KcApplicationResult>;

  constructor(private injector: Injector, private _store: Store<fromRoot.AppState>) {
    this.client$ = this._store.pipe(select(selectClientDataCurrentClient));
    this.application$ = this._store.pipe(select(selectKcAppDataCurrentSelectedKcApplication));
  }


  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    return this.application$.pipe(
      combineLatestWith(this.client$.modelExists()),
      take(1),
      mergeMap(([, client]) => this.injector.get(KcApplicationService).getKcApplicationsByClientId(client.id)), );
  }
}
