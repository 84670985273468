export function Unsubscribe() {
    return function(prototype, propertyKey) {
        const fn = prototype.ngOnDestroy;

        prototype.ngOnDestroy = function () {
            if (this[propertyKey] !== undefined) {
                this[propertyKey].unsubscribe();
            }
            fn && fn.apply(this);
        };
    };
}
