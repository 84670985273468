import {Component, OnInit, ViewChild, Input, Injector} from '@angular/core';
import {AllocationReportUrls} from '../../../api/AllocationReportUrls';
import {DemographicReportUrls} from '../../../api/DemographicReportUrls';
import {PledgeReportUrls} from '../../../api/PledgeReportUrls';
import {FileDownloadService} from '../../../services/FileDownloadService';
import {FormControl, UntypedFormBuilder} from '@angular/forms';
import {Observable} from 'rxjs';
import {AgencyListModalComponent} from '../agency/AgencyListModalComponent';
import {CompanyListModalComponent} from '../company/CompanyListModalComponent';
import {DemographicDemographicReportService} from '../../../services/DemographicReportService';
import * as moment from 'moment';
import {
    Agency, AllocationReport, AllocationReportClient,
    DemographicReportClient,
    PagedListOfPledgeDetailReport, PledgeCountyReport, PledgeDemographicSummaryReport, PledgeReportClient, PledgeStatusType,
    PledgeSummaryReport, PledgeZipcodeReport, UserType
} from '../../../services/api.client';
import {User} from '../../../services/api.client';
import {select, Store} from '@ngrx/store';
import * as fromRoot from '../../../store';
import {selectSecurityStateDataCurrentUser, selectSecurityStateDataSecurityToken} from '../../../store/selectors/security.selectors';
import {CompanySelectedEvent} from '../../shared/events/company-selected-event';
import {NotificationService} from '../../../services/NotificationService';
import {saveAs} from 'file-saver';
import {share} from 'rxjs/operators';

@Component({
    selector: '[pledgeReportForm]',
    templateUrl: './PledgeReportForm.html',
    styleUrls: ['./PledgeReportForm.scss'],
})

export class PledgeReportForm implements OnInit {

    public form = this.fb.group({
        startDate: [moment()
            .startOf('year')
            .format('MM/DD/YYYY')
        ],
        endDate: [moment()
            .endOf('day')
            .format('MM/DD/YYYY')
        ],
        pledgeStatus: [''],
        agencyId: [''],
        companyId: [''],
        pledgeTypeIds: [[]],
        hideZeroBalances: [true],
        agencyInput: ['All Agencies'],
        sortName: ['']
    });

    public startDate = this.form.get('startDate') as FormControl;
    public endDate = this.form.get('endDate') as FormControl;
    public pledgeStatus = this.form.get('pledgeStatus') as FormControl;
    public agencyId = this.form.get('agencyId') as FormControl;
    public companyId = this.form.get('companyId') as FormControl;
    public pledgeTypeIds = this.form.get('pledgeTypeIds') as FormControl;
    public hideZeroBalances = this.form.get('hideZeroBalances') as FormControl;
    public agencyInput = this.form.get('agencyInput') as FormControl;

    @ViewChild(AgencyListModalComponent)
    agencyListModal: AgencyListModalComponent;

    @ViewChild(CompanyListModalComponent)
    companyListModal: CompanyListModalComponent;

    // form options
    @Input('showDisplayPledgeStatus')
    showDisplayPledgeStatus: boolean;

    @Input('showPledgeStatus')
    showPledgeStatus: boolean;

    @Input('showCompany')
    showCompany: boolean;

    @Input('showZeroBalance')
    showZeroBalance: boolean;

    private securityToken: string;
    private securityToken$: Observable<string>;

    currentUser: User;
    currentUser$: Observable<User>;

    constructor(
        private fb: UntypedFormBuilder,
        private _fileDownloadService: FileDownloadService,
        private _demographic: DemographicReportClient,
        private _demographicReport: DemographicDemographicReportService,
        private pledgeReportClient: PledgeReportClient,
        private allocationReport: AllocationReportClient,
        private injector: Injector,
        private _store: Store<fromRoot.AppState>
    ) {
        this.currentUser$ = this._store.pipe(select(selectSecurityStateDataCurrentUser));
        this.securityToken$ = this._store.pipe(select(selectSecurityStateDataSecurityToken));
    }

    ngOnInit(): void {
        this.currentUser$.subscribe(user => {
            this.currentUser = user;

        });
        this.securityToken$.subscribe(securityToken => this.securityToken = securityToken);
        this.initForm();
    }

    initForm(): void {
        // if agency user, populate agency search field & disable
        if ((this.currentUser.userType !== UserType.SystemAdmin) &&
            (this.currentUser.userType !== UserType.FundAdmin) &&
            (this.currentUser.userType !== UserType.RegionAdmin)) {
                this.agencyId.setValue(this.currentUser.agencyId);
                this.agencyInput.setValue(this.currentUser.agency.agencyName);
                this.agencyInput.disable();
        }
    }

    onAgencyChange(agency: Agency): void {
        if (agency) {
            this.agencyId.setValue(agency.id);
            this.agencyInput.setValue(agency.agencyName);
        } else {
            this.agencyInput.setValue('All Agencies');
            this.agencyId.setValue(null);
        }
    }

    // --------- PLEDGE  REPORTS
    submitAgencyPledgeDetailReport(offset?: number, limit?: number, sortName?: string, ascending?: boolean)
        : Observable<PagedListOfPledgeDetailReport> {
        if (!this.pledgeTypeIds.value.length) {
            this.injector.get(NotificationService).showError('Please select at least one fund.');
            return;
        }

        const model = this.momentize(this.form.value);
        Object.assign(model, {offset, limit, sortName, descending: !ascending});
        return this._demographicReport.agencyDetailReport(model);
    }

    downloadAgencyPledgeDetailReportClick(): void {
        if (!this.pledgeTypeIds.value.length) {
            this.injector.get(NotificationService).showError('Please select at least one fund.');
            return;
        }

        const model = this.momentize(this.form.value);
        this.downloadAgencyPledgeDetailReport(model);
    }

    downloadAgencyPledgeDetailReport({startDate, endDate, pledgeTypeIds, pledgeStatus, agencyId}: {
        startDate: moment.Moment,
        endDate: moment.Moment,
        pledgeTypeIds: [],
        pledgeStatus: PledgeStatusType,
        agencyId: string,
    }): any {
        const response = this.pledgeReportClient.downloadDetailReport(
            startDate,
            endDate,
            pledgeTypeIds,
            pledgeStatus,
            agencyId);

        response.subscribe(result => {
            const currentDate = new Date();
            const fileName = `PledgeDetail-${currentDate.getFullYear() + '-' + (currentDate.getMonth() + 1) +
            '-' + currentDate.getDate()}.csv`;
            saveAs(result.data, fileName);
        }, error => {
            this.injector.get(NotificationService).showError('An error occurred while downloading the report.');
        });
    }

    submitAgencyPledgeSummaryReport(): Observable<PledgeSummaryReport[]> {
        if (!this.pledgeTypeIds.value.length) {
            this.injector.get(NotificationService).showError('Please select at least one fund.');
            return;
        }

        const model = this.momentize(this.form.value);
        return this._demographicReport.agencySummaryReport(model);
    }

    downloadAgencyPledgeSummaryReportClick(): void {
        if (!this.pledgeTypeIds.value.length) {
            this.injector.get(NotificationService).showError('Please select at least one fund.');
            return;
        }

        const model = this.momentize(this.form.value);
        this.downloadAgencyPledgeSummaryReport(model);
    }

    downloadAgencyPledgeSummaryReport({startDate, endDate, pledgeTypeIds, pledgeStatus, agencyId}: {
        startDate: moment.Moment,
        endDate: moment.Moment,
        pledgeTypeIds: [],
        pledgeStatus: PledgeStatusType,
        agencyId: string,
    }): any {
        const response = this.pledgeReportClient.downloadSummaryReport(
            startDate,
            endDate,
            pledgeTypeIds,
            pledgeStatus,
            agencyId);

        response.subscribe(result => {
            const currentDate = new Date();
            const fileName = `PledgeDetail-${currentDate.getFullYear() + '-' + (currentDate.getMonth() + 1) +
            '-' + currentDate.getDate()}.csv`;
            saveAs(result.data, fileName);
        }, error => {
            this.injector.get(NotificationService).showError('An error occurred while downloading the report.');
        });
    }

    submitAllocationAndBalanceReport(): Observable<AllocationReport[]> {
        if (!this.pledgeTypeIds.value.length) {
            this.injector.get(NotificationService).showError('Please select at least one fund.');
            return;
        }

        const model = this.momentize(this.form.value);
        return this._demographicReport.allocationReport(model);
    }

    downloadAllocationAndBalanceReportClick(): void {
        if (!this.pledgeTypeIds.value.length) {
            this.injector.get(NotificationService).showError('Please select at least one fund.');
            return;
        }

        const model = this.momentize(this.form.value);
        this.downloadAllocationAndBalanceReport(model);
    }

    downloadAllocationAndBalanceReport({startDate, endDate, pledgeTypeIds, pledgeStatus, agencyId, hideZeroBalances}: {
        startDate: moment.Moment,
        endDate: moment.Moment,
        pledgeTypeIds: [],
        pledgeStatus: PledgeStatusType,
        agencyId: string,
        hideZeroBalances: boolean
    }): any {
        const response = this.allocationReport.downloadDetailReport(
            startDate,
            endDate,
            pledgeTypeIds,
            pledgeStatus,
            agencyId,
            hideZeroBalances);

        response.subscribe(result => {
            const currentDate = new Date();
            const fileName = `Allocation and Balances-${currentDate.getFullYear() + '-' + (currentDate.getMonth() + 1) +
            '-' + currentDate.getDate()}.csv`;
            saveAs(result.data, fileName);
        }, error => {
            this.injector.get(NotificationService).showError('An error occurred while downloading the report.');
        });
    }

    // ---------
    // --------- DEMOGRAPHIC  REPORTS
    // Demographic
    submitPledgeDemographicDetailReport(offset?: number, limit?: number): Observable<PagedListOfPledgeDetailReport> {
        if (!this.pledgeTypeIds.value.length) {
            this.injector.get(NotificationService).showError('Please select at least one fund.');
            return;
        }

        const model = this.momentize(this.form.value);
        Object.assign(model, {offset, limit});
        return this._demographicReport.detailReport(model);
    }

    downloadPledgeDemographicDetailReportClick(): void {
        if (!this.pledgeTypeIds.value.length) {
            this.injector.get(NotificationService).showError('Please select at least one fund.');
            return;
        }

        const model = this.momentize(this.form.value);
        this.downloadPledgeDemographicDetailReport(model);
    }

    downloadPledgeDemographicDetailReport({startDate, endDate, pledgeTypeIds, pledgeStatus, companyId, agencyId}: {
        startDate: moment.Moment,
        endDate: moment.Moment,
        pledgeTypeIds: [],
        pledgeStatus: PledgeStatusType,
        companyId: string,
        agencyId: string,
    }): any {
        const response = this._demographic.downloadDetailReport(
            startDate,
            endDate,
            pledgeTypeIds,
            pledgeStatus,
            companyId,
            agencyId);

        response.subscribe(result => {
            const currentDate = new Date();
            const fileName = `Pledge Detail Report-${currentDate.getFullYear() + '-' + (currentDate.getMonth() + 1) +
            '-' + currentDate.getDate()}.csv`;
            saveAs(result.data, fileName);
        }, error => {
            this.injector.get(NotificationService).showError('An error occurred while downloading the report.');
        });
    }

    submitPledgeDemographicSummaryReport(): Observable<PledgeDemographicSummaryReport> {
        if (!this.pledgeTypeIds.value.length) {
            this.injector.get(NotificationService).showError('Please select at least one fund.');
            return;
        }

        const model = this.momentize(this.form.value);
        return this._demographicReport.summaryReport(model);
    }

    downloadPledgeDemographicSummaryReportClick(): void {
        if (!this.pledgeTypeIds.value.length) {
            this.injector.get(NotificationService).showError('Please select at least one fund.');
            return;
        }

        const model = this.momentize(this.form.value);
        this.downloadPledgeDemographicSummaryReport(model);
    }

    downloadPledgeDemographicSummaryReport({startDate, endDate, pledgeTypeIds, pledgeStatus, companyId, agencyId}: {
        startDate: moment.Moment,
        endDate: moment.Moment,
        pledgeTypeIds: [],
        pledgeStatus: PledgeStatusType,
        companyId: string,
        agencyId: string,
    }): any {
        const response = this._demographic.downloadSummaryReport(
            startDate,
            endDate,
            pledgeTypeIds,
            pledgeStatus,
            companyId,
            agencyId);

        response.subscribe(result => {
            const currentDate = new Date();
            const fileName = `Demographic Summary Report-${currentDate.getFullYear() + '-' + (currentDate.getMonth() + 1) +
            '-' + currentDate.getDate()}.csv`;
            saveAs(result.data, fileName);
        }, error => {
            this.injector.get(NotificationService).showError('An error occurred while downloading the report.');
        });
    }

    submitPledgeDemographicByZipcodeReport(): Observable<PledgeZipcodeReport[]> {
        if (!this.pledgeTypeIds.value.length) {
            this.injector.get(NotificationService).showError('Please select at least one fund.');
            return;
        }

        const model = this.momentize(this.form.value);
        return this._demographicReport.zipcodeReport(model);
    }

    downloadPledgeDemographicByZipcodeReportClick(): void {
        if (!this.pledgeTypeIds.value.length) {
            this.injector.get(NotificationService).showError('Please select at least one fund.');
            return;
        }

        const model = this.momentize(this.form.value);
        this.downloadPledgeDemographicByZipcodeReport(model);
    }

    downloadPledgeDemographicByZipcodeReport({startDate, endDate, pledgeTypeIds, pledgeStatus, companyId, agencyId}: {
        startDate: moment.Moment,
        endDate: moment.Moment,
        pledgeTypeIds: [],
        pledgeStatus: PledgeStatusType,
        companyId: string,
        agencyId: string,
    }): any {
        const response = this._demographic.downloadZipcodeReport(
            startDate,
            endDate,
            pledgeTypeIds,
            pledgeStatus,
            companyId,
            agencyId);

        response.subscribe(result => {
            const currentDate = new Date();
            const fileName = `Zipcode Report-${currentDate.getFullYear() + '-' + (currentDate.getMonth() + 1) +
            '-' + currentDate.getDate()}.csv`;
            saveAs(result.data, fileName);
        }, error => {
            this.injector.get(NotificationService).showError('An error occurred while downloading the report.');
        });
    }

    submitPledgeDemographicByCountyReport(): Observable<PledgeCountyReport[]> {
        if (!this.pledgeTypeIds.value.length) {
            this.injector.get(NotificationService).showError('Please select at least one fund.');
            return;
        }

        const model = this.momentize(this.form.value);
        return this._demographicReport.countyReport(model);
    }

    downloadPledgeDemographicByCountyReportClick(): void {
        if (!this.pledgeTypeIds.value.length) {
            this.injector.get(NotificationService).showError('Please select at least one fund.');
            return;
        }

        const model = this.momentize(this.form.value);
        this.downloadPledgeDemographicByCountyReport(model);
    }

    downloadPledgeDemographicByCountyReport({startDate, endDate, pledgeTypeIds, pledgeStatus, companyId, agencyId}: {
        startDate: moment.Moment,
        endDate: moment.Moment,
        pledgeTypeIds: [],
        pledgeStatus: PledgeStatusType,
        companyId: string,
        agencyId: string,
    }): any {
        const response = this._demographic.downloadCountyReport(
            startDate,
            endDate,
            pledgeTypeIds,
            pledgeStatus,
            companyId,
            agencyId);

        response.subscribe(result => {
            const currentDate = new Date();
            const fileName = `County Report-${currentDate.getFullYear() + '-' + (currentDate.getMonth() + 1) +
            '-' + currentDate.getDate()}.csv`;
            saveAs(result.data, fileName);
        }, error => {
            this.injector.get(NotificationService).showError('An error occurred while downloading the report.');
        });
    }

    // ---------
    private momentize(model: any): any {
        if (model.startDate) {
            model.startDate = moment(model.startDate).startOf('day');
        }
        if (model.endDate) {
            model.endDate = moment(model.endDate).endOf('day');
        }
        return model;
    }

    public companySelected(event: CompanySelectedEvent): void {
        if (event.noCompany) {
            this.companyId.setValue('');
        }
        this.companyId.setValue(event?.company && event.company?.id);
    }

}
