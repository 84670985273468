<div class="container">
  <div class="row form-group">
    <div class="col-xs-12">
      <a class="btn btn-primary btn-clientNav" [routerLink]="['../']">Client Overview</a>
    </div>
  </div>
  <form class="form-horizontal" clientForm #f novalidate>
    <div footer class="col-xs-12 offset-md-5 col-md-7 p-l-0">
      <button type="submit" class="btn btn-primary" [autoDisable]="f.submit">Save Client</button>
    </div>
  </form>
</div>
