import {share} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {CountyClient, CountyResult} from './api.client';
import {Observable} from 'rxjs';

/**
 * Created by djacobsmeyer on 1/4/17.
 */
@Injectable()
export class CountyService {
  constructor(private _countyApiService: CountyClient) {
  }

  getCountiesWithDefault(zipCode: string, state: string): Observable<CountyResult> {
    const request = this._countyApiService.getCountiesWithDefault(zipCode, state).pipe(share());
    request.subscribe(() => {

    });
    return request;
  }
}
