import {Component, OnInit, ViewChild} from '@angular/core';
import {splitCamelCase} from '../../../utilities/Util';
import {ITableHeader, TableComponent} from '../../shared/table/TableComponent';
import {Company, CompanyCategory, CompanyCategoryType, PagedListOfCompany, StatusType, SystemData} from '../../../services/api.client';
import {CompanyService} from '../../../services/CompanyService';
import {ActivatedRoute} from '@angular/router';
import {delay, takeUntil} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {select, Store} from '@ngrx/store';
import * as fromRoot from '../../../store';
import {
  selectCompanyDataCompanies,
  selectCompanyDataCompaniesItems,
  selectCompanyDataCompaniesTotalCount
} from '../../../store/selectors/company.selectors';
import {FormBuilder, FormControl, UntypedFormGroup} from '@angular/forms';
import {ICompanyCategory} from '../../../shared/interfaces/ICompanyCategory';
import {Disposable} from '../../../utilities/Disposable';
import {selectCompanyCategories} from '../../../store/selectors/companyCategory.selectors';

@Component({
  templateUrl: './CompanyListComponent.html',
  styleUrls: ['./CompanyListComponent.scss'],
})

export class CompanyListComponent extends Disposable implements OnInit {

  constructor(private _companyService: CompanyService,
              private _route: ActivatedRoute,
              private _store: Store<fromRoot.AppState>,
              private _formBuilder: FormBuilder) {
    super();
    this.pagedList$ = this._store.pipe(select(selectCompanyDataCompanies));
    this.companies$ = this._store.pipe(select(selectCompanyDataCompaniesItems));
    this.totalCount$ = this._store.pipe(select(selectCompanyDataCompaniesTotalCount));
    this.companyCategories$ = this._store.select(selectCompanyCategories);
  }

  get queryParams(): any {
    return this.params;
  }

  get companyNameControl(): FormControl {
    return this.form.get('companyName') as FormControl;
  }

  get categoryControl(): FormControl {
    return this.form.get('category') as FormControl;
  }

  public form: UntypedFormGroup;

  companyCategories: CompanyCategory[];
  companyCategories$: Observable<CompanyCategory[]>;
  categoryTypesSelect: ICompanyCategory[];

  isLoading: boolean;
  systemData: SystemData;
  searchValue = '';
  params: any;

  @ViewChild(TableComponent)
  table: TableComponent;

  // subscription
  pagedList: PagedListOfCompany;
  pagedList$: Observable<PagedListOfCompany>;
  companies: Company[];
  companies$: Observable<Company[]>;

  // table sorting
  sortName = 'Name';
  descending = false;

  tableHeaders: ITableHeader[] = [
    {text: 'Company Name', sortKey: 'Name', ascending: true},
    {text: 'State', sortKey: 'State', class: 'hidden-sm-down'},
    {text: 'Status', sortKey: 'Status', class: 'hidden-sm-down'},
    {text: 'Category', sortKey: 'Category'},
  ];

  // pagination
  limit = 10;
  currentPage = 1;
  maxSize = 1;
  offset = 0;
  totalCount: number;
  totalCount$: Observable<number>;

  ngAfterViewInit(): void {
    setTimeout(() => {
      const self = this;
      this._route.params
        .pipe(delay(0)) // fixes expression has changed after it was checked.
        .subscribe(params => {
          self.searchValue = params.searchValue || '';
          self.sortName = params.sortName || 'Name';
          self.descending = params.descending || false;
          self.limit = (params.limit || 10) * 1;
          self.currentPage = (params.currentPage || 1) * 1;
          self.offset = params.offset || 0;
          self.params = {
            searchValue: self.searchValue,
            sortName: self.sortName,
            descending: self.descending,
            limit: self.limit,
            offset: self.offset,
            currentPage: self.currentPage
          };
          self.table.request = self._companyService.getCompanies(
            self.searchValue,
            null,
            null,
            true,
            self.sortName,
            self.descending,
            self.limit,
            self.offset
          );
        });
    });
  }

  ngOnInit(): void {
    this.initForm();
    this.companyCategories$
        .pipe(takeUntil(this.$destroyed))
        .subscribe(companyCategories => {
          this.companyCategories = companyCategories;
          this.populateCategoryTypesSelect();
        });
    this.pagedList$.subscribe(pagedList => this.pagedList = pagedList);
    this.companies$.subscribe(companies => this.companies = companies);
    this.totalCount$.subscribe(totalCount => this.totalCount = totalCount);
  }

  clearFilters(): void {
    this.categoryControl?.setValue(null);
    this.companyNameControl?.setValue(null);
  }

  // set Current Selected Company
  setCurrentSelectedCompany(currentSelectedCompany: Company): void {
    this._companyService.setCurrentSelectedCompany(currentSelectedCompany);
  }

  // sorting
  sortCompanies(options: ITableHeader): void {
    this.sortName = options.sortKey;
    this.descending = !options.ascending;
    this.refreshCompanies();
  }
  setLimit(limit: number): void {// # of rows per page
    this.limit = limit;
    this.refreshCompanies();
  }

  setPage(page: number): void {
    setTimeout(() => {
      this.currentPage = page; // set current page
      this.refreshCompanies();
    });
  }

  refreshCompanies(): void {
    this.table.request = this._companyService.getCompanies(
        this.companyNameControl?.value,
        null,
        this.getCategory(this.categoryControl?.value),
        true,
        this.sortName,
        this.descending,
        this.limit,
        (this.currentPage - 1) * this.limit);
  }

  formatStatus(status: string | number): string {
      if (typeof status === 'number') {
          return splitCamelCase(StatusType[status], true);
      }
      return splitCamelCase(status, true);
  }

  private initForm(): void {
    this.form = this._formBuilder.group({
      companyName: new FormControl<string | null>(null),
      category: new FormControl<CompanyCategoryType | null>(null)
    });
  }

  private populateCategoryTypesSelect(): void {
    this.categoryTypesSelect = this.companyCategories.map(x => ({
      key: x.category.toString(),
      value: x.categoryLabel
    } as ICompanyCategory));
  }

  private getCategory(category: string): CompanyCategoryType {
    return CompanyCategoryType[category];
  }
}
