<h1 class="page-title">Payment Processing</h1>
<div class="row mb-lg">
  <div class="col-xs-12">
    <div class="clearfix">
      <ul class="nav nav-tabs pull-xs-left" role="tablist">
        <li class="nav-item"><a class="nav-link" [routerLink]="['./confirmed_pending']"
                                routerLinkActive="active">Shift Confirmed to Pending</a></li>
        <li class="nav-item"><a class="nav-link" [routerLink]="['./pending_paid']"
                                routerLinkActive="active">Shift Pending to Paid</a></li>
        <li class="nav-item"><a class="nav-link" [routerLink]="['./manual_pending_paid']"
                                routerLinkActive="active">Shift Manual Pending to Paid</a></li>
        <li class="nav-item"><a class="nav-link" [routerLink]="['./pledges_summary']"
                                routerLinkActive="active">Pledge Summary</a></li>
        <li class="nav-item"><a class="nav-link" [routerLink]="['./company_mailing']"
                                routerLinkActive="active">Company Mailing Labels</a></li>
      </ul>
    </div>
    <div class="tab-content overflow mb-lg">
      <div role="tabpanel" class="tab-pane active in">
        <router-outlet></router-outlet>
        <div class="clearfix"></div>
      </div>
    </div>
  </div>
</div>
