import {createFeatureSelector, createSelector} from '@ngrx/store';
import {ICompanyCategoryData} from '../ICompanyCategoryData';

const companyCategorySelector = createFeatureSelector<ICompanyCategoryData>("companyCategory");

export const selectCategoryThresholds = createSelector(
    companyCategorySelector,
    (state) => state.categoryThresholds
);

export const selectCurrentPayeeCompanyCategoryAmounts = createSelector(
    companyCategorySelector,
    (state) => state.currentPayeeCompanyCategoryAmounts
);

export const selectCompanyCategories = createSelector(
    companyCategorySelector,
    (state) => state.companyCategories
);
