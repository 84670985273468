import {Component, Input} from '@angular/core';
import {AssistanceApplication} from '../../../../../../services/api.client';

@Component({
  selector: 'app-assistance-application-tabs',
  templateUrl: './assistance-application-tabs.component.html',
  styleUrls: ['./assistance-application-tabs.component.scss']
})
export class AssistanceApplicationTabsComponent {
  @Input() assistanceApplication: AssistanceApplication;
  tab: any = 'files';

  constructor() {
  }
}
