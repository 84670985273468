<div style="position: relative; min-height: 200px; margin-top: 1rem;">
    <h3 *ngIf="receivedForms === null" class="text-center ab-center">
        Fetching files...
    </h3>
    <h3 *ngIf="receivedForms?.length === 0" class="text-center ab-center">
        No files listed.
    </h3>
    <table *ngIf="receivedForms?.length" class="table table-striped table-hover">
        <thead>
        <tr>
            <td>File Name</td>
            <td>File Type</td>
            <td>Download</td>
            <td></td>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let f of receivedForms">
            <td>{{ f?.fileName }}</td>
            <td>{{ getFileType(f?.fileType) }}</td>
            <td><a target="_blank" [href]="apiUrl + f.fileUrl + '&' + system.getQueryParameters()">Download</a></td>
        </tr>
        </tbody>
    </table>
</div>
