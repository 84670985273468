import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {FormControl, FormGroup, UntypedFormBuilder} from '@angular/forms';
import {ActivatedRoute, ResolveData, Router} from '@angular/router';

import * as moment from 'moment';
import {combineLatest, combineLatestWith, Observable, of, ReplaySubject, Subscription} from 'rxjs';

import {
    debounceTime,
    distinctUntilChanged,
    filter,
    map,
    mergeMap,
    shareReplay,
    switchMap, takeUntil,
    tap
} from 'rxjs/operators';
import {
    Client,
    Company,
    KcApplication,
    KcApplicationClient,
    KcApplicationResult,
    KcPaySourceType,
    KcProgram,
    KcProgramLevel,
    KcStatus,
    PledgeStatusType,
    PledgeType,
    PledgeTypeBalance,
    User,
    UserType
} from '../../../services/api.client';
import {KcApplicationService} from '../../../services/KcApplicationService';
import {NotificationService} from '../../../services/NotificationService';
import {PledgeService} from '../../../services/PledgeService';
import {PledgeTypeService} from '../../../services/PledgeTypeService';
import {SystemService} from '../../../services/SystemService';
import {Unsubscribe} from '../../../utilities/Unsubscribe';
import {extractEnumNames} from '../../../utilities/Util';
import {Validators} from '../../../utilities/Validators';
import {select, Store} from '@ngrx/store';
import * as fromRoot from '../../../store';
import {selectClientDataCurrentClient} from '../../../store/selectors/client.selectors';
import {
    selectKcAppDataCurrentSelectedKcApplication,
    selectKcAppDataKcAmerenCompany,
    selectKcAppDataProgramDetail
} from '../../../store/selectors/kcApplication.selectors';
import {selectSecurityStateDataCurrentUser, selectSecurityStateDataSystemDataKcStatuses} from '../../../store/selectors/security.selectors';
import {Disposable} from '../../../utilities/Disposable';
import swal from 'sweetalert2';
import {CompanyListModalComponent} from '../company/CompanyListModalComponent';
import {
    KeepingCurrentAmountOwedCalculatorComponent
} from './keeping-current-amount-owed-calculator/keeping-current-amount-owed-calculator.component';
import {CompanySelectedEvent} from '../../shared/events/company-selected-event';

export type KcApplicationSubmitType = 'New' | 'Approve' | 'Reject' | 'Save' | 'Cancel' | 'Delete';

@Component({
    selector: '[clientEnrollmentForm]',
    templateUrl: './ClientEnrollmentFormComponent.html',
    styleUrls: ['./ClientEnrollmentFormComponent.scss']
})

export class ClientEnrollmentFormComponent extends Disposable implements OnInit {

    get isSystemAdmin(): boolean {
        return this.currentUser.userType === UserType.SystemAdmin;
    }

    constructor(private formBuilder: UntypedFormBuilder,
                private kcApplicationService: KcApplicationService,
                private kcApplicationClient: KcApplicationClient,
                private notificationService: NotificationService,
                private pledgeService: PledgeService,
                private pledgeTypeService: PledgeTypeService,
                private router: Router,
                private _activatedRoute: ActivatedRoute,
                public system: SystemService,
                private _store: Store<fromRoot.AppState>,
                private route: ActivatedRoute) {
        super();
        this.currentUser$ = this._store.pipe(select(selectSecurityStateDataCurrentUser));
        this.currentClient$ = this._store.pipe(select(selectClientDataCurrentClient));
        this.kcStatuses$ = this._store.pipe(select(selectSecurityStateDataSystemDataKcStatuses));
        this.kcApplicationData$ = this._store.pipe(select(selectKcAppDataCurrentSelectedKcApplication));
        this.programDetail$ = this._store.pipe(select(selectKcAppDataProgramDetail));
        this.kcAmerenCompany$ = this._store.pipe(select(selectKcAppDataKcAmerenCompany));
    }

    public form: FormGroup;

    @Output()
    onSubmit = new EventEmitter<KcApplicationSubmitType>();
    @Output()
    onContinue = new EventEmitter<KcApplication>();

    @Input()
    application: KcApplicationResult;

    paySourceTypes = extractEnumNames(KcPaySourceType);

    currentClient: Client;
    currentClient$: Observable<Client>;

    @Unsubscribe() private _pledgeAgencySub: Subscription;
    @Unsubscribe() private _combinedSub$: Subscription;
    @Unsubscribe() _kcRoutDataSub: Subscription;

    showAdvancedEnrollment = false;
    kcStatuses: KcStatus[];
    kcStatuses$: Observable<KcStatus[]>;
    kcStatus: KcStatus;

    @Input() kcPrograms: KcProgram[] = [];

    kcApplicationData$: Observable<KcApplication>;
    programDetail$: Observable<KcProgramLevel>;
    programDetail: KcProgramLevel;
    kcAmerenCompany$: Observable<Company>;
    currentUser$: Observable<User>;
    currentUser: User;
    kcPledgeTypes: PledgeType[] = [];
    kcPledge$ = new ReplaySubject<PledgeType>(1);
    kcBalance: PledgeTypeBalance;

    disableCalculator = true;

    @ViewChild(KeepingCurrentAmountOwedCalculatorComponent)
    keepingCurrentAmountOwedCalculator: KeepingCurrentAmountOwedCalculatorComponent;


    get keepingCurrentProgramIdControl(): FormControl {
        return this.form.get('keepingCurrentProgramId') as FormControl;
    }

    get keepingCurrentStatusIdControl(): FormControl {
        return this.form.get('keepingCurrentStatusId') as FormControl;
    }

    get clientIdControl(): FormControl {
        return this.form.get('clientId') as FormControl;
    }

    get firstNameControl(): FormControl {
        return this.form.get('firstName') as FormControl;
    }

    get lastNameControl(): FormControl {
        return this.form.get('lastName') as FormControl;
    }

    get middleInitialControl(): FormControl {
        return this.form.get('middleInitial') as FormControl;
    }

    get clientLast4SsnControl(): FormControl {
        return this.form.get('clientLast4Ssn') as FormControl;
    }

    get pledgeStatusControl(): FormControl {
        return this.form.get('pledgeStatus') as FormControl;
    }

    get clientFederalPovertyLevelDisplay(): FormControl {
        return this.form.get('clientFederalPovertyLevelDisplay') as FormControl;
    }

    get programMonthlyCreditControl(): FormControl {
        return this.form.get('programMonthlyCredit') as FormControl;
    }

    get arrearsMonthlyEstimatedCreditControl(): FormControl {
        return this.form.get('arrearsMonthlyEstimatedCredit') as FormControl;
    }

    get totalMonthlyEstimatedCreditControl(): FormControl {
        return this.form.get('totalMonthlyEstimatedCredit') as FormControl;
    }

    get approvedTimestampControl(): FormControl {
        return this.form.get('approvedTimestamp') as FormControl;
    }

    get accountNumberControl(): FormControl {
        return this.form.get('accountNumber') as FormControl;
    }

    get agencyIdControl(): FormControl {
        return this.form.get('agencyId') as FormControl;
    }

    get statusNameControl(): FormControl {
        return this.form.get('statusName') as FormControl;
    }

    get grandTotalEstimatedCreditsControl(): FormControl {
        return this.form.get('grandTotalEstimatedCredits') as FormControl;
    }

    get kcFpAcceptanceRateControl(): FormControl {
        return this.form.get('kcFpAcceptanceRate') as FormControl;
    }

    get kcStatusRejectControl(): FormControl {
        return this.form.get('kcStatusReject') as FormControl;
    }

    get totalAmountOwedControl(): FormControl {
        return this.form.get('totalAmountOwed') as FormControl;
    }

    get upfrontAmountDueControl(): FormControl {
        return this.form.get('upfrontAmountDue') as FormControl;
    }

    get upfrontPaySourceControl(): FormControl {
        return this.form.get('upfrontPaySource') as FormControl;
    }

    get applicationStartedButNotSubmitted(): boolean {
        return !!this.application?.id && this.application?.keepingCurrentStatusId === this.system.kcStatusNew.id;
    }

    showTotalAmountOwed = false;
    showUpfrontAmountDue = false;
    showUpfrontPaySource = false;


    routeData$: Observable<ResolveData>;

    private ignoreProgramDetailPopulateOnce = false;

    initForm(): void {
        this.form = this.formBuilder.group({
            keepingCurrentProgramId: [null, Validators.required],
            keepingCurrentStatusId: [null, Validators.required],
            clientId: [null, Validators.required],
            clientLast4Ssn: [null, Validators.required],
            pledgeStatus: [PledgeStatusType.Confirmed],
            clientFederalPovertyLevelDisplay: [null, Validators.required],
            totalAmountOwed: [{value: '0.00', disabled: true}, Validators.required],
            upfrontAmountDue: [null],
            upfrontAmountPaid: [0], // ?
            upfrontPaySource: [null],
            programMonthlyCredit: [null],
            programTotalNumMonthlyCredits: [null],
            programTotalEstimatedCredits: [null],
            arrearsUpfrontPercent: [null],
            arrearsMonthlyPercent: [null],
            arrearsTotalNumMonthlyCredits: [null],
            arrearsMonthlyEstimatedCredit: [null],
            arrearsTotalEstimatedCredits: [0], // ?
            totalMonthlyEstimatedCredit: [null],
            grandTotalEstimatedCredits: [0], // ?
            downloadTimestamp: [null],
            approvedTimestamp: [null],
            rowIsNotActive: [null],
            statusName: [null],
            kcFpAcceptanceRate: [null],
            accountNumber: new FormControl(null, Validators.compose([Validators.required, Validators.companyAccountNumber(this.kcAmerenCompany$)])),
            agencyId: [this.currentUser.agencyId, Validators.required],
            kcStatusReject: [],
            note: [''],
        });

        this.totalAmountOwedControl.disable();
        this.routeData$ = this._activatedRoute.parent.data.pipe(shareReplay(1));
        this._combinedSub$ = combineLatest([
            this.routeData$,
            this.keepingCurrentProgramIdControl.valueChanges.pipe(distinctUntilChanged()).exists(),
            this.currentClient$.modelExists()
        ]).pipe(
            switchMap(([data, programId, client]: [{ kcPledgeTypes: PledgeType[], kcPrograms: KcProgram[] }, string, Client]) => {
                this.kcPledgeTypes = data.kcPledgeTypes;
                this.kcPrograms = data.kcPrograms;
                return this.kcApplicationService.getProgramDetails(programId, client.federalPovertyLevelDisplay);
            }),
            filter(() => this.ignoreProgramDetailPopulateOnce ? (this.ignoreProgramDetailPopulateOnce = false) : true),
            mergeMap((detail: KcProgramLevel) => {
                const kcProgram = this.kcPrograms.find(kcp => kcp.id === detail.keepingCurrentProgramId);
                const kcPledge = this.kcPledgeTypes.find(pt => pt.id === kcProgram.pledgeTypeId);
                this.kcPledge$.next(kcPledge);
                return of(Object.assign({}, detail, {programMonthlyCredit: detail.programMonthlyCredit.toFixed(2)}));
            })
        ).subscribe((detail) => {
            this.form.patchValue(detail);
        });

        this._pledgeAgencySub = combineLatest([
            this.kcPledge$.exists(),
            this.agencyIdControl.valueChanges.exists()
        ]).pipe(debounceTime(100), mergeMap(([pledgeType, agencyId]) => this.pledgeService.getPledgeTypeBalance(agencyId, pledgeType.id))
        ).subscribe((kcBalance) => {
            this.kcBalance = kcBalance;
        });

        this.keepingCurrentStatusIdControl?.valueChanges.pipe(
            mergeMap((id) => this.system.getKcStatusById$(id)))
            .exists()
            .subscribe((status) => {
                this.kcStatus = status;
                this.statusNameControl.setValue(status.statusName);
            });

        this.totalAmountOwedControl.valueChanges.subscribe(x => {
            this.updateUpfrontPayment(x);
        });

        this.upfrontAmountDueControl?.valueChanges
            .subscribe((value) => this.arrearsMonthlyEstimatedCreditControl.setValue(value));

        const upfrontAmountDue$ = this.upfrontAmountDueControl?.valueChanges.pipe(map((value) => parseFloat(value as string)));
        const programMonthlyCredit$ = this.programMonthlyCreditControl.valueChanges.pipe(map((value) => {
            return parseFloat(value as string);
        }));
        upfrontAmountDue$?.pipe(
            combineLatestWith(programMonthlyCredit$)
        )
            .pipe(
                map(([upfrontAmountDue, programMonthlyCredit]) => (upfrontAmountDue || 0) + (programMonthlyCredit || 0)))
            .subscribe((total) => this.totalMonthlyEstimatedCreditControl.setValue(total.toFixed(2)));

        this.currentUser$.pipe(
            map(user => user.agencyId))
            .subscribe((agencyId) => this.agencyIdControl.setValue(agencyId));

        // Hide form fields based off selected program
        this._kcRoutDataSub = combineLatest([
            this.keepingCurrentProgramIdControl.valueChanges,
            this.routeData$.pipe(
                map((data) => data.kcPrograms),
                filter((programs: KcProgram[]) => programs && !!programs.length))
        ])
            .pipe(map(([id, programs]) => programs.find(program => program.id === id)))
            .exists()
            .subscribe((program: KcProgram) => {
                const kcPledge = this.kcPledgeTypes.find(pt => pt.id === program.pledgeTypeId);
                this.kcPledge$.next(kcPledge);
                if (program.hasArrearsCredits) {
                    this.showUpfrontAmountDue = true;

                    this.showUpfrontPaySource = true;
                    setTimeout(() => this.upfrontPaySourceControl?.setValidators([Validators.required]));

                    this.showTotalAmountOwed = true;

                    if (!this.applicationStartedButNotSubmitted) {
                        this.setCalculatorEnabledStatus(true);
                        this.upfrontPaySourceControl.enable();
                    }
                } else {
                    this.showUpfrontAmountDue = false;
                    this.upfrontAmountDueControl.reset();
                    this.upfrontPaySourceControl?.disable();
                    this.showUpfrontPaySource = false;
                    this.upfrontPaySourceControl.reset();
                    setTimeout(() => this.upfrontPaySourceControl?.setValidators([]));
                    this.showTotalAmountOwed = false;
                    this.totalAmountOwedControl.reset('0.00');
                }
            });
        this.totalAmountOwedControl?.updateValueAndValidity();
        // disable form fields based of pledge status
        this.keepingCurrentStatusIdControl
            .valueChanges.pipe(
            map((id) => this.kcStatuses.find(t => t.id === id)))
            .subscribe((status: KcStatus) => {
                if (status.isPendingApprovalStatus || status.isNewStatus) {
                    this.keepingCurrentProgramIdControl.enable();
                    this.accountNumberControl.enable();
                    this.setCalculatorEnabledStatus(true);
                    this.upfrontPaySourceControl?.enable();
                } else {
                    this.keepingCurrentProgramIdControl.disable();
                    this.accountNumberControl.disable();
                    this.setCalculatorEnabledStatus(false);
                    this.upfrontPaySourceControl?.disable();
                }

                this.upfrontAmountDueControl?.disable();
                this.programMonthlyCreditControl.disable();
            });
    }

    private updateUpfrontPayment(totalAmountDue) {
        if (!this.programDetail?.arrearsUpfrontPercent) {
            return;
        }
        this.upfrontAmountDueControl.setValue((totalAmountDue * this.programDetail.arrearsUpfrontPercent).toFixed(2));
    }

    ngOnInit(): void {
        this.currentUser$.subscribe(user => this.currentUser = user);
        this.currentClient$.subscribe(client => this.currentClient = client);
        this.kcStatuses$.subscribe(statuses => this.kcStatuses = statuses);
        this.programDetail$.subscribe(programDetail => this.programDetail = programDetail);
        (window as any).c = this;
        this.initForm();
        this.kcApplicationService
            .getKcAmerenCompany()
            .subscribe();
    }


    /**
     * Returns all parentForm values (including disabled fields)
     */
    getAllValues(options?: { excludeNulls: boolean }): any {
        const obj: any = {};
        Object.keys(this.form.controls)
            .filter((key) => (options && options.excludeNulls) ? this.form.controls[key].value !== null : true)
            .forEach((key) => obj[key] = this.form.controls[key].value);
        return obj;

    }

    public populate(data: any): void {
        // make a shallow copy of the data since store is immutable
        data = {...data};
        if (typeof data.upfrontPaySource === 'string') {
            for (const k in KcPaySourceType) {
                if (data.upfrontPaySource === k) {
                    data.upfrontPaySource = KcPaySourceType[k];
                    break;
                }
            }
        }
        data = (data as KcApplication);

        if (typeof data.totalAmountOwed === 'number') {
            data.totalAmountOwed = (data.totalAmountOwed.toFixed(2) as any);
        }

        this.form.patchValue(data);
    }

    public populateEdit(data: any): void {
        // on edit mode we should ignore the first program detail populate.
        // we only ignore once because we still want the form to load program detail if user decides to change the program.
        this.ignoreProgramDetailPopulateOnce = true;
        this.populate(data);
    }

    isValid(): boolean {
        this.accountNumberControl.updateValueAndValidity();
        this.form.markAllAsTouched();
        if (this.form.valid) {
            return true;
        } else {
            this.form.markAsTouched();
            return false;
        }
    }

    isValid$(): Observable<any> {
        return new Observable((obs) => {
            if (!this.isValid()) {
                obs.complete();
                return;
            }

            const arrearsMonthlyPercent = this.programDetail.arrearsMonthlyPercent;
            const totalAmountOwed = this.totalAmountOwedControl?.value;
            const programTotalEstimatedCredits = this.programDetail.programTotalEstimatedCredits;
            const arrearsTotalNumMonthlyCredits = this.programDetail.arrearsTotalNumMonthlyCredits;

            // this is the equation the database uses to calculate grandTotalEstimate
            const totalEstimate = totalAmountOwed * arrearsMonthlyPercent * arrearsTotalNumMonthlyCredits + programTotalEstimatedCredits;
            this.grandTotalEstimatedCreditsControl.setValue(totalEstimate);
            this.kcPledge$
                .exists().pipe(
                debounceTime(100),
                mergeMap((pledgeType) => this.pledgeService.getPledgeTypeBalance(this.agencyIdControl.value, pledgeType.id)),
                map((kcBalance) => [kcBalance, totalEstimate])
            )
                .subscribe(([kcBalance, totalEstimate1]: [PledgeTypeBalance, number]) => {
                    this.kcBalance = kcBalance;
                    const errors = this.totalMonthlyEstimatedCreditControl.errors;
                    const originalGrandTotal: number = (this.application && this.application.grandTotalEstimatedCredits) || 0;
                    let hasEnoughMoney: boolean;

                    if (!this.kcStatus.isNewStatus) {
                        hasEnoughMoney = (kcBalance.balance + originalGrandTotal) - totalEstimate1 < 0;
                    } else {
                        hasEnoughMoney = kcBalance.balance - totalEstimate < 0;
                    }

                    if (hasEnoughMoney) {
                        this.totalMonthlyEstimatedCreditControl.setErrors(Object.assign(errors || {}, {
                            notEnoughBalance: `Not enough money! Pledge may not exceed ${kcBalance.balance}.`
                        }));
                        obs.complete();
                        return;
                    } else if (errors) {
                        delete errors.notEnoughBalance;
                        this.totalMonthlyEstimatedCreditControl.setErrors(errors);
                    }
                    obs.next();
                    obs.complete();
                });

        });
    }

    submit(): any {
        throw Error('Not Implemented');
    }

    createKcApplication(): void {
        this.isValid$().pipe(
            mergeMap(() => this.kcApplicationService.addKcApplication(this.getAllValues())),
            tap((application) => this.populate(application))
        ).subscribe(() => this.onSubmit.emit('New' as KcApplicationSubmitType));
    }

    updateKcApplication(value?: any): Observable<any> {
        return this.isValid$()
            .pipe(
                mergeMap(() => {
                    const model = Object.assign({}, this.application || {}, this.form.getRawValue(), value || {});
                    return this.kcApplicationService.updateKcApplication(model)
                        .pipe(
                            tap((application) => this.populate(application))
                        );
                })
            );
    }

    submitDeleteApplication(): Subscription {
        return this.kcApplicationService.deleteKcApplication(this.application)
            .subscribe(() => this.onSubmit.emit('Delete' as KcApplicationSubmitType));
    }


    submitSaveApplication(): Subscription {
        return this.updateKcApplication()
            .subscribe(() => this.onSubmit.emit('Save' as KcApplicationSubmitType));
    }

    submitRejectApplication(): void {
        const value = this.kcStatusRejectControl.value || null;
        // make kcStatusReject formControl required only when user clicks the reject application button
        if (!value) {
            this.notificationService.showError('Please select a rejected status.');
            return;
        }
        this.updateKcApplication({keepingCurrentStatusId: value})
            .subscribe(() => this.onSubmit.emit('Reject' as KcApplicationSubmitType));
    }


    submitApproveApplication(): void {
        if (!this.application.isThirdPartyAgreeable) {
            this.notificationService.showError('Client has not agreed to share info with Third Parties.');
            return;
        }
        this.system.kcStatusApproved$
            .pipe(
                // canceled applications can view the advanced form info
                mergeMap((status: KcStatus) => this.updateKcApplication({
                    keepingCurrentStatusId: status.id,
                    approvedTimestamp: moment(new Date())
                })),
            )
            .subscribe(() => this.onSubmit.emit('Approve' as KcApplicationSubmitType));
    }

    submitCancelApplication(): void {
        const appid = this.route.snapshot.paramMap.get('applicationId');
        if (!appid) {
            this.notificationService.showError('Error: Missing param applicationId');
            return;
        }
        this.system.kcStatusCancel$
            .pipe(
                // canceled applications can view the advanced form info
                mergeMap((status: KcStatus) => this.updateKcApplication({keepingCurrentStatusId: status.id})),
                mergeMap(() => this.kcApplicationService.getKcApplicationById(appid))
            )
            .subscribe(() => this.onSubmit.emit('Cancel' as KcApplicationSubmitType));
    }

    openCalculator(): boolean {
        this.keepingCurrentAmountOwedCalculator?.openModal();
        return false;
    }
    ngOnDestroy(): void {

    }

    public onCalculate(event: number): void {
        if (!event) {
            return;
        }
        this.totalAmountOwedControl.setValue(event.toString());
    }

    setCalculatorEnabledStatus(enable: boolean): void {
        this.disableCalculator = enable === false;
    }
}
