import {createFeatureSelector, createSelector} from '@ngrx/store';
import {IActivityAuditData} from '../IActivityAuditData';


const activityAuditFeatureSelector = createFeatureSelector<IActivityAuditData>("activityAudit");

export const selectActivityAuditIsLoading = createSelector(
    activityAuditFeatureSelector,
    (state) => state.isLoading
);

export const selectActivityAuditPagedActivityAudit = createSelector(
    activityAuditFeatureSelector,
    (state) => state.pagedListOfActivityAudit
);

export const selectActivityAuditTotalCount = createSelector(
    activityAuditFeatureSelector,
    (state) => state.pagedListOfActivityAudit?.totalCount
);
