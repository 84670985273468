<h1 class="page-title">Application State Audits</h1>
<section class="widget">
    <div class="widget-body">
        <div class="mt">
            <table-dynamic id="datatable-table"
                           class="table table-striped table-hover"
                           [headers]="tableHeaders"
                           [currentPage]="currentPage"
                           [totalCount]="totalCount"
                           (headerClick)="sortAudits($event)"
                           (pageChanged)="setPage($event)"
                           (limitChange)="setLimit($event)">
                <tr *ngFor="let audit of pagedAudits?.items">
                    <td class="hidden-sm-down">{{ audit?.auditDate | moment: 'MM/DD/YYYY hh:mm:ss' }}</td>
                    <td class="hidden-sm-down">{{ audit?.auditorId }}</td>
                    <td class="hidden-sm-down">{{ audit?.auditorIdAgency }}</td>
                    <td class="hidden-sm-down">{{ audit?.oldState }}</td>
                    <td class="hidden-sm-down">{{ audit?.newState }}</td>
                    <td class="hidden-sm-down">{{ audit?.note }}</td>
                </tr>
            </table-dynamic>
        </div>
    </div>
</section>
