import {Component, ViewChild} from '@angular/core';
import {ITableHeader} from '../../../../shared/table/TableComponent';
import {KeepingCurrentReportFormComponent} from '../form/KeepingCurrentReportFormComponent';
import {Observable} from 'rxjs';
import {BudgetBillingReport} from '../../../../../services/api.client';
import {SHORT_DATE_FORMAT} from '../../../../../constants';
import * as moment from 'moment';

@Component({
    templateUrl: './CompletionReportComponent.html',
})
export class CompletionReportComponent {

    tableHeaders: ITableHeader[] = [
        {text: 'Agency'},
        {text: 'First Name'},
        {text: 'Last Name'},
        {text: 'Last 4 SSN'},
        {text: 'Phone Number'},
        {text: 'Account Number'},
        {text: 'Service Address'},
        {text: 'Enrollment Date'},
        {text: 'Completion Date'},
    ];

    @ViewChild('form')
    form: KeepingCurrentReportFormComponent;

    report$: Observable<BudgetBillingReport[]>;
    rows: any[][];

    submit(): void {
        this.form.submitCompletionReport()
            .map(this.transformTableColumns)
            .subscribe((rows) => this.rows = rows);
    }

    transformTableColumns = (report) => {
        return report.map(a => [
            a.agency,
            a.firstName,
            a.lastName,
            a.last4Ssn,
            this.formatPhoneNumber(a.phoneNumber, a.phoneExt),
            a.accountNumber,
            a.serviceAddress,
            moment(new Date(a.enrollmentDate).toISOString().slice(0, 10)).format(SHORT_DATE_FORMAT),
            moment(new Date(a.completionDate).toISOString().slice(0, 10)).format(SHORT_DATE_FORMAT)
        ]);
    }

    formatPhoneNumber(phoneNumber: string, phoneExt: string | null): string {
        if (!phoneNumber) {
            return '';
        }
        let formattedNumber = phoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
        if (phoneExt) {
            formattedNumber += ` Ext. ${phoneExt}`;
        }
        return formattedNumber;
    }
}

