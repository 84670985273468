import {Directive, forwardRef, Renderer2, ElementRef, Input} from '@angular/core';
import {NG_VALUE_ACCESSOR, ControlValueAccessor, UntypedFormControl} from '@angular/forms';
import {isBlank} from '../utilities/Util';

export const DEFAULT_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => CheckboxArrayAccessor),
  multi: true
};

@Directive({
  selector: '[checkboxArray]',
  host: {'(change)': 'onChange($event.target.checked, $event.ngValue)', '(blur)': 'onTouched()'},
  providers: [DEFAULT_VALUE_ACCESSOR]
})
export class CheckboxArrayAccessor implements ControlValueAccessor {
  @Input('formControl')
  control: UntypedFormControl;

  @Input('ngValue')
  ngValue: any;

  onChange = (_: any, __: any) => {
  }
  onTouched = () => {
  }

  constructor(private _renderer: Renderer2, private _elementRef: ElementRef) {
  }

  createOnChange(func: Function): any {
    const control = this.control;
    return function(checked: boolean): any {
      const currentValue = control.value;
      if (checked) {
        if (isBlank(currentValue)) {
          return func([this.ngValue]);
        } else if (~currentValue.indexOf(this.ngValue)) {
          return func(currentValue);
        } else {
          return func([this.ngValue].concat(currentValue));
        }
      } else {
        if (isBlank(currentValue)) {
          return func([]);
        } else if (~currentValue.indexOf(this.ngValue)) {
          return func(currentValue.filter(v => v !== this.ngValue));
        } else {
          return func(currentValue);
        }
      }
    };
  }

  writeValue(value: any): void {
    const elementValue = this.ngValue;

    const isChecked: boolean = !!(value && value.length && ~value.indexOf(elementValue));
    this._renderer.setProperty(this._elementRef.nativeElement, 'checked', isChecked);
  }

  registerOnChange(fn: (_: any) => void): void {
    this.onChange = this.createOnChange(fn);
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this._renderer.setProperty(this._elementRef.nativeElement, 'disabled', isDisabled);
  }
}
