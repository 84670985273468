<modal>
    <modal-header>
        <div>Calculate Total Amount Owed</div>
    </modal-header>
    <modal-body>
        <div class="form-group row">
            <label class="col-sm-5 control-label">Account balance</label>
            <div class="col-sm-7">
                <currencyInput [control]="accountBalanceControl"></currencyInput>
            </div>
        </div>
        <div class="form-group row">
            <label class="col-sm-5 control-label">Total Amount Deferred / Budget Balance Behind (B)</label>
            <div class="col-sm-7">
                <currencyInput [control]="totalAmountDeferredControl"></currencyInput>
            </div>
        </div>
        <div class="form-group row">
            <label class="col-sm-5 control-label">Unbilled Payment Agreement</label>
            <div class="col-sm-7">
                <currencyInput [control]="unbilledPaymentAgreementControl"></currencyInput>
            </div>
        </div>
    </modal-body>
    <modal-footer>
        <button type="button" class="btn btn-danger" (click)="onCloseModal()">Cancel</button>
        <button type="button" class="btn btn-primary m-l-1" (click)="onCalculate()">Calculate</button>
    </modal-footer>
</modal>
