import {IPledgeTypeData} from '../IPledgeTypeData';
import {createReducer, on} from '@ngrx/store';
import {
  pledgeTypesDataClear,
  pledgeTypesDataIsLoading,
  pledgeTypesDataLoaded, pledgeTypesDataSoftDelete,
  setAllPledgeTypes,
  setCurrentPledgeType
} from '../actions/pledgeType.actions';

export const initialState: IPledgeTypeData = {
  currentSelectedPledgeType: null,
  isPledgeTypesLoading: false,
  pledgeTypes: null,
  allPledgeTypes: null
};

export const pledgeTypeReducer = createReducer(
    initialState,
    on(pledgeTypesDataIsLoading, (state) => ({
      ...state,
      isPledgeTypesLoading: true
    })),
    on(pledgeTypesDataLoaded, (state, { payload }) => ({
      ...state,
      isPledgeTypesLoading: false,
      pledgeTypes: payload
    })),
    on(setCurrentPledgeType, (state, { payload }) => ({
      ...state,
      currentSelectedPledgeType: payload
    })),
    on(pledgeTypesDataClear, (state, { payload }) => ({
      ...state,
      pledgeTypes: payload
    })),
    on(setAllPledgeTypes, (state, { payload }) => ({
      ...state,
      allPledgeTypes: payload
    })),
    on(pledgeTypesDataSoftDelete, (state) => ({
      ...state,
      pledgeTypes: null
    })),
);
