<div class="js-sidebar-content">
    <header class="logo">
        <a [routerLink]="['clients']">
            <img class="logo-img" src="/assets/images/logo-mobile.svg" alt="United Way">
        </a>
    </header>
    <!-- main notification links are placed inside of .sidebar-nav -->
    <ul class="sidebar-nav">
        <li routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
            <a [routerLink]=" ['dashboard'] ">
      <span class="icon">
          <i class="fa fa-child"></i>
      </span>
                Dashboard
            </a>
        </li>
        <li routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
            <a [routerLink]=" ['clients'] ">
      <span class="icon">
          <i class="glyphicon glyphicon-user"></i>
      </span>
                Clients
            </a>
        </li>
        <li routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
            <a [routerLink]=" ['pledges'] ">
         <span class="icon">
            <i class="glyphicon glyphicon-gift"></i>
         </span>
                Pledges
            </a>
        </li>
        <li routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" [class.hidden]="!hasKeepingCurrent">
            <a [routerLink]=" ['applications'] ">
         <span class="icon">
            <i class="glyphicon glyphicon-list-alt"></i>
         </span>
                Keeping Current
            </a>
        </li>
        <li routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" [class.hidden]="!hasEfn">
            <a [routerLink]=" ['efn_cases'] ">
         <span class="icon">
            <i class="glyphicon glyphicon-list-alt"></i>
         </span>
                EF Network
            </a>
        </li>
        <li routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"
            [class.hidden]="!shouldShowApplications">
            <a [routerLink]=" ['assistance_applications'] ">
         <span class="icon">
            <i class="glyphicon glyphicon-list-alt"></i>
         </span>
                UHONS
            </a>
        </li>
        <li routerLinkActive="active">
            <a class="collapsed" data-target="#nav-reports" data-toggle="collapse" aria-expanded="false">
                <span class="icon"><i class="fa fa-table"></i></span> Reports <i class="toggle fa fa-angle-down"></i>
            </a>
            <ul id="nav-reports" class="collapse">
                <li routerLinkActive="active"><a [routerLink]="['reports/pledges']">Pledges</a></li>
                <li routerLinkActive="active"><a [routerLink]="['reports/demographic']">Demographic</a></li>
                <li routerLinkActive="active" [class.hidden]="!hasKeepingCurrent"><a
                        [routerLink]="['reports/keeping_current']">Keeping Current</a></li>
                <li routerLinkActive="active" [class.hidden]="!hasEfs"><a [routerLink]="['reports/efs']">EFS</a></li>
                <li routerLinkActive="active" [class.hidden]="(!system.isSystemAdmin) &&
                    (!system.isAgencyAdmin) &&
                    (!system.isRegionAdmin) &&
                    (!system.isViewer)">
                    <a [routerLink]="['reports/county-spending']">County Spending</a></li>
                <li routerLinkActive="active"
                    [class.hidden]="(!system.isSystemAdmin) && (!system.isAgencyAdmin) && (!system.isRegionAdmin)">
                    <a [routerLink]=" ['reports/audit'] ">Audit</a>
                </li>
                <li routerLinkActive="active"
                    [class.hidden]="(!system.isSystemAdmin) && (!system.isAgencyAdmin) && (!system.isRegionAdmin)">
                    <a [routerLink]=" ['reports/audit-progress'] ">Audit Progress</a>
                </li>
            </ul>
        </li>
        <li routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
            <a class="collapsed" data-target="#nav-support" data-toggle="collapse" aria-expanded="false">
                <span class="icon"><i class="fa fa-life-ring"></i></span>Support <i class="toggle fa fa-angle-down"></i>
            </a>
            <ul id="nav-support" class="collapse">
                <li routerLinkActive="active"><a [routerLink]="['support']">Info</a></li>
                <li routerLinkActive="active"><a [routerLink]="['support/agreements']">Agreements</a></li>
            </ul>
        </li>
        <li [class.hidden]="(!system.isSystemAdmin) && (!system.isAgencyAdmin) && (!system.isRegionAdmin)">
            <h5 class="sidebar-nav-title">
                Admin
            </h5>
        </li>
        <li routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"
            [class.hidden]="!showUwdaMenu()">
            <a [routerLink]=" ['uwdapledges'] ">
         <span class="icon">
            <i class="glyphicon glyphicon-list-alt"></i>
         </span>
                UWDA Pledges
            </a>
        </li>
        <li routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" [class.hidden]="!system.isSystemAdmin">
            <a [routerLink]=" ['agencies'] ">
         <span class="icon">
            <i class="glyphicon glyphicon-globe"></i>
         </span>
                Agencies
            </a>
        </li>
        <li routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" [class.hidden]="!system.isSystemAdmin && !system.isRegionAdmin">
            <a [routerLink]=" ['allocations'] ">
         <span class="icon">
            <i class="glyphicon glyphicon-piggy-bank"></i>
         </span>
                Allocations
            </a>
        </li>
        <li routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" [class.hidden]="!system.isSystemAdmin">
            <a [routerLink]=" ['companies'] ">
         <span class="icon">
            <i class="fa fa-building"></i>
         </span>
                Companies
            </a>
        </li>
        <li routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" [class.hidden]="!system.isSystemAdmin">
            <a [routerLink]=" ['pledgetypes'] ">
         <span class="icon">
            <i class="glyphicon glyphicon-tasks"></i>
         </span>
                Fund Types
            </a>
        </li>
        <li routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"
            [class.hidden]="(!system.isSystemAdmin) && (!system.isAgencyAdmin) && (!system.isRegionAdmin)">
            <a class="collapsed" data-target="#nav-users" data-toggle="collapse" aria-expanded="false"
               [routerLink]=" ['users'] ">
                <span class="icon"><i class="glyphicon glyphicon-user"></i></span>
                Users<i class="toggle fa fa-angle-down"></i>
            </a>
            <ul id="nav-users" class="collapse">
                <li routerLinkActive="active"><a [routerLink]="['users']">User List</a></li>
                <li routerLinkActive="active" *ngIf="userType.SystemAdmin"><a [routerLink]="['users/email']">Email
                    Users</a></li>
            </ul>
        </li>
        <li routerLinkActive="active" [class.hidden]="!system.isSystemAdmin && !system.isRegionAdmin">
            <a [routerLink]="['./pledge-bulk-payment-report']" routerLinkActive="active">
            <span class="icon">
                <i class="glyphicon glyphicon-usd"></i>
            </span>
                Bulk Payment Processing
            </a>
        </li>
        <li routerLinkActive="active" [class.hidden]="!system.isSystemAdmin">
            <a [routerLink]=" ['payment_processing'] ">
         <span class="icon">
            <i class="glyphicon glyphicon-usd"></i>
         </span>
                Payment Processing
            </a>
        </li>
        <li routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" [class.hidden]="!system.isSystemAdmin">
            <a [routerLink]=" ['lookups'] ">
         <span class="icon">
            <i class="glyphicon glyphicon-book"></i>
         </span>
                Look Up Data
            </a>
        </li>
        <li routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" [class.hidden]="!system.isSystemAdmin">
            <a [routerLink]=" ['counties'] ">
         <span class="icon">
            <i class="glyphicon glyphicon-map-marker"></i>
         </span>
                Counties
            </a>
        </li>
    </ul>
</div>
