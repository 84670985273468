import {filter} from 'rxjs/operators';
import {Component, OnInit} from '@angular/core';
import {SecurityService} from '../../../services/SecurityService';
import {Router} from '@angular/router';
import {Observable} from 'rxjs';
import {environment} from '../../../../environments/environment';
import {select, Store} from '@ngrx/store';
import * as fromRoot from '../../../store';
import {
  selectSecurityStateDataIsAuthenticated,
  selectSecurityStateDataIsLoggingIn,
  selectSecurityStateDataLoginMessage
} from '../../../store/selectors/security.selectors';

@Component({
  selector: 'login',
  templateUrl: './LoginComponent.html',
  styleUrls: ['./LoginComponent.scss'],
})
export class LoginComponent implements OnInit {

  email = '';
  password = '';
  rememberMe = true;

  isLoggingIn$: Observable<boolean>;
  isAuthenticated$: Observable<boolean>;
  errorMessage$: Observable<string>;

  constructor(private _securityService: SecurityService, private router: Router,
              private _store: Store<fromRoot.AppState>) {
    this.isLoggingIn$ = this._store.pipe(select(selectSecurityStateDataIsLoggingIn));
    this.isAuthenticated$ = this._store.pipe(select(selectSecurityStateDataIsAuthenticated));
    this.errorMessage$ = this._store.pipe(select(selectSecurityStateDataLoginMessage));
  }


  ngOnInit(): void {
    if (environment.production) {
      this.logout();
    }
    this.errorMessage$.pipe(
      filter((message) => !!message))
      .subscribe(() => this.password = '');
  }

  login(): void {
    this._securityService.login(this.email, this.password, this.rememberMe)
      .subscribe(() => {
        const url = this._securityService.redirectUrl || '/app/clients';
        this._securityService.redirectUrl = '';
        this.router.navigate([url]).then();
      });
  }

  logout(): void {
    this._securityService.logout();
  }
}
