<fieldset>
    <div class="row">
        <h6 *ngIf="!kcFpAcceptanceRateControl.valid"
            class="col-xs-12 text-danger">{{ kcFpAcceptanceRateControl.errors['kcFpAcceptanceRate'] }}</h6>
        <!-- Enrollment Information -->
        <h6 *ngIf="kcBalance" class="col-xs-12">
            {{ application?.agency?.agencyName || currentUser?.agency?.agencyName }}'s available allocation for
            <b>{{(kcPledge$ | async)?.name}}
                = {{ kcBalance?.balance | parseNumber | currency:'USD':'symbol':'1.2-2'  }}</b>.
        </h6>
        <h6 *ngIf="!totalMonthlyEstimatedCreditControl.valid"
            class="col-xs-12 text-danger">{{ totalMonthlyEstimatedCreditControl.errors['notEnoughBalance'] }}</h6>
        <div class="col-xs-6">
            <legend><strong>Enrollment</strong> Information</legend>
            <!-- 1 -->
            <div class="form-group row">
                <label for="normal-field" class="col-sm-5 control-label">Program</label>
                <div class="col-sm-7"
                     [class.has-danger]="!keepingCurrentProgramIdControl.disabled && keepingCurrentProgramIdControl.touched && !keepingCurrentProgramIdControl.valid">
                    <select [formControl]="keepingCurrentProgramIdControl" class="form-control">
                        <option *ngFor="let program of kcPrograms"
                                [value]="program.id">{{ program.programName }}</option>
                    </select>
                </div>
            </div>
            <!-- 2 -->
            <div class="form-group row"
                 [class.has-danger]="!accountNumberControl.disabled && accountNumberControl.touched && !accountNumberControl.valid">
                <div *ngIf="accountNumberControl.touched && accountNumberControl.hasError('companyAccountNumber')"
                     class="col-xs-12 form-control-feedback text-right">
                    {{ accountNumberControl.getError('companyAccountNumber') }}
                </div>
                <div *ngIf="accountNumberControl.touched && accountNumberControl.hasError('accountNumberAlreadyExist')"
                     class="col-xs-12 form-control-feedback text-right">
                    {{ accountNumberControl.getError('accountNumberAlreadyExist') }}
                </div>
                <label for="normal-field" class="col-sm-5 control-label">Ameren Account #</label>
                <div class="col-sm-7">
                    <input type="text" [formControl]="accountNumberControl" id="normal-field" class="form-control"
                           placeholder="Ameren Account #">
                </div>
            </div>
            <!-- 3 -->
            <div class="form-group row" style="align-items: center" *ngIf="showTotalAmountOwed">
                <label for="normal-field" class="col-sm-5 control-label">Total Amount Owed</label>
                <div class="col-sm-6">
                    <currencyInput [control]="totalAmountOwedControl"></currencyInput>
                </div>
                <div class="col-sm-1">
                    <div [class.disable-calculator]="disableCalculator" tooltip="Click here to calculate your total amount owed" (click)="openCalculator()" class="fa fa-calculator calculator"></div>
                </div>
            </div>

            <app-keeping-current-amount-owed-calculator (onCalculateEmitter)="onCalculate($event)"></app-keeping-current-amount-owed-calculator>

            <!-- 4 -->
            <div class="form-group row" *ngIf="showUpfrontAmountDue">
                <label for="normal-field" class="col-sm-5 control-label">1/12 Total Amount Due</label>
                <div class="col-sm-7">
                    <currencyInput [control]="upfrontAmountDueControl"></currencyInput>
                </div>
            </div>
            <!-- 5 -->
            <div class="form-group row" *ngIf="showUpfrontPaySource"
                 [class.has-danger]="!upfrontPaySourceControl.disabled && upfrontPaySourceControl.touched && !upfrontPaySourceControl.valid">
                <label for="normal-field" class="col-sm-5 control-label">1/12 Pay Source</label>
                <div class="col-sm-7">
                    <select [formControl]="upfrontPaySourceControl" class="form-control">
                        <option [value]="null"></option>
                        <option *ngFor="let type of paySourceTypes"
                                [value]="type.value">{{ type.key | splitCamelCase }}</option>
                    </select>
                </div>
            </div>
        </div>
        <div class="col-xs-6">
            <legend><strong>Enrollment</strong> Status</legend>
            <!-- 1 -->
            <div class="form-group row">
                <label for="normal-field" class="col-sm-5 control-label">Current Status:</label>
                <div class="col-sm-7">
                    {{ statusNameControl.value || 'New Application'}}
                </div>
            </div>
            <!-- 2 -->
            <div class="form-group row">
                <label for="normal-field" class="col-sm-5 control-label"> Estimated Bill Monthly Credit:</label>
                <div class="col-sm-7">
                    <currencyInput [control]="programMonthlyCreditControl"></currencyInput>
                </div>
            </div>
            <!-- 3 -->
            <div class="form-group row">
                <label for="normal-field" class="col-sm-8 control-label"> Estimated Arrears Monthly Credit:</label>
                <div class="col-sm-4">
                    <!--<currencyInput [control]="arrearsMonthlyEstimatedCredit"></currencyInput>-->
                    + {{ arrearsMonthlyEstimatedCreditControl.value  | parseNumber | currency:'USD':'symbol':'1.2-2' }}
                    <br>
                    =========
                </div>
            </div>
            <!-- 4 -->
            <div class="form-group row" style="margin-top: -10px">
                <label for="normal-field" class="col-sm-8 control-label">Estimated Total Current Monthly Credit:</label>
                <div class="col-sm-4">
                    <!--<currencyInput [control]="totalMonthlyEstimatedCredit"></currencyInput>-->
                    &nbsp;&nbsp;&nbsp;{{ totalMonthlyEstimatedCreditControl.value  | parseNumber | currency:'USD':'symbol':'1.2-2' }}
                </div>
            </div>
            <div class="form-group row" style="margin-top: 12px">
                <div class="col-sm-12 m-t-1 text-right">
                    <button *ngIf="application?.id && !kcStatus?.isNewStatus && !kcStatus?.isPendingApprovalStatus"
                            class="btn btn-primary" (click)="showAdvancedEnrollment = !showAdvancedEnrollment">Toggle
                        Advanced
                        Enrollment Status
                    </button>
                </div>
            </div>
        </div>
    </div>
    <advancedClientEnrollmentInfo *ngIf="showAdvancedEnrollment && application?.id"
                                  [application]="application"></advancedClientEnrollmentInfo>
    <div *ngIf="applicationStartedButNotSubmitted" class="row">
        <div class="col-12 my-5 text-danger">
            * Changes can be made after the application is submitted or you can delete the application and start over.
        </div>
    </div>
    <div class="row">
        <div class="col-xs-12">
            <ng-content selector="[footer]"></ng-content>
            <button *ngIf="!application?.id" type="submit" class="btn btn-primary pull-right"
                    (click)="createKcApplication()">
                Create Application
            </button>
            <button *ngIf="application?.id && system.loggedInUserCanContinueEnrollment(kcStatus)" type="submit"
                    class="btn btn-primary pull-right" (click)="onContinue.emit($event); false;">Continue
            </button>
            <button *ngIf="application?.id && system.loggedInUserCanDelete(kcStatus)" type="submit"
                    class="btn btn-danger pull-right" (click)="submitDeleteApplication()">Delete Application
            </button>
            <button *ngIf="system.showSaveApplicationBtn(kcStatus)" type="submit" class="btn btn-primary pull-right"
                    (click)="submitSaveApplication()">Save Application
            </button>
            <button *ngIf="application?.id && system.loggedInUserCanApprove(kcStatus)" type="submit"
                    class="btn btn-primary pull-right" (click)="submitApproveApplication()">Approve Application
            </button>
            <button *ngIf="system.loggedInUserCanCancel(kcStatus)" type="submit" class="btn btn-warning pull-right"
                    (click)="submitCancelApplication()">Cancel Application
            </button>
        </div>
    </div>
    <div class="row" *ngIf="system.loggedInUserCanReject(kcStatus)">
        <div class="col-xs-12">
            <legend><strong>Enrollment</strong> Processing</legend>
        </div>
        <div class="col-xs-12">
            <div class="form-group row"
                 [class.has-danger]="!kcStatusRejectControl.valid && kcStatusRejectControl.touched">
                <div class="form-control-feedback"></div>
                <label for="normal-field" class="col-sm-5 control-label">Enter Rejected Status:</label>
                <div class="col-sm-7">
                    <select class="form-control" [formControl]="kcStatusRejectControl" [disabled]="">
                        <option [value]="null"></option>
                        <option *ngFor="let status of system.kcStatusRejected$ | async"
                                [value]="status.id">{{ status.statusName }}</option>
                    </select>
                </div>
            </div>
        </div>
        <div class="col-xs-12">
            <button type="submit" class="btn btn-primary pull-right" (click)="submitRejectApplication()">Reject
                Application
            </button>
        </div>
    </div>
</fieldset>
