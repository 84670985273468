import {share} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {getMessageFromException} from '../utilities/Util';
import {NotificationService} from './NotificationService';
import {Allocation, AllocationClient, PagedListOfAllocationListItem} from './api.client';
import {Store} from '@ngrx/store';
import * as fromRoot from '../store';
import {Observable} from 'rxjs';
import {
    allocationsDataIsLoaded,
    allocationsDataIsLoading,
    allocationsDataSoftDelete,
    setCurrentSelectedAllocation
} from '../store/actions/allocation.actions';
import {PagedList} from '../models/PagedList';
// api
// models
// store

@Injectable()
export class AllocationService {
    constructor(private _allocationApiService: AllocationClient,
                private _notificationService: NotificationService,
                private _store: Store<fromRoot.AppState>) {
    }


    getAllocationsPaged(
        searchValue: string,
        pledgeTypeId: string,
        showZeroBalances: boolean,
        sortName?: string,
        limit?: number,
        offset?: number,
        descending?: boolean): Observable<PagedListOfAllocationListItem> {
        this.startLoadingAction();
        const request = this._allocationApiService.retrieveAllocationsPaged(
            searchValue,
            pledgeTypeId,
            showZeroBalances,
            limit,
            offset,
            sortName,
            null,
            descending
        ).pipe(share());
        request.subscribe({
            next: (result) => {
                this.setAllocationsAction(result);
            }, error: () => {
                this._notificationService.showError('Error Loading Allocations');
            }, complete: () => {

            }
        });
        return request;
    }

    addAllocation(allocation: Allocation): Observable<Allocation> {
        const request = this._allocationApiService.addAllocation(allocation).pipe(share());
        this._notificationService.showSuccess('Attempting to save new Allocation.');
        request.subscribe({
            next: (result) => {
                this.setCurrentSelectedAllocation(result);
                this._notificationService.showSuccess('Allocation Successfully Created!');
            }, error: (err) => {
                this._notificationService.showError(getMessageFromException(err));
            }, complete: () => {

            }
        });
        return request;
    }

    updateAllocation(allocation: Allocation): Observable<Allocation> {
        const request = this._allocationApiService.updateAllocation(allocation).pipe(share());
        this._notificationService.showSuccess('Attempting to update Allocation.');
        request.subscribe({
            next: (result) => {
                this.setCurrentSelectedAllocation(result);
                this._notificationService.showSuccess('Allocation Successfully Updated!');
            }, error: (err) => {
                this._notificationService.showError('There was a problem updating this Allocation. ' + err);
            }, complete: () => {

            }
        });
        return request;
    }

    deleteAllocation(id: string): Observable<boolean> {
        const request = this._allocationApiService.deleteAllocation(id).pipe(share());
        this._notificationService.showSuccess('Attempting to delete Allocation.');
        request.subscribe({
            next: () => {
                this.softDeleteAllocationAction();
                this._notificationService.showSuccess('Allocation Successfully Deleted!');
            }, error: (err) => {
                this._notificationService.showError(getMessageFromException(err));
            }, complete: () => {

            }
        });

        return request;
    }

    getAllocationById(id: string): Observable<Allocation> {
        return this._allocationApiService.retrieveAllocation(id).pipe(share());
    }

    setCurrentSelectedAllocation(allocation: Allocation): void {
        this.setCurrentSelectedAllocationAction(allocation);
    }

    private startLoadingAction(): void {
        this._store.dispatch(allocationsDataIsLoading());
    }

    setCurrentSelectedAllocationAction(selectedAllocation: Allocation): void {
        this._store.dispatch(setCurrentSelectedAllocation({ payload: selectedAllocation}));
    }

    private setAllocationsAction(allocationListItems: PagedListOfAllocationListItem): void {
        this._store.dispatch(allocationsDataIsLoaded({ payload: allocationListItems }));
    }

    private softDeleteAllocationAction(): void {
        this._store.dispatch(allocationsDataSoftDelete());
    }
}
