import {Observable} from 'rxjs';

export interface ModelEmpty<T> {
  (): Observable<T>;
}

declare module 'rxjs/internal/Observable' {
  interface Observable<T> {
    modelEmpty: ModelEmpty<T>;
  }
}
Observable.prototype.modelEmpty = function <T>() {
  return this.filter((obj) => !(obj && obj.id));
};
