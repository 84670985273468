<div class="container">
  <h1 class="page-title">Create Case</h1>
  <section class="widget">
    <efnCaseForm [client]="client" [user]="user" #f>
      <button class="btn btn-primary pull-right" [autoDisable]="submit">Create Case</button>
    </efnCaseForm>
  </section>
</div>
<prompt-dialog
        [titleText]="'Client County Inactive'"
        promptText="{{clientCountyInactiveMessage}}">
</prompt-dialog>

