import {IPledgeData} from '../IPledgeData';
import {createReducer, on} from '@ngrx/store';
import {
  clearCurrentSelectedPledge,
  pledgesDataIsLoaded,
  pledgesDataIsLoading,
  setCurrentClientPledgeList,
  setCurrentSelectedPledge, setManualPendingPledges, setPledgeTypeBalance, setUwdaPledges
} from '../actions/pledge.actions';

const initialState: IPledgeData = {
  isPledgesLoading: false,
  pledges: null,
  currentSelectedPledge: null,
  currentClientPledgeList: null,
  currentPledgeTypeBalance: null,
  confirmedUwdaPledges: null,
  manualPendingPledges: null
};

export const pledgeReducer = createReducer(
    initialState,
    on(setCurrentSelectedPledge, (state, { payload }) => ({
      ...state,
      currentSelectedPledge: payload
    })),
    on(pledgesDataIsLoaded, (state, { payload }) => ({
      ...state,
      isPledgesLoading: false,
      pledges: payload
    })),
    on(pledgesDataIsLoading, (state) => ({
      ...state,
      isPledgesLoading: false
    })),
    on(clearCurrentSelectedPledge, (state, { payload }) => ({
      ...state,
      currentSelectedPledge: payload
    })),
    on(setCurrentClientPledgeList, (state, { payload }) => ({
      ...state,
      currentClientPledgeList: payload
    })),
    on(setPledgeTypeBalance, (state, { payload }) => ({
      ...state,
      currentPledgeTypeBalance: payload
    })),
    on(setUwdaPledges, (state, { payload }) => ({
      ...state,
      isPledgesLoading: false,
      confirmedUwdaPledges: payload
    })),
    on(setManualPendingPledges, (state, { payload }) => ({
      ...state,
      isPledgesLoading: false, manualPendingPledges: payload
    })),
);
