export enum MonthDays {
  Day1 = 1,
  Day2 = 2,
  Day3 = 3,
  Day4 = 4,
  Day5 = 5,
  Day6 = 6,
  Day7 = 7,
  Day8 = 8,
  Day9 = 9,
  Day10 = 10,
  Day11 = 11,
  Day12 = 12,
  Day13 = 13,
  Day14 = 14,
  Day15 = 15,
  Day16 = 16,
  Day17 = 17,
  Day18 = 18,
  Day19 = 19,
  Day20 = 20,
  Day21 = 21,
  Day22 = 22,
  Day23 = 23,
  Day24 = 24,
  Day25 = 25,
  Day26 = 26,
  Day27 = 27,
  Day28 = 28,
  Day29 = 29,
  Day30 = 30,
  Day31 = 31
}
