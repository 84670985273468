import {Injectable} from '@angular/core';
import {AnnualIncomeService} from '../../services/AnnualIncomeService';
import {AbstractControl, ValidationErrors, ValidatorFn} from '@angular/forms';

@Injectable({
    providedIn: 'root',
})
export class IncomeValidatorService {

    constructor(private annualIncomeService: AnnualIncomeService) {
    }

    eligibilityScreeningIncomeValidator(): ValidatorFn {

        return (control: AbstractControl): ValidationErrors | null => {
            const adultCountControl = control.get('adultCount');
            const childrenCountControl = control.get('childrenCount');
            const householdIncomeControl = control.get('householdIncome');


            if (!adultCountControl || !childrenCountControl || !householdIncomeControl) {
                return null;
            }

            if (adultCountControl.invalid || childrenCountControl.invalid || householdIncomeControl.invalid) {
                return null;
            }

            const householdCount = adultCountControl.value + childrenCountControl.value;
            const maxIncome = this.annualIncomeService.calculatePovertyLevelMaxAnnualIncomeForHouseholdCount(householdCount);
            const maxMonthlyIncome = maxIncome / 12;
            const monthlyIncome = parseFloat(householdIncomeControl.value);

            if (monthlyIncome > maxMonthlyIncome) {
                return {incomeExceedsLimit: true};
            }
            return null;
        };
    }
}
