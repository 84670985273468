import {createAction, props} from '@ngrx/store';
import {EfnCase, PagedListOfEfnCase} from '../../services/api.client';

export const setSelectedEfnCase = createAction(
    '[Efn] SET_SELECTED_EFNCASE',
    props<{ payload: EfnCase }>());

export const setClientsEfnCases = createAction(
    '[Efn] SET_CLIENTS_EFNCASES',
    props<{ payload: EfnCase[] }>());

export const setEfnCasesPaged = createAction(
    '[Efn] SET_EFNCASES_PAGED',
    props<{ payload: PagedListOfEfnCase }>());

export const setOpenEfnCases = createAction(
    '[Efn] SET_OPEN_EFNCASES',
    props<{ payload: EfnCase[] }>());

export const setFundedEfnCases = createAction(
    '[Efn] SET_FUNDED_EFNCASES',
    props<{ payload: EfnCase[] }>());
