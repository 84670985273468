import {Component} from '@angular/core';

@Component({
  selector: 'error',
  templateUrl: './AboutComponent.html',
  styleUrls: ['./AboutComponent.scss'],
})
export class AboutComponent {

}
