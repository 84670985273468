import {IClientData} from '../IClientData';
import {createReducer, on} from '@ngrx/store';
import {clientsDataIsLoading, clientsDataLoaded, setCurrentClient} from '../actions/client.actions';

export const initialState: IClientData = {
    currentClient: null,
    isClientsLoading: false,
    clients: null
};

export const clientReducer = createReducer(
    initialState,
    on(setCurrentClient, (state, {payload}) => ({
        ...state,
        currentClient: payload
    })),
    on(clientsDataIsLoading, (state) => ({
        ...state,
        isClientsLoading: true
    })),
    on(clientsDataLoaded, (state, {payload}) => ({
        ...state,
        isClientsLoading: false,
        clients: payload
    }))
);
