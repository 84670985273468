import {share} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {getMessageFromException} from '../utilities/Util';
import {NotificationService} from './NotificationService';
import {CompanyCategoryType, Company, CompanyClient, DuplicateCompanyCheckResponse, PagedListOfCompany} from './api.client';
import {Store} from '@ngrx/store';
import * as fromRoot from '../store';
import {Observable} from 'rxjs';
import {
    companiesDataIsLoaded,
    companiesDataIsLoading,
    companiesDataSoftDelete,
    setCurrentSelectedCompany
} from '../store/actions/company.actions';
// api
// models
// store


@Injectable()
export class CompanyService {

    constructor(private _companyApiService: CompanyClient,
                private _notificationService: NotificationService,
                private _store: Store<fromRoot.AppState>) {
    }

    // getCompanies
    getCompanies(searchValue: string,
                 region: string,
                 category: CompanyCategoryType | null,
                 includeDisabled: boolean,
                 sortName: string,
                 descending: boolean,
                 limit: number,
                 offset: number): Observable<PagedListOfCompany> {
        this.startLoadingAction(); // start Loading Action
        const request = this._companyApiService.retrieveCompaniesPaged(
            searchValue,
            region,
            includeDisabled,
            category,
            limit || 10,
            offset || 0,
            sortName || 'Name',
            null,
            descending
        ).pipe(share());
        request.subscribe({
            next: (result) => {
                this.setCompaniesAction(result);
            }, error: () => {
                this._notificationService.showError('Error Loading Companies');
            }, complete: () => {

            }
        });
        return request;
    }

    addCompany(company: Company): Observable<Company> {
        const request = this._companyApiService.addCompany(company).pipe(share());
        this._notificationService.showSuccess('Attempting to save new Company.');
        request.subscribe({
            next: (result) => {
                this.setCurrentSelectedCompany(result);
                this._notificationService.showSuccess('Company Successfully Created!');
            }, error: (err) => {
                this._notificationService.showError(getMessageFromException(err));
            }, complete: () => {

            }
        });
        return request;
    }

    updateCompany(company: Company): Observable<Company> {
        const request = this._companyApiService.updateCompany(company).pipe(share());
        this._notificationService.showSuccess('Attempting to update Company.');
        request.subscribe({
            next: (result) => {
                this.setCurrentSelectedCompany(result);
                this._notificationService.showSuccess('Company Succesfully Updated!');
            }, error: () => {
                this._notificationService.showError('There was a problem updating this Company.');
            }, complete: () => {

            }
        });
        return request;
    }

    deleteCompany(id: string): Observable<boolean> {
        const request = this._companyApiService.deleteCompany(id).pipe(share());
        this._notificationService.showSuccess('Attempting to delete Company.');
        request.subscribe({
            next: () => {
                this.softDeleteCompanyAction();
                this._notificationService.showSuccess('Company Successfully Deleted!');
            }, error: (err) => {
                this._notificationService.showError(getMessageFromException(err));
            }, complete: () => {

            }
        });

        return request;
    }

    getCompanyById(id: string): Observable<Company> {
        const request = this._companyApiService.retrieveCompany(id).pipe(share());
        request.subscribe({
            next: (result) => {
                this.setCurrentSelectedCompany(result);
            }, error: () => {
                this._notificationService.showError('There was a problem retrieving the selected company.');
            }, complete: () => {

            }
        });
        return request;
    }

    setCurrentSelectedCompany(company: Company): void {
        this.setCurrentSelectedCompanyAction(company);
    }


    // --------Company Actions----------------
    private startLoadingAction(): void {
        this._store.dispatch(companiesDataIsLoading());
    }

    setCurrentSelectedCompanyAction(selectedCompany: Company): void {
        this._store.dispatch(setCurrentSelectedCompany({ payload: selectedCompany }));
    }

    private setCompaniesAction(companies: PagedListOfCompany): void {
        this._store.dispatch(companiesDataIsLoaded({ payload: companies}));
    }

    private softDeleteCompanyAction(): void {
        this._store.dispatch(companiesDataSoftDelete());
    }

    public checkDuplicateCompany(name: string, address: string): Observable<DuplicateCompanyCheckResponse> {
        return this._companyApiService.checkDuplicateCompany(name, address).pipe(share());
    }
}
