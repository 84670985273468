export enum AssistanceApplicationFileType {
    PhotoId = 'PhotoId',
    UtilityBill = 'UtilityBill',
    UtilityShutoffNotice = 'UtilityShutoffNotice',
    TaxStatement = 'TaxStatement',
    BankStatement = 'BankStatement',
    GovtOrCourtRecord = 'GovtOrCourtRecord',
    GovtBenefitsStatement = 'GovtBenefitsStatement',
    SsiSsdiAwardLetter = 'SsiSsdiAwardLetter',
    UnemploymentAward = 'UnemploymentAward',
    WicSnapAwardLetter = 'WicSnapAwardLetter',
    CheckStub = 'CheckStub',
    PensionLetter = 'PensionLetter',
    TaxReturns = 'TaxReturns',
    ProfitLossStatement = 'ProfitLossStatement',
    ChildcareBill = 'ChildcareBill',
    AutoRepairBill = 'AutoRepairBill',
    W9 = 'W9',
    EvictionCourtOrder = 'EvictionCourtOrder',
    LeaseAgreement = 'LeaseAgreement',
    DelinquentRentNotice = 'DelinquentRentNotice',
    SsnProof = 'SsnProof',
    ForCaseworkerUseOnly = 'ForCaseworkerUseOnly',
    Other = 'Other',
}
