import {Observable} from 'rxjs';

export interface LogSignature<T> {
  (name?: string): Observable<T>;
}

declare module 'rxjs/internal/Observable' {
  interface Observable<T> {
    log: LogSignature<T>;
  }
}
Observable.prototype.log = function <T>(name?: string) {
  if (name) {
    return this.do((x) => console.log(`${name}:`, x));
  } else {
    return this.do((x) => console.log(x));
  }
};
