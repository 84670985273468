import {createFeatureSelector, createSelector} from '@ngrx/store';
import {IUserData} from '../IUserData';

const userDataFeatureSelector = createFeatureSelector<IUserData>('user');

export const selectUserDataPagedUsersItems = createSelector(
    userDataFeatureSelector,
    (state) => state.pagedUsers?.items
);

export const selectUserDataPagedUsers = createSelector(
    userDataFeatureSelector,
    (state) => state.pagedUsers
);

export const selectUserDataPagedUsersTotalCount = createSelector(
    userDataFeatureSelector,
    (state) => state.pagedUsers?.totalCount
);

export const selectUserDataSelectedUser = createSelector(
    userDataFeatureSelector,
    (state) => state.selectedUser
);
