<h1 class="page-title">Pledge Reports</h1>
<div class="row mb-lg">
  <div class="col-xs-12">
    <div class="clearfix">
      <ul class="nav nav-tabs pull-xs-left" role="tablist">
        <li class="nav-item"><a class="nav-link" [routerLink]="['./agency_detail']" routerLinkActive="active">Agency
          Pledge Detail</a></li>
        <li class="nav-item"><a class="nav-link" [routerLink]="['./agency_summary']" routerLinkActive="active">Agency
          Pledge Summary</a></li>
        <li class="nav-item"><a class="nav-link" [routerLink]="['./allocation_and_balances']" routerLinkActive="active">Allocation
          and Balances</a></li>
        <li class="nav-item"
            *ngIf="(currentUser.userType == userTypes.SystemAdmin) || (currentUser.userType == userTypes.FundAdmin) || (currentUser.userType == userTypes.RegionAdmin)">
          <a class="nav-link" [routerLink]="['./pledges_detail']" routerLinkActive="active">Pending Pledge Detail</a>
        </li>
      </ul>
    </div>
    <div class="tab-content mb-lg overflow-visible">
      <div role="tabpanel" class="tab-pane active in clearfix">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</div>
