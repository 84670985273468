import {map} from 'rxjs/operators';
import {Component, OnInit, ViewChild} from '@angular/core';
import {ITableHeader} from '../../../../shared/table/TableComponent';
import {KeepingCurrentReportFormComponent} from '../form/KeepingCurrentReportFormComponent';
import {MissedPaymentReport} from '../../../../../services/api.client';
import {Observable} from 'rxjs';
import {formatCurrency} from '../../../../../utilities/Util';
import {SHORT_DATE_FORMAT} from '../../../../../constants';
import * as moment from 'moment';
import {select, Store} from '@ngrx/store';
import * as fromRoot from '../../../../../store';
import {selectReportDataMissedPaymentReport} from '../../../../../store/selectors/report.selectors';

@Component({
    selector: 'missed-payments-reports',
    templateUrl: './MissedPaymentsReportComponent.html',
})
export class MissedPaymentsReportComponent implements OnInit {

    tableHeaders: ITableHeader[] = [
        {text: 'Agency'},
        {text: 'Customer'},
        {text: 'Account Number'},
        {text: 'Delinquent Date'},
        {text: 'Billing Date'},
        {text: 'Amount Due'},
        {text: 'Amount Paid'},
        {text: 'Import Date'},
        {text: 'Enrollment Date'}
    ];

    @ViewChild('form')
    form: KeepingCurrentReportFormComponent;

    report$: Observable<MissedPaymentReport[]>;
    rows: any;

    constructor(private _store: Store<fromRoot.AppState>) {
        this.report$ = this._store.pipe(select(selectReportDataMissedPaymentReport));
    }

    ngOnInit(): void {
        this.rows = this.report$
            .exists().pipe(
                map((report) => report.map(r => [
                    r.agency,
                    r.customer,
                    r.accountNumber,
                    moment(r.delinquentDate).format(SHORT_DATE_FORMAT),
                    moment(r.billingDate).format(SHORT_DATE_FORMAT),
                    formatCurrency(r.amountDue),
                    formatCurrency(r.amountPaid),
                    moment(r.importDate).format(SHORT_DATE_FORMAT),
                    moment(r.enrollmentDate).format(SHORT_DATE_FORMAT)
                ])));

    }
}
