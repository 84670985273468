<div class="container" *ngIf="currentClient">

    <div class="row flex-md-items-between">
        <label class="col-xs"><strong>Pledge History For:</strong>
            {{currentClient.prefix}} {{currentClient.firstName}} {{currentClient.middleInitial}} {{currentClient.lastName}} {{currentClient.suffix}}
        </label>
        <div class="col-xs-3">
            <a [routerLink]="['../pledge/add']" class="btn btn-primary mb-xs new-button"
               [class.disabled]="!isClientValid()">
                New Pledge
            </a><br>
            <span *ngIf="!isClientValid()" class="text-danger">This Client Has Missing Info</span>
        </div>
    </div>
    <div class="row">
        <table class="table table-striped table-hover">
            <thead>
            <th class="text-xs-center">Pledge Amount</th>
            <th class="text-xs-center">Pledge Status</th>
            <th class="text-xs-center">Paid Date</th>
            <th class="text-xs-center">Fund Type</th>
            </thead>
            <tbody>
            <tr *ngFor="let item of currentClientPledgeList">
                <td class="text-xs-center"><a class="clickable text-primary"
                                              [routerLink]="['/app/pledge/', item.id]">{{item.pledgedAmount | parseNumber | currency:'USD':'symbol':'1.2-2'}}</a>
                </td>
                <td class="text-xs-center"
                    [class.has-danger]="item.status == 5 || item.status == 'Deleted'">{{formatPledgeStatus(item.status)}}</td>
                <td class="text-xs-center">{{item.paidDate ? (item.paidDate | moment: 'L') : 'Not Paid'}}</td>
                <td class="text-xs-center">{{formatPledgeTypeName(item.pledgeTypeId)}}</td>
            </tr>
            </tbody>
        </table>
    </div>

</div>
