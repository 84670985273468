<h1 class="page-title">Keeping Current Reports</h1>
<div class="row mb-lg">
    <div class="col-xs-12">
        <div class="clearfix">
            <ul class="nav nav-tabs pull-xs-left" role="tablist">
                <li class="nav-item"><a class="nav-link" [routerLink]="['./enrollment_status']"
                                        routerLinkActive="active">Enrollment
                    Status</a></li>
                <li class="nav-item"><a class="nav-link" [routerLink]="['./missed_payments']" routerLinkActive="active">Missed
                    Payments</a></li>
                <li class="nav-item" [class.hidden]="!systemService.isSystemAdmin && !systemService.isFundAdmin"><a
                        class="nav-link" [routerLink]="['./confirmation_orphans']" routerLinkActive="active">Confirmation
                    Orphans</a>
                </li>
                <li class="nav-item" [class.hidden]="!systemService.isSystemAdmin && !systemService.isFundAdmin"><a
                        class="nav-link" [routerLink]="['./download_approved_enrollments']" routerLinkActive="active">Download
                    Approved Enrollments</a></li>
                <li class="nav-item"><a
                        class="nav-link" [routerLink]="['./budget_billing']" routerLinkActive="active">Budget
                    Billing</a></li>
                <li class="nav-item"><a
                        class="nav-link" [routerLink]="['./completion']" routerLinkActive="active">Nearing
                    Completion</a></li>
            </ul>
        </div>
        <div class="tab-content overflow-visible mb-lg">
            <div role="tabpanel" class="tab-pane active in">
                <router-outlet></router-outlet>
                <div class="clearfix"></div>
            </div>
        </div>
    </div>
</div>
