import {Injectable} from '@angular/core';
import {MaxAnnualIncomeLevelViewModel, PublicClient} from './api.client';

@Injectable({providedIn: 'root'})
export class AnnualIncomeService {

    public maxAnnualIncomeLevels: MaxAnnualIncomeLevelViewModel[];

    constructor(private publicClient: PublicClient) {

    }

    public getData(): void {
        this.publicClient.getMaxAnnualIncomes().subscribe({
            next: (response: MaxAnnualIncomeLevelViewModel[]) => {
                this.maxAnnualIncomeLevels = response;
            },
            error: (err) => {
                console.error(err);
                this.maxAnnualIncomeLevels = [];
            }
        });
    }

    private getMaxAnnualIncomeByHouseholdCount(householdCount: number): number {
        if (householdCount < 1) {
            return 0;
        }
        const incomeData = this.maxAnnualIncomeLevels.find(x => x.householdSize === householdCount);
        return incomeData?.maxAnnualIncome || 0;
    }

    public calculatePovertyLevelMaxAnnualIncomeForHouseholdCount(householdCount: number): number {
        if (householdCount < 1) {
            return 0;
        }
        return this.getMaxAnnualIncomeByHouseholdCount(householdCount);
    }

}
