import {IReportData} from '../IReportData';
import {createReducer, on} from '@ngrx/store';
import {
  agencyDetailReportSuccess,
  agencySummaryReportSuccess,
  allocationReportSuccess,
  buildCompanyMailingLabelsFailed,
  buildCompanyMailingLabelsSuccess,
  buildProcessingDetailReportFailed,
  buildProcessingDetailReportSuccess,
  buildShiftPledgeReportFailed, buildShiftPledgeReportReportReportFailed,
  buildShiftPledgeReportReportReportSuccess,
  buildShiftPledgeReportSuccess,
  pledgeDemographicByZipCodeReportSuccess,
  pledgeDemographicCountyReportSuccess,
  pledgeDemographicDetailReportSuccess,
  pledgeDemographicSummaryReportSuccess,
  reportRetrievePledgeDetailReportFailed,
  reportRetrievePledgeDetailReportSuccess,
  retrieveEfsReportFailed,
  retrieveEfsReportSuccess,
  retrieveKcConfirmOrphanReportFailed,
  retrieveKcConfirmOrphanReportSuccess,
  retrieveKcEnrollmentReportFailed,
  retrieveKcEnrollmentReportSuccess,
  retrieveKcMissedPaymentReportFailed,
  retrieveKcMissedPaymentReportSuccess,
  retrievePledgeCountyReportFailed,
  retrievePledgeCountyReportSuccess,
  retrievePledgeZipcodeReportFailed,
  retrievePledgeZipcodeReportSuccess
} from '../actions/report.actions';

export const initialState: IReportData = {
  pledgeDemographicByZipCodeReport: null,
  pledgeDemographicDetailReport: null,
  pledgeDemographicCountyReport: null,
  pledgeDemographicSummaryReport: null,

  agencyDetailReport: null,
  agencySummaryReport: null,
  allocationReport: null,

  companyMailingReport: null,
  processingReport: null,
  shiftPledgeReportSummary: null,

  enrollmentReport: null,
  confirmOrphanReport: null,
  missedPaymentReport: null,

  paymentProcessingCompanyMailingReport: null,
  efsReport: null,
  pendingPledgesDetailProcessingReport: null,
  pledgeDetailReport: null,
  pledgeDetailSummaryReport: null,
  companyMailingLabelsReport: null,
  processingDetailReport: null,
};

export const reportReducer = createReducer(
    initialState,
    on(reportRetrievePledgeDetailReportFailed, (state, { payload }) => ({
      ...state,
        pledgeDemographicDetailReport: payload
    })),
    on(reportRetrievePledgeDetailReportSuccess, (state, { payload }) => ({
      ...state,
      pledgeDetailReport: payload
    })),
    on(retrievePledgeZipcodeReportSuccess, (state, { payload }) => ({
      ...state,
        pledgeDemographicByZipCodeReport: payload
    })),
    on(retrievePledgeZipcodeReportFailed, (state, { payload }) => ({
      ...state,
        pledgeDemographicByZipCodeReportError: payload
    })),
    on(retrievePledgeCountyReportSuccess, (state, { payload }) => ({
      ...state,
        pledgeByCountyCodeReport: payload
    })),
    on(retrievePledgeCountyReportFailed, (state, { payload }) => ({
      ...state,
        pledgeByCountyCodeReportError: payload
    })),
    on(agencyDetailReportSuccess, (state, { payload }) => ({
      ...state,
        agencyDetailReport: payload
    })),
    on(agencySummaryReportSuccess, (state, { payload }) => ({
      ...state,
        agencySummaryReport: payload
    })),
    on(allocationReportSuccess, (state, { payload }) => ({
      ...state,
        allocationReport: payload
    })),
    on(pledgeDemographicDetailReportSuccess, (state, { payload }) => ({
      ...state,
        pledgeDemographicDetailReport: payload
    })),
    on(pledgeDemographicCountyReportSuccess, (state, { payload }) => ({
      ...state,
        pledgeDemographicCountyReport: payload
    })),
    on(pledgeDemographicByZipCodeReportSuccess, (state, { payload }) => ({
      ...state,
        pledgeDemographicByZipCodeReport: payload
    })),
    on(pledgeDemographicSummaryReportSuccess, (state, { payload }) => ({
      ...state,
        pledgeDemographicSummaryReport: payload
    })),
    on(retrieveEfsReportSuccess, (state, { payload }) => ({
      ...state,
        efsReport: payload
    })),
    on(retrieveEfsReportFailed, (state, { payload }) => ({
      ...state,
        efsReportFailed: payload
    })),
    on(retrieveKcEnrollmentReportSuccess, (state, { payload }) => ({
      ...state,
        enrollmentReport: payload
    })),
    on(retrieveKcEnrollmentReportFailed, (state, { payload }) => ({
      ...state,
        enrollmentReportFailed: payload
    })),
    on(retrieveKcMissedPaymentReportSuccess, (state, { payload }) => ({
      ...state,
        missedPaymentReport: payload
    })),
    on(retrieveKcMissedPaymentReportFailed, (state, { payload }) => ({
      ...state,
        missedPaymentReportFailed: payload
    })),
    on(retrieveKcConfirmOrphanReportSuccess, (state, { payload }) => ({
      ...state,
        confirmOrphanReport: payload
    })),
    on(retrieveKcConfirmOrphanReportFailed, (state, { payload }) => ({
      ...state,
        confirmOrphanReportFailed: payload
    })),
    on(buildCompanyMailingLabelsSuccess, (state, { payload }) => ({
      ...state,
      companyMailingLabelsReport: payload
    })),
    on(buildCompanyMailingLabelsFailed, (state, { payload }) => ({
      ...state,
        companyMailingReportError: payload
    })),
    on(buildProcessingDetailReportSuccess, (state, { payload }) => ({
      ...state,
      processingDetailReport: payload
    })),
    on(buildProcessingDetailReportFailed, (state, { payload }) => ({
      ...state,
        processingDetailReportError: payload
    })),
    on(buildShiftPledgeReportSuccess, (state, { payload }) => ({
      ...state,
        shiftPledgeReportSummary: payload
    })),
    on(buildShiftPledgeReportFailed, (state, { payload }) => ({
      ...state,
        shiftPledgeReportSummaryError: payload
    })),
    on(buildShiftPledgeReportReportReportSuccess, (state) => ({
      ...state
    })),
    on(buildShiftPledgeReportReportReportFailed, (state) => ({
      ...state
    })),
);
