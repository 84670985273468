import {HttpClient} from '@angular/common/http';
import {share} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {getMessageFromException} from '../utilities/Util';
import {Agency, AgencyClient, PagedListOfAgency} from './api.client';
import {NotificationService} from './NotificationService';
import {Store} from '@ngrx/store';
import * as fromRoot from '../store';
import {Observable} from 'rxjs';
import {
    agenciesDataIsLoading,
    agenciesDataLoaded,
    agenciesDataSoftDelete,
    setCurrentSelectedAgency
} from '../store/actions/agency.actions';
// api
// models
// store

@Injectable()
export class AgencyService {

    constructor(private _http: HttpClient,
                private _agencyApiService: AgencyClient,
                private _notificationService: NotificationService,
                private _store: Store<fromRoot.AppState>) {
    }

    getAgencies(
        searchValue: string,
        pledgeTypeId: string,
        includeDisabled: boolean = false,
        limit: number,
        sortName: string,
        descending: boolean,
        offset: number): Observable<PagedListOfAgency> {
        this.startLoadingAction();
        const request = this._agencyApiService.searchAgencies(
            searchValue,
            pledgeTypeId,
            includeDisabled,
            limit || 10,
            offset || 0,
            sortName || '',
            null,
            descending || false
        ).pipe(share());
        request.subscribe({
            next: (data) => {
                this.setAgenciesAction(data);
            }, error: (err) => {
                this._notificationService.showError(getMessageFromException(err));
            }, complete: () => {

            }
        });
        return request;
    }

    addAgency(agency: Agency): Observable<Agency> {
        const request = this._agencyApiService.addAgency(agency).pipe(share());
        this._notificationService.showSuccess('Attempting to save new Agency.');
        request.subscribe({
            next: (result) => {
                this.setCurrentSelectedAgency(result);
                this._notificationService.showSuccess('Agency Successfully Created!');
            }, error: (err) => {
                this._notificationService.showError(getMessageFromException(err));
            }, complete: () => {

            }
        });

        return request;
    }

    updateAgency(agency: Agency): Observable<Agency> {
        const request = this._agencyApiService.updateAgency(agency).pipe(share());
        this._notificationService.showSuccess('Attempting to update Agency.');
        request.subscribe({
            next: (result) => {
                this.setCurrentSelectedAgency(result);
                this._notificationService.showSuccess('Agency Successfully Updated!');
            }, error: () => {
                this._notificationService.showError('There was a problem updating this Agency.');
            }, complete: () => {

            }
        });

        return request;
    }

    deleteAgency(id: string): Observable<boolean> {
        const request = this._agencyApiService.deleteAgency(id).pipe(share());
        this._notificationService.showSuccess('Attempting to delete Agency.');
        request.subscribe({
            next: () => {
                this.softDeleteAgencyAction();
                this._notificationService.showSuccess('Agency Successfully Deleted!');
            },
            error: (err) => {
                this._notificationService.showError(getMessageFromException(err));
            },
            complete: () => {

            }
        });

        return request;
    }

    setCurrentSelectedAgency(selectedAgency: Agency): void {
        this.setCurrentSelectedAgencyAction(selectedAgency);
    }

    getAgencyById(id: string): Observable<Agency> {
        const request = this._agencyApiService.retrieveAgency(id).pipe(share());
        request.subscribe({
            next: (result) => {
                this.setCurrentSelectedAgency(result);
            }, error: () => {
                this._notificationService.showError('There was a problem retrieving the selected agency.');
            }, complete: () => {

            }
        });
        return request;
    }

    getRegionAdminAgencies(): Observable<Agency[]> {
        return this._agencyApiService.getRegionAdminAgencies().pipe(share());
    }

    // start, set clear
    private startLoadingAction(): void {
        this._store.dispatch(agenciesDataIsLoading());
    }

    private setAgenciesAction(agencies: PagedListOfAgency): void {
        this._store.dispatch(agenciesDataLoaded({payload: agencies}));
    }

    setCurrentSelectedAgencyAction(selectedAgency: Agency): void {
        this._store.dispatch(setCurrentSelectedAgency({payload: selectedAgency}));
    }

    private softDeleteAgencyAction(): void {
        this._store.dispatch(agenciesDataSoftDelete());
    }
}
