import {createAction, props} from '@ngrx/store';
import {CompanyCategoryThresholdViewModel} from '../../services/api.client';
import {IPayeeCompanyCategoryAmount} from '../../shared/interfaces/IPayeeCompanyCategoryAmount';

export const setCompanyCategoryThresholds = createAction(
    '[Company Category] SET_COMPANY_CATEGORY_THRESHOLDS',
    props<{ payload: CompanyCategoryThresholdViewModel[] }>());
export const setCurrentPayeeCompanyCategoryAmounts = createAction(
    '[Company Category] SET_CURRENT_COMPANY_CATEGORY_AMOUNTS',
    props<{ payload: IPayeeCompanyCategoryAmount[] }>());
export const setCompanyCategories = createAction(
    '[Company Category] SET_COMPANY_CATEGORIES',
    props<{ payload: CompanyCategoryThresholdViewModel[] }>());
