import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

export interface MapSignature<T> {
  <A,T>(project: (item: A) => T): Observable<T>;

  // <T, R>(project: (value: T, index: number) => R, thisArg?: any): Observable<R>;
  // <T, R>(this: Observable<T>, project: (value: T, index: number) => R, thisArg?: any): Observable<R>;
}

declare module 'rxjs/internal/Observable' {
  interface Observable<T> {
    map: MapSignature<T>;
  }
}
Observable.prototype.map = function(predicate) {
  return this.pipe(map(predicate))
};
