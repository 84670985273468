import { Component, OnInit, ViewChild, Input, AfterViewInit } from '@angular/core';
import { FormControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { KeepingCurrentReportUrls } from '../../../../../api/KeepingCurrentReportUrls';
import { FileDownloadService } from '../../../../../services/FileDownloadService';
import { AgencyListModalComponent } from '../../../agency/AgencyListModalComponent';
import { KcReportService } from '../../../../../services/KcReportService';
import {
    ApprovedEnrollment,
    ApprovedEnrollmentHistory, BudgetBillingReport, CompletionReport,
    ConfirmOrphanReport,
    EnrollmentReport,
    MissedPaymentReport
} from '../../../../../services/api.client';
import { SHORT_DATE_FORMAT } from '../../../../../constants';
import * as moment from 'moment';
import { mergeMap, tap } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import * as fromRoot from '../../../../../store';
import { selectSecurityStateDataSecurityToken } from '../../../../../store/selectors/security.selectors';
import { Router } from '@angular/router';

(window as any).m = moment;

@Component({
    selector: '[keepingCurrentReportForm]',
    templateUrl: './KeepingCurrentReportFormComponent.html',
    styleUrls: ['./KeepingCurrentReportFormComponent.scss'],
})
export class KeepingCurrentReportFormComponent implements OnInit, AfterViewInit {

    form: UntypedFormGroup;

    get startDate(): FormControl {
        return this.form.get('startDate') as FormControl;
    }

    get endDate(): FormControl {
        return this.form.get('endDate') as FormControl;
    }

    get monthYear(): FormControl {
        return this.form.get('monthYear') as FormControl;
    }

    get startMonthYear(): FormControl {
        return this.form.get('startMonthYear') as FormControl;
    }

    get endMonthYear(): FormControl {
        return this.form.get('endMonthYear') as FormControl;
    }

    get statuses(): FormControl {
        return this.form.get('statuses') as FormControl;
    }

    get agencyId(): FormControl {
        return this.form.get('agencyId') as FormControl;
    }

    dateFormat = SHORT_DATE_FORMAT;

    @Input('showDateRange') showDateRange: boolean;
    @Input('showStatuses') showStatuses: boolean;
    @Input('showAgencies') showAgencies: boolean;
    @Input('showMonthSelection') showMonthSelection: boolean;
    @Input('showMonthRangeSelection') showMonthRangeSelection: boolean;

    approvedEnrollmentHistory: ApprovedEnrollmentHistory[];
    _showExportDescription: boolean;

    @Input('showExportDescription')
    set showExportDescription(value: boolean) {
        this._showExportDescription = value;
        if (this._showExportDescription) {
            this._kcReportService.getApprovedEnrollmentHistory()
                .subscribe((approvedEnrollmentHistory) => this.approvedEnrollmentHistory = approvedEnrollmentHistory);
        }
    }

    get showExportDescription(): boolean {
        return this._showExportDescription;
    }

    exportDescription = new FormControl<moment.Moment | string>('new');

    @ViewChild(AgencyListModalComponent)
    agencyListModal: AgencyListModalComponent;

    private securityToken: string;
    private securityToken$: Observable<string>;

    constructor(private _formBuilder: UntypedFormBuilder,
                private _fileDownloadService: FileDownloadService,
                private _kcReportService: KcReportService,
                private _store: Store<fromRoot.AppState>) {
        this.securityToken$ = this._store.pipe(select(selectSecurityStateDataSecurityToken));
    }

    ngOnInit(): void {
        this.securityToken$.subscribe(securityToken => this.securityToken = securityToken);
        this.initForm();
    }

    initForm(): void {
        this.form = this._formBuilder.group({
            startDate: new FormControl(moment().startOf('year').format('YYYY-MM-DD')),
            endDate: new FormControl(moment().endOf('day').format('YYYY-MM-DD')),
            statuses: new FormControl(''),
            agencyId: new FormControl(''),
            monthYear: new FormControl(moment().startOf('month').format('YYYY-MM')),
            startMonthYear: new FormControl(moment().startOf('month').format('YYYY-MM')),
            endMonthYear: new FormControl(moment().add(2, 'months').endOf('month').format('YYYY-MM')),
            exportDescription: new FormControl('new'),
        });
    }

    submit(): Observable<any> | void {
        return void 0;
    }

    submitEnrollmentStatusReport(): Observable<EnrollmentReport[]> {
        const model = this.momentize(this.form.value);
        return this._kcReportService.buildEnrollmentReport(model.startDate,
            model.endDate,
            model.agencyId,
            model.statuses);
    }

    downloadEnrollmentStatusReport(): string {
        const model = this.momentize(this.form.value);
        return this._fileDownloadService.buildUrl(KeepingCurrentReportUrls.DOWNLOAD_ENROLLMENT_REPORT, model);
    }

    submitMissedPaymentsReport(): Observable<MissedPaymentReport[]> {
        const model = this.momentize(this.form.value);
        return this._kcReportService.buildMissedPaymentReport(model.startDate, model.endDate, model.agencyId);
    }

    downloadMissedPaymentsReport(): string {
        const model = this.momentize(this.form.value);
        return this._fileDownloadService.buildUrl(KeepingCurrentReportUrls.DOWNLOAD_MISSED_PAYMENT_REPORT, model);
    }

    setDownloadTimeStampForApprovalRecordedApplications(): Observable<ApprovedEnrollmentHistory[]> {
        return this._kcReportService.setDownloadTimeStampForApprovalRecordedApplications()
            .pipe(
                mergeMap(() => {
                    this.exportDescription?.setValue(moment(new Date()));
                    this.showExportDescription = true;
                    return this.submitApprovedEnrollmentsReport()
                        .pipe(
                            tap(() => this.exportDescription?.setValue(moment(new Date()).startOf('day').format('YYYY-MM-DDTHH:mm:ss')))
                        );
                })
            );
    }

    submitApprovedEnrollmentsReport(): Observable<ApprovedEnrollment[]> {
        return this._kcReportService.buildApprovedEnrollmentReport(this.exportDescription?.value as moment.Moment);
    }

    downloadApprovedEnrollmentsReport(): string {
        let exportDate = moment(this.exportDescription?.value).startOf('day').format('YYYY-MM-DDTHH:mm:ss');
        if (exportDate === 'Invalid date') {
            exportDate = moment().startOf('day').format('YYYY-MM-DDTHH:mm:ss');
        }
        return this._fileDownloadService.buildUrl(KeepingCurrentReportUrls.DOWNLOAD_APPROVED_ENROLLMENTS_REPORT, {
            exportDate
        });
    }

    submitConfirmationOrphansReport(): Observable<ConfirmOrphanReport[]> {
        const model = this.momentize(this.form.value);
        return this._kcReportService.buildConfirmOrphanReport(model.startDate, model.endDate, model.agencyId);
    }

    downloadConfirmationOrphansReport(): string {
        const model = this.momentize(this.form.value);
        return this._fileDownloadService.buildUrl(KeepingCurrentReportUrls.DOWNLOAD_CONFIRM_ORPHAN_REPORT, model);
    }

    submitBudgetBillingReport(): Observable<BudgetBillingReport[]> {
        const model = this.momentize(this.form.value);
        return this._kcReportService.buildBudgetBillingReport(model.monthYear, model.agencyId);
    }

    downloadBudgetBillingReport(): string {
        const model = this.momentize(this.form.value);
        return this._fileDownloadService.buildUrl(KeepingCurrentReportUrls.DOWNLOAD_BUDGET_BILLING_REPORT, model);
    }

    submitCompletionReport(): Observable<CompletionReport[]> {
        const model = this.momentize(this.form.value);
        return this._kcReportService.buildCompletionReport(model.startMonthYear, model.endMonthYear, model.agencyId);
    }

    downloadCompletionReport(): string {
        const model = this.momentize(this.form.value);
        return this._fileDownloadService.buildUrl(KeepingCurrentReportUrls.DOWNLOAD_COMPLETION_REPORT, model);
    }

    private momentize(model: any): any {
        model.startDate = moment(model.startDate).startOf('day');
        model.endDate = moment(model.endDate).endOf('day');
        return model;
    }

    openCalendar(event: Event): void {
        const input = event.target as HTMLInputElement;
        setTimeout(() => input.showPicker(), 0);
    }

    ngAfterViewInit(): void {
    }
}
