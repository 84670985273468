import {Component, Input} from '@angular/core';
import {extractEnumNames} from '../../../../../../utilities/Util';
import {EfnCaseStatusType} from '../../../../../../services/api.client';
import {UntypedFormControl} from '@angular/forms';

@Component({
  selector: 'efnCaseStatus',
  template: `
<select [formControl]="control" class="form-control">
  <option *ngFor="let o of options" [value]="o.key.toLowerCase()">{{ o.key }}</option>
</select>
`,
})
export class EfnCaseStatusComponent {
  @Input()
  control: UntypedFormControl;

  options = extractEnumNames(EfnCaseStatusType);
}
