import {createFeatureSelector, createSelector} from '@ngrx/store';
import {IAllocationData} from '../IAllocationData';

const allocationDataFeatureSelector = createFeatureSelector<IAllocationData>("allocation");

export const selectAllocationDataCurrentSelectedAllocation = createSelector(
    allocationDataFeatureSelector,
    (state) => state.currentSelectedAllocation
);

export const selectAllocationDataAllocations = createSelector(
    allocationDataFeatureSelector,
    (state) => state.allocations
);

export const selectAllocationDataAllocationsItems = createSelector(
    allocationDataFeatureSelector,
    (state) => state.allocations?.items
);

export const selectAllocationDataAllocationsTotalCount = createSelector(
    allocationDataFeatureSelector,
    (state) => state.allocations?.totalCount
);
