import {Observable} from 'rxjs';
import {filter} from 'rxjs/operators';

export interface ModelExists<T> {
  (): Observable<T>;
}

declare module 'rxjs/internal/Observable' {
  interface Observable<T> {
    modelExists: ModelExists<T>;
  }
}
Observable.prototype.modelExists = function() {
  return this.pipe(filter((obj:any) => !!(obj && obj.id)));
};
