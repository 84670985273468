import {map, mergeMap, share} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {getMessageFromException} from '../utilities/Util';
import {
  Company,
  CompanyClient,
  KcApplication,
  KcApplicationClient,
  KcApplicationResult,
  KcNote, KcNoteResult,
  KcProgramLevel, KcStatus,
  PagedListOfKcApplication
} from './api.client';
import {NotificationService} from './NotificationService';
import {SystemService} from './SystemService';
import {Observable, of} from 'rxjs';
import {Store} from '@ngrx/store';
import * as fromRoot from '../store';
import {
  clearCurrentSelectedKcApplicationResult,
  setCurrentClientsApplications,
  setCurrentSelectedKcApplicationResult,
  setCurrentSelectedKcApplications,
  setKcAmerenCompany, setKcApplications, setKcProgramDetail
} from '../store/actions/kcApplication.actions';

@Injectable()
export class KcApplicationService {

  constructor(public _kcApplicationApiService: KcApplicationClient,
              public _notificationService: NotificationService,
              public _companyApiService: CompanyClient,
              public systemService: SystemService,
              private _store: Store<fromRoot.AppState>) {
  }

  getApplications(searchValueAccount: string,
                  searchValueSSN: string,
                  agencyId?: string,
                  statusId?: string,
                  sortName?: string,
                  descending?: boolean,
                  limit?: number,
                  offset?: number): Observable<PagedListOfKcApplication> {
    const request = this._kcApplicationApiService.retrieveKcApplicationsPaged(
      searchValueAccount,
      searchValueSSN,
      agencyId,
      statusId,
      limit,
      offset,
      sortName,
      null,
      descending
    ).pipe(share());
    request.subscribe({ next: (result) => {
      this.setKcApplicationsAction(result);
    }, error: () => {
      this._notificationService.showError('Error Loading Applications');
    }, complete: () => {
    }});
    return request;
  }

  addKcApplication(kcApplication: KcApplication): Observable<KcApplication> {
    const request = this._kcApplicationApiService.addKcApplication(kcApplication).pipe(share());
    request.subscribe({ next: (result) => {
      this.setCurrentSelectedKcApplicationAction(result);
      this._notificationService.showSuccess('Keeping Current Application Successfully Created!');
    }, error: (err) => {
        this._notificationService.showError(getMessageFromException(err));
    }, complete: () => {
    }});
    return request;
  }

  updateKcApplication(kcApplication: KcApplication): Observable<KcApplication> {
    const request = this._kcApplicationApiService.updateKcApplication(kcApplication).pipe(share());
    request.subscribe({ next: (result) => {
      this.setCurrentSelectedKcApplicationAction(result);
      this._notificationService.showSuccess('Keeping Current Application Successfully Updated!');
    }, error: (err) => {
        this._notificationService.showError(getMessageFromException(err));
    }, complete: () => {
    }});
    return request;
  }

  createApplicationEnrollment(application: KcApplication): Observable<KcApplication> {
    const request = this._kcApplicationApiService.createApplicationEnrollment(application).pipe(share());
    request.subscribe({ next: () => {
      this.setCurrentKcApplicationResult(null);
      this._notificationService.showSuccess('Created Application Enrollment - Status Pending for Approval');
    }, error: (err) => {
        this._notificationService.showError(getMessageFromException(err));
    }, complete: () => {
    }});
    return request;
  }

  deleteKcApplication(application: KcApplication): any {
    const request = this.systemService
      .kcStatusDelete$
        .pipe(
          mergeMap((kcStatus: KcStatus) => {
            const kcApplication = KcApplication.fromJS({ ...application, keepingCurrentStatusId: kcStatus.id });
            return this._kcApplicationApiService.updateKcApplication(kcApplication);
          }))
      .pipe(map(() => application), share());
    request.subscribe(() => {
      this.setCurrentKcApplicationResult(null);
      this._notificationService.showSuccess('Successfully Deleted Application');
    }, (err) => {
        this._notificationService.showError(getMessageFromException(err));
    }, () => {
    });
    return request;
  }

  // updateKcApplication (kcApplication: KcApplication) {
  //     let request = this._kcApplicationApiService.updateKcApplication(kcApplication).share()
  //     request.subscribe((result) => {
  //         this.setCurrentSelectedKcApplication(result);
  //         this._notificationService.showSuccess("Keeping Current Application Succesfully Updated!");
  //     }, (err) => {
  //         this._notificationService.showError("There was a problem updating this Keeping Current Application.");
  //     }, () => {
  //
  //     })
  //     return request;
  // }
  getKcApplicationsByClientId(id: string): Observable<KcApplication[]> {
    const request = this._kcApplicationApiService.retrieveKcApplicationsByClientId(id).pipe(share());
    request.subscribe({ next: (result) => {
      this.setCurrentClientKcApplications(result);
    }, error: () => {
      this._notificationService.showError(
        'There was a problem retrieving this client\'s Keeping Current Applications.');
    }, complete: () => {
    }});
    return request;
  }

  // getIncompleteOrApprovedApplicationByClientId (clientId: string) {
  //     let request = this._kcApplicationApiService.getIncompleteOrApprovedApplication(clientId).share();
  //     request.subscribe((result) => {
  //
  //     }, (err) => {
  //         this._notificationService.showError("There was a problem retrieving this client's Keeping Current Application.");
  //     }, () => {
  //
  //     });
  //     return request;
  // }
  getIncompleteOrApprovedApplicationByClientId(clientId: string): Observable<KcApplication> {
    const request = this._kcApplicationApiService.getIncompleteOrApprovedApplication(clientId).pipe(
      // base api apparently returns an object on a 204 status.
      // fixing this issue globally might break other code.
      // In this case we need to differentiate between 204 and 200 status
      map((obj) => (obj && obj.id) ? obj : null),
      share(), );
    request.subscribe({ next: (result) => {
      this.setCurrentSelectedKcApplicationAction(result);
    }, error: () => {
      this._notificationService.showError(
        'There was a problem retrieving the selected Keeping Current Application.');
    }, complete: () => {
    }});
    return request;
  }

  getKcApplicationById(id: string): Observable<KcApplicationResult> {
    const request = this._kcApplicationApiService.retrieveKcApplication(id).pipe(share());
    request.subscribe({ next: (result) => {
      this.setCurrentSelectedKcApplicationAction(result);
      this.setCurrentKcApplicationResult(result);
    }, error: () => {
      this._notificationService.showError(
        'There was a problem retrieving the selected Keeping Current Application.');
    }, complete: () => {
    }});
    return request;
  }

  getKcNotes(applicationId: string): Observable<KcNoteResult[]> {
    const request = this._kcApplicationApiService.getKeepingCurrentNotes(applicationId).pipe(share());
    request.subscribe({ next: () => {
    }, error: () => {
    }, complete: () => {
    }});
    return request;
  }

  addKcNote(note: KcNote): Observable<KcNote> {
    const request = this._kcApplicationApiService.addKcNote(note).pipe(share());
    request.subscribe({ next: () => {
      this._notificationService.showSuccess('Successfully added a note!');
    }, error: () => {
    }, complete: () => {
    }});
    return request;
  }

  deleteKcNote(id: string): Observable<void> {
    const request = this._kcApplicationApiService.deleteNoteById(id).pipe(share());
    request.subscribe({ next: () => {
      this._notificationService.showSuccess('Successfully deleted a note!');
    }, error: () => {
    }, complete: () => {
    }});
    return request;
  }

  getProgramDetails(programId: string, federalPovertyLevel: number): Observable<KcProgramLevel> {
    const request = this._kcApplicationApiService.getProgramDetails(programId, federalPovertyLevel).pipe(share());
    request.subscribe({ next: (result) => {
      this.setProgramDetail(result);
    }, error: (err) => {
      if (typeof err === 'string') {
        this._notificationService.showError(err);
      } else {
        this._notificationService.showError('There was an error getting the relevant Program Details');
      }
    }, complete: () => {
    }});
    return request;
  }

  setProgramDetail(programDetail: KcProgramLevel): void {
    this._store.dispatch(setKcProgramDetail({ payload: programDetail}));
  }

  getKcAmerenCompany(): Observable<Company> {
    // TODO: use Ameren GUID
    const request = this._companyApiService.retrieveCompaniesPaged('Ameren Missouri', null).pipe(
      map((result) => result.items[0]),
      share(), );
    request.subscribe({ next: (company) => {
      this.setKcAmerenCompany(company);
    }, error: () => {
    }, complete: () => {
    }});
    return request;
  }

  setKcAmerenCompany(company: Company): void {
    this._store.dispatch(setKcAmerenCompany({ payload: company }));
  }

  // setCurrentSelectedKcApplication(kcApplication: KcApplication) {
  //     this.setCurrentSelectedKcApplicationAction(kcApplication);
  // }

  setCurrentKcApplicationResult(kcApplicationResult: KcApplicationResult): void {
    this._store.dispatch(setCurrentSelectedKcApplicationResult({ payload: kcApplicationResult }));
  }

  setCurrentClientKcApplications(kcApplications: KcApplication[]): void {
    this._store.dispatch(setCurrentClientsApplications({ payload: kcApplications}));
  }

  // getCurrentSelectedKcApplication() {
  //   return this._store.getState().kcApplicationData.currentSelectedKcApplication;
  // }

  setCurrentSelectedKcApplicationAction(selectedKcApplication: KcApplication): void {
    this._store.dispatch(setCurrentSelectedKcApplications({ payload: selectedKcApplication }));
  }

  setKcApplicationsAction(kcApplications: PagedListOfKcApplication): void {
    this._store.dispatch(setKcApplications({ payload: kcApplications}));
  }

  clearCurrentKcApplicationResultAction(kcApplicationResult: KcApplicationResult): void {
    this._store.dispatch(clearCurrentSelectedKcApplicationResult({ payload: kcApplicationResult }));
  }
}
