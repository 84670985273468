import {
    AddAssistanceApplicationStateAuditViewModel,
    AssistanceApplicationStateAuditClient,
    AssistanceApplicationStateAuditViewModel,
    PagedListOfAssistanceApplicationStateAuditListViewModel,
} from './api.client';
import {NotificationService} from './NotificationService';
import {inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {share} from 'rxjs/operators';
import {getMessageFromException} from '../utilities/Util';

@Injectable()
export class AssistanceApplicationStateAuditService {

    private _notificationService = inject(NotificationService);
    private _assistanceApplicationStateAuditClient = inject(AssistanceApplicationStateAuditClient);

    addAssistanceApplicationStateAudit(assistanceApplicationStateAudit: AddAssistanceApplicationStateAuditViewModel):
        Observable<AssistanceApplicationStateAuditViewModel> {
        const request = this._assistanceApplicationStateAuditClient
            .addAssistanceApplicationStateAudit(assistanceApplicationStateAudit).pipe(share());
        this._notificationService.showSuccess('Attempting to update Assistance Application Audit Status.');
        request.subscribe({
            next: (result) => {
                this._notificationService.showSuccess('Assistance Application Audit Status Successfully Updated!');
            }, error: (err) => {
                this._notificationService.showError(getMessageFromException(err));
            }
        });
        return request;
    }

    retrieveCaseCurrentState(assistanceApplicationId: string): Observable<AssistanceApplicationStateAuditViewModel> {
        return this._assistanceApplicationStateAuditClient
            .retrieveCaseCurrentState(assistanceApplicationId).pipe(share());
    }

    retrievePagedAssistanceApplicationAudits(
        searchValue: string,
        sortName: string,
        descending: boolean,
        limit: number,
        offset: number,
    ): Observable<PagedListOfAssistanceApplicationStateAuditListViewModel> {
        return this._assistanceApplicationStateAuditClient.retrievePagedAssistanceApplicationAudits(
            searchValue,
            sortName,
            null,
            descending,
            limit || 10,
            offset || 0
        ).pipe(share());
    }
}
