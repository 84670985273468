import {Component, OnInit, ViewChild} from '@angular/core';
import {Client, ClientClient} from '../../../../services/api.client';
import {SecurityService} from '../../../../services/SecurityService';
import {ClientService} from '../../../../services/ClientService';
import {getMessageFromException} from '../../../../utilities/Util';
import {PromptComponent} from '../../../shared/dialogs/PromptComponent';
import {UserType} from '../../../../services/api.client';
import {User} from '../../../../services/api.client';
import {NotificationService} from '../../../../services/NotificationService';
import {PledgeService} from '../../../../services/PledgeService';
import {ClientSsnRevealComponent} from '../../../shared/clientSsnReveal/ClientSsnRevealComponent';
import {Observable} from 'rxjs';
import {select, Store} from '@ngrx/store';
import * as fromRoot from '../../../../store';
import {selectClientDataCurrentClient} from '../../../../store/selectors/client.selectors';
import {selectSecurityStateDataCurrentUser} from '../../../../store/selectors/security.selectors';

@Component({
    selector: 'clientComponent',
    templateUrl: './ClientComponent.html',
    styleUrls: ['./ClientComponent.scss']
})

export class ClientComponent implements OnInit {

    constructor(
        private _securityService: SecurityService,
        private _clientService: ClientService,
        private _pledgeService: PledgeService,
        private _notificationService: NotificationService,
        private _store: Store<fromRoot.AppState>) {
        this.currentUser$ = this._store.pipe(select(selectSecurityStateDataCurrentUser));
        this.currentClient$ = this._store.pipe(select(selectClientDataCurrentClient));
    }

    userTypes = UserType;
    currentClient: Client;
    currentClient$: Observable<Client>;
    currentUser: User;
    currentUser$: Observable<User>;

    @ViewChild(PromptComponent)
    prompt: PromptComponent;

    @ViewChild(ClientSsnRevealComponent)
    revealSsn: ClientSsnRevealComponent;

    mergeClient: Client;

    employmentMapping = [
        {label: 'Unemployed', value: 'Unemployed'},
        {label: 'Part time employment', value: 'Part Time Employment'},
        {label: 'Self-employed', value: 'Self-Employed'},
        {label: 'Unemployed – not receiving benefits', value: 'Unemployed No Benefits'},
        {label: 'Active Military', value: 'Active Military'},
        {label: 'Retired', value: 'Retired'},
        {label: 'Student', value: 'Student'},
        {label: 'Unemployed – receiving benefits', value: 'Unemployed With Benefits'},
        {label: 'Employed', value: 'Employed'},
        {label: 'Full time employment', value: 'Full Time Employment'}
    ];
    raceMapping = [
        {label: 'South Asian', value: 'South Asian'},
        {label: 'White/European American', value: 'White'},
        {label: 'American Indian/Native American', value: 'American Indian'},
        {label: 'Bi-racial/Multi-Racial', value: 'BiRacial'},
        {label: 'Other', value: 'Other'},
        {label: 'Unknown', value: 'Unknown'},
        {label: 'Black/African American', value: 'Black'},
        {label: 'Middle Eastern', value: 'Middle Eastern'},
        {label: 'Pacific Islander', value: 'Pacific Islander'},
        {label: 'Asian/Asian American', value: 'Asian American'},
        {label: 'Hispanic/Latino(a)', value: 'Hispanic'},
        {label: 'Jewish', value: 'Jewish'}
    ];

    genderMapping = [
        {label: 'Non-binary', value: 'Non Binary'},
        {label: 'Prefer to self-describe', value: 'Self Describe'},
        {label: 'Unknown', value: 'Unknown'},
        {label: 'Female', value: 'Female'},
        {label: 'Prefer not to disclose', value: 'Not Disclose'},
        {label: 'Male', value: 'Male'}
    ];

    ngOnInit(): void {
        this.currentUser$.subscribe(user => this.currentUser = user);
        this.currentClient$.subscribe(currentClient => this.currentClient = currentClient);
    }

    isClientVerified(): boolean {
        return this._clientService.isClientVerified;
    }

    setVerifyClientId(): void {
        return this._securityService.setVerifyClientId(this.currentClient.id);
    }

    public hasAValue(income: number|undefined|null): boolean {
        return income !== null && income !== undefined && income >= 0;
    }

    mergeClients(): void {
        const src = this.mergeClient.id;
        const dest = this.currentClient.id;
        this._clientService.mergeClient(src, dest)
            .subscribe({
                next: (client) => {
                    this.prompt.close();
                    this._notificationService.showSuccess('Successfully merged client!');
                    this._pledgeService.getCurrenClientPledgeList(client.id);
                }, error: (error) => {
                    this._notificationService.showError(getMessageFromException(error));
                }
            });
    }

    cancelMerge(): void {
        this.prompt.close();
        this.mergeClient = null;
    }

    getEmploymentLabel(value: string): string {
        return this.employmentMapping.find(x => x.value === value).label;
    }

    getGenderLabel(value: string): string {
        return this.genderMapping.find(x => x.value === value).label;
    }

    getRaceLabel(value: string): string {
        return this.raceMapping.find(x => x.value === value).label;
    }
}
