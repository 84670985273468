import {share, map, mergeMap, tap, take} from 'rxjs/operators';
import {Component, ViewChild, AfterViewInit, OnInit} from '@angular/core';
import {PledgeTypeBalanceClient, User, UserType} from '../../../services/api.client';
import {PledgeTypeBalanceParameters} from '../../../services/api.client';
import {Observable} from 'rxjs';
import {PledgeType} from '../../../services/api.client';
import {TableComponent} from '../../shared/table/TableComponent';
import {EfnCase} from '../../../services/api.client';
import {EfnService} from '../../../services/EfnService';
import {ClientService} from '../../../services/ClientService';
import {select, Store} from '@ngrx/store';
import * as fromRoot from '../../../store';
import {selectEfnDataFundedEfnCase, selectEfnDataOpenEfnCase} from '../../../store/selectors/efn.selectors';
import {
  selectSecurityStateDataCurrentUser,
  selectSecurityStateDataSecurityDataPledgeTypes
} from '../../../store/selectors/security.selectors';

@Component({
  templateUrl: './DashboardComponent.html',
  styleUrls: ['./DashboardComponent.scss'],
})
export class DashboardComponent implements OnInit, AfterViewInit {
  userTypes = UserType;
  currentUser: User;
  currentUser$: Observable<User>;

  pledgeTypes: PledgeType[];
  pledgeTypes$: Observable<PledgeType[]>;
  pledgeTypeBalances: { pledgeType: PledgeType, balance: number }[];
  openCases: EfnCase[];
  openCases$: Observable<EfnCase[]>;
  fundedCases: EfnCase[];
  fundedCases$: Observable<EfnCase[]>;

  @ViewChild('fundBalanceTable')
  fundBalanceTable: TableComponent;

  @ViewChild('openEfnCasesTable')
  openEfnCasesTable: TableComponent;

  @ViewChild('fundedEfnCasesTable')
  fundedEfnCasesTable: TableComponent;

  constructor(private pledgeTypeBalanceService: PledgeTypeBalanceClient,
              private efnService: EfnService,
              private clientService: ClientService,
              private _store: Store<fromRoot.AppState>) {
    this.currentUser$ = this._store.pipe(select(selectSecurityStateDataCurrentUser));
    this.pledgeTypes$ = this._store.pipe(select(selectSecurityStateDataSecurityDataPledgeTypes));
    this.openCases$ = this._store.pipe(select(selectEfnDataOpenEfnCase));
    this.fundedCases$ = this._store.pipe(select(selectEfnDataFundedEfnCase));
  }

  ngOnInit(): void {
    this.currentUser$.subscribe(user =>  this.currentUser = user);
    this.pledgeTypes$.subscribe(pledgeTypes => this.pledgeTypes = pledgeTypes);
    this.openCases$.subscribe(openCases => this.openCases = openCases);
    this.fundedCases$.subscribe(fundedCases => this.fundedCases = fundedCases);
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      if (this.currentUser.userType !== UserType.FundAdmin) {

        this.getPledgeTypeBalances();

        // if (this.currentUser.hasEfn) {
        this.getOpenEfnCases();
        this.getFundedEfnCases();
        // }
      }

    });
  }

  getPledgeTypeBalances(): void {
    const pledgeTypeIds = this.pledgeTypes.filter(x => x.isActive).map(x => x.id);
    const request = this.currentUser$.pipe(
      mergeMap((user) => this.pledgeTypeBalanceService.retrieveBalances(
        new PledgeTypeBalanceParameters({
          agencyId: user.agencyId,
          pledgeTypeIds
        })
      )),
      map((pledgeTypeBalances) => pledgeTypeBalances.map((pb) => ({
        pledgeType: this.pledgeTypes.find(p => p.id === pb.pledgeTypeId),
        balance: pb.balance
      }))), share(), );
    request.subscribe((pledgeTypeBalances) => this.pledgeTypeBalances = pledgeTypeBalances);
    this.fundBalanceTable.request = request;
  }

  getOpenEfnCases(): void {
    this.efnService.getOpenEfnCasesByAgencyId(this.currentUser.agencyId);
  }

  getFundedEfnCases(): void {
    this.efnService.getFundedEfnCasesByAgencyId(this.currentUser.agencyId);
  }

  fetchClientById(id: string): void {
    this.clientService.getClientById(id);
  }

  fetchEfnCase(id: string): void {
    this.efnService.getEfnCaseById(id);
  }
}
