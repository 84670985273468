import {AfterViewInit, Component, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {ITableHeader, TableComponent} from '../../shared/table/TableComponent';
import {
    Agency,
    AssistanceApplicationStatus,
    PagedListOfAssistanceApplicationListPreview,
    PublicInternalFilter
} from '../../../services/api.client';
import {AgencyListModalComponent} from '../agency/AgencyListModalComponent';
import {UserType} from '../../../services/api.client';
import {User} from '../../../services/api.client';
import {extractEnumNames, splitCamelCase} from '../../../utilities/Util';
import {UntypedFormControl} from '@angular/forms';
import {combineLatest, Observable, of} from 'rxjs';
import {select, Store} from '@ngrx/store';
import * as fromRoot from '../../../store';
import {selectSecurityStateDataCurrentUser} from '../../../store/selectors/security.selectors';
import {AssistanceApplicationService} from '../../public/application/assistance-application.service';
import {
    selectAssistanceApplicationDataAssistanceApplicationsPaged,
    selectAssistanceApplicationDataAssistanceApplicationsPagedTotalCount
} from '../../../store/selectors/assistanceApplication.selectors';
import {AgencyService} from '../../../services/AgencyService';
import {NotificationService} from '../../../services/NotificationService';
import {catchError, switchMap, tap} from 'rxjs/operators';

@Component({
    templateUrl: './AssistanceApplicationsListComponent.html',
    styleUrls: ['./AssistanceApplicationsListComponent.scss']
})

export class AssistanceApplicationsListComponent implements OnInit, AfterViewInit {

    constructor(
        private assistanceApplicationService: AssistanceApplicationService,
        private _agencyService: AgencyService,
        private _store: Store<fromRoot.AppState>,
        private _notificationService: NotificationService) {
        this.currentUser$ = this._store.pipe(select(selectSecurityStateDataCurrentUser));
        this.assistanceApplicationList$ = this._store.pipe(select(selectAssistanceApplicationDataAssistanceApplicationsPaged));
        this.totalCount$ = this._store.pipe(select(selectAssistanceApplicationDataAssistanceApplicationsPagedTotalCount));
    }

    statuses = extractEnumNames(AssistanceApplicationStatus);
    publicOrPrivate = extractEnumNames(PublicInternalFilter);
    selectedAgencyId: string;
    searchValue: string;
    selectedManagementAgencyId = new UntypedFormControl('');
    appStatus = new UntypedFormControl('');
    appPublicPrivate = new UntypedFormControl('');

    @ViewChild(TableComponent)
    table: TableComponent;

    currentUser: User;
    currentUser$: Observable<User>;

    assistanceApplicationList: PagedListOfAssistanceApplicationListPreview;
    assistanceApplicationList$: Observable<PagedListOfAssistanceApplicationListPreview>;

    regionAdminAgencies: Agency[];

    // sorting
    currentSortName = 'EnteredDate';
    currentSorting = true;

    tableHeaders: ITableHeader[] = [
        {text: 'Fund', sortKey: 'Fund|FundName'},
        {text: 'Last Name', sortKey: 'Client|LastName'},
        {text: 'First Name', sortKey: 'Client|FirstName'},
        {text: 'SSN', sortKey: 'Client|Last4Ssn'},
        {text: 'Created', sortKey: 'EnteredDate', class: 'hidden-sm-down', ascending: false},
        {text: 'Status', sortKey: 'Status'},
        {text: 'Agency', sortKey: 'CreatedByAgency|AgencyName'},
        {text: 'Total Need', sortKey: 'TotalNeed'},
    ];

    // pagination
    limit = 10;
    currentPage = 1;
    maxSize = 1;
    totalCount: number;
    totalCount$: Observable<number>;

    // MODAL
    @ViewChild('agencyListModal')
    agencyListModal: AgencyListModalComponent;
    @Output('onShowAgencyModal') _showAgencies = new EventEmitter();

    // selected agency
    agency: Agency;
    selectedAgencyName = '-- Select Agency --';

    ngOnInit(): void {
        this.currentUser$.pipe(
            tap(user => {
                this.currentUser = user;
                if (!this.userHasAccessToAllAgencyApplications(user)) {
                    this.selectedAgencyId = user.agencyId;
                    this.selectedAgencyName = user.agency.agencyName;
                }
            }),
            switchMap(() => this._agencyService.getRegionAdminAgencies()),
            catchError(error => {
                this._notificationService.showError('There was a problem loading the region admin agencies.');
                return of([]); // Return an empty array or appropriate default value on error.
            })
        ).subscribe(agencies => {
            this.regionAdminAgencies = agencies;
        });

        combineLatest([
            this.assistanceApplicationList$,
            this.totalCount$
        ]).subscribe(([appList, totalCount]) => {
            this.assistanceApplicationList = appList;
            this.totalCount = totalCount;
        });
    }

    get isAdmin(): boolean {
        return this.currentUser.userType === UserType.SystemAdmin;
    }

    setSearchValue(value: string): void {
        this.searchValue = value;
    }

    sortCases(options: ITableHeader): void {
        this.currentSortName = options.sortKey;
        this.currentSorting = !options.ascending;
        this.searchAssistanceApplications();
    }

    setLimit(limit: number): void {// # of rows per page
        this.limit = limit;
        this.searchAssistanceApplications();
    }

    setPage(page: number): void {
        setTimeout(() => {
            this.currentPage = page; // set current page
            this.searchAssistanceApplications();
        });
    }

    searchAssistanceApplications(): void {
        this.table.request = this.assistanceApplicationService.getAssistanceApplicationsPaged(
            this.searchValue,
            this.selectedAgencyId,
            this.selectedManagementAgencyId.value,
            this.appStatus.value,
            this.appPublicPrivate.value,
            this.currentSortName,
            this.limit,
            (this.currentPage - 1) * this.limit,
            this.currentSorting
        );
    }

    clearFilters(): void {
        this.searchValue = '';
        this.selectedAgencyId = '';
        this.selectedAgencyName = '-- Select Agency --';
        this.selectedManagementAgencyId.setValue('');
        this.appStatus.setValue('');
        this.appPublicPrivate.setValue('');

        if (!this.userHasAccessToAllAgencyApplications(this.currentUser)) {
            this.selectedAgencyId = this.currentUser.agencyId;
            this.selectedAgencyName = this.currentUser.agency.agencyName;
        }

        this.searchAssistanceApplications();
    }

    showAgencyModal(): boolean {
        this.agencyListModal.open();
        return false;
    }

    onAgencySelected(selectedAgency): void {
        if (selectedAgency) {
            selectedAgency.agencyName ? this.selectedAgencyName = selectedAgency.agencyName : this.selectedAgencyName = '';
            this.selectedAgencyId = selectedAgency.id;
        } else {
            this.selectedAgencyName = '';
            this.selectedAgencyId = '';
        }
    }

    isDisabledAgency(): boolean {
        return !this.userHasAccessToAllAgencyApplications(this.currentUser);
    }


    private userHasAccessToAllAgencyApplications(user: User): boolean {
        const userIsSystemAdmin = user.userType === UserType.SystemAdmin;
        const userIsFundAdmin = user.userType === UserType.FundAdmin;
        const userIsRegionAdmin = user.userType === UserType.RegionAdmin;

        return userIsSystemAdmin || userIsFundAdmin || userIsRegionAdmin;
    }

    formatStatus(status): string {
        if (!this.statuses) {
            return '';
        }
        let statusText = {key: '', value: -1};
        if (typeof status === 'number') {
            statusText = this.statuses.find(type => type.value === status);
        } else if (typeof status === 'string') {
            statusText = this.statuses.find(type => type.key.toLowerCase() === status.toLowerCase());
        }
        return splitCamelCase(statusText.key) || '';
    }

    ngAfterViewInit(): void {
        this.searchAssistanceApplications();
    }
}
