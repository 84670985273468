import {Component, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {Client} from '../../../services/api.client';
import {ClientService} from '../../../services/ClientService';
import {ClientListComponent} from './ClientListComponent';

/**
 * Created by djacobsmeyer on 1/5/17.
 */
@Component({
  templateUrl: './ClientsComponent.html',
  styleUrls: ['./ClientsComponent.scss'],
})

export class ClientsComponent {

  @ViewChild(ClientListComponent)
  clientList: ClientListComponent;

  constructor(private _clientService: ClientService,
              private _router: Router) {
  }

  selectClient(client: Client): void {
    this._clientService.setCurrentClient(client);
    this._router.navigate(['/app/client', client.id]).then();
  }
}
