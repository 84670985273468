import {Directive, ContentChild, HostBinding, ElementRef} from '@angular/core';
import {FormControlDirective} from '@angular/forms';


@Directive({
  selector: '[hasDanger]',
})
export class HasDangerDirective {

  @ContentChild(FormControlDirective)
  formControlDirective !: ElementRef;

  get formControl(): any {
    return this.formControlDirective && this.formControlDirective?.nativeElement?.control
        ? this.formControlDirective.nativeElement.control
        : null;
  }

  @HostBinding('class.has-error')
  get hasError(): boolean {
    return this.formControl ? this.formControl.touched && !this.formControl.valid : false;
  }

}
