<div class="row">
  <div class="col-sm-12 col-md-12 col-lg-10 offset-lg-1">
    <h4>Company Mailing Labels</h4>
    <p>
      The report below shows the mailing address details to generate company mailing labels.
      You can export this information to MS Excel, then mail-merge with MS Word to generate printable mailing labels.
    </p>
  </div>
  <div class="col-sm-12 col-md-12 col-lg-10 offset-lg-1">
    <form paymentProcessingForm #form
          [showDisplayPledgeStatus]="true"
          [showPledgeTypes]="true"
          [showCompany]="true" novalidate>
      <div footer class="text-right">
        <button class="btn btn-primary" (click)="form.submitCompanyMailingLabel()">Show Results</button>
        <button class="btn btn-primary" [disabled]="!(report$ | async)" (click)="form.downloadCompanyMailingLabel()">Export to Excel</button>
      </div>
    </form>
  </div>
  <div class="col-xs-12 m-b-1 m-t-1">
    <pagedReportTable [headers]="tableHeaders" (tableUpdate)="onTableUpdate($event)" [pagedList]="report$ | async"
                      [rows]="rows | async"></pagedReportTable>
  </div>
</div>
