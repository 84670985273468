<fieldset>
  <div class="row">
    <div class="col-md-12">
      <legend><strong>LIHEAP</strong> Status</legend>
      <div *ngIf="hasLiheapError" class="col-xs-12 text-danger form-control-feedback m-b-1">IMPORTANT: Please enroll
        this household for LIHEAP assistance, OR please send a referral letter to the LIHEAP agency that can serve this
        household. Also please select 'Yes' to indicate that you have done this, or that you will do this within the
        next 5 business days.
      </div>
      <div class="form-group row" [class.has-danger]="hasLiheapReceivedError">
        <label class="col-sm-8 control-label">Has this household received LIHEAP assistance in the current program
          year?</label>
        <div class="col-sm-4">
          <label class="custom-control custom-radio">
            <input [formControl]="isLiheapReceived" name="isLiheapReceived" type="radio" class="custom-control-input"
                   [value]="true">
            <span class="custom-control-indicator"></span>
            <span class="custom-control-description">Yes</span>
          </label>
          <label class="custom-control custom-radio">
            <input [formControl]="isLiheapReceived" (click)="showLiheapAlert()" name="isLiheapReceived" type="radio"
                   class="custom-control-input" [value]="false">
            <span class="custom-control-indicator"></span>
            <span class="custom-control-description">No</span>
          </label>
        </div>
      </div>
      <div class="form-group row" *ngIf="isLiheapReceived.value === false" [class.has-danger]="hasLiheapRequestedError">
        <label class="col-sm-8 control-label">Has a referral or application been completed for LIHEAP for this
          household, or will you complete this within the next 5 business days?</label>
        <div class="col-sm-4">
          <label class="custom-control custom-radio">
            <input [formControl]="isLiheapRequested" name="isLiheapRequested" type="radio" class="custom-control-input"
                   [value]="true">
            <span class="custom-control-indicator"></span>
            <span class="custom-control-description">Yes</span>
          </label>
          <label class="custom-control custom-radio">
            <input [formControl]="isLiheapRequested" (click)="showLiheapAlert()" name="isLiheapRequested" type="radio"
                   class="custom-control-input" [value]="false">
            <span class="custom-control-indicator"></span>
            <span class="custom-control-description">No</span>
          </label>
        </div>
      </div>
      <legend><strong>Weatherization</strong> Status</legend>
      <div *ngIf="hasWeatherizationError" class="col-xs-12 text-danger form-control-feedback m-b-1">IMPORTANT: Please
        refer this client to your DNR Weatherization department, OR please send a referral letter to the DNR agency that
        can serve this household. Also please select 'Yes' to indicate that you have done this, or that you will do this
        within the next 5 business days.
      </div>
      <div class="form-group row" [class.has-danger]="hasWeatherizationReceivedError">

        <label class="col-sm-8 control-label">Has DNR Weatherization been provided for the client's premise in the past
          16 years?</label>
        <div class="col-sm-4">
          <label class="custom-control custom-radio">
            <input [formControl]="isWeatherizationReceived" name="isWeatherizationReceived" type="radio"
                   class="custom-control-input" [value]="true">
            <span class="custom-control-indicator"></span>
            <span class="custom-control-description">Yes</span>
          </label>
          <label class="custom-control custom-radio">
            <input [formControl]="isWeatherizationReceived" (click)="showWeatherizationAlert()"
                   name="isWeatherizationReceived" type="radio" class="custom-control-input" [value]="false">
            <span class="custom-control-indicator"></span>
            <span class="custom-control-description">No</span>
          </label>
        </div>
      </div>
      <div class="form-group row" *ngIf="isWeatherizationReceived.value === false"
           [class.has-danger]="hasWeatherizationRequestedError">
        <label class="col-sm-8 control-label">Has a referral or application been completed for DNR Weatherization for
          the client's premises, or will you complete this within the next 5 business days?</label>
        <div class="col-sm-4">
          <label class="custom-control custom-radio">
            <input [formControl]="isWeatherizationRequested" name="isWeatherizationRequested" type="radio"
                   class="custom-control-input" [value]="true">
            <span class="custom-control-indicator"></span>
            <span class="custom-control-description">Yes</span>
          </label>
          <label class="custom-control custom-radio">
            <input [formControl]="isWeatherizationRequested" (click)="showWeatherizationAlert()"
                   name="isWeatherizationRequested" type="radio" class="custom-control-input" [value]="false">
            <span class="custom-control-indicator"></span>
            <span class="custom-control-description">No</span>
          </label>
        </div>
      </div>
      <div class="form-group row" *ngIf="isWeatherizationRequested.value">
        <div *ngIf="!weatherizationStatus.valid && weatherizationStatus.touched"
             class="col-xs-12 text-danger form-control-feedback m-b-1">Please select the current Weatherization Status
          for this customer from the dropdown list options provided.
        </div>
        <label class="col-sm-8 control-label">What is the status of the DNR Weatherization application?</label>
        <div class="col-sm-4">
          <select [formControl]="weatherizationStatus" class="form-control">
            <option [value]="null"></option>
            <option *ngFor="let status of weatherizationStatuses"
                    [value]="status.value">{{ status.key | splitCamelCase }}</option>
          </select>
        </div>
      </div>
      <legend><strong>Ameren Customer</strong> Agreement</legend>
      <div class="form-group row" [class.has-danger]="!isThirdPartyAgreeable.valid && isThirdPartyAgreeable.touched">
        <div *ngIf="!isThirdPartyAgreeable.valid && isThirdPartyAgreeable.touched"
             class="col-xs-12 form-control-feedback m-b-1">The customer must agree to share their information with
          non-Ameren parties in order to enroll for Keeping Current. Please discuss this matter with the customer and if
          they agree to share their Ameren account information then please select the check box to indicate that the
          customer has agreed to share their Ameren information.
        </div>
        <label class="col-sm-8 control-label">
          The Ameren Missouri customer for account: {{ accountNumber?.value }} has agreed that their LIHEAP and Ameren
          account information may be shared with non-Ameren parties studying the Keeping Current pilot program
        </label>
        <div class="col-sm-4">
          <label class="custom-control custom-checkbox">
            <input type="checkbox" class="custom-control-input" [formControl]="isThirdPartyAgreeable"
                   name="isThirdPartyAgreeable" [value]="true">
            <span class="custom-control-indicator"></span>
            <span class="custom-control-description">Yes, the customer has agreed.</span>
          </label>
        </div>
      </div>
      <importantCustomerInformation></importantCustomerInformation>
      <div class="row">
        <div class="col-xs-12">
          <ng-content selector="[footer]"></ng-content>
        </div>
      </div>
    </div>
  </div>
</fieldset>
