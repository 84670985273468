import {filter} from 'rxjs/operators';
import {Component, Output, EventEmitter, ViewChild, OnInit, AfterViewInit} from '@angular/core';
import {Observable} from 'rxjs';
import {Agency, PagedListOfPledge, Pledge, PledgeType, SystemData, User, UserType} from '../../../services/api.client';
import {ITableHeader, TableComponent} from '../../shared/table/TableComponent';
import {PledgeService} from '../../../services/PledgeService';
import {PledgeTypeService} from '../../../services/PledgeTypeService';
import {ClientService} from '../../../services/ClientService';
import {DropdownValue} from '../../../utilities/DropdownComponent';
import {AgencyListModalComponent} from '../agency/AgencyListModalComponent';
import {UntypedFormControl} from '@angular/forms';
import {SystemService} from '../../../services/SystemService';
import {select, Store} from '@ngrx/store';
import * as fromRoot from '../../../store';
import {selectAgencyDataAgenciesItems} from '../../../store/selectors/agency.selectors';
import {selectPledgeDataPledges, selectPledgeDataPledgesTotalCount} from '../../../store/selectors/pledge.selectors';
import {selectPledgeTypeDataPledgeTypesItems} from '../../../store/selectors/pledgeType.selectors';
import {selectSecurityStateDataCurrentUser} from '../../../store/selectors/security.selectors';

@Component({
    templateUrl: './PledgeListComponent.html',
    styleUrls: ['./PledgeListComponent.scss'],
})

export class PledgeListComponent implements OnInit, AfterViewInit {

    constructor(public pledgeService: PledgeService,
                private _pledgeTypeService: PledgeTypeService,
                private _clientService: ClientService,
                private _store: Store<fromRoot.AppState>) {
        this.currentUser$ = this._store.pipe(select(selectSecurityStateDataCurrentUser));
        this.pagedPledges$ = this._store.select(selectPledgeDataPledges);
        this.pledgeTypes$ = this._store.pipe(select(selectPledgeTypeDataPledgeTypesItems));
        this.agencies$ = this._store.pipe(select(selectAgencyDataAgenciesItems));
        this.totalCount$ = this._store.pipe(select(selectPledgeDataPledgesTotalCount));
    }

    isLoading: boolean;
    systemData: SystemData;
    selectedPledgeTypeId = new UntypedFormControl('');
    selectedAgencyId: string;
    searchValue = '';
    tableHeaders: ITableHeader[] = [];

    @ViewChild(TableComponent)
    table: TableComponent;

    // subscription
    currentUser: User;
    currentUser$: Observable<User>;
    pagedPledges: PagedListOfPledge;
    pagedPledges$: Observable<PagedListOfPledge>;
    pledgeTypes: PledgeType[];
    pledgeTypes$: Observable<PledgeType[]>;
    agencies: Agency[];
    agencies$: Observable<Agency[]>;

    // sorting
    currentSortName = 'PledgedDate';
    currentSorting = true;

    // pagination
    limit = 10;
    currentPage = 1;
    maxSize = 1;
    totalCount: number;
    totalCount$: Observable<number>;

    // Dropdown
    pledgeTypeDropdown: DropdownValue[];

    // MODAL
    @ViewChild(AgencyListModalComponent)
    agencyListModal: AgencyListModalComponent;
    @Output('onShowAgencyModal') _showAgencies = new EventEmitter();
    private agencyList: AgencyListModalComponent;

    // selected agency
    agency: Agency;
    selectedAgencyName = '-- Select Agency --';

    ngOnInit(): void {
        this.currentUser$.subscribe(user => this.currentUser = user);
        this.pagedPledges$.subscribe(pagedPledges => this.pagedPledges = pagedPledges);
        this.pledgeTypes$.subscribe(pledgeTypes => this.pledgeTypes = pledgeTypes);
        this.agencies$.subscribe(agencies => this.agencies = agencies);
        this.totalCount$.subscribe(totalCount => this.totalCount = totalCount);
        this._pledgeTypeService.getPledgeTypes('', '', false, 100, 0);
        this.pledgeTypes$.pipe(
            filter(pledgeTypes => !!pledgeTypes))
            .subscribe((pledgeTypes) => {
                this.pledgeTypeDropdown = [];
                if (this.currentUser && this.currentUser.userType === UserType.SystemAdmin) {
                    for (const pledgeType of pledgeTypes) {
                        this.pledgeTypeDropdown.push(new DropdownValue(pledgeType.id, pledgeType.name));
                    }
                } else {
                    for (const pledgeType of pledgeTypes) {
                        if (this.currentUser.agency) {
                            if (this.currentUser.agency.pledgeTypeIds.some(x => x === pledgeType.id)) {
                                this.pledgeTypeDropdown.push(new DropdownValue(pledgeType.id, pledgeType.name));
                            }
                        }
                    }
                }
            });

        if (this.currentUser.userType !== UserType.SystemAdmin &&
            this.currentUser.userType !== UserType.FundAdmin &&
            this.currentUser.userType !== UserType.RegionAdmin) {// NOT SYS ADMIN
            this.selectedAgencyId = this.currentUser.agencyId;
            this.selectedAgencyName = this.currentUser.agency.agencyName;
        }

        if (this.currentUser.hasPrivate) { // the same - no need for Is Private column
            this.tableHeaders = [
                {text: 'Last Name', sortKey: 'ClientLastName'},
                {text: 'First Name', sortKey: 'ClientFirstName'},
                {text: 'SSN.', sortKey: 'ClientLast4Ssn', class: 'hidden-sm-down'},
                {text: 'Date', sortKey: 'PledgedDate', class: 'hidden-sm-down', ascending: true},
                {text: 'Status', sortKey: 'Status', class: 'hidden-sm-down'},
                {text: 'Type', sortKey: 'PledgeType|Name', class: 'hidden-sm-down'},
                {text: 'Agency', sortKey: 'Agency|AgencyName', class: 'hidden-sm-down'},
                {text: 'Pledge', sortKey: 'PledgedAmount'},
            ];
        } else {
            this.tableHeaders = [
                {text: 'Last Name', sortKey: 'ClientLastName'},
                {text: 'First Name', sortKey: 'ClientFirstName'},
                {text: 'SSN.', sortKey: 'ClientLast4Ssn', class: 'hidden-sm-down'},
                {text: 'Date', sortKey: 'PledgedDate', class: 'hidden-sm-down', ascending: true},
                {text: 'Status', sortKey: 'Status', class: 'hidden-sm-down'},
                {text: 'Type', sortKey: 'PledgeType|Name', class: 'hidden-sm-down'},
                {text: 'Agency', sortKey: 'Agency|AgencyName', class: 'hidden-sm-down'},
                {text: 'Pledge', sortKey: 'PledgedAmount'},
            ];
        }
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            this.searchPledges();
        });
    }

    // Current Selected Pledge
    setCurrentSelectedPledge(pledge: Pledge): void {
        this.pledgeService.setCurrentSelectedPledge(pledge);
    }

    // Current Selected Client
    setCurrentSelectedClient(clientId: string): void {
        this._clientService.getClientById(clientId);
    }

    sortPledges(options: ITableHeader): void {
        this.currentSortName = options.sortKey;
        this.currentSorting = !options.ascending;
        this.searchPledges();
    }

    setLimit(limit: number): void {// # of rows per page
        this.limit = limit;
        this.searchPledges();
    }

    setPage(page: number): void {
        setTimeout(() => {
            this.currentPage = page; // set current page
            this.searchPledges();
        });
    }

    // get Pledges
    searchPledges(): void {
        this.table.request = this.pledgeService.getPledges(
            this.searchValue,
            this.selectedAgencyId,
            this.selectedPledgeTypeId.value,
            this.currentSortName,
            this.currentSorting,
            this.limit,
            (this.currentPage - 1) * this.limit
        );
    }

    // search value
    setSearchValue(searchValue): void {
        this.searchValue = searchValue;
    }


    // format Pledge Type
    formatPledgeType(pledgeTypeId: string): string {
        if (this.pledgeTypes) {
            for (const pledgeType of this.pledgeTypes) {
                if (pledgeType.id === pledgeTypeId) {
                    return pledgeType.name;
                }
            }
            return 'missing pledge type';
        }
    }

    // show Agency Modal
    showAgencyModal(): boolean {
        this.agencyListModal.open();
        return false;
    }

    onAgencySelected(selectedAgency): void {
        if (selectedAgency) {
            selectedAgency.agencyName ? this.selectedAgencyName = selectedAgency.agencyName : this.selectedAgencyName = '';
            this.selectedAgencyId = selectedAgency.id;
        } else {
            this.selectedAgencyName = '';
            this.selectedAgencyId = '';
        }
    }

    isDisabledAgency(): boolean {
        if (!this.currentUser) {
            return false;
        }
        return this.currentUser.userType !== UserType.SystemAdmin && this.currentUser.userType !== UserType.RegionAdmin;
    }

    ngOnDestroy(): void {
        this._pledgeTypeService.setPledgeTypesAction(null);
        this._pledgeTypeService.setAllPledgeTypesAction(null);
    }
}
