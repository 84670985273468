<div class="form-wizard">
  <ul class="nav-justified mb-sm nav nav-pills">
    <!-- can't prevent default with routerLink, not sure why.... so we manually manage active link because we don't wont the links to be clickable -->
    <li class="nav-item">
      <a class="nav-link" [class.active]="currentStep === 1">
        <small>1.</small>
        Client Verification
    </a></li>
    <li class="nav-item">
      <a class="nav-link" [class.active]="currentStep === 2">
        <small>2.</small>
        Client Enrollment
    </a>
    </li>
    <li class="nav-item">
      <a class="nav-link" [class.active]="currentStep === 3">
        <small>3.</small>
        Enrollment Agreement
    </a>
    </li>
  </ul>
  <div class="bg-gray-lighter progress-bar mt-xs m-b-1">
    <progress class="progress progress-xs progress-bar-gray-light" value="100" max="100"
              [style.width]="percentage"></progress>
  </div>
  <router-outlet></router-outlet>
</div>
