import {Injectable} from '@angular/core';
import * as moment from 'moment';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {KcProgram, KcProgramLevel, KcStatus, Pledge, PledgeStatusType, User, UserType} from './api.client';
import {select, Store} from '@ngrx/store';
import * as fromRoot from '../store';
import {selectKcAppDataProgramDetail} from '../store/selectors/kcApplication.selectors';
import {
    selectSecurityStateDataCurrentUser, selectSecurityStateDataSecurityToken,
    selectSecurityStateDataSystemDataKcPrograms,
    selectSecurityStateDataSystemDataKcStatuses
} from '../store/selectors/security.selectors';
import {Disposable} from '../utilities/Disposable';

@Injectable()
export class SystemService extends Disposable {

    kcStatus$: Observable<KcStatus[]>;
    kcStatus: KcStatus[];
    kcPrograms: KcProgram[];
    kcPrograms$: Observable<KcProgram[]>;
    programDetail$: Observable<KcProgramLevel>;
    programDetail: KcProgramLevel;
    currentUser: User;
    currentUser$: Observable<User>;

    private securityToken: string;
    private securityToken$: Observable<string>;

    get kcStatusDelete$(): any {
        return this.kcStatus$.exists()
            .pipe(
                map(status =>
                    status.find(kcStatus => kcStatus.isDeletedStatus)));
    }

    get kcStatusNew$(): Observable<KcStatus> {
        return this.kcStatus$.exists().pipe(
            map(status =>
                status.find(kcStatus => kcStatus.isNewStatus)));
    }

    get kcStatusRejected$(): any {
        return this.kcStatus$.exists().pipe(
            map(status =>
                status.filter(kcStatus => kcStatus.isRejectedStatus)));
    }

    get kcStatusApproved$(): any {
        return this.kcStatus$.exists().pipe(
            map(status =>
                status.find(kcStatus => kcStatus.statusName === 'Approval Recorded')));
    }

    get kcStatusCancel$(): any {
        return this.kcStatus$.exists().pipe(
            map(status =>
                status.find(kcStatus => kcStatus.isCancelledStatus)));
    }

    get kcStatusPendingApproval(): KcStatus {
        return this.kcStatus.find(status => status.statusName === 'Approval Pending');
    }

    get kcStatusNew(): KcStatus {
        return this.kcStatus.find(status => status.isNewStatus);
    }

    public getKcStatusById$(id: string): Observable<KcStatus> {
        return this.kcStatus$.exists().pipe(
            map(status =>
                status.find(kcStatus => kcStatus.id === id)));
    }

    kcStatusIsIncomplete(status: KcStatus): boolean {
        if (!status) {
            return false;
        }
        return status.isNewStatus || status.isPendingApprovalStatus;
    }

    isKcStatusLocked(status: KcStatus): boolean {
        if (!status) {
            return false;
        }
        return status.isSystemSet || !this.kcStatusIsIncomplete(status);
    }

    getKcStatusNameById(statusId: string): string {
        if (!this.kcStatus) {
            return '';
        }
        return this.kcStatus.find((status) => status.id === statusId).statusName;
    }

    get isSystemAdmin(): boolean {
        return this.currentUser.userType === UserType.SystemAdmin;
    }

    get isAgencyAdmin(): boolean {
        return this.currentUser.userType === UserType.AgencyAdmin;
    }

    get isRegionAdmin(): boolean {
        return this.currentUser.userType === UserType.RegionAdmin;
    }

    get isFundAdmin(): boolean {
        return this.currentUser.userType === UserType.FundAdmin;
    }

    get isSystemOrFundAdmin(): boolean {
        return this.isSystemAdmin || this.isFundAdmin;
    }

    get isViewer(): boolean {
        return this.currentUser.userType === UserType.Viewer;
    }

    get isUser(): boolean {
        return this.currentUser.userType === UserType.User;
    }

    constructor(private _store: Store<fromRoot.AppState>) {
        super();
        this.currentUser$ = this._store.select(selectSecurityStateDataCurrentUser);
        this.kcStatus$ = this._store.pipe(select(selectSecurityStateDataSystemDataKcStatuses));
        this.kcPrograms$ = this._store.pipe(select(selectSecurityStateDataSystemDataKcPrograms));
        this.securityToken$ = this._store.pipe(select(selectSecurityStateDataSecurityToken));
        this.programDetail$ = this._store.pipe(select(selectKcAppDataProgramDetail));

        this.currentUser$.subscribe(user => this.currentUser = user);
        this.kcStatus$.subscribe(status => this.kcStatus = status);
        this.kcPrograms$.subscribe(programs => this.kcPrograms = programs);
        this.securityToken$.subscribe(securityToken => this.securityToken = securityToken);
        this.programDetail$.subscribe(programDetail => this.programDetail = programDetail);
    }

    canCreatePledge(): boolean {
        return this.currentUser.userType !== UserType.Viewer;
    }

    loggedInUserCanApprove(status: KcStatus): boolean {
        if (!status) {
            return false;
        }
        return this.isSystemOrFundAdmin && !status.isApprovedStatus && !this.isKcStatusLocked(status);
    }

    loggedInUserCanCancel(status: KcStatus): boolean {
        if (!status) {
            return false;
        }

        return this.isSystemOrFundAdmin &&
            !this.kcStatusIsIncomplete(status) &&
            !status.isCancelledStatus &&
            !status.isDeletedStatus &&
            !status.isRejectedStatus;
    }

    loggedInUserCanContinueEnrollment(status: KcStatus): boolean {
        if (!status) {
            return false;
        }
        return !status.isPendingApprovalStatus && this.kcStatusIsIncomplete(status);
    }

    // Determine whether the logged in user can save this keeping current application id
    loggedInUserCanSave(status: KcStatus): boolean {
        if (!status) {
            return false;
        }
        return (!this.isKcStatusLocked(status) &&
                !this.isViewer) ||
            this.isSystemAdmin;
    }

    loggedInUserCanReject(status: KcStatus): boolean {
        if (!status) {
            return false;
        }
        return this.isSystemOrFundAdmin && status.isPendingApprovalStatus;
    }

    loggedInUserCanDelete(status: KcStatus): boolean {
        if (!status) {
            return false;
        }
        return this.kcStatusIsIncomplete(status) && this.loggedInUserCanSave(status);
    }

    loggedInUserHasAgreed(): boolean {
        const user = this.currentUser;
        if (!user) {
            return false;
        }
        return user.uwAgreementDate && moment(user.uwAgreementDate) > moment().subtract(1, 'year');
    }

    showSaveApplicationBtn(status: KcStatus): boolean {
        if (!status) {
            return false;
        }
        return status.isPendingApprovalStatus && this.loggedInUserCanSave(status);
    }

    canAddNote(): boolean {
        return !this.isViewer;
    }

    canDeleteNote(): boolean {
        return !this.isViewer;
    }

    getQueryParameters(model?: any): string {
        model = model || {};
        model.securityToken = encodeURIComponent(this.securityToken);
        if (model.startDate) {
            model.startDate = model.startDate.format();
        }
        if (model.endDate) {
            model.endDate = model.endDate.format();
        }
        const params = Object.keys(model)
            .filter((key) => !!model[key])
            .map((key) => `${key}=${model[key]}`)
            .join('&');
        return new URLSearchParams(params).toString();
    }

    getPledgeStatus(pledgeStatus: | PledgeStatusType | string): PledgeStatusType | null {
        if (pledgeStatus === 'unknown') {
            return PledgeStatusType.Unknown;
        } else if (pledgeStatus === 'new') {
            return PledgeStatusType.New;
        } else if (pledgeStatus === 'confirmed') {
            return PledgeStatusType.Confirmed;
        } else if (pledgeStatus === 'pending') {
            return PledgeStatusType.Pending;
        } else if (pledgeStatus === 'paid') {
            return PledgeStatusType.Paid;
        } else if (pledgeStatus === 'deleted') {
            return PledgeStatusType.Deleted;
        } else if (pledgeStatus === 'pendingManual') {
            return PledgeStatusType.PendingManual;
        } else {
            return null;
        }
    }
}
