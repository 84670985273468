import {map, take} from 'rxjs/operators';
import {Injectable, Injector, OnInit} from '@angular/core';
import {ActivatedRouteSnapshot, RouterStateSnapshot, Router} from '@angular/router';
import {User} from '../services/api.client';
import {Observable} from 'rxjs';
import {NotificationService} from '../services/NotificationService';
import {select, Store} from '@ngrx/store';
import * as fromRoot from '../store';
import {selectSecurityStateDataCurrentUser} from '../store/selectors/security.selectors';

@Injectable()
export class HasKcFeatureGuard {

    currentUser$: Observable<User>;
    currentUser: User;

    constructor(private inject: Injector, private _store: Store<fromRoot.AppState>) {
        this.currentUser$ = this._store.pipe(select(selectSecurityStateDataCurrentUser));
        this.currentUser$.subscribe(user => this.currentUser = user);
    }

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot)
        : Observable<boolean> | Promise<boolean> | boolean {
        return this.currentUser$
            .modelExists().pipe(
                take(1),
                map((user: User) => {
                    if (!user.hasKeepingCurrent) {
                        this.inject.get(NotificationService).showError('You do not have permission to visit this page.');
                        this.inject.get(Router).navigate(['/login']).then();
                    }
                    return user.hasKeepingCurrent;
                }),);
    }
}
