import {createAction, props} from '@ngrx/store';
import {
    AllocationReport,
    ConfirmOrphanReport,
    EfsReport,
    EnrollmentReport,
    MissedPaymentReport,
    PagedListOfCompanyMailingReport,
    PagedListOfPledgeDemographicDetailReport,
    PagedListOfPledgeDetailReport, PagedListOfProcessingReport,
    PledgeCountyReport,
    PledgeDemographicSummaryReport,
    PledgeDetailReport,
    PledgeSummaryReport,
    PledgeZipcodeReport, ShiftPledgeReportSummary
} from '../../services/api.client';

export const reportRetrievePledgeDetailReportFailed = createAction(
    '[REPORT] REPORT_RETRIEVE_PLEDGE_DETAIL_REPORT_FAILED',
    props<{ payload: any }>());

export const reportRetrievePledgeDetailReportSuccess = createAction(
    '[REPORT] REPORT_RETRIEVE_PLEDGE_DETAIL_REPORT_SUCCESS',
    props<{ payload: PledgeDetailReport[] }>());

export const retrievePledgeZipcodeReportSuccess = createAction(
    '[REPORT} RETRIEVE_PLEDGE_ZIPCODE_REPORT_SUCCESS',
    props<{ payload: PledgeZipcodeReport[] }>());

export const retrievePledgeZipcodeReportFailed = createAction(
    '[REPORT} RETRIEVE_PLEDGE_ZIPCODE_REPORT_FAILED',
    props<{ payload: any }>());

export const retrievePledgeCountyReportSuccess = createAction(
    '[REPORT} RETRIEVE_PLEDGE_COUNTY_REPORT_REPORT_SUCCESS',
    props<{ payload: PledgeCountyReport[] }>());

export const retrievePledgeCountyReportFailed = createAction(
    '[REPORT} RETRIEVE_PLEDGE_COUNTY_REPORT_REPORT_FAILED',
    props<{ payload: any }>());

export const agencyDetailReportSuccess = createAction(
    '[Demographic Report] AGENCY_DETAIL_REPORT_SUCCESS',
    props<{ payload: PagedListOfPledgeDetailReport }>());

export const agencySummaryReportSuccess = createAction(
    '[Demographic Report] AGENCY_SUMMARY_REPORT_SUCCESS',
    props<{ payload: PledgeSummaryReport[] }>());

export const allocationReportSuccess = createAction(
    '[Demographic Report] ALLOCATION_REPORT_SUCCESS',
    props<{ payload: AllocationReport[] }>());

export const pledgeDemographicDetailReportSuccess = createAction(
    '[Demographic Report] PLEDGE_DEMOGRAPHIC_DETAIL_REPORT_SUCCESS',
    props<{ payload: PagedListOfPledgeDemographicDetailReport }>());

export const pledgeDemographicCountyReportSuccess = createAction(
    '[Demographic Report] PLEDGE_DEMOGRAPHIC_COUNTY_REPORT_SUCCESS',
    props<{ payload: PledgeCountyReport[] }>());

export const pledgeDemographicByZipCodeReportSuccess = createAction(
    '[Demographic Report] PLEDGE_DEMOGRAPHIC_BY_ZIP_CODE_REPORT_SUCCESS',
    props<{ payload: PledgeZipcodeReport[] }>());

export const pledgeDemographicSummaryReportSuccess = createAction(
    '[Demographic Report] PLEDGE_DEMOGRAPHIC_SUMMARY_REPORT_SUCCESS',
    props<{ payload: PledgeDemographicSummaryReport }>());

export const retrieveEfsReportSuccess = createAction(
    '[Efs Report] RETRIEVE_EFS_REPORT_SUCCESS',
    props<{ payload: EfsReport[] }>());

export const retrieveEfsReportFailed = createAction(
    '[Efs Report] RETRIEVE_EFS_REPORT_FAILED',
    props<{ payload: EfsReport[] }>());

export const retrieveKcEnrollmentReportSuccess = createAction(
    '[KC REPORT] RETRIEVE_KC_ENROLLMENT_REPORT_SUCCESS',
    props<{ payload: EnrollmentReport[] }>());

export const retrieveKcEnrollmentReportFailed = createAction(
    '[KC REPORT] RETRIEVE_KC_ENROLLMENT_REPORT_FAILED',
    props<{ payload: EnrollmentReport[] }>());

export const retrieveKcMissedPaymentReportSuccess = createAction(
    '[KC REPORT] RETRIEVE_KC_MISSED_PAYMENT_REPORT_SUCCESS',
    props<{ payload: MissedPaymentReport[] }>());

export const retrieveKcMissedPaymentReportFailed = createAction(
    '[KC REPORT] RETRIEVE_KC_MISSED_PAYMENT_REPORT_FAILED',
    props<{ payload: MissedPaymentReport[] }>());

export const retrieveKcConfirmOrphanReportSuccess = createAction(
    '[KC REPORT] RETRIEVE_KC_CONFIRM_ORPHAN_REPORT_SUCCESS',
    props<{ payload: ConfirmOrphanReport[] }>());

export const retrieveKcConfirmOrphanReportFailed = createAction(
    '[KC REPORT] RETRIEVE_KC_CONFIRM_ORPHAN_REPORT_FAILED',
    props<{ payload: ConfirmOrphanReport[] }>());

export const buildCompanyMailingLabelsSuccess = createAction(
    '[PAYMENT PROCESSING REPORT] BUILD_COMPANY_MAILING_LABELS_SUCCESS',
    props<{ payload: PagedListOfCompanyMailingReport }>());

export const buildCompanyMailingLabelsFailed = createAction(
    '[PAYMENT PROCESSING REPORT] BUILD_COMPANY_MAILING_LABELS_FAILED',
    props<{ payload: any }>());

export const buildProcessingDetailReportSuccess = createAction(
    '[PAYMENT PROCESSING REPORT] BUILD_PROCESSING_DETAIL_REPORT_SUCCESS',
    props<{ payload: PagedListOfProcessingReport }>());

export const buildProcessingDetailReportFailed = createAction(
    '[PAYMENT PROCESSING REPORT] BUILD_PROCESSING_DETAIL_REPORT_FAILED',
    props<{ payload: any }>());

export const buildShiftPledgeReportSuccess = createAction(
    '[PAYMENT PROCESSING REPORT] BUILD_SHIFT_PLEDGE_REPORT_SUCCESS',
    props<{ payload: ShiftPledgeReportSummary[] }>());

export const buildShiftPledgeReportFailed = createAction(
    '[PAYMENT PROCESSING REPORT] BUILD_SHIFT_PLEDGE_REPORT_FAILED',
    props<{ payload: any }>());

export const buildShiftPledgeReportReportReportSuccess = createAction(
    '[PAYMENT PROCESSING REPORT] BUILD_SHIFT_PLEDGE_REPORT_REPORT_REPORT_SUCCESS');

export const buildShiftPledgeReportReportReportFailed = createAction(
    '[PAYMENT PROCESSING REPORT] BUILD_SHIFT_PLEDGE_REPORT_REPORT_REPORT_FAILED',
    props<{ payload: any }>());
