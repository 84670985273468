import {Observable} from 'rxjs';
import {filter} from 'rxjs/operators';

export interface Empty<T> {
  (): Observable<T>;
}

declare module 'rxjs/internal/Observable' {
  interface Observable<T> {
    empty: Empty<T>;
  }
}
Observable.prototype.empty = function() {
  return this.pipe(filter((obj) => !obj));
};
