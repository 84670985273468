import {map, filter} from 'rxjs/operators';
import {Component, OnInit, ViewChild} from '@angular/core';
import {ITableHeader} from '../../../../shared/table/TableComponent';
import {Observable} from 'rxjs';

import {
    PagedListOfPledgeDemographicDetailReport,
    PagedListOfPledgeDetailReport
} from '../../../../../services/api.client';
import * as moment from 'moment';
import {DATE_TIME_FORMAT, SHORT_DATE_FORMAT} from '../../../../../constants';
import {PledgeReportForm} from '../../PledgeReportForm';
import {PledgeStatusType} from '../../../../../services/api.client';
import {splitCamelCase} from '../../../../../utilities/Util';
import {User} from '../../../../../services/api.client';
import {PledgeService} from '../../../../../services/PledgeService';
import {select, Store} from '@ngrx/store';
import * as fromRoot from '../../../../../store';
import {selectReportDataAgencyDetailReport} from '../../../../../store/selectors/report.selectors';
import {selectSecurityStateDataCurrentUser} from '../../../../../store/selectors/security.selectors';

@Component({
  templateUrl: './AgencyDetailReportComponent.html',
})
export class AgencyDetailReportComponent implements OnInit {

  constructor(public pledgeService: PledgeService,
              private _store: Store<fromRoot.AppState>) {
      this.currentUser$ = this._store.pipe(select(selectSecurityStateDataCurrentUser));
      this.report$ = this._store.pipe(select(selectReportDataAgencyDetailReport));
  }
  tableHeaders: ITableHeader[] = [];
  rows: any;

  @ViewChild('form')
  form: PledgeReportForm;

  // subscription
  currentUser: User;
  currentUser$: Observable<User>;
  report$: Observable<PagedListOfPledgeDetailReport>;
  report: PagedListOfPledgeDetailReport;

  header: ITableHeader;

  ngOnInit(): void {
    this.currentUser$.subscribe(currentUser => this.currentUser = currentUser);
    if (this.currentUser.hasPrivate) {
      this.tableHeaders = [
        {text: 'Entry Date', sortKey: 'EnteredDate'},
        {text: 'Agency', sortKey: 'AgencyName'},
        {text: 'Pledges Type', sortKey: 'PledgeType'},
        {text: 'Assistance Type'},
        {text: 'Leverage'},
        {text: 'Status', sortKey: 'Status'},
        {text: 'PledgeID', sortKey: 'PledgeNumber'},
        {text: 'Company', sortKey: 'CompanyName'},
        {text: 'Acct#', sortKey: 'AccountNumber'},
        {text: 'First Name', sortKey: 'FirstName'},
        {text: 'Middle Initial', sortKey: 'MiddleName'},
        {text: 'Last Name', sortKey: 'LastName'},
        {text: 'Pledge Date', sortKey: 'PledgeDate'},
        {text: 'Amount$', sortKey: 'PledgeAmount'},
        {text: 'Approved By', sortKey: 'UwdaApprovedByName'},
        {text: 'Ledger #', sortKey: 'LedgerNumber'},
        {text: 'Private', sortKey: 'Private'},
      ];

      this.rows = this.report$.pipe(
        filter(report => !!report),
        map((report) => report.items.map(r => [
          moment(r.enteredDate).format(DATE_TIME_FORMAT),
          r.agencyName,
          r.pledgeType,
          this.getAssistanceType(r.assistanceType, r.isUwda),
          r.leverageAmount,
          this.getStatus(r.status),
          r.pledgeNumber,
          this.getCompanyName(r.companyName, r.onetimeCompanyRecipient),
          r.accountNumber,
          r.firstName,
          r.middleName,
          r.lastName,
          moment(r.pledgeDate).format(SHORT_DATE_FORMAT),
          r.pledgeAmount.toFixed(2),
          r.approvedBy,
          r.ledgerNumber,
          r.isPrivate
        ])), );

    } else {
      this.tableHeaders = [
        {text: 'Entry Date', sortKey: 'EnteredDate'},
        {text: 'Agency', sortKey: 'AgencyName'},
        {text: 'Pledges Type', sortKey: 'PledgeType'},
        {text: 'Assistance Type'},
        {text: 'Leverage'},
        {text: 'Status', sortKey: 'Status'},
        {text: 'PledgeID', sortKey: 'PledgeNumber'},
        {text: 'Company', sortKey: 'CompanyName'},
        {text: 'Acct#', sortKey: 'AccountNumber'},
        {text: 'First Name', sortKey: 'FirstName'},
        {text: 'Middle Initial', sortKey: 'MiddleName'},
        {text: 'Last Name', sortKey: 'LastName'},
        {text: 'Pledge Date', sortKey: 'PledgeDate'},
        {text: 'Amount$', sortKey: 'PledgeAmount'},
        {text: 'Approved By', sortKey: 'UwdaApprovedByName'},
        {text: 'Ledger #', sortKey: 'LedgerNumber'},
      ];

      this.rows = this.report$.pipe(
        filter(report => !!report),
        map((report) => report.items.map(r => [
          moment(r.enteredDate).format(DATE_TIME_FORMAT),
          r.agencyName,
          r.pledgeType,
          this.getAssistanceType(r.assistanceType, r.isUwda),
          r.leverageAmount,
          this.getStatus(r.status),
          r.pledgeNumber,
          this.getCompanyName(r.companyName, r.onetimeCompanyRecipient),
          r.accountNumber,
          r.firstName,
          r.middleName,
          r.lastName,
          moment(r.pledgeDate).format(SHORT_DATE_FORMAT),
          r.pledgeAmount.toFixed(2),
          r.approvedBy,
          r.ledgerNumber
        ])), );
    }
  }

  onHeaderClick(header: ITableHeader): void {
    this.header = header;
    this.form.submitAgencyPledgeDetailReport(this.report.offset, this.report.limit, this.header.sortKey, this.header.ascending);
  }

  onTableUpdate(pagedList: PagedListOfPledgeDetailReport): Observable<PagedListOfPledgeDemographicDetailReport> {
      const sortKey = this.header ? this.header.sortKey : null;
      const ascending = this.header && this.header.ascending;
      return this.form.submitAgencyPledgeDetailReport(pagedList.offset, pagedList.limit, sortKey, ascending);
  }

  private getStatus(status: number | string): string {
      if (typeof status === 'number') {
          return splitCamelCase(PledgeStatusType[status]);
      }
      return splitCamelCase(status);
  }

  private getAssistanceType(assistanceType: any, isUwda: any): string {
    if (isUwda) {// check if 211-DA pledge; if yes, show pledge assistance type
      const assType = this.pledgeService.formatPledgeAssistanceType(assistanceType);
      return splitCamelCase(assType);
    } else {
      return 'NONE';
    }
  }

  private getCompanyName(companyName: any, oneTimePayee: any): string {
    if (companyName) {// check if regular company
      return splitCamelCase(companyName);
    } else {
      return splitCamelCase(oneTimePayee); // one time company
    }
  }
}
