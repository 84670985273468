import {map, filter} from 'rxjs/operators';
import {Component, OnInit, ViewChild} from '@angular/core';
import {ITableHeader} from '../../../../shared/table/TableComponent';
import {Observable} from 'rxjs';
import {PagedListOfPledgeDemographicDetailReport, PagedListOfPledgeDetailReport} from '../../../../../services/api.client';

import {PledgeReportForm} from '../../PledgeReportForm';
import * as moment from 'moment';
import {DATE_TIME_FORMAT, SHORT_DATE_FORMAT} from '../../../../../constants';
import {PledgeStatusType} from '../../../../../services/api.client';
import {splitCamelCase, formatDecimal} from '../../../../../utilities/Util';
import {select, Store} from '@ngrx/store';
import * as fromRoot from '../../../../../store';
import {selectReportDataPledgeDemographicDetailReport} from '../../../../../store/selectors/report.selectors';

@Component({
    templateUrl: './PledgeDetailReportComponent.html',
})
export class PledgeDetailReportComponent implements OnInit {

    tableHeaders: ITableHeader[] = [
        {text: 'Status',},
        {text: 'Pledge ID',},
        {text: 'Pledge Type',},
        {text: 'Company',},
        {text: 'Amount$',},
        {text: 'Agency',},
        {text: 'Entered',},
        {text: 'Pledged',},
        {text: 'Paid Date',},
        {text: 'Crisis?',},
        {text: 'Client SSN',},
        {text: 'First Name',},
        {text: 'M. Initial',},
        {text: 'Last Name',},
        {text: 'Address Line 1',},
        {text: 'Address Line 2',},
        {text: 'City',},
        {text: 'State',},
        {text: 'Zip Code',},
        {text: 'Client Age',},
        {text: 'Client Phone 1',},
        {text: 'Client Phone 2',},
        {text: 'Client Employment Status',},
        {text: 'Client Union Info',},
        {text: 'Client Is Elderly',},
        {text: 'Client Is Disabled',},
        {text: 'Client Has Children Under Five',},
        {text: 'Client Is Veteran',},
        {text: 'Client Is Homeless',},
        {text: 'Client Household Has Elderly',},
        {text: 'Client Household Has Disabled',},
        {text: 'Client Household Has Children Under Five',},
        {text: 'Client Household Income$',},
        {text: 'Client Household Size',},
    ];

    @ViewChild('form')
    form: PledgeReportForm;

    report$: Observable<PagedListOfPledgeDemographicDetailReport>;

    rows: any;

    constructor(private _store: Store<fromRoot.AppState>) {
        this.report$ = this._store.pipe(select(selectReportDataPledgeDemographicDetailReport));
    }

    ngOnInit(): void {
        this.report$.pipe(
            filter(report => !!report),
            // TODO: add missing columns
            map((report) =>
                report.items.map((detailReport) => [
                    this.getPledgeStatus(detailReport.status),
                    detailReport.pledgeNumber,
                    detailReport.pledgeType,
                    detailReport.companyName,
                    formatDecimal(detailReport.pledgedAmount),
                    detailReport.agencyName,
                    moment(detailReport.enteredDate).format(DATE_TIME_FORMAT),
                    moment(detailReport.pledgedDate).format(SHORT_DATE_FORMAT),
                    moment(detailReport.paidDate).format(SHORT_DATE_FORMAT),
                    detailReport.isCrisisPledge,
                    detailReport.clientLast4Ssn,
                    detailReport.clientFirstName,
                    detailReport.clientMiddleInitial,
                    detailReport.clientLastName,
                    detailReport.clientAddress1,
                    detailReport.clientAddress2,
                    detailReport.clientCity,
                    detailReport.clientState,
                    detailReport.clientZipCode,
                    detailReport.clientAge,
                    detailReport.clientPhone,
                    detailReport.clientPhoneAlt,
                    detailReport.clientEmploymentStatus,
                    detailReport.clientUnionInfo,
                    detailReport.clientIsElderly,
                    detailReport.clientIsDisabled,
                    detailReport.clientHasChildrenUnderFive,
                    detailReport.clientIsVeteran,
                    detailReport.clientIsHomeless,
                    detailReport.clientMembersAreElderly,
                    detailReport.clientMembersAreDisabled,
                    detailReport.clientMembersHaveChildrenUnderFive,
                    formatDecimal(detailReport.clientHouseHoldIncome),
                    detailReport.clientHouseHoldSize,
                ])), ).subscribe((rows) => {
            this.rows = rows;
        });
    }

    private getPledgeStatus(status: number | string): string {
        if (typeof status === 'number') {
            return splitCamelCase(PledgeStatusType[status]);
        }
        return splitCamelCase(status);
    }

    onTableUpdate(pagedList: PagedListOfPledgeDemographicDetailReport): Observable<PagedListOfPledgeDetailReport> {
        return this.form.submitPledgeDemographicDetailReport(pagedList.offset, pagedList.limit);
    }
}
