import {Component, OnInit} from '@angular/core';
import {
    UntypedFormBuilder,
    UntypedFormGroup,
    UntypedFormControl,
    Validators,
    FormGroup,
    FormControl,
    FormBuilder,
    AbstractControl
} from '@angular/forms';
import {LookUpService} from '../../../services/LookUpService';
import {LookUp, PagedListOfLookUp} from '../../../services/api.client';

import {LookUpCategoryType} from '../../../services/api.client';
import {ITableHeader} from '../../shared/table/TableComponent';
import {extractEnumNames, splitCamelCase} from '../../../utilities/Util';
import {DropdownValue} from '../../../utilities/DropdownComponent';
import {SecurityService} from '../../../services/SecurityService';
import {Observable} from 'rxjs';
import {select, Store} from '@ngrx/store';
import * as fromRoot from '../../../store';
import {
    selectLookupDataCurrentSelectedLookUp,
    selectLookupDataLookUps,
    selectLookupDataLookUpsTotalCount
} from '../../../store/selectors/lookup.selectors';

@Component({
    templateUrl: './LookUpComponent.html',
    styleUrls: ['./LookUpComponent.scss']
})

export class LookUpComponent implements OnInit {

    public form = this.fb.group({
        id: [''],
        category: [null, Validators.required],
        key: ['', Validators.required],
        lookupValue: ['', Validators.required],
        description: [''],
        sortOrder: ['', Validators.required]
    });

    get id(): FormControl {
        return this.form.get('id') as FormControl;
    }

    get category(): FormControl {
        return this.form.get('category') as FormControl;
    }

    get key(): FormControl {
        return this.form.get('key') as FormControl;
    }

    get lookupValue(): FormControl {
        return this.form.get('lookupValue') as FormControl;
    }

    get description(): FormControl {
        return this.form.get('description') as FormControl;
    }

    get sortOrder(): FormControl {
        return this.form.get('sortOrder') as FormControl;
    }


    constructor(private fb: FormBuilder,
                private _lookUpService: LookUpService,
                private _securityService: SecurityService,
                private _store: Store<fromRoot.AppState>) {
        this.currentLookUp$ = this._store.pipe(select(selectLookupDataCurrentSelectedLookUp));
        this.pagedList$ = this._store.pipe(select(selectLookupDataLookUps));
        this.totalCount$ = this._store.pipe(select(selectLookupDataLookUpsTotalCount));
    }

    categories = extractEnumNames(LookUpCategoryType);
    // defaultCategory = this.categories.map(lookup => lookup.key == "States").toString();

    currentLookUp: LookUp;
    currentLookUp$: Observable<LookUp>;
    pagedList: PagedListOfLookUp;
    pagedList$: Observable<PagedListOfLookUp>;

    isLoading: boolean;
    lookUpCategory: LookUpCategoryType = LookUpCategoryType.States;

    // sorting
    currentSortName = 'Category';
    currentSorting = false;

    tableHeaders: ITableHeader[] = [
        {text: 'Category', sortKey: 'Category', class: 'hidden-sm-down', ascending: true},
        {text: 'Key', sortKey: 'Key'},
        {text: 'Value', class: 'Value'},
        {text: 'Description', sortKey: 'Description', class: 'hidden-sm-down'},
        {text: 'Sort Order', sortKey: 'SortOrder', class: 'hidden-sm-down'}
    ];

    // pagination
    limit = 10;
    currentPage = 1;
    maxSize = 1;

    totalCount: number;
    totalCount$: Observable<number>;

    // dropdown
    lookUpCategoryDropdown: DropdownValue[];

    ngOnInit(): void {
        this.currentLookUp$.subscribe(currentLookUp => this.currentLookUp = currentLookUp);
        this.pagedList$.subscribe(pagedList => this.pagedList = pagedList);
        this.totalCount$.subscribe(totalCount => this.totalCount = totalCount);
        this.filterLookUp();
        this.loadCategories();
    }

    // set Current Selected LookUp
    setCurrentSelectedLookUp(currentSelectedLookUp: LookUp): void {
        this._lookUpService.setCurrentSelectedLookUp(currentSelectedLookUp);
        this.form.patchValue(currentSelectedLookUp as any);
    }

    sortItems(options: ITableHeader): void {
        this.currentSortName = options.sortKey;
        this.currentSorting = !options.ascending;
        this.filterLookUp();
    }

    filterLookUp(): void {
        if (!this.lookUpCategory) {
            return;
        }
        this._lookUpService.getLookUps(
            this.lookUpCategory,
            this.currentSortName,
            this.currentSorting,
            this.limit,
            (this.currentPage - 1) * this.limit);
    }

    setLimit(limit: number): void {// # of rows per page
        this.limit = limit;
        this.filterLookUp();
    }

    setPage(page: number): void {
        setTimeout(() => {
            this.currentPage = page; // set current page
            this.filterLookUp();
        });
    }

    selectedCategory(category): void {
        this.lookUpCategory = category;
        this.filterLookUp();
    }

    loadCategories(): void {// get Pledge Types
        if (this.categories) {
            this.lookUpCategoryDropdown = [];
            for (const category of this.categories) {
                this.lookUpCategoryDropdown.push(new DropdownValue(category.value.toString(), splitCamelCase(category.key)));
            }
        }
    }

    formatCategory(value): string {
        if (typeof value === 'number') {
            return splitCamelCase(LookUpCategoryType[value]);
        }
        return splitCamelCase(value);
    }

    splitCamelCase(value): string {
        return splitCamelCase(value);
    }

    submit(): void {
        const lookUp = LookUp.fromJS(this.form.value);

        if (!this.form.valid) {
            this.form.markAllAsTouched();
            return;
        } else {
            if (this.currentLookUp) {
                // let result = Object.assign({}, this.currentLookUp, lookUp);
                this._lookUpService.updateLookUp(lookUp)
                    .subscribe(() => {
                        this.filterLookUp();
                        this._lookUpService.setCurrentSelectedLookUp(null);
                        this._securityService.loadSystemData();
                        this.form.reset();
                    });
            } else {
                this._lookUpService.addLookUp(lookUp)
                    .subscribe(() => {
                        this.filterLookUp();
                        this._lookUpService.setCurrentSelectedLookUp(null);
                        this._securityService.loadSystemData();
                        this.form.reset();
                    });
            }
        }

    }

    delete(): void {
        if (this.currentLookUp) {
            this._lookUpService.deleteLookUp(this.currentLookUp.id)
                .subscribe(() => {
                    this.filterLookUp();
                    this._lookUpService.setCurrentSelectedLookUp(null);
                });
        }
    }
}
