import {createAction, props} from '@ngrx/store';
import {PagedListOfCountySpendingReportPreview} from '../../services/api.client';


export const setCountySpendingReportIsLoading = createAction('[County Spending Report] IS_LOADING');

export const setCountySpendingReportIsLoaded = createAction(
    '[County Spending Report] LOADED',
    props<{ payload: PagedListOfCountySpendingReportPreview }>()
);
