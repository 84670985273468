<div class="row">
  <div class="col-xs-12">
    <ul>
      <li>
        The Energy Assistance Instructions can be found <a
        href="http://helpingpeople.org/wp-content/uploads/2018/08/Energy-Assistance-Instructions-2017.pdf"
        target="_blank">here</a>
      </li>
      <li>
        For questions or support, please contact Kelly Wilken at the United Way of Greater St. Louis at
        Kelly.Wilken@stl.unitedway.org
      </li>
      <li>
        Agency Partner Data Sharing; Client Confidentiality; and Release of Client Information Agreement can be viewed
        <a
          href="http://helpingpeople.org/wp-content/uploads/2018/08/Direct-Asst-Portal-ConfidentialityAgreement-5-17.pdf"
          target="_blank">here</a>
      </li>
    </ul>
  </div>
</div>
