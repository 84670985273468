<h1 class="page-title">EFS Reports</h1>
<div class="row mb-lg">
  <div class="col-xs-12">
    <div class="clearfix">
      <ul class="nav nav-tabs pull-xs-left" role="tablist">
        <li class="nav-item"><a class="nav-link" [routerLink]="['./efs_report']" routerLinkActive="active">EFS</a></li>
      </ul>
    </div>
    <div class="tab-content overflow-visible mb-lg">
      <div role="tabpanel" class="tab-pane active in">
        <router-outlet></router-outlet>
        <div class="clearfix"></div>
      </div>
    </div>
  </div>
</div>
