import {inject, Injectable} from '@angular/core';
import {CompanyCategoryClient, CompanyCategoryThresholdViewModel} from './api.client';
import {Observable} from 'rxjs';

@Injectable()
export class CompanyCategoryService {
    private _companyCategoryThresholdClient = inject(CompanyCategoryClient);

    getCategoryThresholds(): Observable<CompanyCategoryThresholdViewModel[]> {
        return this._companyCategoryThresholdClient.getCompanyCategoryThresholds();
    }
}
