import {share} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {getMessageFromException} from '../utilities/Util';
import {NotificationService} from './NotificationService';
import {LookUp, LookUpClient, PagedListOfLookUp} from './api.client';

import {LookUpCategoryType} from './api.client';
import {Store} from '@ngrx/store';
import * as fromRoot from '../store';
import {Observable} from 'rxjs';
import {
  clearCurrentSelectedLookup,
  lookupsDataIsLoading,
  lookupsDataLoaded,
  setCurrentSelectedLookup
} from '../store/actions/lookup.actions';
// api
// models
// store
// enums

@Injectable()
export class LookUpService {

  constructor(private _lookupApiService: LookUpClient,
              private _notificationService: NotificationService,
              private _store: Store<fromRoot.AppState>) {
  }

  addLookUp(lookup: LookUp): Observable<LookUp> {
    const request = this._lookupApiService.addLookUp(lookup).pipe(share());
    this._notificationService.showSuccess('Attempting to save new Look Up.');
    request.subscribe({ next: (result) => {
      this.setCurrentSelectedLookUp(result);
      this._notificationService.showSuccess('LookUp Successfully Created!');
    }, error: (err) => {
        this._notificationService.showError(getMessageFromException(err));
    }, complete: () => {

    }});

    return request;
  }

  updateLookUp(lookup: LookUp): Observable<LookUp> {
    const request = this._lookupApiService.updateLookUp(lookup).pipe(share());
    this._notificationService.showSuccess('Attempting to update Look Up.');
    request.subscribe({ next: (result) => {
      this.setCurrentSelectedLookUp(result);
      this._notificationService.showSuccess('LookUp Succesfully Updated!');
    }, error: () => {
      this._notificationService.showError('There was a problem updating this LookUp.');
    }, complete: () => {

    }});
    return request;
  }

  deleteLookUp(id: string): Observable<boolean> {
    const request = this._lookupApiService.deleteLookUp(id).pipe(share());
    this._notificationService.showSuccess('Attempting to delete LookUp.');
    request.subscribe({ next: () => {
      this._notificationService.showSuccess('LookUp Successfully Deleted!');
    }, error: () => {
      this._notificationService.showError('There was a problem deleting this LookUp');
    }, complete: () => {

    }});
    return request;
  }

  getLookUps(categoryType: LookUpCategoryType, sortName: string, sorting: boolean, limit: number, offset: number): void {
    this.startLoadingAction();
    this._lookupApiService.retrieveLookUps(
      categoryType,
      limit || 10,
      offset || 0,
      sortName,
      null,
      sorting
    ).subscribe({ next:
          (data) => {
      this.setLookUpsAction(data);
    }, error: (err) => {
        this._notificationService.showError(getMessageFromException(err));
    }, complete: () => {

    }});
  }

  setCurrentSelectedLookUp(selectedLookUp: LookUp): void {
    this.setCurrentSelectedLookUpAction(selectedLookUp);
  }

  // ---------------- Security Actions ------------------

  // start, set clear
  private startLoadingAction(): void {
    this._store.dispatch(lookupsDataIsLoading());
  }

  private setLookUpsAction(lookups: PagedListOfLookUp): void {
    this._store.dispatch(lookupsDataLoaded({ payload: lookups}));
  }

  private setCurrentSelectedLookUpAction(selectedLookUp: LookUp): void {
    this._store.dispatch(clearCurrentSelectedLookup({ payload: selectedLookUp}));
  }

  private clearLookUpAction(agency: LookUp): void {
    this._store.dispatch(setCurrentSelectedLookup({ payload: agency}));
  }
}
