<groupLayout>
  Commitment:
  <fieldLayout *ngIf="helper.canDecline()">
    <label>Decline:</label>
    <div>
      <div class="half-width">We decline to support this client.</div>
      <button class="btn btn-danger m-l-1" [autoDisable]="decline">Decline</button>
    </div>
  </fieldLayout>

  <fieldLayout>
    <label class="bold">Commitment Amount:</label>
    <div>
      <div class="half-width">
        <currencyInput [control]="commitAmount"></currencyInput>
        <div *ngIf="commitAmount.hasError('overCommit')" class="text-danger">{{ commitAmount.errors.overCommit }}</div>
      </div>
      <button class="hidden-print btn btn-primary m-l-1 commit-case"
              [autoDisable]="commit">{{ existingCommit ? 'Update Commit' : 'Commit' }}</button>
      <button class="hidden-print btn btn-primary m-l-1 commit-case"
              *ngIf="helper.efnCase.status == efnCaseStatuses['Funded']"
              (click)="createPledge()">
        Pledge
      </button>
    </div>
  </fieldLayout>

  <fieldLayout>
    <label>Funding Source & Notes:</label>
    <input type="text" class="form-control half-width" [formControl]="commitNote">
  </fieldLayout>
</groupLayout>
