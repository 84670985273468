<h1 class="page-title">Browse Pledges</h1>
<section class="widget">
    <div class="widget-body">
        <div class="mt">
            <form novalidate>
                <div class="row">
                    <div class="col-md col-xs-12">
                        <ul class="help-text-align">
                            <li><span>To enter a new Pledge or EFS Commitment, go to ‘Clients’ Menu at the left.</span>
                            </li>
                            <li><span>To select a Pledge, click on the Pledge Amount.</span></li>
                        </ul>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md col-xs-12">
                        <!--search string-->
                        <label class="header-label">Search:</label>
                        <input class="search-text-input form-control"
                               type="text"
                               placeholder="Search by Last Name"
                               [ngModel]="searchValue"
                               [ngModelOptions]="{standalone: true}"
                               (ngModelChange)="setSearchValue($event)"/>
                    </div>
                    <div class="col-md col-xs-12">
                        <!--Agency modal filter-->
                        <label class="header-label">Agency:</label>
                        <input class="search-text-input form-control"
                               type="text"
                               placeholder="select agency to filter"
                               (click)="showAgencyModal()"
                               (keydown)="showAgencyModal()"
                               value="{{selectedAgencyName}}"
                               [disabled]="isDisabledAgency()"/>
                    </div>

                    <div class="col-md col-xs-12">
                        <!--Fund Type filter-->
                        <label class="header-label">Fund Type:</label>
                        <select class="drop-down-inline form-control" [formControl]="selectedPledgeTypeId">
                            <option value="">-- Fund Type --</option>
                            <option *ngFor="let item of pledgeTypes" [value]="item.id">{{item.name}}</option>
                        </select>
                    </div>
                </div>
                <div class="row">
                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <button class="btn btn-primary pull-left" role="button" (click)="searchPledges()">Search
                        </button>
                    </div>
                </div>
            </form>
            <table-dynamic id="datatable-table"
                           class="table table-striped table-hover"
                           [headers]="tableHeaders"
                           [currentPage]="currentPage"
                           [totalCount]="totalCount"
                           (headerClick)="sortPledges($event)"
                           (pageChanged)="setPage($event)"
                           (limitChange)="setLimit($event)">
                <tr *ngFor="let pledge of pagedPledges?.items">
                    <td><a [routerLink]="['../client', pledge.clientId]"
                           (click)="setCurrentSelectedClient(pledge.clientId)"><strong>{{ pledge.clientLastName}}</strong></a>
                    </td>
                    <td><a [routerLink]="['../client', pledge.clientId]"
                           (click)="setCurrentSelectedClient(pledge.clientId)"><strong>{{ pledge.clientFirstName
                        }}</strong></a></td>
                    <td class="hidden-sm-down" *ngIf="pledge.clientMissingSsn">***-**-{{pledge.clientLast4Ssn}}</td>
                    <td class="hidden-sm-down" *ngIf="!pledge.clientMissingSsn">Missing SSN</td>
                    <td class="hidden-sm-down">{{ pledge.pledgedDate | moment: 'L' }}</td>
                    <td class="hidden-sm-down">{{ pledgeService.formatPledgeStatus(pledge.status) }}</td>
                    <td class="hidden-sm-down">{{ formatPledgeType(pledge.pledgeTypeId) }}</td>
                    <td class="hidden-sm-down">{{ pledge.agencyName}}</td>
                    <td><a [routerLink]="['../pledge', pledge.id]" (click)="setCurrentSelectedPledge(pledge)"><strong>{{
                        pledge.pledgedAmount | parseNumber | currency:'USD':'symbol':'1.2-2' }}</strong></a></td>
                </tr>
            </table-dynamic>
        </div>
    </div>
    <agency-box (onAgencySelected)="onAgencySelected($event)"></agency-box>
</section>
