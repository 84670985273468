import {Component} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';

@Component({
  selector: 'clientEditComponent',
  templateUrl: './ClientEditComponent.html',
  styleUrls: ['./ClientEditComponent.scss'],
})

export class ClientEditComponent {

  constructor(private _route: ActivatedRoute,
              private _router: Router) {
  }
}
