import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ApplicationCounty, ApplicationCountyClient, PagedListOfApplicationCounty} from './api.client';
import {Observable} from 'rxjs';
import {share} from 'rxjs/operators';
import {Store} from '@ngrx/store';
import * as fromRoot from '../store';
import {
    setApplicationCountyCurrentSelectedApplicationCounty,
    setApplicationCountyDataLoaded,
    setApplicationCountyIsLoading
} from '../store/actions/applicationCounty.actions';
import {getMessageFromException} from '../utilities/Util';
import {NotificationService} from './NotificationService';

@Injectable()
export class ApplicationCountyService {
    constructor(private _http: HttpClient,
                private _applicationCountyApiClient: ApplicationCountyClient,
                private _store: Store<fromRoot.AppState>,
                private _notificationService: NotificationService
    ) {
    }

    searchCounties(
        searchValue: string,
        sortName: string,
        descending: boolean,
        limit: number,
        offset: number,
    ): Observable<PagedListOfApplicationCounty> {
        this.setIsLoadingAction();
        const request = this._applicationCountyApiClient.searchApplicationCounties(
            searchValue,
            sortName,
            null,
            descending,
            limit || 10,
            offset || 0
        ).pipe(share());

        request.subscribe({
            next: (data) => {
                this.setApplicationCountyPagedApplicationsAction(data);
            },
            error: (err) => {

            }, complete: () => {

            }
        });

        return request;
    }

    getApplicationCountyById(id: string): Observable<ApplicationCounty> {
        this.setIsLoadingAction();
        const request = this._applicationCountyApiClient.retrieveApplicationCountyById(id).pipe(share());

        request.subscribe({
            next: (data) => {
                this.setApplicationCountyCurrentSelectedAction(data);
            },
            error: (err) => {

            }, complete: () => {

            }
        });

        return request;
    }

    updateApplicationCounty(applicationCounty: ApplicationCounty): Observable<ApplicationCounty> {
        const request = this._applicationCountyApiClient.updateApplicationCounty(applicationCounty).pipe(share());
        this._notificationService.showSuccess('Attempting to update Application County.');
        request.subscribe({
            next: (result) => {
                this.setApplicationCountyCurrentSelectedAction(result);
                this._notificationService.showSuccess('Application County Successfully Updated!');
            }, error: (err) => {
                this._notificationService.showError(getMessageFromException(err));
            }, complete: () => {

            }
        });
        return request;
    }

    private setIsLoadingAction(): void {
        this._store.dispatch(setApplicationCountyIsLoading());
    }

    private setApplicationCountyPagedApplicationsAction(pagedApplicationCounties: PagedListOfApplicationCounty): void {
        this._store.dispatch(setApplicationCountyDataLoaded({payload: pagedApplicationCounties}));
    }

    private setApplicationCountyCurrentSelectedAction(applicationCounty: ApplicationCounty): void{
        this._store.dispatch(setApplicationCountyCurrentSelectedApplicationCounty({ payload: applicationCounty }));
    }
}
