import {createAction, props} from '@ngrx/store';
import {Client, Company, PagedListOfCompany} from '../../services/api.client';


export const setCurrentSelectedCompany = createAction(
    '[Company] SET_CURRENT_SELECTEDCOMPANYT',
    props<{ payload: Company }>());

export const companiesDataIsLoaded = createAction(
    '[Company] COMPANIESDATA_ISLOADED',
    props<{ payload: PagedListOfCompany }>());

export const companiesDataIsLoading = createAction(
    '[Company] COMPANIESDATA_ISLOADING');

export const clearCurrentSelectedCompany = createAction(
    '[Company] CLEAR_CURRENT_SELECTEDCOMPANY',
    props<{ payload: Client }>());

export const companiesDataSoftDelete = createAction(
    '[Company] COMPANIES_DATA_SOFT_DELETE');
