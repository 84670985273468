import {Directive, Input, HostBinding} from '@angular/core';

@Directive({
  selector: '[visible]',
})
export class VisibleDirective {

  @HostBinding('style.display')
  get display(): string {
    return (this.keepFlow || this.keepFlow) ? '' : 'none';
  }

  @HostBinding('style.visibility')
  get visibility(): string {
    return this.visible ? 'visible' : 'hidden';
  }

  @Input('visible')
  visible: boolean;

  @Input('keepFlow')
  keepFlow = false;

}
