<div class="container client-component" *ngIf="currentClient">
  <div class="row">
    <div class="col-xs-12 p-b-20">
      <i class="">Under ‘Overview’ tab choose and enter desired type of assistance.</i>
      <button class="btn pull-right" [class.btn-warning]="!isClientVerified()" [class.btn-success]="isClientVerified()"
              [class.disabled]="isClientVerified()"
              (click)="setVerifyClientId()">{{ isClientVerified() ? 'Client Verified' : 'Verify Client' }}</button>
    </div>
  </div>
  <div class="row">
    <div class="col-xs-12">
      <h4 class="d-inline-block">Overview for: {{currentClient.prefix}} {{currentClient.firstName}}
        {{currentClient.middleInitial}} {{currentClient.lastName}} {{currentClient.suffix}}
        <span *ngIf="currentClient.last4Ssn"><a href="#" (click)="revealSsn.open()">(***-**-{{currentClient.last4Ssn}}
          )</a></span>
        <span *ngIf="!currentClient.last4Ssn">(Missing SSN)</span></h4>
      <a class="btn btn-primary btn-clientNav" [routerLink]="['./edit']">Edit Client</a>
    </div>
  </div>
  <div class="row">
    <label class="col-md-3"><strong>Address:</strong></label>
    <div class="col-md-8">
      <address>
        {{currentClient.address1}} {{currentClient.address2}}<br>
        {{currentClient.city}}, {{currentClient.state}} {{currentClient.zipCode}}<br>
        <div *ngIf="currentClient.phone">
          <span class="fa fa-phone"></span> {{currentClient.phone}} {{currentClient.phoneExt}}
        </div>
        <div *ngIf="currentClient.email">
          <span class="fa fa-envelope"></span> {{currentClient.email}}
        </div>
      </address>
    </div>
  </div>
  <div class="row">
    <label class="col-md-3"><strong>Personal Information:</strong></label>
    <div class="col-md-8">
      <label>Gender: </label>
      <span *ngIf="currentClient.gender">{{getGenderLabel(currentClient.gender)}}</span>
      <span *ngIf="!currentClient.gender" class="text-danger"> Missing</span> <br>

      <label>Race: </label>
      <span *ngIf="currentClient?.race">{{getRaceLabel(currentClient.race)}}</span>
      <span *ngIf="!currentClient?.race" class="text-danger"> Missing</span> <br>

      <label *ngIf="currentClient?.otherRace">Other Race:</label>
      <span *ngIf="currentClient?.otherRace">{{currentClient.otherRace}}<br></span>

      <label>Birth Year: </label>
      <span *ngIf="currentClient.birthYear">{{currentClient.birthYear}}</span>
      <span *ngIf="!currentClient.birthYear" class="text-danger"> Missing</span> <br>

      <label>Employment Status: </label>
      <span *ngIf="currentClient.employmentStatus">{{getEmploymentLabel(currentClient.employmentStatus)}}</span>
      <span *ngIf="!currentClient.employmentStatus" class="text-danger"> Missing</span> <br>

      <label>Poverty Level: </label>
      <span
              *ngIf="hasAValue(currentClient.federalPovertyLevelDisplay)">{{currentClient.federalPovertyLevelDisplay | percent }}</span>
      <span *ngIf="!hasAValue(currentClient.federalPovertyLevelDisplay)" class="text-danger"> Missing</span> <br>

      <label>Household Size: </label>
      <span *ngIf="currentClient.houseHoldSize">{{currentClient.houseHoldSize}}</span>
      <span *ngIf="!currentClient.houseHoldSize" class="text-danger"> Missing</span> <br>

      <label>Monthly Household Income: </label>
      <span
              *ngIf="hasAValue(currentClient?.houseHoldIncome)">{{currentClient?.houseHoldIncome | parseNumber | currency:'USD':'symbol':'1.2-2'}}</span>
      <span *ngIf="!hasAValue(currentClient?.houseHoldIncome)" class="text-danger"> Missing ({{currentClient.houseHoldIncome}})</span> <br>

      <label>Individual Monthly Income: </label>
      <span
              *ngIf="hasAValue(currentClient?.individualMonthlyIncome)">{{currentClient?.individualMonthlyIncome | parseNumber | currency:'USD':'symbol':'1.2-2'}}</span>
      <span *ngIf="!hasAValue(currentClient?.individualMonthlyIncome)" class="text-danger"> Missing</span> <br>

      <label>Union Info: </label>
      <span>{{currentClient.unionInfo }}</span>
    </div>
  </div>
  <br>
  <div class="row">
    <label class="col-md-3"><strong></strong></label>
    <div class="col-md-8">
      <div *ngIf="currentClient.isElderly">
        <span class="fa fa-check-square-o"></span>
        <label>: Client is elderly</label>
      </div>
      <div *ngIf="currentClient.isDisabled">
        <span class="fa fa-check-square-o"></span>
        <label>: Client is disabled</label>
      </div>
      <div *ngIf="currentClient.hasChildrenUnderFive">
        <span class="fa fa-check-square-o"></span>
        <label>: Client has children under five</label>
      </div>
      <div *ngIf="currentClient.isVeteran">
        <span class="fa fa-check-square-o"></span>
        <label>: Client is a veteran</label>
      </div>
      <div *ngIf="currentClient.isHomeless">
        <span class="fa fa-check-square-o"></span>
        <label>: Client is homeless</label>
      </div>
      <div *ngIf="currentClient.membersAreElderly">
        <span class="fa fa-check-square-o"></span>
        <label>: Household members are elderly</label>
      </div>
      <div *ngIf="currentClient.membersAreDisabled">
        <span class="fa fa-check-square-o"></span>
        <label>: Household members are disabled</label>
      </div>
      <div *ngIf="currentClient.membersHaveChildrenUnderFive">
        <span class="fa fa-check-square-o"></span>
        <label>: Household members have children under 5</label>
      </div>
    </div>
  </div>
  <div class="row" *ngIf="isClientVerified() && currentUser.userType == userTypes.SystemAdmin">
    <div class="col-xs-12">
      <button class="btn btn-outline-danger pull-right" (click)="prompt.open()">Merge Client</button>
    </div>
  </div>
  <prompt-dialog #f [size]="'lg'" [titleText]="'Search For Client To Merge With'" [showTitle]="true"
                 [promptText]="mergeClientText" [overrideFooter]="true">
    <clientList body (selectedClient)="mergeClient = $event"></clientList>
    <div footer>
      <button class="btn btn-secondary pull-right p-x-1" (click)="cancelMerge()">Cancel</button>
      <button class="btn btn-danger pull-right p-x-1 m-r-1" (click)="mergeClients()" *ngIf="mergeClient">Confirm
      </button>
    </div>
  </prompt-dialog>
  <client-ssn-reveal [clientId]="currentClient.id"></client-ssn-reveal>
</div>
