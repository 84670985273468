<div class="container">
    <div class="pull-md-right">
        <div>
            <strong>{{ getAuditStateLabel() }}</strong>
            <button *ngIf="canAudit()" class="btn btn-sm btn-primary fa fa-edit m-l-1" (click)="showAuditStateModal()"></button>
        </div>
    </div>
    <div class="row">
        <h1 class="page-title">Edit Assistance Application for {{assistanceApplication?.fundType?.name}}</h1>
    </div>
    <section class="widget">
        <div class="widget-body">
            <div class="mt">
                <div *ngIf="!loading">
                    <app-assistance-application-form
                            [client]="assistanceApplication?.client"
                            [user]="assistanceApplication?.createdByUser"
                            [assistanceApplication]="assistanceApplication"
                            [auditState]="auditState"
                            [applicationLoading]="loading"
                            [defaultOpenDocumentUpload]="false"
                            [isNewApplication]="false"
                            (onUpdate)="onUpdate($event)"
                            #fs
                    ></app-assistance-application-form>
                </div>
                <button *ngIf="canEdit()"
                        [disabled]="(assistanceApplicationForm?.loading$ | async)"
                        class="hidden-print btn btn-primary save-case"
                        (click)="submit()">
                    Save Case
                </button>
                <br>
                &nbsp;<hr>
                <br>
                <button class="hidden-print btn btn-primary print-case" (click)="printCase()">Print Case</button>
                <app-assistance-application-tabs *ngIf="assistanceApplication" class="hidden-print"
                                                 [assistanceApplication]="assistanceApplication"
                                                 #tabs></app-assistance-application-tabs>
                <hr>
                <app-activity-audit-tabs *ngIf="assistanceApplication" class="hidden-print"
                                         [assistanceApplication]="assistanceApplication"
                                         [canAudit]="canAudit()"
                                         new
                                         #auditTabs></app-activity-audit-tabs>
            </div>
        </div>
    </section>
</div>
<prompt-dialog #auditModal
               [size]="'lg'"
               [titleText]="'Assistance Application Audit Status'"
               [showTitle]="true"
               [overrideFooter]="true">
    <div body>
        <app-manage-audit-state
                [assistanceApplicationId]="applicationId"
                (savedAuditState)="savedAudit = $event"
                (closeModal)="onClickCancelAuditState()"
        ></app-manage-audit-state>
    </div>

    <div footer></div>
</prompt-dialog>
