import {Component, ViewChild, Input} from '@angular/core';
import {ModalComponent} from 'src/app/utilities/old-modal/components/modal';
import {Observable, Observer} from 'rxjs';
import 'rxjs';


export enum PrintConfirmation {
  Print,
  PrintAndConfirm,
  Confirm,
  Cancel
}


@Component({
  selector: 'pledge-confirmation',
  templateUrl: './PledgeConfirmationComponent.html',
  styleUrls: ['./PledgeConfirmationComponent.scss']
})

export class PledgeConfirmationComponent {
  @ViewChild(ModalComponent)
  modal: ModalComponent;

  @Input() isReprint = false;
  @Input() titleText = '';
  @Input() headerText = '';
  @Input() promptText = '';
  @Input() printButtonText = 'Print';
  @Input() confirmPrintButtonText = 'Confirm & Print';
  @Input() confirmButtonText = 'Confirm Only';
  @Input() cancelButtonText = 'Cancel';

  disableButtons: boolean;

  private observer: Observer<PrintConfirmation>;

  open(): Observable<PrintConfirmation> {
    this.modal.open();
    return new Observable(obs => {
      this.observer = obs;
    });
  }

  printClicked(): void {
    window.print();
    this.observer.next(PrintConfirmation.Print);
  }

  confirmPrintClicked(): void {
    window.print();
    this.observer.next(PrintConfirmation.PrintAndConfirm);
  }

  confirmClicked(): void {
    this.observer.next(PrintConfirmation.Confirm);
  }

  cancelClicked(): void {
    this.observer.next(PrintConfirmation.Cancel);
    this.close();
  }

  close(): void {
    this.modal.close();
    if (this.observer) {
      this.observer.complete();
    }
  }
}
