import {createFeatureSelector, createSelector} from '@ngrx/store';
import {IEfnData} from '../IEfnData';

const efnDataFeatureSelector = createFeatureSelector<IEfnData>('efn');

export const selectEfnDataClientEfnCases = createSelector(
    efnDataFeatureSelector,
    (state) => state.clientsEfnCases
);

export const selectEfnDataSelectedEfnCase = createSelector(
    efnDataFeatureSelector,
    (state) => state.selectedEfnCase
);

export const selectEfnDataOpenEfnCase = createSelector(
    efnDataFeatureSelector,
    (state) => state.openEfnCases
);

export const selectEfnDataFundedEfnCase = createSelector(
    efnDataFeatureSelector,
    (state) => state.fundedEfnCases
);

export const selectEfnDataEfnCasesPaged = createSelector(
    efnDataFeatureSelector,
    (state) => state.efnCasesPaged
);

export const selectEfnDataEfnCasesPagedTotalCount = createSelector(
    efnDataFeatureSelector,
    (state) => state.efnCasesPaged?.totalCount
);
