import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'exclude'
})
export class ExcludePipe implements PipeTransform {

  transform(values: any[], args: any[], key?: string): any {
    if (!values || !args || args.length === 0) {
      return values;
    }
    return values.filter((value) => !args.find((a) => (key) ? value[key] === a : value === a));
  }

}
