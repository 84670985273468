import {Input, Component} from '@angular/core';
import {KcApplication, KcNote, KcNoteResult, StatusType} from '../../../services/api.client';
import {KcApplicationService} from '../../../services/KcApplicationService';
import {UntypedFormControl} from '@angular/forms';
import {SystemService} from '../../../services/SystemService';

@Component({
  selector: 'clientNoteTable',
  templateUrl: './ClientNoteTable.html',
})
export class ClientNoteTableComponent {

  note = new UntypedFormControl('');
  notes: KcNoteResult[];

  private _application: KcApplication;
  @Input()
  set application(application: KcApplication) {
    this._application = application;
    if (application && application.id) {
      this.reload();
    }
  }

  get application(): KcApplication {
    return this._application;
  }

  constructor(private kcApplicationService: KcApplicationService, public system: SystemService) {
  }

  reload(): void {
    this.kcApplicationService
      .getKcNotes(this._application.id)
      .subscribe((notes) => this.notes = notes);
  }

  submitAddNote(): void {
    const note = new KcNote({keepingCurrentApplicationId: this.application.id, notes: this.note.value, status: StatusType.Enabled});
    // TODO: have addKcNote return a valid note so we don't have to fetch all notes again
    this.kcApplicationService.addKcNote(note)
      .subscribe(() => this.reload());
  }


  submitDeleteNote(id: string): void {
    // TODO: have addKcNote return a valid note so we don't have to fetch all notes again
    this.kcApplicationService.deleteKcNote(id)
      .subscribe(() => this.reload());
  }


}
