<h3 class="text-center ab-center" *ngIf="!invitedAgencies.length">
  Loading Current Agencies
</h3>
<ng-container>
  <div class="col-xs-12">
    <h1 class="m-b-2">Invite Agencies</h1>
  </div>
  <div class="col-xs-12">
    <div class="list-group row">
      <div class="list-group-item-heading col-md-6 col-sm-12" *ngFor="let a of filteredInvitedAgencies">
        {{a.agencyName}}
        <button type="button" class="btn btn-outline-danger btn-xs pull-right" (click)="removeInvitedAgency(a.id)">
          <span class="glyphicon glyphicon-remove" aria-hidden="true"></span>
        </button>
      </div>
    </div>
  </div>
  <div class="col-xs-12">
    <label class="form-control-label">Add Agency</label>
    <input [formControl]="newAgency"
           type="text" class="form-control"
           (click)="showAgencyModal()"
           (keydown)="showAgencyModal()">
  </div>
</ng-container>
<agency-box (onAgencySelected)="onAgencySelected($event)"></agency-box>
