import {map, filter} from 'rxjs/operators';
import {Component, OnInit} from '@angular/core';
import {ITableHeader} from '../../../../shared/table/TableComponent';
import {PledgeZipcodeReport} from '../../../../../services/api.client';
import {Observable} from 'rxjs';
import {formatDecimal} from '../../../../../utilities/Util';
import {select, Store} from '@ngrx/store';
import * as fromRoot from '../../../../../store';
import {selectReportDataPledgeDemographicByZipCodeReport} from '../../../../../store/selectors/report.selectors';

@Component({
    templateUrl: './PledgeByZipCodeReportComponent.html',
})
export class PledgeByZipCodeReportComponent implements OnInit {
    pledgeDemographicByZipCodeReport$: Observable<PledgeZipcodeReport[]> = null;

    constructor(private _store: Store<fromRoot.AppState>) {
        this.pledgeDemographicByZipCodeReport$ = this._store.pipe(select(selectReportDataPledgeDemographicByZipCodeReport));
    }

    tableHeaders: ITableHeader[] = [
        {text: 'Zip Code'},
        {text: 'Pledge Count'},
        {text: 'Pledge Amount'},
    ];

    rows: Observable<(string | number)[][]>;

    ngOnInit(): void {
        this.rows = this.pledgeDemographicByZipCodeReport$.pipe(
            filter(pledges => !!pledges),
            map((pledges) =>
                pledges.map(pledge => [
                    pledge.zipcode,
                    pledge.pledgeCount,
                    '$' + formatDecimal(pledge.pledgeTotal)
                ])), );
    }
}
