import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Disposable} from '../../../../../../utilities/Disposable';
import {FormArray, FormBuilder, FormGroup} from '@angular/forms';
import {FileUploadChecklist, FileUploadService} from '../../../../../../services/file-upload.service';
import {distinctUntilChanged, takeUntil} from 'rxjs/operators';
import {Validators} from '../../../../../../utilities/Validators';
import {AssistanceApplicationFileTypeOptions} from '../../../../../../shared/constants/assistance-application-file-type-options';
import {AssistanceApplicationFileType} from '../../../../../../shared/enums/assistance-application-file-type';
import {AssistanceApplicationFile} from '../../../../../../services/api.client';
import {environment} from '../../../../../../../environments/environment';
import {SystemService} from '../../../../../../services/SystemService';
import {getUtilityLabelFromAssistanceApplicationAreasOfNeed} from '../../../../../../shared/utils/utilty-label-utils';
import swal from 'sweetalert2';

@Component({
    selector: 'app-assistance-application-document-upload',
    templateUrl: './assistance-application-document-upload.component.html',
    styleUrls: ['./assistance-application-document-upload.component.scss']
})
export class AssistanceApplicationDocumentUploadComponent extends Disposable implements OnInit {
    @Input() formArray: FormArray;
    @Input() baseForm: FormGroup;
    @Input() receivedForms: AssistanceApplicationFile[] = [];
    @Input() defaultOpenDocuments = true;
    @Output() onDeleteFile = new EventEmitter<any>();

    private softDeletedFileIds = [];
    private checkList: FileUploadChecklist;
    public docOptions = AssistanceApplicationFileTypeOptions;
    public showDocumentInformation = false;
    public apiUrl: string = environment.appConfig.apiBaseUrl;
    public utilityBillLabel: string;
    showUploadedDocuments = true;

    public get photoIdStatus(): boolean {
        return this.fileUploadService.photoIdTypes.some(type => this.hasFileTypeWithFile(type));
    }

    public get employmentStatus(): boolean {
        return this.fileUploadService.employmentTypes.some(type => this.hasFileTypeWithFile(type));
    }

    public get incomeStatus(): boolean {
        return !this.baseForm.hasError('incomeDocumentRequiredForSelfEmployed') && !this.baseForm.hasError('incomeDocumentRequiredForNonSelfEmployed');
    }

    public get utilityBillStatus(): boolean {
        return this.fileUploadService.utilityBillTypes.some(type => this.hasFileTypeWithFile(type));
    }

    public get childcareBillStatus(): boolean {
        return this.fileUploadService.childcareTypes.some(type => this.hasFileTypeWithFile(type));
    }

    public get evictionStatus(): boolean {
        return this.fileUploadService.evictionTypes.some(type => this.hasFileTypeWithFile(type));
    }

    public get leaseAgreementStatus(): boolean {
        return this.fileUploadService.leaseAgreementTypes.some(type => this.hasFileTypeWithFile(type));
    }

    public get delinquentRentNoticeStatus(): boolean {
        return this.fileUploadService.delinquentRentNoticeTypes.some(type => this.hasFileTypeWithFile(type));
    }

    public get carRepairEstimateStatus(): boolean {
        return this.fileUploadService.carRepairTypes.some(type => this.hasFileTypeWithFile(type));
    }

    public get proofOfIncome(): boolean {
        return this.fileUploadService.proofOfIncomeTypes.some(type => this.hasFileTypeWithFile(type));
    }

    private hasFileTypeWithFile(fileType: AssistanceApplicationFileType): boolean {
        return this.formArray.controls.some(formGroup => {
            const fg = formGroup as FormGroup;
            return fg.get('fileType').value === fileType && fg.get('file').value;
        });
    }

    constructor(private fileUploadService: FileUploadService, private fb: FormBuilder, public system: SystemService) {
        super();
    }

    public ngOnInit(): void {
        const uploadedFiles = this.formArray.value.map(x => x.fileType);
        this.checkList = this.fileUploadService.getFileRequirementStatus(uploadedFiles);

        this.formArray.valueChanges.pipe(
            takeUntil(this.$destroyed),
            distinctUntilChanged()
        ).subscribe((value) => {
            const uploadedFiles2 = value.map(x => x.fileType);
            this.checkList = this.fileUploadService.getFileRequirementStatus(uploadedFiles2);
        });

        this.updateUtilityBillLabelOnUtilityAssistanceRequestChange();

        this.showUploadedDocuments = this.defaultOpenDocuments;
    }

    public getFileInputFormGroup(index: number): FormGroup {
        return this.formArray.controls[index] as FormGroup;
    }

    public addDocumentFormGroup(): void {
        this.addFileFormGroup();
    }

    public removeDoc(index: number): void {
        const fileId = this.formArray?.at(index)?.value?.id;
        if (fileId) {
            this.softDeletedFileIds.push(fileId);
            this.onDeleteFile.emit(this.softDeletedFileIds);
        }

        if (index >= 0 && index < this.formArray.length) {
            this.formArray.removeAt(index);
        }
    }

    public onFileChange(event, form: FormGroup): void {
        const file = event.target.files[0];
        const maxInMb = 50;

        const maxSizeBytes = maxInMb * 1024 * 1024;
        const fileSizeBytes = event.target.files[0].size;

        if (fileSizeBytes > maxSizeBytes) {
            swal.fire({
                heightAuto: false,
                icon: 'error',
                title: 'File size limit',
                text: 'This file exceeds the limit of 50mb.',
            }).then();
            return;
        } else {
            form.get('file').setValue(file);
        }
    }

    public addFileFormGroup(fileType?: AssistanceApplicationFileType | undefined): void {
        const formGroup = this.fb.group({
            fileType: [fileType, Validators.required],
            file: [null, Validators.required]
        });
        const formArray = this.baseForm.get('files') as FormArray;
        formArray.push(formGroup);
    }

    public get requestingUtilityAssistance(): boolean {
        const assistanceRequestDescription = this.baseForm.get('assistanceApplicationAreasOfNeed') as FormGroup;
        return assistanceRequestDescription.get('electric').value ||
            assistanceRequestDescription.get('gasHeat').value ||
            assistanceRequestDescription.get('water').value ||
            assistanceRequestDescription.get('sewer').value;
    }

    public get requestingChildcareAssistance(): boolean {
        const assistanceRequestDescription = this.baseForm.get('assistanceApplicationAreasOfNeed') as FormGroup;
        return assistanceRequestDescription.get('childcareExpenses').value;
    }

    public get requestingCarRepairAssistance(): boolean {
        const assistanceRequestDescription = this.baseForm.get('assistanceApplicationAreasOfNeed') as FormGroup;
        return assistanceRequestDescription.get('carRepair').value;
    }

    public get requestingEvictionAssistance(): boolean {
        const assistanceRequestDescription = this.baseForm.get('assistanceApplicationAreasOfNeed') as FormGroup;
        return assistanceRequestDescription.get('evictionNotification').value;
    }

    public get requestingRentAssistance(): boolean {
        const assistanceRequestDescription = this.baseForm.get('assistanceApplicationAreasOfNeed') as FormGroup;
        return assistanceRequestDescription.get('rent').value;
    }

    getFileType(fileType): string {
        return AssistanceApplicationFileTypeOptions.find(x => x.value === fileType).label;
    }

    private updateUtilityBillLabelOnUtilityAssistanceRequestChange(): void {
        this.utilityBillLabel = 'Utility Bill';
        const formGroup = this.baseForm.get('assistanceApplicationAreasOfNeed') as FormGroup;

        formGroup.valueChanges.subscribe(value => {
            const utilityBillLabel = getUtilityLabelFromAssistanceApplicationAreasOfNeed(value);
            this.updateUtilityBillOptionLabel(utilityBillLabel);
            this.utilityBillLabel = utilityBillLabel;
        });
    }

    private updateUtilityBillOptionLabel(label: string): void {
        this.docOptions = this.docOptions.map(option => {
            if (option.value === AssistanceApplicationFileType.UtilityBill) {
                return {
                    ...option,
                    label
                };
            }
            return option;
        });
    }
}
