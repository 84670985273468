import {HttpClientModule} from '@angular/common/http';
import {FileInputAccessorModule} from 'file-input-accessor';
import {AppComponent} from './app.component';
import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {BrowserModule} from '@angular/platform-browser';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {APP_BASE_PROVIDERS, routes} from './routes';
import {AboutComponent} from './components/shared/about/AboutComponent';
import {PromptComponent} from './components/shared/dialogs/PromptComponent';
import {ErrorComponent} from './components/shared/error/ErrorComponent';
import {ForgotPasswordComponent} from './components/shared/forgotpassword/ForgotPasswordComponent';
import {LoginComponent} from './components/shared/login/LoginComponent';
import {NavbarComponent} from './components/shared/navbar/NavbarComponent';
import {ResetPasswordComponent} from './components/shared/resetpassword/ResetPasswordComponent';
import {ShellComponent} from './components/shared/shell/ShellComponent';
import {SidebarComponent} from './components/shared/sidebar/SidebarComponent';
import {TableComponent} from './components/shared/table/TableComponent';
import {UserTypeDropdown} from './components/shared/usertypedropdown/UserTypeDropdown';
import {DropdownComponent} from './utilities/DropdownComponent';
import {VisibleDirective} from './directives/VisibleDirective';
import {AgencyListComponent} from './components/energyassistance/agency/AgencyListComponent';
import {AgencyComponent} from './components/energyassistance/agency/AgencyComponent';
import {AllocationComponent} from './components/energyassistance/allocation/AllocationComponent';
import {ApplicationListComponent} from './components/energyassistance/application/ApplicationListComponent';
import {ClientListComponent} from './components/energyassistance/client/ClientListComponent';
import {ClientTabsComponent} from './components/energyassistance/client/ClientTabsComponent';
import {ClientComponent} from './components/energyassistance/client/manage/ClientComponent';
import {ClientCreateComponent} from './components/energyassistance/client/ClientCreateComponent';
import {ClientFormComponent} from './components/energyassistance/client/ClientFormComponent';
import {ClientEditComponent} from './components/energyassistance/client/manage/ClientEditComponent';
import {AgencyListModalComponent} from './components/energyassistance/agency/AgencyListModalComponent';
import {AllocationListComponent} from './components/energyassistance/allocation/AllocationListComponent';
import {CompanyListComponent} from './components/energyassistance/company/CompanyListComponent';
import {CompanyComponent} from './components/energyassistance/company/CompanyComponent';
import {CompanyListModalComponent} from './components/energyassistance/company/CompanyListModalComponent';
import {DashboardComponent} from './components/energyassistance/dashboard/DashboardComponent';
import {LookUpComponent} from './components/energyassistance/lookup/LookUpComponent';
import {PledgeListComponent} from './components/energyassistance/pledge/PledgeListComponent';
import {PledgeComponent} from './components/energyassistance/pledge/PledgeComponent';
import {PledgeConfirmationComponent} from './components/energyassistance/pledge/PledgeConfirmationComponent';
import {PledgeTypeListComponent} from './components/energyassistance/pledgetype/PledgeTypeListComponent';
import {PledgeTypeComponent} from './components/energyassistance/pledgetype/PledgeTypeComponent';
import {PaymentProcessingComponent} from './components/energyassistance/paymentprocessing/PaymentProcessingComponent';
import {
    CompanyMailingLabelComponent
} from './components/energyassistance/paymentprocessing/companymailinglabel/CompanyMailingLabelComponent';
import {ConfirmedToPendingComponent} from './components/energyassistance/paymentprocessing/confirmedtopending/ConfirmedToPendingComponent';
import {
    PendingPledgesDetailComponent
} from './components/energyassistance/reports/pledges/pendingpledgesdetail/PendingPledgesDetailComponent';
import {
    PendingPledgesSummaryComponent
} from './components/energyassistance/paymentprocessing/pendingpledgessummary/PendingPledgesSummaryComponent';
import {PendingToPaidComponent} from './components/energyassistance/paymentprocessing/pendingtopaid/PendingToPaidComponent';
import {UserListComponent} from './components/energyassistance/user/UserListComponent';
import {UserEmailComponent} from './components/energyassistance/user/UserEmailComponent';
import {UserCreateComponent} from './components/energyassistance/user/usercreate/UserCreateComponent';
import {UserFormComponent} from './components/energyassistance/user/userform/UserFormComponent';
import {ClientAssistanceComponent} from './components/energyassistance/client/manage/ClientAssistanceComponent';
import {ClientEfsComponent} from './components/energyassistance/client/manage/ClientEfsComponent';
import {ClientEfsConfirmationComponent} from './components/energyassistance/client/manage/ClientEfsConfirmationComponent';
import {PledgeReportForm} from './components/energyassistance/reports/PledgeReportForm';
import {PledgeTypeDropdown} from './components/shared/pledgetypedropdown/PledgeTypeDropdown';
import {EfsPledgeTypeDropdown} from './components/shared/efspledgetypedropdown/EfsPledgeTypeDropdown';
import {PlegeStatusTypeDropdown} from './components/shared/pledgestatusdropdown/PledgeStatusDropdown';
import {DatePickerComponent} from './components/shared/datepicker/DatePickerComponent';
import {OutOfBoundsDirective} from './directives/OutOfBoundsDirective';
import {PledgeByZipCodeReportComponent} from './components/energyassistance/reports/demographic/byzipcode/PledgeByZipCodeReportComponent';
import {ReportTableComponent} from './components/energyassistance/reports/shared/ReportTableComponent';
import {PledgeDemographicComponent} from './components/energyassistance/reports/demographic/PledgeDemographicComponent';
import {AgencyDetailReportComponent} from './components/energyassistance/reports/pledges/agencypledgedetail/AgencyDetailReportComponent';
import {PledgesReportComponent} from './components/energyassistance/reports/pledges/PledgesReportComponent';
import {PledgeDetailReportComponent} from './components/energyassistance/reports/demographic/detail/PledgeDetailReportComponent';
import {PledgeSummaryReportComponent} from './components/energyassistance/reports/demographic/summary/PledgeSummaryReportComponent';
import {PledgeByCountyComponent} from './components/energyassistance/reports/demographic/bycounty/PledgeByCountyComponent';
import {
    AllocationAndBalancesComponent
} from './components/energyassistance/reports/pledges/allocationandbalances/AllocationAndBalancesComponent';
import {AgencySummaryReportComponent} from './components/energyassistance/reports/pledges/agencypledgesummary/AgencySummaryReportComponent';
import {KeepingCurrentReportComponent} from './components/energyassistance/reports/keepingcurrent/KeepingCurrentReportComponent';
import {
    ApprovedEnrollmentsReportComponent
} from './components/energyassistance/reports/keepingcurrent/approvedenrollments/ApprovedEnrollmentsReportComponent';
import {
    MissedPaymentsReportComponent
} from './components/energyassistance/reports/keepingcurrent/missedpayments/MissedPaymentsReportComponent';
import {OrphansReportComponent} from './components/energyassistance/reports/keepingcurrent/confirmationorphans/OrphansReportComponent';
import {
    EnrollmentStatusReportComponent
} from './components/energyassistance/reports/keepingcurrent/enrollmentstatus/EnrollmentStatusReportComponent';
import {
    KeepingCurrentReportFormComponent
} from './components/energyassistance/reports/keepingcurrent/form/KeepingCurrentReportFormComponent';
import {KeepingCurrentStatuses} from './components/shared/kcstatusesdropdown/KeepingCurrentStatusesDropdown';
import {ChangePasswordModalComponent} from './components/shared/changepassword/ChangePasswordModalComponent';
import {ChangePasswordComponent} from './components/shared/changepassword/ChangePasswordComponent';
import {PaymentProcessingFormComponent} from './components/energyassistance/paymentprocessing/PaymentProcessingFormComponent';
import {ShiftPledgeReportSummaryTable} from './components/energyassistance/paymentprocessing/tables/ShiftPledgeReportSummaryTable';
import {PagedReportTableComponent} from './components/energyassistance/reports/shared/PagedReportTableComponent';
import {DatePipe} from './pipes/DatePipe';
import {GenderRadioComponent} from './components/shared/genderradio/GenderRadioComponent';
import {AgencyInputComponent} from './components/energyassistance/agency/AgencyInputComponent';
import {CompanyInputComponent} from './components/energyassistance/company/CompanyInputComponent';
import {ClientInfoComponent} from './components/energyassistance/client/clientinfo/ClientInfoComponent';
import {ClientVerificationComponent} from './components/energyassistance/client/application/step1/ClientVerificationComponent';
import {ClientEnrollmentComponent} from './components/energyassistance/client/application/step2/ClientEnrollmentComponent';
import {ClientCreateApplicationComponent} from './components/energyassistance/client/application/ClientCreateApplicationComponent';
import {ClientEnrollmentFormComponent} from './components/energyassistance/client/ClientEnrollmentFormComponent';
import {SupportComponent} from './components/energyassistance/support/SupportComponent';
import {SplitCamelCasePipe} from './pipes/SplitCamelCasePipe';
import {RequestEnrollmentFormComponent} from './components/energyassistance/client/RequestEnrollmentFormComponent';
import {RequestClientEnrollmentComponent} from './components/energyassistance/client/application/step3/RequestClientEnrollmentComponent';
import {ClientNoteTableComponent} from './components/energyassistance/client/ClientNoteTable';
import {ClientEditApplicationComponent} from './components/energyassistance/client/application/ClientEditApplicationComponent';
import {ClientApplicationsComponent} from './components/energyassistance/client/application/ClientApplicationsComponent';
import {AdvancedClientEnrollmentInfoComponent} from './components/energyassistance/client/AdvancedClientEnrollmentInfoComponent';
import {ImportantCustomerInformationComponent} from './components/energyassistance/client/ImportantCustomerInformationComponent';
import {UrlPipe} from './pipes/UrlPipe';
import {ClientsComponent} from './components/energyassistance/client/ClientsComponent';
import {ExcludePipe} from './pipes/ExcludePipe';
import {UploadComponent} from './components/shared/upload/upload.component';
import {CancelButtonComponent} from './components/shared/genericbuttons/ButtonComponent';
import {CheckboxArrayAccessor} from './directives/CheckboxArrayAccessor';
import {EfsReportFormComponent} from './components/energyassistance/reports/efs/form/EfsReportFormComponent';
import {EfsReportComponent} from './components/energyassistance/reports/efs/efsreport/EfsReportComponent';
import {EfsMainReportComponent} from './components/energyassistance/reports/efs/EfsMainReportComponent';
import {UniqByPipe} from './pipes/UniqByPipe';
import {ParseNumberPipe} from './pipes/ParseNumberPipe';
import {PledgeTypeCheckbox} from './components/shared/pledgetypecheckbox/PledgeTypeCheckbox';
import {EfnListComponent} from './components/energyassistance/efn/EfnListComponent';
import {ClientEfnComponent} from './components/energyassistance/client/manage/ClientEfnComponent';
import {EditEfnCaseComponent} from './components/energyassistance/client/manage/efncase/editcase/EditEfnCaseComponent';
import {CreateEfnCaseComponent} from './components/energyassistance/client/manage/efncase/createcase/CreateEfnCaseComponent';
import {EfnCommitComponent} from './components/energyassistance/client/manage/efncase/commit/EfnCommitComponent';
import {EfnCaseFormComponent} from './components/energyassistance/client/manage/efncase/form/EfnCaseFormComponent';
import {
    FieldLayoutComponent,
    GroupLayoutComponent
} from './components/energyassistance/client/manage/efncase/form/FormLayout';
import {EfnCaseStatusComponent} from './components/energyassistance/client/manage/efncase/form/EfnCaseStatusComponent';
import {EfnTabsComponent} from './components/energyassistance/client/manage/efncase/tabs/EfnTabsComponent';
import {EfnFilesTabComponent} from './components/energyassistance/client/manage/efncase/tabs/files/EfnFilesTabComponent';
import {EfnCommentsTabComponent} from './components/energyassistance/client/manage/efncase/tabs/comments/EfnCommentsTabComponent';
import {EfnAgenciesTabComponent} from './components/energyassistance/client/manage/efncase/tabs/agencies/EfnAgenciesTabComponent';
import {EfnInvitesTabComponent} from './components/energyassistance/client/manage/efncase/tabs/invites/EfnInvitesTabComponent';
import {MassAssignPledgeTypeComponent} from './components/energyassistance/pledgetype/MassAssignPledgeTypeComponent';
import {UwdaPledgeListComponent} from './components/energyassistance/uwdapledge/UwdaPledgeListComponent';
import {Pledge211Component} from './components/energyassistance/pledge/Pledge211Component';
import {AutoDisableDirective} from './directives/AutoDisableDirective';
import {HasDangerDirective} from './directives/HasDangerDirective';
import {EfnZoneCheckbox} from './components/shared/efnzonecheckbox/EfnZoneCheckbox';
import {SupportAgreementComponent} from './components/energyassistance/support/agreements/SupportAgreementComponent';
import {ClientSsnRevealComponent} from './components/shared/clientSsnReveal/ClientSsnRevealComponent';
import {
    ManualPendingToPaidComponent
} from './components/energyassistance/paymentprocessing/manualpendingtopaid/ManualPendingToPaidComponent';
import {EfnCaseComponent} from './components/energyassistance/client/manage/efncase/EfnCaseComponent';
import {RecruitStatsWidgetComponent} from './components/energyassistance/widgets/RecruitStatsWidgetComponent';
import {TestWidgetComponent} from './components/energyassistance/widgets/TestWidgetComponent';
import {MessageBoxComponent} from './components/shared/dialogs/MessageBoxComponent';
import {FooterComponent} from './components/shared/footer/FooterComponent';
import {NotificationLoadDirective} from './components/shared/notifications/NotificationLoadDirective';
import {NotificationComponent} from './components/shared/notifications/NotificationComponent';
import {PhonePipe} from './pipes/PhonePipe';
import {PaginationModule} from 'ngx-bootstrap/pagination';
import {ButtonsModule} from 'ngx-bootstrap/buttons';
import {TooltipModule} from 'ngx-bootstrap/tooltip';
import {StoreModule} from './store';
import {IMaskDirective} from 'angular-imask';
import {BsDatepickerModule} from 'ngx-bootstrap/datepicker';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {AgenciesCheckbox} from './components/shared/agenciescheckbox/AgenciesCheckbox';
import {CommonModule} from '@angular/common';
import {ProgressbarModule} from 'ngx-bootstrap/progressbar';
import {SharedModule} from './shared/shared.module';
import { ApplicationCountyListComponent } from './components/energyassistance/application-county/application-county-list/application-county-list.component';
import { ManageApplicationCountyComponent } from './components/energyassistance/application-county/manage-application-county/manage-application-county.component';
import {
    AssistanceApplicationSsnRevealComponent
} from './components/shared/assistanceApplicationSsnReveal/assistance-application-ssn-reveal.component';
import { ClientAssistanceApplicationComponent } from './components/energyassistance/client/manage/ClientAssistanceApplicationComponent';
import {OldModalModule} from './utilities/old-modal/old-modal.module';
import {SweetAlert2Module} from '@sweetalert2/ngx-sweetalert2';
import { AssistanceApplicationComponent } from './components/energyassistance/client/manage/assistance-application/assistance-application.component';
import { CreateAssistanceApplicationComponent } from './components/energyassistance/client/manage/assistance-application/create-assistance-application/create-assistance-application.component';
import { EditAssistanceApplicationComponent } from './components/energyassistance/client/manage/assistance-application/edit-assistance-application/edit-assistance-application.component';
import { AssistanceApplicationFormComponent } from './components/energyassistance/client/manage/assistance-application/assistance-application-form/assistance-application-form.component';
import { AssistanceApplicationTabsComponent } from './components/energyassistance/client/manage/assistance-application/assistance-application-tabs/assistance-application-tabs.component';
import {
    AssistanceApplicationHouseholdInformationFormComponent
} from './components/energyassistance/client/manage/assistance-application/assistance-application-form/household-information-form/assistance-application-household-information-form.component';
import { AssistanceApplicationCommentsComponent } from './components/energyassistance/client/manage/assistance-application/assistance-application-tabs/comments/assistance-application-comments.component';
import { AssistanceApplicationFilesComponent } from './components/energyassistance/client/manage/assistance-application/assistance-application-tabs/files/assistance-application-files.component';
import { AssistanceApplicationStatusComponent } from './components/energyassistance/client/manage/assistance-application/assistance-application-form/assistance-application-status.component';
import {
    AssistanceApplicationFormFieldLayoutComponent,
    AssistanceApplicationFormLayoutComponent
} from './components/energyassistance/client/manage/assistance-application/assistance-application-form/assistance-application-form-layout';
import { ManageAuditStateComponent } from './components/energyassistance/client/manage/assistance-application/manage-audit-state/manage-audit-state.component';
import { AuditStateListComponent } from './components/energyassistance/client/manage/assistance-application/activity-audit-tabs/audit-state-list/audit-state-list.component';
import {
    AssistanceApplicationActivityAuditComponent
} from './components/energyassistance/client/manage/assistance-application/activity-audit-tabs/assistance-application-activity-audit/assistance-application-activity-audit.component';
import { ActivityAuditTabsComponent } from './components/energyassistance/client/manage/assistance-application/activity-audit-tabs/activity-audit-tabs.component';
import { ExceedsCategoryAmountThresholdComponent } from './components/energyassistance/client/manage/assistance-application/exceeds-category-amount-threshold/exceeds-category-amount-threshold.component';
import {
    AssistanceApplicationDocumentUploadComponent
} from './components/energyassistance/client/manage/assistance-application/assistance-application-document-upload/assistance-application-document-upload.component';
import { CountySpendingComponent } from './components/energyassistance/reports/county-spending/county-spending.component';
import {AuditReportFiltersComponent} from './components/energyassistance/audit-report/audit-report-filters/audit-report-filters.component';
import {AuditReportTableComponent} from './components/energyassistance/audit-report/audit-report-table/audit-report-table.component';
import {AuditReportComponent} from './components/energyassistance/audit-report/audit-report.component';
import {
    AssistanceApplicationsListComponent
} from './components/energyassistance/assistance-applications/AssistanceApplicationsListComponent';
import { PledgeGenerateBulkPaymentReportComponent } from './components/energyassistance/reports/pledge-bulk-payment/pledge-bulk-payment-report/pledge-generate-bulk-payment-report.component';
import { PledgeBulkPaymentComponent } from './components/energyassistance/reports/pledge-bulk-payment/pledge-bulk-payment.component';
import { AuditProgressReportComponent } from './components/energyassistance/reports/audit-progress-report/audit-progress-report.component';
import { AuditProgressReportTableComponent } from './components/energyassistance/reports/audit-progress-report/audit-progress-report-table/audit-progress-report-table.component';
import { PledgeBulkPaymentSelectBatchComponent } from './components/energyassistance/reports/pledge-bulk-payment/pledge-bulk-payment-select-batch/pledge-bulk-payment-select-batch.component';
import {ViewBatchComponent} from './components/energyassistance/reports/pledge-bulk-payment/view-batch/view-batch.component';
import {
    BudgetBillingReportComponent
} from './components/energyassistance/reports/keepingcurrent/budgetbilling/BudgetBillingReportComponent';
import {CompletionReportComponent} from './components/energyassistance/reports/keepingcurrent/completion/CompletionReportComponent';
import { KeepingCurrentAmountOwedCalculatorComponent } from './components/energyassistance/client/keeping-current-amount-owed-calculator/keeping-current-amount-owed-calculator.component';

@NgModule({
    declarations: [
        LoginComponent,
        ForgotPasswordComponent,
        ShellComponent,
        AboutComponent,
        DashboardComponent,
        ErrorComponent,
        ResetPasswordComponent,
        ClientsComponent,
        ClientListComponent,
        ClientTabsComponent,
        ClientComponent,
        ClientCreateComponent,
        ClientEditComponent,
        ClientFormComponent,
        ClientAssistanceComponent,
        ClientEfsComponent,
        ClientEfsConfirmationComponent,
        ClientCreateApplicationComponent,
        PledgeListComponent,
        PledgeComponent,
        Pledge211Component,
        UwdaPledgeListComponent,
        AutoDisableDirective,
        Pledge211Component,
        UwdaPledgeListComponent,
        AgencyListComponent,
        AgencyComponent,
        AgencyListModalComponent,
        AllocationListComponent,
        AllocationComponent,
        CompanyListComponent,
        CompanyComponent,
        CompanyListModalComponent,
        AgencyInputComponent,
        CompanyInputComponent,
        SidebarComponent,
        VisibleDirective,
        SupportAgreementComponent,
        TableComponent,
        NavbarComponent,
        UrlPipe,
        MassAssignPledgeTypeComponent,
        UploadComponent,
        CancelButtonComponent,
        EfnCaseComponent,
        RecruitStatsWidgetComponent,
        TestWidgetComponent,
        MessageBoxComponent,
        FooterComponent,
        NotificationLoadDirective,
        NotificationComponent,
        PhonePipe,
        UploadComponent,
        CancelButtonComponent,
        PledgeConfirmationComponent,
        PledgeTypeListComponent,
        PledgeTypeComponent,
        UserListComponent,
        UserEmailComponent,
        UserCreateComponent,
        UserTypeDropdown,
        RequestEnrollmentFormComponent,
        RequestClientEnrollmentComponent,
        UserFormComponent,
        DropdownComponent,
        ClientEnrollmentComponent,
        ClientEnrollmentFormComponent,
        ApplicationListComponent,
        LookUpComponent,
        PaymentProcessingComponent,
        PendingToPaidComponent,
        ManualPendingToPaidComponent,
        PendingPledgesDetailComponent,
        PendingPledgesSummaryComponent,
        ConfirmedToPendingComponent,
        PledgeByZipCodeReportComponent,
        PledgeDemographicComponent,
        ReportTableComponent,
        EfnListComponent,
        AssistanceApplicationsListComponent,
        ClientEfnComponent,
        PledgeDetailReportComponent,
        PledgeSummaryReportComponent,
        CompanyMailingLabelComponent,
        CheckboxArrayAccessor,
        PromptComponent,
        KeepingCurrentReportComponent,
        AppComponent,
        PlegeStatusTypeDropdown,
        ChangePasswordModalComponent,
        ChangePasswordComponent,
        AgencyDetailReportComponent,
        AllocationAndBalancesComponent,
        AgencySummaryReportComponent,
        GenderRadioComponent,
        DatePipe,
        EfnCaseStatusComponent,
        PledgesReportComponent,
        EnrollmentStatusReportComponent,
        FieldLayoutComponent,
        GroupLayoutComponent,
        AssistanceApplicationFormLayoutComponent,
        KeepingCurrentReportFormComponent,
        KeepingCurrentStatuses,
        ClientNoteTableComponent,
        ClientEditApplicationComponent,
        PledgeTypeCheckbox,
        AdvancedClientEnrollmentInfoComponent,
        PledgeByCountyComponent,
        ExcludePipe,
        DatePickerComponent,
        ApprovedEnrollmentsReportComponent,
        BudgetBillingReportComponent,
        CompletionReportComponent,
        MissedPaymentsReportComponent,
        ClientInfoComponent,
        ClientVerificationComponent,
        OrphansReportComponent,
        OutOfBoundsDirective,
        ParseNumberPipe,
        PledgeReportForm,
        SplitCamelCasePipe,
        UniqByPipe,
        // EnumPipe,
        PaymentProcessingFormComponent,
        ShiftPledgeReportSummaryTable,
        PledgeTypeDropdown,
        EfsPledgeTypeDropdown,
        PagedReportTableComponent,
        ClientApplicationsComponent,
        ImportantCustomerInformationComponent,
        ClientSsnRevealComponent,
        AssistanceApplicationSsnRevealComponent,
        SupportComponent,
        HasDangerDirective,
        EfsReportFormComponent,
        EfsReportComponent,
        EditEfnCaseComponent,
        CreateEfnCaseComponent,
        EfnCommitComponent,
        EfnCaseFormComponent,
        EfsMainReportComponent,
        EfnTabsComponent,
        EfnFilesTabComponent,
        EfnCommentsTabComponent,
        EfnAgenciesTabComponent,
        EfnInvitesTabComponent,
        EfnZoneCheckbox,
        AgenciesCheckbox,
        ApplicationCountyListComponent,
        ManageApplicationCountyComponent,
        AssistanceApplicationSsnRevealComponent,
        ClientAssistanceApplicationComponent,
        AssistanceApplicationActivityAuditComponent,
        AssistanceApplicationComponent,
        CreateAssistanceApplicationComponent,
        EditAssistanceApplicationComponent,
        AssistanceApplicationFormComponent,
        AssistanceApplicationTabsComponent,
        AssistanceApplicationHouseholdInformationFormComponent,
        AssistanceApplicationCommentsComponent,
        AssistanceApplicationFilesComponent,
        AssistanceApplicationStatusComponent,
        AssistanceApplicationFormFieldLayoutComponent,
        AssistanceApplicationSsnRevealComponent,
        ManageAuditStateComponent,
        AuditStateListComponent,
        AssistanceApplicationFormFieldLayoutComponent,
        ActivityAuditTabsComponent,
        ExceedsCategoryAmountThresholdComponent,
        AssistanceApplicationDocumentUploadComponent,
        AssistanceApplicationDocumentUploadComponent,
        CountySpendingComponent,
        AssistanceApplicationDocumentUploadComponent,
        AuditReportComponent,
        AuditReportFiltersComponent,
        AuditReportTableComponent,
        PledgeGenerateBulkPaymentReportComponent,
        PledgeBulkPaymentComponent,
        AuditProgressReportComponent,
        AuditProgressReportTableComponent,
        PledgeBulkPaymentSelectBatchComponent,
        ViewBatchComponent,
        KeepingCurrentAmountOwedCalculatorComponent
    ],
    imports: [
        CommonModule,
        BrowserModule,
        RouterModule.forRoot(routes, {enableTracing: false}),
        PaginationModule.forRoot(),
        FormsModule,
        FileInputAccessorModule,
        HttpClientModule,
        ReactiveFormsModule,
        ButtonsModule,
        TooltipModule,
        PaginationModule,
        // ResponsiveConfig,
        StoreModule,
        IMaskDirective,
        BsDatepickerModule.forRoot(),
        BrowserAnimationsModule,
        ProgressbarModule,
        SharedModule,
        OldModalModule,
        SweetAlert2Module.forRoot()
    ],
    bootstrap: [
        AppComponent
    ],
    providers: [
        ...APP_BASE_PROVIDERS
    ]
})
export class AppModule {
}

