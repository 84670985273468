import {Component, OnInit, ViewChild} from '@angular/core';
import * as moment from 'moment/moment';
import {FormControl, UntypedFormBuilder} from '@angular/forms';
import {CompanyListModalComponent} from '../../../company/CompanyListModalComponent';
import {
    PledgeBulkPaymentReportClient,
    PledgeBulkPaymentBatchParameters,
} from '../../../../../services/api.client';
import {NotificationService} from '../../../../../services/NotificationService';
import {BehaviorSubject} from 'rxjs';
import {Disposable} from '../../../../../utilities/Disposable';
import {takeUntil} from 'rxjs/operators';
import {Validators} from '../../../../../utilities/Validators';
import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';

(pdfMake as any).vfs = pdfFonts.pdfMake.vfs;

@Component({
    selector: 'app-pledge-bulk-payment-report',
    templateUrl: './pledge-generate-bulk-payment-report.component.html',
    styleUrls: ['./pledge-generate-bulk-payment-report.component.scss']
})
export class PledgeGenerateBulkPaymentReportComponent extends Disposable implements OnInit {
    public form = this.fb.group({
        startDate: [moment()
            .startOf('year')
            .format('MM/DD/YYYY')
            , Validators.compose([Validators.isDateValid])],
        endDate: [moment()
            .endOf('day')
            .format('MM/DD/YYYY'),
            Validators.compose([Validators.isDateValid])],
        company: '',
        pledgeTypes: [],
    });

    public startDate = this.form.get('startDate') as FormControl;
    public endDate = this.form.get('endDate') as FormControl;
    public companyControl = this.form.get('company') as FormControl;
    public pledgeTypes = this.form.get('pledgeTypes') as FormControl;

    private pledgeCountSubject = new BehaviorSubject<number>(0);
    public pledgeCount$ = this.pledgeCountSubject.asObservable();
    public loading = true;

    @ViewChild(CompanyListModalComponent)
    companyListModal: CompanyListModalComponent;

    constructor(private fb: UntypedFormBuilder,
                private pledgeReportClient: PledgeBulkPaymentReportClient,
                private _notificationService: NotificationService) {
        super();
    }

    ngOnInit(): void {
        this.loading = true;

        const form = this.form.value;
        const filters = PledgeBulkPaymentBatchParameters.fromJS({
            startDate: form.startDate,
            endDate: form.endDate,
            pledgeTypes: form.pledgeTypes ? [form.pledgeTypes] : [],
            companies: this.isNotEmpty(form.company) ? [form.company] : []
        });
        const getPledgeCount$ = this.pledgeReportClient.getPledgeCount(filters);

        getPledgeCount$.subscribe(
            pledgeCount => {
                this.pledgeCountSubject.next(pledgeCount);
                this.loading = false;
            },
            error => {
                this.loading = false;
                this._notificationService.showError('An error occurred. Please try again later.');
            }
        );

        this.onFormChanges();
    }

    private onFormChanges(): void {
        this.form.valueChanges.pipe(takeUntil(this.$destroyed)).subscribe(x => {
            if (this.startDate.invalid || this.endDate.invalid) {
                return;
            }
            const form = this.form.value;
            const filters = PledgeBulkPaymentBatchParameters.fromJS({
                startDate: form.startDate,
                endDate: form.endDate,
                pledgeTypes: form.pledgeTypes ? form.pledgeTypes : [],
                companies: this.isNotEmpty(form.company) ? [form.company] : []
            });
            this.pledgeReportClient.getPledgeCount(filters).subscribe(count => {
                this.pledgeCountSubject.next(count);
            }, err => {
                this._notificationService.showError('An error occurred while retrieving pledge count. Please refresh page and try again.');
            });
        });
    }

    public createBatch(): void {
        this.loading = true;
        const form = this.form.value;
        const filters = PledgeBulkPaymentBatchParameters.fromJS({
            startDate: form.startDate,
            endDate: form.endDate,
            pledgeTypes: form.pledgeTypes ? form.pledgeTypes : [],
            companies: this.isNotEmpty(form.company) ? [form.company] : []
        });

        this.pledgeReportClient.createBulkPaymentBatch(filters).subscribe(
            response => {
                this._notificationService.showSuccess(`Bulk payment batch ${response.batchId} with ${response.pledgeCount} pledges for $${response.totalAmount.toFixed(2)} created successfully.`);
                this.resetForm();
                this.loading = false;
            },
            err => {
                this.loading = false;
                this._notificationService.showError(`An error occurred while creating the batch ${err.value}. Please try again.`);
            }
        );
    }

    private resetForm(): void {
        this.form.patchValue({
            startDate: moment()
                .startOf('year')
                .format('MM/DD/YYYY'),
            endDate: moment()
                .endOf('day')
                .format('MM/DD/YYYY'),
            companies: '',
            pledgeTypes: [],
        });
    }

    private isNotEmpty(value: string): boolean {
        return !(value == null || (value.trim().length === 0));
    }

}
