import {Component, ViewChild, Input, EventEmitter, Output} from '@angular/core';
import {ModalComponent} from 'src/app/utilities/old-modal/components/modal';
import {Observable, Observer} from 'rxjs';
import 'rxjs';

@Component({
  selector: 'prompt-dialog',
  templateUrl: './PromptComponent.html',
  styleUrls: ['./PromptComponent.scss']
})

export class PromptComponent {

  @ViewChild(ModalComponent)
  modal: ModalComponent;

  @Input() titleText = '';
  @Input() showTitle = true;
  @Input() promptText = '';
  @Input() okButtonText = 'Ok';
  @Input() cancelButtonText = 'Cancel';
  @Input() size = '\'md\'';
  @Input() okButtonDisabled = false;
  @Input() cancelButtonDisabled = false;
  @Input() overrideFooter = false;
  @Input() allowCustomButton = false;
  @Input() customButtonText = 'Override';
  @Output() customButtonEvent = new EventEmitter<boolean>();

  private observer: Observer<boolean>;

  open(): Observable<boolean> {
    this.modal.open();

    return new Observable(obs => {
      this.observer = obs;
    });
  }

  close(): void {
    this.cancelClicked();
  }

  onCustomButton(): void {
    this.customButtonEvent.emit(true);
    this.modal.close();
  }

  okClicked(): void {
    this.modal.close();
    if (this.observer) {
      this.observer.next(true);
      this.observer.complete();
    }
  }

  cancelClicked(): void {
    this.modal.close();
    if (this.observer) {
      this.observer.next(false);
      this.observer.complete();
    }
  }
}
