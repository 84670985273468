import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormControl} from '@angular/forms';
import {Store} from '@ngrx/store';
import * as fromRoot from '../../../../store';
import {ApplicationCounty, PledgeType} from '../../../../services/api.client';
import {combineLatest, Observable} from 'rxjs';
import {
    selectApplicationCountyCurrentSelectedApplicationCounty,
    selectApplicationCountyFundAdministrativeAgencyFundTypes
} from '../../../../store/selectors/applicationCounty.selectors';
import {takeUntil} from 'rxjs/operators';
import {Disposable} from '../../../../utilities/Disposable';
import {ApplicationCountyService} from '../../../../services/applicationCounty.service';
import {ActivatedRoute, Router} from '@angular/router';
import {PledgeTypeService} from '../../../../services/PledgeTypeService';
import {Validators} from '../../../../utilities/Validators';

@Component({
    selector: 'app-manage-application-county',
    templateUrl: './manage-application-county.component.html',
    styleUrls: ['./manage-application-county.component.scss']
})
export class ManageApplicationCountyComponent extends Disposable implements OnInit {

    form = this._formBuilder.group({
        name: new FormControl({value: '', disabled: true}),
        isActiveInternally: new FormControl(false),
        isActiveExternally: new FormControl(false),
        selectedFundAdministrativePledgeType: new FormControl('', Validators.requiredWhen('isActiveExternally', true))
    });

    get countyNameControl(): FormControl {
        return this.form.get('name') as FormControl;
    }

    get isActiveInternalControl(): FormControl {
        return this.form.get('isActiveInternally') as FormControl;
    }

    get isActiveExternalControl(): FormControl {
        return this.form.get('isActiveExternally') as FormControl;
    }

    get selectedFundAdministrativePledgeTypeControl(): FormControl {
        return this.form.get('selectedFundAdministrativePledgeType') as FormControl;
    }

    get isActiveExternalValue(): boolean {
        return this.isActiveExternalControl && this.isActiveExternalControl.value || false;
    }

    constructor(
        private _formBuilder: FormBuilder,
        private _store: Store<fromRoot.AppState>,
        private _applicationCountyService: ApplicationCountyService,
        private _pledgeTypeService: PledgeTypeService,
        private _router: Router,
        private _route: ActivatedRoute,
    ) {
        super();
        this.currentSelectedApplicationCounty$ = this._store.select(selectApplicationCountyCurrentSelectedApplicationCounty);
        this.fundAdministrativePledgeTypes$ = this._store.select(selectApplicationCountyFundAdministrativeAgencyFundTypes);
    }

    currentSelectedApplicationCounty: ApplicationCounty;
    currentSelectedApplicationCounty$: Observable<ApplicationCounty>;
    fundAdministrativePledgeTypes: PledgeType[];
    fundAdministrativePledgeTypes$: Observable<PledgeType[]>;

    ngOnInit(): void {
        combineLatest([
            this._route.params,
            this._pledgeTypeService.retrieveFundAdministrativePledgeTypes()
        ]).pipe(
            takeUntil(this.$destroyed),
        ).subscribe(([params, fundAdministrativePledgeTypes]) => {
            this.fundAdministrativePledgeTypes = fundAdministrativePledgeTypes;
            if (params.id) {
                this.currentSelectedApplicationCounty$
                    .pipe(takeUntil(this.$destroyed))
                    .subscribe(currentSelectedApplicationCounty => {
                        this.currentSelectedApplicationCounty = currentSelectedApplicationCounty;

                        if (params.id === currentSelectedApplicationCounty?.id) {
                            this.form.patchValue({
                                name: currentSelectedApplicationCounty?.name,
                                isActiveInternally: currentSelectedApplicationCounty?.isActiveInternal || false,
                                isActiveExternally: currentSelectedApplicationCounty?.isActiveExternal || false,
                                selectedFundAdministrativePledgeType: currentSelectedApplicationCounty?.fundAdministrativePledgeTypeId || ''
                            });

                            if (currentSelectedApplicationCounty?.isActiveExternal &&
                                !currentSelectedApplicationCounty?.fundAdministrativePledgeTypeId) {
                                this.selectedFundAdministrativePledgeTypeControl?.markAsTouched();
                            }
                        } else {
                            this.getApplicationCountyById(params.id);
                        }
                    });
            } else {
                this.getApplicationCountyByIdAction();
            }
        });

        this.handleIsActiveExternalOnChange();
    }

    getApplicationCountyById(id: string): void {
        this._applicationCountyService.getApplicationCountyById(id)
            .pipe(takeUntil(this.$destroyed))
            .subscribe(() => {
                this.getApplicationCountyByIdAction();
            });
    }

    onSave(): void {
        if (!this.form.valid) {
            this.form.markAllAsTouched();
            return;
        }

        let fundAdministrativePledgeTypeId = this.selectedFundAdministrativePledgeTypeControl?.value || null;
        if (!this.isActiveExternalValue) {
            fundAdministrativePledgeTypeId = null;
        }

        const applicationCounty = ApplicationCounty.fromJS({
            id: this.currentSelectedApplicationCounty.id,
            name: this.countyNameControl?.value,
            isActiveInternal: this.isActiveInternalControl?.value,
            isActiveExternal: this.isActiveExternalControl?.value,
            fundAdministrativePledgeTypeId
        });
        this._applicationCountyService.updateApplicationCounty(applicationCounty)
            .subscribe(() => this._router.navigate(['app/counties']));
    }

    private getApplicationCountyByIdAction(): void {
        this.currentSelectedApplicationCounty$
            .pipe(takeUntil(this.$destroyed))
            .subscribe(currentSelectedApplicationCounty => {
                this.currentSelectedApplicationCounty = currentSelectedApplicationCounty;

                if (!currentSelectedApplicationCounty) {
                    this._router.navigate(['app/counties']);
                }

                this.form.patchValue({
                    name: currentSelectedApplicationCounty?.name,
                    isActiveInternally: currentSelectedApplicationCounty?.isActiveInternal || false,
                    isActiveExternally: currentSelectedApplicationCounty?.isActiveExternal || false
                });
            });
    }

    private handleIsActiveExternalOnChange(): void {
        this.isActiveExternalControl
            .valueChanges
            .pipe(takeUntil(this.$destroyed))
            .subscribe((active: boolean) => {
                if (!active) {
                    delete this.selectedFundAdministrativePledgeTypeControl?.errors?.requiredWhen;
                    this.selectedFundAdministrativePledgeTypeControl?.setValue('');
                }
            });
    }
}
