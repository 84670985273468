import {Component, ViewChild, Optional, OnInit} from '@angular/core';
import {Client, KcApplication} from '../../../../../services/api.client';
import {Observable} from 'rxjs';
import {KcApplicationService} from '../../../../../services/KcApplicationService';
import {NotificationService} from '../../../../../services/NotificationService';
import {RequestEnrollmentFormComponent} from '../../RequestEnrollmentFormComponent';
import {ClientCreateApplicationComponent} from '../ClientCreateApplicationComponent';
import {ActivatedRoute, Router} from '@angular/router';
import {select, Store} from '@ngrx/store';
import * as fromRoot from '../../../../../store';
import {selectClientDataCurrentClient} from '../../../../../store/selectors/client.selectors';
import {selectKcAppDataCurrentSelectedKcApplication} from '../../../../../store/selectors/kcApplication.selectors';

@Component({
  selector: 'requestClientEnrollment',
  templateUrl: './RequestClientEnrollmentComponent.html',
  styleUrls: ['./RequestClientEnrollmentComponent.scss']
})

export class RequestClientEnrollmentComponent implements OnInit {

  client: Client;
  client$: Observable<Client>;

  @ViewChild(RequestEnrollmentFormComponent)
  form: RequestEnrollmentFormComponent;

  application$: Observable<KcApplication>;
  application: KcApplication;

  constructor(@Optional() private parent: ClientCreateApplicationComponent,
              private route: ActivatedRoute,
              private router: Router,
              private kcApplicationService: KcApplicationService,
              private notificationService: NotificationService,
              private _store: Store<fromRoot.AppState>) {
    this.client$ = this._store.pipe(select(selectClientDataCurrentClient));
    this.application$ = this._store.pipe(select(selectKcAppDataCurrentSelectedKcApplication));
  }

  ngOnInit(): void {
    this.client$.subscribe(client => this.client = client);
    this.application$.subscribe(application => this.application = application);
    // this.application$ = this.client$
    //     .modelExists()
    //     .map(client => client.id)
    //     .flatMap(clientId => this.kcApplicationService.getNewKcApplicationsByClientId(clientId))
    //     .map((applications: KcApplication[]) => applications.length && applications[0] || null)
    //     .log('kcApllication')
    //     .share();
  }

  back(): void {
    if (this.parent) {
      this.parent.back();
    } else {
      // assume back button is parent
      this.router.navigate(['../'], {relativeTo: this.route}).then();
    }
  }


  submit(): void {
    this.form.submit()
      .subscribe((kcApplication: KcApplication) => {
        this.notificationService.showSuccess('Successfully created pledge');
        this.router.navigate(['/app/client', this.client.id, 'application', kcApplication.id]).then(() => {
          window.location.reload();
        });
      });
  }
}
