import {Pipe, PipeTransform} from '@angular/core';
import {Moment} from 'moment';
import * as moment from 'moment';

/**
 * LT   - 3:09 PM
 * LTS  - 3:09:16 PM
 * L    - 11/17/2016
 * l    - 11/17/2016
 * LL   - November 17, 2016
 * ll   - Nov 17, 2016
 * LLL  - November 17, 2016 3:09 PM
 * lll  - Nov 17, 2016 3:09 PM
 * LLLL - Thursday, November 17, 2016 3:09 PM
 * llll - Thu, Nov 17, 2016 3:09 PM
 */
@Pipe({name: 'moment'})
export class DatePipe implements PipeTransform {
  transform(value: string | Moment, format): string {
    if (!value) {
      return '';
    }
    return moment(value).format(format);
  }
}
