<div class="row">
  <label class="col-xs-12">{{labelText}}</label>
  <div class="col-xs-12">
    <label class="custom-file">
      <input type="file"
             id="file-upload"
             class="custom-file-input"
             [formControl]="fileInput">
      <span class="custom-file-control"></span>
    </label>
  </div>
</div>

