import {Component, OnInit} from '@angular/core';
import {Client} from '../../../../services/api.client';
import {EfnCase} from '../../../../services/api.client';
import {EfnCaseStatusType} from '../../../../services/api.client';
import {EfnService} from '../../../../services/EfnService';
import {splitCamelCase} from '../../../../utilities/Util';
import {Observable} from 'rxjs';
import {select, Store} from '@ngrx/store';
import * as fromRoot from '../../../../store';
import {selectClientDataCurrentClient} from '../../../../store/selectors/client.selectors';
import {selectEfnDataClientEfnCases} from '../../../../store/selectors/efn.selectors';
import {takeUntil} from 'rxjs/operators';
import {Disposable} from '../../../../utilities/Disposable';

@Component({
  templateUrl: './ClientEfnComponent.html',
  styleUrls: ['./ClientEfnComponent.scss']
})

export class ClientEfnComponent extends Disposable implements OnInit {
  currentClient: Client;
  currentClient$: Observable<Client>;

  clientsEfnCases: EfnCase[];
  clientsEfnCases$: Observable<EfnCase[]>;
  isClientCountyActive = true;
  invalidClientMessage: string;

  constructor(private efnService: EfnService, private _store: Store<fromRoot.AppState>) {
    super();
    this.currentClient$ = this._store.pipe(select(selectClientDataCurrentClient));
    this.clientsEfnCases$ = this._store.pipe(select(selectEfnDataClientEfnCases));
  }

  ngOnInit(): void {
    this.currentClient$.subscribe(currentClient => this.currentClient = currentClient);
    this.clientsEfnCases$.subscribe(clientEfnCases => this.clientsEfnCases = clientEfnCases);
    this.getClientsEfnCases(this.currentClient.id);
    this.efnService.checkIsCaseCountyActive(this.currentClient.county)
        .pipe(takeUntil(this.$destroyed))
        .subscribe((isActive: boolean) => this.isClientCountyActive = isActive || false);
  }

  isClientValid(): boolean {
    const isValid =
        !!this.currentClient?.birthYear ||
        !!this.currentClient?.gender ||
        !!this.currentClient?.employmentStatus ||
        !!this.currentClient?.houseHoldIncome ||
        !!this.currentClient?.houseHoldSize;

    if (!isValid) {
      this.invalidClientMessage = 'This Client Has Missing Info';
      return isValid;
    }

    if (!this.isClientCountyActive) {
      this.invalidClientMessage = `This Client's County is not accepting new cases.`;
    }

    return this.isClientCountyActive;
  }

  getClientsEfnCases(clientId: string): void {
    this.efnService.getEfnCasesByClientId(clientId);
  }

  getCaseStatus(status: string | number): string {
      if (typeof status === 'number') {
          return splitCamelCase(EfnCaseStatusType[status], true);
      }
      return splitCamelCase(status, true);
  }
}
