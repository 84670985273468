<h1 class="page-title">Audit Reports</h1>
<section class="widget">
    <div class="widget-body p-5">
        <div class="container-fluid">
            <div class="row">
                <div class="col-xs-12">
                </div>
            </div>
            <div class="row">
                <div class="col-xs-12">
                    <app-audit-report-filters (filters)="onFiltersChange($event)"></app-audit-report-filters>
                </div>
            </div>
            <div class="row">
                <div class="col-xs-12">
                    <div class="actions-wrapper">
                        <button type="button" class="btn btn-danger m-2" (click)="onResetFiltersClick()"><i
                                class="fa fa-times me-2"></i>&nbsp;Reset Filters
                        </button>
                        <div>
                            <button (click)="onExportClick()" [disabled]="!pagedData?.items.length"
                                    class="btn btn-primary">
                                <i class="fa fa-download me-2"></i>&nbsp;
                                Export to Excel
                            </button>
                            <button [disabled]="fetchingData || !filtersChanged" class="btn btn-primary m-2" (click)="onSearchClick()">
                                <i *ngIf="fetchingData" class="fa fa-spinner fa-spin me-2"></i>
                                <i *ngIf="!fetchingData" class="fa fa-search me-2"></i>
                                Search
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-xs-12">
                    <app-audit-report-table (pageChange)="onPageChange($event)"
                                            (limitChange)="onLimitChange($event)"
                                            [pagedData]="pagedData"></app-audit-report-table>
                </div>
            </div>
        </div>
    </div>
</section>
