import {filter} from 'rxjs/operators';
import {Component, Output, EventEmitter, ViewChild, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import {ITableHeader, TableComponent} from '../../shared/table/TableComponent';
import {PagedListOfKcApplication, SystemData, UserType} from '../../../services/api.client';
import {User} from '../../../services/api.client';
import {KcStatus} from '../../../services/api.client';
import {Agency} from '../../../services/api.client';

import {KcApplicationService} from '../../../services/KcApplicationService';
import {ClientService} from '../../../services/ClientService';
import {DropdownComponent, DropdownValue} from '../../../utilities/DropdownComponent';
import {AgencyListModalComponent} from '../agency/AgencyListModalComponent';
import {select, Store} from '@ngrx/store';
import * as fromRoot from '../../../store';
import {selectAgencyDataAgenciesItems} from '../../../store/selectors/agency.selectors';
import {selectKcAppDataKcApps, selectKcAppDataKcAppsTotalCount} from '../../../store/selectors/kcApplication.selectors';
import {selectSecurityStateDataCurrentUser, selectSecurityStateDataSystemDataKcStatuses} from '../../../store/selectors/security.selectors';

@Component({
  templateUrl: './ApplicationListComponent.html',
  styleUrls: ['./ApplicationListComponent.scss']
})

export class ApplicationListComponent implements OnInit {

  constructor(private _applicationService: KcApplicationService,
              private _clientService: ClientService,
              private _store: Store<fromRoot.AppState>) {
   this.currentUser$ = this._store.pipe(select(selectSecurityStateDataCurrentUser));
   this.kcStatuses$ = this._store.pipe(select(selectSecurityStateDataSystemDataKcStatuses));
   this.pagedApplications$ = this._store.pipe(select(selectKcAppDataKcApps));
   this.agencies$ = this._store.pipe(select(selectAgencyDataAgenciesItems));
   this.totalCount$ = this._store.pipe(select(selectKcAppDataKcAppsTotalCount));
  }
  isLoading: boolean;
  systemData: SystemData;
  selectedStatusId: string;
  selectedAgencyId: string;
  searchValueAccount = '';
  searchValueSSN = '';

  // subscription
  currentUser: User;
  currentUser$: Observable<User>;
  kcStatuses: KcStatus[];
  kcStatuses$: Observable<KcStatus[]>;
  pagedApplications: PagedListOfKcApplication;
  pagedApplications$: Observable<PagedListOfKcApplication>;
  agencies: Agency[];
  agencies$: Observable<Agency[]>;

  // sorting
  currentSortName = 'EnteredDate';
  currentSorting = true;

  tableHeaders: ITableHeader[] = [
    {text: 'Last Name', sortKey: 'Client|LastName'},
    {text: 'First Name', sortKey: 'Client|FirstName'},
    {text: 'Account #', sortKey: 'AccountNumber', class: 'hidden-sm-down'},
    {text: 'Creation Date', sortKey: 'EnteredDate', class: 'hidden-sm-down', ascending: false},
    {text: 'Edited Date', sortKey: 'ModifiedDate', class: 'hidden-sm-down'},
    {text: 'Status', sortKey: 'KeepingCurrentStatu|StatusName'},
  ];

  // pagination
  limit = 10;
  currentPage = 1;
  maxSize = 1;
  totalCount: number;
  totalCount$: Observable<number>;

  // Dropdowns
  statusesDropdown: DropdownValue[];
  @ViewChild('statusDropdown')
  statusDropdownComponent: DropdownComponent;

  @ViewChild(TableComponent)
  table: TableComponent;

  // MODAL
  @ViewChild(AgencyListModalComponent)
  agencyListModal: AgencyListModalComponent;
  @Output('onShowAgencyModal') _showAgencies = new EventEmitter();
  private agencyList: AgencyListModalComponent;

  // selected agency
  agency: Agency;
  selectedAgencyName = '-- Select Agency --';

  ngOnInit(): void {
    this.loadKcStatuses();

    this.currentUser$.subscribe(user => this.currentUser = user);
    this.kcStatuses$.subscribe(status => this.kcStatuses = status);
    this.pagedApplications$.subscribe(applications => this.pagedApplications = applications);
    this.agencies$.subscribe(agencies => this.agencies = agencies);
    this.totalCount$.subscribe(totalCount => this.totalCount = totalCount);

    if (this.currentUser.userType !== UserType.SystemAdmin
        && this.currentUser.userType !== UserType.FundAdmin) {// NOT SYS ADMIN OR FUND ADMIN
      this.selectedAgencyId = this.currentUser.agencyId;
      this.selectedAgencyName = this.currentUser.agency.agencyName;
    }
  }

  // Current Selected application
  setCurrentSelectedApplication(id: string): void {
    this._applicationService.getKcApplicationById(id);
  }

  // Current Selected Client
  setCurrentSelectedClient(clientId: string): void {
    this._clientService.getClientById(clientId);
  }

  sortApplications(options: ITableHeader): void {
    this.currentSortName = options.sortKey;
    this.currentSorting = !options.ascending;
    this.searchApplications();
  }

  setLimit(limit: number): void {// # of rows per page
    this.limit = limit;
    this.searchApplications();
  }

  setPage(page: number): void {
    setTimeout(() => {
      this.currentPage = page; // set current page
      this.searchApplications();
    });
  }

  // set selected Status ID
  currentStatus(status): void {
    this.selectedStatusId = status;
  }

  // dropdown - pledge types
  loadKcStatuses(): void {// get statuses
    // set Pledge Types drop-down
    this.kcStatuses$.pipe(
      filter(statuses => !!statuses))
      .subscribe(statuses => {
        this.statusesDropdown = [];
        for (const status of statuses) {
          this.statusesDropdown.push(new DropdownValue(status.id, status.statusName));
        }
      });
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      if (this.currentUser.userType === UserType.FundAdmin) {
        const pendingStatus = this.kcStatuses.find(s => s.isPendingApprovalStatus).id;
        this.statusDropdownComponent.initialValue = pendingStatus;
        this.selectedStatusId = pendingStatus;
      }
      this.searchApplications();

    });

  }

  // get Applications
  searchApplications(): void {
    this.table.request = this._applicationService.getApplications(
      this.searchValueAccount,
      this.searchValueSSN,
      this.selectedAgencyId,
      this.selectedStatusId,
      this.currentSortName,
      this.currentSorting,
      this.limit,
      (this.currentPage - 1) * this.limit
    );
  }

  // search value Account
  setSearchValueAccount(value): void {
    this.searchValueAccount = value;
  }

  // search value SSN
  setSearchValueSSN(value): void {
    this.searchValueSSN = value;
  }

  // format status
  formatStatus(statusId: any): string {
    if (this.kcStatuses) {
      for (const status of this.kcStatuses) {
        if (status.id === statusId) {
          return status.statusName;
        }
      }
      return 'missing status';
    }
  }

  // show Agency Modal
  showAgencyModal(): boolean {
    this.agencyListModal.open();
    return false;
  }

  onAgencySelected(selectedAgency): void {
    if (selectedAgency) {
      selectedAgency.agencyName ? this.selectedAgencyName = selectedAgency.agencyName : this.selectedAgencyName = '';
      this.selectedAgencyId = selectedAgency.id;
    } else {
      this.selectedAgencyName = '';
      this.selectedAgencyId = '';
    }
  }

  isDisabledAgency(): boolean {
    return this.currentUser.userType !== UserType.SystemAdmin && this.currentUser.userType !== UserType.FundAdmin;
  }
}
