<section class="widget">
  <header>
    <div class="row">
      <div class="col-xs-12">
        <h1>Manage User</h1>
      </div>
    </div>
  </header>
  <div class="widget-body">
    <form userForm #f novalidate>
      <div footer>
        <button class="btn btn-primary" [autoDisable]="f.submit">Save User</button>
        <cancel-button [route]="'/app/users'"></cancel-button>
      </div>
    </form>
  </div>
</section>
