export enum Race {
    AmericanIndian = 'American Indian',
    AsianAmerican = 'Asian American',
    Biracial = 'BiRacial',
    AfricanAmerican = 'Black',
    Jewish = 'Jewish',
    Hispanic = 'Hispanic',
    MiddleEastern = 'Middle Eastern',
    PacificIslander = 'Pacific Islander',
    SouthAsian = 'South Asian',
    EuropeanAmerican = 'White',
    Other = 'Other',
}
