import {map, filter} from 'rxjs/operators';
import {Component, OnInit, ViewChild} from '@angular/core';
import {PagedListOfProcessingReport, PledgeType} from '../../../../../services/api.client';
import {Company} from '../../../../../services/api.client';
import {ITableHeader} from '../../../../shared/table/TableComponent';
import {Observable} from 'rxjs';

import {formatCurrency, formatDate} from '../../../../../utilities/Util';
import {PaymentProcessingFormComponent} from '../../../paymentprocessing/PaymentProcessingFormComponent';
import {User} from '../../../../../services/api.client';
import {select, Store} from '@ngrx/store';
import * as fromRoot from '../../../../../store';
import {selectReportDataPendingPledgesDetailProcessingReport} from '../../../../../store/selectors/report.selectors';
import {selectSecurityStateDataCurrentUser} from '../../../../../store/selectors/security.selectors';

@Component({
    selector: 'pledgesDetail',
    templateUrl: './PendingPledgesDetailComponent.html',
    styleUrls: ['./PendingPledgesDetailComponent.scss']
})

export class PendingPledgesDetailComponent implements OnInit {
    tableHeaders: ITableHeader[] = [];
    rows: any;

    @ViewChild('form')
    form: PaymentProcessingFormComponent;

    // subscription
    currentUser: User;
    currentUser$: Observable<User>;
    report$: Observable<PagedListOfProcessingReport>;
    report: PagedListOfProcessingReport;

    constructor(private _store: Store<fromRoot.AppState>) {
        this.currentUser$ = this._store.pipe(select(selectSecurityStateDataCurrentUser));
        this.report$ = this._store.pipe(select(selectReportDataPendingPledgesDetailProcessingReport));
    }

    ngOnInit(): void {
        this.report$.subscribe(report => this.report = report);
        this.currentUser$.subscribe(user => this.currentUser = user);
        if (this.currentUser.hasPrivate) {
            this.tableHeaders = [
                {text: 'Status', sortKey: 'Status'},
                {text: 'Pledge #', sortKey: 'PledgeNumber'},
                {text: 'Pledge Type', sortKey: 'PledgeType'},
                {text: 'First Name', sortKey: 'FirstName'},
                {text: 'Last Name', sortKey: 'LastName'},
                {text: 'Company', sortKey: 'Company'},
                {text: 'Utility Acct#', sortKey: 'AccountNumber'},
                {text: 'Invoice #', sortKey: 'InvoiceNumber'},
                {text: 'Amount', sortKey: 'Amount'},
                {text: 'Agency', sortKey: 'Agency'},
                {text: 'Pledged Date', sortKey: 'PledgedDate'},
                {text: 'Crisis', sortKey: 'Crisis'},
                {text: 'Client SSN', sortKey: 'Last4Ssn'},
                {text: 'Paid Date', sortKey: 'PaidDate'},
                {text: 'Ledger #', sortKey: 'LedgerNumber'},
                {text: 'Private', sortKey: 'Private'},
            ];

            this.rows = this.report$.pipe(
                filter(report => !!report),
                map((reports) => reports.items.map((report) => [
                    report.status,
                    report.pledgeNumber,
                    report.pledgeType,
                    report.firstName,
                    report.lastName,
                    report.company,
                    report.clientCompanyAccountNumber,
                    report.invoiceNumber,
                    formatCurrency(report.amount),
                    report.agency,
                    formatDate(report.pledgedDate),
                    report.crisis ? 'Yes' : 'No',
                    report.clientLast4Ssn,
                    formatDate(report.paidDate),
                    report.ledgerNumber,
                    report.isPrivate
                ])));

        } else {
            this.tableHeaders = [
                {text: 'Status', sortKey: 'Status'},
                {text: 'Pledge #', sortKey: 'PledgeNumber'},
                {text: 'Pledge Type', sortKey: 'PledgeType'},
                {text: 'First Name', sortKey: 'FirstName'},
                {text: 'Last Name', sortKey: 'LastName'},
                {text: 'Company', sortKey: 'Company'},
                {text: 'Utility Acct#', sortKey: 'AccountNumber'},
                {text: 'Invoice #', sortKey: 'InvoiceNumber'},
                {text: 'Amount', sortKey: 'Amount'},
                {text: 'Agency', sortKey: 'Agency'},
                {text: 'Pledged Date', sortKey: 'PledgedDate'},
                {text: 'Crisis', sortKey: 'Crisis'},
                {text: 'Client SSN', sortKey: 'Last4Ssn'},
                {text: 'Paid Date', sortKey: 'PaidDate'},
                {text: 'Ledger #', sortKey: 'LedgerNumber'},
            ];

            this.rows = this.report$.pipe(
                filter(report => !!report),
                map((reports) => reports.items.map((report) => [
                        report.status,
                        report.pledgeNumber,
                        report.pledgeType,
                        report.firstName,
                        report.lastName,
                        report.company,
                        report.clientCompanyAccountNumber,
                        report.invoiceNumber,
                        formatCurrency(report.amount),
                        report.agency,
                        formatDate(report.pledgedDate),
                        report.crisis ? 'Yes' : 'No',
                        report.clientLast4Ssn,
                        formatDate(report.paidDate),
                        report.ledgerNumber
                    ])
                )
            );
        }
    }

    onTableUpdate(pagedList: PagedListOfProcessingReport): void {
        this.form.submitPendingPledgesDetail(pagedList.offset, pagedList.limit)
            .subscribe();
    }
}
