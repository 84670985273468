import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {Observable} from 'rxjs';
import {ShiftPledgeReportSummary} from '../../../../services/api.client';
import {PaymentProcessingFormComponent} from '../PaymentProcessingFormComponent';
import {PledgeStatusType} from '../../../../services/api.client';
import {select, Store} from '@ngrx/store';
import * as fromRoot from '../../../../store';
import {selectReportDataShiftPledgeReportSummary} from '../../../../store/selectors/report.selectors';

@Component({
    selector: 'pendingToPaid',
    templateUrl: './PendingToPaidComponent.html',
    styleUrls: ['./PendingToPaidComponent.scss']
})

export class PendingToPaidComponent implements OnInit, AfterViewInit {
    reports$: Observable<ShiftPledgeReportSummary[]>;
    reports: ShiftPledgeReportSummary[];

    @ViewChild(PaymentProcessingFormComponent)
    form: PaymentProcessingFormComponent;

    constructor(private _store: Store<fromRoot.AppState>) {
        this.reports$ = this._store.pipe(select(selectReportDataShiftPledgeReportSummary));
    }

    ngOnInit(): void {
        this.reports$.subscribe(reports => this.reports = reports);
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            this.form.form.get('displayPledgeStatus').reset(PledgeStatusType.Pending, {disabled: true});
            this.form.form.get('shiftPledgeStatus').reset(PledgeStatusType.Paid, {disabled: true});
        });
    }

}
