import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot} from '@angular/router';
import {ApplicationInsights} from '@microsoft/applicationinsights-web';
import {environment} from '../../environments/environment';

@Injectable()
export class MonitorService {
    private appInsights: ApplicationInsights;

    constructor() {
        if (environment.appInsights && environment.appInsights.instrumentationKey) {
            this.appInsights = new ApplicationInsights({
                config: environment.appInsights
            });
            this.appInsights.trackPageView();
        }
    }

    getActivatedComponent(snapshot: ActivatedRouteSnapshot): any {
        return snapshot.firstChild ? this.getActivatedComponent(snapshot.firstChild) : snapshot.component;
    }

    getRouteTemplate(snapshot: ActivatedRouteSnapshot): string {
        let path = '';
        if (snapshot.routeConfig) {
            path += snapshot.routeConfig.path;
        }

        if (snapshot.firstChild) {
            return path + this.getRouteTemplate(snapshot.firstChild);
        }

        return path;
    }

    logPageView(
        name?: string,
        url?: string,
        properties?: { [key: string]: string },
        measurements?: { [key: string]: number },
        duration?: number): void {
        if (environment.appInsights && environment.appInsights.instrumentationKey) {
            this.appInsights.trackPageView({
                name,
                uri: url,
                properties: {...this.AddGlobalProperties(properties), duration, measurements}
            });
            return;
        }
    }

    logError(error: Error, properties?: { [key: string]: string }, measurements?: { [key: string]: number }): void {
        if (environment.appInsights && environment.appInsights.instrumentationKey) {
            this.appInsights.trackException({
                    exception: error
                },
                { ...this.AddGlobalProperties(properties), measurements });
            return;
        }
        console.error(error, this.AddGlobalProperties(properties), measurements);
    }

    private AddGlobalProperties(properties?: { [key: string]: string }): { [key: string]: string } {
        if (!properties) {
            properties = {};
        }

        // add your custom properties such as app version

        return properties;
    }
}
