<div class="container" *ngIf="currentClient">
  <h1 class="page-title">EFS Clearinghouse</h1>
  <section class="widget">
    <div class="widget-body">
      <div class="mt">
        <span *ngIf="!isClientValid()" class="text-danger">This Client Has Missing County</span>
        <div class="row">
          <div class="col-md-12">
            <section class="widget bg-primary">
              <form class="form-horizontal" [formGroup]="form">
                <div class="widget-body clearfix">
                  <div class="row flex-nowrap">
                    <div class="col-md-6">
                      <div class="row form-field-spacing">
                        <span class="col-md-3 text-white">Client Name</span>
                        <span class="col-md-8 text-white">{{setClientName()}}</span>
                      </div>
                      <div class="row form-field-spacing">
                        <span class="col-md-3 text-white">Client Zip Code</span>
                        <span class="col-md-8 text-white">{{setClientZipCode()}}</span>
                      </div>
                      <div class="row form-field-spacing">
                        <span class="col-md-3 text-white">County</span>
                        <span class="col-md-8 text-white">{{currentClient.county}}</span>
                      </div>
                      <div class="row form-field-spacing"
                           [class.has-danger]="!assistanceDate.valid && assistanceDate.touched">
                        <span class="col-md-3 text-white">Assistance Date</span>
                        <datePicker [control]="assistanceDate"
                                    [controlFormat]="'L'"
                                    [autoClose]="true"
                                    class="datePicker-padding col-md-8"></datePicker>
                        <span *ngIf="assistanceDate.touched && assistanceDate.hasError('dateRange')"
                              class="m-t-05 col-md-3"></span>
                        <div *ngIf="assistanceDate.touched && assistanceDate.hasError('dateRange')"
                             class="form-control-feedback efsError">
                          {{assistanceDate.getError('dateRange')}}
                        </div>
                        <span *ngIf="assistanceDate.touched && assistanceDate.hasError('isDateValid')"
                              class="m-t-05 col-md-3"></span>
                        <div *ngIf="assistanceDate.touched && assistanceDate.hasError('isDateValid')"
                             class="form-control-feedback efsError">
                          {{assistanceDate.getError('isDateValid')}}
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="pull-right">
                        <a class="text-white" (click)="modal.open(); false;">EFS info</a>
                      </div>
                      <modal #modal>
                        <modal-header>
                          <h4 class="modal-title">How to Pledge</h4>
                        </modal-header>
                        <modal-body>
                          <ul>
                            <li>Make sure correct Funding Phase is selected.</li>
                            <li>Select assistance type agency is committing to client and enter commitment date.</li>
                            <li>Click ‘Add and Save EFS’ button to save.</li>
                            <li>Click ‘Print’ for file copy.</li>
                          </ul>
                        </modal-body>
                        <modal-footer>
                          <button type="button" class="btn btn-primary"
                                  (click)="modal.close()">Ok
                          </button>
                        </modal-footer>
                      </modal>

                      <div class="row form-field-spacing" *ngIf="efsPledgeTypes.length === 1">
                        <span class="col-md-3 text-white">EFS Phase</span>
                        <span class="col-md-8 text-white">{{efsPledgeTypes[0].name}}</span>
                      </div>
                      <div class="row form-field-spacing" *ngIf="efsPledgeTypes.length !== 1"
                           [class.has-danger]="!pledgeTypeId.valid && pledgeTypeId.touched">
                        <span class="col-md-3 text-white">EFS Phase</span>
                        <select class="form-control col-md-8 custom-select"
                                (click)="setMarkAsTouched()"
                                [formControl]="pledgeTypeId"
                                type="text"
                                formControlName="pledgeTypeId">
                          <option value="null">-- Choose EFS Phase --</option>
                          <option *ngFor="let item of efsPledgeTypes"
                                  [value]="item.id">{{item.name}}
                          </option>
                        </select>
                      </div>
                      <div class="form-control-feedback efsError"
                           *ngIf="assistanceType.getError('duplicateAssistanceType')">
                        {{ assistanceType.getError('duplicateAssistanceType') }}
                      </div>
                      <div class="row form-field-spacing"
                           [class.has-danger]="existingAssistancerErrorMessage || (!assistanceType.valid && assistanceType.touched)">
                        <span class="col-md-3 text-white">Assistance Type</span>
                        <div>
                          <div class="custom-controls-stacked">
                            <label class="custom-control custom-radio">
                              <input [formControl]="assistanceType"
                                     (click)="setMarkAsTouched()"
                                     value="Electric"
                                     class="custom-control-input"
                                     type="radio"
                                     name="assistanceType"
                                     id="isAssistanceTypeElectric">
                              <span class="custom-control-indicator"></span>
                              <span class="custom-control-description text-white">Electric</span>
                            </label>
                            <label class="custom-control custom-radio">
                              <input [formControl]="assistanceType"
                                     (click)="setMarkAsTouched()"
                                     value="Gas"
                                     class="custom-control-input"
                                     type="radio"
                                     name="assistanceType"
                                     id="isAssistanceTypeGas">
                              <span class="custom-control-indicator"></span>
                              <span class="custom-control-description text-white">Gas</span>
                            </label>
                            <label class="custom-control custom-radio">
                              <input [formControl]="assistanceType"
                                     (click)="setMarkAsTouched()"
                                     value="Mass Shelter"
                                     class="custom-control-input"
                                     type="radio"
                                     name="assistanceType"
                                     id="isAssistanceTypeMassShelter">
                              <span class="custom-control-indicator"></span>
                              <span class="custom-control-description text-white">Mass Shelter</span>
                            </label>
                            <label class="custom-control custom-radio">
                              <input [formControl]="assistanceType"
                                     (click)="setMarkAsTouched()"
                                     value="Rent or Mortgage"
                                     class="custom-control-input"
                                     type="radio"
                                     name="assistanceType"
                                     id="isAssistanceTypeRentOrMortgage">
                              <span class="custom-control-indicator"></span>
                              <span class="custom-control-description text-white">Rent or Mortgage</span>
                            </label>
                            <label class="custom-control custom-radio">
                              <input [formControl]="assistanceType"
                                     (click)="setMarkAsTouched()"
                                     value="Sewer"
                                     class="custom-control-input"
                                     type="radio"
                                     name="assistanceType"
                                     id="isAssistanceTypeSewer">
                              <span class="custom-control-indicator"></span>
                              <span class="custom-control-description text-white">Sewer</span>
                            </label>
                            <label class="custom-control custom-radio">
                              <input [formControl]="assistanceType"
                                     (click)="setMarkAsTouched()"
                                     value="Water"
                                     class="custom-control-input"
                                     type="radio"
                                     name="assistanceType"
                                     id="isAssistanceTypeWater">
                              <span class="custom-control-indicator"></span>
                              <span class="custom-control-description text-white">Water</span>
                            </label>
                            <label class="custom-control custom-radio">
                              <input [formControl]="assistanceType"
                                     (click)="setMarkAsTouched()"
                                     value="Other Shelter Assistance"
                                     class="custom-control-input"
                                     type="radio"
                                     name="assistanceType"
                                     id="isAssistanceTypeOtherShelterAssistance">
                              <span class="custom-control-indicator"></span>
                              <span class="custom-control-description text-white">Other Shelter Assistance</span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row flex-nowrap">
                    <div class="col-md-6">
                      <button class="btn btn-secondary"
                              [disabled]="loggedInUser.userType == userTypes.Viewer || loggedInUser.userType == userTypes.FundAdmin"
                              type="button"
                              *ngIf="selectedEfs?.id || selectedRow"
                              (click)="Cancel()">New EFS
                      </button>
                      <button class="btn btn-secondary"
                              type="button"
                              *ngIf="(selectedEfs?.id || selectedRow) && !form.touched"
                              (click)="showConfirmPrompt()">Re-Print
                      </button>
                    </div>
                    <div class="col-md-6 flex-xs-middle">
                      <button class="btn btn-secondary" *ngIf="!selectedEfs"
                              [disabled]="assistanceDate.errors
                                                    || assistanceDate.errors
                                                    || pledgeTypeId.errors
                                                    || !isClientValid()
                                                    || assistanceType.errors
                                                    || loggedInUser.userType == userTypes.Viewer
                                                    || loggedInUser.userType == userTypes.FundAdmin"
                              type="button"
                              name="button"
                              (click)="submit()">Add and Save EFS
                      </button>
                      <button class="btn btn-secondary" *ngIf="selectedEfs && isSystemAdmin()"
                              [disabled]="assistanceDate.errors
                                                    || assistanceDate.errors
                                                    || pledgeTypeId.errors
                                                    || !isClientValid()
                                                    || loggedInUser.userType == userTypes.Viewer
                                                    || loggedInUser.userType == userTypes.FundAdmin"
                              type="button"
                              name="button"
                              (click)="submit()">Update EFS
                      </button>
                      <button class="btn btn-danger" [hidden]="!selectedEfs"
                              [disabled]="loggedInUser.userType == userTypes.Viewer || loggedInUser.userType == userTypes.FundAdmin"
                              *ngIf="isSystemAdmin()"
                              type="button"
                              name="button" (click)="delete()">Delete EFS
                      </button>
                    </div>
                  </div>
                </div>
              </form>
              <!--CONFIRMATION-->
              <clientEfs-confirmation #confirmPrompt>
                <div body>
                  <table class="table table-bordered" style="table-layout: fixed">
                    <thead>
                    <tr>
                      <td colspan="2" style="font-weight: bold; text-align: center">Agency Info
                      </td>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                      <td>Agency Name:</td>
                      <td>{{ formatAgencyName(selectedEfs?.agencyId) }}</td>
                    </tr>
                    </tbody>
                  </table>
                  <table class="table table-bordered" style="table-layout: fixed">
                    <thead>
                    <tr>
                      <td colspan="2" style="font-weight: bold; text-align: center">Client Info
                      </td>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                      <td>An EFS assistance of type:</td>
                      <td>{{ assistanceType.value }}</td>
                    </tr>
                    <tr>
                      <td>... will be made for the client:</td>
                      <td>{{ setClientName() }}</td>
                    </tr>
                    <tr>
                      <td>... who resides at:</td>
                      <td>{{ currentClient?.address1 }} {{ currentClient?.address2 }} {{
                        currentClient?.city }},
                        {{ currentClient?.state }} {{ currentClient?.zipCode}}
                      </td>
                    </tr>
                    <tr>
                      <td>... in county:</td>
                      <td>{{ currentClient?.county }}</td>
                    </tr>
                    </tbody>
                  </table>
                  <table class="table table-bordered" style="table-layout: fixed">
                    <thead>
                    <tr>
                      <td colspan="2" style="font-weight: bold; text-align: center">EFS Info
                      </td>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                      <td>EFS Phase:</td>
                      <td>{{ pledgeTypeName.value}}</td>
                    </tr>
                    <tr>
                      <td>... Assistance Date:</td>
                      <td>{{ assistanceDate.value}}</td>
                    </tr>
                    <tr>
                      <td>... Modified Date:</td>
                      <td>{{ modifiedDate.value}}</td>
                    </tr>
                    <tr>
                      <td>... Entered Date:</td>
                      <td>{{ enteredDate.value}}</td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </clientEfs-confirmation>
            </section>
          </div>
        </div>
        <!--EFS GRID-->
        <div class="row">
          <div class="col-md-6">
            <!--<label>Filter by EFS cycle:</label>-->
            <select class="form-control"
                    (change)="filterByEfsCycle($event.target.value)">
              <option value="">-- Filter by EFS cycle --</option>
              <option *ngFor="let efs of currentClientEfsList | uniqBy: 'pledgeTypeName'"
                      [value]="efs.pledgeTypeId">{{ efs.pledgeTypeName }}
              </option>
            </select>
          </div>
          <div class="col-md"></div>
        </div>
        <table-dynamic id="datatable-table"
                       class="table table-striped table-hover"
                       [headers]="tableHeaders"
                       (headerClick)="sortItems($event)">
          <tr *ngFor="let efs of efsFilteredList" [ngClass]="{selected: efs?.id === selectedRow?.id}"
              (click)="setClickedRow(efs); false">
            <td>{{efs.pledgeTypeName}}</td>
            <td>
              <a><span class="fw-semi-bold">{{efs.assistanceType}}</span></a>
            </td>
            <td class="hidden-sm-down">{{efs.assistanceDate | moment: 'L'}}</td>
            <td class="hidden-sm-down">{{efs.agencyName}}</td>
          </tr>
        </table-dynamic>
      </div>
    </div>
  </section>
</div>
