<h1 class="page-title">Browse UHONS</h1>
<section class="widget">
    <div class="widget-body">
        <div class="mt">
            <form novalidate>
                <div class="row">
                    <div class="col-md col-xs-12">
                        <ul class="help-text-align">
                            <li><span>To enter new Assistance Application, go to ‘Clients’ Menu on the left.</span></li>
                        </ul>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md col-xs-12">
                        <!--Search String #-->
                        <label class="header-label">Search:</label>
                        <input class="search-text-input form-control"
                               type="text"
                               placeholder="Enter Keyword"
                               [ngModel]="searchValue"
                               [ngModelOptions]="{standalone: true}"
                               (ngModelChange)="setSearchValue($event)"/>
                    </div>
                    <div class="col-md col-xs-12">
                        <!--Agency modal filter-->
                        <label class="header-label">Agency:</label>
                        <input class="agency-modal-input form-control"
                               type="text"
                               placeholder="select agency to filter"
                               (click)="showAgencyModal()"
                               (keydown)="showAgencyModal()"
                               value="{{selectedAgencyName}}"
                               [disabled]="isDisabledAgency()"/>
                    </div>
                    <div class="col-md col-xs-12 dropdown-menu-top" *ngIf="isAdmin">
                        <!--Region Admin-->
                        <label class="header-label">Region Admin Agency:</label>
                        <select name="" id="" class="custom-select form-control" [formControl]="selectedManagementAgencyId">
                            <option value="">-- Select Region Admin Agency--</option>
                            <option *ngFor="let agency of regionAdminAgencies"
                                    [value]="agency.id">{{agency.agencyName}}</option>
                        </select>
                    </div>
                    <div class="col-md col-xs-12 dropdown-menu-top">
                        <!--Status filter-->
                        <label class="header-label">Case Status:</label>
                        <select name="" id="" class="custom-select form-control" [formControl]="appStatus">
                            <option value="">-- Filter By Status --</option>
                            <option *ngFor="let status of statuses"
                                    [value]="status.value">{{formatStatus(status.value)}}</option>
                        </select>
                    </div>

                    <div class="col-md col-xs-12 dropdown-menu-top">
                        <!--Public/Private filter-->
                        <label class="header-label">Public/Internal:</label>
                        <select name="" id="" class="custom-select form-control" [formControl]="appPublicPrivate">
                            <option value="">-- Filter By Public/Internal --</option>
                            <option *ngFor="let publicPrivate of publicOrPrivate"
                                    [value]="publicPrivate.value">{{publicPrivate.key}}</option>
                        </select>
                    </div>
                </div>
                <div class="row">
                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <button class="btn btn-primary pull-left" role="button"
                                (click)="searchAssistanceApplications()">Search
                        </button>
                        <button class="btn btn-secondary m-l-05" role="button"
                                (click)="clearFilters()">Clear Filters
                        </button>
                    </div>
                </div>

            </form>
            <table-dynamic id="datatable-table" class="table table-striped table-hover"
                           [headers]="tableHeaders" [currentPage]="currentPage" [totalCount]="totalCount"
                           (headerClick)="sortCases($event)" (pageChanged)="setPage($event)"
                           (limitChange)="setLimit($event)">
                <tr *ngFor="let app of (assistanceApplicationList$ | async)?.items">
                    <td class="hidden-sm-down">{{app?.fundTypeName}}</td>
                    <td><a [routerLink]="['../client', app?.clientId]"><strong>{{
                        app?.clientLastName}}</strong></a></td>
                    <td class="hidden-sm-down">{{app?.clientFirstName}}</td>
                    <td class="hidden-sm-down">***-**-{{app?.clientLast4Ssn}}</td>
                    <td class="hidden-sm-down">{{app?.enteredDate | moment: 'L'}}</td>
                    <td>{{formatStatus(app?.status)}}</td>
                    <td class="hidden-sm-down">{{app?.agencyName}}</td>
                    <td><a [routerLink]="['../client', app?.clientId, 'assistance-applications', app?.id]"
                    ><strong>{{app?.totalNeed
                        | currency:'USD':'symbol':'1.2-2'}}</strong></a></td>
                </tr>
            </table-dynamic>
        </div>
    </div>
    <agency-box id="agencyListModal" #agencyListModal (onAgencySelected)="onAgencySelected($event)"></agency-box>
</section>
