import {share} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {getMessageFromException} from '../utilities/Util';
import {NotificationService} from './NotificationService';
import {Efs, EfsAssistanceClient} from './api.client';
import {Store} from '@ngrx/store';
import * as fromRoot from '../store';
import {Observable} from 'rxjs';
import {clearCurrentSelectedEfs, efsDataIsLoading, setCurrentClientEfsList, setCurrentSelecteddEfs} from '../store/actions/efs.actions';

@Injectable()
export class EfsService {

  constructor(private _efsApiService: EfsAssistanceClient,
              private _notificationService: NotificationService,
              private _store: Store<fromRoot.AppState>) {
  }


  // getEfsPaged (searchValue?: string,
  //             agencyId?: string,
  //             efsTypeId?: string,
  //             sortName?: string,
  //             descending?: boolean,
  //             limit?: number,
  //             offset?: number) {
  //     this.startLoadingAction();
  //     let request = this._efsApiService.retrieveEfsPaged(
  //         searchValue,
  //         agencyId,
  //         efsTypeId,
  //         sortName,
  //         limit,
  //         offset,
  //         descending
  //     ).share();
  //     request.subscribe((result) => {
  //         this.setEfsAction(result);
  //     }, (err) => {
  //         this._notificationService.showError('Error Loading EFS');
  //     }, () => {
  //
  //     });
  //     return request;
  // }

  // getEfsListByClientID (ClientId?: string) {
  //     this.startLoadingAction();
  //     let request = this._efsApiService.retrieveClientsEfsAssistances(ClientId).share();
  //     request.subscribe((result) => {
  //         this.setCurrentClientEfsListAction(result);
  //     }, (err) => {
  //         this._notificationService.showError('Error Loading EFS');
  //     }, () => {
  //
  //     });
  //     return request;
  // }

  getCurrentClientEfsList(id: string, year: string): Observable<Efs[]> {
    this.startLoadingAction();
    const request = this._efsApiService.retrieveClientsEfsAssistances(id, year).pipe(share());
    request.subscribe({ next: (result) => {
      this.setCurrentClientEfsListAction(result);
    }, error: () => {
      this._notificationService.showError('There was a problem retrieving the client\'s EFS.');
    }, complete: () => {

    }});
    return request;
  }

  addEfs(efs: Efs): Observable<Efs> {
    const request = this._efsApiService.addEfsAssistance(efs).pipe(share());
    this._notificationService.showSuccess('Attempting to save new EFS.');
    request.subscribe({ next: (result) => {
      this.setCurrentSelectedEfs(result);
      this._notificationService.showSuccess('Efs Successfully Created!');
    }, error: (err) => {
        this._notificationService.showError(getMessageFromException(err));
    }, complete: () => {

    }});
    return request;
  }

  updateEfs(efs: Efs): Observable<Efs> {
    const request = this._efsApiService.updateEfsAssistance(efs).pipe(share());
    this._notificationService.showSuccess('Attempting to update EFS.');
    request.subscribe({ next: (result) => {
      this.setCurrentSelectedEfs(result);
      this._notificationService.showSuccess('EFS Successfully Updated!');
    }, error: () => {
      this._notificationService.showError('There was a problem updating this EFS.');
    }, complete: () => {

    }});
    return request;
  }

  deleteEfs(id: string): Observable<boolean> {
    const request = this._efsApiService.deleteEfs(id).pipe(share());
    this._notificationService.showSuccess('Attempting to delete EFS.');
    request.subscribe({ next: () => {
      this._notificationService.showSuccess('EFS Successfully Deleted!');
    }, error: () => {
      this._notificationService.showError('There was a problem deleting this EFS');
    }, complete: () => {

    }});
    return request;
  }

  setCurrentSelectedEfs(efs: Efs): void {
      this.setCurrentSelectedEfsAction(efs);
  }

  // --------Efs Actions----------------
  private startLoadingAction(): void {
    this._store.dispatch(efsDataIsLoading());
  }

  setCurrentSelectedEfsAction(selectedEfs: Efs): void {
    this._store.dispatch(setCurrentSelecteddEfs({ payload: selectedEfs}));
  }

  private setCurrentClientEfsListAction(efs: Efs[]): void {
    this._store.dispatch(setCurrentClientEfsList({ payload: efs}));
  }
}
