import {from as observableFrom, Observable} from 'rxjs';

import {map, mergeMap, take} from 'rxjs/operators';
import {Injectable, Injector} from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {Client} from '../services/api.client';
import {ClientService} from '../services/ClientService';
import {getRouteParam} from '../utilities/Util';
import {select, Store} from '@ngrx/store';
import * as fromRoot from '../store';
import {selectClientDataCurrentClient} from '../store/selectors/client.selectors';

@Injectable()
export class ResolveClient  {

  client$: Observable<Client>;

  constructor(private injector: Injector, private _store: Store<fromRoot.AppState>) {
      this.client$ = this._store.pipe(select(selectClientDataCurrentClient));
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.client$.pipe(
      take(1),
      mergeMap((client) => {
        const id = getRouteParam(route, 'id');
        if (!client || client.id !== id) {
          return this.injector.get(ClientService).getClientById(id);
        }
        return observableFrom([client]);
      }), map((client) => !!client), );

  }
}
