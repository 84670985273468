import {Component, OnInit, ViewChild, EventEmitter, Output} from '@angular/core';
import {Observable} from 'rxjs';
import {Router} from '@angular/router';
import {ITableHeader, TableComponent} from '../../shared/table/TableComponent';
import {PagedListOfUser, User} from '../../../services/api.client';
import {Agency} from '../../../services/api.client';

import {UserService} from '../../../services/UserService';
import {NotificationService} from '../../../services/NotificationService';
import {extractEnumNames, splitCamelCase} from '../../../utilities/Util';
import {DropdownValue} from '../../../utilities/DropdownComponent';
import {SystemData} from '../../../services/api.client';
import {UserStatusType} from '../../../services/api.client';
import {UserType} from '../../../services/api.client';
import {AgencyListModalComponent} from '../agency/AgencyListModalComponent';
import {SystemService} from '../../../services/SystemService';
import {select, Store} from '@ngrx/store';
import * as fromRoot from '../../../store';
import {selectAgencyDataAgenciesItems} from '../../../store/selectors/agency.selectors';
import {
  selectUserDataPagedUsers,
  selectUserDataPagedUsersItems,
  selectUserDataPagedUsersTotalCount
} from '../../../store/selectors/user.selectors';

@Component({
  templateUrl: './UserListComponent.html',
  styleUrls: ['./UserListComponent.scss'],
})

export class UserListComponent implements OnInit {

  constructor(private _userService: UserService,
              private system: SystemService,
              private _notificationService: NotificationService,
              private _router: Router,
              private _store: Store<fromRoot.AppState>) {
    this.users$ = this._store.pipe(select(selectUserDataPagedUsersItems));
    this.pagedUsers$ = this._store.pipe(select(selectUserDataPagedUsers));
    this.agencies$ = this._store.pipe(select(selectAgencyDataAgenciesItems));
    this.totalCount$ = this._store.pipe(select(selectUserDataPagedUsersTotalCount));
  }
  isLoading: boolean;
  systemData: SystemData;
  selectedUserType: any;
  searchValue = '';
  userTypes = extractEnumNames(UserType);
  userTypeDisplay = UserType;
  agencyId: string = null;

  // subscription
  users: User[];
  users$: Observable<User[]>;
  pagedUsers: PagedListOfUser;
  pagedUsers$: Observable<PagedListOfUser>;
  // subscriptions for drop-downs
  agencies: Agency[];
  agencies$: Observable<Agency[]>;

  // sorting
  currentSortName = 'LastName';
  currentSorting = false;

  tableHeaders: ITableHeader[] = [
    {text: 'Last Name', sortKey: 'LastName', ascending: true},
    {text: 'First Name', sortKey: 'FirstName'},
    {text: 'Email', sortKey: 'EmailAddress'},
    {text: 'User Type', sortKey: 'UserType', class: 'hidden-sm-down'},
    {text: 'Agency Name', class: 'hidden-sm-down', sortKey: 'Agency|AgencyName'},
    {text: 'Company Name', class: 'hidden-sm-down', sortKey: 'Company|Name'},
    {text: 'Status', class: 'hidden-sm-down', sortKey: 'Status'}
  ];

  // pagination
  limit = 10;
  currentPage = 1;
  maxSize = 1;
  totalCount: number;
  totalCount$: Observable<number>;
  includeDisabled = false;

  // Dropdowns
  userTypeDropdown: DropdownValue[];

  @ViewChild(TableComponent)
  table: TableComponent;

  // //MODAL
  @ViewChild(AgencyListModalComponent)
  agencyListModal: AgencyListModalComponent;
  @Output('onShowAgencyModal') _showAgencies = new EventEmitter();
  private agencyList: AgencyListModalComponent;

  // selected agency
  agency: Agency;
  selectedAgencyName = '   select agency';

  ngOnInit(): void {
    this.users$.subscribe(users => this.users = users);
    this.pagedUsers$.subscribe(pagedUsers => this.pagedUsers = pagedUsers);
    this.agencies$.subscribe(agencies => this.agencies = agencies);
    this.totalCount$.subscribe(totalCount => this.totalCount = totalCount);
    this.loadUserTypes();
  }

  // Current Selected User
  setCurrentSelectedUser(user: User): void {
    this._userService.setCurrentSelectedUser(user);
  }

  sortUsers(options: ITableHeader): void {
    this.currentSortName = options.sortKey;
    this.currentSorting = !options.ascending;
    this.searchUsers();
  }

  setLimit(limit: number): void {// # of rows per page
    this.limit = limit;
    this.searchUsers();
  }

  setPage(page: number): void {
    setTimeout(() => {
      this.currentPage = page; // set current page
      this.searchUsers();

    });
  }

  // set selected Pledge Type ID
  selectUserType(userType): void {
    this.selectedUserType = userType;
    this.searchUsers();
  }

  // dropdown - agencies
  loadUserTypes(): void {// get Pledge Types
    if (this.userTypes) {
      this.userTypeDropdown = [];
      for (const userType of this.userTypes) {
        this.userTypeDropdown.push(new DropdownValue(userType.value.toString(), splitCamelCase(userType.key)));
      }
    }
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.searchUsers();

    });
  }

  // get Users
  searchUsers(): void {
    this.table.request = this._userService.searchUsers(
      '', // disable feature this.searchValue,
      this.agencyId,
      this.selectedUserType,
      this.includeDisabled,
      this.currentSortName,
      this.currentSorting,
      this.limit,
      (this.currentPage - 1) * this.limit
    );
  }

  // download Users list
  downloadUsers(): string {
    const data = {
      searchValue: this.searchValue,
      agencyId: this.agencyId,
      userTypeId: this.selectedUserType,
      includeDisabled: this.includeDisabled,
      sortName: this.currentSortName,
      limit: this.limit,
      offset: (this.currentPage - 1) * this.limit
    };

    return this._userService.downloadUserList(data);
  }

  isSystemAdmin(): boolean {
    return this.system.isSystemAdmin;
  }

  // search value
  setSearchValue(searchValue): void {
    this.searchValue = searchValue;
  }

  formatUserType(type): string {
    return splitCamelCase(type);
  }

  // show Agency Modal
  showAgencyModal(): boolean {
    this.agencyListModal.open();
    return false;
  }

  onAgencySelected(selectedAgency): void {
    if (selectedAgency) {
      selectedAgency.agencyName ? this.selectedAgencyName = selectedAgency.agencyName : this.selectedAgencyName = '';
      this.searchValue = selectedAgency.agencyName;
      this.agencyId = selectedAgency.id;
      this.searchUsers();
    } else {
      this.selectedAgencyName = '';
      this.searchValue = '';
      this.agencyId = null;
      this.searchUsers();
    }
  }
}
