import {Component} from '@angular/core';
import {SystemService} from '../../../../services/SystemService';

@Component({
  templateUrl: './KeepingCurrentReportComponent.html',
})
export class KeepingCurrentReportComponent {

  constructor(public systemService: SystemService) {
  }
}
