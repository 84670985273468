import {filter, take} from 'rxjs/operators';
import {Component, OnInit} from '@angular/core';
import {ITableHeader} from '../../../../shared/table/TableComponent';
import {Observable} from 'rxjs';
import {PledgeDemographicSummaryReport} from '../../../../../services/api.client';
import {select, Store} from '@ngrx/store';
import * as fromRoot from '../../../../../store';
import {selectReportDataPledgeDemographicSummaryReport} from '../../../../../store/selectors/report.selectors';

@Component({
    templateUrl: './PledgeSummaryReportComponent.html',
    styleUrls: ['./PledgeSummaryReportComponent.scss'],
})
export class PledgeSummaryReportComponent implements OnInit {

    report$: Observable<PledgeDemographicSummaryReport>;
    rows$: any;

    // ------------ Company Summary Report
    companySummaryHeaders: ITableHeader[] = [
        {text: 'Utility Company'},
        {text: 'TtlNumPledges'},
        {text: 'TtlAmtPledged'},
        {text: 'AvgAmtPledged'},
        {text: 'AvgDaysPledgedToEntry'},
        {text: 'AvgDaysEntryToPaid'},
        {text: 'AvgDaysTotal'},
    ];
    companySummaryRows: any[];


    // ------------ Client Demographic Reports
    clientDemographicsHeaders: ITableHeader[] = [
        {text: 'Client Demographics'},
        {text: 'Total'},
    ];
    clientDemographicsRows: any[];
    employmentStatusHeaders: ITableHeader[] = [
        {text: 'Employment Status'},
        {text: 'Total'},
    ];
    employmentStatusRows: any[];
    ageGroupHeadersHeaders: ITableHeader[] = [
        {text: 'Age Group'},
        {text: 'Total'},
    ];
    ageGroupHeadersRows: any[];


    // ------------ Household Demographic Reports...
    householdDemographicsHeaders: ITableHeader[] = [
        {text: 'Household Demographics'},
        {text: 'Total'},
    ];
    householdDemographicsRows: any[];
    monthlyHouseholdHeaders: ITableHeader[] = [
        {text: 'Monthly Household Income'},
        {text: 'Total'},
    ];
    monthlyHouseholdRows: any[];
    householdeSizeHeaders: ITableHeader[] = [
        {text: 'Household Size'},
        {text: 'Total'},
    ];
    householdeSizeRows: any[];

    constructor(private _store: Store<fromRoot.AppState>) {
        this.report$ = this._store.pipe(select(selectReportDataPledgeDemographicSummaryReport));
        this.rows$ = this.report$.pipe(
            // TODO: remove this after server generates model
            filter(report => !!report));
    }

    ngOnInit(): void {
        this.rows$.subscribe((report) => {
                this.companySummaryRows = report.companySummaryReport.map((row) => [
                    row.companyName,
                    row.numberOfPledges,
                    row.totalPledged,
                    row.averagePledged,
                    row.avgDaysPledgedToEntry,
                    row.avgDaysEntryToPaid,
                    row.avgDaysTotal,
                ]);
                this.clientDemographicsRows = report.clientDemographic.map((row: any) => [row.key, row.value]);
                this.employmentStatusRows = report.employmentStatus.map((row: any) => [row.key, row.value]);
                this.ageGroupHeadersRows = report.clientAge.map((row: any) => [row.key, row.value]);
                this.householdDemographicsRows = report.householdDemographic.map((row: any) => [row.key, row.value]);
                this.monthlyHouseholdRows = report.householdIncome.map((row: any) => [row.key, row.value]);
                this.householdeSizeRows = report.householdSize.map((row: any) => [row.key, row.value]);
            });
    }
}
