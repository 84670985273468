<div class="form-group col-xs-12" [class.has-danger]="!emailAddress.valid && emailAddress.touched || emailTaken"
     [class.hidden]="false">
    <!-- TODO: double check this change, original expression was always false. -->
    <!--[class.hidden]="!loggedInUserType == 'User'">-->
    <div class="row">
        <label for="email" class="col-xs-12 required-label col-form-label">Email</label>
        <div class="col-xs-12 form-control-container">
            <input [formControl]="emailAddress" type="email" class="form-control"
                   id="email" placeholder="Email" (blur)="isEmailTaken()">
            <div *ngIf="emailErrorMessage" class="form-control-feedback">{{ emailErrorMessage }}</div>
        </div>
    </div>
</div>
<div class="form-group col-xs-12 col-sm-6" [class.has-danger]="!firstName.valid && firstName.touched">
    <div class="row">
        <label for="firstName" class="col-xs-12 required-label col-form-label">First Name</label>
        <div class="col-xs-12 form-control-container">
            <input [formControl]="firstName"
                   [class.form-control-danger]="!firstName.valid && firstName.touched"
                   class="form-control" type="text" value="" id="firstName" placeholder="First Name">
        </div>
    </div>
</div>

<div class="form-group col-xs-12 col-sm-6" [class.has-danger]="!lastName.valid && lastName.touched">
    <div class="row">
        <label for="lastName" class="col-xs-12 required-label col-form-label">Last Name</label>
        <div class="col-xs-12 form-control-container">
            <input [formControl]="lastName"
                   [class.form-control-danger]="!lastName.valid && lastName.touched"
                   class="form-control" type="text" value="" id="lastName" placeholder="Last Name">
        </div>
    </div>
</div>

<div class="form-group col-xs-12 col-sm-6" [class.has-danger]="!phone.valid && phone.touched">
    <div class="row">
        <label for="phone" class="col-xs-12 required-label col-form-label">Phone</label>
        <div class="col-xs-12 form-control-container">
            <input [formControl]="phone"
                   [class.form-control-danger]="!phone.valid && phone.touched"
                   type="text" class="form-control" id="phone" placeholder="Phone" [imask]="phoneMask">
        </div>
    </div>
</div>

<div class="form-group col-xs-12 col-sm-6">
    <div class="row">
        <label for="phoneExt" class="col-xs-12 col-form-label">Phone Ext</label>
        <div class="col-xs-12 form-control-container">
            <input [formControl]="phoneExt"
                   type="text" class="form-control" id="phoneExt" numbersOnly placeholder="Phone Ext">
        </div>
    </div>
</div>

<div class="form-group col-xs-12" [class.has-danger]="!userType.valid && userType.touched">
    <div class="row">
        <label for="userType" class="col-xs-12 required-label col-form-label">User Type</label>
        <div class="col-xs-12 form-control-container">
            <select class="form-control col-md-8 custom-select"
                    type="text" id="userType"
                    [formControl]="userType"
                    (change)="onUserTypeChange($event.target.value)"
                    [disabled]="selectedUser?.id == currentUser?.id">
                <option value="">Choose a User Type</option>
                <option *ngFor="let item of filteredUserTypes" [value]="item.value">{{ item.key | splitCamelCase }}
                </option>
            </select>
        </div>
    </div>
</div>

<div class="form-group col-xs-12" [hidden]="isSelectedUserFundAdmin()"
     [class.has-danger]="!agencyId.valid && agencyId.touched && agencyIsRequired">
    <div class="row">
        <label class="col-xs-12 required-label col-form-label">Agency</label>
        <div class="col-xs-12 form-control-container">
            <input #agency [formControl]="agencyId" type="text" class="form-control"
                   (click)="agencyListModal.open()" [placeholder]="agencyIsRequired ? 'Select Agency' : 'All Agencies'">
            <agency-box (onAgencySelected)="onAgencyChange($event)"></agency-box>
        </div>
    </div>
</div>

<div class="form-group col-xs-12" [hidden]="!isSelectedUserFundAdmin()"
     [class.has-danger]="!companyId.valid && companyId.touched && !companyId.disabled">
    <div class="row">
        <label class="col-xs-12 required-label col-form-label">Company</label>
        <div class="col-xs-12 form-control-container">
            <input #company [formControl]="companyId" type="text" class="form-control"
                   (click)="companyListModal.openModal()" value=''>
            <company-box (onCompanySelected)="onCompanySelected($event)"></company-box>
        </div>
    </div>
</div>

<div class="form-group col-xs-12">
    <div class="row">
        <div class="col-xs-12 custom-control-container">
            <label class="custom-control custom-checkbox">
                <input [formControl]="emailOptOut" type="checkbox" class="custom-control-input" id="emailOptOut">
                <span class="custom-control-indicator"></span>
                <span class="custom-control-description">Opt out of all emails except urgent system messages</span>
            </label>
        </div>
    </div>
</div>

<div class="form-group col-xs-12">
    <div class="row">
        <div class="col-xs-12 custom-control-container">
            <label class="custom-control custom-checkbox">
                <input [formControl]="receiveEfnEmails" type="checkbox" class="custom-control-input"
                       id="receiveEfnEmails">
                <span class="custom-control-indicator"></span>
                <span class="custom-control-description">Receive EFN Email Notifications</span>
            </label>
        </div>
    </div>
</div>

<div class="form-group col-xs-12" *ngIf="canDisabledUser">
    <div class="row">
        <div class="col-xs-12 custom-control-container">
            <label class="custom-control custom-checkbox">
                <input [formControl]="status"
                       type="checkbox"
                       class="custom-control-input"
                       id="status"
                       [checked]="status.value">
                <span class="custom-control-indicator"></span>
                <span class="custom-control-description">Disable this user's login</span>
            </label>
        </div>
    </div>
</div>
<div class="form-group col-xs-12">
    <div class="row">
        <div class="col-xs-12">
            <ng-content select="[footer]"></ng-content>
            <button *ngIf="isEdit" [autoDisable]="resetPassword" class="btn btn-primary">Reset password</button>
            <button *ngIf="isEdit" (click)="changePasswordModal.open()" class="btn btn-primary">Change password</button>
        </div>
    </div>
</div>
<changePasswordModal></changePasswordModal>
