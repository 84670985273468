import {Component} from '@angular/core';
import {UserService} from '../../../../services/UserService';
import {ActivatedRoute} from '@angular/router';
import {AgencyService} from '../../../../services/AgencyService';

@Component({
  templateUrl: './UserCreateComponent.html',
  styleUrls: ['./UserCreateComponent.scss']
})
export class UserCreateComponent {


  constructor(private _userService: UserService, private _agencyService: AgencyService,
              private _route: ActivatedRoute) {
  }
}
