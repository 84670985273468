import {Component, ViewChild} from '@angular/core';
import {FormControl, UntypedFormBuilder} from '@angular/forms';
import {ModalComponent} from 'src/app/utilities/old-modal/components/modal';
import {Observable, Observer} from 'rxjs';
import {Validators} from '../../../utilities/Validators';
import {AssistanceApplicationClient, AssistanceApplicationRevealSsnParams} from '../../../services/api.client';

@Component({
  selector: 'assistance-application-ssn-reveal',
  templateUrl: './assistance-application-ssn-reveal.component.html',
  styleUrls: ['./assistance-application-ssn-reveal.component.scss']
})

export class AssistanceApplicationSsnRevealComponent {
  @ViewChild(ModalComponent)
  modal: ModalComponent;

  userId: string;

  isAdult: boolean;

  get userPassword(): FormControl {
    return this.form.get('userPassword') as FormControl;
  }

  form = this.formBuilder.group({
    userPassword: new FormControl('', Validators.required)
  });

  fullSsn: string;
  private observer: Observer<boolean>;

  constructor(private assistanceApplicationClient: AssistanceApplicationClient, private formBuilder: UntypedFormBuilder) {
  }

  open(id: string, isAdult: boolean): Observable<boolean> {
    this.userId = id;
    this.isAdult = isAdult;
    this.modal.open();
    this.fullSsn = null;
    this.userPassword.setValue(null);
    return new Observable(obs => {
      this.observer = obs;
    });
  }

  revealSsn(): void {
    const paramssn = AssistanceApplicationRevealSsnParams.fromJS({
      id: this.userId,
      isAdult: this.isAdult,
      enteredPassword: this.userPassword.value
    });
    this.assistanceApplicationClient.exposeMinorAdultSsn(paramssn)
      .subscribe((result) => {
        this.fullSsn = result.result;
      });
  }

  cancelClicked(): void {
    this.modal.close();
    if (this.observer) {
      this.observer.next(false);
      this.observer.complete();
    }
  }

  submit(): void {
    this.revealSsn();
  }

  ngOnDestroy(): void {
    this.fullSsn = null;
  }
}
