<div class="row force-full-width">
    <div class="col-xs-12">
        <div class="clearfix">
            <table-dynamic id="datatable-table"
                           class="table table-striped table-hover"
                           [headers]="tableHeaders"
                           [currentPage]="currentPage"
                           [totalCount]="totalCount"
                           (headerClick)="sortActivity($event)"
                           (pageChanged)="setPage($event)"
                           (limitChange)="setLimit($event)">
                <tr *ngFor="let activityAudit of (pagedListOfActivityAudit$ | async)?.items; let i = index">
                    <td class="">{{ activityAudit?.date | moment: 'MM/DD/YY hh:mm:ss' }}</td>
                    <td class="">{{ activityAudit.createdBy }}</td>
                    <td class="">{{ activityAudit?.organization }}</td>
                    <td class="">{{ getProcessTypeLabel(activityAudit?.processType) }}</td>
                    <td class="">{{ activityAudit?.propertyName }}</td>
                    <td class="">{{ activityAudit?.oldValue || 'N/A' }}</td>
                    <td class="">{{ activityAudit?.newValue }}</td>
                    <td class="">{{ activityAudit?.action }}</td>
                </tr>
            </table-dynamic>
        </div>
    </div>
</div>
