import {map} from 'rxjs/operators';
import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {Company, PagedListOfCompanyMailingReport} from '../../../../services/api.client';
import {ITableHeader} from '../../../shared/table/TableComponent';
import {Observable} from 'rxjs';

import {PaymentProcessingFormComponent} from '../PaymentProcessingFormComponent';
import {formatPhone, formatCurrency} from '../../../../utilities/Util';
import {PledgeStatusType} from '../../../../services/api.client';
import {select, Store} from '@ngrx/store';
import * as fromRoot from '../../../../store';
import {selectReportDataPaymentProcessingCompanyMailingReport} from '../../../../store/selectors/report.selectors';

@Component({
  selector: 'companyMailing',
  templateUrl: './CompanyMailingLabelComponent.html',
  styleUrls: ['./CompanyMailingLabelComponent.scss']
})

export class CompanyMailingLabelComponent implements AfterViewInit {
  tableHeaders: ITableHeader[] = [
    {text: 'Company', sortKey: 'company'},
    {text: 'Address1', sortKey: 'address1'},
    {text: 'Address2', sortKey: 'address2'},
    {text: 'City', sortKey: 'city'},
    {text: 'State', sortKey: 'state'},
    {text: 'Zipcode', sortKey: 'zipcode'},
    {text: 'ContactName1', sortKey: 'contactName1'},
    {text: 'ContactPhone1', sortKey: 'contactPhone1'},
    {text: 'ContactName2', sortKey: 'contactName2'},
    {text: 'ContactPhone2', sortKey: 'contactPhone2'},
    {text: 'ContactName3', sortKey: 'contactName3'},
    {text: 'ContactPhone3', sortKey: 'contactPhone3'},
    {text: 'AmountPledged', sortKey: 'amountPledged'},
  ];

  @ViewChild('form')
  form: PaymentProcessingFormComponent;

  report$: Observable<PagedListOfCompanyMailingReport>;

  constructor(private _store: Store<fromRoot.AppState>) {
    this.report$ = this._store.pipe(select(selectReportDataPaymentProcessingCompanyMailingReport));
  }

  report: PagedListOfCompanyMailingReport;
  rows: any;

  onTableUpdate(pagedList: PagedListOfCompanyMailingReport): Observable<PagedListOfCompanyMailingReport> {
    return this.form.submitCompanyMailingLabel(pagedList.offset, pagedList.limit);
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
        this.rows = this.report$
            .pipe(
                map((reports) => reports?.items?.map((report) => [
                    report.company,
                    report.address1,
                    report.address2,
                    report.city,
                    report.state,
                    report.zipcode,
                    report.contactName1,
                    formatPhone(report.contactPhone1),
                    report.contactName2,
                    formatPhone(report.contactPhone2),
                    report.contactName3,
                    formatPhone(report.contactPhone3),
                    formatCurrency(report.amountPledged),
                ])));
        this.form.form.get('displayPledgeStatus').reset(PledgeStatusType.Confirmed);
    }, 0);
  }
}

