import {filter, map, mergeMap, take} from 'rxjs/operators';
import './vendor';
import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute, NavigationEnd, ResolveEnd, Router} from '@angular/router';
import {MonitorService} from './services/MonitorService';
import {SecurityService} from './services/SecurityService';
import {Title} from '@angular/platform-browser';
import {AnnualIncomeService} from './services/AnnualIncomeService';


@Component({
  selector: 'app',
  templateUrl: './app.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  constructor( private _securityService: SecurityService,
               private _router: Router,
               private _title: Title,
               private _monitor: MonitorService,
               private _activatedRoute: ActivatedRoute,
               private annualIncomeService: AnnualIncomeService
               ) {
  }

  ngOnInit(): void {
    this.registerTitleListener();

    this._router.events.pipe(filter(event => event instanceof ResolveEnd))
        .subscribe((event: ResolveEnd) => {
            const activatedComponent = this._monitor.getActivatedComponent(event.state.root);
            if (activatedComponent) {
                this._monitor.logPageView(`${activatedComponent.prototype} ${this._monitor.getRouteTemplate(event.state.root)}`,
                    event.urlAfterRedirects);
            }
        });

    this.annualIncomeService.getData();
  }

  registerTitleListener(): void {
    const navigationEnd$ = this._router.events.pipe(filter((ev) => ev instanceof NavigationEnd));
    const getDeepestChild = (parent: ActivatedRoute) => {
      while (parent.firstChild != null) {
        parent = parent.firstChild;
      }
      return parent;
    };
    navigationEnd$.pipe(
      mergeMap(() => getDeepestChild(this._activatedRoute).data.pipe(take(1))),
      // ignore routes without titles
      filter((data) => data && data.title),
      map((data) => data.title), )
      .subscribe((title) => this._title.setTitle('EA Platform - ' + title));

  }
}
