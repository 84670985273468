import {FormArray, FormGroup, ValidationErrors, ValidatorFn} from '@angular/forms';
import {EmploymentStatus} from '../../../../../../shared/enums/employment-status';
import {AssistanceApplicationFileType} from '../../../../../../shared/enums/assistance-application-file-type';
import {Client} from '../../../../../../services/api.client';

function countFileType(fileType: AssistanceApplicationFileType, filesArray: FormArray): number {
    return filesArray.controls.filter(control => control.get('fileType').value === fileType).length;
}

export function requiredDocumentsUploadedValidator(client: Client): ValidatorFn {
    return (form: FormGroup): ValidationErrors | null => {
        if (form.get('status').value === 'Denied') {
            return null;
        }
        const assistanceRequest = (form.get('assistanceApplicationAreasOfNeed') as FormGroup).value;
        const applicantAdult = {
            firstName: client?.firstName,
            lastName: client?.lastName,
            totalMonthlyIncome: +client?.individualMonthlyIncome,
            employmentStatus: client?.employmentStatus
        };
        const additionalAdults = (form.get('adults') as FormArray).value;
        const filesArray = form.get('files') as FormArray;

        const errors: ValidationErrors = {};
        if (countFileType(AssistanceApplicationFileType.PhotoId, filesArray) < 1) {
            errors.photoIdRequired = true;
        }

        if (
            countFileType(AssistanceApplicationFileType.UtilityBill, filesArray) +
            countFileType(AssistanceApplicationFileType.TaxStatement, filesArray) +
            countFileType(AssistanceApplicationFileType.TaxReturns, filesArray) +
            countFileType(AssistanceApplicationFileType.BankStatement, filesArray) +
            countFileType(AssistanceApplicationFileType.GovtOrCourtRecord, filesArray) +
            countFileType(AssistanceApplicationFileType.GovtBenefitsStatement, filesArray) +
            countFileType(AssistanceApplicationFileType.SsiSsdiAwardLetter, filesArray) +
            countFileType(AssistanceApplicationFileType.UnemploymentAward, filesArray) +
            countFileType(AssistanceApplicationFileType.WicSnapAwardLetter, filesArray
            ) < 1) {
            errors.utilityOrEquivalentRequired = true;
        }

        const allAdults = [applicantAdult, ...additionalAdults];

        const incomeDocuments = {
            checkStub: countFileType(AssistanceApplicationFileType.CheckStub, filesArray),
            pensionLetter: countFileType(AssistanceApplicationFileType.PensionLetter, filesArray),
            govtBenefitsStatement: countFileType(AssistanceApplicationFileType.GovtBenefitsStatement, filesArray),
            ssiSsdiAwardLetter: countFileType(AssistanceApplicationFileType.SsiSsdiAwardLetter, filesArray),
            unemploymentAward: countFileType(AssistanceApplicationFileType.UnemploymentAward, filesArray),
            wicSnapAwardLetter: countFileType(AssistanceApplicationFileType.WicSnapAwardLetter, filesArray),
            taxReturns: countFileType(AssistanceApplicationFileType.TaxReturns, filesArray),
            profitLossStatement: countFileType(AssistanceApplicationFileType.ProfitLossStatement, filesArray),
        };

        const selfEmployedAdults = allAdults.filter(adult => adult.employmentStatus === EmploymentStatus.SelfEmployed);
        const nonSelfEmployedAdults = allAdults.filter(adult => adult.employmentStatus !== EmploymentStatus.SelfEmployed);

        const hasSelfEmployedAdults = selfEmployedAdults.length > 0;
        const hasValidIncomeDocumentsForSelfEmployed =
            incomeDocuments.taxReturns + incomeDocuments.profitLossStatement >= selfEmployedAdults.length;
        if (hasSelfEmployedAdults && !hasValidIncomeDocumentsForSelfEmployed) {
            errors.incomeDocumentRequiredForSelfEmployed = true;
        }

        const hasNonSelfEmployedAdults = nonSelfEmployedAdults.length > 0;
        const hasValidIncomeDocumentsForNonSelfEmployed = (
            incomeDocuments.checkStub +
            incomeDocuments.pensionLetter +
            incomeDocuments.govtBenefitsStatement +
            incomeDocuments.ssiSsdiAwardLetter +
            incomeDocuments.unemploymentAward +
            incomeDocuments.wicSnapAwardLetter
        ) >= nonSelfEmployedAdults.length;

        if (hasNonSelfEmployedAdults && !hasValidIncomeDocumentsForNonSelfEmployed) {
            errors.incomeDocumentRequiredForNonSelfEmployed = true;
        }

        if (assistanceRequest.childcare && countFileType(AssistanceApplicationFileType.ChildcareBill, filesArray) < 1) {
            errors.childcareBillRequired = true;
        }

        if (assistanceRequest.carRepair && countFileType(AssistanceApplicationFileType.AutoRepairBill, filesArray) < 1) {
            errors.autoRepairBillRequired = true;
        }

        if (assistanceRequest.electric ||
            assistanceRequest.gasHeat ||
            assistanceRequest.water ||
            assistanceRequest.sewer) {
            if (countFileType(AssistanceApplicationFileType.UtilityBill, filesArray) +
                countFileType(AssistanceApplicationFileType.UtilityShutoffNotice, filesArray) < 1) {
                errors.utilityBillOrShutoffNoticeRequired = true;
            }
        }

        if (assistanceRequest.evictionNotification) {
            if (countFileType(AssistanceApplicationFileType.EvictionCourtOrder, filesArray) < 1) {
                errors.evictionCourtOrderRequired = true;
            }
        }

        if (assistanceRequest.rent) {
            if (countFileType(AssistanceApplicationFileType.LeaseAgreement, filesArray) < 1) {
                errors.leaseAgreementRequired = true;
            }

            if (countFileType(AssistanceApplicationFileType.DelinquentRentNotice, filesArray) < 1) {
                errors.delinquentRentNoticeRequired = true;
            }
        }

        return Object.keys(errors).length > 0 ? errors : null;
    };
}
