import {EMPTY as observableEmpty, Observable} from 'rxjs';
import {Component, Input, OnInit} from '@angular/core';
import {Client} from '../../../services/api.client';
import {UntypedFormBuilder, UntypedFormGroup, FormControl} from '@angular/forms';
import {Validators} from '../../../utilities/Validators';
import {KcApplicationService} from '../../../services/KcApplicationService';
import {KcApplication} from '../../../services/api.client';
import {extractEnumNames} from '../../../utilities/Util';
import {WeatherizationStatusesType} from '../../../services/api.client';
import {SystemService} from '../../../services/SystemService';

@Component({
  selector: '[requestEnrollmentForm]',
  templateUrl: './RequestEnrollmentFormComponent.html'
})

export class RequestEnrollmentFormComponent implements OnInit {

  form: UntypedFormGroup;

  weatherizationStatuses = extractEnumNames(WeatherizationStatusesType);
  @Input()
  client: Client;

  _application: KcApplication;
  @Input('application')
  set application(application: KcApplication) {
    this._application = application;
    if (application) {
      if (this.form) {
        this.form.patchValue(application);
      } else {
        setTimeout(() => this.form.patchValue(application));
      }

    }
  }

  get application(): KcApplication {
    return this._application;
  }

  get isLiheapReceived(): FormControl {
    return this.form.get('isLiheapReceived') as FormControl;
  }

  get isLiheapRequested(): FormControl {
    return this.form.get('isLiheapRequested') as FormControl;
  }

  get isWeatherizationReceived(): FormControl {
    return this.form.get('isWeatherizationReceived') as FormControl;
  }

  get isWeatherizationRequested(): FormControl {
    return this.form.get('isWeatherizationRequested') as FormControl;
  }

  get weatherizationStatus(): FormControl {
    return this.form.get('weatherizationStatus') as FormControl;
  }

  get isThirdPartyAgreeable(): FormControl {
    return this.form.get('isThirdPartyAgreeable') as FormControl;
  }

  // thirdPartyAgreement: FormControl;
  // downloadTimestamp: FormControl;
  // approvedTimestamp: FormControl;
  // rowIsNotActive: FormControl;
  get accountNumber(): FormControl {
    return this.form.get('accountNumber') as FormControl;
  }

  constructor(private formBuilder: UntypedFormBuilder, private kcService: KcApplicationService, private system: SystemService) {
  }

  ngOnInit(): void {
    this.initForm();
  }

  initForm(): void {
    this.form = this.formBuilder.group({
      isLiheapReceived: [null, Validators.required],
      isLiheapRequested: [null, Validators.mustBeValue(true)],

      isWeatherizationReceived: [null, Validators.required],
      isWeatherizationRequested: [null, Validators.mustBeValue(true)],

      weatherizationStatus: [null, Validators.required],

      isThirdPartyAgreeable: [null, Validators.mustBeValue(true)], // checkboxes coerce 1 and 0 to boolean
      // thirdPartyAgreement: [null], // ?

      accountNumber: [null],

      // downloadTimestamp: [null],
      // approvedTimestamp: [null],
      // rowIsNotActive: [null],
    });

    this.isLiheapReceived
      .valueChanges
      .subscribe((isTrue) => {
        if (isTrue) {
          this.isLiheapRequested.setValue(null);
          this.isLiheapRequested.disable();
        } else {
          this.isLiheapRequested.enable();
        }
      });

    this.isWeatherizationReceived
      .valueChanges
      .subscribe((isTrue) => {
        if (isTrue) {
          this.isWeatherizationRequested.setValue(null);
          this.weatherizationStatus.setValue(null);
          this.isWeatherizationRequested.disable();
          this.weatherizationStatus.disable();
        } else {
          this.weatherizationStatus.enable();
          this.isWeatherizationRequested.enable();
        }
      });


    // this.isLiheapReceived
    //     .valueChanges
    //     .empty()
    //     .subscribe(() => )
  }

  get hasLiheapReceivedError(): boolean {
    return !this.isLiheapReceived.valid && this.isLiheapReceived.touched;
  }

  get hasLiheapRequestedError(): boolean {
    return !this.isLiheapRequested.valid && this.isLiheapRequested.touched && !this.isLiheapRequested.disabled;
  }

  get hasLiheapError(): boolean {
    return this.hasLiheapReceivedError || this.hasLiheapRequestedError;
  }


  get hasWeatherizationReceivedError(): boolean {
    return !this.isWeatherizationReceived.valid && this.isWeatherizationReceived.touched;
  }

  get hasWeatherizationRequestedError(): boolean {
    return !this.isWeatherizationRequested.valid && this.isWeatherizationRequested.touched && !this.isWeatherizationRequested.disabled;
  }

  get hasWeatherizationError(): boolean {
    return this.hasWeatherizationReceivedError || this.hasWeatherizationRequestedError;
  }

  showLiheapAlert(): void {
    alert(
      'IMPORTANT: Please enroll this household for LIHEAP assistance, OR please send a referral letter to the LIHEAP agency that can serve this household.');
  }

  showWeatherizationAlert(): void {
    alert(
      'IMPORTANT: Please refer this client to your DNR Weatherization department, OR please send a referral letter to the DNR agency that can serve this household.');
  }

  get model(): any {
    const model = this.form.value;
    model.keepingCurrentStatusId = this.system.kcStatusPendingApproval.id;
    return model;
  }

  submit(): Observable<KcApplication> {
    if (!this.form.valid) {
      this.form.markAsTouched();
      return observableEmpty;
    }
    if (!this.application) {
      throw Error('No existing application to update');
    }
    const model = Object.assign({}, this.application, this.model);
    return this.kcService.createApplicationEnrollment(model);
  }
}
