<div class="input_container">
  <input type="text" class="form-control"
         [placeholder]="placeholder"
         [formControl]="formControl"
         [value]="formattedDate"
         (keydown)="isManualEdit = true"
         (blur)="isManualEdit = false; selectedDateColumn = formControl.value"
         (click)="show()">
</div>
<div class="datepicker_container">
  <div *ngIf="!hidden" class="datepicker" (outOfBounds)="hide()" [delay]="200">
    <div class="toolbar">
      <a href="#" class="left-arrow" [class.disabled]="isMinMonth"
         (click)="!isMinMonth && calendar.prevMonth(); false;"></a>
      <h1> {{ calendar.firstDayOfMonth.format("MMMM YYYY") }}</h1>
      <a href="#" class="right-arrow" [class.disabled]="isMaxMonth"
         (click)="!isMaxMonth && calendar.nextMonth(); false;"></a>
    </div>
    <table class="calendar">
      <thead class="weekdays">
      <tr>
        <td *ngFor="let weekday of calendar.weekdays">{{ weekday }}</td>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let row of calendar.dateRows" class="week">
        <td *ngFor="let date of row" [class]="getDateCSS(date)" (click)="dateClicks.next({event:$event, date: date})">
          <div class="date-number">{{ date.date() }}</div>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</div>
