<div class="row">
    <h2 class="offset-md-1 col-md-10">Agency Pledge Detail Report</h2>
</div>
<div class="row">
    <div class="col-xs-12">
        <form pledgeReportForm #form
              [showPledgeStatus]="true" novalidate>
            <button type="submit" class="btn btn-primary" (click)="form.submitAgencyPledgeDetailReport()">Submit
            </button>
            <button class="btn btn-primary" (click)="form.downloadAgencyPledgeDetailReportClick()">Export to Excel</button>
        </form>
        <pagedReportTable (headerClick)="onHeaderClick($event)"
                          (tableUpdate)="onTableUpdate($event)"
                          [headers]="tableHeaders"
                          [pagedList]="report$ | async"
                          [rows]="rows | async"></pagedReportTable>
    </div>
</div>
