<div class="col-xs">
</div>
<div class="form-group col-xs-8">
  Password must meet the following requirements: <br>
  <ul>
    <li>Minimum length: 12</li>
    <li>Password must have 3 of 4:
      <ul>
        <li>Uppercase letter (A-Z)</li>
        <li>Lowercase letter (a-z)</li>
        <li>Number (0-9)</li>
        <li>Special Character (! @ # $ % ^ & *)</li>
      </ul>
    </li>
  </ul>
</div>
<div class="col-xs">
</div>


<div *ngIf="errorMessage" class="col-xs-12">
  <div class="form-group" [class.has-danger]="errorMessage">
    <div class="form-control-feedback">{{ errorMessage }}</div>
  </div>
</div>
<div class="form-group col-xs-12" [class.has-danger]="!currentPassword.valid && currentPassword.touched">
  <div class="row">
    <label for="currentPassword" class="col-xs-12 required-label col-form-label">Current Password</label>
    <div class="col-xs-12 form-control-container">
      <input [formControl]="currentPassword"
             [class.form-control-danger]="!currentPassword.valid && currentPassword.touched"
             type="password" class="form-control" id="currentPassword" placeholder="Password">
    </div>
  </div>
</div>
<div class="form-group col-xs-12" [class.has-danger]="!newPassword.valid && newPassword.touched">
  <div class="row">
    <label for="newPassword" class="col-xs-12 required-label col-form-label">New Password</label>
    <div class="col-xs-12 form-control-container">
      <input [formControl]="newPassword"
             [class.form-control-danger]="!newPassword.valid && newPassword.touched"
             type="password" class="form-control" id="newPassword" placeholder="Password">
      <div *ngIf="newPassword.touched && newPassword.hasError('passwordComplexity')" class="form-control-feedback">
        {{newPassword.getError('passwordComplexity')}}</div>
    </div>
  </div>
</div>
<div class="form-group col-xs-12"
     [class.has-danger]="!confirmPassword.valid && confirmPassword.touched || showMatchPasswordError">
  <div class="row">
    <label for="confirmPassword" class="col-xs-12 required-label col-form-label">Confirm Password</label>
    <div class="col-xs-12 form-control-container">
      <input [formControl]="confirmPassword"
             [class.form-control-danger]="!confirmPassword.valid && confirmPassword.touched"
             type="password" class="form-control" id="confirmPassword" placeholder="Confirm Password">
      <div *ngIf="showMatchPasswordError" class="form-control-feedback">Password must match.</div>
    </div>
  </div>
</div>
<ng-content select="[footer]"></ng-content>
