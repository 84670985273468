import {Observable} from 'rxjs';
import {filter} from 'rxjs/operators';

export type Exists<T> = () => Observable<T>;

declare module 'rxjs/internal/Observable' {
  interface Observable<T> {
    exists: Exists<T>;
  }
}

Observable.prototype.exists = function() {
  return this.pipe(filter((obj) => !!obj));
};
