import {Component, OnInit, OnDestroy} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import {Client} from '../../../../services/api.client';
import {Observable} from 'rxjs';
import {User} from '../../../../services/api.client';
import {KcApplicationService} from '../../../../services/KcApplicationService';
import {select, Store} from '@ngrx/store';
import * as fromRoot from '../../../../store';
import {selectClientDataCurrentClient} from '../../../../store/selectors/client.selectors';
import {selectSecurityStateDataCurrentUser} from '../../../../store/selectors/security.selectors';

@Component({
  selector: 'clientCreateApplication',
  templateUrl: './ClientCreateApplicationComponent.html',
  styleUrls: ['./ClientCreateApplicationComponent.scss']
})

export class ClientCreateApplicationComponent implements OnInit, OnDestroy {
  totalSteps = 3;
  currentStep = 0;

  currentClient: Client;
  currentClient$: Observable<Client>;

  currentUser$: Observable<User>;
  currentUser: User;


  get percentage(): string {
    return (this.currentStep / this.totalSteps * 100) + '%';
  }

  constructor(
      private router: Router,
      private route: ActivatedRoute,
      private kcApplicationService: KcApplicationService,
      private _store: Store<fromRoot.AppState>) {
    this.currentUser$ = this._store.pipe(select(selectSecurityStateDataCurrentUser));
    this.currentClient$ = this._store.pipe(select(selectClientDataCurrentClient));
  }

  ngOnInit(): void {
    this.currentUser$.subscribe(currentUser => this.currentUser = currentUser);
    this.currentClient$.subscribe(currentClient => this.currentClient = currentClient);
    this.route.url.subscribe((params) => {
      const url = params.map((segment) => segment.path).join('/');

      if (~this.router.url.indexOf('/verify_client')) { // root url client/:id
        this.currentStep = 1;
      } else if (~this.router.url.indexOf('/client_enrollment')) {
        this.currentStep = 2;
      } else if (~this.router.url.indexOf('/request_enrollment')) {
        this.currentStep = 3;
      } else {
        this.currentStep = 0;
      }
    });
  }

  resolveUrl(): void {
    switch (this.currentStep) {
      case 1:
        this.router.navigate(['./verify_client'], {relativeTo: this.route}).then();
        break;
      case 2:
        this.router.navigate(['./client_enrollment'], {relativeTo: this.route}).then();
        break;
      case 3:
        this.router.navigate(['./request_enrollment'], {relativeTo: this.route}).then();
        break;
      default:
        this.currentStep = 1;
        this.resolveUrl();
    }
  }

  next(): void {
    this.currentStep++;
    this.resolveUrl();
  }

  back(): void {
    this.currentStep--;
    this.resolveUrl();
  }

  leaveForm(): void {
    this.router.navigate(['../'], {relativeTo: this.route}).then();
  }

  ngOnDestroy(): void {
    this.kcApplicationService.setCurrentSelectedKcApplicationAction(null);
  }
}
