import {EMPTY as observableEmpty, Observable, Subject} from 'rxjs';
import {Component, Input, OnInit, EventEmitter, Output, ViewChild} from '@angular/core';
import {Client, Company, EfnCase, EfnCaseStatusType, LookUp, User} from '../../../../../../services/api.client';
import {AbstractControl, FormControl, UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {NotificationService} from '../../../../../../services/NotificationService';
import {Router} from '@angular/router';
import {Validators} from '../../../../../../utilities/Validators';
import {EfnService} from '../../../../../../services/EfnService';
import {Masking} from '../../../../../../utilities/Masking';
import * as moment from 'moment';
import {CompanyListModalComponent} from '../../../../company/CompanyListModalComponent';
import {select, Store} from '@ngrx/store';
import * as fromRoot from '../../../../../../store';
import {
  selectSecurityStateDataSystemDataStates
} from '../../../../../../store/selectors/security.selectors';
import {distinctUntilChanged, takeUntil} from 'rxjs/operators';
import {Disposable} from '../../../../../../utilities/Disposable';
import {PromptComponent} from '../../../../../shared/dialogs/PromptComponent';
import {extractEnumNames} from '../../../../../../utilities/Util';
import {CompanySelectedEvent} from '../../../../../shared/events/company-selected-event';

@Component({
  selector: 'efnCaseForm',
  templateUrl: './EfnCaseFormComponent.html',
  styleUrls: ['./EfnCaseFormComponent.scss'],
})
export class EfnCaseFormComponent extends Disposable implements OnInit {

  constructor(private formBuilder: UntypedFormBuilder,
              private notificationService: NotificationService,
              private efnService: EfnService,
              private router: Router,
              private _store: Store<fromRoot.AppState>) {
    super();
    this.states$ = this._store.pipe(select(selectSecurityStateDataSystemDataStates));
  }

  form: UntypedFormGroup;

  states: LookUp[];
  states$: Observable<LookUp[]>;

  zipCodeMask = Masking.zipMask;
  isCountyActive: boolean;
  clientCountyInactiveMessage: string;

  @ViewChild(PromptComponent)
  clientIsInactiveModal: PromptComponent;

  @Input()
  client: Client;

  @Input()
  user: User;

  @Input()
  efnCase: EfnCase;

  caseStates = extractEnumNames(EfnCaseStatusType);

  get clientId(): FormControl {
    return this.form.get('clientId') as FormControl;
  }
  get clientFirstName(): FormControl {
    return this.form.get('clientFirstName') as FormControl;
  }
  get clientLastName(): FormControl {
    return this.form.get('clientLastName') as FormControl;
  }
  get clientSsn(): FormControl {
    return this.form.get('clientSsn') as FormControl;
  }
  get clientAddress(): FormControl {
    return this.form.get('clientAddress') as FormControl;
  }
  get clientCounty(): FormControl {
    return this.form.get('clientCounty') as FormControl;
  }

  get clientPhone(): FormControl {
    return this.form.get('clientPhone') as FormControl;
  }

  get clientEmail(): FormControl {
    return this.form.get('clientEmail') as FormControl;
  }

  get totalNeed(): FormControl {
    return this.form.get('totalNeed') as FormControl;
  }

  get createdByAgencyId(): FormControl {
    return this.form.get('createdByAgencyId') as FormControl;
  }

  get summary(): FormControl {
    return this.form.get('summary') as FormControl;
  }

  get description(): FormControl {
    return this.form.get('description') as FormControl;
  }

  get deadline(): FormControl {
    return this.form.get('deadline') as FormControl;
  }

  get status(): FormControl {
    return this.form.get('status') as FormControl;
  }

  get paymentTo(): FormControl {
    return this.form.get('paymentTo') as FormControl;
  }

  get payeeAddress(): FormControl {
    return this.form.get('payeeAddress') as FormControl;
  }

  get payeeAddress2(): FormControl {
    return this.form.get('payeeAddress2') as FormControl;
  }

  get accountNumber(): FormControl {
    return this.form.get('accountNumber') as FormControl;
  }

  get fundedDate(): FormControl {
    return this.form.get('fundedDate') as FormControl;
  }

  get payeeCity(): FormControl {
    return this.form.get('payeeCity') as FormControl;
  }

  get payeeState(): FormControl {
    return this.form.get('payeeState') as FormControl;
  }

  get payeeZip(): FormControl {
    return this.form.get('payeeZip') as FormControl;
  }

  get companyId(): FormControl {
    return this.form.get('companyId') as FormControl;
  }

  get currentTotal(): FormControl {
    return this.form.get('currentTotal') as FormControl;
  }

  get companyName(): FormControl {
    return this.form.get('companyName') as FormControl;
  }

  get usesOneTimeCompany(): FormControl {
    return this.form.get('usesOneTimeCompany') as FormControl;
  }

  selectedCompanyName: string;
  selectedCompany$ = new Subject<Company>();

  date = moment();

  // -------- company modal ------------
  @ViewChild(CompanyListModalComponent)
  companyListModal: CompanyListModalComponent;
  @Output('onShowCompanyModal') _showCompany = new EventEmitter();
  private companyList: CompanyListModalComponent;

  ngOnInit(): void {
    this.states$.subscribe(states => this.states = states);
    this.initForm();
    this.efnService.checkIsCaseCountyActive(this.client.county)
        .pipe(takeUntil(this.$destroyed), distinctUntilChanged())
        .subscribe(isActive => this.isCountyActive = isActive);
  }

  initForm(): void {
    this.form = this.formBuilder.group({
      usesOneTimeCompany: [false],
      clientId: ['', Validators.required],
      clientFirstName: [''],
      clientLastName: [''],
      clientSsn: [''],
      clientAddress: [''],
      clientCounty: [''],
      clientPhone: [''],
      clientEmail: [''],
      totalNeed: ['', Validators.required],
      createdByAgencyId: ['', Validators.required],
      summary: ['', Validators.compose([Validators.required, Validators.maxLength(128)])],
      deadline: ['', Validators.compose([Validators.required, Validators.dateRange(moment(), moment().add('year', 1), true)])],
      description: ['', Validators.required],
      status: ['', Validators.required],
      paymentTo: ['', Validators.requiredWhen('usesOneTimeCompany', true)],
      payeeAddress: ['', Validators.requiredWhen('usesOneTimeCompany', true)],
      payeeAddress2: [''],
      accountNumber: ['', Validators.compose(
          [Validators.requiredWhen('usesOneTimeCompany', false),
                    Validators.companyAccountNumber(this.selectedCompany$)])],
      fundedDate: [''],
      payeeCity: ['', Validators.requiredWhen('usesOneTimeCompany', true)],
      payeeState: ['', Validators.requiredWhen('usesOneTimeCompany', true)],
      payeeZip: ['', Validators.requiredWhen('usesOneTimeCompany', true)],
      currentTotal: [0],
      companyId: ['', Validators.requiredWhen('usesOneTimeCompany', false)],
      companyName: ['', Validators.requiredWhen('usesOneTimeCompany', false)],
    }, {
      validators: Validators.compose([
        Validators.mutuallyExclusive('companyId', 'paymentTo', 'Company', 'Payment To'),
        Validators.mutuallyExclusive('companyId', 'payeeAddress1', 'Company', 'Payee Address'),
        Validators.mutuallyExclusive('companyId', 'payeeCity', 'Company', 'Payee Address2'),
        Validators.mutuallyExclusive('companyId', 'payeeState', 'Company', 'Payee State'),
        Validators.mutuallyExclusive('companyId', 'payeeZip', 'Company', 'Payee Zip')
      ])
    });
    this.form.updateValueAndValidity();

    this.status
      .valueChanges
      .subscribe((status) => {
        if (status === EfnCaseStatusType.Funded && !this.fundedDate.value) {
          this.fundedDate.setValue(moment());
        }
      });
  }

  submit(): any {
    throw Error('Invalid Operation: Use createCase() or updateCase()');
  }

  populateClient(client: Client): void {
    this.form.patchValue({
      clientId: client.id,
      clientFirstName: client.firstName,
      clientLastName: client.lastName,
      clientSsn: client.ssn,
      clientAddress: client.address1 + '\n' + client.address2,
      clientCounty: client.county,
      clientPhone: client.phone,
      clientEmail: client.email,
    });
  }

  populateUser(user: User): void {
    this.form.patchValue({createdByAgencyId: user.agencyId, createdByUserId: user.id});
  }

  showCompanyModal(): boolean {
    this.companyListModal.openModal();
    return false;
  }

  onCompanySelected(selectedCompany: CompanySelectedEvent): void {
    if (selectedCompany?.company) {
      this.selectedCompanyName = selectedCompany.company.name;
      this.companyId.setValue(selectedCompany.company.id);
      this.companyName.setValue(selectedCompany.company.name);
    } else {
      this.selectedCompanyName = '';
      this.companyId.setValue('');
      this.companyName.setValue('');
    }
    this.selectedCompany$.next(selectedCompany.company);
  }

  createCase(): Observable<EfnCase> {
    if (!this.isCountyActive) {
      this.clientCountyInactiveMessage = `The Client County ${this.client?.county} is inactive and is not currently accepting new cases.`;
      this.clientIsInactiveModal.open().subscribe(() => {
        this.router.navigate([`app/client/${this.client.id}/efn_cases`]);
      });
      return observableEmpty as Observable<EfnCase>;
    }

    this.form.updateValueAndValidity();
    if (!this.form.valid) {
      this.form.markAsTouched();
      return observableEmpty as Observable<EfnCase>;
    }
    const caseInfo = this.form.value;
    const createCaseModel = EfnCase.fromJS({
      clientId: caseInfo?.clientId || null,
      clientFirstName: caseInfo?.clientFirstName || null,
      clientLastName: caseInfo?.clientLastName || null,
      clientLast4Ssn: caseInfo?.clientLast4Ssn || null,
      createdByAgencyId: caseInfo?.createdByAgencyId || null,
      createdByAgencyName: caseInfo?.createdByAgencyName || null,
      totalNeed: caseInfo?.totalNeed || null,
      summary: caseInfo?.summary || null,
      description: caseInfo?.description || null,
      caseNote: caseInfo?.caseNote || null,
      status: caseInfo?.status || EfnCaseStatusType.Open,
      deadline: caseInfo?.deadline || null,
      fundedDate: caseInfo?.fundedDate || null,
      currentTotal: caseInfo?.currentTotal || 0,
      caseWorker: caseInfo?.caseWorker || null,
      clientSsn: caseInfo?.clientSsn || null,
      clientAddress: caseInfo?.clientAddress || null,
      clientCounty: caseInfo?.clientCounty || null,
      clientPhone: caseInfo?.clientPhone || null,
      clientEmail: caseInfo?.clientEmail || null,
      paymentTo: caseInfo?.paymentTo || null,
      payeeAddress: caseInfo?.payeeAddress || null,
      payeeAddress2: caseInfo?.payeeAddress2 || null,
      payeeCity: caseInfo?.payeeCity || null,
      payeeState: caseInfo?.payeeState || null,
      payeeZip: caseInfo?.payeeZip || null,
      accountNumber: caseInfo?.accountNumber || null,
      companyId: caseInfo?.companyId || null,
      companyName: caseInfo?.companyName || null,
      createdByUserId: caseInfo?.createdByUserId || this.user?.id,
    });
    return this.efnService.addEfnCase(createCaseModel);
  }

  updateCase(originalCase: any): Observable<EfnCase> {
    this.form.updateValueAndValidity();

    if (!this.form.valid) {
      this.form.markAsTouched();
      return observableEmpty as Observable<EfnCase>;
    }

    const caseUpdate = this.form.value;

    const updateCaseModel = EfnCase.fromJS({
      id: originalCase?.id,
      clientId: caseUpdate?.clientId || null,
      clientFirstName: caseUpdate?.clientFirstName || null,
      clientLastName: caseUpdate?.clientLastName || null,
      clientLast4Ssn: caseUpdate?.clientLast4Ssn || null,
      createdByAgencyId: caseUpdate?.createdByAgencyId || null,
      createdByAgencyName: caseUpdate?.createdByAgencyName || null,
      totalNeed: caseUpdate?.totalNeed || null,
      summary: caseUpdate?.summary || null,
      description: caseUpdate?.description || null,
      caseNote: caseUpdate?.caseNote || null,
      status: this.caseStates
          .find(x => x.key?.toLowerCase() === caseUpdate?.status?.toString()?.toLowerCase())
          .value,
      deadline: caseUpdate?.deadline || null,
      fundedDate: caseUpdate?.fundedDate || null,
      currentTotal: caseUpdate?.currentTotal || 0,
      caseWorker: caseUpdate?.caseWorker || null,
      clientSsn: caseUpdate?.clientSsn || null,
      clientAddress: caseUpdate?.clientAddress || null,
      clientCounty: caseUpdate?.clientCounty || null,
      clientPhone: caseUpdate?.clientPhone || null,
      clientEmail: caseUpdate?.clientEmail || null,
      paymentTo: caseUpdate?.paymentTo || null,
      payeeAddress: caseUpdate?.payeeAddress || null,
      payeeAddress2: caseUpdate?.payeeAddress2 || null,
      payeeCity: caseUpdate?.payeeCity || null,
      payeeState: caseUpdate?.payeeState || null,
      payeeZip: caseUpdate?.payeeZip || null,
      accountNumber: caseUpdate?.accountNumber || null,
      companyId: caseUpdate?.companyId || null,
      companyName: caseUpdate?.companyName || null,
      createdByUserId: caseUpdate?.createdByUserId || this.user?.id,
    });

    return this.efnService.updateEfnCase(updateCaseModel);
  }
}
