<h1 class="page-title">Demographic Reports</h1>
<div class="row mb-lg">
  <div class="col-xs-12">
    <div class="clearfix">
      <ul class="nav nav-tabs pull-xs-left" role="tablist">
        <li class="nav-item"><a class="nav-link" [routerLink]="['./pledge_detail']" routerLinkActive="active">Detail</a>
        </li>
        <li class="nav-item"><a class="nav-link" [routerLink]="['./pledge_summary']"
                                routerLinkActive="active">Summary</a></li>
        <li class="nav-item"><a class="nav-link" [routerLink]="['./pledge_by_zip_code']" routerLinkActive="active">By
          Zip Code</a></li>
        <li class="nav-item"><a class="nav-link" [routerLink]="['./pledge_by_counties']" routerLinkActive="active">By
          Counties</a></li>
      </ul>
    </div>
    <div class="tab-content mb-lg overflow-visible">
      <div role="tabpanel" class="tab-pane active in">
        <router-outlet></router-outlet>
        <div class="clearfix"></div>
      </div>
    </div>
  </div>
</div>
