import {AfterContentChecked, AfterViewInit, ChangeDetectorRef, Component, Injector, OnInit, ViewChild} from '@angular/core';
import {
    AddAssistanceApplicationStateAuditViewModel,
    AssistanceApplication,
    AssistanceApplicationClient,
    AssistanceApplicationStateAuditType,
    Client,
    PledgeStatusType,
    User,
    UserType
} from '../../../../../../services/api.client';
import {asapScheduler, BehaviorSubject, forkJoin, Observable} from 'rxjs';
import {FormArray, FormBuilder} from '@angular/forms';
import {select, Store} from '@ngrx/store';
import * as fromRoot from '../../../../../../store';
import {selectClientDataCurrentClient} from '../../../../../../store/selectors/client.selectors';
import {observeOn, takeUntil, tap} from 'rxjs/operators';
import {ActivatedRoute} from '@angular/router';
import {AssistanceApplicationFormComponent} from '../assistance-application-form/assistance-application-form.component';
import {AssistanceApplicationStateAuditService} from '../../../../../../services/assistanceApplicationStateAudit.service';
import {PromptComponent} from '../../../../../shared/dialogs/PromptComponent';
import {Disposable} from '../../../../../../utilities/Disposable';
import {selectSecurityStateDataCurrentUser} from '../../../../../../store/selectors/security.selectors';
import {toBoolean} from '../../../../../../utilities/Util';
import {NotificationService} from '../../../../../../services/NotificationService';
import {CompanyCategoryService} from '../../../../../../services/companyCategory.service';
import {setCompanyCategoryThresholds} from '../../../../../../store/actions/companyCategory.actions';
import {AssistanceApplicationStatus} from '../../../../../../shared/enums/assistance-application-status';
import {ActivityAuditService} from '../../../../../../services/activityAudit.service';
import {minorValidation} from '../../../../../public/application/validators';
import * as moment from 'moment/moment';
import {Validators} from '../../../../../../utilities/Validators';

@Component({
    selector: 'app-edit-assistance-application',
    templateUrl: './edit-assistance-application.component.html',
    styleUrls: ['./edit-assistance-application.component.scss'],
})
export class EditAssistanceApplicationComponent extends Disposable implements OnInit, AfterViewInit, AfterContentChecked {
    client: Client;
    client$: Observable<Client>;

    @ViewChild(AssistanceApplicationFormComponent) assistanceApplicationForm: AssistanceApplicationFormComponent;
    @ViewChild('auditModal') auditModal: PromptComponent;

    assistanceApplication: AssistanceApplication;
    assistanceApplicationSubject = new BehaviorSubject<AssistanceApplication>(null);
    assistanceApplication$ = this.assistanceApplicationSubject.asObservable();
    applicationId: string;

    user: User;
    user$: Observable<User>;
    loading = true;
    auditState: string;

    savedAudit: AddAssistanceApplicationStateAuditViewModel;

    constructor(private assistanceApplicationClient: AssistanceApplicationClient,
                private activatedRoute: ActivatedRoute,
                private injector: Injector,
                private cdref: ChangeDetectorRef,
                private formBuilder: FormBuilder,
                private _store: Store<fromRoot.AppState>,
                private _assistanceApplicationStateAuditService: AssistanceApplicationStateAuditService,
                private _assistanceApplicationCategoryThresholdService: CompanyCategoryService,
                private _auditActivityService: ActivityAuditService) {
        super();
        this.applicationId = this.activatedRoute.snapshot.paramMap.get('assistanceApplicationId');

        this.client$ = this._store.pipe(select(selectClientDataCurrentClient));
        this.user$ = this._store.pipe(select(selectSecurityStateDataCurrentUser));
    }


    ngOnInit(): void {
        this.loading = true;
        this.user$
            .pipe(takeUntil(this.$destroyed))
            .subscribe(user => this.user = user);
        this.client$
            .pipe(takeUntil(this.$destroyed))
            .subscribe(client => this.client = client);
        this._auditActivityService.setPagedActivityAuditDataAction(null);
    }

    setupChild(): void {

        this.assistanceApplicationForm.form.patchValue({
            ...this.assistanceApplication,
        });

        this.assistanceApplicationForm.companiesFormArray.clear();

        this.assistanceApplicationForm.companiesFormArray.clear();
        this.assistanceApplication.companies.forEach(p => {
            this.assistanceApplicationForm.companyFormArrayMetaData.push({ignoreDuplicates: false, duplicateCompany: null});
            this.assistanceApplicationForm.companiesFormArray.push(this.assistanceApplicationForm.loadPayeeForm(p));
        });

        this.populateHouseHoldInformation();
        this.populateFiles();

        if (!this.canEdit()) {
            this.assistanceApplicationForm.form.disable();
        }
    }

    populateHouseHoldInformation(): void {
        const minorArray = this.assistanceApplicationForm.form.get('minors') as FormArray;
        const adultArray = this.assistanceApplicationForm.form.get('adults') as FormArray;

        minorArray.clear();
        adultArray.clear();

        this.assistanceApplication?.minors?.forEach(x => {
            const ssn = (x.last4Ssn) ? '***-**-' + x.last4Ssn : null;
            const fbGroup = this.formBuilder.group({
                id: x.id,
                firstName: [x.firstName, Validators.required],
                lastName: [x.lastName, Validators.required],
                birthYear: [x.birthYear, [Validators.required, minorValidation(), Validators.yearRange(moment().subtract(150, 'years'), moment())]],
                ssn: [ssn, Validators.compose([Validators.required])]
            });
            minorArray.push(fbGroup);
        });

        this.assistanceApplication?.adults?.forEach(x => {
            const ssn = (x.last4Ssn) ? '***-**-' + x.last4Ssn : null;
            const fbGroup = this.formBuilder.group({
                id: x.id,
                firstName: [x.firstName, Validators.required],
                lastName: [x.lastName, Validators.required],
                birthYear: [x.birthYear, [Validators.required, Validators.yearRange(moment().subtract(150, 'years'), moment())]],
                ssn: [ssn, Validators.compose([Validators.required])],
                employmentStatus: [x.employmentStatus, Validators.required],
                monthlyIncomeAmount: [x.monthlyIncomeAmount, Validators.required]
            });
            adultArray.push(fbGroup);
        });
    }

    populateFiles(): void {
        const filesArray = this.assistanceApplicationForm.form.get('files') as FormArray;

        filesArray.clear();

        this.assistanceApplication?.files?.forEach(x => {
            const fbGroup = this.formBuilder.group({
                id: x.id,
                fileType: x.fileType,
                file: {name: x.fileName, fileUrl: x.fileUrl}
            });
            filesArray.push(fbGroup);
        });
    }

    async submit(): Promise<void> {
        await this.assistanceApplicationForm
            .updateCase(this.assistanceApplication);
    }

    printCase(): void {
        window.print();
    }

    getAuditStateLabel(): string {
        switch (this.auditState) {
            case AssistanceApplicationStateAuditType[AssistanceApplicationStateAuditType.Pass]:
                return 'Pass';
            case AssistanceApplicationStateAuditType[AssistanceApplicationStateAuditType.Fail]:
                return 'Fail';
            case AssistanceApplicationStateAuditType[AssistanceApplicationStateAuditType.PassWithCorrection]:
                return 'Pass With Correction';
            case AssistanceApplicationStateAuditType[AssistanceApplicationStateAuditType.NotYetAudited]:
                return 'Not Yet Audited';
            default:
                return '';
        }
    }

    showAuditStateModal(): void {
        this.auditModal.open();
    }

    onClickCancelAuditState(): void {
        this.auditModal.close();
    }

    onClickConfirmAuditState(): void {

        this._assistanceApplicationStateAuditService.addAssistanceApplicationStateAudit(this.savedAudit)
            .pipe(takeUntil(this.$destroyed))
            .subscribe(result => {
                this.auditModal.close();
                this.auditState = result.newState?.toString();
            });
    }

    ngAfterViewInit(): void {
        forkJoin([
            this.assistanceApplicationClient.getById(this.applicationId),
            this._assistanceApplicationStateAuditService.retrieveCaseCurrentState(this.applicationId),
            this._assistanceApplicationCategoryThresholdService.getCategoryThresholds()
        ])
            .subscribe({
                next: ([application, auditState, categoryThresholds]) => {
                    this.assistanceApplication = application;
                    this.auditState = auditState?.newState
                        ? auditState?.newState?.toString()
                        : auditState?.oldState?.toString() || AssistanceApplicationStateAuditType.NotYetAudited.toString();
                    this.assistanceApplicationSubject.next(this.assistanceApplication);
                    this.loading = false;
                    setTimeout(x => {
                        this.setupChild();
                    });
                    this._store.dispatch(setCompanyCategoryThresholds({payload: categoryThresholds}));
                }, error: () => {
                    this.loading = false;
                    this.injector.get(NotificationService).showError('An error occurred while retrieving assistance application.');
                }
            });
    }

    canAudit(): boolean {
        if (this.user?.userType === UserType.SystemAdmin) {
            return true;
        }

        if (this.user?.agency?.isDefaultManagementAgency === true) {
            return true;
        }

        if (this.assistanceApplication?.isPublic === true) {
            return this.user?.userType === UserType.AgencyAdmin ||
                this.user?.userType === UserType.RegionAdmin ||
                this.user?.userType === UserType.User;
        }

        if (this.assistanceApplication?.isPublic === false) {
            return this.user?.userType === UserType.AgencyAdmin ||
                this.user?.userType === UserType.RegionAdmin ||
                this.user?.userType === UserType.User;
        }

        return false;
    }

    canEdit(): boolean {
        return (this.user?.userType === UserType.SystemAdmin || this.user?.userType === UserType.RegionAdmin) ||
            (this.isApplicationCreator && this.isUnderReview && this.applicationPledgeStatusAllowsEdit);
    }

    get isApplicationCreator(): boolean {
        return toBoolean(this.assistanceApplication && this.assistanceApplication.createdByUserId === this.user?.id);
    }

    get isUnderReview(): boolean {
        return this.assistanceApplication.status !== AssistanceApplicationStatus.UnderReview;
    }

    get applicationPledgeStatusAllowsEdit(): boolean {
        if (!this.assistanceApplication.currentPledgeStatus) {
            return true;
        }

        return this.assistanceApplication?.currentPledgeStatus !== PledgeStatusType.Pending &&
            this.assistanceApplication?.currentPledgeStatus !== PledgeStatusType.PendingManual &&
            this.assistanceApplication?.currentPledgeStatus !== PledgeStatusType.Paid;
    }

    ngAfterContentChecked(): void {
        this.cdref.detectChanges();
    }

    onUpdate(event): void {
        this.assistanceApplication = event;
        this.populateFiles();
    }
}
