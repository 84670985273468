import {Component, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {Agency, AssistanceApplicationClient, PledgeType, User, UserClient} from '../../../../services/api.client';
import {select, Store} from '@ngrx/store';
import * as fromRoot from '../../../../store';
import {Masking} from '../../../../utilities/Masking';
import {AgencyListModalComponent} from '../../agency/AgencyListModalComponent';
import {SystemService} from '../../../../services/SystemService';
import {
    ASSISTANCE_APPLICATION_STATE_AUDIT_TYPE_OPTIONS
} from '../../../../shared/constants/options/assistance-application-state-audit-type-options';
import {
    selectSecurityStateDataCurrentUser,
} from '../../../../store/selectors/security.selectors';
import {Disposable} from '../../../../utilities/Disposable';
import {distinctUntilChanged, takeUntil} from 'rxjs/operators';



@Component({
    selector: 'app-audit-report-filters',
    templateUrl: './audit-report-filters.component.html',
    styleUrls: ['./audit-report-filters.component.scss']
})
export class AuditReportFiltersComponent extends Disposable implements OnInit {
    @ViewChild(AgencyListModalComponent) agencyListModal: AgencyListModalComponent;

    @Output('filters') filters = new EventEmitter<AuditReportFilters>();

    public readonly ASSISTANCE_APPLICATION_STATE_AUDIT_TYPE_OPTIONS = ASSISTANCE_APPLICATION_STATE_AUDIT_TYPE_OPTIONS;
    public form: FormGroup = this.fb.group({
        agencyIds: new FormControl<string[]>([]),
        userIds: new FormControl<string[]>([]),
        fundPledgeTypeId: new FormControl<string[]>([]),
        enteredDateStart: new FormControl<Date>(null),
        enteredDateEnd: new FormControl<Date>(null),
        totalRequestedMin: new FormControl<number>(null),
        totalRequestedMax: new FormControl<number>(null),
        isPublic: new FormControl<boolean>(null),
        auditStatus: new FormControl<string>(''),
    });

    public agencies: Agency[] = [];
    public fundPledgeTypes: PledgeType[] = [];
    public agencyUsers: User[] = [];

    public Masking = Masking;

    public selectedAgencyNames: string[] = [];
    public selectedCaseWorkerNames: string[] = [];

    private currentUser: User;

    public get userHasAccessToAllAgencies(): boolean {
        return this.system.isSystemAdmin || this.currentUser.isManagementAgencyUser || this.system.isRegionAdmin;
    }

    constructor(private store: Store<fromRoot.AppState>, private fb: FormBuilder, private assistanceApplicationClient: AssistanceApplicationClient, public system: SystemService, private userClient: UserClient) {
        super();

        this.store.pipe(select(selectSecurityStateDataCurrentUser), takeUntil(this.$destroyed)).subscribe(user => {
            this.currentUser = user;
        });
    }

    ngOnInit(): void {
        this.form.valueChanges.subscribe(() => {
            this.filters.emit(this.form.value);
        });
        this.resetFilters();

        this.assistanceApplicationClient.getFundPledgeTypesForAgency(this.currentUser?.agencyId)
            .pipe(takeUntil(this.$destroyed), distinctUntilChanged())
            .subscribe(fundTypes => {
                this.fundPledgeTypes = fundTypes;

                const fundPledgeTypeControl = this.form.get('fundPledgeTypeId');

                if (fundTypes?.length === 1) {
                    fundPledgeTypeControl.setValue(fundTypes[0].id);
                }
            });
    }

    public showAgencyModal(): void {
        this.agencyListModal.open();
    }

    public onAgencySelected(agency: Agency | null): void {
        if (agency) {
            const agencyIdsControl = this.form.get('agencyIds') as FormControl<string[]>;
            const agencyIds: string[] = agencyIdsControl.value;
            if (!agencyIds.includes(agency.id)) {
                agencyIds.push(agency.id);
                agencyIdsControl.setValue(agencyIds);
                this.selectedAgencyNames.push(agency.agencyName);

                this.userClient.getUsersByAgencyIds(agencyIds).pipe(takeUntil(this.$destroyed), distinctUntilChanged())
                    .subscribe(users => {
                        this.agencyUsers = users.sort((a, b) => a.lastName.localeCompare(b.lastName));
                    });
            }
        }
    }

    public removeAgency(index: number): void {
        const agencyIdsControl = this.form.get('agencyIds') as FormControl<string[]>;
        const agencyIds: string[] = agencyIdsControl.value;
        agencyIds.splice(index, 1);
        agencyIdsControl.setValue(agencyIds);
        this.selectedAgencyNames.splice(index, 1);

        this.userClient.getUsersByAgencyIds(this.form.get('agencyIds').value).pipe(takeUntil(this.$destroyed), distinctUntilChanged())
            .subscribe(users => {
                this.agencyUsers = users.sort((a, b) => a.lastName.localeCompare(b.lastName));
                const userIdsControl = this.form.get('userIds') as FormControl<string[]>;
                const userIds: string[] = userIdsControl.value;
                for (let i = userIds.length - 1; i >= 0; i--) {
                    const user = this.agencyUsers.find(u => u.id === userIds[i]);
                    if (!user) {
                        userIds.splice(i, 1);
                        this.selectedCaseWorkerNames.splice(i, 1);
                    }
                }
                userIdsControl.setValue(userIds);
            });
    }

    public onCaseWorkerChange(userId: string): void {
        if (userId) {
            const user = this.agencyUsers.find(u => u.id === userId);
            if (user) {
                const userIdsControl = this.form.get('userIds') as FormControl<string[]>;
                const userIds: string[] = userIdsControl.value;
                if (!userIds.includes(user.id)) {
                    userIds.push(user.id);
                    userIdsControl.setValue(userIds);
                    this.selectedCaseWorkerNames.push(user.firstName + ' ' + user.lastName);
                }
            }
        }

        const dropdownElement = document.getElementById('case-workers') as HTMLSelectElement;
        dropdownElement.value = '';
    }

    public removeCaseWorker(index: number): void {
        const userIdsControl = this.form.get('userIds') as FormControl<string[]>;
        const userIds: string[] = userIdsControl.value;
        userIds.splice(index, 1);
        userIdsControl.setValue(userIds);
        this.selectedCaseWorkerNames.splice(index, 1);
    }

    public resetFilters(): void {
        this.agencyListModal?.resetClicked();
        let agencyId = '';
        let fundPledgeTypeId = '';

        this.selectedAgencyNames = [];
        this.selectedCaseWorkerNames = [];

        if (!this.userHasAccessToAllAgencies) {
            this.selectedAgencyNames = [this.currentUser.agency.agencyName];
            agencyId = this.currentUser.agency.id;
        }

        if (this.fundPledgeTypes?.length === 1) {
            fundPledgeTypeId = this.fundPledgeTypes[0].id;
        }

        this.form.reset({
            agencyIds: agencyId === '' ? [] : [agencyId],
            userIds: [],
            fundPledgeTypeId,
            enteredDateStart: null,
            enteredDateEnd: null,
            totalRequestedStart: null,
            totalRequestedEnd: null,
            isPublic: null,
            auditStatus: '',
        });

        this.userClient.getUsersByAgencyIds(this.form.get('agencyIds').value).pipe(takeUntil(this.$destroyed), distinctUntilChanged())
            .subscribe(users => {
                    this.agencyUsers = users.sort((a, b) => a.lastName.localeCompare(b.lastName));
                }
            );
    }
}

export interface AuditReportFilters {
    agencyIds: string[];
    userIds: string[];
    fundPledgeTypeId: string;
    enteredDateStart: Date;
    enteredDateEnd: Date;
    totalRequestedMin: number;
    totalRequestedMax: number;
    isPublic: boolean;
    auditStatus: string;
}
