import {NgModule} from '@angular/core';
import * as fromAgency from '../store/reducers/AgencyReducer';
import * as fromAllocation from '../store/reducers/AllocationReducer';
import * as fromClient from '../store/reducers/ClientReducer';
import * as fromCompany from '../store/reducers/CompanyReducer';
import * as fromEfn from '../store/reducers/EfnReducer';
import * as fromAssistanceApplication from '../store/reducers/assistanceApplication.reducers';
import * as fromEfs from '../store/reducers/EfsReducer';
import * as fromFpl from '../store/reducers/FederalPovertyLevelReducer';
import * as fromKcApp from '../store/reducers/KcApplicationReducer';
import * as fromLookup from '../store/reducers/LookUpReducer';
import * as fromPledge from '../store/reducers/PledgeReducer';
import * as fromPledgeType from '../store/reducers/PledgeTypeReducer';
import * as fromReport from '../store/reducers/ReportReducer';
import * as fromSecurity from '../store/reducers/SecurityReducer';
import * as fromUser from '../store/reducers/UserReducer';
import * as fromApplicationCounty from '../store/reducers/applicationCounty.reduers';
import * as fromActivityAudit from '../store/reducers/activityAudit.reducers';
import * as fromCompanyCategory from './reducers/companyCategory.reducers';
import * as fromCountySpendingReport from '../store/reducers/countySpendingReport.reducers';
import {StoreModule as NgrxStoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {IAgencyData} from './IAgencyData';
import {IAllocationData} from './IAllocationData';
import {IClientData} from './IClientData';
import {ICompanyData} from './ICompanyData';
import {IEfnData} from './IEfnData';
import {IEfsData} from './IEfsData';
import {IFederalPovertyLevelData} from './IFederalPovertyLevelData';
import {IKcApplicationData} from './IKcApplicationData';
import {ILookUpData} from './ILookUpData';
import {IPledgeData} from './IPledgeData';
import {IPledgeTypeData} from './IPledgeTypeData';
import {IReportData} from './IReportData';
import {ISecurityState} from './ISecurityState';
import {IUserData} from './IUserData';
import {IActivityAuditData} from './IActivityAuditData';
import {StoreDevtoolsModule} from '@ngrx/store-devtools';
import {IApplicationCountyData} from './IApplicationCountyData';
import {ICountySpendingReportData} from './ICountySpendingReportData';
import {IAssistanceApplicationData} from './IAssistanceApplicationData';

export interface AppState {
    agency: IAgencyData;
    allocation: IAllocationData;
    client: IClientData;
    company: ICompanyData;
    efn: IEfnData;
    assistanceApplication: IAssistanceApplicationData;
    efs: IEfsData;
    fpl: IFederalPovertyLevelData;
    kcApp: IKcApplicationData;
    lookup: ILookUpData;
    pledge: IPledgeData;
    pledgeType: IPledgeTypeData;
    report: IReportData;
    security: ISecurityState;
    user: IUserData;
    applicationCounty: IApplicationCountyData;
    activityAudit: IActivityAuditData;
    countySpendingReport: ICountySpendingReportData;
}

@NgModule({
    imports: [
        NgrxStoreModule.forRoot({
            agency: fromAgency.agencyReducer,
            allocation: fromAllocation.allocationReducer,
            client: fromClient.clientReducer,
            company: fromCompany.companyReducer,
            efn: fromEfn.efnReducer,
            assistanceApplication: fromAssistanceApplication.assistanceApplicationReducer,
            efs: fromEfs.efsReducer,
            fpl: fromFpl.federalPovertyLevelReducer,
            kcApp: fromKcApp.kcApplicationReducer,
            lookup: fromLookup.lookupReducer,
            pledge: fromPledge.pledgeReducer,
            pledgeType: fromPledgeType.pledgeTypeReducer,
            report: fromReport.reportReducer,
            security: fromSecurity.securityReducer,
            user: fromUser.userReducer,
            applicationCounty: fromApplicationCounty.applicationCountyReducer,
            activityAudit: fromActivityAudit.activityAuditReducer,
            companyCategory: fromCompanyCategory.companyCategoryReducer,
            countySpendingReport: fromCountySpendingReport.countySpendingReportReducer
        }),
        EffectsModule.forRoot([
        ]),
        StoreDevtoolsModule.instrument({
            maxAge: 25
        })
    ]
})
export class StoreModule {
}
