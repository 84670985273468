<div [formGroup]="form">
    <div class="p-b-2">
        <h5 class="add person p-r-2 add-person">Adults in household</h5>
        <div class="add-person">
            <button type="button" class="btn btn-primary" (click)="addAdult()"><i class="fa fa-plus icon-right-padding"></i>Add Adult</button>
        </div>
    </div>
    <div>Please enter all household members excluding the applicant.</div>

    <div class="p-b-1">
        <div formArrayName="adults">
            <div class="pt-4 pb-2 mt-5 household-section" *ngFor="let adult of householdInformationAdultsFormArray.controls; let i = index">
                <div [formGroupName]="i">
                    <div class="row">
                        <div class="col-sm-11">
                            <div class="row">
                                <div class="form-group col-xs-12 col-sm-6" [class.has-danger]="!adult.controls.firstName.valid && adult.controls.firstName.touched">
                                    <div class="row">
                                        <label for="adultFirstName" class="col-xs-12">First Name</label>
                                        <div class="col-xs-12 form-control-container">
                                            <input formControlName="firstName" class="form-control" type="text" value="" id="adultFirstName" placeholder="First Name">
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group col-xs-12 col-sm-6" [class.has-danger]="!adult.controls.lastName.valid && adult.controls.lastName.touched">
                                    <div class="row">
                                        <label for="adultLastName" class="col-xs-12">Last Name</label>
                                        <div class="col-xs-12 form-control-container">
                                            <input formControlName="lastName" class="form-control" type="text" value="" id="adultLastName" placeholder="Last Name">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="form-group col-xs-12 col-sm-6" [class.has-danger]="!adult.controls.birthYear.valid && adult.controls.birthYear.touched">
                                    <div class="row">
                                        <label class="col-xs-12">Birth Year</label>
                                        <div class="col-xs-12 form-control-container">
                                            <input formControlName="birthYear" numbersOnly [decimal]="false" class="form-control" type="text"
                                                   name="adultBirthYear"
                                                   placeholder="Birth Year">
                                            <span class="m-t-05 col-md-3" *ngIf="adult.controls.birthYear?.getError('yearRange') && adult.controls.birthYear?.touched"></span>
                                            <span class="form-control-feedback"
                                                  *ngIf="adult.controls.birthYear.getError('yearRange') && adult.controls?.birthYear.touched">{{adult.controls.birthYear.getError('yearRange')}}</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group col-xs-12 col-sm-6">
                                    <div class="row" [class.has-danger]="adult.controls.ssn?.touched && adult.controls.ssn?.errors">
                                        <label for="adultSsn" class="col-xs-12">Social Security Number
                                            <a *ngIf="showSsnEye(adult.controls.ssn.value)" href="#" (click)="revealSsn.open(adult.controls.id.value, true)">
                                                <i style="padding-left: 5px;" class="fa fa-eye"></i>
                                            </a>
                                        </label>
                                        <div class="col-xs-12 form-control-container">
                                            <input id="adultSsn"
                                                   class="form-control m-b-05"
                                                   placeholder="123-45-6789"
                                                   type="text"
                                                   maxlength="11"
                                                   minlength="11"
                                                   formControlName="ssn"
                                                   [imask]="!adult.controls.ssn.dirty ? last4Mask : ssnMask"
                                                   (blur)="this.validateSsn(adult.controls.ssn)">
                                            <div *ngIf="adult.controls.ssn?.touched && adult.controls.ssn.errors" class="invalid-feedback">
                                                Please enter a valid 9 digit SSN.
                                                Example: 123-45-6789
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row" [class.has-danger]="adult.controls.employmentStatus?.touched && adult.controls.employmentStatus?.errors">
                                <div class="col-sm-6 col-xs-12 dropdown-menu-top">
                                    <label class="">Employment Status:</label>
                                    <select name="employmentStatus" id="adultEmploymentStatus" class="custom-select form-control" formControlName="employmentStatus">
                                        <option value="">--Select Employment Status--</option>
                                        <option *ngFor="let status of employmentStatuses" [value]="status.key">{{status.lookupValue}}</option>
                                    </select>
                                </div>
                                <div class="form-group col-xs-12 col-sm-6" [class.has-danger]="!adult.controls.monthlyIncomeAmount.valid && adult.controls.monthlyIncomeAmount.touched">
                                    <div class="row">
                                        <label for="monthlyIncomeAmount" class="col-xs-12">Total Monthly Income</label>
                                        <div class="col-xs-12 form-control-container">
                                            <currencyInput id="monthlyIncomeAmount" [control]="adult.controls.monthlyIncomeAmount"></currencyInput>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-1 align-self-center">
                            <button class="btn btn-danger trash-button" (click)="removeAdult(i)"><i class="fa fa-trash-o"></i></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <hr>

    <div class="pt-4">
        <h5 class="add-person p-r-2">Minors in household</h5>
        <div class="add-person">
            <button type="button" class="btn btn-primary" (click)="addMinor()"><i class="fa fa-plus icon-right-padding"></i>Add Minor</button>
        </div>
    </div>
    <div class="pt-4">At least one child is required to complete this application.</div>
    <div class="p-b-1">
        <div formArrayName="minors">
            <div class="pt-4 pb-2 mt-5 household-section" *ngFor="let minor of householdInformationMinorsFormArray.controls; let i = index">
                <div [formGroupName]="i">
                    <div class="row">
                        <div class="col-sm-11">
                            <div class="row">
                                <div class="form-group col-xs-12 col-sm-6" [class.has-danger]="!minor.controls.firstName?.valid && minor.controls.firstName?.touched">
                                    <div class="row">
                                        <label for="minorFirstName" class="col-xs-12">First Name</label>
                                        <div class="col-xs-12 form-control-container">
                                            <input formControlName="firstName" class="form-control" type="text" value="" id="minorFirstName" placeholder="First Name">
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group col-xs-12 col-sm-6" [class.has-danger]="!minor?.controls?.lastName?.valid && minor?.controls?.lastName?.touched">
                                    <div class="row">
                                        <label for="minorLastName" class="col-xs-12">Last Name</label>
                                        <div class="col-xs-12 form-control-container">
                                            <input formControlName="lastName" class="form-control" type="text" value="" id="minorLastName" placeholder="Last Name">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="form-group col-xs-12 col-sm-6" [class.has-danger]="!minor?.controls?.birthYear?.valid && minor.controls.birthYear.touched">
                                    <div class="row">
                                        <label class="col-xs-12">Birth Year</label>
                                        <div class="col-xs-12 form-control-container">
                                            <input formControlName="birthYear" numbersOnly [decimal]="false" class="form-control" type="text"
                                                   name="minorBirthYear"
                                                   placeholder="Birth Year">
                                            <span class="m-t-05 col-md-3" *ngIf="minor.controls.birthYear?.getError('yearRange') && minor.controls.birthYear?.touched"></span>
                                            <span class="form-control-feedback"
                                                  *ngIf="minor.controls.birthYear.getError('yearRange') && minor.controls?.birthYear.touched">{{minor.controls.birthYear.getError('yearRange')}}</span>
                                            <span class="m-t-05 col-md-3" *ngIf="minor.controls.birthYear?.getError('minor') && minor.controls.birthYear?.touched"></span>
                                            <span class="form-control-feedback"
                                                  *ngIf="minor.controls.birthYear.getError('minor') && minor.controls?.birthYear.touched">{{minor.controls.birthYear.getError('minor')}}</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group col-xs-12 col-sm-6">
                                    <div class="row" [class.has-danger]="minor.controls.ssn?.touched && minor.controls.ssn?.errors">
                                        <label for="minorSsn" class="col-xs-12">Social Security Number<a *ngIf="showSsnEye(minor.controls.ssn.value)" href="#" (click)="revealSsn.open(minor.controls.id.value, false)"><i style="padding-left: 5px;" class="fa fa-eye"></i></a></label>
                                        <div class="col-xs-12 form-control-container">
                                            <input id="minorSsn"
                                                   class="form-control m-b-05"
                                                   placeholder="123-45-6789"
                                                   type="text"
                                                   formControlName="ssn"
                                                   minlength="11"
                                                   maxlength="11"
                                                   [imask]="!minor.controls.ssn.dirty ? last4Mask : ssnMask"
                                            (blur)="this.validateSsn(minor.controls.ssn)">
                                            <div *ngIf="minor.controls.ssn?.touched && minor.controls.ssn.errors" class="invalid-feedback">
                                                Please enter a valid 9 digit SSN.
                                                Example: 123-45-6789
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-1 align-self-center">
                            <button [disabled]="numberOfMinors < 2" class="btn btn-danger trash-button" (click)="removeMinor(i)"><i class="fa fa-trash-o"></i></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <assistance-application-ssn-reveal></assistance-application-ssn-reveal>
</div>
