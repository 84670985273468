import {Injectable, Injector} from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import {Observable} from 'rxjs';
import {KcApplicationResult} from '../services/api.client';
import {EfnService} from '../services/EfnService';
import {getRouteParam} from '../utilities/Util';
import {NotificationService} from '../services/NotificationService';
import {catchError} from 'rxjs/operators';

@Injectable()
export class ResolveEfnCase  {

  constructor(private injector: Injector) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    const service: EfnService = this.injector.get(EfnService);
    const clientId = getRouteParam(route, 'id');
    const id = getRouteParam(route, 'efnCaseId');
    return service.getEfnCaseById(id)
        .pipe(
            catchError(() => {
            const notification: NotificationService = this.injector.get(NotificationService);
            notification.showError('Failed to retrieve EFN Case.');
            return this.injector.get(Router).navigate(['/app/client', clientId]);
        })
    );
  }
}
