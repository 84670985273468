import {from as observableFrom, Observable} from 'rxjs';

import {mergeMap, take} from 'rxjs/operators';
import {Injectable, Injector} from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import {Pledge} from '../services/api.client';
import {getRouteParam} from '../utilities/Util';
import {PledgeService} from '../services/PledgeService';
import {SystemService} from '../services/SystemService';
import {NotificationService} from '../services/NotificationService';
import {select, Store} from '@ngrx/store';
import * as fromRoot from '../store';
import {selectPledgeDataCurrentSelectedPledge} from '../store/selectors/pledge.selectors';

@Injectable()
export class ResolvePledge  {

  currentPledge$: Observable<Pledge>;

  constructor(private injector: Injector, private _store: Store<fromRoot.AppState>) {
      this.currentPledge$ = this._store.pipe(select(selectPledgeDataCurrentSelectedPledge));
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Pledge> | Promise<Pledge> | Pledge {
    return this.currentPledge$.pipe(
      take(1),
      mergeMap(() => {
        const pledgeService: PledgeService = this.injector.get(PledgeService);
        const router: Router = this.injector.get(Router);
        const system: SystemService = this.injector.get(SystemService);
        const notificationService: NotificationService = this.injector.get(NotificationService);

        const id = getRouteParam(route, 'id');
        if (id) {
          return pledgeService.getPledgeById(id);
        } else {
          if (!system.canCreatePledge()) {
            notificationService.showError('You do not have permission to add a pledge');
            router.navigate(['/app/pledges']).then();
            return observableFrom([null]);
          } else {
            const newPledge = new Pledge();
            pledgeService.setCurrentSelectedPledge(newPledge);
            return observableFrom([newPledge]);
          }
        }
      }), );

  }
}
