<form [formGroup]="form">
    <div *ngIf="this.assistanceApplication?.isPublic && this.hasApplicantDifferences && this.showBulkAlerts"
         class="alert alert-warning mb-5">
        <i (click)="showBulkAlerts = !showBulkAlerts" class="fa fa-times close-icon"
           style="float: right; font-size: 18px; padding: 5px;"></i>
        <div class="py-2" *ngIf="hasDifferentCounty">{{this.countyAlertMessageText}}</div>
        <div class="py-2" *ngIf="hasDifferentEmail">{{this.emailAlertMessage}}</div>
        <div class="py-2" *ngIf="hasDifferentHouseholdIncome">{{this.householdIncomeAlertMessage}}</div>
        <div class="py-2" *ngIf="hasDifferentIndividualIncome">{{this.individualIncomeAlertMessage}}</div>
    </div>
    <assistanceApplicationGroupLayout>
        Client:
        <assistanceApplicationFieldLayout>
            <label>Name:</label>
            <div>{{ client?.firstName }} {{client?.middleInitial}} {{ client?.lastName }}</div>
        </assistanceApplicationFieldLayout>

        <assistanceApplicationFieldLayout>
            <label>SSN:</label>
            <div>{{ client?.last4Ssn ? '***-**-' + client?.last4Ssn : 'N/A' }}</div>
        </assistanceApplicationFieldLayout>

        <assistanceApplicationFieldLayout>
            <label>Address:</label>
            <address>
                {{client?.address1}} {{client?.address2}} <br>
                {{client?.city}}, {{client?.state}} {{client?.zipCode}} <br>
                {{client?.county}}
            </address>
        </assistanceApplicationFieldLayout>

        <assistanceApplicationFieldLayout>
            <label>Household Size:</label>
            <div>{{client?.houseHoldSize ?? 'N/A'}}</div>
        </assistanceApplicationFieldLayout>

        <assistanceApplicationFieldLayout>
            <label>Household Monthly Income:</label>
            <span *ngIf="hasIncome(client?.houseHoldIncome)">{{client?.houseHoldIncome | parseNumber | currency:'USD':'symbol':'1.2-2'}}</span>
            <span *ngIf="!hasIncome(client?.houseHoldIncome)" class="text-danger"> N/A</span>
        </assistanceApplicationFieldLayout>

        <assistanceApplicationFieldLayout>
            <label>Individual Monthly Income:</label>
            <span *ngIf="hasIncome(client?.individualMonthlyIncome)">{{client?.individualMonthlyIncome | parseNumber | currency:'USD':'symbol':'1.2-2'}}</span>
            <span *ngIf="!hasIncome(client?.individualMonthlyIncome)" class="text-danger"> N/A</span>
        </assistanceApplicationFieldLayout>

    </assistanceApplicationGroupLayout>

    <assistanceApplicationGroupLayout>
        Assigned To:
        <assistanceApplicationFieldLayout>
            <label>Agency:</label>
            <div>{{ assistanceApplication?.assignedAgency?.agencyName}}</div>
        </assistanceApplicationFieldLayout>
    </assistanceApplicationGroupLayout>

    <assistanceApplicationGroupLayout>
        Originator:
        <assistanceApplicationFieldLayout>
            <label>{{assistanceApplication?.isPublic ? 'Managed By' : 'Created By'}}:</label>
            <div>{{ user?.agency?.agencyName }}</div>
        </assistanceApplicationFieldLayout>

        <assistanceApplicationFieldLayout>
            <label>Case Manager:</label>
            <div>{{ user?.firstName }} {{ user?.lastName }}</div>
        </assistanceApplicationFieldLayout>

        <assistanceApplicationFieldLayout>
            <label>Phone:</label>
            <div>{{ user?.phone }}</div>
        </assistanceApplicationFieldLayout>

        <assistanceApplicationFieldLayout>
            <label>Email:</label>
            <div>{{ user?.emailAddress }}</div>
        </assistanceApplicationFieldLayout>
    </assistanceApplicationGroupLayout>

    <assistanceApplicationGroupLayout>
        <label>Application:</label>

        <assistanceApplicationFieldLayout>
            <label>Type:</label>
            <div *ngIf="assistanceApplication?.isPublic; else privateApplication">
                <i class="fa fa-users me-1" aria-hidden="true"></i> Public Submission
            </div>
            <ng-template #privateApplication>
                <i class="fa fa-briefcase me-1" aria-hidden="true"></i> Agency Submission
            </ng-template>
        </assistanceApplicationFieldLayout>

        <assistanceApplicationFieldLayout *ngIf="assistanceApplication?.isPublic">
            <label>Applicant County:</label>
            <div>
                {{assistanceApplication?.applicantCounty}}
                <i *ngIf="hasDifferentCounty" class="fa fa-exclamation-circle warning-icon"
                   (click)="openDetailAlert('', this.countyAlertMessageHtml)"></i>
            </div>
        </assistanceApplicationFieldLayout>

        <assistanceApplicationFieldLayout
                *ngIf="assistanceApplication?.isPublic && assistanceApplication?.applicantEmail">
            <label>Applicant Email:</label>
            <div>
                {{assistanceApplication?.applicantEmail}}
                <i class="fa fa-exclamation-circle warning-icon" (click)="openDetailAlert(this.emailAlertMessage, '')"
                   *ngIf="hasDifferentEmail"></i>
            </div>
        </assistanceApplicationFieldLayout>

        <assistanceApplicationFieldLayout
                [class.has-danger]="(!fundPledgeTypeId.valid && fundPledgeTypeId.touched && !fundPledgeTypeId.disabled)">
            <label>Fund Type:</label>
            <select [formControl]="fundPledgeTypeId" class="form-control custom-select">
                <option value="">Choose a Fund Type</option>
                <option *ngFor="let fundType of fundTypes"
                        [value]="fundType.id">{{fundType.name}}</option>
            </select>
        </assistanceApplicationFieldLayout>

        <assistanceApplicationFieldLayout>
            <label>Total Amount Needed:</label>
            <currencyInput [control]="totalNeedControl"></currencyInput>
        </assistanceApplicationFieldLayout>

        <assistanceApplicationFieldLayout [class.has-danger]="summaryControl.touched && !summaryControl.valid">
            <label>Summary:</label>
            <div *ngIf="summaryControl.touched && summaryControl.hasError('maxLength')" class="text-danger">
                {{summaryControl.getError('maxLength')}}
            </div>
            <input type="text" class="form-control" [formControl]="summaryControl">
            <p>Note: Incl. type of assistance & demographic info if desired (i.e. “Gas for elderly client”)</p>
        </assistanceApplicationFieldLayout>

        <assistanceApplicationFieldLayout
                [class.has-danger]="(!descriptionControl.valid && descriptionControl.touched)">
            <label>Circumstances:</label>
            <textarea class="form-control" cols="30" rows="5" [formControl]="descriptionControl"></textarea>
            <p>Note: If needed, drag lower right corner to expand box to show all text</p>
        </assistanceApplicationFieldLayout>

        <!-- START LOOP -->
        <assistanceApplicationFieldLayout formArrayName="companies" style="margin-top: 20px;">

            <label>Payee:</label>
            <ng-container *ngFor="let company of companiesFormArray.controls; let i=index;">

                <div class="payee-container">
                    <button [disabled]="companiesFormArray.value.length < 2"
                            class="btn btn-danger trash-button remove-icon"
                            (click)="removePayee(i)"><i class="fa fa-trash-o"></i></button>
                    <!-- Radio Buttons Group -->
                    <div class="payee-radio-group">
                        <div [formGroupName]="i">
                            <div class="radio-container">
                                <label class="custom-control custom-radio">
                                    <input type="radio" class="custom-control-input" [value]="false"
                                           (change)="setPayeeValidators(company, false)"
                                           formControlName="useNewCompany">
                                    <span class="custom-control-indicator"></span>
                                    <span class="custom-control-description">Company</span>
                                </label>
                                <label class="custom-control custom-radio">
                                    <input (click)="resetCompanyFormArrayMetaDataAtIndex(i)"
                                           (change)="setPayeeValidators(company, true)"
                                           type="radio" class="custom-control-input" [value]="true"
                                           formControlName="useNewCompany"
                                           [checked]="getUseNewCompany(company).value">
                                    <span class="custom-control-indicator"></span>
                                    <span class="custom-control-description">New Payee</span>
                                </label>
                            </div>
                        </div>
                    </div>

                    <!-- Error Messages -->
                    <ng-container *ngIf="form.hasError('mutuallyExclusive') || form.hasError('exclusiveRequire')">
                        <span class="text-danger">{{form.getError('mutuallyExclusive')}}</span>
                        <span class="text-danger">{{form.getError('exclusiveRequire')}}</span>
                    </ng-container>

                    <!-- Company Input -->
                    <div *ngIf="!getUseNewCompany(company).value"
                         [class.has-danger]="!getCompanyName(company).valid &&
                         getCompanyName(company).touched &&
                         !getCompanyName(company).disabled">
                        <input class="form-control mb-4" type="text" [formControl]="getCompanyName(company)"
                               value="{{getCompanyName(company).value}}" placeholder="Select a Company"
                               (click)="showCompanyModal(company)"/>
                    </div>

                    <!-- New Payee Details -->
                    <fieldset *ngIf="getUseNewCompany(company).value">
                        <assistanceApplicationFieldLayout
                                [class.has-danger]="!getPayeePayeeType(company).valid &&
                            getPayeePayeeType(company).touched">
                            <label>Payee Type:</label>
                            <div>
                                <select [formControl]="getPayeePayeeType(company)"
                                        class="form-control custom-select">
                                    <option [ngValue]="null">Choose Company or Individual Payee</option>
                                    <option *ngFor="let payeeType of payeeTypesSelect"
                                            [value]="payeeType.key">{{payeeType.value}}</option>
                                </select>
                            </div>
                        </assistanceApplicationFieldLayout>

                        <assistanceApplicationFieldLayout
                                [class.has-danger]="!getPaymentTo(company).valid && getPaymentTo(company).touched">
                            <label>Payment To:</label>
                            <input type="text" class="form-control" [formControl]="getPaymentTo(company)">
                        </assistanceApplicationFieldLayout>

                        <!-- Address Fields -->
                        <div class="address-fields">
                            <assistanceApplicationFieldLayout
                                    [class.has-danger]="!getPayeeAddress(company).valid && getPayeeAddress(company).touched">
                                <label>Payee Address:</label>
                                <input type="text" class="form-control" [formControl]="getPayeeAddress(company)">
                            </assistanceApplicationFieldLayout>
                            <assistanceApplicationFieldLayout
                                    [class.has-danger]="!getPayeeAddress2(company).valid && getPayeeAddress2(company).touched">
                                <label>Payee Address2:</label>
                                <input type="text" class="form-control" [formControl]="getPayeeAddress2(company)">
                            </assistanceApplicationFieldLayout>
                            <assistanceApplicationFieldLayout
                                    [class.has-danger]="!getPayeeCity(company).valid && getPayeeCity(company).touched">
                                <label>Payee City:</label>
                                <input type="text" class="form-control" [formControl]="getPayeeCity(company)">
                            </assistanceApplicationFieldLayout>
                            <assistanceApplicationFieldLayout
                                    [class.has-danger]="!getPayeeState(company).valid && getPayeeState(company).touched">
                                <label>Payee State:</label>
                                <select [formControl]="getPayeeState(company)" class="form-control custom-select">
                                    <option value="null">Choose a State</option>
                                    <option *ngFor="let state of states"
                                            [value]="state.key">{{state.lookupValue}}</option>
                                </select>
                            </assistanceApplicationFieldLayout>
                            <assistanceApplicationFieldLayout
                                    [class.has-danger]="!getPayeeZip(company).valid && getPayeeZip(company).touched">
                                <label>Payee Zip:</label>
                                <input type="text" class="form-control" [imask]="zipCodeMask"
                                       [formControl]="getPayeeZip(company)">
                            </assistanceApplicationFieldLayout>
                            <assistanceApplicationFieldLayout>
                                <div class="light-gray-background"
                                     *ngIf="!companyFormArrayMetaData[i]?.ignoreDuplicates && !!companyFormArrayMetaData[i]?.duplicateCompany">
                                    <p class="text-danger">Duplicate Company Found:</p>
                                    <div class="ms-1 p-1 m-1">
                                        <div style="font-style: italic; font-weight: bold">{{companyFormArrayMetaData[i]?.duplicateCompany?.name}}</div>
                                        <div style="font-style: italic; font-weight: bold">{{companyFormArrayMetaData[i]?.duplicateCompany?.address1}}</div>
                                    </div>
                                    <span class="text-danger">
                                       Use existing company?
                                       <button (click)="populateCompanyFormGroupWithExistingCompany(i)"
                                               class="ms-1 btn btn-primary btn-sm"><i
                                               class="fa fa-primary fa-check"></i></button>
                                       <button (click)="ignoreDuplicateCompany(i)" class="ms-1 btn btn-danger btn-sm"><i
                                               class="fa fa-danger fa-times"></i></button>
                          </span>
                                </div>
                            </assistanceApplicationFieldLayout>
                        </div>
                    </fieldset>

                    <div class="m-t-05">

                        <assistanceApplicationFieldLayout>
                            <label>Account Number:</label>
                            <div [class.has-danger]="(!getAccountsNumber(company).valid && getAccountsNumber(company).touched)">
                                <div *ngIf="getAccountsNumber(company).touched && getAccountsNumber(company).hasError('companyAccountNumber')"
                                     class="text-danger">
                                    {{getAccountsNumber(company).getError('companyAccountNumber')}}
                                </div>
                                <input *ngIf="getAccountsNumber(company).disabled" type="text"
                                       class="form-control"
                                       readonly="readonly" [value]="getAccountsNumber(company).value">
                                <input *ngIf="!getAccountsNumber(company).disabled" type="text"
                                       class="form-control"
                                       placeholder="Account # or Payment Memo Information"
                                       [formControl]="getAccountsNumber(company)"
                                       (blur)="validateAccountNumber(company)"
                                       (keyup)="validateAccountNumber(company)"
                                >
                                <div *ngIf="getAccountsNumber(company).touched && getAccountsNumber(company).hasError('accountNumberValidation')"
                                     class="text-danger">
                                    {{getAccountsNumber(company).getError('accountNumberValidation')}}
                                </div>
                            </div>
                        </assistanceApplicationFieldLayout>


                        <assistanceApplicationFieldLayout>

                            <label>Amount:</label>

                            <div [class.has-danger]="!getPayeeAmount(company).valid && getPayeeAmount(company).touched">
                                <input class="form-control" type="number" [formControl]="getPayeeAmount(company)"
                                       value="{{getPayeeAmount(company).value}}" placeholder="Amount"/>
                            </div>
                        </assistanceApplicationFieldLayout>
                        <assistanceApplicationFieldLayout>
                            <label>Category:</label>
                            <div [class.has-danger]="!getPayeeCategory(company).valid &&
                                getPayeeCategory(company).touched &&
                                !getPayeeCategory(company).disabled">
                                <select [formControl]="getPayeeCategory(company)" class="form-control custom-select">
                                    <option value="">Choose a Category</option>
                                    <option *ngFor="let category of categoryThresholdsSelect"
                                            [value]="category.key">{{category.value}}</option>
                                </select>
                            </div>
                        </assistanceApplicationFieldLayout>
                    </div>
                    <!--                    <button *ngIf="existingCompanyDetected" (click)="removePayee(1)" class="btn btn-primary mt-3">Use Existing Company</button>-->
                    <div *ngIf="getPayeePledge(company)?.value">
                        <assistanceApplicationFieldLayout>
                            <label><a [routerLink]=" ['/app/pledge/', getPayeePledge(company)?.value]">View
                                Pledge</a></label>
                        </assistanceApplicationFieldLayout>
                    </div>
                </div>

            </ng-container>
        </assistanceApplicationFieldLayout>
        <!-- END PAYEE LOOP -->
        <assistanceApplicationFieldLayout>
            <button class="btn btn-primary pull-left" (click)="addPayee()">Add Another Payee</button>
        </assistanceApplicationFieldLayout>

        <assistanceApplicationFieldLayout>
            <label>Status:</label>
            <app-assistance-application-status [isPublic]="assistanceApplication?.isPublic"
                                               [control]="statusControl"></app-assistance-application-status>
        </assistanceApplicationFieldLayout>

        <assistanceApplicationFieldLayout *ngIf="denied">
            <label>Denial Reason:</label>
            <select [formControl]="denialReasonControl" class="form-control">
                <option [ngValue]="null" disabled>Choose a Denial Reason</option>
                <option *ngFor="let option of ASSISTANCE_APPLICATION_DENIAL_REASON_OPTIONS"
                        [value]="option.value">{{option.label}}</option>
            </select>
            <div class="alert alert-danger mt-2"
                 *ngIf="denialReasonControl.touched && denialReasonControl.hasError('required')">Denial reason is
                required
            </div>
        </assistanceApplicationFieldLayout>

        <assistanceApplicationFieldLayout *ngIf="denialReasonControl.value === AssistanceApplicationDenialReason.Other" [class.has-danger]="otherDenialReasonControl.touched && !otherDenialReasonControl.valid">
            <label>Explanation:</label>
            <input type="text" class="form-control" [formControl]="otherDenialReasonControl">
        </assistanceApplicationFieldLayout>

    </assistanceApplicationGroupLayout>

    <div class="row pb-5">
        <div class="col-sm-2">
            <div>Household Information:</div>
        </div>
        <div class="col-sm-10 p-t-3">
            <div *ngIf="assistanceApplication?.isPublic">
                <div><span
                        class="fw-bold">Applicant Household Income: </span>{{assistanceApplication.applicantHouseHoldIncome | parseNumber | currency:'USD':'symbol':'1.2-2'}}
                    <i *ngIf="hasDifferentHouseholdIncome" (click)="openDetailAlert(householdIncomeAlertMessage, '')"
                       style="padding-left: 5px;" class="fa fa-exclamation-circle warning-icon"></i></div>
                <div class="p-t-1 pb-5"><span
                        class="fw-bold">Applicant Individual Income: </span>{{assistanceApplication.applicantIndividualMonthlyIncome | parseNumber | currency:'USD':'symbol':'1.2-2'}}
                    <i *ngIf="hasDifferentIndividualIncome" (click)="openDetailAlert(individualIncomeAlertMessage, '')"
                       style="padding-left: 5px;" class="fa fa-exclamation-circle warning-icon"></i></div>
            </div>
            <app-assistance-application-household-information-form
                    [form]="form"></app-assistance-application-household-information-form>
        </div>
    </div>


    <div class="row">
        <div class="col-sm-2">
            <div>Assistance Request Description:</div>
        </div>
        <div class="col-sm-10 p-t-3">
            <label>Please select the areas you would like to request assistance in:</label>
            <div [formGroup]="assistanceApplicationAreasOfNeedGroup">
                <div class="m-t-1">
                    <div class="ps-4 pt-1 checkboxes">
                        <ng-container *ngFor="let assistanceDescription of assistanceOptions">
                            <div class="row mb-3">
                                <div class="col pe-3">
                                    <input [id]="assistanceDescription.key" type="checkbox"
                                           [formControlName]="assistanceDescription.key">
                                </div>
                                <div class="col">
                                    {{assistanceDescription.lookupValue}}
                                </div>
                            </div>
                            <div *ngIf="assistanceDescription.key === 'rent' && assistanceApplicationAreasOfNeedGroup.get('rent').value"
                                 class="row mb-3 p-l-2">
                                <div class="col-md-auto">
                                    Do you have notification from a court that your landlord is pursuing eviction?
                                </div>
                                <div class="col-md p-l-1">
                                    <input id="evictionNotification" type="checkbox"
                                           formControlName="evictionNotification">
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div style="padding-bottom: 70px;" class="row p-t-2">
        <div class="col-sm-2">
            <div>Document Upload:</div>
        </div>
        <div class="col-sm-10 p-t-2">
            <app-assistance-application-document-upload [baseForm]="form" [formArray]="filesFormArray"
                                                        [receivedForms]="assistanceApplication?.files"
                                                        [defaultOpenDocuments]="defaultOpenDocumentUpload"
                                                        (onDeleteFile)="onDeleteFile($event)"></app-assistance-application-document-upload>
        </div>
    </div>

    <ng-content></ng-content>
    <company-box (onCompanySelected)="onCompanySelected($event)"></company-box>
</form>
<div class="error-text">
    <span *ngFor="let error of formErrors$ | async">
        {{error}}
    </span>
</div>

<prompt-dialog
        [titleText]="'Client County Inactive'"
        promptText="{{clientCountyInactiveMessage}}">
</prompt-dialog>
<prompt-dialog #exceedsCategoryThreshold
               [size]="'lg'"
               [titleText]="'Payee category amounts exceed maximum allowance.'"
               [showTitle]="true"
               [allowCustomButton]="allowThresholdOverride"
               [customButtonText]="'Override and Save'"
               (customButtonEvent)="overrideSave()"
               promptText="The following is a list of entered payees and their associated category and amounts that were entered. Please verify that the values entered are within the maximum allowed amounts per category.">
    <div body>
        <app-exceeds-category-amount-threshold
                [utilityTotal]="utilityTotal"
                [rentalTotal]="rentalTotal"
                [transportationTotal]="transportationTotal"
                [childcareTotal]="childcareTotal">
        </app-exceeds-category-amount-threshold>
    </div>
</prompt-dialog>

<swal #loadingDialog [allowOutsideClick]="false" [showConfirmButton]="false" [showDenyButton]="false">
    <div *swalPortal>
        <div class="row title-row mb-2">
            <h2>{{savingTitle$ | async}}</h2>
        </div>
        <div class="row w-100 py-2">
            <div class="bg-gray-lighter progress-bar w-100">
                <progress class="progress progress-bar-upload" value="100" max="100"
                          [style.width]="percentComplete$ | async"></progress>
            </div>
        </div>
        <h3>{{savingMessage$ | async}}</h3>
    </div>
</swal>
