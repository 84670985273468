import {Inject, Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {select, Store} from '@ngrx/store';
import * as fromRoot from '../store';
import {Observable} from 'rxjs';
import {selectSecurityStateDataSecurityToken} from '../store/selectors/security.selectors';
import {ApiBaseUrl} from '../services/api.client';
import {NotificationService} from '../services/NotificationService';
import {SecurityService} from '../services/SecurityService';
import {Router} from '@angular/router';

@Injectable()
export class ApiTokenInterceptor implements HttpInterceptor {

    securityToken$: Observable<string>;
   constructor(private _store: Store<fromRoot.AppState>,
               private _notificationService: NotificationService,
               private _securityService: SecurityService,
               private _router: Router,
               @Inject(ApiBaseUrl) private baseUrl?: string
               ) {
       this.securityToken$ = this._store.pipe(select(selectSecurityStateDataSecurityToken));
   }

   intercept(req: HttpRequest<any>, next: HttpHandler)
       : Observable<HttpEvent<any>> {

       let securityToken: string;
       const url = req.url.toLowerCase();

       if (!this.checkUrl(url)) {
           return next.handle(req);
       }

       this.securityToken$.subscribe(token => {
           securityToken = token;
           const expiry = (JSON.parse(atob(token?.split('.')[1]))).exp;
           const currentTime = Math.floor(Date.now() / 1000);
           if (currentTime >= expiry && !url.endsWith('login')) {
               this._notificationService.showError('Token has expired. You need to log in again.');
               this._securityService.logout();
               this._router.navigate(['/login']).then();
               return next.handle(req);
           }
       });

       const header = 'Bearer ' + securityToken;
       const headers = req.headers.set('Authorization', header);
       req = req.clone({ headers });
       return next.handle(req);
   }

    private checkUrl(url: string): boolean {
        return url.startsWith(this.baseUrl);
    }
}
