import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {combineLatestWith, from as observableFrom, Observable, of, Subscription} from 'rxjs';

import {filter, map, mergeMap, tap} from 'rxjs/operators';
import {Client, KcApplication, KcApplicationClient, KcProgram, User} from '../../../../../services/api.client';
import {KcApplicationService} from '../../../../../services/KcApplicationService';
import {NotificationService} from '../../../../../services/NotificationService';
import {SystemService} from '../../../../../services/SystemService';
import {Unsubscribe} from '../../../../../utilities/Unsubscribe';
import {ClientEnrollmentFormComponent, KcApplicationSubmitType} from '../../ClientEnrollmentFormComponent';
import {ClientCreateApplicationComponent} from '../ClientCreateApplicationComponent';
import {select, Store} from '@ngrx/store';
import * as fromRoot from '../../../../../store';
import {selectClientDataCurrentClient} from '../../../../../store/selectors/client.selectors';
import {selectKcAppDataCurrentSelectedKcApplication} from '../../../../../store/selectors/kcApplication.selectors';
import {
    selectSecurityStateDataCurrentUser,
    selectSecurityStateDataSystemDataSettingKcFplAcceptanceRate
} from '../../../../../store/selectors/security.selectors';

@Component({
    selector: 'clientEnrollment',
    templateUrl: './ClientEnrollmentComponent.html',
    // styleUrls: ['./ClientVerificationComponent.scss']
})

export class ClientEnrollmentComponent implements OnInit, AfterViewInit {

    @ViewChild(ClientEnrollmentFormComponent)
    form: ClientEnrollmentFormComponent;

    user: User;
    user$: Observable<User>;

    client: Client;
    client$: Observable<Client>;

    kcPrograms: KcProgram[] = [];


    kcFpAcceptanceRate: number | null;
    kcFpAcceptanceRate$: Observable<number | null>;

    private currentProgram: KcProgram;
    incompleteNewApplication$: Observable<KcApplication>;
    incompleteNewApplication: KcApplication;

    @Unsubscribe()
    _incompleteNewAppSub: Subscription;


    constructor(private parent: ClientCreateApplicationComponent,
                private kcApplicationService: KcApplicationService,
                private notificationService: NotificationService,
                private kcApplicationClient: KcApplicationClient,
                private router: Router,
                private route: ActivatedRoute,
                private system: SystemService,
                private _store: Store<fromRoot.AppState>) {
        this.user$ = this._store.pipe(select(selectSecurityStateDataCurrentUser));
        this.client$ = this._store.pipe(select(selectClientDataCurrentClient));
        this.kcFpAcceptanceRate$ = this._store.pipe(select(selectSecurityStateDataSystemDataSettingKcFplAcceptanceRate));
        this.incompleteNewApplication$ = this._store.pipe(select(selectKcAppDataCurrentSelectedKcApplication));
    }

    ngOnInit(): void {
        this.user$.subscribe(user => this.user = user);
        this.client$.subscribe(client => this.client = client);
        this.kcFpAcceptanceRate$.subscribe(kcFpAcceptanceRate => this.kcFpAcceptanceRate = kcFpAcceptanceRate);
        this.incompleteNewApplication$.subscribe(incompleteNewApplication => this.incompleteNewApplication = incompleteNewApplication);
    }

    back(): void {
        this.parent.back();
    }

    next(): void {
        this.parent.next();
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            const {form, client$, incompleteNewApplication$, system} = this;

            const kcStatus$ = this.system.kcStatusNew$;

            const kcProgram$ = client$
                .modelExists()
                .pipe(
                    filter(client => client.federalPovertyLevelDisplay !== null && client.federalPovertyLevelDisplay !== undefined),
                    mergeMap((client) => this.kcApplicationClient.retrieveKcPrograms(client.federalPovertyLevelDisplay)),
                    tap((programs) => {
                        this.kcPrograms = programs || [];
                    }),
                    filter((programs) => programs && !!programs.length),
                    map((programs) => programs[0]),
                );
            kcProgram$.subscribe(program => {
                // set validators that are only applied on create
                this.currentProgram = program;
                this.form.kcFpAcceptanceRateControl.setValidators([this.kcFpAcceptanceRateValidator.bind(this)]);
                this.form.kcFpAcceptanceRateControl.updateValueAndValidity();
            });

            function getSetupNewApplication$(): any {
                return client$
                    .modelExists().pipe(
                        combineLatestWith(kcStatus$, kcProgram$),
                        tap(([client, status, kcProgram]) => {
                            form.clientIdControl.setValue(client.id);
                            form.clientLast4SsnControl.setValue(client.last4Ssn);
                            form.clientFederalPovertyLevelDisplay.setValue(client.federalPovertyLevelDisplay);

                            form.keepingCurrentStatusIdControl.setValue(status.id);
                            form.statusNameControl.setValue(status.statusName);
                            form.keepingCurrentProgramIdControl.setValue(kcProgram.id);
                        }),);
            }

            function getSetupIncompleteApplication$(application: KcApplication): Observable<any> {
                return observableFrom([application]).pipe(
                    combineLatestWith(kcStatus$, kcProgram$, of(application)),
                    tap(() => {
                        form.populate(application);
                        if (form.applicationStartedButNotSubmitted) {
                            form.accountNumberControl.disable();
                            form.keepingCurrentProgramIdControl.disable();
                            form.setCalculatorEnabledStatus(false);
                            form.upfrontAmountDueControl.disable();
                            form.grandTotalEstimatedCreditsControl.disable();
                            form.upfrontPaySourceControl.disable();
                        }

                    }));

            }

            this._incompleteNewAppSub = incompleteNewApplication$.pipe(
                mergeMap((application) => application ? getSetupIncompleteApplication$(application) as any : getSetupNewApplication$()))
                .subscribe();
        });
    }

    kcFpAcceptanceRateValidator(): any {
        const currentDisplayLevelPercentage = this.client.federalPovertyLevelDisplay * 100;
        if (currentDisplayLevelPercentage > this.currentProgram.maxLevel) {
            return {kcFpAcceptanceRate: 'User must have a federal poverty level of ' + currentDisplayLevelPercentage + '% or lower'};
        }

        if (currentDisplayLevelPercentage < this.currentProgram.minLevel) {
            return {kcFpAcceptanceRate: 'User must have a federal poverty level of ' + currentDisplayLevelPercentage + '% or higher'};
        }

        return null;
    }


    onSubmit(type: KcApplicationSubmitType): void {
        switch (type) {
            case 'New':
                this.parent.next();
                break;
            case 'Save':
                break;
            case 'Reject':
                break;
            case 'Cancel':
                break;
            case 'Delete':
                this.parent.leaveForm();
                break;
        }

    }
}
