<div class="row">
  <div class="col-sm-12 col-md-12 col-lg-10 offset-lg-1">
    <h4>Pledge Summary Report</h4>
    <p>
      The report below shows comprehensive Pledge information for any group of Pledges you select.
      If you would like to know how many of a certain 'Fund Type/Pledge Status', then select your desired critieria and
      export the results to Excel.
      From there you can easily get pledge counts; save pledge details to your hard-drive; and/or verify pledge details
      relative to your other systems (i.e. verify a customer's account number, account balance, etc).
    </p>
  </div>
  <div class="col-sm-12 col-md-12 col-lg-10 offset-lg-1">
    <form paymentProcessingForm #form
          [showDisplayPledgeStatus]="true"
          [showDateRange]="true"
          [showPledgeTypes]="true"
          [showCompany]="true" novalidate>
      <div footer class="text-right">
        <button class="btn btn-primary" (click)="form.submitPendingPledgesSummary()">Show Results</button>
      </div>
    </form>
  </div>
  <div class="col-xs-12 m-b-1 m-t-1">
    <pledgeReportSummaryTable [rows]="reports$ | async"></pledgeReportSummaryTable>
  </div>
</div>
