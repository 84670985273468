<div class="container">
  <h1 class="page-title">Company Overview</h1>
  <section class="widget">
    <div class="widget-body">
      <div class="mt">
        <form class="form-horizontal" [formGroup]="form" novalidate>
          <div class="row form-group">
            <label class="col-md-3"><strong>Company:</strong></label>
            <div class="col-md-8">
              <div class="row form-field-spacing" [class.has-danger]="!name.valid && name.touched">
                <span class="m-t-05 required-label col-md-3">Name</span>
                <input [formControl]="name" class="form-control col-md-8" type="text"
                       formControlName="name"
                       placeholder="Company Name">
              </div>
              <div class="row form-field-spacing">
                <span class="m-t-05 required-label col-md-3">UWDA Company</span>
                <label class="custom-checkbox custom-control">
                  <input [formControl]="isUwdaCompany" class="form-control col-md-8 custom-control-input align-checkbox"
                         type="checkbox">
                  <span class="custom-control-indicator"></span>
                  <span class="required-label custom-control-description"></span>
                </label>
              </div>
              <div class="row form-field-spacing form-group"
                   [class.has-danger]="!regionValue.valid && regionValue.touched">
                <span class="m-t-05 required-label col-xs-3">Regions</span>
                <div class="col-xs-8 p-l-0">
                  <div class="row">
                    <div class="col-xs-12" *ngFor="let region of allRegions">
                      <label class="custom-control custom-checkbox">
                        <input class="form-control custom-control-input align-checkbox"
                               checkboxArray
                               [formControl]="regionValue"
                               type="checkbox"
                               name="allRegions"
                               id="regionValue"
                               [ngValue]="region.key">
                        <span class="custom-control-indicator"></span>
                        <span class="required-label custom-control-description">{{region.lookupValue}}</span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row form-field-spacing">
                <span class="m-t-05 col-md-3">Fund Types</span>
                <div class="p-l-0 col-md-8">
                  <pledgeTypeCheckbox [control]="pledgeTypeIds"></pledgeTypeCheckbox>
                </div>
              </div>

              <div class="row form-field-spacing" [class.has-danger]="!status.valid && status.touched">
                <span class="m-t-05 required-label col-md-3">Status</span>
                <select [formControl]="status" required class="form-control col-md-8 custom-select"
                        type="text"
                        formControlName="status">
                  <option value="null">Choose a Status</option>
                  <option *ngFor="let enumName of statuses" [value]="enumName.key">{{enumName.key}}</option>
                </select>
              </div>
            </div>
          </div>
          <div class="row form-group">
            <label class="col-md-3"><strong>Address: </strong></label>
            <div class="col-md-8">
              <div class="row form-field-spacing"
                   [class.has-danger]="!address1.valid && address1.touched">
                <span class="m-t-05 required-label col-md-3">Address</span>
                <input [formControl]="address1" required class="form-control col-md-8" type="text"
                       formControlName="address1" placeholder="Address">
              </div>
              <div class="row form-field-spacing">
                <span class="m-t-05 col-md-3">Unit/Suite</span>
                <input [formControl]="address2" class="form-control col-md-8" type="text"
                       formControlName="address2"
                       placeholder="Unit/Suite">
              </div>
              <div class="row form-field-spacing" [class.has-danger]="!city.valid && city.touched">
                <span class="m-t-05 required-label col-md-3">City</span>
                <input [formControl]="city" required class="form-control col-md-8" type="text"
                       formControlName="city"
                       placeholder="City">
              </div>
              <div class="row form-field-spacing" [class.has-danger]="!state.valid && state.touched">
                <span class="m-t-05 required-label col-md-3">State</span>
                <select [formControl]="state" required class="form-control col-md-8 custom-select"
                        type="text"
                        formControlName="state" placeholder="State">
                  <option value="null">Choose a State</option>
                  <option *ngFor="let state of states" [value]="state.key">{{state.lookupValue}}
                  </option>
                </select>
              </div>
              <div class="row form-field-spacing" [class.has-danger]="!zipCode.valid && zipCode.touched">
                <span class="m-t-05 required-label col-md-3">Zipcode</span>
                <input [formControl]="zipCode" required class="form-control col-md-8" type="text"
                       formControlName="zipCode" placeholder="Zipcode" numbersOnly>
              </div>
              <div class="row form-field-spacing">
                <span class="m-t-05 col-md-3">Url Link</span>
                <input [formControl]="urlLink" class="form-control col-md-8" type="text"
                       formControlName="urlLink" placeholder="http://www.company.com">
              </div>
              <div class="row form-field-spacing">
                <span class="m-t-05 col-md-3">Url Button Title</span>
                <input [formControl]="urlDisplay" class="form-control col-md-8" type="text"
                       formControlName="urlDisplay" placeholder="Button Name">
              </div>
              <div class="row form-field-spacing">
                <span class="m-t-05 col-md-3">Sort Order</span>
                <input [formControl]="sortOrder" class="form-control col-md-8" numbersOnly type="text"
                       formControlName="sortOrder" placeholder="Sort Order">
              </div>
            </div>
          </div>
          <div class="row form-group">
            <label class="col-md-3"><strong>Contact Info: </strong></label>
            <div class="col-md-8">
              <div class="row form-field-spacing">
                <span class="m-t-05 col-md-3">Contact Name 1</span>
                <input [formControl]="contactName1" class="form-control col-md-8" type="text"
                       formControlName="contactName1" placeholder="Contact Name">
              </div>
              <div class="row form-field-spacing">
                <span class="m-t-05 col-md-3">Contact Phone 1</span>
                <input [formControl]="contactPhone1" class="form-control col-md-8" type="text"
                       formControlName="contactPhone1" placeholder="Phone"
                       [imask]="phoneMask">
              </div>
              <div class="row form-field-spacing">
                <span class="m-t-05 col-md-3">Contact Name 2</span>
                <input [formControl]="contactName2" class="form-control col-md-8" type="text"
                       formControlName="contactName2" placeholder="Contact Name">
              </div>
              <div class="row form-field-spacing">
                <span class="m-t-05 col-md-3">Contact Phone 2</span>
                <input [formControl]="contactPhone2" class="form-control col-md-8" type="text"
                       formControlName="contactPhone2" placeholder="Phone"
                       [imask]="phoneMask">
              </div>
              <div class="row form-field-spacing">
                <span class="m-t-05 col-md-3">Contact Name 3</span>
                <input [formControl]="contactName3" class="form-control col-md-8" type="text"
                       formControlName="contactName3" placeholder="Contact Name">
              </div>
              <div class="row form-field-spacing">
                <span class="m-t-05 col-md-3">Contact Phone 3</span>
                <input [formControl]="contactPhone3" class="form-control col-md-8" type="text"
                       formControlName="contactPhone3" placeholder="Phone"
                       [imask]="phoneMask">
              </div>
              <div class="row form-field-spacing"  [class.has-danger]="!payeeType.valid && payeeType.touched">
                <span class="m-t-05 required-label col-md-3">Payee Type</span>
                  <select [formControl]="payeeType"
                          required
                          type="text"
                          class="form-control col-md-8 custom-select"
                          formControlName="payeeType"
                  >
                      <option [ngValue]="null">Choose Company or Individual Payee</option>
                      <option *ngFor="let payeeType of payeeTypesSelect"
                              [value]="payeeType.key">{{payeeType.value}}</option>
                  </select>
              </div>
                <div class="row form-field-spacing"  [class.has-danger]="!category.valid && category.touched">
                    <span class="m-t-05 required-label col-md-3">Category</span>
                    <select [formControl]="category"
                            required
                            type="text"
                            class="form-control col-md-8 custom-select"
                            formControlName="category"
                    >
                        <option [ngValue]="null">Choose a Category</option>
                        <option *ngFor="let category of categorySelect"
                                [value]="category.key">{{category.value}}</option>
                    </select>
                </div>
            </div>
          </div>
          <div class="row form-group">
            <label class="col-md-3"><strong>Account Validation Rules</strong></label>
            <div class="col-md-8">
              <div class="row">
                <span class="m-t-05 col-md-3">Enforce Validation</span>
                <div class="col-md-8">
                  <div class="custom-checkbox-padding">
                    <label class="custom-control custom-checkbox">
                      <input [formControl]="isAccountValidationEnforced" type="checkbox"
                             class="form-control custom-control-input"
                             formControlName="isAccountValidationEnforced"
                             id="isAccountValidationEnforced">
                      <span class="custom-control-indicator"></span>
                      <span class="custom-control-description">Enforce Account # validation during Pledge Entry?</span>
                    </label>
                  </div>
                </div>
              </div>
              <div *ngIf="isAccountValidationEnforced.value">
                <div class="row">
                  <span class="m-t-05 col-md-3">Valid Characters</span>
                  <div class="col-md-8">
                    <div class="custom-controls-stacked">
                      <label class="custom-control custom-radio">
                        <input [formControl]="isAccountValid"
                               value="numeric"
                               class="custom-control-input"
                               type="radio" name="setAccountValidationDetails"
                               id="isAccountValidNumerical">
                        <span class="custom-control-indicator"></span>
                        <span class="custom-control-description">Allow only numeric characters for the Account #</span>
                      </label>
                      <label class="custom-control custom-radio">
                        <input [formControl]="isAccountValid"
                               value="alphabetical"
                               class="custom-control-input"
                               type="radio" name="setAccountValidationDetails"
                               id="isAccountValidAlphabetical">
                        <span class="custom-control-indicator"></span>
                        <span
                          class="custom-control-description">Allow only alphabetical characters for the Account #</span>
                      </label>
                      <label class="custom-control custom-radio">
                        <input [formControl]="isAccountValid"
                               value="alphabeticalAndNumeric"
                               class="custom-control-input"
                               type="radio"
                               id="setAccountValidationBoth"
                        name="setAccountValidationBoth">
                        <span class="custom-control-indicator"></span>
                        <span
                          class="custom-control-description">Allow alpha & numeric characters for the Account #</span>
                      </label>
                    </div>
                    <div class="custom-checkbox-padding">
                      <label class="custom-control custom-checkbox">
                        <input [formControl]="isAccountValidSpecialChars" type="checkbox"
                               class="custom-control-input"
                               formControlName="isAccountValidSpecialChars"
                               id="isAccountValidSpecialChars">
                        <span class="custom-control-indicator"></span>
                        <span
                          class="custom-control-description">Allow special characters (i.e. spaces, dashes, etc.)</span>
                      </label>
                    </div>
                  </div>
                </div>
                <div class="row form-field-spacing">
                  <span class="m-t-05 col-md-3">Min # of characters</span>
                  <input [formControl]="accountValidationMinLen" class="form-control col-md-8"
                         type="text" numbersOnly
                         formControlName="accountValidationMinLen">
                </div>
                <div class="row form-field-spacing">
                  <span class="m-t-05 col-md-3">Max # of characters</span>
                  <input [formControl]="accountValidationMaxLen" class="form-control col-md-8"
                         type="text" numbersOnly
                         formControlName="accountValidationMaxLen">
                </div>
                <div class="row form-field-spacing">
                  <span class="m-t-05 col-md-3">Account Validation Error Message</span>
                  <textarea [formControl]="accountValidationErrorMsg" class="form-control col-md-8"
                            formControlName="accountValidationErrorMsg"></textarea>
                </div>
              </div>
            </div>
          </div>
          <!--submit-->
          <div class="row form-group">
            <div class="col-md-3">
            </div>
            <div class="col-md-8 btn-form">
              <cancel-button class="p-l-1 pull-right" [route]="'/app/companies'"></cancel-button>
              <!--save-->
              <button [disable]="isValidSave()"
                      [autoDisable]="submit"
                      type="submit"
                      class="btn btn-primary">Save Company
              </button>
              <!--delete-->
              <button type="button"
                      class="btn btn-inverse"
                      (click)="showPromptModal()">Delete Company
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
    <prompt-dialog
      [titleText]="'Delete Company?'"
      [promptText]="'Are you sure you want to delete this company?'">
    </prompt-dialog>
  </section>
</div>
