import {share} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {getMessageFromException} from '../utilities/Util';
import {NotificationService} from './NotificationService';
import {FederalPovertyLevel, FederalPovertyLevelClient, SimpleDecimalResult} from './api.client';
import {Store} from '@ngrx/store';
import * as fromRoot from '../store';
import {Observable} from 'rxjs';
import {currentClientFederalPovertyLevelsData, setCurrentSelectedFederalPovertyLevel} from '../store/actions/federalPovertyLevel.actions';


@Injectable()
export class FederalPovertyLevelService {

  constructor(private _federalPovertyLevelApiService: FederalPovertyLevelClient,
              private _notificationService: NotificationService,
              private _store: Store<fromRoot.AppState>) {
  }

  addFederalPovertyLevel(federalPovertyLevel: FederalPovertyLevel): Observable<FederalPovertyLevel> {
    const request = this._federalPovertyLevelApiService.addFederalPovertyLevel(federalPovertyLevel).pipe(share());
    request.subscribe({ next: (result) => {
      this.setCurrentSelectedFederalPovertyLevel(result);
      this._notificationService.showSuccess('Keeping Current Application Successfully Created!');
    }, error: (err) => {
        this._notificationService.showError(getMessageFromException(err));
    }, complete: () => {

    }});
    return request;
  }

  updateFederalPovertyLevel(federalPovertyLevel: FederalPovertyLevel): Observable<FederalPovertyLevel> {
    const request = this._federalPovertyLevelApiService.updateFederalPovertyLevel(federalPovertyLevel).pipe(share());
    request.subscribe({ next: (result) => {
      this.setCurrentSelectedFederalPovertyLevel(result);
      this._notificationService.showSuccess('Keeping Current Application Succesfully Updated!');
    }, error: () => {
      this._notificationService.showError('There was a problem updating this Keeping Current Application.');
    }, complete: () => {

    }});
    return request;
  }

  validateFederalPovertyLevel(houseHoldSize: number, houseHoldIncome: number): Observable<SimpleDecimalResult> {
    const request = this._federalPovertyLevelApiService.calculateFederalPovertyLevel(houseHoldSize, houseHoldIncome).pipe(share());
    request.subscribe({ next: (result) => {
      this.setCurrentClientFederalPovertyLevelAction(result.result);
    }, error: () => {
      this._notificationService.showError('There was a problem retrieving the Poverty Level of the selected client.');
    }, complete: () => {

    }});
    return request;
  }

  setCurrentSelectedFederalPovertyLevel(federalPovertyLevel: FederalPovertyLevel): void {
    this.setCurrentSelectedFederalPovertyLevelAction(federalPovertyLevel);
  }

  private setCurrentSelectedFederalPovertyLevelAction(selectedFederalPovertyLevel: FederalPovertyLevel): void {
    this._store.dispatch(setCurrentSelectedFederalPovertyLevel({ payload: selectedFederalPovertyLevel }));
  }

  private setCurrentClientFederalPovertyLevelAction(currentClientFederalPovertyLevel: number): void {
    this._store.dispatch(currentClientFederalPovertyLevelsData({ payload: currentClientFederalPovertyLevel }));
  }
}
