import {createFeatureSelector, createSelector, select} from '@ngrx/store';
import {ISecurityState} from '../ISecurityState';

const securityStateDataFeatureSelector = createFeatureSelector<ISecurityState>('security');
export const selectSecurityStateDataSystemData = createSelector(
    securityStateDataFeatureSelector,
    (securityState) => securityState.systemData,
);

export const selectSecurityStateDataIsLoggingIn = createSelector(
    securityStateDataFeatureSelector,
    (state) => state.isLoggingIn
);

export const selectSecurityStateDataIsAuthenticated = createSelector(
    securityStateDataFeatureSelector,
    (state) => state.isAuthenticated
);

export const selectSecurityStateDataLoginMessage = createSelector(
    securityStateDataFeatureSelector,
    (state) => state.loginMessage
);

export const selectSecurityStateDataCurrentUser = createSelector(
    securityStateDataFeatureSelector,
    (state) => state.currentUser
);

export const selectSecurityStateDataSystemDataKcStatuses = createSelector(
    securityStateDataFeatureSelector,
    (state) => state.systemData?.kcStatuses || []
);

export const selectSecurityStateDataSecurityToken = createSelector(
    securityStateDataFeatureSelector,
    (state) => state.securityToken
);

export const selectSecurityStateDataSecurityDataPledgeTypes = createSelector(
    securityStateDataFeatureSelector,
    (state) => state.systemData?.pledgeTypes
);

export const selectSecurityStateDataSystemDataPrefix = createSelector(
    securityStateDataFeatureSelector,
    (state) => state.systemData?.prefix
);

export const selectSecurityStateDataSystemDataSuffix = createSelector(
    securityStateDataFeatureSelector,
    (state) => state.systemData?.suffix
);

export const selectSecurityStateDataSystemDataStates = createSelector(
    securityStateDataFeatureSelector,
    (state) => state.systemData?.states
);

export const selectSecurityStateDataSystemDataEmploymentStatus = createSelector(
    securityStateDataFeatureSelector,
    (state) => state.systemData?.employmentStatus
);

export const selectSecurityStateDataCurrentUserUserType = createSelector(
    securityStateDataFeatureSelector,
    (state) => state.currentUser?.userType?.toString()
);

export const selectSecurityStateDataSystemDataUnionInfo = createSelector(
    securityStateDataFeatureSelector,
    (state) => state.systemData?.unionInfo
);

export const selectSecurityStateDataSystemDataKcPrograms = createSelector(
    securityStateDataFeatureSelector,
    (state) => state.systemData?.kcPrograms
);

export const selectSecurityStateDataSystemDataSettingKcFplAcceptanceRate = createSelector(
    securityStateDataFeatureSelector,
    (state) => state.systemData?.setting?.kcFplAcceptanceRate
);

export const selectSecurityStateDataSystemDataAssistanceType = createSelector(
    securityStateDataFeatureSelector,
    (state) => state.systemData?.assistanceType
);

export const selectSecurityStateDataSystemDataRegion = createSelector(
    securityStateDataFeatureSelector,
    (state) => state.systemData?.region
);

export const selectSecurityStateDataSystemDataZones = createSelector(
    securityStateDataFeatureSelector,
    (state) => state.systemData?.zones
);

export const selectSecurityStateDataSystemDataGender = createSelector(
    securityStateDataFeatureSelector,
    (state) => state.systemData?.gender
);

export const selectSecurityStateDataSystemDataRace = createSelector(
    securityStateDataFeatureSelector,
    (state) => state.systemData?.race
);

export const selectSecurityStateDataSystemDataAdministrativeAgencyFundAssistanceOptions = createSelector(
    securityStateDataFeatureSelector,
    (state) => state.systemData?.administrativeAgencyFundAssistance
);
export const selectSecuritySecurityDataCurrentUserHasEfs = createSelector(
    securityStateDataFeatureSelector,
    (state) => state.currentUser?.hasEfs
);

export const selectSecurityStateDataCurrentUserHasEfn = createSelector(
    securityStateDataFeatureSelector,
    (state) => state.currentUser?.hasEfn
);

export const selectSecuritySecurityDataVerifiedClientIed = createSelector(
    securityStateDataFeatureSelector,
    (state) => state.verifiedClientId
);

export const selectSecurityDefaultManagementAgency = createSelector(
    securityStateDataFeatureSelector,
    (state) => state.defaultManagementAgency
);
