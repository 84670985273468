export class KeepingCurrentReportUrls {

	public static DOWNLOAD_CONFIRM_ORPHAN_REPORT = '/api/v1/keepingcurrentreport/downloadconfirmorphanreport';
	public static DOWNLOAD_MISSED_PAYMENT_REPORT = '/api/v1/keepingcurrentreport/downloadmissedpaymentreport';
	public static DOWNLOAD_ENROLLMENT_REPORT = '/api/v1/keepingcurrentreport/downloadenrollmentreport';
	public static DOWNLOAD_APPROVED_ENROLLMENTS_REPORT = '/api/v1/keepingcurrentreport/downloadapprovedenrollmentsreport';
	public static DOWNLOAD_BUDGET_BILLING_REPORT = '/api/v1/keepingcurrentreport/downloadbudgetbillingreport';
	public static DOWNLOAD_COMPLETION_REPORT = '/api/v1/keepingcurrentreport/downloadcompletionreport';

}
