<div class="row force-full-width">
    <div class="col-xs-12">
        <div class="clearfix">
            <ul class="nav nav-tabs pull-xs-left" role="tablist">
                <li class="nav-item">
                    <a class="nav-link" (click)="tab = 'activityLog'; false;"
                                        [class.active]="tab === 'activityLog'">Activity Log
                    </a>
                </li>
                <li *ngIf="canAudit" class="nav-item">
                    <a class="nav-link" (click)="tab = 'auditLog'; false;" [class.active]="tab === 'auditLog'">Audit Log
                    </a>
                </li>
            </ul>
        </div>
        <div class="tab-content mb-lg overflow-visible">
            <div role="tabpanel" class="tab-pane active in" [ngSwitch]="tab">
                <app-assistance-application-activity-audit *ngIf="tab === 'activityLog'" [caseId]="assistanceApplication?.id">
                </app-assistance-application-activity-audit>
                <app-audit-state-list *ngIf="tab === 'auditLog' && canAudit" [assistanceApplicationId]="assistanceApplication?.id">
                </app-audit-state-list>
            </div>
        </div>
    </div>
</div>
