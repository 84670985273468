import {EMPTY as observableEmpty, Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import {Component, Input, forwardRef, EventEmitter, Output, OnInit} from '@angular/core';
import {FormControl, UntypedFormControl} from '@angular/forms';
import {EfnService} from '../../../../../../services/EfnService';
import {NotificationService} from '../../../../../../services/NotificationService';
import {Validators} from '../../../../../../utilities/Validators';
import {ActivatedRoute, Router} from '@angular/router';
import {EfnCaseHelper} from '../EfnHelper';
import {CommitEfnCaseParameters, EfnCase} from '../../../../../../services/api.client';
import {AutoDisableParent} from '../../../../../../directives/AutoDisableDirective';
import {User} from '../../../../../../services/api.client';
import {EfnCaseStatusType} from '../../../../../../services/api.client';
import {extractEnumNames} from '../../../../../../utilities/Util';
import {AgencyEfnCase} from '../../../../../../services/api.client';
import {select, Store} from '@ngrx/store';
import * as fromRoot from '../../../../../../store';
import {selectSecurityStateDataCurrentUser} from '../../../../../../store/selectors/security.selectors';

interface ICommitValidator {
  totalNeedAmount: number;
  totalNeedBalance: number;
  oldCommit: number;
}

function overCommit(commitSelector: () => ICommitValidator): any {
  return (control: UntypedFormControl): { [key: string]: any } => {
    const {totalNeedAmount, totalNeedBalance, oldCommit} = commitSelector();
    let newCommit = parseFloat(control.value);
    newCommit = (Number.isNaN(newCommit)) ? 0 : newCommit;
    const futureTotalNeedBalance = totalNeedBalance + (newCommit - (oldCommit || 0));
    if (futureTotalNeedBalance > totalNeedAmount) {
      return {overCommit: 'You cannot commit over the total need amount'};
    }
    return null;
  };
}

@Component({
  selector: 'efnCommit',
  templateUrl: './EfnCommitComponent.html',
  styleUrls: ['./EfnCommitComponent.scss'],
  providers: [{provide: AutoDisableParent, useExisting: forwardRef(() => EfnCommitComponent)}],
})
export class EfnCommitComponent implements OnInit {
  efnCaseStatuses = extractEnumNames(EfnCaseStatusType);
  currentUser: User;
  currentUser$: Observable<User>;

  @Output()
  commitSuccess = new EventEmitter<void>();

  _efnCase: EfnCase;
  existingCommit: { commitNote: string, originalCommitAmount: number };

  @Input()
  set efnCase(efnCase: EfnCase) {
    this._efnCase = efnCase;
    this.commitAmount.updateValueAndValidity();
  }

  get efnCase(): EfnCase {
    return this._efnCase;
  }

  get efnCaseId(): string {
    return this.efnCase && this.efnCase.id;
  }

  get agencyId(): string {
    return this.currentUser.agencyId;
  }

  commitNote = new FormControl(null);
  commitAmount = new FormControl(null, Validators.compose([Validators.required, overCommit(() => {
    return {
      totalNeedAmount: (this._efnCase && this._efnCase.totalNeed) || 0,
      totalNeedBalance: (this._efnCase && this._efnCase.currentTotal) || 0,
      oldCommit: (this.existingCommit && this.existingCommit.originalCommitAmount) || 0
    } as ICommitValidator;
  })]));

  constructor(private efnService: EfnService,
              private notification: NotificationService,
              public helper: EfnCaseHelper,
              private router: Router,
              private route: ActivatedRoute,
              private _store: Store<fromRoot.AppState>) {
    this.currentUser$ = this._store.pipe(select(selectSecurityStateDataCurrentUser));
  }

  ngOnInit(): void {
    this.helper.getAgencyCommit$()
      .subscribe((commit) => {
        if (commit.commitNote) {
          this.commitNote.setValue(commit.commitNote);
        }
        if (commit.originalCommitAmount) {
          this.commitAmount.setValue(commit.originalCommitAmount);
        }
        if (commit.commitNote || commit.originalCommitAmount) {
          this.existingCommit = commit;
        }
        this.commitAmount.updateValueAndValidity();
      });

    this.currentUser$.subscribe(user => this.currentUser = user);
  }

  isValid(): boolean {
    return !!(this.efnCase && this.commitAmount.valid);
  }

  decline(): Observable<void> {
    if (!this.hasEfnAndAgencyId()) {
      this.notification.showError('Missing EFN Case ID or Agency ID.');
      this.markAsTouched();
      return observableEmpty;
    }
    return this.efnService.declineEfnCase({
      efnCaseId: this.efnCaseId,
      agencyId: this.agencyId,
    }).pipe(tap(() => this.router.navigate(['../'], {relativeTo: this.route})));
  }

  hasEfnAndAgencyId(): boolean {
    return !!(this.efnCaseId && this.agencyId);
  }

  markAsTouched(): void {
    this.commitAmount.markAsTouched();
    this.commitNote.markAsTouched();
  }


  commit(): Observable<AgencyEfnCase> {
    if (!this.hasEfnAndAgencyId()) {
      this.notification.showError('Missing EFN Case ID or Agency ID.');
      return observableEmpty;
    }
    if (!this.isValid()) {
      this.markAsTouched();
      return observableEmpty;
    }
    return this.efnService.commitEfnCase(CommitEfnCaseParameters.fromJS({
      efnCaseId: this.efnCaseId,
      agencyId: this.agencyId,
      commitAmount: this.commitAmount.value,
      commitNote: this.commitNote.value
    })).pipe(tap((agencyCase: AgencyEfnCase) => {
      this.notification.showSuccess('Successfully updated commit.');
      if (this.existingCommit) {
        this.existingCommit.originalCommitAmount = parseFloat(this.commitAmount.value);
      } else {
        this.existingCommit = {
          commitNote: agencyCase.commitNote,
          originalCommitAmount: agencyCase.originalCommitAmount
        };
      }
      this.commitAmount.updateValueAndValidity();
      this.helper.reloadEfnCase();
    }));
  }

  createPledge(): void {

  }
}
