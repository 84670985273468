import {Component, Input} from '@angular/core';
import {AssistanceApplication} from '../../../../../../services/api.client';

@Component({
  selector: 'app-activity-audit-tabs',
  templateUrl: './activity-audit-tabs.component.html',
  styleUrls: ['./activity-audit-tabs.component.scss']
})
export class ActivityAuditTabsComponent {
  @Input() assistanceApplication: AssistanceApplication;
  @Input() canAudit: boolean;
  tab: any = 'activityLog';

  constructor() {
  }
}
