<modal #modal>
    <modal-header>
        <div class="row">
            <div class="col-md-12">
                <h4 class="modal-title m-b-05">Companies</h4>
            </div>
        </div>
        <form (submit)="filterCompanies()" novalidate class="form-group">
            <div class="row pb-2">
                <div class="col-md-4">
                    <label class="form-control-label">Company Name:</label>
                </div>
                <div class="col-md-8">
                    <input type="text"
                           placeholder="Search By Name"
                           class="form-control"
                           [formControl]="searchBox" style="">
                </div>
            </div>

            <div class="row pb-2">
                <div class="col-md-4">
                    <label>Company Category:</label>
                </div>
                <div class="col-md-8 w-100">
                    <select style="width: 100%" [formControl]="companyCategoryControl" class="form-control w-100">
                        <option [ngValue]="null" disabled>Choose a Company Category</option>
                        <option *ngFor="let option of categoryTypesSelect"
                                [value]="option.key">{{ option.value }}
                        </option>
                    </select>
                </div>
            </div>
            <button type="submit" class="btn btn-primary pull-right">Search</button>
            <button type="button" (click)="resetFilters()" class="btn btn-secondary pull-right m-r-05">Clear</button>
        </form>
    </modal-header>
    <modal-body>
        <table-dynamic id="datatable-table"
                       class="table table-striped table-hover"
                       [currentPage]="currentPage"
                       [totalCount]="totalCount"
                       [headers]="headers"
                       (pageChanged)="setPage($event)"
                       (limitChange)="setLimit($event)">
            <tr *ngFor="let company of companies" [ngClass]="{selected: company == selectedRow}"
                (click)="setClickedRow(company)">
                <td><span>{{ company.name }}</span></td>
                <td><span>{{ getLastUsedDate(company.lastUsedDate) }}</span></td>
                <td><span>{{ company.categoryLabel }}</span></td>
            </tr>
        </table-dynamic>
    </modal-body>
    <modal-footer>
        <button type="button" [disabled]="!selectedRow" class="btn btn-primary" (click)="okClicked(selectedRow)">Ok
        </button>
        <button type="button" class="btn btn-default" data-dismiss="modal" (click)="cancelClicked()">Cancel</button>
        <button *ngIf="showNoCompany" type="button" class="btn btn-danger pull-left" data-dismiss="modal"
                (click)="resetClicked()">No Company
        </button>
    </modal-footer>
</modal>
