import {Component, Input, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';
import * as fromRoot from '../../../../../../store';
import {CompanyCategoryThresholdViewModel, CompanyCategoryType} from '../../../../../../services/api.client';
import {Observable} from 'rxjs';
import {
    selectCategoryThresholds,
    selectCurrentPayeeCompanyCategoryAmounts
} from '../../../../../../store/selectors/companyCategory.selectors';
import {IPayeeCompanyCategoryAmount} from '../../../../../../shared/interfaces/IPayeeCompanyCategoryAmount';

@Component({
    selector: 'app-exceeds-category-amount-threshold',
    templateUrl: './exceeds-category-amount-threshold.component.html',
    styleUrls: ['./exceeds-category-amount-threshold.component.scss']
})
export class ExceedsCategoryAmountThresholdComponent implements OnInit {
    @Input() utilityTotal: number;
    @Input() rentalTotal: number;
    @Input() transportationTotal: number;
    @Input() childcareTotal: number;

    categoryThresholds: CompanyCategoryThresholdViewModel[];
    categoryThresholds$: Observable<CompanyCategoryThresholdViewModel[]>;

    currentPayeeCompanyCategoryAmounts$: Observable<IPayeeCompanyCategoryAmount[]>;

    utilityCompanyCategoryAmounts: IPayeeCompanyCategoryAmount[];
    rentalCompanyCategoryAmounts: IPayeeCompanyCategoryAmount[];
    transportationCompanyCategoryAmounts: IPayeeCompanyCategoryAmount[];
    childcareCompanyCategoryAmounts: IPayeeCompanyCategoryAmount[];
    carRepairCompanyCategoryAmounts: IPayeeCompanyCategoryAmount[];

    hasUtilityAmounts = false;
    hasRentalAmounts = false;
    hasTransportationAmounts = false;
    hasChildcareAmounts = false;
    hasCarRepairAmounts = false;

    utilityThresholdInformation: CompanyCategoryThresholdViewModel;
    rentalThresholdInformation: CompanyCategoryThresholdViewModel;
    transportationThresholdInformation: CompanyCategoryThresholdViewModel;
    childcareThresholdInformation: CompanyCategoryThresholdViewModel;
    carRepairThresholdInformation: CompanyCategoryThresholdViewModel;

    constructor(private _store: Store<fromRoot.AppState>) {
        this.categoryThresholds$ = this._store.select(selectCategoryThresholds);
        this.currentPayeeCompanyCategoryAmounts$ = this._store.select(selectCurrentPayeeCompanyCategoryAmounts);
    }

    ngOnInit(): void {
        this.categoryThresholds$.subscribe(categoryThresholds => {
            this.categoryThresholds = categoryThresholds;
            this.setCompanyCategoryAmountsByCategory();
        });
    }

    private setThresholdInformation(): void {
        this.categoryThresholds.map(categoryThreshold => {
            switch (categoryThreshold.category.toString()) {
                case this.getKeyByValue(CompanyCategoryType.Utility):
                    this.utilityThresholdInformation = categoryThreshold;
                    break;
                case this.getKeyByValue(CompanyCategoryType.EmergencyRentalAssistance):
                    this.rentalThresholdInformation = categoryThreshold;
                    break;
                case this.getKeyByValue(CompanyCategoryType.TransportationForWorkforceNeeds):
                    this.transportationThresholdInformation = categoryThreshold;
                    break;
                case this.getKeyByValue(CompanyCategoryType.ChildcareSupport):
                    this.childcareThresholdInformation = categoryThreshold;
                    break;
                case this.getKeyByValue(CompanyCategoryType.CarRepair):
                    this.carRepairThresholdInformation = categoryThreshold;
                    break;
            }
        });
    }

    private setCompanyCategoryAmountsByCategory(): void {
        this.currentPayeeCompanyCategoryAmounts$.subscribe(payeeCompanyCategoryAmounts => {
            this.setThresholdInformation();
            this.utilityCompanyCategoryAmounts = payeeCompanyCategoryAmounts
                .filter(x => x.category === this.getKeyByValue(CompanyCategoryType.Utility));
            this.rentalCompanyCategoryAmounts = payeeCompanyCategoryAmounts
                .filter(x => x.category === this.getKeyByValue(CompanyCategoryType.EmergencyRentalAssistance));
            this.transportationCompanyCategoryAmounts = payeeCompanyCategoryAmounts
                .filter(x => x.category === this.getKeyByValue(CompanyCategoryType.TransportationForWorkforceNeeds));
            this.childcareCompanyCategoryAmounts = payeeCompanyCategoryAmounts
                .filter(x => x.category === this.getKeyByValue(CompanyCategoryType.ChildcareSupport));
            this.carRepairCompanyCategoryAmounts = payeeCompanyCategoryAmounts
                .filter(x => x.category === this.getKeyByValue(CompanyCategoryType.CarRepair));

            this.hasUtilityAmounts = this.utilityCompanyCategoryAmounts?.length > 0;
            this.hasRentalAmounts = this.rentalCompanyCategoryAmounts?.length > 0;
            this.hasTransportationAmounts = this.transportationCompanyCategoryAmounts?.length > 0;
            this.hasChildcareAmounts = this.childcareCompanyCategoryAmounts?.length > 0;
            this.hasCarRepairAmounts = this.carRepairCompanyCategoryAmounts?.length > 0;
        });
    }

    private getKeyByValue(value: number): string | undefined {
        return Object.keys(CompanyCategoryType)
            .find(
                (key) => CompanyCategoryType[key as keyof typeof CompanyCategoryType] === value
            ) as string | undefined;
    }
}
