<section>
    <form novalidate>
        <div class="row" [formGroup]="searchForm">
            <div class="col-md col-xs-12">
                <!-- Client Name Search -->
                <label class="header-label">Client Name:</label>
                <input class="search-text-input form-control"
                       type="text"
                       placeholder="John Smith"
                       formControlName="clientName"
                />
            </div>
            <div class="col-md col-xs-12">
                <!-- Company Selection -->
                <label class="header-label">Company/Payee:</label>
                <input class="search-text-input form-control"
                       type="text"
                       placeholder="Select Company to filter"
                       (click)="showCompanyModal()"
                       (keydown)="showCompanyModal()"
                       [value]="selectedCompany"
                />
            </div>
            <div class="col-md col-xs-12">
                <!--Fund Type filter-->
                <label class="header-label">Fund Type:</label>
                <select class="drop-down-inline form-control" formControlName="pledgeTypeId">
                    <option value="">-- Fund Type --</option>
                    <option *ngFor="let item of pledgeTypes" [value]="item.id">{{item.name}}</option>
                </select>
            </div>
        </div>
        <div class="button-row">
            <div>
                <button class="btn btn-primary pull-left" role="button" (click)="loadPledges()">Search
                </button>
                <button class="btn btn-warning pull-left ms-2" role="button" (click)="resetFilters()">Reset Filters
                </button>
            </div>
            <div>
                <button class="btn btn-primary pull-left" role="button" (click)="paySelectedPledges()">Pay Selected
                </button>
                <button  class="btn btn-primary pull-left ms-2" role="button" (click)="generateVendorSummary()">Generate Vendor Summary (PDF)
                </button>
            </div>
        </div>
    </form>
    <div class="table-view">
    <table-dynamic id="datatable-table"
                   class="table table-striped table-hover"
                   [showPagination]="false"
                   [headers]="tableHeaders"
                   (selectAllCheckChanged)="selectAllOrNone($event)"
                   (headerClick)="sortPledgeData($event)"
                   [allRowsSelected]="allRowsSelected"
    >
        <tr *ngFor="let pledge of pledgeData">
            <td><input type="checkbox" [disabled]="isDeletedStatus(pledge)" [(ngModel)]="pledge.isChecked" (ngModelChange)="rowCheckChanged()"/></td>
            <td [class.deleted-row]="isDeletedStatus(pledge)">{{ pledge.fund}}</td>
            <td [class.deleted-row]="isDeletedStatus(pledge)">{{ pledge.lastName}}</td>
            <td [class.deleted-row]="isDeletedStatus(pledge)">{{ pledge.firstName}}</td>
            <td [class.deleted-row]="isDeletedStatus(pledge)">{{ pledge.date | date:'MM/dd/yyyy'}}</td>
            <td [class.deleted-row]="isDeletedStatus(pledge)">{{ pledge.amount | currency}}</td>
            <td [class.deleted-row]="isDeletedStatus(pledge)">{{ pledge.company}}</td>
            <td [class.deleted-row]="isDeletedStatus(pledge)">{{ pledge.status}}</td>
            <td [class.deleted-row]="isDeletedStatus(pledge)">{{ pledge.paidDate | date:'MM/dd/yyyy'}}</td>
        </tr>
    </table-dynamic>
    </div>
</section>
<company-box (onCompanySelected)="onCompanySelected($event)"></company-box>
