<h1 class="page-title">Audit Progress Report</h1>
<section class="widget">
    <div class="widget-body p-5">
        <div class="container-fluid">
            <div class="pb-4">
                The report below provides a summary of the assistance application audit statuses for each fund,
                and exports the data to excel. If more information is needed,
                please navigate to the Audit Report for more details on the applications themselves.
            </div>
            <div class="row">
                <div class="col-xs-12">
                    <div class="actions-wrapper">
                        <div class="export-button pb-2">
                            <button (click)="onExportClick()"
                                    [disabled]="fetchingData"
                                    class="btn btn-primary">
                                <i class="fa fa-download me-2"></i>&nbsp;
                                Export to Excel
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-xs-12">
                    <app-audit-progress-report-table (pageChange)="onPageChange($event)"
                                            (limitChange)="onLimitChange($event)"
                                            [pagedData]="pagedData"></app-audit-progress-report-table>
                </div>
            </div>
        </div>
    </div>
</section>
