<!-- main page content. the place to put widgets in. usually consists of .row > .col-lg-* > .widget.  -->
<h1 class="page-title">Browse Companies</h1>
<section class="widget">
  <div class="widget-body">
    <div class="mt">
      <form novalidate [formGroup]="form">
        <div class="row">
          <div class="col-sm-6">
            <label class="grid-label">Company:</label>
            <input class="search-text-input form-control"
                   type="text"
                   placeholder="Search by Company"
                   maxlength="64"
                   [formControl]="companyNameControl" />
          </div>
            <div class="col-sm-6">
                <label class="grid-label">Category:</label>
                <select style="width: 100%" [formControl]="categoryControl" class="form-control">
                    <option [ngValue]="null" disabled>Choose a Company Category</option>
                    <option *ngFor="let option of categoryTypesSelect"
                            [value]="option.key">{{ option.value }}
                    </option>
                </select>
            </div>
        </div>
        <div class="row form-group">
          <div class="col-xl-2 col-lg-3 col-md-6 col-sm-6 col-xs-12">
            <button class="btn btn-primary" type="submit" role="button" (click)="refreshCompanies()">Search</button>
              <button class="btn btn-secondary m-l-05" type="button" role="button" (click)="clearFilters()">Clear</button>
          </div>
          <div class="col-xl-10 col-lg-9 col-md-6 col-sm-6 col-xs-12">
            <button class="btn btn-primary float-right" [routerLink]="['../company/add']">New Company</button>
          </div>
        </div>
      </form>
      <table-dynamic id="datatable-table"
                     class="table table-striped table-hover"
                     [headers]="tableHeaders"
                     [currentPage]="currentPage"
                     [totalCount]="totalCount"
                     (headerClick)="sortCompanies($event)"
                     (pageChanged)="setPage($event)"
                     (limitChange)="setLimit($event)">
        <tr *ngFor="let company of companies">
          <td>
            <a [routerLink]="['../company', company.id, queryParams]"
               (click)="setCurrentSelectedCompany(company); false"><span
              class="fw-semi-bold">{{company.name}}</span></a>
          </td>
          <td class="hidden-sm-down">{{company.state}}</td>
          <td>{{ formatStatus(company.status) }}</td>
          <td>{{ company.categoryLabel }}</td>
        </tr>
      </table-dynamic>
    </div>
  </div>
</section>
