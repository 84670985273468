import {Component, OnInit, ViewChild} from '@angular/core';
import {ClientEnrollmentFormComponent, KcApplicationSubmitType} from '../ClientEnrollmentFormComponent';
import {Client, KcApplicationClient} from '../../../../services/api.client';
import {BehaviorSubject, mergeMap, Observable, of} from 'rxjs';
import {KcProgram} from '../../../../services/api.client';
import {KcApplicationService} from '../../../../services/KcApplicationService';
import {NotificationService} from '../../../../services/NotificationService';
import {Router, ActivatedRoute} from '@angular/router';
import {SystemService} from '../../../../services/SystemService';
import {KcApplicationResult} from '../../../../services/api.client';
import {select, Store} from '@ngrx/store';
import * as fromRoot from '../../../../store';
import {selectClientDataCurrentClient} from '../../../../store/selectors/client.selectors';
import {selectKcAppDataCurrentSelectedKcApplication, selectKcAppDataKcAppResult} from '../../../../store/selectors/kcApplication.selectors';
import {selectSecurityStateDataSystemDataKcStatuses} from '../../../../store/selectors/security.selectors';
import {filter, take} from 'rxjs/operators';

@Component({
    selector: 'clientEditApplication',
    templateUrl: './ClientEditApplicationComponent.html',
    styleUrls: ['./ClientEditApplicationComponent.scss']
})

export class ClientEditApplicationComponent implements OnInit {
    @ViewChild(ClientEnrollmentFormComponent)
    form: ClientEnrollmentFormComponent;

    client: Client;
    client$: Observable<Client>;

    kcPrograms: KcProgram[];
    kcPrograms$: Observable<KcProgram[]>;

    application: KcApplicationResult;
    application$: Observable<KcApplicationResult>;

    public isLoaded$ = new BehaviorSubject<boolean>(false);

    constructor(private kcApplicationService: KcApplicationService,
                private notificationService: NotificationService,
                private router: Router,
                private route: ActivatedRoute,
                private system: SystemService,
                private _store: Store<fromRoot.AppState>,
                private _kcAppClient: KcApplicationClient) {
        this.client$ = this._store.pipe(select(selectClientDataCurrentClient));
        this.kcPrograms$ = this._store.pipe(select(selectSecurityStateDataSystemDataKcStatuses));
        this.application$ = this._store.pipe(select(selectKcAppDataCurrentSelectedKcApplication));
    }

    ngOnInit(): void {
        this.client$.subscribe(client => this.client = client);
        this.kcPrograms$.subscribe(kcProgram => this.kcPrograms = kcProgram);
        this.kcApplicationService.getKcApplicationById(this.route.snapshot.paramMap.get('applicationId')).subscribe(application => {
            this.application = application;
            this.isLoaded$.next(true);
            }
        );
    }

    onSubmit(type: KcApplicationSubmitType): void {
        switch (type) {
            case 'New':
                break;
            case 'Save':
                break;
            case 'Reject':
                break;
            case 'Cancel':
                break;
            case 'Delete':
                this.router.navigate(['../../'], {relativeTo: this.route}).then();
                break;
        }

    }

    ngAfterViewInit(): void {
        this.isLoaded$.pipe(
            filter(val => !!val),
            take(1)
        ).subscribe(_ => {
            this.form.populateEdit(this.application);
            this.form.application = this.application;
            if (this.form.applicationStartedButNotSubmitted) {
                this.form.accountNumberControl.disable();
                this.form.keepingCurrentProgramIdControl.disable();
                this.form.totalAmountOwedControl.disable();
                this.form.upfrontAmountDueControl.disable();
                this.form.grandTotalEstimatedCreditsControl.disable();
                this.form.upfrontPaySourceControl.disable();
            }
        });
    }

    onContinue(): void {
        this.router.navigate(['./request_enrollment'], {relativeTo: this.route}).then();
    }

    ngOnDestroy(): void {
        this.kcApplicationService.setCurrentKcApplicationResult(null);
    }
}
