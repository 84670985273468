import {ErrorHandler, Injectable} from '@angular/core';
import {SwaggerException} from '../services/api.client';
import {MonitorService} from '../services/MonitorService';

@Injectable()
export class CustomErrorHandler implements ErrorHandler {

    constructor(private _monitor: MonitorService) {
    }

    handleError(error: Error | SwaggerException | any): void {
        let msg = error.message || '';
        if (error.isSwaggerException) {
            try {
                msg = JSON.parse(error.response).error;
            } catch (ex) {
                msg = error instanceof Error ? error.message : error instanceof SwaggerException ? error.message : error.toString();
            }
        }

        this._monitor.logError(error, {message: msg}, {});
    }
}
