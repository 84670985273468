<h1 class="page-title">Email Users</h1>
<section class="widget">
    <div class="widget-body">
        <div class="mt">
            <div class="row">
                <div class="col-md-12">
                    <section class="widget">

                        <form class="form-horizontal" [formGroup]="form" novalidate>

                            <div class="row">
                                <div class="col-xs-6">
                                    <div class="row">
                                        <label for="fromMail" class="m-l-1 required-label">From Email</label>
                                        <div class="col-xs-12 form-control-container">
                                            <input [formControl]="fromMail" type="email" class="form-control"
                                                   id="fromMail" formControlName="fromMail">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xs-6">
                                    <div class="row" [class.has-danger]="!agencyId?.valid && agencyId?.touched">
                                        <label for="agencyId" class="m-l-1 required-label">Agency</label>
                                        <div class="col-xs-12 form-control-container">
                                            <input class="form-control"
                                                   type="text"
                                                   id="agencyId"
                                                   [formControl]="agencyName"
                                                   formControlName="agencyName"
                                                   placeholder="Select an Agency"
                                                   (click)="showAgencyModal()"
                                                   (keydown)="showAgencyModal()"/>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-xs-12 m-t-05">
                                    <div class="row">
                                        <div class="col-xs-6">
                                            <div class="custom-control-container">
                                                <label class="custom-control custom-radio">
                                                    <input class="custom-control-input" type="radio"
                                                           [formControl]="selectAllFundTypes"
                                                           [value]="true" name="selectAllFundTypes">
                                                    <span class="custom-control-indicator"></span>
                                                    <span class="custom-control-description">Email All Fund Types</span>
                                                </label>
                                            </div>
                                        </div>
                                        <div class="col-xs-6">
                                            <div class="custom-control-container">
                                                <label class="custom-control custom-radio">
                                                    <input class="custom-control-input" type="radio"
                                                           [formControl]="selectAllFundTypes"
                                                           [value]="false" name="selectAllFundTypes">
                                                    <span class="custom-control-indicator"></span>
                                                    <span class="custom-control-description">Choose Specific Fund Type(s) to Email</span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>

                                    <!--Fund Types-->
                                    <div class="row m-t-05" [class.hidden]="selectAllFundTypes.value">
                                        <label class="required-label col-xs-12">Fund Type:</label>
                                        <div class="col-xs-4" *ngFor="let pledgeType of allPledgeTypes">
                                            <!--check-box-->
                                            <div class="row"
                                                 [class.has-danger]="!fundTypes?.valid && fundTypes?.touched">
                                                <span class="m-t-05 col-md-8">{{pledgeType.name}}</span>
                                                <div class="col-md-3">
                                                    <label class="custom-control custom-checkbox">
                                                        <input class="form-control custom-control-input"
                                                               ngDefaultControl
                                                               type="checkbox"
                                                               name="allPledgeTypes"
                                                               id="fundTypes"
                                                               [checked]="isChecked(pledgeType)"
                                                               [value]="pledgeType"
                                                               (change)="pledgeTypesChange(pledgeType, $event.target.checked)">
                                                        <span class="custom-control-indicator"></span>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row m-t-05">
                                <div class="col-xs-6">
                                    <div class="custom-control-container">
                                        <label class="custom-control custom-radio">
                                            <input class="custom-control-input" type="radio"
                                                   [formControl]="includeOptOut" formControlName="includeOptOut"
                                                   [value]="false">
                                            <span class="custom-control-indicator"></span>
                                            <span class="custom-control-description">General Email</span>
                                        </label>
                                    </div>
                                </div>
                                <div class="col-xs-6">
                                    <div class="custom-control-container">
                                        <label class="custom-control custom-radio">
                                            <input class="custom-control-input" type="radio"
                                                   [formControl]="includeOptOut" formControlName="includeOptOut"
                                                   [value]="true">
                                            <span class="custom-control-indicator"></span>
                                            <span class="custom-control-description">Urgent: Email All Users</span>
                                        </label>
                                    </div>
                                </div>
                            </div>

                            <div class="row m-t-05">
                                <div class="col-xs-12">
                                    <label for="subject" class="required-label">Message Subject:</label>
                                    <div class="form-control-container">
                                        <input [formControl]="subject" formControlName="subject" type="text"
                                               class="form-control" id="subject">
                                    </div>
                                </div>
                            </div>

                            <div class="row m-t-05">
                                <div class="col-xs-12">
                                    <label for="content" class="required-label">Message Body:</label>
                                    <div class="form-control-container">
                                        <textarea [formControl]="content" formControlName="content" type="text"
                                                  class="form-control" id="content"></textarea>
                                    </div>
                                </div>
                            </div>

                        </form>

                        <div class="row">
                            <div class="col-xs"></div>
                            <div class="col-xs-1">
                                <button class="btn btn-primary" (click)="submit()">Send Email</button>
                            </div>
                            <div class="col-xs"></div>
                        </div>

                    </section>
                    <agency-box (onAgencySelected)="onAgencySelected($event)"></agency-box>
                </div>
            </div>
        </div>
    </div>
</section>
