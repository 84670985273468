import {IUserData} from '../IUserData';
import {createReducer, on} from '@ngrx/store';
import {setCurrentSelectedUser, setPagedUsers} from '../actions/user.actions';

export const initialState: IUserData = {
    pagedUsers: null,
    selectedUser: null
};

export const userReducer = createReducer(
    initialState,
    on(setPagedUsers, (state, {payload}) => ({
        ...state,
        pagedUsers: payload
    })),
    on(setCurrentSelectedUser, (state, {payload}) => ({
        ...state,
        selectedUser: payload
    })),
    on(setPagedUsers, (state, {payload}) => ({
        ...state,
        pagedUsers: payload
    }))
);
