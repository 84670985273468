import {IKcApplicationData} from '../IKcApplicationData';
import {createReducer, on} from '@ngrx/store';
import {
    setCurrentClientsApplications, setCurrentKcNotes,
    setCurrentSelectedKcApplicationResult,
    setCurrentSelectedKcApplications, setKcAmerenCompany, setKcApplications, setKcProgramDetail
} from '../actions/kcApplication.actions';

export const initialState: IKcApplicationData = {
    kcApplications: null,
    currentSelectedKcApplication: null,
    currentKcApplicationResult: null,
    currentKcNotes: null,
    currentClientApplications: null,
    programDetail: null,
    kcAmerenCompany: null,
    kcPledge: null
};

export const kcApplicationReducer = createReducer(
    initialState,
    on(setCurrentSelectedKcApplications, (state, { payload }) => ({
       ...state,
        currentSelectedKcApplication: payload
    })),
    on(setCurrentSelectedKcApplicationResult, (state, { payload }) => ({
        ...state,
        currentKcApplicationResult: payload
    })),
    on(setKcApplications, (state, { payload }) => ({
        ...state,
        kcApplications: payload
    })),
    on(setCurrentClientsApplications, (state, { payload }) => ({
        ...state,
        currentClientApplications: payload
    })),
    on(setCurrentKcNotes, (state, { payload }) => ({
        ...state,
        currentKcNotes: payload
    })),
    on(setKcAmerenCompany, (state, { payload }) => ({
        ...state,
        kcAmerenCompany: payload
    })),
    on(setKcProgramDetail, (state, { payload }) => ({
        ...state,
        programDetail: payload
    })),
);
