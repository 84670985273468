import {FormControl, FormGroup, ValidationErrors, ValidatorFn} from '@angular/forms';
import {AssistanceApplicationStatus} from '../../../../../../shared/enums/assistance-application-status';
import {AssistanceApplicationDenialReason} from '../../../../../../shared/enums/assistance-application-denial-reason';

export function requireDenialReasonIfDenied(): ValidatorFn {
    return (formGroup: FormGroup): ValidationErrors | null => {
        const statusControl = formGroup.get('status') as FormControl<AssistanceApplicationStatus | null>;
        const denialReasonControl = formGroup.get('denialReason') as FormControl<AssistanceApplicationDenialReason | null>;

        if (!statusControl || !denialReasonControl) {
            return null;
        }

        if (statusControl.value === AssistanceApplicationStatus.Denied && !denialReasonControl.value) {
            const error = {required: true};
            denialReasonControl.setErrors(error);
        }

        return null;
    };
}
