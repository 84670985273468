import {map, take} from 'rxjs/operators';
import {AgencyEfnCase, AgencyEfnCaseStatusType, EfnCase, EfnCaseStatusType, User, UserType} from '../../../../../services/api.client';
import {SystemService} from '../../../../../services/SystemService';
import {Injectable} from '@angular/core';
import {EfnService} from '../../../../../services/EfnService';
import {Observable} from 'rxjs';
import {toBoolean} from '../../../../../utilities/Util';
import {select, Store} from '@ngrx/store';
import * as fromRoot from '../../../../../store';
import {selectEfnDataAgencyEfnCases} from '../../../../../store/selectors/agency.selectors';
import {selectEfnDataSelectedEfnCase} from '../../../../../store/selectors/efn.selectors';
import {selectSecurityStateDataCurrentUser} from '../../../../../store/selectors/security.selectors';

@Injectable()
export class EfnCaseHelper {

    user: User;
    user$: Observable<User>;

    efnCase: EfnCase;
    efnCase$: Observable<EfnCase>;

    agencyEfnCases: AgencyEfnCase[];
    agencyEfnCases$: Observable<AgencyEfnCase[]>;

    constructor(
        private system: SystemService,
        private efnService: EfnService,
        private _store: Store<fromRoot.AppState>) {
        this.user$ = this._store.pipe(select(selectSecurityStateDataCurrentUser));
        this.efnCase$ = this._store.pipe(select(selectEfnDataSelectedEfnCase));
        this.agencyEfnCases$ = this._store.pipe(select(selectEfnDataAgencyEfnCases));

        this.user$.subscribe(user => this.user = user);
        this.efnCase$.subscribe(efnCase => this.efnCase = efnCase);
        this.agencyEfnCases$.subscribe(agencyEfnCases => this.agencyEfnCases = agencyEfnCases);
    }

    canDecline(): boolean {
        return this.isStatusOpen && this.isInvitedAgency && !this.hasAgencyDeclined;
    }

    canEdit(): boolean {
        return this.user.userType === UserType.SystemAdmin || (this.isEfnCaseCreator && this.isStatusOpen);
    }

    canCommit(): boolean {
        return this.isStatusOpen && (this.isEfnCaseCreator || this.isInvitedAgency);
    }

    canAddInvites(): boolean {
        return this.isEfnCaseCreator && this.isStatusOpen;
    }

    getAgencyCommit$(): Observable<{ commitNote: any, originalCommitAmount: any }> {
        return this.agencyEfnCases$
            .exists().pipe(
                take(1),
                map((agencyEfnCase) => agencyEfnCase.find((value) =>
                    this.efnCase.id === value.efnCaseId && value.agencyId === this.user.agencyId)), )
            .exists().pipe(
                map((agencyEfnCase) => ({
                    commitNote: agencyEfnCase.commitNote,
                    originalCommitAmount: agencyEfnCase.originalCommitAmount
                })));
    }

    get hasAgencyDeclined(): boolean {
        if (this.agencyEfnCases) {
            const efnCase = this.agencyEfnCases.find((value) => value.agencyId === this.user.agencyId);
            return !!efnCase && efnCase.status === AgencyEfnCaseStatusType.Declined;
        }
        return false;
    }

    get isInvitedAgency(): boolean {
        return toBoolean(!this.isEfnCaseCreator &&
            (this.agencyEfnCases && this.agencyEfnCases.find((value) =>
                value.agencyId === this.user.agencyId)));
    }

    get isEfnCaseCreator(): boolean {
        return toBoolean(this.efnCase && this.efnCase.createdByUserId === this.user.id);
    }

    get isStatusOpen(): boolean {
        return toBoolean(this.efnCase && (this.efnCase.status === EfnCaseStatusType.Open ||
        this.efnCase.status.toString() === 'Open'));
    }

    reloadEfnCase(): void {
        this.efnService.getEfnCaseById(this.efnCase.id);
    }

    dispose(): void {
        ((this as any).ngOnDestroy());
    }

}
