<div class="container" *ngIf="!loading">
    <h4 class="page-title"></h4>
    <form [formGroup]="form">
        <div class="row">
            <div class="px-3 pt-1 pb-3">
                There {{(pledgeCount$ | async) === 1 ? 'is' : 'are'}} <strong>{{pledgeCount$ | async}}</strong> record{{(pledgeCount$ | async) === 1 ? '' : 's'}} available to add to a batch in the payment file.
                <br>
                Creating a new Batch will move those pledges from the Confirmed to Pending status.
            </div>
            <div class="offset-md-1 col-md-10">
                <div class="form-group row pt-4" style="display: flex; align-items: center;">
                    <label class="col-md-2">Start Date:</label>
                    <datePicker class="col-md-4" [control]="startDate" [autoClose]="true"></datePicker>
                    <label class="col-md-2">End Date:</label>
                    <datePicker class="col-md-4" [control]="endDate" [autoClose]="true"></datePicker>
                </div>

                <div class="form-group row">
                    <label class="col-md-2">Select Company</label>
                    <div class="col-md-10">
                        <input #company type="text" class="form-control" (click)="companyListModal.openModal()"
                               value="All Companies">
                        <company-box
                                (onCompanySelected)="companyControl.setValue($event.company && $event.company.id); company.value = ($event.company && $event.company.name) || 'All Companies'"></company-box>
                    </div>
                </div>

                <div class="form-group row">
                    <label class="col-md-2">Select Fund(s)</label>
                    <div class="col-md-10">
                        <pledgeTypeCheckbox [filterByRole]="true" [control]="pledgeTypes" [requireOne]="true"
                                            showSelectAll="true"
                                            [preselectAll]="true"></pledgeTypeCheckbox>
                    </div>
                </div>

                <div class="p-t-1 action-buttons">
                    <button [disabled]="loading || (((pledgeCount$ | async) < 1))"
                            class="btn btn-primary" (click)="createBatch()">Create Batch
                    </button>
                </div>
                <div *ngIf="!this.loading && ((pledgeCount$ | async) < 1)"
                     class="alert-warning p-2 mt-2">There are no pledges available for the filters applied.
                </div>
                <ng-content></ng-content>
            </div>
        </div>
    </form>
</div>
<div *ngIf="loading" class="loading-text">
    <h2>Loading...</h2>
</div>
