import {share} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {UserUrls} from '../api/UserUrls';
import {getMessageFromException} from '../utilities/Util';
import {FileDownloadService} from './FileDownloadService';
import {NotificationService} from './NotificationService';

import {
    ChangePasswordParameters,
    DateTimeOffsetParameter,
    MailUsersClient,
    MailUsersParameters,
    PagedListOfUser, SimpleBooleanResult,
    UserClient
} from './api.client';
import {User} from './api.client';
import {Moment} from 'moment';
import {Store} from '@ngrx/store';
import * as fromRoot from '../store';
import {Observable} from 'rxjs';
import {setCurrentSelectedUser, setPagedUsers} from '../store/actions/user.actions';

@Injectable()
export class UserService {

    constructor(private _userApiService: UserClient,
                private _fileDownloadService: FileDownloadService,
                private _notificationService: NotificationService,
                private _store: Store<fromRoot.AppState>,
                private _emailApiService: MailUsersClient) {
    }

    searchUsers(searchValue: string,
                agencyId: string,
                userType: any,
                includeDisabled: boolean,
                sortName: string,
                descending: boolean,
                limit: number,
                offset: number): Observable<PagedListOfUser> {
        const request = this._userApiService.searchUsers(
            searchValue,
            agencyId,
            userType,
            includeDisabled,
            limit,
            offset,
            sortName,
            null,
            descending).pipe(share());
        request.subscribe({
            next: (result) => {
                this.setPagedUsersAction(result);
            }, error: () => {
                this._notificationService.showError('Error Loading Users');
            }, complete: () => {

            }
        });
        return request;
    }

    downloadUserList(data: {
        searchValue?: string,
        agencyId?: string,
        userTypeId?: any,
        includeDisabled?: boolean,
        sortName: string,
        limit: number,
        offset?: number,
        descending?: boolean
    } = {
        sortName: '',
        limit: 100
    }): string {
        return this._fileDownloadService.buildUrl(UserUrls.DOWNLOAD_USERS, data);
    }

    setCurrentSelectedUser(user: User): void {
        this.setSelectedUserAction(user);
    }

    addUser(user: User): Observable<User> {
        const request = this._userApiService.addUser(user).pipe(share());
        this._notificationService.showSuccess('Attempting to save new User.');
        request.subscribe({
            next: (result) => {
                this.setCurrentSelectedUser(result);
                this._notificationService.showSuccess('User Successfully Created!');
            }, error: (err) => {
                this._notificationService.showError(getMessageFromException(err));
            }, complete: () => {

            }
        });
        return request;
    }

    updateUser(user: User): Observable<User> {
        const request = this._userApiService.updateUser(user).pipe(share());
        this._notificationService.showSuccess('Attempting to update User.');
        request.subscribe({
            next: () => {
                this._notificationService.showSuccess('User Successfully Updated!');
            }, error: (err) => {
                this._notificationService.showError(getMessageFromException(err));
            }, complete: () => {

            }
        });
        return request;
    }

    getUserById(id: string): Observable<User> {
        const request = this._userApiService.getUserById(id).pipe(share());
        request.subscribe({
            next: (result) => {
                this.setCurrentSelectedUser(result);
            }, error: (err) => {
                this._notificationService.showError(getMessageFromException(err));
            }, complete: () => {

            }
        });
        return request;
    }

    changePassword(id: string, currentPassword: string, newPassword: string): Observable<boolean> {
        const params = new ChangePasswordParameters({id, currentPassword, newPassword});
        const response = this._userApiService.changePassword(params).pipe(share());
        this._notificationService.showSuccess('Attempting to save new Password.');
        response.subscribe({
            next: () => {
                this._notificationService.showSuccess('New Password Saved.');
            }, error: () => {

            }
        });
        return response;

    }

    isEmailTaken(email: string, id: string): Observable<SimpleBooleanResult> {
        const request = this._userApiService.isEmailTaken(email, id).pipe(share());
        request.subscribe({
            next: () => {

            }, error: () => {
                this._notificationService.showError('There was an error checking this email for existing Users.');
            }, complete: () => {

            }
        });
        return request;
    }

    sendMail(parameters: MailUsersParameters): Observable<void> {
        const request = this._emailApiService.mailUsers(parameters).pipe(share());
        request.subscribe({
            next: () => {
                this._notificationService.showSuccess('Email Successfully Sent!');
            }, error: () => {
                this._notificationService.showError('There was a problem sending this Email.');
            }, complete: () => {

            }
        });
        return request;
    }

    setAgreementDate(agreementDate: Moment): Observable<SimpleBooleanResult> {
        const param = new DateTimeOffsetParameter({date: agreementDate});
        const request = this._userApiService.setUserAgreementDate(param).pipe(share());
        request.subscribe({
            next: () => {
                this._notificationService.showSuccess('Agreement Date Saved!');
            }, error: () => {
                this._notificationService.showError('There was a problem saving the Agreement Date.');
            }, complete: () => {

            }
        });
        return request;
    }

    private setPagedUsersAction(payload: PagedListOfUser): void {
        this._store.dispatch(setPagedUsers({ payload }));
    }

    setSelectedUserAction(user: User): void {
        this._store.dispatch(setCurrentSelectedUser({ payload: user}));
    }
}
