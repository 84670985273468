import {share} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {DemographicReportUrls} from '../api/DemographicReportUrls';
import {
    AllocationReportClient,
    DemographicReportClient, PagedListOfPledgeDemographicDetailReport,
    PagedListOfPledgeDetailReport,
    PledgeReportClient,
    PledgeStatusType
} from './api.client';
import * as moment from 'moment';
import {PledgeZipcodeReport} from './api.client';
import {PledgeCountyReport} from './api.client';
import {PledgeDemographicSummaryReport} from './api.client';
import {PledgeSummaryReport} from './api.client';
import {AllocationReport} from './api.client';
import {FileDownloadService} from './FileDownloadService';
import {Store} from '@ngrx/store';
import * as fromRoot from '../store';
import {
  agencyDetailReportSuccess,
  agencySummaryReportSuccess,
  allocationReportSuccess,
  pledgeDemographicByZipCodeReportSuccess,
  pledgeDemographicCountyReportSuccess,
  pledgeDemographicDetailReportSuccess,
  pledgeDemographicSummaryReportSuccess
} from '../store/actions/report.actions';

@Injectable()
export class DemographicDemographicReportService {

  constructor(private _demographicReportApiService: DemographicReportClient,
              private _fileDownloadService: FileDownloadService,
              private _agencyReportApiService: PledgeReportClient,
              private _allocationReportService: AllocationReportClient,
              private _store: Store<fromRoot.AppState>) {
  }

  detailReport({startDate, endDate, pledgeTypeIds, pledgeStatus, companyId, agencyId}: {
    startDate: moment.Moment,
    endDate: moment.Moment,
    pledgeTypeIds: [],
    pledgeStatus: PledgeStatusType,
    companyId: string,
    agencyId: string
  }): any {
    const response = this._demographicReportApiService
        .detailReport(startDate, endDate, pledgeTypeIds, pledgeStatus, companyId, agencyId).pipe(share());
    response.subscribe({ next: (data) => {
      this.demographicDetailReportSuccessAction(data);
    }, error: () => {

    }});
    return response;
  }

  agencyDetailReport({startDate, endDate, pledgeTypeIds, pledgeStatus, agencyId, offset, limit, sortName, descending}: {
    startDate: moment.Moment,
    endDate: moment.Moment,
    pledgeTypeIds: [],
    pledgeStatus: PledgeStatusType,
    companyId: string,
    agencyId: string,
    offset: number,
    limit: number,
    sortName: string,
    descending: boolean
  }): any {
    const response = this._agencyReportApiService.detailReport(
        startDate,
        endDate,
        pledgeTypeIds,
        pledgeStatus,
        agencyId,
        limit,
        offset,
        sortName,
        null,
        descending).pipe(share());

    response.subscribe({ next: (data) => {
      this.agencyDetailReportSuccessAction(data);
    }, error: () => {

    }});
    return response;
  }

  agencySummaryReport({startDate, endDate, pledgeTypeIds, pledgeStatus, agencyId}: {
    startDate: moment.Moment,
    endDate: moment.Moment,
    pledgeTypeIds: [],
    pledgeStatus: PledgeStatusType,
    companyId: string,
    agencyId: string
  }): any {
    const response = this._agencyReportApiService.summaryReport(
        startDate, endDate, pledgeTypeIds, pledgeStatus, agencyId).pipe(share());

    response.subscribe({ next: (data) => {
      this.agencySummaryReportSuccessAction(data);
    }, error: () => {

    }});
    return response;
  }

  allocationReport({startDate, endDate, pledgeTypeIds, pledgeStatus, agencyId, hideZeroBalances}: {
    startDate: moment.Moment,
    endDate: moment.Moment,
    pledgeTypeIds: [],
    pledgeStatus: PledgeStatusType,
    companyId: string,
    agencyId: string,
    hideZeroBalances: boolean }): any {
    const response = this._allocationReportService
        .detailReport(startDate, endDate, pledgeTypeIds, pledgeStatus, agencyId, hideZeroBalances).pipe(share());

    response.subscribe({ next: (data) => {
      this.allocationReportSuccessAction(data);
    }, error: () => {

    }});
    return response;
  }

  countyReport({startDate, endDate, pledgeTypeIds, pledgeStatus, companyId, agencyId}: {
    startDate: moment.Moment,
    endDate: moment.Moment,
    pledgeTypeIds: [],
    pledgeStatus: PledgeStatusType,
    companyId: string,
    agencyId: string }): any {
    const response = this._demographicReportApiService
        .countyReport(startDate, endDate, pledgeTypeIds, pledgeStatus, companyId, agencyId).pipe(share());
    response.subscribe({ next: (data) => {
      this.countyReportSuccessAction(data);
    }, error: () => {

    }});
    return response;
  }

  downloadCountyReport(data: {
    startDate: moment.Moment,
    endDate: moment.Moment,
    pledgeTypeId: string,
    pledgeStatus: PledgeStatusType,
    companyId: string,
    agencyId: string }): string {
    return this._fileDownloadService.buildUrl(DemographicReportUrls.DOWNLOAD_COUNTY_REPORT, data);
  }

  zipcodeReport({startDate, endDate, pledgeTypeIds, pledgeStatus, companyId, agencyId}: {
    startDate: moment.Moment,
    endDate: moment.Moment,
    pledgeTypeIds: [],
    pledgeStatus: PledgeStatusType,
    companyId: string,
    agencyId: string }): any {
    const response = this._demographicReportApiService
        .zipcodeReport(startDate, endDate, pledgeTypeIds, pledgeStatus, companyId, agencyId).pipe(share());
    response.subscribe({ next: (data) => {
      this.zipCodeReportSuccessAction(data);
    }, error: () => {

    }});
    return response;
  }

  summaryReport({startDate, endDate, pledgeTypeIds, pledgeStatus, companyId, agencyId}: {
    startDate: moment.Moment,
    endDate: moment.Moment,
    pledgeTypeIds: [],
    pledgeStatus: PledgeStatusType,
    companyId: string,
    agencyId: string }): any {
    const response = this._demographicReportApiService
        .summaryReport(startDate, endDate, pledgeTypeIds, pledgeStatus, companyId, agencyId).pipe(share());
    response.subscribe({ next: (data) => {
      this.summaryReportSuccessAction(data);
    }, error: () => {

    }});
    return response;
  }

  downloadSummaryReport(data: {
    startDate: moment.Moment,
    endDate: moment.Moment,
    pledgeTypeId: string,
    pledgeStatus: PledgeStatusType,
    companyId: string,
    agencyId: string }): string {
    return this._fileDownloadService.buildUrl(DemographicReportUrls.DOWNLOAD_SUMMARY_REPORT, data);
  }

  private agencyDetailReportSuccessAction(data: PagedListOfPledgeDetailReport): void {
    this._store.dispatch(agencyDetailReportSuccess({ payload: data}));
  }

  private agencySummaryReportSuccessAction(data: PledgeSummaryReport[]): void {
    this._store.dispatch(agencySummaryReportSuccess({ payload: data}));
  }

  private allocationReportSuccessAction(data: AllocationReport[]): void {
    this._store.dispatch(allocationReportSuccess({ payload: data}));
  }

  private demographicDetailReportSuccessAction(data: PagedListOfPledgeDemographicDetailReport): void {
    this._store.dispatch(pledgeDemographicDetailReportSuccess({ payload: data}));
  }

  private countyReportSuccessAction(data: PledgeCountyReport[]): void {
    this._store.dispatch(pledgeDemographicCountyReportSuccess({ payload: data}));
  }

  private zipCodeReportSuccessAction(data: PledgeZipcodeReport[]): void {
    this._store.dispatch(pledgeDemographicByZipCodeReportSuccess({ payload: data}));
  }

  private summaryReportSuccessAction(data: PledgeDemographicSummaryReport): void {
    this._store.dispatch(pledgeDemographicSummaryReportSuccess({ payload: data}));
  }
}
