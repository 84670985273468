import {Component, ViewChild} from '@angular/core';
import {ITableHeader} from '../../../../shared/table/TableComponent';
import {KeepingCurrentReportFormComponent} from '../form/KeepingCurrentReportFormComponent';
import {Observable} from 'rxjs';
import {BudgetBillingReport} from '../../../../../services/api.client';
import {formatCurrency} from '../../../../../utilities/Util';
import {SHORT_DATE_FORMAT} from '../../../../../constants';
import * as moment from 'moment';

@Component({
    templateUrl: './BudgetBillingReportComponent.html',
})
export class BudgetBillingReportComponent {

    tableHeaders: ITableHeader[] = [
        {text: 'Agency'},
        {text: 'First Name'},
        {text: 'Last Name'},
        {text: 'Account Number'},
        {text: 'Service Address'},
        {text: 'Enrollment Date'},
        {text: 'First Billing Date'},
        {text: 'Billing Date'},
        {text: 'Prior Month Bill'},
        {text: 'Current Month Bill'},
        {text: 'Billing Change'},
    ];

    @ViewChild('form')
    form: KeepingCurrentReportFormComponent;

    report$: Observable<BudgetBillingReport[]>;
    rows: any[][];

    submit(): void {
        this.form.submitBudgetBillingReport()
            .map(this.transformTableColumns)
            .subscribe((rows) => this.rows = rows);
    }

    transformTableColumns = (report) => {
        return report.map(a => [
            a.agency,
            a.firstName,
            a.lastName,
            a.accountNumber,
            a.serviceAddress,
            moment(new Date(a.enrollmentDate).toISOString().slice(0, 10)).format(SHORT_DATE_FORMAT),
            moment(new Date(a.firstBillingDate).toISOString().slice(0, 10)).format(SHORT_DATE_FORMAT),
            moment(new Date(a.billingDate).toISOString().slice(0, 10)).format(SHORT_DATE_FORMAT),
            formatCurrency(a.previousMonthAmount),
            formatCurrency(a.currentMonthAmount),
            formatCurrency(a.adjustmentAmount),
        ]);
    }
}
