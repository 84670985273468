import {timer as observableTimer, from as observableFrom, Observable, Subscription} from 'rxjs';
import {takeUntil, switchMap, map} from 'rxjs/operators';
import {Component, OnInit, EventEmitter, ElementRef, Output, OnDestroy} from '@angular/core';
import {ConfigService} from '../../../services/ConfigService';
import {RequestService} from '../../../services/RequestService';
import {User} from '../../../services/api.client';
import {SecurityService} from '../../../services/SecurityService';
import {select, Store} from '@ngrx/store';
import * as fromRoot from '../../../store';
import {selectSecurityStateDataCurrentUser} from '../../../store/selectors/security.selectors';

declare var jQuery: any;
@Component({
  selector: 'navbar,[navbar]',
  templateUrl: './NavbarComponent.html',
  styleUrls: ['./NavbarComponent.scss'],
})
export class NavbarComponent implements OnInit, OnDestroy {

  @Output('toggleSidebarEvent')
  toggleSidebarEvent: EventEmitter<any> = new EventEmitter();

  @Output('toggleChatEvent')
  toggleChatEvent: EventEmitter<any> = new EventEmitter();
  $el: any;
  showLoader: boolean;
  sub: Subscription;

  currentUser: User;
  currentUser$: Observable<User>;
  currentAgency: any;

  constructor(
      el: ElementRef,
      public config: ConfigService,
      private securityService: SecurityService,
      private _request: RequestService,
      private _store: Store<fromRoot.AppState>) {
    this.currentUser$ = this._store.pipe(select(selectSecurityStateDataCurrentUser));
    this.$el = jQuery(el.nativeElement);
  }

  logout(): void {
    this.securityService.logout();
  }

  toggleSidebar(state): void {
    this.toggleSidebarEvent.emit(state);
  }

  ngOnInit(): void {
    this.currentUser$.subscribe(user => this.currentUser = user);
    this.setupAnimations();
    // TODO: make this a component
    const loading$ = this._request.loading;
    this.sub = loading$.pipe(
      switchMap((isLoading: boolean) => {
        if (isLoading) {
          return observableFrom([isLoading]);
        } else {
          return observableTimer(700).pipe(takeUntil(loading$), map(() => isLoading), );
        }
      }))
      .subscribe((isLoading) => this.showLoader = isLoading);
  }

  /**
   * Taken from theme
   */
  setupAnimations(): void {
    setTimeout(() => {
      const $chatNotification = jQuery('#chat-notification');
      $chatNotification.removeClass('hide').addClass('animated fadeIn')
        .one('webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend', () => {
          $chatNotification.removeClass('animated fadeIn');
          setTimeout(() => {
            $chatNotification.addClass('animated fadeOut')
              .one('webkitAnimationEnd mozAnimationEnd MSAnimationEnd' +
                ' oanimationend animationend', () => {
                $chatNotification.addClass('hide');
              });
          }, 8000);
        });
      $chatNotification.siblings('#toggle-chat')
        .append('<i class="chat-notification-sing animated bounceIn"></i>');
    }, 4000);

    this.$el.find('.input-group-addon + .form-control').on('blur focus', function(e): void {
      jQuery(this).parents('.input-group')
        [e.type === 'focus' ? 'addClass' : 'removeClass']('focus');
    });
  }

  ngOnDestroy(): void {
    this.sub?.unsubscribe();
  }

}
