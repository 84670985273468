<div class="row force-full-width">
  <div class="col-xs-12">
    <div class="clearfix">
      <ul class="nav nav-tabs pull-xs-left" role="tablist">
        <li class="nav-item"><a class="nav-link" (click)="tab = 'agencies'; false;" [class.active]="tab === 'agencies'">Agencies</a>
        </li>
        <li class="nav-item"><a class="nav-link" (click)="tab = 'files'; false;"
                                [class.active]="tab === 'files'">Files</a></li>
        <li class="nav-item"><a class="nav-link" (click)="tab = 'comments'; false;" [class.active]="tab === 'comments'">Comments</a>
        </li>
        <li class="nav-item" *ngIf="helper.canAddInvites()"><a class="nav-link" (click)="tab = 'invites'; false;"
                                                               [class.active]="tab === 'invites'">Invites</a></li>
      </ul>
    </div>
    <div class="tab-content mb-lg overflow-visible">
      <div role="tabpanel" class="tab-pane active in" [ngSwitch]="tab">
        <efnAgenciesTab *ngIf="tab === 'agencies'" [efnCase]="efnCase" #agency></efnAgenciesTab>
        <efnFilesTab *ngIf="tab === 'files'" [efnCase]="efnCase"></efnFilesTab>
        <efnCommentsTab *ngIf="tab === 'comments'" [efnCase]="efnCase"></efnCommentsTab>
        <efnInvitesTab *ngIf="helper.canAddInvites() && tab === 'invites'" [efnCase]="efnCase"></efnInvitesTab>
      </div>
    </div>
  </div>
</div>
