import {Component, forwardRef, Injector, Input, OnInit} from '@angular/core';
import {AutoDisableParent} from '../../../../../../../directives/AutoDisableDirective';
import {AssistanceApplicationTabsComponent} from '../assistance-application-tabs.component';
import {UntypedFormControl} from '@angular/forms';
import {Validators} from '../../../../../../../utilities/Validators';
import {takeUntil, tap} from 'rxjs/operators';
import {
  AssistanceApplication,
  AssistanceApplicationComment,
  AssistanceApplicationCommentClient, User
} from '../../../../../../../services/api.client';
import {select, Store} from '@ngrx/store';
import {selectSecurityStateDataCurrentUser} from '../../../../../../../store/selectors/security.selectors';
import {Observable} from 'rxjs';
import {Disposable} from '../../../../../../../utilities/Disposable';
import * as fromRoot from '../../../../../../../store';
import {NotificationService} from '../../../../../../../services/NotificationService';

@Component({
  selector: 'app-assistance-application-comments',
  templateUrl: './assistance-application-comments.component.html',
  styleUrls: ['./assistance-application-comments.component.scss'],
})
export class AssistanceApplicationCommentsComponent extends Disposable implements OnInit{
  public loading = true;
  comment = new UntypedFormControl(null, Validators.required);
  comments: AssistanceApplicationComment[] = null;
  user: User;
  user$: Observable<User>;
  _assistanceApplication: AssistanceApplication;
  @Input()
  set assistanceApplication(assistanceApplication: AssistanceApplication) {
    this._assistanceApplication = assistanceApplication;
    if (this._assistanceApplication && !this.comments) {
      this.reloadComments();
    }
  }

  get assistanceApplication(): AssistanceApplication {
    return this._assistanceApplication;
  }

  constructor(private assistanceApplicationCommentsClient: AssistanceApplicationCommentClient,
              private _store: Store<fromRoot.AppState>,
              private injector: Injector) {
    super();
    this.user$ = this._store.pipe(select(selectSecurityStateDataCurrentUser));
  }

  addComment(): void {
    this.loading = true;
    if (!this.comment.valid) {
      this.comment.markAsTouched();
      return;
    }
    const user = this.user;
    this.assistanceApplicationCommentsClient.add({
      assistanceApplicationId: this._assistanceApplication.id,
      userId: user.id,
      comment: this.comment.value,
      agencyName: user.agency.agencyName,
      userFullName: user.firstName + ' ' + user.lastName
    } as AssistanceApplicationComment)
        .pipe(
            tap(() => {
              this.comment.setValue(null);
              this.comment.markAsUntouched();
              this.comment.markAsPristine();
              this.reloadComments();
            }) as any
        ).subscribe(x => {
          this.injector.get(NotificationService).showSuccess('Comment Successfully Added!');
    }, error => {
      this.injector.get(NotificationService).showError('An error occurred while adding comment. Please try again.');
    });
  }

  reloadComments(): void {
    this.comments = null;
    this.assistanceApplicationCommentsClient.get(this._assistanceApplication.id)
        .subscribe((comments: AssistanceApplicationComment[]) => {
          this.comments = comments;
          this.loading = false;
            },
            error => {
              this.injector.get(NotificationService).showError('An error occurred while retrieving comments.');
            });
  }

  ngOnInit(): void {
    this.user$
        .pipe(takeUntil(this.$destroyed))
        .subscribe(user => this.user = user);
  }
}
