import {Injectable} from '@angular/core';

@Injectable()
export class LocalStorageService {
  localStorage: any;
  isEnabled = false;

  constructor() {
    if (!localStorage) {
      this.isEnabled = false;
      console.error('Current browser does not support Local Storage');
      return;
    }
    this.isEnabled = true;
    this.localStorage = localStorage;
  }

  public set(key: string, value: string): void {
    if (this.isEnabled) {
      this.localStorage[key] = value;
    }
  }

  public get(key: string): string {
    if (!this.isEnabled) {
      return '';
    }

    return this.localStorage[key] || false;
  }

  public setObject(key: string, value: any): void {
    if (!this.isEnabled) {
      return;
    }
    this.localStorage[key] = JSON.stringify(value);
  }

  public getObject(key: string): any {
    if (!this.isEnabled) {
      return null;
    }
    return JSON.parse(this.localStorage[key] || '{}');
  }

  public remove(key: string): any {
    if (!this.isEnabled) {
      return null;
    }
    this.localStorage.removeItem(key);
  }
}
