<clientInfo [client]="client"></clientInfo>
<div class="row">
  <div class="col-xs-12">
    <form requestEnrollmentForm class="form-horizontal" [application]="application$ | async">
      <div footer>
        <button type="button" class="btn btn-primary" (click)="back()">Back</button>
        <button type="submit" class="btn btn-primary pull-right" (click)="submit()">Request Enrollment</button>
      </div>
    </form>
  </div>
</div>