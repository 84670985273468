<h3 *ngIf="agencies === null" class="text-center ab-center">
  Fetching agencies...
</h3>
<h3 *ngIf="agencies?.length === 0" class="text-center ab-center">
  No agencies listed.
</h3>
<table *ngIf="agencies?.length" class="table table-striped table-hover">
  <thead>
  <tr>
    <td>Agency Name</td>
    <td>Commit Amount</td>
    <td>Commit Note</td>
    <td>Status</td>
    <td *ngIf="isFunded()">Pledge</td>
  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let a of agencies">
    <td>{{ a.agencyName }}</td>
    <td>${{ a.originalCommitAmount ? a.originalCommitAmount : 0 }}</td>
    <td>{{ a.commitNote ? a.commitNote : 'N/A' }}</td>
    <td>{{ a.status }}</td>
    <td *ngIf="isFunded() && !a.originalCommitAmount">
      <span></span>
    </td>
    <td *ngIf="canAccessPledge(a) && isFunded() && a.originalCommitAmount && !a.pledgeId">
      <button type="button" class="btn btn-xs btn-outline-success" (click)="createPledge(a)">
        Create
      </button>
    </td>
    <td *ngIf="canAccessPledge(a) && isFunded() && a.originalCommitAmount && a.pledgeId">
      <button type="button" class="btn btn-xs btn-outline-success" (click)="goToPledge(a.pledgeId)">
        View
      </button>
    </td>
  </tr>
  </tbody>
</table>



