import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {ClientCreateApplicationComponent} from '../ClientCreateApplicationComponent';
import {ClientFormComponent} from '../../ClientFormComponent';
import {UntypedFormControl} from '@angular/forms';
import {Validators} from '../../../../../utilities/Validators';

@Component({
    selector: 'clientVerification',
    templateUrl: './ClientVerificationComponent.html',
    styleUrls: ['./ClientVerificationComponent.scss']
})

export class ClientVerificationComponent implements AfterViewInit {
    @ViewChild(ClientFormComponent)
    form: ClientFormComponent;
    confirm = new UntypedFormControl(false, Validators.mustBeChecked);

    ngAfterViewInit(): void {
        setTimeout(() => {
            this.form.form.addControl('confirm', this.confirm);
            this.confirm.updateValueAndValidity({onlySelf: false, emitEvent: true});

            // KeepingCurrent application flow requires a SSN and a Monthly Household Income to be completed
            this.form.noSsn.setValue(false);
            this.form.houseHoldIncome.setValidators([Validators.required, Validators.minAmount(20)]);
        });
    }

    constructor(
        private parent: ClientCreateApplicationComponent,
    ) {
    }

    submit(): void {
        this.form
            .submitNoRedirect()
            .subscribe(() => {
                this.parent.next();
            });

    }
}
