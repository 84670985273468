import {DisplayEntity} from '../../models/DisplayEntity';
import {User, UserType} from '../../services/api.client';
import {ISecurityState} from '../ISecurityState';
import {createReducer, on} from '@ngrx/store';
import {
    loginFailure,
    loginInProgress,
    loginSuccess,
    logoutAction, setDefaultManagementAgency,
    setVerifiedClientId,
    systemDataUpdatedAction
} from '../actions/security.actions';

// export const initialState: State = adapter.getInitialState({
export const initialState: ISecurityState = {
    isAuthenticated: false,
    isLoggingIn: false,
    currentUser: null,
    currentDisplayEntity: null,
    securityToken: null,
    rootUrl: null,
    loginMessage: null,
    systemData: null,
    envName: null,
    verifiedClientId: null,
    defaultManagementAgency: null
};

export const securityReducer = createReducer(
    initialState,
    on(loginSuccess, (state, { payload }) => {
        const result = payload;
        const currentUser = result.currentUser;
        const userType = currentUser.userType;
        const userAgency = result.userAgency;
        let displayEntity;

        if (userType === UserType.FundAdmin) {
            displayEntity = new DisplayEntity();
            displayEntity.entityType = 'company';
            displayEntity.entityId = currentUser.company.id;
            displayEntity.entityName = currentUser.company.name;
        } else if (userType === UserType.SystemAdmin) {
            displayEntity = new DisplayEntity();
            displayEntity.entityType = 'admin';
            displayEntity.entityName = 'System Admin';
        } else {
            const currentAgency = currentUser.agency;
            displayEntity = new DisplayEntity();
            displayEntity.entityType = 'agency';
            displayEntity.entityId = currentAgency.id;
            displayEntity.entityName = currentAgency.agencyName;
        }

        const user = User.fromJS({ ...currentUser, agency: userAgency });

        const newUser: ISecurityState = {
            ...state,
            isAuthenticated: true,
            currentUser: user,
            currentDisplayEntity: displayEntity,
            securityToken: result.securityToken,
            rootUrl: '',
            isLoggingIn: false,
            envName: result.environment,
            verifiedClientId: result.verifiedClientId
        };

        return newUser;
    }),
    on(loginFailure, (state, { payload }) => ({
        ...state,
        isAuthenticated: false,
        loginMessage: payload,
        isLoggingIn: false,
    })),
    on(logoutAction, (state) => ({
        ...state,
        isAuthenticated: false,
        isLoggingIn: false,
    })),
    on(loginInProgress, (state) => ({
        ...state,
        isAuthenticated: false,
        isLoggingIn: true,
    })),
    on(systemDataUpdatedAction, (state, { payload }) => ({
        ...state,
        systemData: payload,
    })),
    on(setVerifiedClientId, (state, { payload }) => ({
        ...state,
        verifiedClientId: payload
    })),
    on(setDefaultManagementAgency, (state, { payload }) => ({
        ...state,
        defaultManagementAgency: payload
    }))
);
