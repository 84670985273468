<div class="row">
  <div class="col-md col-xs-12">
    <ul class="help-text-align">
      <li><span>To Enter new EFS commitment go to ‘Clients’ Menu at the left.</span></li>
    </ul>
  </div>
</div>
<div class="row">
  <h2 class="offset-md-1 col-md-10">EFS Report</h2>
</div>
<div class="row">
  <div class="col-xs-12">
    <form efsReportForm #form
          [showDateRange]="true"
          [showPledgeTypes]="true"
          [showAgencies]="true" novalidate>
      <button type="submit" class="btn btn-primary" (click)="form.submitEfsReport()">Submit</button>
      <a class="btn btn-primary" [href]="form.downloadEfsReport()">Export to Excel</a>
    </form>
    <h1 *ngIf="rows | async">EFS Report</h1>
    <reportTable [headers]="tableHeaders" [rows]="rows | async"></reportTable>
  </div>
</div>
