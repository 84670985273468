import {Component, OnInit, ViewChild} from '@angular/core';
import {ITableHeader} from '../../../../shared/table/TableComponent';
import {KeepingCurrentReportFormComponent} from '../form/KeepingCurrentReportFormComponent';
import {Observable} from 'rxjs';
import {ApprovedEnrollment, WeatherizationStatusesType} from '../../../../../services/api.client';
import {formatDate, splitCamelCase} from '../../../../../utilities/Util';

@Component({
  templateUrl: './ApprovedEnrollmentsReportComponent.html',
})
export class ApprovedEnrollmentsReportComponent {

  tableHeaders: ITableHeader[] = [
    {text: 'Keeping Current Application Id'},
    {text: 'Program Name'},
    {text: 'Program Monthly Credit'},
    {text: 'Status Name'},
    {text: 'Account Number'},
    {text: 'Agency Name'},
    {text: 'Ameren Css Code'},
    {text: 'Client Ssn'},
    {text: 'Client Last Name'},
    {text: 'Client Middle Initial'},
    {text: 'Client Federal Poverty Level Display'},
    {text: 'Is Third Party Agreeable'},
    {text: 'Client Has Children Under Five'},
    {text: 'Client Is Disabled'},
    {text: 'Client Is Elderly'},
    {text: 'Is Liheap Received'},
    {text: 'Is Liheap Requested'},
    {text: 'Is Weatherization Received'},
    {text: 'Is Weatherization Requested'},
    {text: 'Weatherization Status'},
    {text: 'Download Timestamp'},
  ];

  @ViewChild('form')
  form: KeepingCurrentReportFormComponent;

  report$: Observable<ApprovedEnrollment[]>;
  rows: any[][];

  submit(): void {
    if (this.form.exportDescription?.value === 'new') {
      this.form.setDownloadTimeStampForApprovalRecordedApplications()
        .map(this.transformTableColumns)
        .subscribe((rows) => this.rows = rows);
      return;
    } else {
      this.form.submitApprovedEnrollmentsReport()
        .map(this.transformTableColumns)
        .subscribe((rows) => this.rows = rows);
    }
  }

  transformTableColumns = (report) => {
    return report.map(a => [
      a.keepingCurrentApplicationId,
      a.programName,
      a.programMonthlyCredit,
      a.statusName,
      a.accountNumber,
      a.agencyName,
      a.amerenCssCode,
      a.clientSsn,
      a.clientLastName,
      a.clientMiddleInitial,
      a.clientFederalPovertyLevelDisplay,
      a.isThirdPartyAgreeable,
      a.clientHasChildrenUnderFive,
      a.clientIsDisabled,
      a.clientIsElderly,
      a.isLiheapReceived,
      a.isLiheapRequested,
      a.isWeatherizationReceived,
      a.isWeatherizationRequested,
      this.getWeatherizationStatus(a.weatherizationStatus),
      formatDate(a.downloadTimestamp)
    ]);
  }

  private getWeatherizationStatus = (status: number | string): string => {
      if (typeof status === 'number') {
          return splitCamelCase(WeatherizationStatusesType[status], true);
      }
      return splitCamelCase(status, true);
  }

  ngAfterViewInit(): void {

  }
}
