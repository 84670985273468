import {Component, Input, ViewChild} from '@angular/core';
import {AbstractControl, FormArray, FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {Masking} from '../../../../../../../utilities/Masking';
import {LookUp} from '../../../../../../../services/api.client';
import {Observable} from 'rxjs';
import {
  AssistanceApplicationSsnRevealComponent
} from '../../../../../../shared/assistanceApplicationSsnReveal/assistance-application-ssn-reveal.component';
import {EmploymentStatus} from '../../../../../../../shared/enums/employment-status';
import {select, Store} from '@ngrx/store';
import * as fromRoot from '../../../../../../../store';
import {selectSecurityStateDataSystemDataEmploymentStatus} from '../../../../../../../store/selectors/security.selectors';
import {Validators} from '../../../../../../../utilities/Validators';
import * as moment from 'moment';
import {minorValidation} from '../../../../../../public/application/validators';

@Component({
  selector: 'app-assistance-application-household-information-form',
  templateUrl: './assistance-application-household-information-form.component.html',
  styleUrls: ['./assistance-application-household-information-form.component.scss']
})
export class AssistanceApplicationHouseholdInformationFormComponent {
  @Input() form: FormGroup;

  public ssnMask = Masking.ssnMask;
  public last4Mask = Masking.ssnLast4Mask;
  public phoneMask = Masking.phoneMask;

  public employmentStatuses: LookUp[];
  private employmentStatuses$: Observable<LookUp[]>;
  @ViewChild(AssistanceApplicationSsnRevealComponent) revealSsn: AssistanceApplicationSsnRevealComponent;

  private includedEmploymentStatuses = [EmploymentStatus.PartTimeEmployment, EmploymentStatus.FullTimeEmployment,
    EmploymentStatus.Retired, EmploymentStatus.SelfEmployed, EmploymentStatus.UnemployedWithBenefits,
    EmploymentStatus.UnemployedNoBenefits];

  constructor(private fb: FormBuilder, private _store: Store<fromRoot.AppState>){
    this.employmentStatuses$ = this._store.pipe(select(selectSecurityStateDataSystemDataEmploymentStatus));
  }

  ngOnInit(): void {
    this.employmentStatuses$.subscribe(employmentStatus => {
      const includedEmploymentStatusesStrings = this.includedEmploymentStatuses.map(o => o.toString());
      this.employmentStatuses = employmentStatus.filter(item => includedEmploymentStatusesStrings.includes(item.key));
    });

    const minorCount = this.form.get('minors').value.length;
    this.populateHouseholdSlots(minorCount);
  }

  get householdInformationAdultsFormArray(): FormArray {
    return this.form.get('adults') as FormArray;
  }
  get householdInformationMinorsFormArray(): FormArray {
    return this.form.controls.minors as FormArray;
  }

  get numberOfAdults(): number {
    const adults = this.form.get('adults').value;
    return adults.length;
  }

  get numberOfMinors(): number {
    const minors = this.form.get('minors').value;
    return minors.length;
  }

  populateHouseholdSlots(minorCount): void {
    if (!minorCount || minorCount < 1) {
      this.addMinor();
    }
  }

  addAdult(): void {
    const adults = this.form.get('adults') as FormArray;
    adults.push(this.createAdult());
  }

  addMinor(): void {
    const minors = this.form.get('minors') as FormArray;
    minors.push(this.createMinor());
  }

  createAdult(): FormGroup {
    return this.fb.group({
      id: [null],
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      birthYear: new FormControl(
          null,
          Validators.compose([Validators.required,
            Validators.yearRange(moment().subtract(150, 'years'), moment())])),
      ssn: ['', Validators.compose([Validators.required])],
      employmentStatus: ['', Validators.required],
      monthlyIncomeAmount: ['', Validators.required]
    });
  }

  createMinor(): FormGroup {
    return this.fb.group({
      id: [null],
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      birthYear: new FormControl(
          null,
          Validators.compose([Validators.required,
            minorValidation(),
            Validators.yearRange(moment().subtract(150, 'years'), moment())])),
      ssn: ['', Validators.compose([Validators.required])]
    });
  }

  removeAdult(index: number): void {
    const adults = this.form.get('adults') as FormArray;
    adults.removeAt(index);
  }

  removeMinor(index: number): void {
    const minors = this.form.get('minors') as FormArray;
    minors.removeAt(index);
  }

  public showSsnEye(ssn: string): boolean {
    return (ssn && ssn.includes('*'));
  }

  protected validateSsn(control: AbstractControl): void {
    const ssnRegexPattern = new RegExp(/(?:\d{3}-\d{2}-\d{4}|\*{3}-\*{2}-\d{4})/);
    const ssn = control?.value;

    if (ssn == null) {
      control.setErrors(Validators.required);
    }

    // Return true if the ssn matched the ReGex
    if (ssnRegexPattern.test(ssn) === true) {
      delete control?.errors?.invalidSocial;
    }
    else {
      control.setErrors({
        invalidSocial: true
      });
    }
  }
}
