import {share} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {getMessageFromException} from '../utilities/Util';
import {
    PagedListOfPledge,
    Pledge,
    PledgeAssistanceType,
    PledgeClient,
    PledgeStatusType,
    PledgeTypeBalance,
    PledgeTypeBalanceClient, SimpleBooleanResult, UserPledgeAccess
} from './api.client';
import {NotificationService} from './NotificationService';
import {Store} from '@ngrx/store';
import * as fromRoot from '../store';
import {Observable} from 'rxjs';
import {
    pledgesDataIsLoaded,
    pledgesDataIsLoading,
    setCurrentClientPledgeList,
    setCurrentSelectedPledge, setManualPendingPledges, setPledgeTypeBalance, setUwdaPledges
} from '../store/actions/pledge.actions';

@Injectable()
export class PledgeService {

    constructor(private _pledgeApiService: PledgeClient,
                private _notificationService: NotificationService,
                private _pledgeTypeBalanceApiService: PledgeTypeBalanceClient,
                private _store: Store<fromRoot.AppState>) {
    }

    // format Pledge Status
    formatPledgeStatus(pledgeStatus): any {
        let status: any = pledgeStatus;
        if (typeof pledgeStatus === 'string' && pledgeStatus.length > 0) {
            status = pledgeStatus[0].toUpperCase() + (pledgeStatus.length > 1 ? pledgeStatus.slice(1) : '');
        } else if (typeof pledgeStatus === 'number') {
            status = PledgeStatusType[pledgeStatus];
        }
        return status || 'Missing Status';
    }

    // format Pledge Assistance Type
    formatPledgeAssistanceType(assistanceType): string {

        return PledgeAssistanceType[assistanceType];
    }

    getPledges(searchValue?: string,
               agencyId?: string,
               pledgeTypeId?: string,
               sortName?: string,
               descending?: boolean,
               limit?: number,
               offset?: number): Observable<PagedListOfPledge> {
        this.startLoadingAction();
        const request = this._pledgeApiService.retrievePledgesPaged(
            searchValue,
            agencyId,
            pledgeTypeId,
            limit,
            offset,
            sortName,
            null,
            descending
        ).pipe(share());
        request.subscribe({
            next: (result) => {
                this.setPledgesAction(result);
            }, error: () => {
                this._notificationService.showError('Error Loading Pledges');
            }, complete: () => {

            }
        });
        return request;
    }

    addPledge(pledge: Pledge): Observable<Pledge> {
        const request = this._pledgeApiService.addPledge(pledge).pipe(share());
        this._notificationService.showSuccess('Attempting to save new Pledge.');
        request.subscribe({
            next: (result) => {
                this.setCurrentSelectedPledge(result);
                this._notificationService.showSuccess('Pledge Successfully Created!');
            }, error: (err) => {
                this._notificationService.showError(getMessageFromException(err));
            }, complete: () => {

            }
        });
        return request;
    }

    updatePledge(pledge: Pledge): Observable<Pledge> {
        const request = this._pledgeApiService.updatePledge(pledge).pipe(share());
        this._notificationService.showSuccess('Attempting to update Pledge.');
        request.subscribe({
            next: (result) => {
                this.setCurrentSelectedPledge(result);
                this._notificationService.showSuccess('Pledge Successfully Updated!');
            }, error: (err) => {
                this._notificationService.showError(getMessageFromException(err));
            }, complete: () => {

            }
        });
        return request;
    }

    deletePledge(id: string): Observable<Pledge> {
        // this uses the pledge update API endpoint on purpose
        const request = this._pledgeApiService.deletePledge(id).pipe(share());
        this._notificationService.showSuccess('Attempting to delete Pledge.');
        request.subscribe({
            next: () => {
                this._notificationService.showSuccess('Pledge Successfully Deleted!');
            }, error: () => {
                this._notificationService.showError('There was an error deleting this Pledge.');
            }, complete: () => {

            }
        });
        return request;
    }

    getPledgeById(id: string): Observable<Pledge> {
        const request = this._pledgeApiService.retrievePledge(id).pipe(share());
        request.subscribe({
            next: (result) => {
                this.setCurrentSelectedPledge(result);
            }, error: () => {
                this._notificationService.showError('There was a problem retrieving the selected pledge.');
            }, complete: () => {

            }
        });
        return request;
    }

    getCurrenClientPledgeList(id: string): Observable<Pledge[]> {
        const request = this._pledgeApiService.retrieveClientsPledges(id).pipe(share());
        request.subscribe({
            next: (result) => {
                this.setCurrentClientPledgeListAction(result);
            }, error: () => {
                this._notificationService.showError('There was a problem retrieving the client\'s pledges.');
            }, complete: () => {

            }
        });
        return request;
    }

    getPledgeTypeBalance(agencyId: string, pledgeTypeId: string): Observable<PledgeTypeBalance> {
        const request = this._pledgeTypeBalanceApiService.retrieveBalance(agencyId, pledgeTypeId).pipe(share());
        request.subscribe({
            next: (result) => {
                this.setCurrentPledgeTypeBalance(result);
            }, error: () => {
                this._notificationService.showError('There was an error getting the balance for this Fund Type');
            }, complete: () => {

            }
        });

        return request;
    }

    canCurrentUserManagePledge(pledgeId: string): Observable<UserPledgeAccess> {
        return this._pledgeApiService.canCurrentUserManagePledge(pledgeId).pipe(share());
    }

    checkMaxYearlyPledgeBalance(clientId: string, pledgeTypeId: string): Observable<PledgeTypeBalance> {
        const request = this._pledgeTypeBalanceApiService.checkYearlyMaxPledge(clientId, pledgeTypeId).pipe(share());
        request.subscribe({
            next: () => {

            }, error: () => {
                this._notificationService.showError('There was an error getting the running pledge balance for this Client');
            }, complete: () => {

            }
        });
        return request;
    }

    getConfirmedUwdaPledges(pledgeTypeId?: string,
                            sortName?: string,
                            descending?: boolean,
                            limit?: number,
                            offset?: number): Observable<PagedListOfPledge> {
        this.startLoadingAction();
        const request = this._pledgeApiService.getConfirmedUwdaPledges(
            pledgeTypeId,
            limit,
            offset,
            sortName,
            null,
            descending
        ).pipe(share());
        request.subscribe({
            next: (result) => {
                this.setConfirmedUwdaPledgesAction(result);
            }, error: () => {
                this._notificationService.showError('There was a problem getting the UWDA Confirmed Pledges.');
            }, complete: () => {

            }
        });
        return request;
    }


    approveConfirmedUwdaPledgesAuto(ids: string[]): Observable<void> {
        const request = this._pledgeApiService.approveConfirmedUwdaPledgesAuto(ids).pipe(share());
        request.subscribe({
            next: () => {
                this._notificationService.showSuccess('Successfully Auto Approved Pledges!');
            }, error: () => {
                this._notificationService.showError('There was a problem Approving Pledges.');
            }, complete: () => {

            }
        });
        return request;
    }

    approveConfirmedUwdaPledgesManual(ids: string[]): Observable<void> {
        const request = this._pledgeApiService.approveConfirmedUwdaPledgesManual(ids).pipe(share());
        request.subscribe({
            next: () => {
                this._notificationService.showSuccess('Successfully Manually Approved Pledges!');
            }, error: () => {
                this._notificationService.showError('There was a problem Approving Pledges.');
            }, complete: () => {

            }
        });
        return request;
    }

    getManualPendingToPaidPledges(): Observable<Pledge[]> {
        this.startLoadingAction();
        const request = this._pledgeApiService.getManualPendingPledges();
        request.subscribe({
            next: (result) => {
                this.setManualPendingPledgesAction(result);
            }, error: () => {
            }
        });
        return request;
    }

    bulkUpdateManualPendingToPaid(parms): Observable<SimpleBooleanResult> {
        // let ids = parms.pledgeIds;
        // let date = parms.entryDate;
        const request = this._pledgeApiService.changeManualPandingToPaid(parms).pipe(share());
        this._notificationService.showSuccess('Attempting to bulk update Pledge Status.');
        request.subscribe({
            next: () => {
                this._notificationService.showSuccess('Pledge Status bulk update completed!');
                this.getManualPendingToPaidPledges();
            }, error: () => {
                this._notificationService.showSuccess('Pledge Status bulk update completed!');
            }, complete: () => {

            }
        });
        return request;
    }

    setCurrentPledgeTypeBalance(balance: PledgeTypeBalance): void {
        this.setCurrentPledgeTypeBalanceAction(balance);
    }

    setCurrentSelectedPledge(pledge: Pledge): void {
        this.setCurrentSelectedPledgeAction(pledge);
    }

    clearConfirmedUwdaPledges(): void {
        this.setConfirmedUwdaPledgesAction(null);
    }

    clearManualPendingPledges(): void {
        this.setManualPendingPledgesAction(null);
    }

    // --------Pledge Actions----------------
    private startLoadingAction(): void {
        this._store.dispatch(pledgesDataIsLoading());
    }

    setCurrentSelectedPledgeAction(selectedPledge: Pledge): void {
        this._store.dispatch(setCurrentSelectedPledge({payload: selectedPledge}));
    }

    private setPledgesAction(pledges: PagedListOfPledge): void {
        this._store.dispatch(pledgesDataIsLoaded({payload: pledges}));
    }

    private setCurrentClientPledgeListAction(pledges: Pledge[]): void {
        this._store.dispatch(setCurrentClientPledgeList({payload: pledges}));
    }

    private setCurrentPledgeTypeBalanceAction(balance: PledgeTypeBalance): void {
        this._store.dispatch(setPledgeTypeBalance({payload: balance}));
    }

    private setConfirmedUwdaPledgesAction(pledges: PagedListOfPledge): void {
        this._store.dispatch(setUwdaPledges({payload: pledges}));
    }

    private setManualPendingPledgesAction(pledges: Pledge[]): void {
        this._store.dispatch(setManualPendingPledges({payload: pledges}));
    }
}
