import {createFeatureSelector, createSelector} from '@ngrx/store';
import {ILookUpData} from '../ILookUpData';

const lookupDataFeatureSelector = createFeatureSelector<ILookUpData>('lookup');

export const selectLookupDataCurrentSelectedLookUp = createSelector(
    lookupDataFeatureSelector,
    (state) => state.currentSelectedLookUp
);

export const selectLookupDataLookUps = createSelector(
    lookupDataFeatureSelector,
    (state) => state.lookups
);

export const selectLookupDataLookUpsTotalCount = createSelector(
    lookupDataFeatureSelector,
    (state) => state.lookups?.totalCount
);
