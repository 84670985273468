import {EMPTY as observableEmpty, Observable} from 'rxjs';
import {filter, tap} from 'rxjs/operators';
import {Component, OnInit, Output, EventEmitter, ViewChild, forwardRef, AfterViewInit} from '@angular/core';
import {Router, Params, ActivatedRoute} from '@angular/router';
import {UntypedFormBuilder, Validators, FormControl} from '@angular/forms';
import {AgencyService} from '../../../services/AgencyService';
import {Agency, AgencyStatusType, PledgeType, SystemData} from '../../../services/api.client';
import {PledgeTypeService} from '../../../services/PledgeTypeService';
import {extractEnumNames} from '../../../utilities/Util';
import {PromptComponent} from '../../shared/dialogs/PromptComponent';
import {AutoDisableParent} from '../../../directives/AutoDisableDirective';
import {select, Store} from '@ngrx/store';
import * as fromRoot from '../../../store';
import {selectAgencyDataCurrentSelectedAgency} from '../../../store/selectors/agency.selectors';
import {selectPledgeTypeDataPledgeTypesItems} from '../../../store/selectors/pledgeType.selectors';
import {selectSecurityStateDataSystemData} from '../../../store/selectors/security.selectors';


@Component({
  templateUrl: './AgencyComponent.html',
  styleUrls: ['./AgencyComponent.scss'],
  providers: [{provide: AutoDisableParent, useExisting: forwardRef(() => AgencyComponent)}],
})

export class AgencyComponent implements OnInit, AfterViewInit {

  get agencyName(): FormControl {
    return this.form.get('agencyName') as FormControl;
  }

  get status(): FormControl {
    return this.form.get('status') as FormControl;
  }

  get amerenCssCode(): FormControl {
    return this.form.get('amerenCssCode') as FormControl;
  }

  get isEfnAgency(): FormControl {
    return this.form.get('isEfnAgency') as FormControl;
  }

  get efnZones(): FormControl {
    return this.form.get('efnZones') as FormControl;
  }

  get pledgeTypeIds(): FormControl {
    return this.form.get('pledgeTypeIds') as FormControl;
  }

  constructor(private _formBuilder: UntypedFormBuilder,
              private _agencyService: AgencyService,
              private _pledgeTypeService: PledgeTypeService,
              private _router: Router,
              private _route: ActivatedRoute,
              private _store: Store<fromRoot.AppState>) {
    this.systemData$ = this._store.pipe(select(selectSecurityStateDataSystemData)) ;
    this.currentSelectedAgency$ = this._store.pipe(select(selectAgencyDataCurrentSelectedAgency));
    this.allPledgeTypes$ = this._store.pipe(select(selectPledgeTypeDataPledgeTypesItems));
  }

  isLoading: boolean;
  statuses = extractEnumNames(AgencyStatusType);
  checkBoxDirty = false;
  selectedPledgeTypes: string[] = [];
  params: any;

  // subscriptions
  systemData: SystemData;
  systemData$: Observable<SystemData>;
  currentSelectedAgency: Agency;
  currentSelectedAgency$: Observable<Agency>;
  allPledgeTypes: PledgeType[];
  allPledgeTypes$: Observable<PledgeType[]>;

  // form
  form = this._formBuilder.group({
    agencyName: new FormControl(null, Validators.required),
    status: new FormControl(AgencyStatusType.Active, Validators.required),
    amerenCssCode: new FormControl(null),
    pledgeTypeIds: new FormControl([]),
    isEfnAgency: new FormControl(false),
    efnZones: new FormControl(null),
  });

  // children - agency modal
  @ViewChild(PromptComponent)
  promptModal: PromptComponent;
  @Output('onShowPromptModal') _showPrompt = new EventEmitter();

  ngOnInit(): void {
    this.systemData$.subscribe(systemData => this.systemData = systemData);
    this.allPledgeTypes$.subscribe(pledgeTypes => this.allPledgeTypes = pledgeTypes);
    this.currentSelectedAgency$.subscribe(selectedAgency => this.currentSelectedAgency = selectedAgency);
    this._route.params.subscribe((params: Params) => {
      this.params = params;
      const id = params.id;
      if (id === 'add') { // add
        this._agencyService.setCurrentSelectedAgency(new Agency());
      } else if (!this.currentSelectedAgency) {
        this._agencyService.getAgencyById(id);
      }
    });

    this.initForm();
    this.pledgeTypeLoad();

    this.currentSelectedAgency$.pipe(
      filter(agency => !!(agency && agency.id)))
      .subscribe(agency => {
        this.form.patchValue(agency);
        this.selectedPledgeTypes = agency.pledgeTypeIds;
      });
  }

  initForm(): void {
  }

  // get Pledge Types
  pledgeTypeLoad(): void {
    this._pledgeTypeService.getPledgeTypes(
      '',
      'Name',
      false,
      100,
      0);
  }

  // disable save button if no changes
  isValidSave(): boolean {
    return !(this.form.dirty || this.checkBoxDirty);
  }

  isEdit(): boolean {
    return !!(this.currentSelectedAgency && this.currentSelectedAgency.id);
  }

  submit(): Observable<Agency> {
    const agency = this.form.value;
    if (!this.form.valid) {
      this.form.markAsTouched();
      return observableEmpty;
    }
    if (this.isEdit()) {
      agency.id = this.currentSelectedAgency.id;
      return this._agencyService.updateAgency(agency)
          .pipe(
            tap(() => this._router.navigate(['/app/agencies']))
          );
    } else {
      return this._agencyService.addAgency(agency)
          .pipe(
            tap(() => this._router.navigate(['/app/agencies']))
          );
    }

  }

  // modal
  showPromptModal(): boolean {
    this.promptModal.open().subscribe(okClicked => {
      if (okClicked) {
        this._agencyService.deleteAgency(this.currentSelectedAgency.id);
        this._router.navigate(['/app/agencies', this.params]).then();
      } else {
        return false;
      }
    });

    return false;
  }

  ngOnDestroy(): void {
    this._agencyService.setCurrentSelectedAgencyAction(null);
  }

  ngAfterViewInit(): void {
  }
}
