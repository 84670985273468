<div class="row">
    <div class="offset-md-1 col-md-10">
        <div *ngIf="showDateRange" class="form-group row">
            <label class="col-md-2">Start Date:</label>
            <datePicker class="col-md-4" [control]="startDate" [autoClose]="true"></datePicker>
            <label class="col-md-2">End Date:</label>
            <datePicker class="col-md-4" [control]="endDate" [autoClose]="true"></datePicker>
        </div>
        <div *ngIf="showMonthSelection" class="form-group row">
            <label class="col-md-2">Month/Year:</label>
            <div class="col-md-4">
                <input type="month" class="form-control" [formControl]="monthYear" (click)="openCalendar($event)">
            </div>
        </div>
        <div *ngIf="showMonthRangeSelection" class="form-group row">
            <label class="col-md-2">Start Month/Year:</label>
            <div class="col-md-4">
                <input type="month" class="form-control" [formControl]="startMonthYear" (click)="openCalendar($event)">
            </div>
            <label class="col-md-2">End Month/Year:</label>
            <div class="col-md-4">
                <input type="month" class="form-control" [formControl]="endMonthYear" (click)="openCalendar($event)">
            </div>
        </div>
        <div *ngIf="showStatuses" class="form-group row">
            <label class="col-md-2">Statuses:</label>
            <div class="col-md-10">
                <select keepingCurrentStatus [formControl]="statuses" class="form-control">
                </select>
            </div>
        </div>
        <div *ngIf="showAgencies" class="form-group row">
            <label class="col-md-2">Agency</label>
            <div class="col-md-10">
                <input #agency type="text" class="form-control" (click)="agencyListModal.open()" value='All Agencies'>
                <agency-box
                        (onAgencySelected)="agencyId.setValue($event && $event.id); agency.value = $event && $event.agencyName || 'All Agencies'"></agency-box>
            </div>
        </div>
        <div *ngIf="showExportDescription" class="form-group row">
            <label class="col-md-2">Export Description:</label>
            <div class="col-md-10">
                <select [formControl]="exportDescription" class="form-control">
                    <option value="new">[new]</option>
                    <option *ngFor="let item of approvedEnrollmentHistory"
                            [value]="item.downloadTimestamp">{{ item.downloadTimestamp | moment:dateFormat}} ... {{ item.count }}
                        Approved Enrollment Record(s)
                    </option>
                </select>
            </div>
        </div>
        <ng-content></ng-content>
    </div>
</div>
