<h1 class="page-title">Browse KC Applications</h1>
<section class="widget">
  <div class="widget-body">
    <div class="mt">
      <form novalidate>
        <div class="row">
          <div class="col-md col-xs-12 label-input-inline">
            <!--Account #-->
            <label class="header-label">Account #</label>
            <input class="search-text-input form-control"
                   type="text"
                   placeholder="Search by Account Number"
                   [ngModel]="searchValueAccount"
                   [ngModelOptions]="{standalone: true}"
                   (ngModelChange)="setSearchValueAccount($event)"/>
          </div>
          <div class="col-md col-xs-12">
            <!--SSN #-->
            <label class="header-label">SSN #</label>
            <input class="search-text-input form-control"
                   type="text"
                   placeholder="Search by SSN"
                   [ngModel]="searchValueSSN"
                   [ngModelOptions]="{standalone: true}"
                   (ngModelChange)="setSearchValueSSN($event)"/>
          </div>
        </div>
        <div class="row">
          <div class="col-md col-xs-12">
            <!--Agency modal filter-->
            <label class="header-label">Agency:</label>
            <input class="agency-modal-input form-control"
                   type="text"
                   placeholder="select agency to filter"
                   (click)="showAgencyModal()"
                   (keydown)="showAgencyModal()"
                   value="{{selectedAgencyName}}"
                   [disabled]="isDisabledAgency()"/>
          </div>
          <div class="col-md col-xs-12 dropdown-menu-top">
            <!--Status filter-->
            <label class="allocation-label">Application Status:</label>
            <dropdown #statusDropdown class="drop-down-inline"
                      [promptValue]="'Status'"
                      [values]="statusesDropdown"
                      (select)="currentStatus($event)"></dropdown>
          </div>
        </div>
        <div class="row">
          <div class="col-xs-12">
            <button class="btn btn-primary pull-left" role="button" (click)="searchApplications()">
              Search
            </button>
          </div>
        </div>
      </form>
      <table-dynamic id="datatable-table"
                     class="table table-striped table-hover"
                     [headers]="tableHeaders"
                     [currentPage]="currentPage"
                     [totalCount]="totalCount"
                     (headerClick)="sortApplications($event)"
                     (pageChanged)="setPage($event)"
                     (limitChange)="setLimit($event)">
        <tr *ngFor="let app of pagedApplications?.items">
          <td>
            <a [routerLink]="['../client', app.clientId]"
               (click)="setCurrentSelectedClient(app.clientId)"><strong>{{ app.client.lastName
              }}</strong></a>
          </td>
          <td>{{ app.client.firstName }}</td>
          <td class="hidden-sm-down">{{ app.accountNumber}}</td>
          <td class="hidden-sm-down">{{ app.enteredDate | moment: 'L' }}</td>
          <td class="hidden-sm-down">{{ app.modifiedDate | moment: 'L' }}</td>
          <td>
            <a [routerLink]="['../client', app.clientId, 'application', app.id]"
               (click)="setCurrentSelectedApplication(app.id)"><strong>{{
              formatStatus(app.keepingCurrentStatusId) }}</strong></a>
          </td>
        </tr>
      </table-dynamic>
    </div>
  </div>
  <agency-box (onAgencySelected)="onAgencySelected($event)"></agency-box>
</section>
