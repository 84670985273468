import {EMPTY as observableEmpty, Observable} from 'rxjs';

import {catchError, takeUntil, tap} from 'rxjs/operators';
import {Component, EventEmitter, forwardRef, HostBinding, OnInit, Output, ViewChild} from '@angular/core';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {Validators} from '../../../../utilities/Validators';
import {AbstractControl, FormBuilder, FormControl} from '@angular/forms';
import {UserService} from '../../../../services/UserService';
import {Agency, Company, GenericApiResponse, User, UserStatusType, UserType} from '../../../../services/api.client';
import {AgencyService} from '../../../../services/AgencyService';
import {SecurityService} from '../../../../services/SecurityService';
import {NotificationService} from '../../../../services/NotificationService';
import {ChangePasswordModalComponent} from '../../../shared/changepassword/ChangePasswordModalComponent';
import {Masking} from '../../../../utilities/Masking';
import {extractEnumNames} from '../../../../utilities/Util';
import * as _ from 'lodash';
import {AutoDisableParent} from '../../../../directives/AutoDisableDirective';
import {Store} from '@ngrx/store';
import * as fromRoot from '../../../../store';
import {selectAgencyDataAgenciesItems} from '../../../../store/selectors/agency.selectors';
import {
    selectSecurityStateDataCurrentUser,
    selectSecurityStateDataCurrentUserUserType
} from '../../../../store/selectors/security.selectors';
import {selectUserDataSelectedUser} from '../../../../store/selectors/user.selectors';
import {AgencyListModalComponent} from '../../agency/AgencyListModalComponent';
import {Disposable} from '../../../../utilities/Disposable';
import {CompanyListModalComponent} from '../../company/CompanyListModalComponent';
import {CompanySelectedEvent} from '../../../shared/events/company-selected-event';
import {type} from 'jquery';

@Component({
    selector: '[userForm]',
    templateUrl: './UserFormComponent.html',
    styleUrls: ['./UserFormComponent.scss'],
    providers: [{provide: AutoDisableParent, useExisting: forwardRef(() => UserFormComponent)}],
})
export class UserFormComponent extends Disposable implements OnInit {

    get canDisabledUser(): boolean {
        return !(this.paramId === 'add' || this.paramId === this.currentUser.id);
    }

    constructor(private fb: FormBuilder,
                private _router: Router,
                private _securityService: SecurityService,
                private _route: ActivatedRoute,
                private _notificationService: NotificationService,
                private _agencyService: AgencyService,
                private _userService: UserService,
                private _store: Store<fromRoot.AppState>) {
        super();
        this.currentUser$ = this._store.select(selectSecurityStateDataCurrentUser);
        this.loggedInUserType$ = this._store.select(selectSecurityStateDataCurrentUserUserType);
        this.allAgencies$ = this._store.select(selectAgencyDataAgenciesItems);
        this.selectedUser$ = this._store.select(selectUserDataSelectedUser);
    }

    get userTypeValue(): number | null {
        return +this.userType?.value as number ?? null;
    }

    get isEdit(): string {
        return this.selectedUser && this.selectedUser.id;
    }

    get model(): any {
        const user = this.form.getRawValue();
        if (user.userType !== UserType.FundAdmin && +user.userType !== UserType.FundAdmin) {
            user.agencyId = this.agency?.id;
            user.companyId = null;

            if (_.isObject(user.agencyId)) {
                user.agencyId = (user.agencyId as any).id;
            }
        } else {
            user.companyId = this.company?.id;
            user.agencyId = null;

            if (_.isObject(user.companyId)) {
                user.companyId = (user.companyId as any).id;
            }
        }

        return user;
    }

    public form = this.fb.group({
        id: [null],
        emailAddress: ['', Validators.compose([Validators.email, Validators.required])],
        username: [''],
        firstName: ['', Validators.required],
        lastName: ['', Validators.required],
        phone: ['', Validators.required],
        phoneExt: [''],
        userType: this.fb.control<UserType | string>('', Validators.required),
        status: [''],
        agencyId: ['All Agencies'],
        companyId: [null],
        emailOptOut: [false],
        receiveEfnEmails: [false]
    });

    userTypes = extractEnumNames(UserType);
    filteredUserTypes: any = [];

    loggedInUserType: string;
    loggedInUserType$: Observable<string>;

    @HostBinding('class.row')
    hostCSS = true;

    currentUser: User;
    currentUser$: Observable<User>;

    company: Company;
    agency: Agency;
    allAgencies: Agency[];
    allAgencies$: Observable<Agency[]>;

    selectedUser: User;
    selectedUser$: Observable<User>;

    @ViewChild(ChangePasswordModalComponent)
    changePasswordModal: ChangePasswordModalComponent;

    @ViewChild(AgencyListModalComponent)
    agencyListModal: AgencyListModalComponent;

    @ViewChild(CompanyListModalComponent)
    companyListModal: CompanyListModalComponent;
    @Output('onShowCompanyModal') _showCompany = new EventEmitter();

    phoneMask = Masking.phoneMask;

    public emailAddress = this.form.get('emailAddress') as FormControl;
    public username = this.form.get('username') as FormControl;
    public firstName = this.form.get('firstName') as FormControl;
    public lastName = this.form.get('lastName') as FormControl;
    public phone = this.form.get('phone') as FormControl;
    public phoneExt = this.form.get('phoneExt') as FormControl;
    public userType = this.form.get('userType') as FormControl;
    public status = this.form.get('status') as FormControl;
    public agencyId = this.form.get('agencyId') as FormControl;
    public companyId = this.form.get('companyId') as FormControl;
    public emailOptOut = this.form.get('emailOptOut') as FormControl;
    public receiveEfnEmails = this.form.get('receiveEfnEmails') as FormControl;

    // current page id
    paramId: string;

    emailTaken: boolean;
    emailErrorMessage = '';
    emailCheckComplete = true;

    userTypeErrorMessage = '';

    protected readonly UserStatusType = UserStatusType;

    public agencyIsRequired = false;


    ngOnInit(): void {
        this.currentUser$
            .pipe(takeUntil(this.$destroyed))
            .subscribe(user => this.currentUser = user);
        this.loggedInUserType$
            .pipe(takeUntil(this.$destroyed))
            .subscribe(loggedInUserType => this.loggedInUserType = loggedInUserType);
        this.allAgencies$
            .pipe(takeUntil(this.$destroyed))
            .subscribe(allAgencies => this.allAgencies = allAgencies);
        this.selectedUser$
            .pipe(takeUntil(this.$destroyed))
            .subscribe(selectedUser => this.selectedUser = selectedUser);

        this._route.params.subscribe((params: Params) => {
            const id = params.id;
            this.paramId = id;

            if (id === this.currentUser.id) { // is self edit
                this.filteredUserTypes = this.userTypes.filter((u) => u.value === this.currentUser.userType);
                this.userType.disable(); // users can't  change their own userType status
            } else if (this.currentUser.userType === UserType.SystemAdmin) {
                this.filteredUserTypes = this.userTypes; // system admin can see all user types
            } else {
                this.filteredUserTypes = this.userTypes.filter((u) => u.value === UserType.User || u.value === UserType.Viewer);
            }

            if (!this.isSystemAdmin()) {
                this.agencyId.disable();
            }
            // load selected user
            if (id === 'add') {
                if (!this.isSystemAdmin()) {
                    this.agency = this.currentUser.agency;
                    this.agencyId.setValue(this.currentUser.agency.agencyName);
                }
                this._userService.setCurrentSelectedUser(new User());
            } else if (!this.selectedUser || this.selectedUser.id !== id) {
                this._userService.getUserById(id);
            }
        });
        this.selectedUser$
            .modelExists()
            .subscribe(user => {
                const fields = Object.assign({}, user, {
                    agencyId: user.agency?.agencyName,
                    companyId: user.company?.name
                });
                this.agency = user.agency;

                const userStatus = (UserStatusType[user.status]).valueOf().toString() === UserStatusType.Disabled.toString();

                this.form.patchValue(fields as any);
                this.status.patchValue(userStatus);
            });
    }

    mutuallyExclusiveValidator(group: AbstractControl): { [key: string]: any } | null {
        const agencyId = group.get('agencyId');
        const companyId = group.get('companyId');

        if (agencyId.value !== null && companyId.value !== null) {
            return {mutuallyExclusive: true};
        }

        return null;
    }

    isEmailTaken(): boolean {
        let checkId = '';
        this.emailCheckComplete = false;
        if (this.selectedUser && this.selectedUser.id) {
            checkId = this.selectedUser.id;
        }

        if (this.selectedUser && this.selectedUser.emailAddress === this.emailAddress.value) {
            this.emailTaken = false;
            this.emailErrorMessage = '';
            this.emailCheckComplete = true;
            return this.emailTaken;
        }
        this._userService.isEmailTaken(this.emailAddress.value, checkId)
            .subscribe((check) => {
                this.emailTaken = check.result;
                if (this.emailTaken) {
                    this.emailErrorMessage = 'This Email Address has been registered by another User already.';
                } else {
                    this.emailErrorMessage = '';
                }
                this.emailCheckComplete = true;

            });
        return this.emailTaken;
    }

    isSystemAdmin(): boolean {
        return this.currentUser.userType === UserType.SystemAdmin;
    }

    isSelectedUserFundAdmin(): boolean {
        return this.userTypeValue === UserType.FundAdmin;
    }


    onUserTypeChange(event): void {
        if (event === UserType.FundAdmin || +event === UserType.FundAdmin) {
            this.agencyId.setValue(null);
            this.agency = null;
        } else {
            this.companyId.setValue(null);
        }

        if (event === UserType.RegionAdmin || +event === UserType.RegionAdmin) {
            this.agencyIsRequired = true;
            this.agencyId.setValidators([Validators.required]);
            this.agencyId.setValue(null);
            this.agency = null;
        } else {
            this.agencyIsRequired = false;
            this.agencyId.clearValidators();
            this.agencyId.setValue('All Agencies');
        }
        this.agencyId.updateValueAndValidity();
    }

    submit(): Observable<User> {
        this.form.markAllAsTouched();
        if (!this.form.valid) {
            this.form.markAllAsTouched();
            return observableEmpty;
        }

        if (!this.isSelectedUserFundAdmin()) {
            this.model.companyId = null;
            this.model.agencyId = this.agency ? this.agencyId : null;
        } else {
            this.model.companyId = this.company ? this.company.id : null;
            this.model.agencyId = null;
        }

        const status = (this.status.value ? UserStatusType.Disabled : UserStatusType.Active);
        const updatedModel = {...this.model, status};

        if (this.isEdit) {
            return this._userService.updateUser(updatedModel)
                .pipe(
                    tap(() => this._router.navigate(['/app/users']))
                );
        } else {
            return this._userService.addUser(updatedModel)
                .pipe(
                    tap(() => this._router.navigate(['/app/users']))
                );
        }
    }

    resetPassword(): Observable<GenericApiResponse> {
        if (!this.selectedUser.emailAddress) {
            this._notificationService.showError('User must have an email set to reset password.');
            return observableEmpty;
        }
        return this._securityService.sendPasswordResetEmail(this.selectedUser.emailAddress).pipe(
            tap(() => this._notificationService.showSuccess('Email password reset sent.')),
            catchError(() => {
                this._notificationService.showError('Failed resetting user\'s password.');
                return observableEmpty;
            }));
    }

    onAgencyChange(agency: Agency): void {
        if (agency) {
            this.agency = agency;
            this.agencyId.setValue(agency.agencyName);
        } else {
            this.agency = null;
            this.agencyId.setValue('All Agencies');
        }
    }

    onCompanySelected(event: CompanySelectedEvent): void {
        if (event?.company) {
            this.company = event.company;
            this.companyId.setValue(event.company.name);
        } else {
            this.company = null;
            this.companyId.setValue(null);
        }
    }

    ngOnDestroy(): void {
        this._userService.setSelectedUserAction(null);
    }
}
