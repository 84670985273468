import {Injectable} from '@angular/core';
import {PledgeStatusType} from './api.client';
import * as moment from 'moment';
import Moment = moment.Moment;
import {PledgeDetailReport} from './api.client';
import {Observable} from 'rxjs';
import {PledgeZipcodeReport} from './api.client';
import {PledgeCountyReport} from './api.client';
import {Store} from '@ngrx/store';
import * as fromRoot from '../store';
import {
    reportRetrievePledgeDetailReportFailed,
    reportRetrievePledgeDetailReportSuccess,
    retrievePledgeCountyReportFailed,
    retrievePledgeCountyReportSuccess,
    retrievePledgeZipcodeReportFailed,
    retrievePledgeZipcodeReportSuccess
} from '../store/actions/report.actions';

@Injectable()
export class ReportService {
  _reportApiService: any;

  constructor(private _store: Store<fromRoot.AppState>) {
  }

  retrievePledgeDetailReport({startDate, endDate, pledgeTypeId, pledgeStatus, agencyId}:
                               {
                                 startDate: Moment,
                                 endDate: Moment,
                                 pledgeTypeId: string,
                                 pledgeStatus: PledgeStatusType,
                                 agencyId: string
                               }): Observable<PledgeDetailReport[]> {
    const request = this._reportApiService.retrievePledgeDetailReport(startDate, endDate, pledgeTypeId, pledgeStatus, agencyId).share();
    request.subscribe((data) => {
      this.retrievePledgeDetailReportSuccessAction(data);
    }, (error) => {
      this.retrievePledgeDetailReportFailedAction(error);
    });
    return request;
  }

  retrievePledgeCountyReport({startDate, endDate, pledgeTypeId, pledgeStatus, companyId, agencyId}:
                               {
                                 startDate: Moment,
                                 endDate: Moment,
                                 pledgeTypeId: string,
                                 pledgeStatus: PledgeStatusType,
                                 agencyId: string,
                                 companyId: string
                               }): Observable<PledgeCountyReport[]> {
    const request = this._reportApiService.retrievePledgeCountyReport(
        startDate,
        endDate,
        pledgeTypeId,
        pledgeStatus,
        companyId,
        agencyId)
        .share();
    request.subscribe((data) => {
      this.retrievePledgeCountyReportSuccessAction(data);
    }, (error) => {
      this.retrievePledgeCountyReportFailedAction(error);
    });
    return request;
  }


  retrievePledgeZipcodeReport({startDate, endDate, pledgeTypeId, pledgeStatus, companyId, agencyId}:
                                {
                                  startDate: Moment,
                                  endDate: Moment,
                                  pledgeTypeId: string,
                                  pledgeStatus: PledgeStatusType,
                                  agencyId: string,
                                  companyId: string
                                }): Observable<PledgeZipcodeReport[]> {
    const request = this._reportApiService.retrievePledgeZipcodeReport(
        startDate,
        endDate,
        pledgeTypeId,
        pledgeStatus,
        companyId,
        agencyId)
        .share();
    request.subscribe((data) => {
      this.retrievePledgeZipcodeReportSuccessAction(data);
    }, (error) => {
      this.retrievePledgeZipcodeReportFailedAction(error);
    });
    return request;
  }

  // ---------------security actions-------------
  // PledgeDetailReport
  private retrievePledgeDetailReportSuccessAction(data: PledgeDetailReport[]): void {
    this._store.dispatch(reportRetrievePledgeDetailReportSuccess({ payload: data}));
  }

  private retrievePledgeDetailReportFailedAction(error: any): void {
    this._store.dispatch(reportRetrievePledgeDetailReportFailed({ payload: error}));
  }

  // PledgeZipcodeReport
  private retrievePledgeZipcodeReportSuccessAction(data: PledgeZipcodeReport[]): void {
    this._store.dispatch(retrievePledgeZipcodeReportSuccess({ payload: data}));
  }

  private retrievePledgeZipcodeReportFailedAction(error: any): void {
    this._store.dispatch(retrievePledgeZipcodeReportFailed({ payload: error}));
  }

  // PledgeCountyReport
  private retrievePledgeCountyReportSuccessAction(data: PledgeCountyReport[]): void {
    this._store.dispatch(retrievePledgeCountyReportSuccess({ payload: data}));
  }

  private retrievePledgeCountyReportFailedAction(error: any): void {
    this._store.dispatch(retrievePledgeCountyReportFailed({ payload: error}));
  }
}
