<ng-container *ngIf="pagedData">
    <table-dynamic
            (limitChange)="onLimitChange($event)"
            (pageChanged)="onPageChange($event)"
            [currentPage]="pagedData.pageIndex"
            [headers]="headers"
            [totalCount]="pagedData.totalCount"
            class="table table-striped table-hover"
            id="datatable-table">
        <tr class="cursor-pointer" (click)="onRowClick(row.clientId, row.assistanceApplicationId)" *ngFor="let row of pagedData.items">
            <td>{{row.enteredDate | date: 'MM/dd/yyyy'}}</td>
            <td>{{row.clientFirstName}} {{row.clientLastName}}</td>
            <td>{{getAuditStateLabel(row.auditState)}}</td>
            <td>{{row.auditComment}}</td>
        </tr>
    </table-dynamic>
</ng-container>
<ng-container *ngIf="!pagedData || pagedData.items.length === 0">
    <div class="container-fluid">
        <div class="row">
            <div class="col">
                <div class="alert alert-danger w-100 text-center" role="alert">
                    <strong>No data found</strong>
                </div>
            </div>
        </div>

    </div>
</ng-container>
