<div class="container">
  <h1 class="page-title">Agency Overview</h1>
  <!--section-->
  <section class="widget">
    <div class="widget-body">
      <div class="mt">
        <!--form-->
        <form class="form-horizontal" [formGroup]="form" novalidate>
          <!--Agency-->
          <div class="row form-group">
            <label class="col-md-2"><strong>Agency:</strong></label>
            <div class="col-md-10">
              <!--Name-->
              <div class="row form-group" [class.has-danger]="!agencyName.valid && agencyName.touched">
                <span class="m-t-05 required-label col-md-2">Name</span>
                <input [formControl]="agencyName" class="form-control col-md-9" type="text"
                       formControlName="agencyName"
                       placeholder="Agency Name">
              </div>
              <!--Status-->
              <div class="row form-group" [class.has-danger]="!status.valid && status.touched">
                <span class="m-t-05 required-label col-md-2">Status</span>
                <select [formControl]="status" required class="form-control col-md-9 custom-select"
                        type="text"
                        formControlName="status">
                  <option value="null">Choose a Status</option>
                  <option *ngFor="let enumValue of statuses" [value]="enumValue.key">{{enumValue.key}}</option>
                </select>
              </div>
              <!--CssCode-->
              <div class="row form-group" [class.has-danger]="!amerenCssCode.valid && amerenCssCode.touched">
                <span class="m-t-05 col-md-2">Ameren CSS</span>
                <input [formControl]="amerenCssCode"
                       class="form-control col-md-9"
                       type="text"
                       formControlName="amerenCssCode"
                       placeholder="Ameren CSS">
              </div>
              <!--is efn-->
              <div class="row form-group" [class.has-danger]="!isEfnAgency.valid && isEfnAgency.touched">
                <span class="m-t-05 col-md-2">Is EFN Agency</span>
                <input [formControl]="isEfnAgency"
                       type="checkbox"
                       class="form-control col-md-9 custom-checkbox"
                       formControlName="isEfnAgency"
                       id="isEfnAgency">
              </div>
              <div class="row form-group" *ngIf="isEfnAgency.value">
                <span class="m-t-05 col-md-2">EFN Zones</span>
                <div class="col-md-9 no-padding">
                  <efnZoneCheckbox [control]="efnZones"></efnZoneCheckbox>
                </div>
              </div>
            </div>
          </div>
          <!--PledgeTypes-->
          <div class="row form-group">
            <label class="col-md-2"><strong>Fund Types:</strong></label>
            <div class="col-md-10">
              <!--check-box-->
              <div class="row" [class.has-danger]="!pledgeTypeIds.valid && pledgeTypeIds.touched">
                <span class="m-t-05 col-md-2"></span>
                <div class="col-md-9 no-padding">
                  <pledgeTypeCheckbox [control]="pledgeTypeIds" [showDisabled]="true"></pledgeTypeCheckbox>
                </div>
              </div>
            </div>
          </div>
          <!--submit-->
          <div class="row form-group">
            <div class="col-md-12">
              <div class="col-md-2">
              </div>
              <div class="col-md-9 btn-form pull-right">
                <cancel-button class="p-l-1 pull-right" [route]="'/app/agencies'"></cancel-button>

                <!--save-->
                <button [disable]="isValidSave()"
                        [autoDisable]="submit"
                        type="submit"
                        class="btn btn-primary">Save Agency
                </button>
                <!--delete-->
                <button type="button"
                        class="btn btn-inverse"
                        (click)="showPromptModal()">Delete Agency
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
    <prompt-dialog
      [titleText]="'Delete Agency?'"
      [promptText]="'Are you sure you want to delete agency?'">
    </prompt-dialog>
  </section>
</div>
