export enum EmploymentStatus {
    UnemployedWithBenefits = 'Unemployed With Benefits',
    UnemployedNoBenefits = 'Unemployed No Benefits',
    PartTimeEmployment = 'Part Time Employment',
    FullTimeEmployment = 'Full Time Employment',
    SelfEmployed = 'Self-Employed',
    Retired = 'Retired',
    Student = 'Student',
    ActiveMilitary = 'Active Military',
    Employed = 'Employed'
}
