import {ICountySpendingReportData} from '../ICountySpendingReportData';
import {createReducer, on} from '@ngrx/store';
import {setCountySpendingReportIsLoaded, setCountySpendingReportIsLoading} from '../actions/countySpendingReport.actions';


export const initialState: ICountySpendingReportData = {
    isLoading: false,
    data: null
};

export const countySpendingReportReducer = createReducer(
    initialState,
    on(setCountySpendingReportIsLoading, (state) => ({
        ...state,
        isLoading: true
    })),
    on(setCountySpendingReportIsLoaded, (state, { payload }) => ({
        ...state,
        isLoading: false,
        data: payload
    })),
);
