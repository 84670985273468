<form>
  <groupLayout>
    Client:
    <fieldLayout>
      <label>Name:</label>
      <div>{{ client.firstName }} {{client.middleInitial}} {{ client.lastName }}</div>
    </fieldLayout>

    <fieldLayout>
      <label>SSN:</label>
      <div>{{ client.last4Ssn ? '***-**-' + client.last4Ssn : 'N/A' }}</div>
    </fieldLayout>

    <fieldLayout>
      <label>Address:</label>
      <address>
        {{client.address1}} {{client.address2}} <br>
        {{client.city}}, {{client.state}} {{client.zipCode}} <br>
        {{client.county}}
      </address>
    </fieldLayout>

    <fieldLayout>
      <label>Household Size:</label>
      <div>{{client.houseHoldSize ?? 'N/A'}}</div>
    </fieldLayout>

    <fieldLayout>
      <label>Household Monthly Income:</label>
      <span *ngIf="client?.houseHoldIncome">{{client.houseHoldIncome | parseNumber | currency:'USD':'symbol':'1.2-2'}}</span>
      <span *ngIf="!client?.houseHoldIncome" class="text-danger">N/A</span>
    </fieldLayout>

    <fieldLayout>
      <label>Individual Monthly Income:</label>
      <span *ngIf="client?.individualMonthlyIncome">{{client.individualMonthlyIncome | parseNumber | currency:'USD':'symbol':'1.2-2'}}</span>
      <span *ngIf="!client?.individualMonthlyIncome" class="text-danger">N/A</span>
    </fieldLayout>

  </groupLayout>

  <groupLayout>
    Originator:
    <fieldLayout>
      <label>Created By:</label>
      <div>{{ user?.agency.agencyName }}</div>
    </fieldLayout>

    <fieldLayout>
      <label>Case Manager:</label>
      <div>{{ user?.firstName }} {{ user?.lastName }}</div>
    </fieldLayout>

    <fieldLayout>
      <label>Phone:</label>
      <div>{{ user?.phone }}</div>
    </fieldLayout>

    <fieldLayout>
      <label>Email:</label>
      <div>{{ user?.emailAddress }}</div>
    </fieldLayout>
  </groupLayout>

  <groupLayout>
    Case:
    <fieldLayout>
      <label>Total Amount Needed:</label>
      <currencyInput [control]="totalNeed"></currencyInput>
    </fieldLayout>
    <fieldLayout>
      <label>Current Total:</label>
      <div>${{ currentTotal.value }}</div>
    </fieldLayout>

    <fieldLayout>
      <label>Total Needed:</label>
      <div>${{ totalNeed.value ? totalNeed.value - currentTotal.value : 0}}</div>
    </fieldLayout>

    <fieldLayout [class.has-danger]="summary.touched && !summary.valid">
      <label>Summary:</label>
      <div *ngIf="summary.touched && summary.hasError('maxLength')" class="text-danger">
        {{summary.getError('maxLength')}}
      </div>
      <input type="text" class="form-control" [formControl]="summary">
      <p>Note: Incl. type of assistance & demographic info if desired (i.e. “Gas for elderly client”)</p>
    </fieldLayout>

    <fieldLayout [class.has-danger]="(!description.valid && description.touched)">
      <label>Circumstances:</label>
      <textarea class="form-control" cols="30" rows="5" [formControl]="description"></textarea>
      <p>Note: If needed, drag lower right corner to expand box to show all text</p>
    </fieldLayout>

    <fieldLayout>
      <label>Deadline:</label>
      <datePicker [minDate]="date" [control]="deadline" [controlFormat]="'L'"></datePicker>
    </fieldLayout>

    <fieldLayout>
      <div class="custom-control-container">
        <label class="custom-control custom-radio">
          <input type="radio" class="custom-control-input" [value]="false" [formControl]="usesOneTimeCompany">
          <span class="custom-control-indicator"></span>
          <span class="custom-control-description">Company</span>
        </label>
        <label class="custom-control custom-radio">
          <input type="radio" class="custom-control-input" [value]="true" [formControl]="usesOneTimeCompany"
                 [checked]="usesOneTimeCompany.value">
          <span class="custom-control-indicator"></span>
          <span class="custom-control-description">One-Time Payee</span>
        </label>
      </div>
    </fieldLayout>

    <fieldLayout *ngIf="form.hasError('mutuallyExclusive') || form.hasError('exclusiveRequire')">
            <span class="text-danger">
                {{form.getError('mutuallyExclusive')}}
            </span>
      <span class="text-danger">
                {{form.getError('exclusiveRequire')}}
            </span>
    </fieldLayout>

    <fieldLayout *ngIf="!usesOneTimeCompany.value"
                 [class.has-danger]="!companyName.valid && companyName.touched">
      <label>Company:</label>
      <input class="form-control"
             type="text"
             [formControl]="companyName"
             placeholder="Select a Company"
             (click)="showCompanyModal()"
             (keydown)="showCompanyModal()"/>
    </fieldLayout>

    <fieldLayout *ngIf="usesOneTimeCompany.value"
                 [class.has-danger]="!paymentTo.valid && paymentTo.touched">
      <label>Payment To:</label>
      <input type="text" class="form-control" [formControl]="paymentTo">
    </fieldLayout>

    <fieldLayout *ngIf="usesOneTimeCompany.value"
                 [class.has-danger]="!payeeAddress.valid && payeeAddress.touched">
      <label>Payee Address:</label>
      <input type="text" class="form-control" [formControl]="payeeAddress">
    </fieldLayout>

    <fieldLayout *ngIf="usesOneTimeCompany.value"
                 [class.has-danger]="!payeeAddress2.valid && payeeAddress2.touched">
      <label>Payee Address2:</label>
      <input type="text" class="form-control" [formControl]="payeeAddress2">
    </fieldLayout>

    <fieldLayout *ngIf="usesOneTimeCompany.value"
                 [class.has-danger]="!payeeCity.valid && payeeCity.touched">
      <label>Payee City:</label>
      <input type="text" class="form-control" [formControl]="payeeCity">
    </fieldLayout>

    <fieldLayout *ngIf="usesOneTimeCompany.value"
                 [class.has-danger]="!payeeState.valid && payeeState.touched">
      <label>Payee State:</label>
      <select [formControl]="payeeState" class="form-control custom-select"
              type="text"
              placeholder="State">
        <option value="null">Choose a State</option>
        <option *ngFor="let state of states" [value]="state.key">{{state.lookupValue}}
        </option>
      </select>
    </fieldLayout>

    <fieldLayout *ngIf="usesOneTimeCompany.value"
                 [class.has-danger]="!payeeZip.valid && payeeZip.touched">
      <label>Payee Zip:</label>
      <input type="text" class="form-control" [imask]="zipCodeMask" [formControl]="payeeZip">
    </fieldLayout>

    <fieldLayout [class.has-danger]="(!accountNumber.valid && accountNumber.touched)">
      <label>Account # or Payment Memo Information:</label>
      <div *ngIf="accountNumber.touched && accountNumber.hasError('companyAccountNumber')" class="text-danger">
        {{accountNumber.getError('companyAccountNumber')}}
      </div>
      <input *ngIf="accountNumber.disabled" type="text" class="form-control" readonly="readonly" [value]="accountNumber.value">
      <input *ngIf="!accountNumber.disabled" type="text" class="form-control" [formControl]="accountNumber">
    </fieldLayout>

    <fieldLayout>
      <label>Status:</label>
      <efnCaseStatus [control]="status"></efnCaseStatus>
    </fieldLayout>

    <fieldLayout>

    </fieldLayout>

  </groupLayout>
  <ng-content></ng-content>
  <company-box (onCompanySelected)="onCompanySelected($event)"></company-box>
</form>
<prompt-dialog
        [titleText]="'Client County Inactive'"
        promptText="{{clientCountyInactiveMessage}}">
</prompt-dialog>
