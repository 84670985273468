import {Component} from '@angular/core';
import {extractEnumNames} from '../../../utilities/Util';
import {PledgeStatusType} from '../../../services/api.client';


@Component({
  selector: '[pledgeStatus]',
  templateUrl: './PledgeStatusDropdown.html'
})
export class PlegeStatusTypeDropdown {
  pledgeStatus = extractEnumNames(PledgeStatusType)
    .filter((type) => type.value !== PledgeStatusType.New);
}
