<div class="container">
  <h1 class="page-title">Edit Case</h1>
  <section class="widget">
    <div class="widget-body">
      <div class="mt">
        <efnCaseForm [client]="efnCase.client" [user]="efnCase.user">
          <button *ngIf="helper.canEdit()" class="hidden-print btn btn-primary save-case" [autoDisable]="submit">Save
            Case
          </button>
          <br *ngIf="helper.canEdit()">
          &nbsp;<hr *ngIf="helper.canEdit()">
          <br>
          <efnCommit *ngIf="helper.canCommit()" [efnCase]="efnCase" (commitSuccess)="tabs.reload()"></efnCommit>
          <hr *ngIf="helper.canCommit()">
          <br *ngIf="helper.canCommit()">
          <button class="hidden-print btn btn-primary print-case" (click)="printCase()">Print Case</button>
          <efnTabs class="hidden-print" [efnCase]="efnCase" #tabs></efnTabs>
        </efnCaseForm>
      </div>
    </div>
  </section>
</div>
