import {createAction, props} from '@ngrx/store';
import {Allocation, PagedListOfAllocationListItem} from '../../services/api.client';
import {PagedList} from '../../models/PagedList';

export const setCurrentSelectedAllocation = createAction(
    '[Allocation] SET_CURRENT_SELECTEDALLOCATION',
    props<{ payload: Allocation }>());
export const allocationsDataIsLoaded = createAction(
    '[Allocation] ALLOCATIONSDATA_ISLOADED',
    props<{ payload: PagedListOfAllocationListItem }>());
export const allocationsDataIsLoading = createAction('[Allocation] ALLOCATIONSDATA_ISLOADING');
export const allocationsDataSoftDelete = createAction('[Allocation] ALLOCATIONS_DATA_SOFT_DELETE');
