<div class="container" *ngIf="currentClient">

  <div class="row flex-md-items-between">
    <label class="col-xs"><strong>EFN Case History For:</strong>
      {{currentClient.prefix}} {{currentClient.firstName}} {{currentClient.middleInitial}} {{currentClient.lastName}} {{currentClient.suffix}}
    </label>
    <div class="col-xs-3">
      <a [routerLink]="['./add']" class="btn btn-primary mb-xs new-button" [class.disabled]="!isClientValid()">New EFN
        Case</a><br>
      <span *ngIf="invalidClientMessage && !isClientValid()" class="text-danger">{{ invalidClientMessage }}</span>
    </div>
  </div>
  <div class="row">
    <table class="table table-striped table-hover">
      <thead>
      <th class="text-xs-center">Summary</th>
      <th class="text-xs-center">Total Need</th>
      <th class="text-xs-center">Amount Committed</th>
      <th class="text-xs-center">Agency</th>
      <th class="text-xs-center">Case Manager</th>
      <th class="text-xs-center">Case Status</th>
      <th class="text-xs-center">Entry Date</th>
      <th class="text-xs-center">Funded Date</th>
      </thead>
      <tbody>
      <tr *ngFor="let case of clientsEfnCases">
        <td class="text-xs-center"><a class="clickable text-primary" [routerLink]="['./', case.id]">{{case.summary}}</a>
        </td>
        <td class="text-xs-center">{{case.totalNeed | parseNumber | currency:'USD':'symbol':'1.2-2'}}</td>
        <td class="text-xs-center">{{case.currentTotal | parseNumber | currency:'USD':'symbol':'1.2-2'}}</td>
        <td class="text-xs-center">{{case.createdByAgencyName}}</td>
        <td class="text-xs-center">{{case.caseWorker}}</td>
        <td class="text-xs-center">{{getCaseStatus(case.status)}}</td>
        <td class="text-xs-center">{{case.enteredDate | moment: 'L'}}</td>
        <td class="text-xs-center">{{case.fundedDate | moment: 'L'}}</td>
      </tr>
      </tbody>
    </table>
  </div>

</div>
