import {Component, Input} from '@angular/core';
import {UntypedFormControl} from '@angular/forms';

@Component({
  selector: 'currencyInput',
  templateUrl: './CurrencyInputComponent.html'
})
export class CurrencyInputComponent {
  @Input()
  control = new UntypedFormControl();
  @Input()
  allowDecimal = true;
  @Input()
  showDecimal: boolean;
}
