import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {ITableHeader, TableComponent} from '../../../shared/table/TableComponent';
import {FormBuilder, FormControl, UntypedFormGroup} from '@angular/forms';
import {Observable} from 'rxjs';
import {ApplicationCounty, PagedListOfApplicationCounty} from '../../../../services/api.client';
import {Store} from '@ngrx/store';
import * as fromRoot from '../../../../store';
import {
  selectApplicationCountyPagedApplicationCounties,
  selectApplicationCountyPagedApplicationTotalCount
} from '../../../../store/selectors/applicationCounty.selectors';
import {Disposable} from '../../../../utilities/Disposable';
import {takeUntil} from 'rxjs/operators';
import {ApplicationCountyService} from '../../../../services/applicationCounty.service';
import {Router} from '@angular/router';
import {setApplicationCountyCurrentSelectedApplicationCounty} from '../../../../store/actions/applicationCounty.actions';

@Component({
  selector: 'app-application-county-list',
  templateUrl: './application-county-list.component.html',
  styleUrls: ['./application-county-list.component.scss']
})
export class ApplicationCountyListComponent extends Disposable implements OnInit, AfterViewInit {

  @ViewChild(TableComponent) table: TableComponent;

  get nameControl(): FormControl {
    return this.form.get('name') as FormControl;
  }

  constructor(
      private _formBuilder: FormBuilder,
      private _store: Store<fromRoot.AppState>,
      private _applicationCountyService: ApplicationCountyService,
      private _router: Router
  ) {
    super();
    this.pagedListOfApplicationCounties$ = this._store.select(selectApplicationCountyPagedApplicationCounties);
    this.totalCount$ = this._store.select(selectApplicationCountyPagedApplicationTotalCount);
  }

  form: UntypedFormGroup;

  tableHeaders: ITableHeader[] = [
    {text: 'County', sortKey: 'Name'},
    {text: 'Internal Application', sortKey: 'IsActiveInternal'},
    {text: 'Public Application', sortKey: 'IsActiveExternal'},
    {text: 'Fund Organization', sortKey: 'FundAdministrativePledgeTypeId'},
  ];

  // sorting
  currentSortName = 'Name';
  currentSorting = false;

  // pagination
  limit = 10;
  currentPage = 1;
  maxSize = 1;
  totalCount: number;
  totalCount$: Observable<number>;

  pagedListOfApplicationCounties: PagedListOfApplicationCounty;
  pagedListOfApplicationCounties$: Observable<PagedListOfApplicationCounty>;

  ngOnInit(): void {
    this.initForm();

    this.pagedListOfApplicationCounties$
        .pipe(takeUntil(this.$destroyed))
        .subscribe(pagedListOfApplicationCounties => this.pagedListOfApplicationCounties = pagedListOfApplicationCounties);

    this.totalCount$
        .pipe(takeUntil(this.$destroyed))
        .subscribe(totalCount => this.totalCount = totalCount);
  }

  ngAfterViewInit(): void {
    this.filterCounties();
  }

  initForm(): void {
    this.form = this._formBuilder.group({
      name: new FormControl<string>('')
    });
  }

  sortCounties(options: ITableHeader): void {
    this.currentSortName = options.sortKey;
    this.currentSorting = !options.ascending;
    this.filterCounties();
  }

  setLimit(limit: number): void {// # of rows per page
    this.limit = limit;
    this.filterCounties();
  }

  setPage(page: number): void {
    setTimeout(() => {
      this.currentPage = page; // set current page
      this.filterCounties();
    });
  }

  filterCounties(): void {
    this.table.request = this._applicationCountyService.searchCounties(
        this.nameControl.value,
        this.currentSortName,
        this.currentSorting,
        this.limit,
        (this.currentPage - 1) * this.limit
    );
  }

  showActiveText(isActive: boolean): string {
    return isActive ? 'Enabled' : 'Disabled';
  }

  setCurrentSelectedApplicationCounty(county: ApplicationCounty): void {
    this._store.dispatch(setApplicationCountyCurrentSelectedApplicationCounty({ payload: county}));
  }
}
