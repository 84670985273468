<label class="form-check-label pb-3" *ngIf="showSelectAll">
    <input [disabled]="togglingSelectAll" checkboxArray type="checkbox" class="form-check-input" checked="showSelectAll"
           (click)="toggleSelectAll()"
    >
    Select All
</label>
<div class="form-check" *ngFor="let item of filteredPledgeTypes$ | async">
    <label class="form-check-label">
        <input checkboxArray [ngValue]="item.id" [formControl]="control" type="checkbox" class="form-check-input"
               (click)="onClick(item.id)">
        {{ item.name || item.displayName }}
    </label>
</div>
