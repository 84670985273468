export function getUtilityLabelFromAssistanceApplicationAreasOfNeed(areasOfNeed: any): string {
    const selectedOptions = Object.entries(areasOfNeed)
        .filter(([key, val]) => val)
        .map(([key]) => {
            switch (key) {
                case 'electric':
                    return 'Electric';
                case 'gasHeat':
                    return 'Gas/Heat';
                case 'sewer':
                    return 'Sewer';
                case 'water':
                    return 'Water';
                default:
                    return '';
            }
        })
        .filter(label => label !== '')
        .join('/');

    return selectedOptions ? `Utility Bill(s) (${selectedOptions})` : 'Utility Bill(s)';
}
