import {Component, OnInit} from '@angular/core';
import {AssistanceApplication, Client, User, UserType} from '../../../../services/api.client';
import {splitCamelCase} from '../../../../utilities/Util';
import {Observable} from 'rxjs';
import {select, Store} from '@ngrx/store';
import * as fromRoot from '../../../../store';
import {selectClientDataCurrentClient} from '../../../../store/selectors/client.selectors';
import {takeUntil} from 'rxjs/operators';
import {Disposable} from '../../../../utilities/Disposable';
import {AssistanceApplicationService} from '../../../public/application/assistance-application.service';
import {ActivatedRoute, Router} from '@angular/router';
import {AssistanceApplicationStatus} from '../../../../shared/enums/assistance-application-status';
import {NotificationService} from '../../../../services/NotificationService';
import {selectSecurityStateDataCurrentUser} from '../../../../store/selectors/security.selectors';

@Component({
    templateUrl: './ClientAssistanceApplicationComponent.html',
    styleUrls: ['./ClientAssistanceApplicationComponent.scss']
})

export class ClientAssistanceApplicationComponent extends Disposable implements OnInit {
    currentClient: Client;
    currentClient$: Observable<Client>;

    currentUser: User;
    currentUser$: Observable<User>;

    sortField = '';
    sortDirection = 'asc';

    clientAssistanceApplications: AssistanceApplication[];
    isClientCountyActive = true;
    invalidClientMessage: string;

    constructor(private assistanceApplicationService: AssistanceApplicationService,
                private _store: Store<fromRoot.AppState>,
                private router: Router, private route: ActivatedRoute,
                private _notificationService: NotificationService) {
        super();
        this.currentClient$ = this._store.pipe(select(selectClientDataCurrentClient));
        this.currentUser$ = this._store.select(selectSecurityStateDataCurrentUser);
    }

    ngOnInit(): void {
        this.currentClient$
            .pipe(takeUntil(this.$destroyed))
            .subscribe(currentClient => this.currentClient = currentClient);
        this.currentUser$
            .pipe(takeUntil(this.$destroyed))
            .subscribe(currentUser => this.currentUser = currentUser);
        this.getClientAssistanceApplications(this.currentClient.id);
        this.assistanceApplicationService.checkIsCaseCountyActive(this.currentClient.county)
            .pipe(takeUntil(this.$destroyed))
            .subscribe((isActive: boolean) => this.isClientCountyActive = isActive || false);
    }

    isClientValid(): boolean {
        const isValid =
            !!this.currentClient?.birthYear ||
            !!this.currentClient?.gender ||
            !!this.currentClient?.employmentStatus ||
            !!this.currentClient?.houseHoldIncome ||
            !!this.currentClient?.houseHoldSize;

        if (!isValid) {
            this.invalidClientMessage = 'This Client Has Missing Info';
            return isValid;
        }

        if (!this.isClientCountyActive) {
            this.invalidClientMessage = `This Client's County is not accepting new applications.`;
        }

        return this.isClientCountyActive;
    }


    getCaseStatus(status: string | number): string {
        if (typeof status === 'number') {
            return splitCamelCase(AssistanceApplicationStatus[status], true);
        }
        return splitCamelCase(status, true);
    }

    public navToDetail(id: string): void {
        this.router.navigate(['./', id], {relativeTo: this.route}).then();
    }

    sortTable(field: string): void {
        if (this.sortField === field) {
            this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
        } else {
            this.sortField = field;
            this.sortDirection = 'asc';
        }
        this.getClientAssistanceApplications(this.currentClient.id);
    }

    getClientAssistanceApplications(clientId: string): void {
        this.assistanceApplicationService.getClientAssistanceApplications(clientId)
            .subscribe({
                next: (next) => {
                    this.clientAssistanceApplications = next.sort((a, b) => {
                        if (a[this.sortField] < b[this.sortField]) {
                            return this.sortDirection === 'asc' ? -1 : 1;
                        }
                        if (a[this.sortField] > b[this.sortField]) {
                            return this.sortDirection === 'asc' ? 1 : -1;
                        }
                        return 0;
                    });
                }
                ,
                error: () => {
                    this._notificationService.showError('Error Loading Assistance Applications');
                }
            });
    }

    hasAccess(): boolean {
        return this.currentUser.userType !== UserType.Viewer;
    }
}
