<h1 class="page-title">Browse Users</h1>
<section class="widget">
  <div class="widget-body">
    <div class="mt">
      <form novalidate>
        <div class="row">
          <div class="col-md-6 col-sm-6">
            <!--search string-->
            <label class="header-label">Search:</label>
            <input class="form-control"
                   type="text"
                   [value]="searchValue"
                   placeholder="Select an Agency"
                   (click)="showAgencyModal()"
                   (keydown)="showAgencyModal()"
                   [disabled]="!isSystemAdmin()"/>
          </div>
          <div class="col-md-3 col-sm-3">
            <!--User Type filter-->
            <label class="header-label">User Type:</label>
            <dropdown [promptValue]="'User Types'"
                      [values]="userTypeDropdown"
                      (select)="selectUserType($event)"></dropdown>
          </div>
          <div class="col-md-3 col-sm-3 align-checkbox">
            <div class="custom-control-container">
              <label class="custom-control custom-checkbox">
                <input class="custom-control-input"
                       [(ngModel)]="includeDisabled"
                       [ngModelOptions]="{standalone: true}"
                       type="checkbox">
                <span class="custom-control-indicator"></span>
                <span class="custom-control-description">Show Disabled</span>
              </label>
            </div>
          </div>
        </div>
        <div class="row form-group">
          <div class="col-md-3 col-sm-3">
            <button class="btn btn-primary width-100 mb-xs" type="submit" role="button"
                    (click)="searchUsers()">Search
            </button>
            <a class="btn btn-primary mb-xs" [href]="downloadUsers()">Download List</a>
          </div>
          <div class="col-md-9 col-sm-9">
            <button class="btn btn-primary width-100 mb-xs button-right" [routerLink]="['../user/add']">New
              User
            </button>
          </div>
        </div>
      </form>

      <table-dynamic id="datatable-table"
                     class="table table-striped table-hover"
                     [headers]="tableHeaders"
                     [currentPage]="currentPage"
                     [totalCount]="totalCount"
                     (headerClick)="sortUsers($event)"
                     (pageChanged)="setPage($event)"
                     (limitChange)="setLimit($event)">
        <tr *ngFor="let user of pagedUsers?.items">
          <td>
            <a [routerLink]="['/app/user', user.id]" (click)="setCurrentSelectedUser(user)"><strong>{{user.lastName
              }}</strong></a>
          </td>
          <td>
            <a [routerLink]="['/app/user', user.id]" (click)="setCurrentSelectedUser(user)"><strong>{{
              user.firstName }}</strong></a>
          </td>
          <td>{{ user.emailAddress }}</td>
          <td class="hidden-sm-down">{{ formatUserType(userTypeDisplay[user.userType]) }}</td>
          <td class="hidden-sm-down">{{ user.agency?.agencyName }}</td>
          <td class="hidden-sm-down">{{ user.company?.name }}</td>
          <td class="hidden-sm-down">{{ user.status }}</td>
        </tr>
      </table-dynamic>
    </div>
  </div>
  <agency-box (onAgencySelected)="onAgencySelected($event)"></agency-box>
</section>
