<div class="container">
    <h1 class="page-title">Fund Type Overview</h1>
    <!--section-->
    <section class="widget">
        <div class="widget-body">
            <div class="mt">
                <!--form-->
                <form class="form-horizontal" [formGroup]="form" novalidate>
                    <div class="row form-group">
                        <label class="col-md-3"><strong>Fund Type Info:</strong></label>
                        <div class="col-md-8">
                            <!--Name-->
                            <div class="row form-field-spacing" [class.has-danger]="!name.valid && name.touched">
                                <span class="m-t-05 required-label col-md-3">Name</span>
                                <input [formControl]="name" class="form-control col-md-8" type="text"
                                       formControlName="name"
                                       placeholder="Name">
                            </div>
                            <!--Regions-->
                            <div class="row form-field-spacing"
                                 [class.has-danger]="!regionValue.valid && regionValue.touched">
                                <span class="m-t-05 required-label col-md-3">Zone</span>
                                <select [formControl]="regionValue" class="form-control col-md-8 custom-select"
                                        formControlName="regionValue">
                                    <option value="null">Choose a zone</option>
                                    <option *ngFor="let region of allRegions" [value]="region.key">
                                        {{region.lookupValue}}
                                    </option>
                                </select>
                            </div>
                            <!--Admin Region/Organization-->
                            <div class="row col-md-12 form-field-spacing">
                                    <label class="custom-control custom-checkbox">
                                        <input class="form-control custom-control-input"
                                               id="selectManagementAgency"
                                               [formControl]="selectManagementAgencyControl"
                                               type="checkbox">
                                        <span class="custom-control-indicator"></span>
                                        <span class="required-label">Select Administrative Region</span>
                                    </label>
                            </div>
                            <div *ngIf="showManagementAgency" class="row form-field-spacing"
                                 [class.has-danger]="managementAgencyId.errors?.['isRequired'] && managementAgencyId.touched">
                                <span class="m-t-05 required-label col-md-3">Admin Region</span>
                                <input #agency [formControl]="managementAgencyId"
                                       formControlName="managementAgencyId"
                                       type="text"
                                       class="form-control col-md-8"
                                       (click)="managementAgencyListModal?.open()"
                                       value='All Agencies'
                                       placeholder="Administrative Region/Organization"
                                >
                                <agency-box (onAgencySelected)="onManagementAgencyChange($event)"></agency-box>
                            </div>
                            <!--Fiscal Start Month-->
                            <div class="row form-field-spacing"
                                 [class.has-danger]="!fiscalStartMonth.valid && fiscalStartMonth.touched">
                                <span class="m-t-05 required-label col-md-3">Fiscal Start Month</span>
                                <select [formControl]="fiscalStartMonth" required
                                        class="form-control col-md-8 custom-select"
                                        type="text" formControlName="fiscalStartMonth">
                                    <option value="null">Fiscal Start Month</option>
                                    <option *ngFor="let month of months" [value]="month.value">{{month.key}}</option>
                                </select>
                            </div>
                            <!--Fiscal Start Day-->
                            <div class="row form-field-spacing"
                                 [class.has-danger]="!fiscalStartDay.valid && fiscalStartDay.touched">
                                <span class="m-t-05 required-label col-md-3">Fiscal Start Day</span>
                                <select [formControl]="fiscalStartDay" required
                                        class="form-control col-md-8 custom-select" type="text"
                                        formControlName="fiscalStartDay">
                                    <option value="null">Fiscal Start Day</option>
                                    <option *ngFor="let day of days" [value]="day.value">{{day.value}}</option>
                                </select>
                            </div>
                            <!--minimum Single Pledge Amt-->
                            <div class="row form-field-spacing"
                                 [class.has-danger]="!minSinglePledgeAmt.valid && minSinglePledgeAmt.touched">
                                <span class="m-t-05 required-label col-md-3">Min Single Pledge</span>
                                <input [formControl]="minSinglePledgeAmt" numbersOnly class="form-control col-md-8"
                                       type="text"
                                       formControlName="minSinglePledgeAmt" placeholder="Minimum Single Pledge Amount">
                            </div>
                            <!--maximum Single Pledge Amt-->
                            <div class="row form-field-spacing"
                                 [class.has-danger]="!maxSinglePledgeAmt.valid && maxSinglePledgeAmt.touched">
                                <span class="m-t-05 required-label col-md-3">Max Single Pledge</span>
                                <input [formControl]="maxSinglePledgeAmt" numbersOnly class="form-control col-md-8"
                                       type="text"
                                       formControlName="maxSinglePledgeAmt" placeholder="Maximum Single Pledge Amount">
                            </div>
                            <!--maximum Yearly Pledge Amt-->
                            <div class="row form-field-spacing"
                                 [class.has-danger]="!maxYearlyPledgeAmt.valid && maxYearlyPledgeAmt.touched">
                                <span class="m-t-05 required-label col-md-3">Max Yearly Pledge</span>
                                <input [formControl]="maxYearlyPledgeAmt" numbersOnly class="form-control col-md-8"
                                       type="text"
                                       formControlName="maxYearlyPledgeAmt" placeholder="Maximum Yearly Pledge Amount">
                            </div>
                            <!--crisis Pledge Threshold-->
                            <div class="row form-field-spacing"
                                 [class.has-danger]="!crisisPledgeThreshold.valid && crisisPledgeThreshold.touched">
                                <span class="m-t-05 required-label col-md-3">Crisis Pledge Threshold</span>
                                <input [formControl]="crisisPledgeThreshold" numbersOnly class="form-control col-md-8"
                                       type="text"
                                       formControlName="crisisPledgeThreshold"
                                       placeholder="Crisis Pledge Threshold Amount">
                            </div>
                            <!--ledger Number-->
                            <div class="row form-field-spacing"
                                 [class.has-danger]="!ledgerNumber.valid && ledgerNumber.touched">
                                <span *ngIf="ledgerNumber.touched && ledgerNumber.hasError('maxLength')"
                                      class="m-t-05 col-md-3"></span>
                                <div *ngIf="ledgerNumber.touched && ledgerNumber.hasError('maxLength')"
                                     class="form-control-feedback pledgeTypeError"> {{ledgerNumber.getError('maxLength')}}
                                </div>
                                <span class="m-t-05 required-label col-md-3">Ledger Number</span>
                                <input [formControl]="ledgerNumber" class="form-control col-md-8" type="text"
                                       formControlName="ledgerNumber" placeholder="Ledger Number">
                            </div>
                        </div>
                    </div>
                    <!--TYPE-->
                    <div class="row form-group">
                        <label class="col-md-3"><strong>Pledge Type: </strong></label>
                        <div class="col-md-8">
                            <!--is SSN Required-->
                            <div class="row" [class.has-danger]="!isSsnRequired.valid && isSsnRequired.touched">
                                <span class="m-t-05 required-label col-md-3">Is SSN Required</span>
                                <div class="col-md-3">
                                    <label class="custom-control custom-checkbox">
                                        <input [formControl]="isSsnRequired" class="form-control custom-control-input"
                                               type="checkbox"
                                               id="isSsnRequired">
                                        <span class="custom-control-indicator"></span>
                                    </label>
                                </div>
                            </div>
                            <!--is Active-->
                            <div *ngIf="currentSelectedPledgeType" class="row"
                                 [class.has-danger]="!isActive.valid && isActive.touched">
                                <span class="m-t-05 required-label col-md-3">Is Active</span>
                                <div class="col-md-3">
                                    <label class="custom-control custom-checkbox">
                                        <input [formControl]="isActive" class="form-control custom-control-input"
                                               type="checkbox"
                                               id="isActive">
                                        <span class="custom-control-indicator"></span>
                                    </label>
                                </div>
                            </div>
                            <!--is EFS-->
                            <div [hidden]="isUwda.value || isUhons.value || isPrivate.value" class="row"
                                 [class.has-danger]="!isEfs.valid && isEfs.touched">
                                <span class="m-t-05 required-label col-md-3">Is Efs</span>
                                <div class="col-md-3">
                                    <label class="custom-control custom-checkbox">
                                        <input [formControl]="isEfs" class="form-control custom-control-input"
                                               type="checkbox"
                                               id="isEfs">
                                        <span class="custom-control-indicator"></span>
                                    </label>
                                </div>
                            </div>
                            <!--efsStartDate-->
                            <div [hidden]="!isEfs.value" class="row form-field-spacing"
                                 [class.has-danger]="!efsStartDate.valid && efsStartDate.touched">
                                <span class="m-t-05 required-label col-md-3">EFS Start Date</span>
                                <datePicker [control]="efsStartDate"
                                            [controlFormat]="'L'"
                                            [autoClose]="true"
                                            class="datePicker-padding col-md-8"></datePicker>
                            </div>
                            <!--efsEndDate-->
                            <div [hidden]="!isEfs.value" class="row form-field-spacing"
                                 [class.has-danger]="!efsEndDate.valid && efsEndDate.touched">
                                <span class="m-t-05 required-label col-md-3">EFS End Date</span>
                                <datePicker [control]="efsEndDate"
                                            [controlFormat]="'L'"
                                            [autoClose]="true"
                                            class="datePicker-padding col-md-8"></datePicker>
                            </div>
                            <!--isUwda-->
                            <div [hidden]="isEfs.value || isUhons.value" class="row"
                                 [class.has-danger]="!isUwda.valid && isUwda.touched">
                                <span class="m-t-05 required-label col-md-3">Is UWDA</span>
                                <div class="col-md-3">
                                    <label class="custom-control custom-checkbox">
                                        <input [formControl]="isUwda" class="form-control custom-control-input"
                                               type="checkbox"
                                               id="isUwda">
                                        <span class="custom-control-indicator"></span>
                                    </label>
                                </div>
                            </div>
                            <!--is UHONS-->
                            <div [hidden]="isUwda.value || isEfs.value" class="row"
                                 [class.has-danger]="!isUhons.valid && isUhons.touched">
                                <span class="m-t-05 required-label col-md-3">Is UHONS</span>
                                <div class="col-md-3">
                                    <label class="custom-control custom-checkbox">
                                        <input [formControl]="isUhons" class="form-control custom-control-input"
                                               type="checkbox"
                                               id="isUhons">
                                        <span class="custom-control-indicator"></span>
                                    </label>
                                </div>
                            </div>
                            <!--is Private-->
                            <div [hidden]="isEfs.value" class="row"
                                 [class.has-danger]="!isPrivate.valid && isPrivate.touched">
                                <span class="m-t-05 required-label col-md-3">Is Private</span>
                                <div class="col-md-3">
                                    <label class="custom-control custom-checkbox">
                                        <input [formControl]="isPrivate" class="form-control custom-control-input"
                                               type="checkbox"
                                               id="isPrivate">
                                        <span class="custom-control-indicator"></span>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row form-group" *ngIf="currentSelectedPledgeType?.id">
                        <label class="col-md-3">Mass Assign Fund Type:</label>
                        <div class="col-md-8">
                            <div class="row">
                                <span class="m-t-05 col-md-3">Associated Fund Type</span>
                                <massAssignPledgeType class="col-md-8"
                                                      [newPledgeTypeId]="currentSelectedPledgeType?.id"></massAssignPledgeType>
                            </div>
                        </div>
                    </div>
                    <!--submit-->
                    <div class="row form-group">
                        <div class="col-md-3">
                        </div>
                        <div class="col-md-8 btn-form">
                            <cancel-button class="p-l-1 pull-right" [route]="'/app/pledgetypes'"></cancel-button>

                            <!--save-->
                            <button [disable]="!form.dirty"
                                    type="submit"
                                    class="btn btn-primary"
                                    [autoDisable]="submit">Save Fund Type
                            </button>
                            <!--delete-->
                            <button type="button"
                                    class="btn btn-inverse"
                                    (click)="showPromptModal()">Delete Fund Type
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <prompt-dialog
                [titleText]="'Delete Fund Type?'"
                [promptText]="'Are you sure you want to delete fund type?'">
        </prompt-dialog>
    </section>
</div>
