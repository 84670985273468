import {IMask} from 'angular-imask';
import {format, parse} from 'date-fns';

export class Masking {
    static phoneMask = {mask: '(000) 000-0000'};
    static zipMask = {mask: '00000'};
    static ssnMask = {mask: '000-00-0000'};
    static ssnLast4Mask = {mask: 'XXX-XX-0000'};
    static currencyMask = {
        mask: '$num',
        blocks: {
            num: {
                mask: Number,
                thousandsSeparator: ',',
                scale: 2, // digits after decimal
                signed: true, // allow negative
                normalizeZeros: true, // appends or removes zeros at ends
                radix: '.', // fractional delimiter
                padFractionalZeros: true // if true, then pads zeros at end to the length of scale
            }
        }
    };

    static wholeCurrencyMask = {
        mask: '$num',
        blocks: {
            num: {
                mask: Number,
                thousandsSeparator: ',',
                scale: 0, // digits after decimal
                signed: true, // allow negative
                normalizeZeros: true, // appends or removes zeros at ends
                radix: '.', // fractional delimiter
                padFractionalZeros: true // if true, then pads zeros at end to the length of scale
            }
        }
    };

    static dateOfBirthMask = {
        mask: Date,
        pattern: 'MM/dd/yyyy',
        min: new Date(1900, 0, 1), // defaults to `1900-01-01`
        max: new Date(),
        format: (date) => {
            return format(date, 'MM/dd/yyyy');
        },
        parse: (str) => {
            return parse(str, 'MM/dd/yyyy', new Date());
        },
        blocks: {
            yyyy: {
                mask: IMask.MaskedRange,
                from: 1900,
                to: new Date().getFullYear()
            },
            MM: {
                mask: IMask.MaskedRange,
                from: 1,
                to: 12
            },
            dd: {
                mask: IMask.MaskedRange,
                from: 1,
                to: 31
            }
        }
    };

    static dateMask = {
        mask: Date,
        pattern: 'MM/dd/yyyy',
        max: new Date(),
        format: (date) => {
            return format(date, 'MM/dd/yyyy');
        },
        parse: (str) => {
            return parse(str, 'MM/dd/yyyy', new Date());
        },
        blocks: {
            yyyy: {
                mask: IMask.MaskedRange,
                from: 1900,
                to: new Date().getFullYear()
            },
            MM: {
                mask: IMask.MaskedRange,
                from: 1,
                to: 12
            },
            dd: {
                mask: IMask.MaskedRange,
                from: 1,
                to: 31
            }
        }
    };
}
