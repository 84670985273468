import {Injectable} from '@angular/core';
import {
    CountySelection,
    CountySpendingReportClient,
    CountySpendingReportParameter,
    PagedListOfCountySpendingReportPreview
} from './api.client';
import {Observable} from 'rxjs';
import {Store} from '@ngrx/store';
import * as fromRoot from '../store';
import {setCountySpendingReportIsLoaded, setCountySpendingReportIsLoading} from '../store/actions/countySpendingReport.actions';
import {share} from 'rxjs/operators';

@Injectable()
export class CountySpendingReportService {

    constructor(
        private _countySpendingReportClient: CountySpendingReportClient,
        private _store: Store<fromRoot.AppState>){
    }

    getCounties(): Observable<CountySelection[]> {
        return this._countySpendingReportClient.getCounties();
    }

    previewCountySpendingReport(
        parameters: CountySpendingReportParameter,
        sortName: string,
        descending: boolean,
        limit: number,
        offset: number): Observable<PagedListOfCountySpendingReportPreview> {
        this.setIsLoadingAction();
        const request = this._countySpendingReportClient.previewCountySpendingReport(
            parameters,
            sortName,
            null,
            descending,
            limit || 10,
            offset || 0)
            .pipe(share());

        request.subscribe((data) => this.setIsLoadedAction(data));

        return request;
    }

    private setIsLoadingAction(): void {
        this._store.dispatch(setCountySpendingReportIsLoading());
    }

    setIsLoadedAction(data: PagedListOfCountySpendingReportPreview): void {
        this._store.dispatch(setCountySpendingReportIsLoaded({ payload: data }));
    }
}
