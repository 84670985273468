import {Component, Input} from '@angular/core';
import {UntypedFormControl} from '@angular/forms';

@Component({
  selector: 'app-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.scss']
})
export class UploadComponent {

  @Input()
  labelText: string;

  @Input()
  fileInput: UntypedFormControl;

}
