import {Component, EventEmitter, Injector, Input, OnInit, Output, ViewChild} from '@angular/core';
import {AbstractControl, FormArray, FormControl, FormGroup, UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {NotificationService} from '../../../../../../services/NotificationService';
import {ActivatedRoute, Router} from '@angular/router';
import {
    AssistanceApplication,
    CompanyCategoryThresholdViewModel,
    CompanyCategoryType,
    AssistanceApplicationClient,
    AssistanceApplicationFileClient,
    CompanyPayeeType,
    Client,
    Company,
    CreateAssistanceApplication,
    FundTypeClient,
    LookUp,
    PledgeType,
    UpdateAssistanceApplication,
    User, UserType,
} from '../../../../../../services/api.client';
import {select, Store} from '@ngrx/store';
import * as fromRoot from '../../../../../../store';
import {
    selectSecurityStateDataCurrentUser,
    selectSecurityStateDataSystemDataStates,
    selectSecurityStateDataSystemDataAdministrativeAgencyFundAssistanceOptions
} from '../../../../../../store/selectors/security.selectors';
import {BehaviorSubject, forkJoin, lastValueFrom, Observable} from 'rxjs';
import {Masking} from '../../../../../../utilities/Masking';
import {PromptComponent} from '../../../../../shared/dialogs/PromptComponent';
import {extractEnumNames, getMessageFromException} from '../../../../../../utilities/Util';
import * as moment from 'moment';
import {CompanyListModalComponent} from '../../../../company/CompanyListModalComponent';
import {debounceTime, distinctUntilChanged, map, takeUntil, tap} from 'rxjs/operators';
import {Validators} from '../../../../../../utilities/Validators';
import {Disposable} from '../../../../../../utilities/Disposable';
import {ActivityAuditService} from '../../../../../../services/activityAudit.service';
import {selectCategoryThresholds} from '../../../../../../store/selectors/companyCategory.selectors';
import {requiredDocumentsUploadedValidator} from '../assistance-application-document-upload/documentValidator';
import {
    ASSISTANCE_APPLICATION_DENIAL_REASON_OPTIONS
} from '../../../../../../shared/constants/options/assistance-application-denial-options';
import {AssistanceApplicationStatus} from '../../../../../../shared/enums/assistance-application-status';
import {AssistanceApplicationDenialReason} from '../../../../../../shared/enums/assistance-application-denial-reason';
import {requireDenialReasonIfDenied} from './validators';
import {setCurrentPayeeCompanyCategoryAmounts} from '../../../../../../store/actions/companyCategory.actions';
import {CompanyService} from '../../../../../../services/CompanyService';
import swal from 'sweetalert2';
import {CurrencyPipe} from '@angular/common';
import {
    FundAdministrativeAgencyName
} from '../../../../../../shared/constants/FundConstants';
import {IPayeeType} from '../../../../../../shared/interfaces/IPayeeType';
import {ICategoryThreshold} from '../../../../../../shared/interfaces/ICategoryThreshold';
import {IPayeeCompanyCategoryAmount} from '../../../../../../shared/interfaces/IPayeeCompanyCategoryAmount';
import {CompanySelectedEvent} from '../../../../../shared/events/company-selected-event';
import {SwalComponent} from '@sweetalert2/ngx-sweetalert2';
import {fileErrorNames, formControlErrorMapper} from './form-control-error-mapper';

@Component({
    selector: 'app-assistance-application-form',
    templateUrl: './assistance-application-form.component.html',
    styleUrls: ['./assistance-application-form.component.scss']
})
export class AssistanceApplicationFormComponent extends Disposable implements OnInit {
    constructor(private formBuilder: UntypedFormBuilder,
                private notificationService: NotificationService,
                private assistanceApplicationClient: AssistanceApplicationClient,
                private router: Router,
                private fundTypeClient: FundTypeClient,
                private injector: Injector,
                private activatedRoute: ActivatedRoute,
                private _companyService: CompanyService,
                private _store: Store<fromRoot.AppState>,
                private _activityAuditService: ActivityAuditService,
                private assistanceApplicationFileClient: AssistanceApplicationFileClient,
                private currencyPipe: CurrencyPipe) {
        super();
        this.states$ = this._store.pipe(select(selectSecurityStateDataSystemDataStates));
        this.assistanceOptions$ = this._store.pipe(select(selectSecurityStateDataSystemDataAdministrativeAgencyFundAssistanceOptions));
        this.categoryThresholds$ = this._store.select(selectCategoryThresholds);
        this.loggedInUser$ = this._store.pipe(select(selectSecurityStateDataCurrentUser));
    }

    @ViewChild('auditModal') auditModal: PromptComponent;

    @ViewChild('exceedsCategoryThreshold') exceedsCategoryThresholdModal: PromptComponent;

    @ViewChild('loadingDialog')
    public readonly loadingDialog!: SwalComponent;

    @Input()
    client: Client = null;

    @Input() user: User;

    @Input() assistanceApplication: AssistanceApplication;

    @Input() auditState: string;
    @Input() applicationLoading = true;
    @Input() defaultOpenDocumentUpload = true;
    @Input() isNewApplication: boolean;

    @Output() onUpdate = new EventEmitter<AssistanceApplication>();

    loggedInUser: User;
    loggedInUser$: Observable<User>;
    softDeletedIds = [];

    public ASSISTANCE_APPLICATION_DENIAL_REASON_OPTIONS = ASSISTANCE_APPLICATION_DENIAL_REASON_OPTIONS;
    public form: UntypedFormGroup;

    public companyFormArrayMetaData: { ignoreDuplicates: boolean, duplicateCompany: undefined | null | Company }[] = [];

    private _originalEditCase = new BehaviorSubject<AssistanceApplication>(null);

    loadingSubject = new BehaviorSubject<boolean>(false);
    loading$ = this.loadingSubject.asObservable();
    saving$ = new BehaviorSubject<boolean>(false);
    savingMessage$ = new BehaviorSubject<string>('');
    savingTitle$ = new BehaviorSubject<string>('');
    percentComplete$ = new BehaviorSubject<string>('50%');
    formErrors$ = new BehaviorSubject<string[]>([]);

    public uploadedCount = 0;
    public totalToUpload = 0;
    states: LookUp[];
    states$: Observable<LookUp[]>;
    fundTypes: PledgeType[];

    categoryThresholds: CompanyCategoryThresholdViewModel[];
    categoryThresholds$: Observable<CompanyCategoryThresholdViewModel[]>;
    categoryThresholdsSelect: ICategoryThreshold[];

    payeeTypesSelect: IPayeeType[];

    utilityTotal = 0;
    rentalTotal = 0;
    transportationTotal = 0;
    childcareTotal = 0;
    carRepairTotal = 0;

    assistanceOptions: LookUp[];
    assistanceOptions$: Observable<LookUp[]>;

    zipCodeMask = Masking.zipMask;
    isCountyActive: boolean;
    clientCountyInactiveMessage: string;
    private administrativeAgencyFundTypeId;
    public householdIncomeAlertMessage: string;
    public individualIncomeAlertMessage: string;
    public emailAlertMessage: string;
    public countyAlertMessageHtml: string;
    public countyAlertMessageText: string;
    public showBulkAlerts = true;

    @ViewChild(PromptComponent)
    clientIsInactiveModal: PromptComponent;
    // -------- company modal ------------

    @ViewChild(CompanyListModalComponent) companyListModal: CompanyListModalComponent;

    assistanceApplicationStates = extractEnumNames(AssistanceApplicationStatus);

    private selectedCompanies: Company[] = [];

    get assistanceApplicationAreasOfNeedGroup(): FormGroup {
        return this.form.get('assistanceApplicationAreasOfNeed') as FormGroup;
    }

    get fundPledgeTypeId(): FormControl {
        return this.form.get('fundPledgeTypeId') as FormControl;
    }

    get totalNeedControl(): FormControl {
        return this.form.get('totalNeed') as FormControl;
    }

    get assignedAgencyIdControl(): FormControl {
        return this.form.get('assignedAgencyId') as FormControl;
    }

    get summaryControl(): FormControl {
        return this.form.get('summary') as FormControl;
    }

    get descriptionControl(): FormControl {
        return this.form.get('description') as FormControl;
    }

    get statusControl(): FormControl<AssistanceApplicationStatus> {
        return this.form.get('status') as FormControl<AssistanceApplicationStatus>;
    }

    get denialReasonControl(): FormControl<AssistanceApplicationDenialReason> {
        return this.form.get('denialReason') as FormControl<AssistanceApplicationDenialReason>;
    }

    get otherDenialReasonControl(): FormControl<string> {
        return this.form.get('otherDenialReason') as FormControl<string>;
    }

    get companiesFormArray(): FormArray {
        return this.form.get('companies') as FormArray;
    }

    get denied(): boolean {
        return this.statusControl.value === AssistanceApplicationStatus.Denied;
    }

    get otherDenialReasonSelected(): boolean {
        return this.denialReasonControl.value === AssistanceApplicationDenialReason.Other;
    }

    get filesFormArray(): FormArray {
        return this.form?.get('files') as FormArray;
    }

    get formCompanies(): FormArray {
        return this.form.get('companies') as FormArray;
    }

    get hasApplicantDifferences(): boolean {
        return this.hasDifferentIndividualIncome || this.hasDifferentCounty || this.hasDifferentEmail || this.hasDifferentHouseholdIncome;
    }

    get hasDifferentEmail(): boolean {
        return this.assistanceApplication.applicantEmail.toLowerCase() !== this.client?.email?.toLowerCase();
    }

    get hasDifferentCounty(): boolean {
        return this.assistanceApplication.applicantCounty?.toLowerCase() !== this.client.county?.toLowerCase();
    }

    get hasDifferentHouseholdIncome(): boolean {
        return this.assistanceApplication.applicantHouseHoldIncome !== this.client.houseHoldIncome;
    }

    get hasDifferentIndividualIncome(): boolean {
        return this.assistanceApplication.applicantIndividualMonthlyIncome !== this.client.individualMonthlyIncome;
    }

    get allowThresholdOverride(): boolean {
        return this.user?.userType === UserType.SystemAdmin || this.user?.userType === UserType.RegionAdmin;
    }

    date = moment();

    ngOnInit(): void {
        this.initForm();
        this.states$.subscribe(states => this.states = states);
        this.assistanceOptions$.subscribe(options => this.assistanceOptions = options);
        this.setPayeeTypesSelect();
        this.categoryThresholds$.subscribe(categoryThresholds => {
            this.categoryThresholds = categoryThresholds;
            this.setCategoryThresholdsSelect();
        });
        this.assignedAgencyIdControl.setValue(this.user?.agencyId);
        this.assistanceApplicationClient.isAssistanceApplicationCountyActive(this.client?.county)
            .pipe(takeUntil(this.$destroyed), distinctUntilChanged())
            .subscribe(isActive => this.isCountyActive = isActive);
        this.fundTypeClient.getFundTypeId(FundAdministrativeAgencyName).subscribe(x => {
            this.administrativeAgencyFundTypeId = x;
        });

        if (!this.user?.agencyId) {
            this.loggedInUser$
                .pipe(takeUntil(this.$destroyed))
                .subscribe(loggedInUser => this.loggedInUser = loggedInUser);
            this.handleFundPledgeTypeIds(this.loggedInUser?.agencyId);
        } else {
            this.handleFundPledgeTypeIds(this.user?.agencyId);
        }
        this.form.valueChanges.pipe(takeUntil(this.$destroyed)).subscribe(() => {
            if (!this.denied) {
                this.denialReasonControl.reset({value: null, disabled: true}, {emitEvent: false});
            } else {
                this.denialReasonControl.enable({emitEvent: false});
            }

            if (this.otherDenialReasonSelected) {
                this.otherDenialReasonControl.setValidators(Validators.required);
            } else {
                this.otherDenialReasonControl.clearValidators();
                this.otherDenialReasonControl.reset(null, {emitEvent: false});
            }
        });

        this.checkForDuplicateCompanyOnFormChanges();
        this.setAlertMessages();

        this.saving$.pipe(takeUntil(this.$destroyed), distinctUntilChanged()).subscribe(val => {
            if (val) {
                this.loadingDialog?.fire().then();
            } else {
                this.loadingDialog?.close().then();
            }
        });
    }

    public hasIncome(income: number | undefined | null): boolean {
        return income !== null && income !== undefined && income >= 0;
    }

    getCompanyName(payee: AbstractControl): FormControl {
        return payee.get('companyName') as FormControl;
    }

    getCompanyOriginalId(payee: AbstractControl): FormControl {
        return payee.get('companyId') as FormControl;
    }

    getUseNewCompany(payee: AbstractControl): FormControl {
        return payee.get('useNewCompany') as FormControl;
    }

    getPayeeAmount(payee: AbstractControl): FormControl {
        return payee.get('amount') as FormControl;
    }

    getPayeeCategory(payee: AbstractControl): FormControl {
        return payee.get('category') as FormControl;
    }

    getPayeePledge(payee: AbstractControl): FormControl {
        return payee.get('pledgeId') as FormControl;
    }

    getPaymentTo(payee: AbstractControl): FormControl {
        return payee.get('paymentTo') as FormControl;
    }

    getPayeePayeeType(payee: AbstractControl): FormControl {
        return payee.get('payeeType') as FormControl;
    }

    getPayeeAddress(payee: AbstractControl): FormControl {
        return payee.get('payeeAddress') as FormControl;
    }

    getPayeeAddress2(payee: AbstractControl): FormControl {
        return payee.get('payeeAddress2') as FormControl;
    }

    getPayeeCity(payee: AbstractControl): FormControl {
        return payee.get('payeeCity') as FormControl;
    }

    getPayeeState(payee: AbstractControl): FormControl {
        return payee.get('payeeState') as FormControl;
    }

    getPayeeZip(payee: AbstractControl): FormControl {
        return payee.get('payeeZip') as FormControl;
    }

    getAccountsNumber(payee: AbstractControl): FormControl {
        return payee.get('accountNumber') as FormControl;
    }

    initForm(): void {
        this.form = this.formBuilder.group({
            clientId: ['', Validators.required],
            fundPledgeTypeId: ['', Validators.required],
            totalNeed: ['', Validators.required],
            assignedAgencyId: [null, Validators.required],
            summary: ['', Validators.compose([Validators.maxLength(128)])],
            description: '',
            status: new FormControl<AssistanceApplicationStatus | ''>('', Validators.required),
            denialReason: new FormControl<AssistanceApplicationDenialReason | string>(''),
            otherDenialReason: new FormControl<string>(''),
            fundedDate: '',
            companies: this.formBuilder.array([this.initCompanyForm()]),
            adults: this.formBuilder.array([]),
            minors: this.formBuilder.array([]),
            assistanceApplicationAreasOfNeed: this.formBuilder.group({
                rent: [false],
                evictionNotification: [false],
                electric: [false],
                gasHeat: [false],
                water: [false],
                sewer: [false],
                childcareExpenses: [false],
                transportationRideShare: [false],
                carRepair: [false],
            }, {validators: [Validators.atLeastOneChecked()]}),
            files: this.formBuilder.array([])
        }, {
            validators: [requireDenialReasonIfDenied(), requiredDocumentsUploadedValidator(this.client)]
        });
    }


    initCompanyForm(): FormGroup {
        return this.formBuilder.group({
            useNewCompany: new FormControl(false),
            paymentTo: [''],
            payeeAddress: [''],
            payeeAddress2: [''],
            accountNumber: ['', Validators.required],
            payeeCity: [''],
            payeeState: [''],
            payeeZip: [''],
            payeeType: [null],
            id: [null, Validators.requiredWhen('useNewCompany', false)],
            companyId: [null],
            companyName: [null, Validators.required],
            isLegacyPayee: [false],
            amount: new FormControl<number | null>(null, Validators.required),
            category: ['', Validators.required],
            pledgeId: [null]
        }, {
            validators: Validators.compose([
                Validators.mutuallyExclusive('companyId', 'paymentTo', 'Company', 'Payment To'),
                Validators.mutuallyExclusive('companyId', 'payeeAddress1', 'Company', 'Payee Address'),
                Validators.mutuallyExclusive('companyId', 'payeeCity', 'Company', 'Payee Address2'),
                Validators.mutuallyExclusive('companyId', 'payeeState', 'Company', 'Payee State'),
                Validators.mutuallyExclusive('companyId', 'payeeZip', 'Company', 'Payee Zip')
            ])
        });
    }

    loadPayeeForm(company: any): UntypedFormGroup {
        const payeeForm = this.formBuilder.group({
            useNewCompany: [company.isLegacyPayee ?? false],
            payeeType: [company.payeeType ?? null],
            paymentTo: [''],
            payeeAddress: [''],
            payeeAddress2: [''],
            accountNumber: [company.accountNumber],
            payeeCity: [''],
            payeeState: [''],
            payeeZip: [''],
            id: [company.id],
            companyName: [company.companyName],
            companyId: [company.companyId],
            isLegacyPayee: [company.isLegacyPayee],
            amount: [company.amount],
            category: [company.category],
            pledgeId: [company.pledgeId || null]
        }, {
            validators: Validators.compose([
                Validators.mutuallyExclusive('companyId', 'paymentTo', 'Company', 'Payment To'),
                Validators.mutuallyExclusive('companyId', 'payeeAddress1', 'Company', 'Payee Address'),
                Validators.mutuallyExclusive('companyId', 'payeeCity', 'Company', 'Payee Address2'),
                Validators.mutuallyExclusive('companyId', 'payeeState', 'Company', 'Payee State'),
                Validators.mutuallyExclusive('companyId', 'payeeZip', 'Company', 'Payee Zip')
            ])
        });
        payeeForm.get('useNewCompany').disable();
        payeeForm.get('companyName').disable();
        payeeForm.get('category').disable();
        return payeeForm;
    }

    setPayeeValidators(company: any, isPayee: boolean): void {
        if (isPayee) {
            (company)?.clearValidators();
            this.getCompanyName(company)?.removeValidators(Validators.required);
            this.getCompanyName(company)?.setValue(null);
            this.getCompanyOriginalId(company)?.setValue(null);
            this.getPayeeCategory(company)?.setValue('');
            this.getPayeePayeeType(company)?.setValidators(Validators.required);
            this.getPaymentTo(company)?.setValidators(Validators.required);
            this.getPayeeAddress(company)?.setValidators(Validators.required);
            this.getPayeeCity(company)?.setValidators(Validators.required);
            this.getPayeeState(company)?.setValidators(Validators.required);
            this.getPayeeZip(company)?.setValidators(Validators.required);
            this.getPayeeCategory(company)?.setValidators(Validators.required);
            this.getPayeeCategory(company)?.enable();
        } else {
            this.getPaymentTo(company)?.removeValidators(Validators.required);
            this.getPayeePayeeType(company)?.removeValidators(Validators.required);
            this.getCompanyName(company)?.setValidators(Validators.required);
            this.getPayeeAddress(company)?.removeValidators(Validators.required);
            this.getPayeeCity(company)?.removeValidators(Validators.required);
            this.getPayeeState(company)?.removeValidators(Validators.required);
            this.getPayeeZip(company)?.removeValidators(Validators.required);
            this.getAccountsNumber(company)?.removeValidators(Validators.required);

            const companyOriginalId = this.getCompanyOriginalId(company)?.value;
            const foundCompany = this.selectedCompanies.find(x => x.id === companyOriginalId);

            if (foundCompany) {
                this.getPayeeCategory(company)?.setValue(foundCompany.category);
                this.getPayeeCategory(company)?.disable();
            } else {
                this.getPayeeCategory(company)?.setValue('');
                this.getPayeeCategory(company)?.enable();
            }
        }

        this.getAccountsNumber(company)?.setValidators(Validators.required);

        this.getCompanyName(company)?.updateValueAndValidity({onlySelf: true});
        this.getPayeePayeeType(company)?.updateValueAndValidity({onlySelf: true});
        this.getPaymentTo(company)?.updateValueAndValidity({onlySelf: true});
        this.getPayeeAddress(company)?.updateValueAndValidity({onlySelf: true});
        this.getPayeeCity(company)?.updateValueAndValidity({onlySelf: true});
        this.getPayeeState(company)?.updateValueAndValidity({onlySelf: true});
        this.getPayeeZip(company)?.updateValueAndValidity({onlySelf: true});
        this.getAccountsNumber(company)?.updateValueAndValidity({onlySelf: true});
    }

    private setPercentComplete(numDone: number, total: number): void {
        const val = `${Math.floor((numDone / total) * 100)}%`;
        this.percentComplete$.next(val);
    }

    private checkForDuplicateCompanyOnFormChanges(): void {
        this.companiesFormArray.valueChanges.pipe(takeUntil(this.$destroyed), debounceTime(3000)).subscribe(() => {
            this.companiesFormArray.controls.forEach((companyFormGroup, i) => {
                const isNewCompany = companyFormGroup.get('useNewCompany').value;
                if (isNewCompany) {
                    const name = companyFormGroup.get('paymentTo').value;
                    const address = companyFormGroup.get('payeeAddress').value;

                    if (!name && !address) {
                        return;
                    }

                    this._companyService.checkDuplicateCompany(name, address).subscribe(next => {
                        const existingCompanyFound = !!next.company;
                        if (existingCompanyFound) {
                            const metaData = this.companyFormArrayMetaData[i];
                            if (!metaData.ignoreDuplicates) {
                                metaData.duplicateCompany = next.company;
                            }
                        }
                    });
                }
            });
        });
    }

    public populateCompanyFormGroupWithExistingCompany(i: number): void {
        const companyFormGroup = this.companiesFormArray.controls[i] as FormGroup;
        const metaData = this.companyFormArrayMetaData[i];
        if (!metaData.ignoreDuplicates && !!metaData.duplicateCompany) {
            companyFormGroup.get('useNewCompany').setValue(false);
            companyFormGroup.get('companyName').setValue(metaData.duplicateCompany.name);
            companyFormGroup.get('companyId').setValue(metaData.duplicateCompany.id);
            companyFormGroup.get('isLegacyPayee').setValue(false);
            this.getPaymentTo(companyFormGroup)?.setValue(null);
            this.getPayeeAddress(companyFormGroup)?.setValue(null);
            this.getPayeeCity(companyFormGroup)?.setValue(null);
            this.getPayeeState(companyFormGroup)?.setValue(null);
            this.getPayeeZip(companyFormGroup)?.setValue(null);
        }
        this.setPayeeValidators(companyFormGroup, false);
    }

    public resetCompanyFormArrayMetaDataAtIndex(i: number): void {
        this.companyFormArrayMetaData[i] = {ignoreDuplicates: false, duplicateCompany: null};

    }

    public ignoreDuplicateCompany(i: number): void {
        this.companyFormArrayMetaData[i].ignoreDuplicates = true;
        const companyFormGroup = this.companiesFormArray.controls[i] as FormGroup;
        this.setPayeeValidators(companyFormGroup, true);
    }


    submit(): any {
        throw Error('Invalid Operation: Use createCase() or updateCase()');
    }

    populateClient(client: Client): void {
        this.form.patchValue({
            clientId: client.id,
            clientFirstName: client.firstName,
            clientLastName: client.lastName,
            clientSsn: client.ssn,
            clientAddress: client.address1 + '\n' + client.address2,
            clientCounty: client.county,
            clientPhone: client.phone,
            clientEmail: client.email,
        });
    }

    populateUser(user: User): void {
        this.form.patchValue({assignedAgencyId: user.agencyId, createdByUserId: user.id});
    }

    showCompanyModal(company: AbstractControl): boolean {
        this.companyListModal.openModal(null, company, false);
        return false;
    }

    onCompanySelected(event: CompanySelectedEvent): void {
        if (event.company) {
            this.getCompanyName(event.payee).patchValue(event.company.name);
            this.getCompanyOriginalId(event.payee).patchValue(event.company.id);
            this.selectedCompanies.push(event.company);
            this.getPayeeCategory(event.payee)?.setValue(event.company.category);
            this.getPayeeCategory(event.payee)?.removeValidators(Validators.required);
            this.getPayeeCategory(event.payee)?.disable();
        } else {
            this.getPayeeCategory(event.payee)?.addValidators(Validators.required);
            this.getPayeeCategory(event.payee)?.enable();
        }
    }

    getHumanizedInvalidFields(): string {
        const invalidFields = [];
        Object.keys(this.form.controls).forEach(controlName => {
            if (this.form.get(controlName)?.invalid) {
                const humanizedError = this.humanizeControlName(controlName);
                invalidFields.push(humanizedError);
            }
        });
        if (this.form.errors && fileErrorNames.some(x => Object.keys(this.form.errors).includes(x))) {
            const humanizedError = this.humanizeControlName('files');
            invalidFields.push(humanizedError);
        }
        this.formErrors$.next(invalidFields);
        return invalidFields.join('\r\n');
    }

    humanizeControlName(controlName: string): string {
        const mapping = formControlErrorMapper.find(x => x.formControlName === controlName);
        return mapping?.humanizedError;
    }

    async createCase(overrideSave: boolean = false): Promise<void> {
        if (!this.isValidForm()) {
            this.loadingSubject.next(false);
            this.saving$.next(false);
            this.getHumanizedInvalidFields();
            return;
        }

        this.formErrors$.next([]);

        if (!overrideSave && !this.doesCategoryAmountsHaveValidAmounts()) {
            this.showExceedsCategoryThresholdModal();
            this.loadingSubject.next(false);
            this.saving$.next(false);
            return;
        }

        this.setPercentComplete(0, 4);
        this.saving$.next(true);
        this.loadingSubject.next(true);
        this.savingTitle$.next('Creating Application');
        this.savingMessage$.next('Please wait...');
        this.form.markAllAsTouched();

        const assistanceApplicationForm = this.form.getRawValue();
        const createAssistanceApplicationModel = CreateAssistanceApplication.fromJS({
            clientId: assistanceApplicationForm?.clientId || null,
            assignedAgencyId: this.user?.agencyId || null,
            fundPledgeTypeId: assistanceApplicationForm?.fundPledgeTypeId || null,
            totalNeed: assistanceApplicationForm?.totalNeed || null,
            summary: assistanceApplicationForm?.summary || null,
            description: assistanceApplicationForm?.description || null,
            note: assistanceApplicationForm?.note || null,
            status: assistanceApplicationForm?.status || AssistanceApplicationStatus.UnderReview,
            fundedDate: assistanceApplicationForm?.fundedDate || null,
            createdByUserId: assistanceApplicationForm?.createdByUserId || this.user?.id,
            isPublic: false,
            companies: this.companiesFormArray.getRawValue(),
            fundTypeId: this.administrativeAgencyFundTypeId,
            adults: assistanceApplicationForm?.adults,
            minors: assistanceApplicationForm?.minors,
            assistanceApplicationAreasOfNeed: assistanceApplicationForm?.assistanceApplicationAreasOfNeed,
            client: this.client
        });

        const filesArray = assistanceApplicationForm.files.map((fileWrapper: any) => {
            return {file: {data: fileWrapper.file, fileName: fileWrapper.file.name}, type: fileWrapper.fileType};
        });

        let createdApplicationId: string;

        try {
            const result = await lastValueFrom(this.assistanceApplicationClient.add(createAssistanceApplicationModel));
            createdApplicationId = result.id;
        } catch (err) {
            this.loadingSubject.next(false);
            this.saving$.next(false);

            if (err) {
                this.injector.get(NotificationService).showError(getMessageFromException(err));
            } else {
                this.injector.get(NotificationService)
                    .showError('An error occurred while creating your application. Please try again.');
            }
            return;
        }

        this.setPercentComplete(2, 4);

        this.uploadedCount = 0;
        this.totalToUpload = filesArray.length;
        this.savingTitle$.next('Uploading Files');
        this.savingMessage$.next(`${0} out of ${this.totalToUpload} files uploaded - please wait...`);

        const uploadRequests = filesArray.map((file: any) =>
            this.assistanceApplicationFileClient.uploadFile(createdApplicationId, file.type, 'v1', file.file).pipe(
                tap((response) => {
                    this.uploadedCount++;
                    console.log(`${response.fileName} uploaded...`);
                    const uploadPercentage = (this.uploadedCount / this.totalToUpload) * 75; // 75% for total process minus initial save
                    const totalPercentage = 25 + uploadPercentage;
                    this.percentComplete$.next(`${Math.floor(totalPercentage)}%`);
                    this.savingMessage$.next(`${this.uploadedCount} out of ${this.totalToUpload} files uploaded...`);
                })
            )
        );

        forkJoin(uploadRequests).subscribe(
            {
                next: () => {
                    this.setPercentComplete(1, 1);
                    setTimeout(() => {
                        this.loadingSubject.next(false);
                        this.saving$.next(false);
                        this.injector.get(NotificationService).showSuccess('Application Successfully Created!');
                        this.router.navigate(['../', createdApplicationId], {relativeTo: this.activatedRoute}).then();
                    }, 500);
                }, error: (err) => {
                    this.loadingSubject.next(false);
                    this.saving$.next(false);
                    this.injector.get(NotificationService)
                        .showError('An error occurred while uploading one or more of your files.');
                    swal.fire({
                        heightAuto: false,
                        icon: 'warning',
                        title: 'File Upload Error',
                        text: 'There was an error uploading one or more of your files. The application was successfully created. Please try adding the files to the application again. If the error persists please contact an application administrator.',
                    }).then(_ => this.router.navigate(['../', createdApplicationId], {relativeTo: this.activatedRoute}).then());
                }
            }
        );
    }

    addPayee(): void {
        this.companiesFormArray.push(this.initCompanyForm());
        this.companyFormArrayMetaData.push({
            ignoreDuplicates: false,
            duplicateCompany: null
        });
    }

    async overrideSave(): Promise<void> {
        if (this.isNewApplication) {
            await this.createCase(true);
        } else {
            const originalCase = this._originalEditCase.getValue();
            await this.updateCase(originalCase, true);
        }
    }

    private completeApplicationSave(): void {
        setTimeout(() => {
            this.loadingSubject.next(false);
            this.saving$.next(false);
            this.onUpdate.emit(this.assistanceApplication);
            this.injector.get(NotificationService).showSuccess('Application Successfully Updated!');
            window.location.reload();
        }, 500);
    }

    async updateCase(originalCase: AssistanceApplication, overrideSave: boolean = false): Promise<void> {

        if (!this.isValidForm() && !(this.statusControl.value === AssistanceApplicationStatus.Denied)) {
            this.form.markAllAsTouched();
            this.loadingSubject.next(false);
            this.getHumanizedInvalidFields();
            return;
        }

        this.formErrors$.next([]);

        this._originalEditCase.next(originalCase);

        if (!overrideSave && !this.doesCategoryAmountsHaveValidAmounts()) {
            this.showExceedsCategoryThresholdModal();
            this.loadingSubject.next(false);
            return;
        }

        this.setPercentComplete(0, 4);
        this.saving$.next(true);
        this.loadingSubject.next(true);
        this.savingTitle$.next('Saving Application');
        this.savingMessage$.next('Please wait...');
        this.form.markAllAsTouched();
        this.form.get('assignedAgencyId').setValue(originalCase.assignedAgencyId);

        const assistanceApplicationUpdate = this.form.getRawValue();

        assistanceApplicationUpdate.assistanceApplicationAreasOfNeed.id = originalCase.assistanceApplicationAreasOfNeed.id;

        const updateAssistanceApplicationModel = UpdateAssistanceApplication.fromJS({
            id: originalCase?.id,
            clientId: assistanceApplicationUpdate?.clientId || null,
            assignedAgencyId: originalCase.assignedAgencyId || null,
            fundPledgeTypeId: assistanceApplicationUpdate?.fundPledgeTypeId || null,
            totalNeed: assistanceApplicationUpdate?.totalNeed || null,
            summary: assistanceApplicationUpdate?.summary || null,
            description: assistanceApplicationUpdate?.description || null,
            note: assistanceApplicationUpdate?.note || null,
            status: this.assistanceApplicationStates
                .find(x => x.key?.toLowerCase() === assistanceApplicationUpdate?.status?.toString()?.toLowerCase())
                .value,
            denialReason: assistanceApplicationUpdate?.denialReason || null,
            otherDenialReason: (assistanceApplicationUpdate?.denialReason === AssistanceApplicationDenialReason.Other ? assistanceApplicationUpdate?.otherDenialReason : null) || null,
            fundedDate: assistanceApplicationUpdate?.fundedDate || null,
            createdByUserId: assistanceApplicationUpdate?.createdByUserId || this.user?.id,
            isPublic: false,
            companies: this.companiesFormArray.getRawValue(),
            fundTypeId: originalCase.fundTypeId,
            adults: assistanceApplicationUpdate?.adults,
            minors: assistanceApplicationUpdate?.minors,
            assistanceApplicationAreasOfNeed: assistanceApplicationUpdate?.assistanceApplicationAreasOfNeed
        });

        this.setPercentComplete(1, 4);

        const newFilesSubmitted = this.form.get('files').value.filter(x => !x.id) as any[];
        const newFilesMapped = newFilesSubmitted.map((fileWrapper: any) => {
            return {data: fileWrapper.file, fileName: fileWrapper.file.name};
        });

        const fileTypes = newFilesSubmitted.map((fileWrapper: any) => {
            return fileWrapper.fileType;
        });

        try {
            const result = await lastValueFrom(this.assistanceApplicationClient.update(updateAssistanceApplicationModel));
            this.user = result.createdByUser;
            this.assistanceApplication = result;
            if (!this.softDeletedIds?.length && !newFilesMapped.length) {
                this.setPercentComplete(4, 4);
                this.completeApplicationSave();
                return;
            }
            this.setPercentComplete(2, 4);
        } catch (err) {
            this.loadingSubject.next(false);
            this.saving$.next(false);
            if (err) {
                this.injector.get(NotificationService).showError(getMessageFromException(err));
            } else {
                this.injector.get(NotificationService)
                    .showError('An error occurred while updating the application. Please try again.');
            }
            this.onUpdate.emit(this.assistanceApplication);
            window.location.reload();
            return;
        }

        this.savingTitle$.next('Updating Files');
        this.savingMessage$.next('Please wait...');

        this.assistanceApplicationFileClient.updateFiles(
            fileTypes,
            this.softDeletedIds,
            this.assistanceApplication.id,
            'v1',
            newFilesMapped
        ).subscribe({
            next: () => {
                this.setPercentComplete(4, 4);
                this.completeApplicationSave();
            }, error: (err) => {
                this.loadingSubject.next(false);
                this.saving$.next(false);
                this.injector.get(NotificationService)
                    .showError('An error occurred while uploading one or more of your files.');
                swal.fire({
                    heightAuto: false,
                    icon: 'warning',
                    title: 'File Upload Error',
                    text: 'There was an error uploading one or more of your files. The rest of the application was successfully updated. Please try saving again. If the error persists please contact an application administrator.',
                }).then();
            }
        });
    }

    private isValidForm(): boolean {
        if (!this.isCountyActive) {
            this.clientCountyInactiveMessage = `The Client County ${this.client?.county} is inactive and is not currently accepting new cases.`;
            this.clientIsInactiveModal.open().subscribe(() => {
                this.router.navigate([`app/client/${this.client.id}/assistance-applications`]).then();
            });
            return false;
        }

        this.form.updateValueAndValidity();
        if (!this.form.valid && !(this.statusControl.value === AssistanceApplicationStatus.Denied)) {
            this.injector.get(NotificationService).showError('Please fill out all required fields and ensure all necessary documents are uploaded.');
            this.form.markAllAsTouched();
            return false;
        }

        if (!this.isAssistanceRequestTypeValid()) {
            return false;
        }

        const isIncomeValid = this.validateIncome();
        if (!isIncomeValid && !(this.statusControl.value === AssistanceApplicationStatus.Denied)) {
            this.injector.get(NotificationService).showError('Individual Monthly Incomes Do Not Equal Total Household Monthly Income.');
            return false;
        }

        return true;
    }

    private isAssistanceRequestTypeValid(): boolean {
        const assistanceRequestDescription = this.assistanceApplicationAreasOfNeedGroup as FormGroup;

        const assistanceValues: boolean[] = Object.values(assistanceRequestDescription.value);

        if (!assistanceValues.includes(true)) {
            this.injector.get(NotificationService).showError('Please Select At Least One Assistance Area.');
            return false;
        }

        return true;
    }

    private validateIncome(): boolean {
        let householdMonthlyIncome = 0;
        let applicantMonthlyIncome = 0;

        if (this.assistanceApplication && this.assistanceApplication.isPublic) {
            // if from a public application, use the applicants income entered in the public application
            householdMonthlyIncome = +this.assistanceApplication?.applicantHouseHoldIncome;
            applicantMonthlyIncome = +this.assistanceApplication?.applicantIndividualMonthlyIncome;
        } else {
            // if an internal application, use the income entered in the client record
            householdMonthlyIncome = +this.client?.houseHoldIncome;
            applicantMonthlyIncome = +this.client?.individualMonthlyIncome;
        }

        let totalIndividualIncomes = 0;

        const adultsEntered = this.form.get('adults').value;
        adultsEntered.forEach(x => {
            const incomeAmount = +x.monthlyIncomeAmount;
            totalIndividualIncomes += incomeAmount;
        });

        totalIndividualIncomes += +applicantMonthlyIncome;

        return +householdMonthlyIncome === totalIndividualIncomes;
    }

    private setCategoryThresholdsSelect(): void {
        this.categoryThresholdsSelect = this.categoryThresholds.map(x => ({
            key: x.category.toString(),
            value: x.categoryLabel
        } as ICategoryThreshold));
    }

    private setPayeeTypesSelect(): void {
        this.payeeTypesSelect = ([{
            key: this.getPayeeTypeKeyByValue(CompanyPayeeType.Company),
            value: 'Company'
        }, {
            key: this.getPayeeTypeKeyByValue(CompanyPayeeType.Individual),
            value: 'Individual'
        }] as IPayeeType[]);
    }

    removePayee(index: number): void {
        this.companiesFormArray.removeAt(index);
        this.companyFormArrayMetaData.splice(index, 1);
    }

    private doesCategoryAmountsHaveValidAmounts(): boolean {
        this.rentalTotal = 0;
        this.utilityTotal = 0;
        this.transportationTotal = 0;
        this.childcareTotal = 0;
        this.carRepairTotal = 0;

        const companies = this.companiesFormArray.getRawValue();

        const utility = this.getKeyByValue(CompanyCategoryType.Utility);
        const rental = this.getKeyByValue(CompanyCategoryType.EmergencyRentalAssistance);
        const transportation = this.getKeyByValue(CompanyCategoryType.TransportationForWorkforceNeeds);
        const childcare = this.getKeyByValue(CompanyCategoryType.ChildcareSupport);
        const carRepair = this.getKeyByValue(CompanyCategoryType.CarRepair);

        companies.map(company => {
            if (company?.category === rental) {
                this.rentalTotal += Number(company?.amount);
            } else if (company?.category === utility) {
                this.utilityTotal += Number(company?.amount);
            } else if (company?.category === transportation) {
                this.transportationTotal += Number(company?.amount);
            } else if (company?.category === childcare) {
                this.childcareTotal += Number(company?.amount);
            } else if (company?.category === carRepair) {
                this.carRepairTotal += Number(company?.amount);
            }
        });

        const utilityThreshold = this.categoryThresholds.find(x => x.category.toString() === utility);
        const rentalThreshold = this.categoryThresholds.find(x => x.category.toString() === rental);
        const transportationThreshold = this.categoryThresholds.find(x => x.category.toString() === transportation);
        const childcareThreshold = this.categoryThresholds.find(x => x.category.toString() === childcare);
        const carRepairThreshold = this.categoryThresholds.find(x => x.category.toString() === carRepair);

        const utilityThresholdAvailable = this.utilityTotal <= utilityThreshold?.thresholdAmount;
        const rentalThresholdAvailable = this.rentalTotal <= rentalThreshold?.thresholdAmount;
        const transportationThresholdAvailable = this.transportationTotal <= transportationThreshold?.thresholdAmount;
        const childcareThresholdAvailable = this.childcareTotal <= childcareThreshold?.thresholdAmount;
        const carRepairThresholdAvailable = this.carRepairTotal <= carRepairThreshold?.thresholdAmount;

        this.getPayeeCompanyCategoryAmounts(
            utilityThresholdAvailable,
            rentalThresholdAvailable,
            transportationThresholdAvailable,
            childcareThresholdAvailable,
            carRepairThresholdAvailable);

        return utilityThresholdAvailable &&
            rentalThresholdAvailable &&
            transportationThresholdAvailable &&
            childcareThresholdAvailable &&
            carRepairThresholdAvailable;
    }

    private getKeyByValue(value: number): string | undefined {
        return Object.keys(CompanyCategoryType)
            .find(
                (key) => CompanyCategoryType[key as keyof typeof CompanyCategoryType] === value
            ) as string | undefined;
    }

    private getPayeeTypeKeyByValue(value: number): string | undefined {
        return Object.keys(CompanyPayeeType)
            .find(
                (key) => CompanyPayeeType[key as keyof typeof CompanyPayeeType] === value
            ) as string | undefined;
    }

    private showExceedsCategoryThresholdModal(): void {
        this.exceedsCategoryThresholdModal.open();
    }

    private handleFundPledgeTypeIds(agencyId: string): void {
        this.assistanceApplicationClient.getFundPledgeTypesForAgency(agencyId)
            .pipe(takeUntil(this.$destroyed), distinctUntilChanged())
            .subscribe(fundTypes => {
                this.fundTypes = fundTypes;

                if (fundTypes?.length === 1) {
                    this.fundPledgeTypeId.setValue(fundTypes[0].id);
                    this.fundPledgeTypeId.disable();
                    this.fundPledgeTypeId.removeValidators(Validators.required);
                    this.fundPledgeTypeId.updateValueAndValidity({onlySelf: true});
                    return;
                }

                if (!fundTypes && this.assistanceApplication.fundPledgeTypeId) {
                    this.fundPledgeTypeId.setValue(this.assistanceApplication.fundPledgeTypeId);
                    this.fundPledgeTypeId.disable();
                    this.fundPledgeTypeId.removeValidators(Validators.required);
                    this.fundPledgeTypeId.updateValueAndValidity({onlySelf: true});
                }
            });
    }

    public getPayeeCompanyCategoryAmounts(
        utilityThresholdAvailable: boolean,
        rentalThresholdAvailable: boolean,
        transportationThresholdAvailable: boolean,
        childcareThresholdAvailable: boolean,
        carRepairThresholdAvailable: boolean
    ): IPayeeCompanyCategoryAmount[] {
        const companies = this.companiesFormArray.getRawValue();

        const payeeCompanyCategoryAmounts = new Array<IPayeeCompanyCategoryAmount>();

        companies.map(company => {
            if ((company.category === CompanyCategoryType[CompanyCategoryType.EmergencyRentalAssistance] &&
                    !rentalThresholdAvailable) ||
                (company.category === CompanyCategoryType[CompanyCategoryType.Utility] &&
                    !utilityThresholdAvailable) ||
                (company.category ===
                    CompanyCategoryType[CompanyCategoryType.TransportationForWorkforceNeeds] &&
                    !transportationThresholdAvailable) ||
                (company.category === CompanyCategoryType[CompanyCategoryType.ChildcareSupport] &&
                    !childcareThresholdAvailable) ||
                (company.category === CompanyCategoryType[CompanyCategoryType.CarRepair] &&
                    !carRepairThresholdAvailable)) {
                payeeCompanyCategoryAmounts.push({
                    paymentTo: company.paymentTo || company.companyName,
                    amount: company.amount,
                    category: company.category
                });
            }
        });

        this._store.dispatch(setCurrentPayeeCompanyCategoryAmounts({payload: payeeCompanyCategoryAmounts}));

        return payeeCompanyCategoryAmounts;
    }

    onDeleteFile(event): void {
        this.softDeletedIds = event;
    }

    validateAccountNumber(companyGroup: any): boolean {
        const companyOriginalId = this.getCompanyOriginalId(companyGroup)?.value;
        const foundCompany = this.selectedCompanies.find(x => x.id === companyOriginalId);
        const accountNumberField = this.getAccountsNumber(companyGroup);

        if (!foundCompany) {
            accountNumberField.setErrors(null);
            return true;
        }
        if (!foundCompany || !foundCompany.isAccountValidationEnforced) {
            accountNumberField.setErrors(null);
            return true;
        }
        const accountNumber = accountNumberField?.value;
        let regex = '';
        if (foundCompany.isAccountValidAlphabetical) {
            regex += 'a-zA-Z';
        }
        if (foundCompany.isAccountValidNumerical) {
            regex += '0-9';
        }
        if (foundCompany.isAccountValidSpecialChars) {
            regex += '\-\(\)';
        }
        const min = foundCompany.accountValidationMinLen || 0;
        const max = foundCompany.accountValidationMaxLen || 100;
        if (!(new RegExp(`^[${regex}]{${min},${max}}$`)).test(accountNumber)) {
            if ((foundCompany.accountValidationMaxLen !== null &&
                    foundCompany.accountValidationMinLen !== null &&
                    foundCompany.accountValidationMaxLen === foundCompany.accountValidationMinLen) &&
                accountNumber.length !== foundCompany.accountValidationMinLen) {
                accountNumberField.setErrors({
                    accountNumberValidation: `Account number must be ${foundCompany.accountValidationMinLen} characters. ` +
                        foundCompany.accountValidationErrorMsg
                });
                return false;
            }
            if (foundCompany.accountValidationMinLen && accountNumber.length < foundCompany.accountValidationMinLen) {
                accountNumberField.setErrors({
                    accountNumberValidation: `Account number must be at least ${foundCompany.accountValidationMinLen} characters. ` +
                        foundCompany.accountValidationErrorMsg
                });
                return false;
            }
            if (foundCompany.accountValidationMaxLen && accountNumber.length > foundCompany.accountValidationMaxLen) {
                accountNumberField.setErrors({
                    accountNumberValidation: `Account number cannot be greater than ${foundCompany.accountValidationMaxLen} characters. ` +
                        foundCompany.accountValidationErrorMsg
                });
                return false;
            }
            let allowedCharacters = '';
            if (foundCompany.isAccountValidAlphabetical && foundCompany.isAccountValidNumerical) {
                allowedCharacters = 'alphanumeric';
            } else if (foundCompany.isAccountValidAlphabetical) {
                allowedCharacters = 'alphabetic';
            } else if (foundCompany.isAccountValidNumerical) {
                allowedCharacters = 'numerical';
            }
            accountNumberField.setErrors({
                accountNumberValidation: `Account number may only contain ${allowedCharacters} characters. ` +
                    foundCompany.accountValidationErrorMsg
            });
            return false;
        }

        accountNumberField.setErrors(null);
        return true;
    }

    private setAlertMessages(): void {
        this.householdIncomeAlertMessage = `The client record's household income does not match the applicant's household income submitted in the public application.
This application will be validated based on what was entered on the public application - ${this.currencyPipe.transform(this.assistanceApplication?.applicantHouseHoldIncome, 'USD')}. Please update the client record's household income if necessary.
`;
        this.individualIncomeAlertMessage = `The client record's individual income does not match the applicant's individual income submitted in the public application.
This application will be validated based on what was entered on the public application - ${this.currencyPipe.transform(this.assistanceApplication?.applicantIndividualMonthlyIncome, 'USD')}. Please update the client record's individual income if necessary.`;
        this.emailAlertMessage = `The client record's email does not match the applicant's email submitted in the public application.
Please update the client record's email if necessary. Application updates will be sent to ${this.assistanceApplication?.applicantEmail}`;
        this.countyAlertMessageHtml = `The client record's county does not match the applicant's county submitted in the public application.<br>Please update the client record's address if necessary. If the application county selected is incorrect, you will have to cancel this application.<br><br>Applicant address:<br>${this.assistanceApplication?.applicantAddress}<br>${this.assistanceApplication?.applicantCity}, ${this.assistanceApplication?.applicantState} ${this.assistanceApplication?.applicantZipCode}`;
        this.countyAlertMessageText = `The client record's county does not match the applicant's county submitted in the public application.
Please update the client record's address if necessary. If the application county selected is incorrect, you will have to cancel this application.
Applicant address: ${this.assistanceApplication?.applicantAddress} ${this.assistanceApplication?.applicantCity}, ${this.assistanceApplication?.applicantState} ${this.assistanceApplication?.applicantZipCode}`;
    }

    public openDetailAlert(text: string, html): void {
        swal.fire({
            heightAuto: false,
            html,
            icon: 'warning',
            title: 'Warning',
            text,
        }).then();
    }

    protected readonly AssistanceApplicationDenialReason = AssistanceApplicationDenialReason;
}
