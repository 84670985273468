import {IEfsData} from '../IEfsData';
import {createReducer, on} from '@ngrx/store';
import {
    clearCurrentSelectedEfs,
    efsDataIsLoaded,
    efsDataIsLoading,
    setCurrentClientEfsList,
    setCurrentSelecteddEfs,
} from '../actions/efs.actions';
import {clearCurrentSelectedCompany} from '../actions/company.actions';

export const initialState: IEfsData = {
    isEfsLoading: false,
    efsPaged: null,
    currentSelectedEfs: null,
    currentClientEfsList: null
};

export const efsReducer = createReducer(
    initialState,
    on(setCurrentSelecteddEfs, (state, {payload}) => ({
        ...state,
        isEfsLoading: false,
        currentSelectedEfs: payload
    })),
    on(efsDataIsLoaded, (state, {payload}) => ({
        ...state,
        isEfsLoading: false,
        currentSelectedEfs: payload
    })),
    on(efsDataIsLoading, (state) => ({
        ...state,
        isEfsLoading: true
    })),
    on(clearCurrentSelectedCompany, (state, {payload}) => ({
        ...state,
        currentSelectedEfs: payload
    })),
    on(setCurrentClientEfsList, (state, {payload}) => ({
        ...state,
        currentClientEfsList: payload
    }))
);
