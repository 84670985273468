<div class="row">
  <div class="col-xs-12">
    <h1>Manage Client</h1>
    <div class="tabbable tabs-left mb-lg">
      <ul class="nav nav-tabs">
        <li class="nav-item">
          <a class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" [routerLink]="['./']"
             [class.active-override]="isClientEdit">Overview</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLinkActive="active" [class.tab-bold]="currentClientPledgeList"
             [routerLink]="['./assistance']">Direct Assistance</a>
        </li>
        <li class="nav-item" *ngIf="currentUser?.hasEfs">
          <a class="nav-link" routerLinkActive="active" [class.tab-bold]="currentClientEfsList"
             [routerLink]="['./efs']">EFS</a>
        </li>
        <li class="nav-item" *ngIf="currentUser?.hasKeepingCurrent">
          <!--<a class="nav-link" [class.active]="isActive('applicationTab')" (click)="setView('applicationTab')" [routerActive]="'active'" [routerLink]="['./application']">Keeping Current</a>-->
          <a *ngIf="!applicationResult" class="nav-link" routerLinkActive="active" [routerLink]="['./application']">Keeping
            Current</a>
          <a *ngIf="applicationResult" class="nav-link" routerLinkActive="active"
             [routerLink]="['./application', applicationResult.id]">Keeping Current</a>
        </li>
        <li class="nav-item" *ngIf="currentUser?.hasKeepingCurrent">
          <a class="nav-link" routerLinkActive="active" [routerLink]="['./applications']">Keeping Current History</a>
        </li>
        <li class="nav-item" *ngIf="currentUser?.hasEfn">
          <a class="nav-link" routerLinkActive="active" [class.tab-bold]="clientEfnCases"
             [routerLink]="['./efn_cases']">EF Network</a>
        </li>
        <li class="nav-item" *ngIf="showAssistanceApps">
          <a class="nav-link" routerLinkActive="active" [class.tab-bold]="clientAssistanceApplications"
             [routerLink]="['./assistance-applications']">UHONS</a>
        </li>
      </ul>

      <section class="tab-content">
        <div class="tab-pane active">
          <router-outlet></router-outlet>
        </div>
      </section>
    </div>
  </div>
</div>
