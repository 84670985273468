import {Component, Input, OnInit} from '@angular/core';
import {PledgeTypeService} from '../../../services/PledgeTypeService';
import {PledgeType} from '../../../services/api.client';
import {Observable} from 'rxjs';
import {select, Store} from '@ngrx/store';
import * as fromRoot from '../../../store';
import {selectPledgeTypeDataAllPledgeTypes} from '../../../store/selectors/pledgeType.selectors';

@Component({
  selector: ('massAssignPledgeType'),
  templateUrl: './MassAssignPledgeTypeComponent.html',
  styleUrls: ['./MassAssignPledgeTypeComponent.scss']
})

export class MassAssignPledgeTypeComponent implements OnInit {

  pledgeTypes: PledgeType[];
  pledgeTypes$: Observable<PledgeType[]>;

  @Input('newPledgeTypeId')
  newPledgeTypeId: string;

  sourcePledgeTypeId: string;
  pledgeTypesLoaded = false;

  constructor(
      private pledgeTypeService: PledgeTypeService,
      private _store: Store<fromRoot.AppState>) {
    this.pledgeTypes$ = this._store.pipe(select(selectPledgeTypeDataAllPledgeTypes));
  }

  ngOnInit(): void {
    this.pledgeTypes$.subscribe(pledgeTypes => this.pledgeTypes = pledgeTypes);
    if (this.pledgeTypes && this.pledgeTypes.length) {
      this.pledgeTypesLoaded = true;
    } else {
      this.pledgeTypeService.getAllPledgeTypes()
        .subscribe(() => {
          this.pledgeTypesLoaded = true;
        });
    }
  }

  setSourcePledgeTypeId(sourceId: string): void {
    if (sourceId) {
      this.sourcePledgeTypeId = sourceId;
    }
  }

  submitMassAssign(): void {
    if (this.newPledgeTypeId && this.sourcePledgeTypeId) {
      this.pledgeTypeService.massAssignPledgeType(this.newPledgeTypeId, this.sourcePledgeTypeId);
    }
  }

  //
  // ngOnDestory() {
  //     this.pledgeTypesLoaded = false;
  // }
}
