<h1 class="page-title">County Spending Report</h1>
<section class="widget">
    <div class="widget-body p-5">
        <div class="mb-lg">
            <div [formGroup]="form">

                <div class="row">

                    <div class="col-md-6">
                        <label>Fund Type</label>
                        <select [formControl]="fundType" class="form-control custom-select">
                            <option value="allFundTypes">All Fund Types</option>
                            <option *ngFor="let pledgeFundType of fundTypes"
                                    [value]="pledgeFundType.id">{{pledgeFundType.displayName}}</option>
                        </select>
                    </div>
                    <div class="col-md-6">
                        <label>County</label>
                        <select [formControl]="countyName" class="form-control custom-select">
                            <option value="allCounties">All Counties</option>
                            <option *ngFor="let county of counties"
                                    [value]="county.name">{{county.displayName}}</option>
                        </select>
                    </div>
                </div>
                <div class="row mb-lg pull-right m-t-05 p-t-5 me-2">
                    <a [href]="downloadReport()" class="btn btn-primary">Export to Excel</a>
                    <button type="button" (click)="filterCounties()" class="btn btn-primary m-l-1">Submit</button>
                </div>
            </div>

            <table-dynamic id="datatable-table"
                           class="table table-striped table-hover"
                           [headers]="tableHeaders"
                           [currentPage]="currentPage"
                           [totalCount]="totalCount"
                           (headerClick)="sortCounties($event)"
                           (pageChanged)="setPage($event)"
                           (limitChange)="setLimit($event)">
                <tr *ngFor="let county of (countySpending$ | async)?.items">
                    <td class="hidden-sm-down">{{ county.countyName }}</td>
                    <td class="hidden-sm-down">{{ county.pledgesInProgressTotal }}</td>
                    <td class="hidden-sm-down">{{ county.pledgesPaidTotal }}</td>
                    <td class="hidden-sm-down">{{ county.pledgeDollarsTotal | currency }}</td>
                    <td class="hidden-sm-down">{{ county.pledgesUnprocessedTotal | currency }}</td>
                    <td class="hidden-sm-down">{{ county.pledgesApplicationsTotal }}</td>
                </tr>
            </table-dynamic>
        </div>
    </div>
</section>
