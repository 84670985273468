import {ILookUpData} from '../ILookUpData';
import {createReducer, on} from '@ngrx/store';
import {clearCurrentSelectedLookup, lookupsDataIsLoading, lookupsDataLoaded, setCurrentSelectedLookup} from '../actions/lookup.actions';

export const initialState: ILookUpData = {
  isLookUpsLoading: false,
  lookups: null,
  currentSelectedLookUp: null,
  currentClientLookUpList: null
};

export const lookupReducer = createReducer(
    initialState,
    on(setCurrentSelectedLookup, (state, { payload }) => ({
      ...state,
      currentSelectedLookUp: payload
    })),
    on(lookupsDataLoaded, (state, { payload }) => ({
      ...state,
      isLookUpsLoading: false,
      lookups: payload
    })),
    on(lookupsDataIsLoading, (state) => ({
      ...state,
      isLookUpsLoading: true
    })),
    on(clearCurrentSelectedLookup, (state, { payload }) => ({
      ...state,
      currentSelectedLookUp: payload
    })),
);
