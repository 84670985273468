<div class="row">
  <h2 class="offset-md-1 col-md-10">Pledge Demographic Details Report</h2>
</div>
<div class="row">
  <div class="col-xs-12">
    <form pledgeReportForm #form [showDisplayPledgeStatus]="true" [showCompany]="true" novalidate>
      <button type="submit" class="btn btn-primary" (click)="form.submitPledgeDemographicDetailReport()">Submit</button>
      <button class="btn btn-primary" (click)="form.downloadPledgeDemographicDetailReportClick()">Export to Excel</button>
    </form>
    <h1 *ngIf="rows">Pledge Demographic Details Report</h1>
    <pagedReportTable [headers]="tableHeaders" (tableUpdate)="onTableUpdate($event)" [pagedList]="report$ | async"
                      [rows]="rows "></pagedReportTable>
  </div>
</div>
