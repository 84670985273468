import {createFeatureSelector, createSelector} from '@ngrx/store';
import {IAssistanceApplicationData} from '../IAssistanceApplicationData';

const assistanceApplicationDataFeatureSelector = createFeatureSelector<IAssistanceApplicationData>('assistanceApplication');

export const selectAssistanceApplicationDataAssistanceApplicationsPaged = createSelector(
    assistanceApplicationDataFeatureSelector,
    (state) => state.assistanceApplicationsPaged
);

export const selectAssistanceApplicationDataAssistanceApplicationsPagedTotalCount = createSelector(
    assistanceApplicationDataFeatureSelector,
    (state) => state.assistanceApplicationsPaged?.totalCount
);
