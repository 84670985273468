import {createFeatureSelector, createSelector} from '@ngrx/store';
import {IEfsData} from '../IEfsData';

const efsDataFeatureSelector = createFeatureSelector<IEfsData>('efs');

export const selectEfsDataCurrentClientEfsList = createSelector(
    efsDataFeatureSelector,
    (state) => state.currentClientEfsList
);

export const selectEfsDataCurrentSelectedEfs = createSelector(
    efsDataFeatureSelector,
    (state) => state.currentSelectedEfs
);
