import {AbstractControl, FormArray, FormGroup, UntypedFormControl, ValidationErrors, ValidatorFn} from '@angular/forms';
import {AssistanceApplicationFileType} from '../../../shared/enums/assistance-application-file-type';
import {EmploymentStatus} from '../../../shared/enums/employment-status';
import * as moment from 'moment';
import {environment} from '../../../../environments/environment';
import {AnnualIncomeService} from '../../../services/AnnualIncomeService';

function countFileType(fileType: AssistanceApplicationFileType, filesArray: FormArray): number {
    return filesArray.controls.filter(control => control.get('fileType').value === fileType).length;
}

export function publicAssistanceApplicationRequiredDocumentsUploadedValidator(): ValidatorFn {
    return (form: FormGroup): ValidationErrors | null => {
        const assistanceRequest = (form.get('standardQuestions.assistanceRequestDescription') as FormGroup).value;
        const applicantInformation = (form.get('standardQuestions.applicantInformation') as FormGroup).value;
        const applicantAdult = {
            firstName: applicantInformation.firstName,
            lastName: applicantInformation.lastName,
            totalMonthlyIncome: +applicantInformation.totalMonthlyIncome,
            employmentStatus: applicantInformation.employmentStatus
        };
        const additionalAdults = (form.get('standardQuestions.householdInformation.adults') as FormArray).value;
        const filesArray = form.get('files') as FormArray;

        const errors: ValidationErrors = {};

        if (countFileType(AssistanceApplicationFileType.PhotoId, filesArray) < 1) {
            errors.photoIdRequired = true;
        }

        if (
            countFileType(AssistanceApplicationFileType.UtilityBill, filesArray) +
            countFileType(AssistanceApplicationFileType.TaxStatement, filesArray) +
            countFileType(AssistanceApplicationFileType.TaxReturns, filesArray) +
            countFileType(AssistanceApplicationFileType.BankStatement, filesArray) +
            countFileType(AssistanceApplicationFileType.GovtOrCourtRecord, filesArray) +
            countFileType(AssistanceApplicationFileType.GovtBenefitsStatement, filesArray) +
            countFileType(AssistanceApplicationFileType.SsiSsdiAwardLetter, filesArray) +
            countFileType(AssistanceApplicationFileType.UnemploymentAward, filesArray) +
            countFileType(AssistanceApplicationFileType.WicSnapAwardLetter, filesArray
            ) < 1) {
            errors.utilityOrEquivalentRequired = true;
        }

        const allAdults = [applicantAdult, ...additionalAdults];

        const incomeDocuments = {
            checkStub: countFileType(AssistanceApplicationFileType.CheckStub, filesArray),
            pensionLetter: countFileType(AssistanceApplicationFileType.PensionLetter, filesArray),
            govtBenefitsStatement: countFileType(AssistanceApplicationFileType.GovtBenefitsStatement, filesArray),
            ssiSsdiAwardLetter: countFileType(AssistanceApplicationFileType.SsiSsdiAwardLetter, filesArray),
            unemploymentAward: countFileType(AssistanceApplicationFileType.UnemploymentAward, filesArray),
            wicSnapAwardLetter: countFileType(AssistanceApplicationFileType.WicSnapAwardLetter, filesArray),
            taxReturns: countFileType(AssistanceApplicationFileType.TaxReturns, filesArray),
            profitLossStatement: countFileType(AssistanceApplicationFileType.ProfitLossStatement, filesArray),
        };

        const selfEmployedAdults = allAdults.filter(adult => adult.employmentStatus === EmploymentStatus.SelfEmployed);
        const nonSelfEmployedAdults = allAdults.filter(adult => adult.employmentStatus !== EmploymentStatus.SelfEmployed);

        const hasSelfEmployedAdults = selfEmployedAdults.length > 0;
        const hasValidIncomeDocumentsForSelfEmployed = incomeDocuments.taxReturns + incomeDocuments.profitLossStatement >= selfEmployedAdults.length;
        if (hasSelfEmployedAdults && !hasValidIncomeDocumentsForSelfEmployed) {
            errors.incomeDocumentRequiredForSelfEmployed = true;
        }

        const hasNonSelfEmployedAdults = nonSelfEmployedAdults.length > 0;
        const hasValidIncomeDocumentsForNonSelfEmployed = (
            incomeDocuments.checkStub +
            incomeDocuments.pensionLetter +
            incomeDocuments.govtBenefitsStatement +
            incomeDocuments.ssiSsdiAwardLetter +
            incomeDocuments.unemploymentAward +
            incomeDocuments.wicSnapAwardLetter
        ) >= nonSelfEmployedAdults.length;

        if (hasNonSelfEmployedAdults && !hasValidIncomeDocumentsForNonSelfEmployed) {
            errors.incomeDocumentRequiredForNonSelfEmployed = true;
        }

        if (assistanceRequest.childcare && countFileType(AssistanceApplicationFileType.ChildcareBill, filesArray) < 1) {
            errors.childcareBillRequired = true;
        }

        if (assistanceRequest.carRepair && countFileType(AssistanceApplicationFileType.AutoRepairBill, filesArray) < 1) {
            errors.autoRepairBillRequired = true;
        }

        if (assistanceRequest.electric ||
            assistanceRequest.gasHeat ||
            assistanceRequest.water ||
            assistanceRequest.sewer) {
            if (countFileType(AssistanceApplicationFileType.UtilityBill, filesArray) +
                countFileType(AssistanceApplicationFileType.UtilityShutoffNotice, filesArray) < 1) {
                errors.utilityBillOrShutoffNoticeRequired = true;
            }
        }

        if (assistanceRequest.evictionNotification) {
            if (countFileType(AssistanceApplicationFileType.EvictionCourtOrder, filesArray) < 1) {
                errors.evictionCourtRecordRequired = true;
            }
        }

        if (assistanceRequest.rent) {
            if (countFileType(AssistanceApplicationFileType.LeaseAgreement, filesArray) < 1) {
                errors.leaseAgreementRequired = true;
            }

            if (countFileType(AssistanceApplicationFileType.DelinquentRentNotice, filesArray) < 1) {
                errors.delinquentRentNoticeRequired = true;
            }
        }

        if (countFileType(AssistanceApplicationFileType.SsnProof, filesArray) < 1) {
            errors.ssnProofRequired = true;
        }

        return Object.keys(errors).length > 0 ? errors : null;
    };
}

export function yearRangeValidator(startDate: moment.Moment, endDate: moment.Moment): any {
    return (control: UntypedFormControl) => {
        if (!control.value) {
            return null;
        }
        const value = control.value + '';
        if (value.length !== 4) {
            return {yearRange: 'Please enter valid birth year using four digit format (0-9).'};
        }

        const year = moment(value, 'YYYY');

        if (year < startDate) {
            return {
                yearRange: 'The date must be more recent than ' +
                    moment(startDate).format('YYYY') + '.'
            };
        }
        return null;
    };
}

export function zipCodeValidator(): ValidatorFn {
    return (control: UntypedFormControl) => {
        if (!control.value) {
            return null;
        }
        const value = control.value + '';
        if (value.length !== 5) {
            return {zipCode: 'Please enter a valid zip code using a five digit format (0-9).'};
        }
        return null;
    };
}

export function ssnValidator(): ValidatorFn {
    return (control: UntypedFormControl) => {
        if (!control.value) {
            return null;
        }
        const value = (control.value + '').replace(/-/g, '');
        if (value.length !== 9) {
            return {ssn: 'Please enter a valid social security number using a nine digit format (0-9).'};
        }
        return null;
    };
}

export function minorValidation(): ValidatorFn {
    return (control: UntypedFormControl) => {
        if (!control.value) {
            return null;
        }
        const value = control.value + '';
        if (value.length !== 4) {
            return;
        }

        const enteredYear = +value;
        const currentYear = new Date().getFullYear();

        const age = currentYear - enteredYear;

        // This will include some 18-year-olds. We rely on user to externally validate that they are 18.
        if (age > 17) {
            return {minor: 'Person entered is 18 or older.'};
        }
        return null;
    };
}

export function adultApplicationValidator(): ValidatorFn {
    return (control: UntypedFormControl) => {
        if (!control.value) {
            return null;
        }
        const value = control.value + '';
        if (value.length !== 4) {
            return;
        }

        const enteredYear = +value;
        const currentYear = new Date().getFullYear();

        const age = currentYear - enteredYear;

        if (age < 18) {
            return {adultApplication: 'You must be older than 18 to complete this application.'};
        }
        return null;
    };
}

export function stateValidator(): ValidatorFn {
    const invalidStates = ['IL', 'Other'];

    return (control: UntypedFormControl) => {
        if (!control.value) {
            return null;
        }
        if (invalidStates.includes(control.value)) {
            return {
                invalidState: 'To be eligible for this program you must currently live in Missouri. Based on your\n' +
                    '                answer, you are not qualified for this program. To see what other programs and services\n' +
                    '                might be available in your community, please dial 211.'
            };
        }
        return null;
    };
}

export function stateValidatorForValidStates(): ValidatorFn {
    const validStates = ['MO'];

    return (control: UntypedFormControl) => {
        if (!control.value) {
            return null;
        }
        if (!validStates.includes(control.value)) {
            return {
                invalidState: 'To be eligible for this program you must currently live in Missouri. Based on your\n' +
                    '                answer, you are not qualified for this program. To see what other programs and services\n' +
                    '                might be available in your community, please dial 211.'
            };
        }
        return null;
    };
}


