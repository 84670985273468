import {IAgencyData} from '../IAgencyData';
import {createReducer, on} from '@ngrx/store';
import {
    agenciesDataClear,
    agenciesDataIsLoading,
    agenciesDataLoaded,
    agenciesDataSoftDelete, setAgencyEfnCases,
    setCurrentSelectedAgency,
} from '../actions/agency.actions';

export const initialState: IAgencyData = {
  currentSelectedAgency: null,
  isAgenciesLoading: false,
  agencies: null,
  agencyEfnCases: [],
};

export const agencyReducer = createReducer(
    initialState,
    on(agenciesDataIsLoading, (state) => ({
      ...state,
      isAgenciesLoading: true
    })),
    on(agenciesDataLoaded, (state, { payload }) => ({
      ...state,
      isAgenciesLoading: false, agencies: payload
    })),
    on(setCurrentSelectedAgency, (state, { payload }) => ({
      ...state,
      currentSelectedAgency: payload
    })),
    on(agenciesDataClear, (state) => ({
      ...state,
      currentSelectedAgency: null
    })),
    on(agenciesDataSoftDelete, (state) => ({
      ...state,
      agencies: null
    })),
    on(setAgencyEfnCases, (state, { payload }) => ({
        ...state,
        agencyEfnCases: payload
    }))
);
