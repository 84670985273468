import {createFeatureSelector, createSelector} from '@ngrx/store';
import {IReportData} from '../IReportData';

const reportStateFeatureSelector = createFeatureSelector<IReportData>('report');

export const selectReportDataShiftPledgeReportSummary = createSelector(
    reportStateFeatureSelector,
    (state) => state.shiftPledgeReportSummary
);

export const selectReportDataCompanyMailingReport = createSelector(
    reportStateFeatureSelector,
    (state) => state.companyMailingReport
);

export const selectReportDataPledgeDemographicByZipCodeReport = createSelector(
    reportStateFeatureSelector,
    (state) => state.pledgeDemographicByZipCodeReport
);

export const selectReportDataPledgeDemographicCountryReport = createSelector(
    reportStateFeatureSelector,
    (state) => state.pledgeDemographicCountyReport
);

export const selectReportDataConfirmOrphanReport = createSelector(
    reportStateFeatureSelector,
    (state) => state.confirmOrphanReport
);

export const selectReportDataAllocationReport = createSelector(
    reportStateFeatureSelector,
    (state) => state.allocationReport
);

export const selectReportDataPledgeDemographicSummaryReport = createSelector(
    reportStateFeatureSelector,
    (state) => state.pledgeDemographicSummaryReport
);

export const selectReportDataPledgeDemographicDetailReport = createSelector(
    reportStateFeatureSelector,
    (state) => state.pledgeDemographicDetailReport
);

export const selectReportDataEnrollmentReport = createSelector(
    reportStateFeatureSelector,
    (state) => state.enrollmentReport
);

export const selectReportDataMissedPaymentReport = createSelector(
    reportStateFeatureSelector,
    (state) => state.missedPaymentReport
);

export const selectReportDataAgencySummaryReport = createSelector(
    reportStateFeatureSelector,
    (state) => state.agencySummaryReport
);

export const selectReportDataAgencyDetailReport = createSelector(
    reportStateFeatureSelector,
    (state) => state.agencyDetailReport
);

export const selectReportDataProcessingReport = createSelector(
    reportStateFeatureSelector,
    (state) => state.processingReport
);

export const selectReportDataPaymentProcessingCompanyMailingReport = createSelector(
    reportStateFeatureSelector,
    (state) => state.companyMailingLabelsReport
);

export const selectReportDataEfsReport = createSelector(
    reportStateFeatureSelector,
    (state) => state.efsReport
);

export const selectReportDataPendingPledgesDetailProcessingReport = createSelector(
    reportStateFeatureSelector,
    (state) => state.processingDetailReport
);

// export const selectReportDataEfsReport = createSelector(
//     selectReportStateFeature,
//     (state) => state.
// );

