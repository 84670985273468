import {Injectable} from '@angular/core';
import {Disposable} from '../utilities/Disposable';
import {AssistanceApplicationFileType} from '../shared/enums/assistance-application-file-type';

@Injectable({
    providedIn: 'root',
})
export class FileUploadService extends Disposable {

    constructor() {
        super();
    }

    public photoIdTypes = [
        AssistanceApplicationFileType.PhotoId
    ];

    public ssnProofTypes = [
        AssistanceApplicationFileType.SsnProof
    ];

    public proofOfIncomeTypes = [
        AssistanceApplicationFileType.CheckStub,
        AssistanceApplicationFileType.TaxReturns,
        AssistanceApplicationFileType.ProfitLossStatement
    ];

    public employmentTypes = [
        AssistanceApplicationFileType.UtilityBill,
        AssistanceApplicationFileType.TaxStatement,
        AssistanceApplicationFileType.TaxReturns,
        AssistanceApplicationFileType.BankStatement,
        AssistanceApplicationFileType.GovtOrCourtRecord,
        AssistanceApplicationFileType.GovtBenefitsStatement,
        AssistanceApplicationFileType.SsiSsdiAwardLetter,
        AssistanceApplicationFileType.UnemploymentAward,
        AssistanceApplicationFileType.WicSnapAwardLetter
    ];

    public incomeTypes = [
        AssistanceApplicationFileType.CheckStub,
        AssistanceApplicationFileType.PensionLetter,
        AssistanceApplicationFileType.GovtBenefitsStatement,
        AssistanceApplicationFileType.SsiSsdiAwardLetter,
        AssistanceApplicationFileType.UnemploymentAward,
        AssistanceApplicationFileType.WicSnapAwardLetter
    ];

    public selfEmployedTypes = [
        AssistanceApplicationFileType.TaxReturns,
        AssistanceApplicationFileType.ProfitLossStatement
    ];

    public utilityBillTypes = [
        AssistanceApplicationFileType.UtilityBill,
        AssistanceApplicationFileType.UtilityShutoffNotice
    ];

    public childcareTypes = [
        AssistanceApplicationFileType.ChildcareBill
    ];

    public evictionTypes = [
        AssistanceApplicationFileType.EvictionCourtOrder
    ];

    public leaseAgreementTypes = [
        AssistanceApplicationFileType.LeaseAgreement
    ];

    public delinquentRentNoticeTypes = [
        AssistanceApplicationFileType.DelinquentRentNotice
    ];

    public transportationTypes = [
    ];

    public carRepairTypes = [
        AssistanceApplicationFileType.AutoRepairBill
    ];

    public getFileRequirementStatus(currentFiles: AssistanceApplicationFileType[]): FileUploadChecklist {
        return {
            photoId: currentFiles.includes(AssistanceApplicationFileType.PhotoId),
            employment: this.employmentTypes.some(x => currentFiles.includes(x)),
            income: this.incomeTypes.some(x => currentFiles.includes(x)),
            selfEmployed: this.selfEmployedTypes.some(x => currentFiles.includes(x)),
            utilityBill: this.utilityBillTypes.some(x => currentFiles.includes(x)),
            childcare: this.childcareTypes.some(x => currentFiles.includes(x)),
            transportation: this.transportationTypes.some(x => currentFiles.includes(x)),
            carRepair: this.carRepairTypes.some(x => currentFiles.includes(x)),
            evictionNotification: this.evictionTypes.some(x => currentFiles.includes(x))
        } as FileUploadChecklist;
    }

}

export interface FileUploadChecklist {
    photoId?: boolean;
    employment?: boolean;
    income?: boolean;
    selfEmployed?: boolean;
    utilityBill?: boolean;
    childcare?: boolean;
    transportation?: boolean;
    evictionNotification?: boolean;
}
