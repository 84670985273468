import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, UntypedFormControl} from '@angular/forms';
import {ITableHeader, TableComponent} from '../../../../shared/table/TableComponent';
import {
    BulkPaymentPayPledgeParameters,
    BulkPaymentPdfParameters,
    GetPledgesByBatchRequest,
    PledgeBulkPaymentReportClient, PledgeBulkPaymentVendorSummaryViewModel,
    PledgeClient,
    PledgeStatusType,
    PledgeType,
    PledgeTypeClient
} from '../../../../../services/api.client';
import {BehaviorSubject, lastValueFrom, Observable, Subject} from 'rxjs';
import {select, Store} from '@ngrx/store';
import {selectPledgeTypeDataPledgeTypesItems} from '../../../../../store/selectors/pledgeType.selectors';
import * as fromRoot from '../../../../../store';
import {CompanySelectedEvent} from '../../../../shared/events/company-selected-event';
import {CompanyListModalComponent} from '../../../company/CompanyListModalComponent';
import {filter, takeUntil, tap} from 'rxjs/operators';
import {PledgeDataRow} from '../pledge-data-row';
import {Disposable} from '../../../../../utilities/Disposable';
import swal from 'sweetalert2';
import * as moment from 'moment';

import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import {NotificationService} from '../../../../../services/NotificationService';

(pdfMake as any).vfs = pdfFonts.pdfMake.vfs;

@Component({
    selector: 'app-view-batch',
    templateUrl: './view-batch.component.html',
    styleUrls: ['./view-batch.component.scss']
})
export class ViewBatchComponent extends Disposable implements OnInit, AfterViewInit {

    searchValue = '';
    tableHeaders: ITableHeader[] = [];
    searchForm: FormGroup;
    pledgeTypes: PledgeType[];
    pledgeTypes$: Observable<PledgeType[]>;
    selectedCompany = '';
    pledgeData: PledgeDataRow[] = [];
    deletedStatus = PledgeStatusType[PledgeStatusType.Deleted];
    activeTableHeader: ITableHeader;
    allRowsSelected = new Subject<boolean>();
    @Output() onPledgesPaid = new EventEmitter<number>();

    @Input() batchId: BehaviorSubject<string> = new BehaviorSubject<string>('');

    @ViewChild(TableComponent)
    table: TableComponent;
    @ViewChild(CompanyListModalComponent)
    companyListModal: CompanyListModalComponent;

    constructor(
        private _store: Store<fromRoot.AppState>,
        private fb: FormBuilder,
        private pledgeClient: PledgeClient,
        private pledgeTypeClient: PledgeTypeClient,
        private pledgeReportClient: PledgeBulkPaymentReportClient,
        private _notificationService: NotificationService
    ) {
        super();
        this.pledgeTypes$ = this._store.pipe(select(selectPledgeTypeDataPledgeTypesItems));

        this.searchForm = this.fb.group({
            companyId: new UntypedFormControl(''),
            pledgeTypeId: new UntypedFormControl(''),
            clientName: new UntypedFormControl(''),
        });

        this.tableHeaders = [
            {text: '', sortKey: '', class: 'hidden-sm-down', isCheckbox: true},
            {text: 'Fund', sortKey: 'fund', class: 'hidden-sm-down'},
            {text: 'Last Name', sortKey: 'lastName'},
            {text: 'First Name', sortKey: 'firstName'},
            {text: 'Date', sortKey: 'date'},
            {text: 'Amount', sortKey: 'amount'},
            {text: 'Payee', sortKey: 'company'},
            {text: 'Status', sortKey: 'status'},
            {text: 'Paid Date', sortKey: 'paidDate'},
        ];
    }

    public ngOnInit(): void {
        this.pledgeTypeClient.getAuthorizedPledgeTypes().subscribe(r => this.pledgeTypes = r);
    }

    public ngAfterViewInit(): void {
        this.batchId.pipe(takeUntil(this.$destroyed), filter(id => !!id)).subscribe(_ => this.loadPledges());
    }

    public onCompanySelected(event: CompanySelectedEvent): void {
        if (!event.company && !event.noCompany) {
            return;
        }
        this.selectedCompany = event.company?.name || '';
        this.searchForm.get('companyId').setValue(event.company?.id);
    }

    public showCompanyModal(): void {// show Company Modal
        this.companyListModal.openModal();
    }

    public loadPledges(): void {
        const request = GetPledgesByBatchRequest.fromJS(
            {
                ...this.searchForm.value,
                ...{
                    batchId: this.batchId.getValue()
                }
            }
        );
        this.table.request = this.pledgeClient.loadPledgesByBatch(request).pipe(
            tap(results => this.pledgeData = results.map(r => {
                return {
                    ...r,
                    ...{
                        isChecked: false
                    }
                };
            })),
            tap(_ => {
                if (this.activeTableHeader) {
                    this.sortPledgeData(this.activeTableHeader);
                }
            })
        );
    }

    public isDeletedStatus(row: PledgeDataRow): boolean {
        return row.status.toString() === this.deletedStatus;
    }

    public selectAllOrNone(val: boolean): void {
        this.pledgeData.forEach(row => {
            if (row.status.toString() !== this.deletedStatus) {
                row.isChecked = val;
            }
        });
    }

    public resetFilters(): void {
        this.searchForm.reset();
        this.selectedCompany = '';
    }

    public sortPledgeData(options: ITableHeader): void {
        this.pledgeData = this.pledgeData.slice().sort((a, b) => {
            const valueA = a[options.sortKey];
            const valueB = b[options.sortKey];

            if (typeof valueA === 'string' && typeof valueB === 'string') {

                const lowerCaseValueA = valueA.toLowerCase().trim();
                const lowerCaseValueB = valueB.toLowerCase().trim();

                const comparison = lowerCaseValueA.localeCompare(lowerCaseValueB);

                return options.ascending ? comparison : -comparison;
            } else {
                return options.ascending ? valueA - valueB : valueB - valueA;
            }
        });

        this.activeTableHeader = options;
    }

    public rowCheckChanged(): void {
        this.allRowsSelected.next(this.pledgeData.filter(x => !x.isChecked)?.length === 0);
    }

    public async paySelectedPledges(): Promise<void> {
        const selectedPledges = this.pledgeData.filter(x => x.isChecked);
        if (!selectedPledges?.length) {
            swal.fire({
                title: 'No Pledges Selected',
                text: 'Please select at least one pledge',
                icon: 'warning'
            }).then();
            return;
        }
        try {
            const result = await this.showConfirmationDialog();
            if (result.isConfirmed && result.value) {
                await this.payPledges(result.value);
                this.loadPledges();
            }
        } catch (error) {
            console.error('Error paying selected pledges:', error);
        }
    }

    private async showConfirmationDialog(): Promise<any> {
        const today = new Date();
        const maxDate = new Date();
        maxDate.setDate(today.getDate() + 5); // 5 days in the future
        const minDate = new Date();
        minDate.setDate(today.getDate() - 30); // 30 days in the past

        return swal.fire({
            title: 'Pay Selected Pledges',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Pay',
            html: `
            <label for="pledgeDate" style="margin-bottom: .5rem; display: block;">Paid Date</label>
            <input type="date"
                   id="pledgeDate"
                   name="pledgeDate"
                   min="${minDate.toISOString().split('T')[0]}"
                   max="${maxDate.toISOString().split('T')[0]}"
                   value="${today.toISOString().split('T')[0]}"
                   class="swal2-input">
                    `,
            preConfirm: () => {
                const dateValue = (document.getElementById('pledgeDate') as HTMLInputElement).value;
                if (!dateValue) {
                    swal.showValidationMessage('Please select a valid date');
                }
                return dateValue;
            },
        });
    }

    private async payPledges(paidDate: Date): Promise<void> {
        const selectedPledges = this.pledgeData.filter(x => x.isChecked);
        const ids = selectedPledges.map(x => x.pledgeId);
        const request = BulkPaymentPayPledgeParameters.fromJS({
            pledgeIds: ids,
            paidDate: moment(paidDate).toDate()
        });
        await lastValueFrom(
            this.pledgeReportClient.bulkPayPledges(request)
        );
        this.onPledgesPaid.emit(selectedPledges?.length);
    }

    public generateVendorSummary(): void {
        const selectedPledges = this.pledgeData.filter(x => x.isChecked);

        if (!selectedPledges?.length) {
            swal.fire({
                title: 'No Pledges Selected',
                text: 'Please select at least one pledge',
                icon: 'warning'
            }).then();
            return;
        }

        const ids = selectedPledges.map(x => x.pledgeId);
        const request = BulkPaymentPdfParameters.fromJS({
            pledgeIds: ids
        });
        this.pledgeReportClient.getBulkPaymentPdfData(request).subscribe(data => {
            const docDefinition = {
                pageOrientation: 'landscape',
                content: [],
                styles: {
                    header: {fontSize: 14, bold: true, margin: [0, 0, 0, 10]},
                    subheader: {fontSize: 12, margin: [0, 0, 0, 20]},
                    tableHeader: {bold: true, fillColor: '#EEEEEE', alignment: 'left', fontSize: 10, color: '#333333'},
                    totalLabel: {bold: true, fontSize: 12, alignment: 'right', margin: [0, 5, 0, 0]},
                    totalAmount: {bold: true, fontSize: 12, alignment: 'right', margin: [0, 5, 0, 0]},
                    footer: {fontSize: 10, italics: true, margin: [0, 10, 0, 0]}
                },
                footer: (currentPage, pageCount) => {
                    return {
                        columns: [
                            {
                                text: 'Generated on ' + moment().format('MM/DD/YYYY'),
                                alignment: 'center',
                                style: 'footer'
                            },
                            {
                                text: data[0].footerText,
                                alignment: 'center',
                                style: 'footer'
                            },
                            {
                                text: `Page ${currentPage} of ${pageCount}`,
                                alignment: 'center',
                                style: 'footer'
                            }
                        ]
                    };
                },
            };
            data.forEach((vendor: PledgeBulkPaymentVendorSummaryViewModel, vendorIndex: number) => {
                if (vendorIndex > 0) {
                    docDefinition.content.push({text: '', pageBreak: 'after'});
                }
                docDefinition.content.push({text: vendor.vendorName, style: 'header'});
                docDefinition.content.push({
                    text: `${vendor.address1}${vendor.address2 ? `, ${vendor.address2}` : ''}, ${vendor.city}, ${vendor.state} ${vendor.zipCode}`,
                    style: 'subheader'
                });
                const pledgeChunks = [];
                for (let i = 0; i < vendor.pledges.length; i += 8) {
                    pledgeChunks.push(vendor.pledges.slice(i, i + 8));
                }
                pledgeChunks.forEach((chunk, index) => {
                    docDefinition.content.push({
                        table: {
                            headerRows: 1,
                            widths: ['*', '*', '*', '*', '*'],
                            body: [
                                [
                                    {text: 'NAME / SSN (Last 4)', style: 'tableHeader'},
                                    {text: 'ADDRESS', style: 'tableHeader'},
                                    {text: 'ACCT #', style: 'tableHeader'},
                                    {text: 'PLEDGING AGENCY / FUNDING SOURCE', style: 'tableHeader'},
                                    {text: 'AMOUNT PLEDGED', style: 'tableHeader'}
                                ],
                                ...chunk.map((pledge: any) => [
                                    `${pledge.clientName} / ${pledge.clientLast4Ssn}`,
                                    `${pledge.address1}${pledge.address2 ? `, ${pledge.address2}` : ''}, ${pledge.city}, ${pledge.state} ${pledge.zipCode}`,
                                    pledge.accountNumber,
                                    `${pledge.pledgingAgency} / ${pledge.fundType}`,
                                    {text: `$ ${pledge.amount.toFixed(2)}`, alignment: 'center'}
                                ]),
                                ...(index === pledgeChunks.length - 1 ? [
                                    [
                                        {text: 'TOTAL REMITTED', colSpan: 4, style: 'totalLabel'},
                                        {}, {}, {},
                                        {text: `$ ${vendor.totalRemitted.toFixed(2)}`, style: 'totalAmount'}
                                    ]
                                ] : [])
                            ]
                        },
                        layout: 'lightHorizontalLines'
                    });
                    if (index < pledgeChunks.length - 1) {
                        docDefinition.content.push({text: '', pageBreak: 'after'});
                    }
                });
            });
            const pdfDoc = pdfMake.createPdf(docDefinition);
            pdfDoc.download('Vendor-Summary-' + moment().toDate() + '.pdf');
        }, error => {
            this._notificationService.showError('Error generating vendor summary:', error);
        });
    }
}
