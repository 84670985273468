import {createAction, props} from '@ngrx/store';
import {Agency, LoginResult, SystemData} from '../../services/api.client';

export const loginInProgress = createAction('[SECURITY] LOGIN_INPROGRESS');
export const loginSuccess = createAction('[SECURITY] LOGIN_SUCCESS', props<{ payload: LoginResult }>());
export const loginFailure = createAction('[SECURITY] LOGIN_FAILURE', props<{ payload: string }>());
export const logoutAction = createAction('[SECURITY] LOGOUT');
export const systemDataUpdatedAction = createAction('[SECURITY] SYSTEMDATA_UPDATED', props<{ payload: SystemData }>());
export const setVerifiedClientId = createAction('[SECURITY] SET_VERIFIED_CLIENT_ID', props<{ payload: string }>());
export const setDefaultManagementAgency = createAction('[SECURITY] DEFAULT_MANAGEMENT_AGENCY', props<{ payload: Agency }>());

