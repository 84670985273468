import {IFederalPovertyLevelData} from '../IFederalPovertyLevelData';
import {createReducer, on} from '@ngrx/store';
import {
  clearCurrentSelectedFederalPovertyLevel, currentClientFederalPovertyLevelsData,
  federalPovertyLevelsDataIsLoaded,
  federalPovertyLevelsDataIsLoading,
  setCurrentSelectedFederalPovertyLevel
} from '../actions/federalPovertyLevel.actions';

export const initialState: IFederalPovertyLevelData = {
  isFederalPovertyLevelsLoading: false,
  currentClientFederalPovertyLevel: 0,
  currentSelectedFederalPovertyLevel: null
};

export const federalPovertyLevelReducer = createReducer(
    initialState,
    on(setCurrentSelectedFederalPovertyLevel, (state, { payload }) => ({
      ...state,
      currentSelectedFederalPovertyLevel: payload
    })),
    on(federalPovertyLevelsDataIsLoaded, (state, { payload }) => ({
      ...state,
      isFederalPovertyLevelsLoading: false,
      currentSelectedFederalPovertyLevel: payload
    })),
    on(federalPovertyLevelsDataIsLoading, (state) => ({
      ...state,
      isFederalPovertyLevelsLoading: true
    })),
    on(clearCurrentSelectedFederalPovertyLevel, (state, { payload }) => ({
      ...state,
      currentSelectedFederalPovertyLevel: payload
    })),
    on(currentClientFederalPovertyLevelsData, (state, { payload }) => ({
      ...state,
      currentClientFederalPovertyLevel: payload
    }))
);
