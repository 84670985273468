import {EMPTY as observableEmpty, Observable} from 'rxjs';
import {Component, Input, forwardRef} from '@angular/core';
import {EfnCase} from '../../../../../../../services/api.client';
import {EfnCaseComment} from '../../../../../../../services/api.client';
import {EfnService} from '../../../../../../../services/EfnService';
import {UntypedFormControl} from '@angular/forms';
import {Validators} from '../../../../../../../utilities/Validators';
import {EfnCaseHelper} from '../../EfnHelper';
import {AutoDisableParent} from '../../../../../../../directives/AutoDisableDirective';
import {tap} from 'rxjs/operators';

@Component({
  selector: 'efnCommentsTab',
  templateUrl: './EfnCommentsTabComponent.html',
  styleUrls: ['./EfnCommentsTabComponent.scss'],
  providers: [{provide: AutoDisableParent, useExisting: forwardRef(() => EfnCommentsTabComponent)}],
})
export class EfnCommentsTabComponent {

  comment = new UntypedFormControl(null, Validators.required);
  comments: EfnCaseComment[] = null;

  _efnCase: EfnCase;

  @Input()
  set efnCase(efnCase: EfnCase) {
    this._efnCase = efnCase;
    if (this._efnCase && !this.comments) {
      this.reloadComments();
    }
  }

  get efnCase(): EfnCase {
    return this._efnCase;
  }

  constructor(private efnService: EfnService, private helper: EfnCaseHelper) {
  }

  addComment(): Observable<any> {
    if (!this.comment.valid) {
      this.comment.markAsTouched();
      return observableEmpty;
    }
    const user = this.helper.user;
    return this.efnService.addEfnCaseComment({
      efnCaseId: this._efnCase.id,
      userId: user.id,
      comment: this.comment.value,
      agencyName: user.agency.agencyName,
      userFullName: user.firstName + ' ' + user.lastName
    } as any)
        .pipe(
          tap(() => {
            this.comment.setValue(null);
            this.comment.markAsUntouched();
            this.comment.markAsPristine();
            this.reloadComments();
          }) as any
        );
  }

  reloadComments(): void {
    this.comments = null;
    this.efnService.getEfnCommentsByEfnCaseId(this._efnCase.id)
      .subscribe((comments: EfnCaseComment[]) => this.comments = comments);
  }

}
