import {map} from 'rxjs/operators';
import {Component, OnInit, ViewChild} from '@angular/core';
import {ITableHeader} from '../../../../shared/table/TableComponent';
import {KeepingCurrentReportFormComponent} from '../form/KeepingCurrentReportFormComponent';
import {Observable} from 'rxjs';
import {ConfirmOrphanReport} from '../../../../../services/api.client';
import {SHORT_DATE_FORMAT} from '../../../../../constants';
import * as moment from 'moment';
import {formatCurrency} from '../../../../../utilities/Util';
import {select, Store} from '@ngrx/store';
import * as fromRoot from '../../../../../store';
import {selectReportDataConfirmOrphanReport} from '../../../../../store/selectors/report.selectors';

@Component({
    selector: 'orphan-report-component',
    templateUrl: './OrphansReportComponent.html',
})
export class OrphansReportComponent implements OnInit {

    tableHeaders: ITableHeader[] = [
        {text: 'Agency'},
        {text: 'SSN'},
        {text: 'Customer'},
        {text: 'Account Number'},
        {text: 'Initial Bill Date'},
        {text: 'Program Expiration Date'},
        {text: 'Program Credit'},
        {text: 'Arrears Credit'},
        {text: 'Budget Bill Amount'},
        {text: 'Customer Pay Amount'},
        {text: 'Enrollment Status'},
        {text: 'Rejection Reason'},
        {text: 'Confirm Date'},
        {text: 'Import Date'},
    ];

    @ViewChild('form')
    form: KeepingCurrentReportFormComponent;

    report$: Observable<ConfirmOrphanReport[]>;
    rows: any;

    constructor(private _store: Store<fromRoot.AppState>) {
        this.report$ = this._store.pipe(select(selectReportDataConfirmOrphanReport));
    }

    ngOnInit(): void {
        this.rows = this.report$
            .exists().pipe(
                map((report) => report.map(r => [
                    r.agency,
                    '***-**-' + r.ssn,
                    r.customer,
                    r.accountNumber,
                    moment(r.initialBillDate).format(SHORT_DATE_FORMAT),
                    moment(r.programExpiration).format(SHORT_DATE_FORMAT),
                    formatCurrency(r.programCredit),
                    formatCurrency(r.arrearsCredit),
                    formatCurrency(r.budgetBillAmount),
                    formatCurrency(r.customerPayAmount),
                    r.enrollmentStatus,
                    r.rejectionReason,
                    moment(r.confirmDate).format(SHORT_DATE_FORMAT),
                    moment(r.importDate).format(SHORT_DATE_FORMAT)
                ])));

    }
}
