import {map, filter} from 'rxjs/operators';
import {Component, OnInit} from '@angular/core';
import {ITableHeader} from '../../../../shared/table/TableComponent';
import {Observable} from 'rxjs';
import {PledgeSummaryReport} from '../../../../../services/api.client';
import {User} from '../../../../../services/api.client';
import {select, Store} from '@ngrx/store';
import * as fromRoot from '../../../../../store';
import {selectReportDataAgencySummaryReport} from '../../../../../store/selectors/report.selectors';
import {selectSecurityStateDataCurrentUser} from '../../../../../store/selectors/security.selectors';

@Component({
  templateUrl: './AgencySummaryReportComponent.html',
})
export class AgencySummaryReportComponent implements OnInit {
  tableHeaders: ITableHeader[] = [];
  rows: any;

  // subscription
  currentUser: User;
  currentUser$: Observable<User>;
  report$: Observable<PledgeSummaryReport[]>;

  constructor(private _store: Store<fromRoot.AppState>) {
      this.currentUser$ = this._store.pipe(select(selectSecurityStateDataCurrentUser));
      this.report$ = this._store.pipe(select(selectReportDataAgencySummaryReport));
  }

  ngOnInit(): void {
    this.currentUser$.subscribe(user => this.currentUser = user);
    if (this.currentUser.hasPrivate) {
      this.tableHeaders = [
        {text: 'Agency', },
        {text: 'Pledge Type', },
        {text: '# People Helped', },
        {text: '# Pledges', },
        {text: 'Amount', },
        {text: 'Ledger #', },
        {text: 'Private', },
      ];

      this.rows = this.report$.pipe(
        filter(report => !!report),
        map((report) => report.map(r => [
          r.agencyName,
          r.pledgeType,
          r.helpedCount,
          r.pledgeCount,
          '$ ' + r.pledgeAmount.toFixed(2),
          r.ledgerNumber,
          r.isPrivate
        ])), );

    } else {
      this.tableHeaders = [
        {text: 'Agency', },
        {text: 'Pledge Type', },
        {text: '# People Helped', },
        {text: '# Pledges', },
        {text: 'Amount', },
        {text: 'Ledger #', },
      ];

      this.rows = this.report$.pipe(
        filter(report => !!report),
        map((report) => report.map(r => [
          r.agencyName,
          r.pledgeType,
          r.helpedCount,
          r.pledgeCount,
          '$ ' + r.pledgeAmount.toFixed(2),
          r.ledgerNumber
        ])), );
    }
  }
}
