import {Injectable, OnDestroy} from '@angular/core';
import {Subject} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export abstract class Disposable implements OnDestroy {
    protected $destroyed = new Subject();

    ngOnDestroy(): void {
        this.$destroyed.next(null);
    }
}
