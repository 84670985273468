import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {Observable} from 'rxjs';
import {Pledge} from '../../../../services/api.client';
import {PledgeService} from '../../../../services/PledgeService';
import {ITableHeader, TableComponent} from '../../../shared/table/TableComponent';
import {User} from '../../../../services/api.client';
import {FormControl, FormGroup, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import * as moment from 'moment';
import {PledgeType} from '../../../../services/api.client';
import {select, Store} from '@ngrx/store';
import * as fromRoot from '../../../../store';
import {selectPledgeDataIsPledgesLoading, selectPledgeDataManualPendingPledges} from '../../../../store/selectors/pledge.selectors';
import {
    selectSecurityStateDataCurrentUser,
    selectSecurityStateDataSecurityDataPledgeTypes
} from '../../../../store/selectors/security.selectors';

@Component({
    selector: 'manualPendingToPaid',
    templateUrl: './ManualPendingToPaidComponent.html',
    styleUrls: ['./ManualPendingToPaidComponent.scss']
})

export class ManualPendingToPaidComponent implements OnInit, AfterViewInit {

    get paidDate(): FormControl {
        return this.form.get('paidDate') as FormControl;
    }

    get pledgeIds(): FormControl {
        return this.form.get('pledgeIds') as FormControl;
    }

    form = new FormGroup({
        paidDate: new FormControl([moment()
            .startOf('year')
            .format("MM/DD/YYYY")
        ]),
        pledgeIds: new FormControl([])
    });

    tableHeaders: ITableHeader[] = [];

    // subscriptions
    isLoading: boolean;
    isLoading$: Observable<boolean>;
    currentUser: User;
    currentUser$: Observable<User>;
    pledges: Pledge[];
    pledges$: Observable<Pledge[]>;
    pledgeTypes: PledgeType[];
    pledgeTypes$: Observable<PledgeType[]>;

    @ViewChild(TableComponent)
    table: TableComponent;

    constructor(public _pledgeService: PledgeService,
                private _formBuilder: UntypedFormBuilder,
                private _store: Store<fromRoot.AppState>) {
        this.currentUser$ = this._store.pipe(select(selectSecurityStateDataCurrentUser));
        this.isLoading$ = this._store.pipe(select(selectPledgeDataIsPledgesLoading));
        this.pledges$ = this._store.pipe(select(selectPledgeDataManualPendingPledges));
        this.pledgeTypes$ = this._store.pipe(select(selectSecurityStateDataSecurityDataPledgeTypes));
    }

    ngOnInit(): void {
        this.currentUser$.subscribe(currentUser => this.currentUser = currentUser);
        this.isLoading$.subscribe(isLoading => this.isLoading = isLoading);
        this.pledges$.subscribe(pledges => this.pledges = pledges);
        this.pledgeTypes$.subscribe(pledgeTypes => this.pledgeTypes = pledgeTypes);
        this._pledgeService.getManualPendingToPaidPledges();
        this.initForm();
        if (this.currentUser.hasPrivate) {
            this.tableHeaders = [
                {text: 'Select to Pay', },
                {text: 'Last Name', sortKey: 'ClientLastName'},
                {text: 'First Name', sortKey: 'ClientFirstName'},
                {text: 'SSN.', sortKey: 'ClientLast4Ssn', class: 'hidden-sm-down'},
                {text: 'Pledge', sortKey: 'PledgedAmount'},
                {text: 'Fund Type', sortKey: 'Name', class: 'hidden-sm-down'},
                {text: 'Date', sortKey: 'PledgedDate', class: 'hidden-sm-down'},
                {text: 'Status', sortKey: 'Status', class: 'hidden-sm-down'},
                {text: 'Ledger #'},
                {text: 'Private'},
            ];
        } else {
            this.tableHeaders = [
                {text: 'Select to Pay', },
                {text: 'Last Name', sortKey: 'ClientLastName'},
                {text: 'First Name', sortKey: 'ClientFirstName'},
                {text: 'SSN.', sortKey: 'ClientLast4Ssn', class: 'hidden-sm-down'},
                {text: 'Pledge', sortKey: 'PledgedAmount'},
                {text: 'Fund Type', sortKey: 'Name', class: 'hidden-sm-down'},
                {text: 'Date', sortKey: 'PledgedDate', class: 'hidden-sm-down', ascending: true},
                {text: 'Status', sortKey: 'Status', class: 'hidden-sm-down'},
                {text: 'Ledger #', },
            ];
        }
    }

    initForm(): void {
        this.form = this._formBuilder.group({
            paidDate: [moment()
                .startOf('year')
                .format("MM/DD/YYYY")
            ],
            pledgeIds: [],
        });
    }

    ngAfterViewInit(): void {
    }

    formatFundType(fundTypeId): string {
        if (this.pledgeTypes) {
            for (const pledgeType of this.pledgeTypes) {
                if (pledgeType.id === fundTypeId) {
                    return pledgeType.name;
                }
            }
            return 'Missing Pledge Type';
        }
    }

    submit(): any {
        return void 0;
    }

    manualSubmitPendingToPaid(): void {
        const form = this.form.value;
        this._pledgeService.bulkUpdateManualPendingToPaid(form);
    }

    ngOnDestroy(): void {
        this._pledgeService.clearManualPendingPledges();
    }

    // TODO: disable Pay button if no selection or no date
}
