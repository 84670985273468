<div class="container">

    <div *ngIf="hasUtilityAmounts" class="row m-l-05 mt-5">
        <label class="col-md-12"><strong>Payee Category:</strong> {{utilityThresholdInformation?.categoryLabel}} - <strong>Allowed
            Maximum
            Amount: </strong>{{utilityThresholdInformation?.thresholdAmount | currency}}</label>
        <li *ngFor="let utilityCompanyCategoryAmount of utilityCompanyCategoryAmounts" class="col-md-8 m-l-05">
            <strong>Payee Name: </strong>{{ utilityCompanyCategoryAmount?.paymentTo }} -
            <strong>Amount: </strong>{{ utilityCompanyCategoryAmount?.amount | currency }}
        </li>
    </div>

    <div *ngIf="hasRentalAmounts" class="row m-l-05 mt-5">
        <label class="col-md-12"><strong>Payee Category: </strong>{{rentalThresholdInformation?.categoryLabel}} - <strong>Allowed
            Maximum
            Amount: </strong>{{rentalThresholdInformation?.thresholdAmount | currency}}</label>
        <li *ngFor="let rentalCompanyCategoryAmount of rentalCompanyCategoryAmounts" class="col-md-8 m-l-05">
            <strong>Payee Name: </strong> {{ rentalCompanyCategoryAmount?.paymentTo }} -
            <strong>Amount:</strong> {{ rentalCompanyCategoryAmount?.amount | currency }}
        </li>
    </div>

    <div *ngIf="hasTransportationAmounts" class="row m-l-05 mt-5">
        <label class="col-md-12"><strong>Payee Category: </strong>{{transportationThresholdInformation?.categoryLabel}} - <strong>Allowed
            Maximum
            Amount: </strong>{{transportationThresholdInformation?.thresholdAmount | currency}}</label>
        <li *ngFor="let transportationCompanyCategoryAmount of transportationCompanyCategoryAmounts"
             class="col-md-8 m-l-05">
            <strong>Payee Name: </strong>{{ transportationCompanyCategoryAmount?.paymentTo }} -
            <strong>Amount: </strong>{{ transportationCompanyCategoryAmount?.amount | currency }}
        </li>
    </div>


    <div *ngIf="hasChildcareAmounts" class="row m-l-05 mt-5">
        <label class="col-md-12"><strong>Payee Category: </strong>{{childcareThresholdInformation?.categoryLabel}} - <strong>Allowed
            Maximum
            Amount: </strong>{{childcareThresholdInformation?.thresholdAmount | currency}}</label>
        <li *ngFor="let childcareCompanyCategoryAmount of childcareCompanyCategoryAmounts" class="col-md-8 m-l-05">
            <strong>Payee Name: </strong>{{ childcareCompanyCategoryAmount?.paymentTo }} -
            <strong>Amount: </strong>{{ childcareCompanyCategoryAmount?.amount | currency }}
        </li>
    </div>

    <div *ngIf="hasCarRepairAmounts" class="row m-l-05 mt-5">
        <label class="col-md-12"><strong>Payee Category: </strong>{{carRepairThresholdInformation?.categoryLabel}} - <strong>Allowed
            Maximum
            Amount: </strong>{{carRepairThresholdInformation?.thresholdAmount | currency}}</label>
        <li *ngFor="let carRepairCompanyCategoryAmount of carRepairCompanyCategoryAmounts" class="col-md-8 m-l-05">
            <strong>Payee Name: </strong>{{ carRepairCompanyCategoryAmount?.paymentTo }} -
            <strong>Amount: </strong>{{ carRepairCompanyCategoryAmount?.amount | currency }}
        </li>
    </div>

    <div class="row m-l-05 mt-5">
        <em>If this case qualifies for emergency assistance above the standard program thresholds, please contact your administrator.</em>
    </div>
</div>
