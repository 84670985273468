import {Component, OnInit} from '@angular/core';
import {Pledge} from '../../../../services/api.client';
import {PledgeType} from '../../../../services/api.client';
import {Client} from '../../../../services/api.client';
import {PledgeStatusType} from '../../../../services/api.client';
import {splitCamelCase} from '../../../../utilities/Util';
import {PledgeTypeService} from '../../../../services/PledgeTypeService';
import {Router} from '@angular/router';
import {Observable} from 'rxjs';
import {select, Store} from '@ngrx/store';
import * as fromRoot from '../../../../store';
import {selectClientDataCurrentClient} from '../../../../store/selectors/client.selectors';
import {selectPledgeDataCurrentClientPledgeList} from '../../../../store/selectors/pledge.selectors';
import {selectPledgeTypeDataAllPledgeTypes} from '../../../../store/selectors/pledgeType.selectors';

@Component({
  selector: 'ClientAssistanceComponent',
  templateUrl: './ClientAssistanceComponent.html',
  styleUrls: ['./ClientAssistanceComponent.scss']
})

export class ClientAssistanceComponent implements OnInit {

  // subscription
  currentClient: Client;
  currentClient$: Observable<Client>;
  allPledgeTypes: PledgeType[];
  allPledgeTypes$: Observable<PledgeType[]>;
  currentClientPledgeList: Pledge[];
  currentClientPledgeList$: Observable<Pledge[]>;

  constructor(private _router: Router,
              private _pledgeTypeService: PledgeTypeService,
              private _store: Store<fromRoot.AppState>) {
    this.currentClient$ = this._store.pipe(select(selectClientDataCurrentClient));
    this.allPledgeTypes$ = this._store.pipe(select(selectPledgeTypeDataAllPledgeTypes));
    this.currentClientPledgeList$ = this._store.pipe(select(selectPledgeDataCurrentClientPledgeList));
  }

  ngOnInit(): void {
    this.currentClient$.subscribe(currentClient => this.currentClient = currentClient);
    this.allPledgeTypes$.subscribe(allPledgeTypes => this.allPledgeTypes = allPledgeTypes);
    this.currentClientPledgeList$.subscribe(currentClientPledgeList => this.currentClientPledgeList = currentClientPledgeList);
    this.getPledgeTypes();
  }

  formatPledgeTypeName(pledgeTypeId): string {

    if (!this.allPledgeTypes) {
      return '';
    }
    const type = this.allPledgeTypes.find(pledgeType => pledgeType.id === pledgeTypeId);
    // if (type && type.name) {
    // return type.name;
    // }
    return type && type.name || '';
  }

  formatPledgeStatus(pledgeStatus): string {
      if (typeof pledgeStatus === 'number' && !!PledgeStatusType[pledgeStatus]) {
          return splitCamelCase(PledgeStatusType[pledgeStatus], true);
      }
      if (typeof pledgeStatus === 'string') {
          return splitCamelCase(pledgeStatus, true);
      }
      return '';
  }

  getPledgeTypes(): void {
    this._pledgeTypeService.getAllPledgeTypes();
  }

  isClientValid(): number {
    return (this.currentClient.birthYear &&
        this.currentClient.gender &&
        this.currentClient.employmentStatus &&
        this.currentClient.houseHoldIncome && this.currentClient.houseHoldSize);
  }
}
